import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CategoriesDashFetchAPI {

    static async createCategorie(data, token) {
        console.log(data)
        const res = await server(`/category`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    static async getCategoriesByBranchOffice(branchoffice_id, token) {
        const res = await server(`/category_per_branchoffice/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getCategoriesByBusiness(business_id, token) {
        const res = await server(`/category_per_business/${business_id}/business`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async UpdateCategories(data, category_id, token) {
        const res = await server(`/category/${category_id}`, {
            method: "put",
            data: data,
            headers: { 'x-access-token': token, 'Content-Type': 'multipart/form-data' },
        });
        return res
    }

    static async UpdateCategoriesByBranchOffice(data, category_id, branchoffice_id, token) {
        const res = await server(`/category_per_branchoffice/${category_id}/category/${branchoffice_id}/branchoffice`, {
            method: "put",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }


    static async getCategoryType( token) {
        const res = await server(`/types/category`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async deleteCategory(data, business_id, token) {
        const res = await server(`/category_per_business/${business_id}/business`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }


    static async deleteCategorytoBranchoffice(category_id, branchoffice_id, token) {
        const res = await server(`/category_per_branchoffice/${category_id}/category/${branchoffice_id}/branchoffice`, {
            method: "delete",
            headers: { 'x-access-token': token },
        });
        return res
    }


    static async asingCategoryToBranchoffice(category_id, branchoffice_id, token) {
        const res = await server(`/category_per_branchoffice/${category_id}/category/${branchoffice_id}/branchoffice`, {
            method: "post",
            headers: { 'x-access-token': token },
        });
        return res
    }
    
 
}