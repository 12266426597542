import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CategriesFetchAPI {

    static async getCategories(businessId) {
        const res = await server(`/category_per_business/${businessId}/business`, {
            method: "get"
        });
        return res
    }

    static async getProductsByCategories(categoryId) {
        const res = await server(`/group_per_category/${categoryId}/category`, {
            method: "get"
        });
        return res
    }
}