import React, { useState } from 'react'
//Component
import Header from "./components/Header"
import ModalKioskLanguage from './ModalKioskLanguage';
import Footer from './components/Layout/Footer';
import { RenderIf } from '../../components/utils/RenderIf';

//Icons
import { UilUtensils, UilShoppingBag } from '@iconscout/react-unicons'


function KioskDelivery({ data, icon, background, updateData, nextStep, skipToStep3, setCheckDeliveryOrder, statusLanguage, setStatusLanguage, setAtivity, parthners, setStep, setPageLoader }) {

  const [isButtonFocusedId, setButtonFocusId] = useState(null);

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };

  const handleReturnParthners = () => {// function reload request and clear params localstorage
    if (localStorage.getItem('branchoffice_original') != null) {
      localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
    }

    if (localStorage.getItem('machine_original') != null) {
      localStorage.setItem('machine', localStorage.getItem('machine_original'))
    }

    setPageLoader(1)
  }



  return (
    <>
      <section className='kiosk-delivery'
        style={{ backgroundImage: `url(${background})` }}
        onClick={() => setAtivity(1)}>
        {/* Headaer */}
        <div className='d-flex justify-content-between align-items-center'>
          <Header
            classContainer={"kiosk-delivery_header m-0 p-0"}
            classImg={"kiosk-delivery_header_img"}>
          </Header>
          <ModalKioskLanguage
            icon={icon}
            background={background}
            statusLanguage={statusLanguage}
            setStatusLanguage={setStatusLanguage}
            setAtivity={setAtivity} >
          </ModalKioskLanguage>

        </div>
        {/* Content */}
        <div className='kiosk-container_section  scale-up-top'>
          <h2 className='kiosk-delivery_title 
           text-center p-2'>
            ¡Hola! <br />
            ¿Cómo deseas recibir <br /> tu pedido?
          </h2>
          {/* TODO: Hacer componente la parte de los botones de delivery  */}
          <div className='kiosk-delivery_btn ' >
            <div className='kiosk-delivery_btns  mt-5'>
              {/* +++++++ 1 ++++++ */}
              <button
                id='comer_aqui'
                onClick={() => (setCheckDeliveryOrder("comer aqui"), setStep(2))}
                className={`btn  btn-outline-primary  btn-card-delivery  btn-lg m-2 
                 shadow border border-2 position-relative
                 ${isButtonFocusedId === 'comer_aqui' ? 'focused' : ''}`}
                onFocus={() => handleButtonFocus('comer_aqui')}
                onBlur={handleButtonBlur}
              >
                {/* <div className='my-3'>
                  <UilUtensils size="240" />
                </div> */}
                <i className="uil uil-utensils btn-img-delivery"></i>
                <span className='kiosk-text-delivery d-block '>
                  Comer Aquí
                </span>
              </button>


              {/* +++++++ 2 ++++++ */}
              <button
                id='para_llevar'
                onClick={() => (setCheckDeliveryOrder("para llevar"), setStep(2))}
                className={`btn  btn-outline-primary  btn-card-delivery  btn-lg m-2 
                shadow border border-2 position-relative
                ${isButtonFocusedId === 'para_llevar' ? 'focused' : ''}`}
                onFocus={() => handleButtonFocus('para_llevar')}
                onBlur={handleButtonBlur} >
                {/* <div className='my-3 '>
                  <UilShoppingBag size="240"  ></UilShoppingBag>
                </div> */}
                <i className="uil uil-shopping-bag btn-img-delivery "></i>
                <span className='kiosk-text-delivery d-block'>
                  Para Llevar
                </span>
              </button>
            </div>
          </div>

          <RenderIf isTrue={parthners.length > 0}>
            <div className='d-flex position-absolute
           start-0 bottom-0  container-button m-2'>
              <button
                type="button"
                onClick={() => handleReturnParthners()}
                className={`m-0
               btn-lg  shadow-lg border-0
            button-ft_1 position-relative `}

                title='Regresar'
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                  className=""
                  id="left-arrow">
                  <path
                    d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>


              </button>
            </div>
          </RenderIf>

        </div>
        {/* Footer */}
        <Footer></Footer>
      </section>
    </>
  )
}

export default KioskDelivery