import React, { useState } from 'react'
//Component
import Header from "./components/Header"
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import dataProducts from "./data/products.json"
import { RenderIf } from '../../components/utils/RenderIf';

function InfoProduct({ categories, icon, background, updateData, nextStep, prevStep, skipToStep3, selectProduct, segments, amountGroupSelect, setAmountGroupSelect, shoppingCart, setShoppingCart, calculateInvoiceTotals, setAtivity }) {

  //Focus
  const [isButtonFocusedId, setButtonFocusId] = useState(null);
  let logo = ""
  if(localStorage.getItem('logo') !=  null || localStorage.getItem('logo') !=  undefined || localStorage.getItem('logo') != ""){
    logo=localStorage.getItem('logo')
  }

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };


  //increment amount select group
  const incrementAmount = () => {
    let newAmount = (amountGroupSelect + 1)
    setAmountGroupSelect(newAmount)
  }

  //decrement amount select group
  const decrementAmount = () => {
    if (amountGroupSelect > 1) {
      let newAmount = (amountGroupSelect - 1)
      setAmountGroupSelect(newAmount)
    }
  }


  // format json of product to add shopppingcart
  const formatJsonToAddShoppingCart = () => {

    console.log(selectProduct);
    let formatProductAdd = selectProduct.group

    if(formatProductAdd.img == null || formatProductAdd.img == "" || formatProductAdd.img == undefined){
      formatProductAdd.img=logo
    }
    //For default we set that the amount of the selected product depending the number 
    formatProductAdd.amount = amountGroupSelect;
    formatProductAdd.cantidadItem = amountGroupSelect;
    formatProductAdd.delivery_type = "comer aqui";
    formatProductAdd.note = '';
    formatProductAdd.unit_discount = 0;
    formatProductAdd.product_discount = 0;
    formatProductAdd.base_price = formatProductAdd.price;
    formatProductAdd.descuento = formatProductAdd.product_discount;
    formatProductAdd.discount = formatProductAdd.product_discount;
    //For default we initialice the segments empty of the selected product 
    formatProductAdd.segments = [];
    formatProductAdd.DetalleSegmentos = [];
    formatProductAdd.segments_string = ""

    //find segments checked
    let temporal_segment_string = ""
    segments.forEach((segment) => {
      segment.items.forEach((item) => {
        let idCheckedItem = document.getElementById("listGroupRadioGrid" + segment.segment_id + item.item_id)
        if (idCheckedItem.checked == true) {
          item.amount = amountGroupSelect
          temporal_segment_string = formatProductAdd.segments_string + " " + segment.name + ": " + item.name
          formatProductAdd.segments_string = formatProductAdd.segments_string + " " + segment.name + ": " + item.name
          formatProductAdd.segments.push({ ...item, segment_name: segment.name, cantidadItem: amountGroupSelect, item: item })
          formatProductAdd.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: amountGroupSelect, item: item })
          formatProductAdd.price = formatProductAdd.price + item.price
        }
      })

    });

    if (formatProductAdd.promotion_id) {
      addpromotionToShoppiingCart(formatProductAdd)
    } else {
      addProductSelectToShoppingcart(formatProductAdd, temporal_segment_string)
    }


  }

  //add promotion
  const addpromotionToShoppiingCart = (promotion) => {
    let turn = 0
    shoppingCart.forEach((product) => {
      if (product.promotion_id) {
        if (product.promotion_id == promotion.promotion_id) {
          ErrorAlert("Puedes incrementar la cantidad del mismo en el carrito.", "Ya existe esta promocion en el carrito.", "warning");
          turn = 1
        }
      }
    });

    if (turn == 0) {
      //We add the new product to the shopping cart
      setShoppingCart([...shoppingCart, promotion]);
      //We calculate the invoice totals
      calculateInvoiceTotals([...shoppingCart, promotion])
    } else {
      setShoppingCart(shoppingCart)
      //We calculate the invoice totals
      calculateInvoiceTotals(shoppingCart)
    }
    //reset amount in infoProduct
    setAmountGroupSelect(1);
    nextStep();
  }


  //add product to shopingcart 
  const addProductSelectToShoppingcart = (product, temporal_segment_string) => {
    let temporalShopingCart = shoppingCart
    let turn = 0
    //check item string segments into shopingcart
    for (let shopping = 0; shopping < temporalShopingCart.length; shopping++) {
      if (product.group_id == temporalShopingCart[shopping].group_id) {
        if (temporalShopingCart[shopping].segments_string === temporal_segment_string) {
          if (temporalShopingCart[shopping].descuento == 0) {
            temporalShopingCart[shopping].amount = (temporalShopingCart[shopping].amount + amountGroupSelect)
            temporalShopingCart[shopping].cantidadItem = temporalShopingCart[shopping].amount
            turn = 1
          }
        }
      }
    }

    if (turn == 0) {
      //We add the new product to the shopping cart 
      setShoppingCart([...shoppingCart, product]);
      //We calculate the invoice totals 
      calculateInvoiceTotals([...shoppingCart, product])
      //if (checkDeliveryOrder != "comer aqui") { setCheckDeliveryOrder("mixto") }
    } else {
      setShoppingCart(temporalShopingCart)
      //We calculate the invoice totals
      calculateInvoiceTotals(temporalShopingCart)
    }

    //reset amount in infoProduct
    setAmountGroupSelect(1);

    nextStep();

  }

  const imgTest = 'https://images.unsplash.com/photo-1628155930542-3c7a64e2c833?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  return (  

    <>
      <section className='kiosk-delivery' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>

        <div className='kiosk-segments-container position-relative' >
          <div className='kiosk-segments-backgound'        
            style={{
              backgroundImage: `url('${selectProduct.group.img || selectProduct.group.img
                }')`,
            }}
          >

            <div className='cover-fake bg-transparent p-5 buttons-z'>
            </div>

            <button className=' shadow position-absolute  me-2
                 icon-info-product' >
              <i class="uil uil-search-plus"></i>
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                  <div className='position-relative '>
                    <img className='img-modal-info' src={selectProduct.group.img === null || selectProduct.group.img === '' ? logo : selectProduct.group.img}  alt="" />
                    <button type="button"
                      className="btn-close btn-close-img-info position-absolute top-100 start-100 translate-middle  bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                </div>
              </div>
            </div>

            <div className='product-card-tv ' data-bs-toggle="modal" data-bs-target="#exampleModal">
            </div>

            {/* Producto Name*/}
            <div className='d-flex  align-items-center justify-content-between 
    position-absolute w-100 start-0 bottom-0' >
              <div>
                <h5 className='kiosk-subtitle-md  scale-up-horizontal-left text-wrap m-3 text-white'>{selectProduct.group.name}</h5>
              </div>
              {/* Producto Price*/}
              <div>
                <span className='kiosk-subtitle-md text-nowrap border-2
      border-start border-end px-3 text-white'>$ {parseFloat(selectProduct.group.price).toFixed(2)}</span>
              </div>
              {/* Producto Cound*/}
              <div className='m-2 text-white '>
                <div className="btn-group" role="group" aria-label="Basic outlined example">
                  {/* 1 */}
                  <button type="button" className={`btn btn-danger count-kiosk rounded-4 position-relative`}
                    onClick={() => decrementAmount()}><i className="bi bi-dash"></i>



                  </button>

                  {/* Count  */}
                  <span className="btn count-kiosk btn-outline-light fw-bold border-0 ">{amountGroupSelect}</span>

                  {/* 2 */}
                  <button type="button"
                    className={`btn btn-success count-kiosk rounded-4 position-relative`}


                    onClick={() => incrementAmount()}><i className="bi bi-plus"></i>


                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Producto Description*/}
          <div className='kiosk-segments-description py-2'>
            <p className='kiosk-description-product mx-3 text-dark'>{selectProduct.group.description}</p>
          </div>



        </div>

        <div className='kiosk-container_section kiosk-segments-items container-fluid  bg-white ' style={{ backgroundImage: `url(${background})` }} >
          <div className='mt-3'>
            {segments.map((segment) => (<>
              <article className='container-card-content-wrapper'>
                <div>
                  <div className='d-block mt-2'>
                    <span className='kiosk-subtitle-md text-dark'>{segment.name}</span>
                  </div>
                </div>


                {/* <div className="d-flex flex-column flex-md-row  gap-4 py-md-1 
              align-items-center"> */}
                {/* <div className="list-group list-group-radio d-grid gap-2 border-0"> */}
                <div className="kiosk-lista-container-product mx-1 border-end border-1">
                  <div className='kiosk-lista scale-up-left '>
                    {segment.items.map((item, index) => (
                      <div key={segment.segment_id + item.item_id}

                        className={`position-relative h-100 `}
                        onFocus={() => handleButtonFocus(segment.segment_id + item.item_id)}
                        onBlur={handleButtonBlur}

                      >
                        <label htmlFor={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                          className="radio-card mx-2 ">
                          <input
                            type="radio"
                            name={"listGroupRadioGrid" + segment.segment_id}
                            id={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                            value=""
                            defaultChecked={segment.segment_item_id_default == null ? index == 0 ? true : false : segment.segment_item_id_default == item.item_id ? true : false}

                          />
                          <div className="card-content-wrapper">
                            <span className="check-icon"></span>
                            <div className="card-content">
                              <img
                                className='bg-light'
                                src={item.img === null || item.img === '' ? logo : item.img}
                                alt=""
                              />
                              <h4 className='label-h4'>                               
                                  {item.name}                               
                              </h4>
                              <h5 className='text-center'>${parseFloat(item.price).toFixed(2)}</h5>
                            </div>
                          </div>
                        </label>



                      </div>
                    ))}
                  </div>
                </div>

                {/* </div> */}
                {/* </div> */}
              </article>

            </>))}
          </div>
        </div>
      </section >



      <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
        <button
          onClick={prevStep}

          className={`btn btn-danger kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4
          ${isButtonFocusedId === "cancel" ? 'focused' : ''}`}
          onFocus={() => handleButtonFocus("cancel")}
          onBlur={handleButtonBlur}

        >Cancelar
          {/* Focus  */}
          <div className="position-absolute postition-icon-btn-accions">
            {isButtonFocusedId === "cancel" &&
              <img src={icon} alt="Hand" className='icon-focus' />}
          </div>
        </button>


        <button
          className={`btn btn-success kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}

          onClick={() => formatJsonToAddShoppingCart()}


        >Agregar


        </button>

      </div>
    </>
  )
}

export default InfoProduct