import axios from 'axios';

const server = axios.create({
    baseURL: "",
    headers: { 'Content-Type': "application/json" },
});

export class RequestLocalFetchAPI {

    static async postPaymentMethodVending(data) {

        const payment = JSON.stringify(data); 
        let response = await axios.post("http://localhost:9090", payment);
        return response;
    }


    static async postPaymentMethodVendingVoid(data) {

        const payment = JSON.stringify(data); 
        let response = await axios.post("http://localhost:9090/validarBAC", payment);
        return response;
    }

    

    //post payment
    /*static async postPaymentMethod(data) {

        const payment = JSON.stringify(data);
        let response = await axios.post("http://localhost:9090", payment);
        return response;
    }*/

    /*static async postPaymentMethod(data, endpoint) {
        const res = await axios.post(`http://${endpoint}:4000/smartpay`, , {
            headers: { 'Content-Type': "application/json" },
        });
        return res
    }*/


    
    //request windows local witch  c# pos
    static async postPaymentMethod(data, endpoint) {
        const payment = JSON.stringify(data);
        let response = await axios.post("http://localhost:9090/cobrar", payment);

        let jsonResponse = {data: response}
        console.log(jsonResponse);
        console.log(response);
        return jsonResponse;
    }



    //request windows local witch node
    /*static async postPaymentMethod(data, endpoint) {
        const payment = {ruta: "http://localhost:9090/cobrar", body: data };
        const res = await server(`http://localhost:4000/smartpay`, {
            method: "post",
            data: payment,

        });
        return res
    }*/


   //request shutdown machine
    static async postShutdownMachine(json) {
        const data = JSON.stringify(json)
        let response = await axios.post("http://localhost:9090/apagar", data);
        return response;
    }

    static async postMicros(json) {
        const data = JSON.stringify(json)
        let response = await axios.post("http://localhost:9090/micros", data);
        return response;
    }
    
    //request android local
    /*static async postPaymentMethod(data, endpoint){
        const payment = JSON.stringify({ruta:`http://${endpoint}:4000/smartpay`,  body: { ruta: "http://localhost:9090/cobrar", body: data }});
        let response = await axios.post("http://localhost:9000/tarjetas", payment);
        return response;
    }*/
}