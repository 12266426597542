//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//Components 
import { Badge } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import { RenderIf } from '../../components/utils/RenderIf';
import Header from './components/Header';
//Utils 
//External modules 
import moment from 'moment-timezone';
//Api 
import { OrdersFetchAPI } from '../../api/Orders';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
//img
//import empty from '../img/icons/empty.png'

function ModalOrderDetails({ icon, orderData, showModalOrderDetails, setShowMOdalOrderDetails }) {

    const [isButtonFocusedId, setButtonFocusId] = useState(null);

    const handleButtonFocus = (buttonId) => {
        setButtonFocusId(buttonId);
    };

    const handleButtonBlur = () => {
        setButtonFocusId(null);
    };

    console.log(orderData);
    //We charge the items of the order 
    const orderItems = () => {
        let items = orderData.groups.map((product) => (
            <div className="d-flex text-muted pt-3 px-xs-2" key={product._id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="150" height="150" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold kiosk-text-card">{product.name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3 kiosk-text-card'>x {product.amount} </span>
                            <label className="fw-bold kiosk-text-card" translate='no'>$ {product.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>
                        <div className="d-flex justify-content-between">
                            {/* Name */}
                            {/* Cantidad/ Quantity*/}
                            <div >
                                {/** We check the type of modal and we return the input discount field depending of it */}
                                <label className={product.discount === undefined ? "" : "text-danger kiosk-text-card"}>{product.discount === undefined ? "No registrado kiosk-text-card" : "- $" + product.discount.toFixed(2)}</label>



                            </div>
                        </div>
                        {product.segments.map((segments) => (
                            <div className="d-flex justify-content-between" key={segments._id}>
                                {/* item name */}
                                <span className="kiosk-text-card">{segments.item.name}</span>
                            </div>
                        ))}

                        <div>
                            <div>
                                <small className='text-primary p-1 kiosk-text-card' >
                                    Entrega: {product.delivery_type}
                                </small>
                            </div>
                            <div>
                                <RenderIf isTrue={product.note != ""}>
                                    <small className='text-danger p-1 kiosk-text-card' >
                                        Nota: {product.note}
                                    </small>
                                </RenderIf>
                            </div>
                        </div>

                    </>
                </div>
            </div>
        ));
        return <>{items}</>
    }






    return (
        <>

            <Modal show={showModalOrderDetails} onHide={() => setShowMOdalOrderDetails(false)} fullscreen>

                <Modal.Body>

                    <div className='d-flex justify-content-center align-items-center'>
                        <Header
                            classContainer={"kiosk-delivery_header m-0 p-0"}
                            classImg={"kiosk-delivery_header_img"}>
                        </Header>

                    </div>
                    <Container className='mb-2'>
                        <Row>
                            <Col sm={12} md={12}  >
                                <div className="py-4 px-lg-4 ">
                                    <div className="head-section-sp mt-2">
                                        <div className="">
                                            <div className="mt-1 mb-3">


                                                <div className="d-flex justify-content-between align-items-center mb-3 ">
                                                    <h5 className=''>
                                                        <span className="text-success kiosk-delivery_title">Orden: </span>
                                                        <span className="text-secondary kiosk-delivery_title"> # {orderData.transaction_code ? orderData.transaction_code : 0}</span>
                                                    </h5>
                                                </div>

                                                <div>
                                                    <span className="text-nowrap text-secondary h6 d-block kiosk-text-card">
                                                        <i className="fa-solid fa-store"></i>&nbsp;
                                                        Sucursal: {orderData.branchoffice.name}
                                                    </span>


                                                </div>

                                            </div>
                                        </div>
                                        <div className=' d-flex justify-content-between text-secondary opacity-75'>
                                            <div className='kiosk-text-card'> Productos </div>
                                            <div className='kiosk-text-card'> Precio </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-lg-12 order-md-last">
                                                {orderItems()}
                                                <div className="d-flex text-muted pt-3">
                                                    <div className="pb-3 mb-0 small lh-sm border-top w-100">

                                                        <div className="d-flex justify-content-end mt-3 ">
                                                            <span className="text-gray-dark fw-light h6 kiosk-text-card"> Descuento: </span>
                                                            <span className='text-danger fw-light h6 kiosk-text-card'>
                                                                <span className='px-2 ' translate='no'>- $</span>
                                                                {orderData.discount.toFixed(2)}
                                                            </span>
                                                        </div>

                                                        <div className="d-flex justify-content-end mt-3 kiosk-text-card">
                                                            <span className="text-gray-dark fw-light h6 kiosk-text-card"> Subtotal: </span>
                                                            <span className='text-black fw-light h6 kiosk-text-card'>
                                                                <span className='px-2 ' translate='no'>$</span>
                                                                {orderData.subtotal.toFixed(2)}
                                                            </span>
                                                        </div>


                                                        {orderData.taxes.map((tax) => (
                                                            <>
                                                                {tax.total > 0 ? <>
                                                                    <div className="d-flex justify-content-end mt-3 kiosk-text-card">
                                                                        <span className="text-gray-dark fw-light h6 kiosk-text-card"> Impuesto {tax.tax}: </span>
                                                                        <span className='text-black fw-light h6 kiosk-text-card'>
                                                                            <span className='px-2 ' translate='no'>$</span>
                                                                            {tax.total.toFixed(2)}
                                                                        </span>
                                                                    </div></> : <></>}
                                                            </>
                                                        ))}

                                                        <div className="d-flex justify-content-end mt-3 kiosk-text-card">
                                                            <strong className="text-black h5 kiosk-delivery_title"> Total: </strong>
                                                            <span className='text-black fw-bold h5 kiosk-delivery_title'>
                                                                <span className='px-2 ' translate='no'>$ </span>
                                                                {orderData.total.toFixed(2)}
                                                            </span>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                    <div className='d-flex position-absolute start-0 bottom-0 container-button'>
                        <button
                            type="button"
                            onClick={() => (setShowMOdalOrderDetails(false))}
                            className={`m-0 btn-lg  shadow-lg border-0
            button-ft_1 position-relative
            ${isButtonFocusedId === 'exit' ? 'focused' : ''}`}
                            onFocus={() => handleButtonFocus('exit')}
                            onBlur={handleButtonBlur}
                            title='Regresar'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                className=""
                                id="left-arrow">
                                <path
                                    d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

                            <div className="position-absolute postition-icon-md">
                                {isButtonFocusedId === 'exit' && <img src={icon} alt="Hand" className='icon-focus' />}
                            </div>


                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ModalOrderDetails