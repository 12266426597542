import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
const SmartpayModal = ({ button_text, modal_size, modal_header_text, modal_body, submit, handleFieldChange  }) => {
    //States
    const [showModal, setShowModal] = useState(false)

    //Modal body form 
    const modalBodyForm = () => {
        const form = <Form onSubmit={submit}className="needs-validation">
            {modal_body.map((field, index) => (
                <Form.Group key={index} className="mb-3">
                    <Form.Label htmlFor={field.name}>{field.label}</Form.Label>
                    {field.type === 'select' ?
                        <>
                            <Form.Select
                                id={field.name}
                                name={field.name}
                                onChange={(e) => handleFieldChange(field.name, e.target.value)}
                                defaultValue={field.defaultValue}
                                required={field.required}>
                                {field.options.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </>
                        :
                        <Form.Control
                            type={field.type}
                            id={field.name}
                            name={field.name}
                            placeholder={field.placeholder}
                            onChange={(e) => handleFieldChange(field.name, e.target.value)}
                            required={field.required}
                        />
                    }

                </Form.Group>
            ))}
            <div className="mt-3 d-flex justify-content-end">
                <Button size="sm" variant="success" type="submit">
                    Guardar
                </Button>
            </div>
        </Form>
        return form
    }
    return (
        <>
            {/**    Button of the modal */}
            <div>
                <button
                    className='btn btn-primary btn-sm border-0'
                    onClick={() => (setShowModal(true))}
                >
                    <i className="uil uil-plus-circle"></i> {button_text}
                </button>
            </div>
            {/** Modal content */}
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size={modal_size}
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        {modal_header_text} </h3>
                </Modal.Header>
                <Modal.Body>

                    {modalBodyForm()}

                </Modal.Body>
                <Modal.Footer>
                <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default SmartpayModal