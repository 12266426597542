//React Components
import React, { useState, useEffect, useMemo } from 'react';
//Components 
import { Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import Swal from 'sweetalert2';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
//API
import { GroupsFechtAPI } from '../../api/Groups';
import ModalImport from './ModalImport';
import ModalCreateGroup from './ModalGroups/ModalCreateGroup';
import ModalEditGroup from './ModalGroups/ModalEditGroup';
import ModalViewGroup from './ModalGroups/ModalViewGroup';
import ModalListSegmentByGroup from './ModalGroups/ModalListSegmentByGroup';
import ModalAsinAndRemovedgGroupToBranchoffice from './ModalGroups/ModalAsingAndRemovedGroupToBranchoffice';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';

const ProductTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    const [products, setProducts] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');



    useEffect(() => {
        // We load the products list 
        loadProducts();
    }, [eventHandler]);

    //Function to load products
    const loadProducts = async () => {

        try {
            //We search the groups of the branchoffice 
            const groups = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice_id, token);

            console.log(groups.data.data);
            setProducts(groups.data.data)
            setSearchedProducts(groups.data.data)
        } catch (err) {
            setProducts([])
            setSearchedProducts([])
        }

    };

    const beforeDelete = async (group_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar este producto de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteGroupBYbranchoffice(group_id);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino el producto',
                    'error'
                )
            }
        })
    }


    const deleteGroupBYbranchoffice = async (group_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            group_id: group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteProductbybranchoffice(branchoffice_id, data, token)
            loadProducts()
            ErrorAlert('', "Producto removido con exito", "success")
            console.log(response)
        } catch (err) {
            ErrorAlert(err.data.response.data, "Error, no se pudo remover el producto", "error")
            console.log(err)
        }
    }

    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return products
        }
        return searchedProducts.filter(product => {
            const nameMatch = product.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = product.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, products]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked,
            group_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await GroupsFechtAPI.updateGroupBranchoffice(branchoffice_id, data, token);
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los productos al cambiar el estado del switch
            loadProducts();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los productos al cambiar el estado del switch
            loadProducts();
        }
    };

    const viewProduct = () => {

        const list = filteredProducts.map(product => (
            <Col sm="2" md="6" lg="4" xl="4" >
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={product.img}
                            alt={product.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{product.name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                                {/*We check if user have enable disable products  */}
                                <RenderIf isTrue={checkUserPermission('enable_disable_product', user.permissions) !== -1}>
                                    <Form.Switch type="switch" id={product.group_id} label="" checked={product.status === 1 ? true : false} onChange={handleSwitchChange} />
                                </RenderIf>
                                {product.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>

                            <div className="">Precio: <b>${parseFloat(product.price).toFixed(2)}</b></div>
                            <div className="">Impuesto: <b>{product.tax}</b></div>
                            <p className=''>{product.description}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className='m-1'><ModalViewGroup product={product} loadProducts={loadProducts}></ModalViewGroup></div>
                                {/*We check if the user has permition to update products  */}
                                <RenderIf isTrue={checkUserPermission('update_product', user.permissions) !== -1}>
                                    <div className='m-1'><ModalEditGroup product={product} loadProducts={loadProducts}></ModalEditGroup></div>
                                </RenderIf>
                                {/**We check if the user has permition to asing products to segment */}
                                <RenderIf isTrue={checkUserPermission('asing_segment_product', user.permissions) !== -1}>
                                    <div className='m-1'><ModalListSegmentByGroup product={product}></ModalListSegmentByGroup></div>
                                </RenderIf>

                                {/**We check if the user has permition to delete products */}
                                <div className='m-1'>
                                    <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_product', user.permissions) !== -1}>
                                        <button className='btn btn-outline-danger btn-sm border-0'
                                            onClick={async () => beforeDelete(product.group_id)}
                                        >
                                            <i className="uil uil-trash-alt"></i>
                                        </button>
                                    </RenderIf>
                                </div>
                            </div>
                        </div>


                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }
    return (
        <>
            <div className='board rounded shadow-sm p-sm-1 p-lg-4'>

                <div className="row bg-light rounded py-3 m-1 border border-success border-opacity-10">
                    <div className='col-md-12'>
                        <h4 className='fs-4 fw-bold text-center'>{title}</h4>
                        <p className=' text-center'>Cantidad productos: {products.length}</p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-item-center  p-2">
                        <div className="w-auto  d-flex justify-content-end ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto ">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese producto"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />
                                </div>
                            </form>

                        </div>

                    </div>
                    <div className="col-md-6 p-2">
                        <div className='d-flex justify-content-center align-item-center'>
                            {/**Check user permition to create products */}
                            <RenderIf isTrue={checkUserPermission('create_product', user.permissions) !== -1}>
                                <div className=''><ModalCreateGroup loadProducts={loadProducts}></ModalCreateGroup></div>
                            </RenderIf>
                            {/**Check user permition to import products */}
                            <RenderIf isTrue={checkUserPermission('create_product', user.permissions) !== -1}>
                                <div className=''><ModalImport title={"producto"} load={loadProducts}></ModalImport></div>
                            </RenderIf>
                            {/**Check user permition to asing products to branchoffice */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_product', user.permissions) !== -1}>
                                <div className=''><ModalAsinAndRemovedgGroupToBranchoffice branchoffice_id={branchoffice_id} load={loadProducts} productsBranchoffice={products}></ModalAsinAndRemovedgGroupToBranchoffice></div>
                            </RenderIf>
                        </div>

                    </div>
                </div>


                <div className=" mt-3">
                    <Container>
                        <Row>
                            {viewProduct()}
                        </Row>
                    </Container>
                </div>
            </div>
        </>

    );
};

export default ProductTable;
