import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class ItemsFechtAPI {

    
    //Function to create item
    static async createSingleItem(data, token) {
        const res = await server(`/item`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token},
        });
        return res
    }

    //Function to get the items by branchoffice 
    static async getItemsByBranchoffice(branchoffice_id, token) {
        const res = await server(`/item/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    //Function to update a item status of a branchoffice 
    static async updateItemBranchoffice(branchoffice_id, data, token){
        const res = await server(`/item/${branchoffice_id}/branchoffice`, 
        {
            method: "put",
            data: {
                "item_id": data.item_id,
                "status": data.status, 
                "user_id": data.user_id
            },
            headers: {'x-access-token': token},
        });
        return res
    }

     //Function to update a item status of a branchoffice 
     static async updateItem(item_id, data, token){
        const res = await server(`/item/${item_id}`, 
        {
            method: "put",
            data: data,
            headers: {'Content-Type': 'multipart/form-data', 'x-access-token': token},
        });
        return res
    }

    
    //Function to get items to business
    static async getItemsbYBusiness(business_id, token){
        const res = await server(`/item/${business_id}/business`, 
        {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }


    //Function to get segments by item
    static async getSegmentsByItem(item_id, token){
        const res = await server(`/item_per_segment/${item_id}/item`, 
        {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }


     //Function to update  or modification item in segment asigned
     static async updataItemTosegment(item_id, data, token){
        const res = await server(`/item/${item_id}`, 
        {
            method: "put",
            data: data,
            headers: {'x-access-token': token},
        });
        return res
    }

    //Function to delete item to business
    static async deleteItemToBusiness(business_id, data, token){
        const res = await server(`/item/${business_id}/business`, 
        {
            method: "delete",
            data: data,
            headers: {'x-access-token': token},
        });
        return res
    }

    //Function to delete item to branchoffice
    static async deleteItemToBranchoffice(branchoffice_id, data, token){
        const res = await server(`/item/${branchoffice_id}/branchoffice`, 
        {
            method: "delete",
            data: data,
            headers: {'x-access-token': token},
        });
        return res
    }

      //Function to asing item to branchoffice
      static async asingItemToBranchoffice(branchoffice_id, data, token){
        const res = await server(`/item/${branchoffice_id}/branchoffice`, 
        {
            method: "post",
            data: data,
            headers: {'x-access-token': token},
        });
        return res
    }


    


}