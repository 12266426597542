import React from 'react'

import KdsMain from '../layouts/Kds/KdsMain'

function Kds() {
  return (
    < KdsMain>    
    </ KdsMain>
  )
}

export default Kds