//Components
import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
//Utils
import { RenderIf } from './utils/RenderIf';
import Socket from './utils/Socket.config';
//PDF
import { PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from './Pdf/DocPdf';
//Icons
import { BsCheckLg } from "react-icons/bs";
//API
import { OrdersFetchAPI } from '../api/Orders';
import { BusinessFetchAPI } from '../api/Business';
import { DgiFetchAPI } from '../api/Dgi';
import { BranchOfficeFetchAPI } from '../api/BranchOffices';
//img
import logo_sp from './img/logos/logo.png';

const Orders = () => {

  //State for show modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formatVoucher, setformatVoucher] = useState("");
  const [modalShowVoucher, setmodalShowVoucher] = useState(false)
  //State order data 
  const { order_code, branchoffice_id } = useParams();
  const [eventHandler, setEventHandler] = useState(0);
  const [orderData, setOrderData] = useState({
    createdAt: "2023-07-25T10:02:35.000+00:00",
    cufe: "0",
    dgi_qr: "0"

  });
  const [cardsItems, setCardItems] = useState([]);
  const [listOrderHistroy, setOrderHistory] = useState([]);
  const [businessData, setBusinessData] = useState({
    name: ''
  });
  const [branchOfficeData, setBranchofficeData] = useState({
    "ruc": "",
    "dv": "",
    "name": "",
    "ruc_type": "",
    "direction": "",
    "dgi_name": "",
  });


  //Total taxes 
  const [totalTaxes, setTotalTaxes] = useState([
    { total_base: 0, tax: "0%", total_tax: 0 },
    { total_base: 0, tax: "7%", total_tax: 0 },
    { total_base: 0, tax: "10%", total_tax: 0 },
    { total_base: 0, tax: "15%", total_tax: 0 }
  ]);

  //Function to get order data 
  const getOrderByCode = async (code, branchoffice_id) => {
    try {
      const response = await OrdersFetchAPI.getOrderByCode(code, true, branchoffice_id);

      console.log(response);
      return response;


    } catch (err) {
      console.log(err);
    }
  }

  // function convert format voucher
  const formatVoucherString = () => {
    let voucher = orderData.voucher_response
    // Extraer el número de tarjeta ocultando los dígitos intermedios
    const maskedVoucher = voucher.replace(/(\d{6})\d+(\d{4})/g, "$1XXXXXX$2");

    // Construir el string formateado del voucher
    const formattedVoucher = `${maskedVoucher}`;
    setformatVoucher(formattedVoucher)
    //return formattedVoucher;
  };

  const handleViewCoucher = () => {
    formatVoucherString()
    setmodalShowVoucher(true)
  }

  //We get the page data 
  useEffect(() => {
    (async () => {
      try {
        const response = await getOrderByCode(order_code, branchoffice_id);
        console.log(response);
        setOrderData(response.data.order);
        setCardItems(response.data.order.groups);
        setOrderHistory(response.data.order.status_update_history);
        //We get the business
        const response_business = await BusinessFetchAPI.getBusinessByCode(response.data.order.business.code)
        //We set the business data 
        setBusinessData(response_business.data.business)
        //We get the branchoffice info 
        const response_branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(response.data.order.business.code, response.data.order.branchoffice.code);
        //We set the business data 
        setBranchofficeData(response_branch.data.branchoffice);
        try {
          //We get the dgi info 
          const dgi_info_branch = await DgiFetchAPI.checkDGIContribuyent(response_branch.data.branchoffice.ruc);
          console.log(dgi_info_branch)
          //setBranchofficeData( {dgi_name: dgi_info_branch.data.dgi_response.dNomb}, ...branchOfficeData)
        } catch (err) {
          console.log(err)
        }

      } catch (err) {
        console.log(err)
      }



    })()
  }, []);

  //We get the socket 
  useEffect(() => {
    //Connection with the sockets server 
    const socket = Socket({ "branchoffice_id": branchoffice_id, "order_code": order_code });

    //We update the estate of the order depending of the signal of the order 
    socket.on(`branchoffice_${branchoffice_id}_orderUpdated_${order_code}`, (updatedOrder) => {
      console.log(updatedOrder)
      //We set orders history 
      setOrderHistory(updatedOrder.status_update_history);
      //We refresh
      setEventHandler(1)
    })

    // When the component is over, we  disconect the client of the socket 
    return () => {
      socket.disconnect();
    };
  }, [order_code])


  useEffect(() => {
    //We set orders history 
    setOrderHistory(listOrderHistroy);
    setEventHandler(0)
  }, [eventHandler])


  //We charge the items of the order
  const orderItems = () => {
    let items = cardsItems.map((options) => (
      <div className="d-flex text-muted pt-3 px-xs-2" key={options._id}>
        <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={options.img} width="42" height="42" />
        <div className="pb-3 mb-0  lh-sm  w-100">
          <div className="d-flex justify-content-between ">
            {/* Name */}
            <span className="text-dark fw-bold">{options.name}</span>
            {/* Cantidad/ Quantity*/}
            <div>
              <span className='px-3'>x{options.amount} </span>
              <label className="fw-bold" >{options.price.toFixed(2)}</label>
            </div>

          </div>
          {/** Segment items */}
          <>
            <div className="d-flex justify-content-between">
              {/* Name */}
              <span className="">Descuento</span>
              {/* Cantidad/ Quantity*/}
              <div>
                <label className={options.discount === undefined ? "" : "text-danger"}>{options.discount === undefined ? "No registrado" : "- $" + options.discount.toFixed(2)}</label>
              </div>
            </div>
            {options.segments.map((segments) => (
              <div className="d-flex justify-content-between">
                {/* item name */}
                <span className="">{segments.item.name}</span>
              </div>
            ))}
          </>
        </div>
      </div>
    ))
    return <> {items}</>
  }

  //We charge the history update 
  const orderHistory = () => {
    let key_id = 0;
    let history = listOrderHistroy.map((history) => (
      <li key={key_id++} className="timeline-item mb-5">
        <span className="timeline-icon">
          <BsCheckLg />
        </span>

        <h5 className="fw-bold">{history.status}</h5>
        <p className="text-muted mb-2 fw-bold">{history.update_date}</p>
        <p className="text-muted">
          {
            history.state && history.state === 1 ? 'Su orden ha sido confirmada para ser preparada' :
              history.state && history.state === 2 ? 'Su orden esta en preparación' :
                history.state && history.state === 3 ? 'Su orden esta lista para ser entregada' :
                  'Su orden ya fue entregada'
          }
        </p>
      </li>
    ));
    return <>{history}</>
  }
  return (
    <>
      <header>
        <div className='d-flex justify-content-center'>
          <img src={logo_sp} className="" width="150" alt="Logo Smart Pay " />
        </div>
      </header>
      <main className='main-invoice-sp'>
        <Container className='mb-2 '>
          <div className='d-flex justify-content-between py-1'>
            <h1 className='h2'>Tú Orden </h1>

            <div>
              <h5 className='border p-2 rounded shadow-sm'>
                <span className="text-secondary">Notificador: </span>
                <span className="text-success"># {orderData.locator}</span>
              </h5>

            </div>
            <div>

              <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
                doc_type={orderData.invoice_type === "devolution" ? "credit_note" : "invoice"} invoiceQR={orderData.dgi_qr} invoiceDate={orderData.createdAt} cufe={orderData.cufe} auth_protocol={orderData.auth_protocol ? orderData.auth_protocol : "00000000"}
                receptor={{
                  name: orderData.client_name,
                  ruc: orderData.client_ruc,
                  direction: "Panama",
                  dv: orderData.client_dv,
                }}
                emitter={branchOfficeData}
                products={cardsItems} invoiceSubtotal={orderData.subtotal} totalTaxes={totalTaxes}
                totalCashReceived={orderData.total} invoiceTotal={orderData.total} invoiceTotalItbms={orderData.tax} paymentMethod={orderData.paymenthod}


              />} >
                <button className='btn btn-secondary-sp border border-sp' > {orderData.invoice_type === "devolution" ? "Descargar nota de credito" : "Descargar factura electronica"} </button>
              </PDFDownloadLink>
            </div>
            {/* Modal Table numb */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Número de mesa </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="justify-content-md-center">
                    <Col md="auto">
                      <Form.Control className='w-100' placeholder="Ingrese el Número" />
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}>
                  Cerrar
                </Button>
                <Button variant="success" onClick={handleClose}>
                  Guardar
                </Button>
              </Modal.Footer>
            </Modal>

          </div>

          {/* Your card */}
          <Row>
            <Col sm={12} md={6}  >
              <div className="py-4 px-lg-4 zigzag ">
                <div className="head-section-sp mt-2">
                  <div className="">
                    <div className="mt-1 mb-3">
                      <div className="d-flex justify-content-between align-items-center mb-3 ">
                        <div className='header-invoice'>
                          <div>
                            <h4 className='bussines-donlee'>{businessData.name}</h4>
                          </div>
                        </div>

                        <h5>
                          <span className="text-success">Orden: </span>
                          <span className=""> #{orderData.transaction_code}</span>
                        </h5>

                      </div>
                    </div>
                  </div>


                  <div className=' d-flex justify-content-between text-secondary opacity-75'>
                    <div> Productos </div>
                    <div> Precio </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 order-md-last">
                      {/* Item cards */}
                      {orderItems()}
                      <div className="d-flex text-muted pt-3">

                        <div className="pb-3 mb-0 small lh-sm border-top w-100">
                          <div className="d-flex justify-content-end mt-3">
                            <span className="text-gray-dark fw-light h6"> Descuento: </span>
                            <span className='text-danger fw-light h6'>
                              <span className='px-2 '>- $</span>
                              {typeof orderData.subtotal === 'number' ? orderData.discount.toFixed(2) : orderData.discount}
                            </span>
                          </div>
                          <div className="d-flex justify-content-end mt-3">
                            <span className="text-gray-dark fw-light h6"> Subtotal: </span>
                            <span className='text-black fw-light h6'>
                              <span className='px-2 '>$</span>
                              {typeof orderData.subtotal === 'number' ? orderData.subtotal.toFixed(2) : orderData.subtotal}
                            </span>
                          </div>
                          <div className="d-flex justify-content-end mt-3">
                            <span className="text-gray-dark fw-light h6"> Itbms: </span>
                            <span className='text-black fw-light h6'>
                              <span className='px-2 '>$</span>
                              {typeof orderData.tax === 'number' ? orderData.tax.toFixed(2) : orderData.tax}</span>
                          </div>
                          <div className="d-flex justify-content-end mt-3">
                            <strong className="text-black h5"> Total: </strong>
                            <span className='text-black fw-bold h5'>
                              <span className='px-2 '>$</span>
                              {typeof orderData.total === 'number' ? orderData.total.toFixed(2) : orderData.total}</span>
                          </div>

                          <RenderIf isTrue={orderData.bank_voucher_response != "0"}>
                            <div className='d-flex justify-content-end mt-3'>
                              <Button className='m-2' onClick={() => (handleViewCoucher())}><i className="uil uil-receipt-alt"></i> Voucher de transaccion</Button>
                            </div>
                          </RenderIf>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* Orders Status*/}
            <Col xs={10} md={{ span: 5, offset: 1 }} >
              <section className="py-5 ps-3 ">
                <h3 className='text-center'>Estado de la orden</h3>
                <hr className="hr" />
                <ul className="timeline-with-icons">
                  {orderHistory()}

                </ul>
              </section>
            </Col>
          </Row>
        </Container>

        <Modal
          show={modalShowVoucher}
          onHide={() => setmodalShowVoucher(false)}
          centered>
          <Modal.Header>
            Voucher de transaccion: #{orderData.transaction_code}
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex alight-item-center justify-content-center'>
              <div>
                <pre>{formatVoucher}</pre>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => (setmodalShowVoucher(false))}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </main >
    </>
  )
}

export default Orders