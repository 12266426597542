import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class ExcelModuleFechtAPI {

    //Function to upload Group
    static async UploadBusinessGroup(business_id, data, token) {
        const res = await server(`/excel/upload/products/${business_id}/business`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data',  'x-access-token': token },
        });
        return res
    }

    //Function to get template Group
    static async DownloadTemplateGroups() {
        const res = await server(`/excel/download/excel/product/template`, {
            method: "get",
        });
        return res
    }

    //Function to upload segments
    static async UploadBusinessSegments(business_id, data, token) {
        const res = await server(`/excel/upload/segments/${business_id}/business`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data',  'x-access-token': token },
        });
        return res
    }


    //Function to get template segment
    static async DownloadTemplateSegments(business_id) {
        const res = await server(`/excel/download/excel/segment/template`, {
            method: "get",
        });
        return res
    }


    
    //Function to upload item
    static async UploadBusinessItem(business_id, data, token) {
        const res = await server(`/excel/upload/items/${business_id}/business`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

        //Function to get template item
        static async DownloadTemplateItem(business_id) {
            const res = await server(`/excel/download/excel/item/template`, {
                method: "get",
            });
            return res
        }
    


}