import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

//Components
import KdsList from './KdsList'
import KdsView from './KdsView'
import { Form } from 'react-bootstrap';

//Modules 
import moment from 'moment-timezone';
//Utils 
import Socket from '../../components/utils/Socket.config';
//Api 
import { OrdersFetchAPI } from '../../api/Orders';
import { MachinesFetchAPI } from '../../api/Machines';
//IMG
import logo from '../../assets/img/logos/icon-black.png'
//css
import './KdsMain.css'

const KdsMain = () => {
   //Params 
   const navigate = useNavigate();
   let { business_code, branchoffice_code } = useParams();
   let token = localStorage.getItem('x-access-machine-token');
   //States
   const [orders, setOrders] = useState([]);
   const [historicOrders, setHistoricOrders] = useState([]);
   const [machineData, setMachineData] = useState({
      code: "0",
      categories_available: []
   })
   //We get the actual date 
   const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')
   //We set the order states
   const [selectedOrder, setSelectedOrder] = useState({
      transaction_code: 0,
      createdAt: `${actualDate}T23:59:59.000+00:00`,
      order_type: '',
      groups: [],
      data_selected: false
   });
   const [eventHandler, setEventHandler] = useState(0)


   //Hook to make the initial config of the component 
   useEffect(() => {
      // Connection with the sockets server
      const socket = Socket({ "branchoffice_code": branchoffice_code, "business_code": business_code })
      // Update of the data when the server send the signal of new order for this branchoffice 
      socket.on(`branchofficeOrder_${branchoffice_code}`, (newOrder) => {
         let machine_info = localStorage.getItem('machine');
         let machine = JSON.parse(machine_info)
         //We return the products avilable of the orders depending of the machine categories asigned 
         const groupsWithAvailableCategories = getGroupsWithAvailableCategories(newOrder.groups, machine.categories_available);
         //We check if the some groups of the order have available categories 
         if (groupsWithAvailableCategories.length !== 0) {
            //We put just the groups availables depending of the category 
            newOrder.groups = groupsWithAvailableCategories
         }
         setOrders((prevOrders) => [newOrder, ...prevOrders]);
         setEventHandler(1);
      });

      // We update the data of the order that was updated 
      socket.on(`branchoffice_updateOrder_${branchoffice_code}`, (updatedOrder) => {
         setOrders((prevOrders) => {
            let machine_info = localStorage.getItem('machine');
            let machine = JSON.parse(machine_info)
            //We return the products avilable of the orders depending of the machine categories asigned 
            const groupsWithAvailableCategories = getGroupsWithAvailableCategories(updatedOrder.groups, machine.categories_available);
            //We check if the some groups of the order have available categories 
            if (groupsWithAvailableCategories.length !== 0) {
               //We put just the groups availables depending of the category 
               updatedOrder.groups = groupsWithAvailableCategories
               //We get the order position 
               const order_index = prevOrders.findIndex(order => order._id === updatedOrder._id);

               //We check if some group has the status done or superior 
               const order_groups = getGroupsWithSpecificStatus(updatedOrder.groups, 3)
               //We check if the order group segments are all updated 
               const order_groups_segments = areAllGroupsSegmentsUpdated(updatedOrder.updated_groups)
               // Filter segments in each group for available categories
               updatedOrder.groups.forEach(group => {
                  group.segments = getItemsWithAvailableCategories(group.segments, machine.categories_available);
               });
               //We check if the order updated is done or the products that are showed on the screen are done 
               if ((updatedOrder.status_id >= 3) || (order_groups.length > 0 && updatedOrder.updated_groups.length === order_groups.length) || (order_groups_segments === true)) {
                  //We delete the order on the orders and then we set this order on the history orders
                  prevOrders.splice(order_index, 1);
                  //We set the order updated to the historic 
                  setHistoricOrders((prevHistoryOrders) => [updatedOrder, ...prevHistoryOrders]);
               } else {
                  //We clean and update the order position 
                  prevOrders[order_index] = updatedOrder;
               }
            }
            //We return to set the orders uopdated 
            return prevOrders;
         });
         //We refresh 
         setEventHandler(1)
      });

      // We update the categories availables 
      socket.on(`asignedCategory`, (category_machine_asigned) => {
         let machine_info = localStorage.getItem('machine');
         let machine = JSON.parse(machine_info)
         //We check if the removed category is from this machine 
         if (machine.id === category_machine_asigned.branch_machine_id) {
            //If the machine is we get the index of the category on the info data to delete it 
            let index = machine.categories_available.findIndex(category => category.category_id === category_machine_asigned.category_id);
            //If we dont find the category asigned we asing it to the machine data 
            if (index === -1) {
               machine.categories_available.push({
                  "branch_machine_id": category_machine_asigned.branch_machine_id,
                  "business_id": category_machine_asigned.business_id,
                  "category_id": category_machine_asigned.category_id,
                  "machine_categorie_status": 1,
                  "img": category_machine_asigned.category_img,
                  "name": category_machine_asigned.category_name
               })
               //We set again the machine info 
               localStorage.setItem('machine', JSON.stringify(machine))
               setMachineData(machine)
               //We update the data 
               getBranchofficeOrders()
            }
         }
      });

      // We update the categories availables 
      socket.on(`removeCategory`, (category_machine_removed) => {
         let machine_info = localStorage.getItem('machine');
         let machine = JSON.parse(machine_info)
         //We check if the removed category is from this machine 
         if (machine.id === category_machine_removed.branch_machine_id) {
            //If the machine is we get the index of the category on the info data to delete it 
            let index = machine.categories_available.findIndex(category => category.category_id === category_machine_removed.category_id);
            //We delete the category of the machine 
            machine.categories_available.splice(index, 1);
            //We set again the machine info 
            localStorage.setItem('machine', JSON.stringify(machine))
            setMachineData(machine)
            //We update the data 
            getBranchofficeOrders()
         }
      });
      // When the component is over, we  disconect the client of the socket 
      return () => {
         socket.disconnect();
      };
   }, [branchoffice_code]);

   // Function to get the branchoffice orders 
   const getBranchofficeOrders = async () => {
      let pending_orders = [];
      let finished_orders = [];
      try {
         let machine_info = localStorage.getItem('machine');
         //Validation of machine logged 
         if (machine_info !== "" || machine_info !== undefined) {
            let machine = JSON.parse(machine_info)
            //We get the orders 
            const response = await OrdersFetchAPI.getOrderByStatusBusinessBranchOrderTypeDate(['comer aqui', 'para llevar', 'mixto'], business_code, branchoffice_code, [1, 2, 3], `${actualDate}`, `${actualDate}T23:59:59.000+00:00`, token);
            //We filter the response 
            response.data.orders.filter((order) => {
               //We return the products avilable of the orders depending of the machine categories asigned 
               const groupsWithAvailableCategories = getGroupsWithAvailableCategories(order.groups, machine.categories_available);
               // Filter segments in each group for available categories
               order.groups.forEach(group => {
                  group.segments = getItemsWithAvailableCategories(group.segments, machine.categories_available);
               });
               //We check if the order group segments are all updated 
               const order_groups_segments = areAllGroupsSegmentsUpdated(order.groups)
               //We check if the some groups of the order have available categories 
               if (groupsWithAvailableCategories.length !== 0) {
                  //We put just the groups availables depending of the category 
                  order.groups = groupsWithAvailableCategories
                  //We check if some group has the status done or superior 
                  const order_groups = getGroupsWithSpecificStatus(order.groups, 3)
                  //We check the order status 
                  if (order.status_id >= 3 || (order_groups.length > 0 && order.groups.length === order_groups.length) || order_groups_segments === true) {
                     finished_orders.push(order)
                  } else if (order.status_id === 1 || order.status_id === 2) {
                     pending_orders.push(order)
                  }
               }
            });
            //We set the orders depending the status returned 
            setOrders(pending_orders);
            setHistoricOrders(finished_orders);
         }
         setEventHandler(1)
      } catch (error) {
         console.error('Error al obtener las órdenes:', error);
      }
   };

   // Function to check if the category is available 
   const isCategoryAvailable = (categoryId, categories_available) => {
      return categories_available.some(category => category.category_id === categoryId && (category.machine_categorie_status === 1 || category.machine_categorie_status === true));
   }

   // Function to get the groups that have categories available
   const getGroupsWithAvailableCategories = (groups, categories_available) => {
      return groups.filter(group => {
         return group.categories.some(group_category => isCategoryAvailable(group_category.id, categories_available));
      });
   }

   // Function to get groups that have some specific status 
   const getGroupsWithSpecificStatus = (groups, status) => {
      return groups.filter(group => { return group.status >= status })
   }

   // Function to check if all groups and segments are updated
   const areAllGroupsSegmentsUpdated = (groups) => {
      return groups.every(group => {
         const areAllSegmentsUpdated = group.segments.every(segment => segment.item.status >= 3);
         return areAllSegmentsUpdated;
      });
   }

   // Function to get items/segments that have categories available  
   const getItemsWithAvailableCategories = (segments, categories_available) => {
      return segments.filter(segment => {
         return segment.item.categories.some(item_category => isCategoryAvailable(item_category.id, categories_available))
      })
   }
   //Function to logout 
   const singout = async () => {
      let machine_token = localStorage.getItem('x-access-machine-token');
      //We delete the data 
      localStorage.setItem('x-access-token', '');
      localStorage.setItem('x-access-machine-token', '');
      localStorage.setItem('machine', '');
      localStorage.setItem('branchoffice', '');
      //We logout the machine 
      try {
         await MachinesFetchAPI.updateMachineStatus(machineData.code, false, machine_token)
      } catch (err) {
         console.log(err)
      }
      //We redirect to the login of the comanda machine
      navigate(`/comanda/login/${business_code}`);
   }

   //Function to update order status 
   const updateOrderState = async (order_id, groups, state) => {
      try {
         await OrdersFetchAPI.updateOrderStatus(state, order_id, groups, token)
         setEventHandler(1)
      } catch (err) {
         console.log(err)
      }
   }

   //Function to update machine category status 
   const updateMachineCategory = async (machine_id, category_id, status) => {
      try {

         //We update on the dabatase the machine category status
         await MachinesFetchAPI.updateMachineCategory(machine_id, category_id, status, token)
         //We search on the machine data the category that we are updating 
         let index = machineData.categories_available.findIndex(element => element.category_id === category_id && element.branch_machine_id === machine_id);
         //We set the category available updated on the machine data
         let categories_available = machineData.categories_available;
         categories_available[index].machine_categorie_status = status;
         //We set the info 
         setMachineData({ categories_available: categories_available, ...machineData })
         localStorage.setItem('machine', JSON.stringify(machineData))
         //We update the data 
         getBranchofficeOrders()
      } catch (err) {
         console.log(err)
      }
   }

   //Function to get the categories of a machine 
   /*    const getMachineCategories = async (machine_id, token) => {
         try {
            const response = await MachinesFetchAPI.getMachineCategories(machine_id, token)
            setMachineData({ categories_available: response.data.categories, ...machineData })
         } catch (err) {
            console.log(err)
            setMachineData({ categories_available: [], ...machineData })
         }
      } */

   //Function to list machine categories 
   const machineCategoriesList = () => {
      let list = machineData.categories_available.map((categorie) => (
         <>
            <li>
               <Form.Switch
                  type="switch"
                  id={categorie.id}
                  label=""
                  defaultChecked={categorie.machine_categorie_status === 1 || categorie.machine_categorie_status === true ? true : false}
                  onChange={event => updateMachineCategory(machineData.id, categorie.category_id, event.target.checked)}
               />
               <a className="dropdown-item" href="#"><i className="uil uil-robot"></i> Categorias: {categorie.name}</a></li>
            <li><hr className="dropdown-divider" /></li>
         </>
      ))
      return <>{list}</>
   }

   //Hook to refresh orders data 
   useEffect(() => {
      setOrders(orders)
      setEventHandler(0)
   }, [eventHandler]);

   //Hook to get the initial data 
   useEffect(() => {
      let machine_info = localStorage.getItem('machine');
      //Validation of machine logged 
      if (machine_info !== "" || machine_info !== undefined) {
         setMachineData(JSON.parse(machine_info));
      }
      getBranchofficeOrders();
      setEventHandler(0)
   }, [branchoffice_code]);


   //Animation View 
   const [isSelected, setIsSelected] = useState('Click');

   const AnimationClick = (isSelected) => {
      setIsSelected(isSelected);
   };

   return (
      <>
         <div className='kds-main'>
            <main className="container-parent ">
               <div className="container-left   no-scroll">
                  <header className="kds-header px-1  border-bottom w-100 bg-white">
                     <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a className="d-flex align-content-center align-items-center mb-2 mb-lg-0 text-dark text-decoration-none me-4">
                           <img src={logo} alt="Logo Smart Pay" className='logo' />
                           <span className='fw-bold mb-0 h5'> KDS</span>
                        </a>
                        <ul className="nav nav-pills me-lg-auto mb-2 justify-content-center mb-md-0" id="pills-tab" role="tablist">
                           <li className="nav-item" role="presentation">
                              <button className="nav-link active"
                                 id="tab-orders-tab" data-bs-toggle="pill"
                                 data-bs-target="#tab-orders" type="button"
                                 role="tab" aria-controls="tab-orders"
                                 aria-selected="true">Ordenes Actuales </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button className="nav-link" id="tab-orders-history-tab" data-bs-toggle="pill" data-bs-target="#tab-orders-history" type="button" role="tab" aria-controls="tab-orders-history" aria-selected="false">Historial de Ordenes</button>
                           </li>
                        </ul>

                        <div className="dropdown text-end">
                           <a href="#" className="link-dark text-decoration-none" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="tooltip-container rounded-circle ">
                                 <i className="uil uil-label-alt h4"></i>
                                 {/* <span className='fw-bold'>Categorias </span>                                */}
                                 {/* <div className="tooltip-one">
                                    Categorias
                                 </div> */}
                              </div>
                           </a>
                           <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                              <li className="dropdown-item border-bottom  fw-bold text-center">
                                 <i className="uil uil-label-alt"></i>Categorias
                              </li>
                              {machineCategoriesList()}
                           </ul>
                        </div>
                        <div className="dropdown text-end">
                           <a href="#" className="link-dark text-decoration-none " id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                              <div className="tooltip-container   rounded-circle">
                                 <i className="uil uil-setting h4 tooltip-trigger"></i>
                                 {/* <div className="tooltip-one">
                                    Perfil
                                 </div> */}
                              </div>
                           </a>
                           <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                              <li className="dropdown-item border-bottom  fw-bold text-center">
                                 <i className="uil uil-user"></i>Perfil
                              </li>
                              <li><a className="dropdown-item" href="#"><i className="uil uil-robot"></i> Máquina: {machineData.code}</a></li>
                              <li><hr className="dropdown-divider" /></li>
                              <li><a className="dropdown-item" onClick={singout} href="#"><i className="uil uil-signout"></i> Cerrar Sesion</a></li>
                           </ul>
                        </div>


                     </div>
                  </header>

                  <section className='kds-list-orders'>
                     <div className="tab-content mt-4 " id="pills-tabContent">
                        <div className="kds-tab tab-pane fade show active  vh-100 mx-3" id="tab-orders" role="tabpanel" aria-labelledby="tab-orders-tab">
                           <KdsList
                              updateOrderState={updateOrderState}
                              orders={orders}
                              setEventHandler={setEventHandler}
                              eventHandler={eventHandler}
                              selectedOrder={selectedOrder}
                              setSelectedOrder={setSelectedOrder}
                              token={token}

                              selectOrderAnm={AnimationClick}
                           />
                        </div>
                        <div className="tab-pane fade" id="tab-orders-history" role="tabpanel" aria-labelledby="tab-orders-history-tab">
                           <KdsList
                              updateOrderState={updateOrderState}
                              orders={historicOrders}
                              setEventHandler={setEventHandler}
                              eventHandler={eventHandler}
                              selectedOrder={selectedOrder}
                              setSelectedOrder={setSelectedOrder}
                              token={token}
                           />
                        </div>
                     </div>
                  </section>
               </div>
               <div className="container-right kds-view no-scroll  border-3 border-start bg-light">
                  <KdsView updateOrderState={updateOrderState} selectedOrder={selectedOrder} requestAnimation={isSelected} />
               </div>
            </main>
         </div>

      </>
   )
}

export default KdsMain