import React, { useState } from 'react'
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad';
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import { AuthFetchAPI } from '../../api/Auth';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import logoYappy from './assets/img/yappy.png'

function StepYappy({ icon, background, showModalKeyCode, setShowModalKeyCode, paymentInfo, requestPyament, nextStep, setAtivity, setPaymentInfo }) {
    //state of keypad number
    const [displayNumber, setDisplayNumber] = useState('');
    const [numberYappy, setNumberYappy] = useState("6000-0000")


    const [isButtonFocusedId, setButtonFocusId] = useState(null);

    const handleButtonFocus = (buttonId) => {
        setButtonFocusId(buttonId);
    };

    const handleButtonBlur = () => {
        setButtonFocusId(null);
    };
    //set code's of the sesion 
    const handleSaveCode = () => {

        setNumberYappy(displayNumber);
        setDisplayNumber('')//clear input of the keypad

        requestPyament(paymentInfo, displayNumber)
        setShowModalKeyCode(false)
        nextStep()
    }




    return (
        <>
            <Modal
                show={showModalKeyCode}
                fullscreen
                onHide={() => setShowModalKeyCode(false)}>
                <Modal.Body>
                    <main className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
                        <div className=''>
                            <header className='d-flex justify-content-center'>
                                <img src={logoYappy} alt="Logo" width="250" height="220" />
                            </header>
                            <h2 className='kiosk-delivery_title  text-center '>
                                ¡Hola! Ingrese el  número
                            </h2>


                            <section className='bd-highlight w-100'>
                                <NumericKeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} />
                            </section>
                            <section className='bd-highlight row'>
                                {/* <div className='col-md-6'>
                           <button type="button" className=" btn btn-lg btn-vending-secondary btn-vending w-100 text-dark" onClick={() => (handleClearAllData())}>
                           <i className="uil uil-arrow-right"></i> Limpiar 
                           </button>
                        </div> */}
                                {/* <div className='col-12'>
                                    <button type="button" className=" btn btn-lg btn-vending btn-vending-success w-100"
                                     onClick={() => (handleSaveCode())}>
                                    <i className="uil uil-arrow-right"></i> Siguiente
                                </button></div> */}
                            </section>
                        </div>
                    </main>
                </Modal.Body>
                {/* 

                <button className='btn btn-primary btn-vending-product mx-4 rounded' >
                    <i className="uil uil-arrow-left"></i>
                    Regresar
                </button> */}
                {/* 
                <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>

                    <button
                        type="button"
                        onClick={() => (setShowModalKeyCode(false))}
                        className="m-0 btn-lg  shadow-lg border-0 button-ft_1" title='Regresar' >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            className=""
                            id="left-arrow">
                            <path
                                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>
                    </button>
                    <div className="m-2 ">
                        <button 
                               onClick={() => (handleSaveCode())}            
                            className='btn btn-success kiosk-subtitle w-100 rounded-pill border px-5 py-3 h-100'
                        >

                            Siguiente</button>
                    </div>
                </div> */}


                <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
                  {/* 1 */}
                  <button
                     type="button"
                     onClick={() => (setShowModalKeyCode(false), setPaymentInfo({ payment: "", payment_id: 0 }))}
                     className={`m-0 btn-lg  shadow-lg border-0 button-ft_1
                   button-ft_1 position-relative
                  ${isButtonFocusedId === 'exit' ? 'focused' : ''}`}
                        onFocus={() => handleButtonFocus('exit')}
                        onBlur={handleButtonBlur}
                        title='Regresar'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            className=""
                            id="left-arrow">
                            <path
                                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

                        <div className="position-absolute postition-icon-md">
                            {isButtonFocusedId === 'exit' && <img src={icon} alt="Hand" className='icon-focus' />}
                        </div>
                    </button>
                    {/* 2 */}
                    <div className="m-2 ">
                        <button className={`btn btn-success kiosk-subtitle w-100 rounded-pill border px-5 py-3 h-100 position-relative           
                     ${isButtonFocusedId === 'send' ? 'focused' : ''}`}
                            onFocus={() => handleButtonFocus('send')}
                            onBlur={handleButtonBlur}

                            onClick={() => (handleSaveCode())}   >
                            Siguiente
                            <div className="position-absolute postition-icon-md">
                                {isButtonFocusedId === 'send' && <img src={icon} alt="Hand" className='icon-focus' />}
                            </div>
                        </button>
                    </div>
                </div>

            </Modal>

        </>
    )
}

export default StepYappy