import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';


function ModalCreateCategories({ loadCategories }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    {/*const submit = async (event) => {
        user = JSON.parse(localStorage.getItem('user'))
        event.preventDefault();
        const form = new FormData(event.target);
        form.append("business_id", user.business_id);
        form.append("branchoffice_id", null);
        let newCategorie = Object.fromEntries(form.entries());

        console.log(form)
        try {
            //We update the data 
            let response = await CategoriesDashFetchAPI.createCategorie(form);
            //We show the success alert 
            console.log(response)
            ErrorAlert('', 'Categoria creada con exito', 'success')
        } catch (err) {
            ErrorAlert('', 'Error de registro', 'error')
            console.log(err)
        }
    }*/}

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append("business_id", user.business_id);
        form.append("branchoffice_id", null);
        const createCategorie = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await CategoriesDashFetchAPI.createCategorie(createCategorie, token);
            //We show the success alert 

            ErrorAlert('', 'Categoria creada con exito', 'success')
            loadCategories();
            setShowModal(false);
        } catch (err) {
            ErrorAlert('', 'Error de registro', 'error')
            console.log(err)
        }
    }

    const getCategoryType = async () => {
        try {
            const response = await CategoriesDashFetchAPI.getCategoryType(token);
            setTypeCategory(response.data.categorie_types)
        } catch (err) {
            console.log(err)
        }
    }

    const viewTypeCategory = () => {
        const view = typeCategory.map((type) => (
            <><option value={type.id}>{type.name}</option></>
        ));

        return (view)
    }






    useEffect(() => {
        setEventHandle(0)

    }, [eventHandle])




    return (
        <>

            <div><button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModal(true), getCategoryType())} ><i className="uil uil-plus-circle"></i> Nueva</button></div>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nueva categoria </h3>
                </Modal.Header>
                <Modal.Body>
                    {/*<Form className="needs-validation" onSubmit={submit} encType="multipart/form-data">
                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Imagen"
                                name="file"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" placeholder="Nombre categoria" name="name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Nombre secundario</Form.Label>
                            <Form.Control type="text" placeholder="Nombre secundario" name="secundaryName" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="category_type">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                <option value="P">Producto</option>
                                <option value="S">Servicio</option>

                            </Form.Select>
                        </Form.Group>
                        <Button type="submit" value="Submit" >Guardar</Button>
                    </Form>*/}

                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre de la categoria"
                                name="name"
                                required
                                autoFocus
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Descripcion de la categoria"
                                name="secundaryName"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                {viewTypeCategory()}
                            </Form.Select>
                        </Form.Group>



                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Image categoria</Form.Label>
                            <Form.Control type="file"
                                placeholder="Imagen"
                                name="files"
                                required />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateCategories