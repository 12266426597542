import React, { useEffect, useState } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

function PdfMake() {

   //Information
   let name_bussiness = 'Empresa Prueba '
   let name_branch = 'Empresa Prueba sucursal  '
   let date = '2012-06-20'
   let hour = '18:48:03Z'
   //Values
   let order = '9056'
   let met_pay = 'Visa'
   let total = '10 000 000.00'

   const docDefinition = {
      pageSize: 'A4',
      pageMargins: [23, 12, 23, 12],
      // watermark: {text:'Reportes de Ventas', color: 'gray', opacity: 0.1, bold: false, italics: false},
      content: [
         {
            "image": "logo-smartpay",
            width: 100,

         },
         {
            stack: [
               'Reportes de Ventas',
            ],
            style: 'header'
         },

         //Information Bussiness

         {
            text: [
               { text: 'Empresa:', bold: true, style: 'subheader', margin: [0, 20, 0, 0] },
               name_bussiness
            ],
         },

         {
            text: [
               { text: 'Sucursal:', bold: true, style: 'subheader' },
               name_branch
            ],
         },



         {
            text: 'Máquinas: ' + '0328',
            style: 'subheader'
         },

         {
            text: 'Fecha: ' + date,
            style: 'subheader'
         },


         {
            text: 'Hora: ' + hour,
            style: 'subheader', margin: [0, 0, 0, 20]
         },


         {
            text: 'Fecha de las ordenes: ' + date,
            style: 'subheaderRight'
         },

         // ?Title Of Table
         //Title Of Table
         {
            text: 'Ordenes',
            style: 'subheader'
         },
         //Main Table of Contents
         {
            style: 'tableExample',
            table: {
               widths: [140, '*', 150],
               headerRows: 1,
               // dontBreakRows: true,
               // keepWithHeaderRows: 1,
               body: [
                  [

                     //Tables Headers 
                     { text: 'N°.Orden', style: 'tableHeader' },
                     { text: 'Método de Pago', style: 'tableHeader' },
                     { text: 'Total', style: 'tableHeader' }],


                  //Values of Reports 
                  [
                     //Column 1
                     'Orden:# ' + order,
                     //Column 2
                     met_pay,
                     //Column 3
                     ' $ ' + total,
                  ]
               ]
            }
         },


         //Title Of Table
         {
            text: 'Métodos de Pago',
            style: 'subheader'
         },


         //Pays methods Table of Contents
         {
            style: 'tableExample',
            table: {
               widths: [200, '*'],
               headerRows: 1,
               // dontBreakRows: true,
               // keepWithHeaderRows: 1,
               body: [
                  [

                     //Tables Headers 
                     { text: 'Formas de Pago', style: 'tableHeader' },
                     { text: 'Total', style: 'tableHeader' }],


                  //Values of Reports 
                  [
                     //Column 1
                     met_pay,
                     //Column 2
                     ' $ ' + total,
                  ]
               ]
            }
         },

         //Title Of Table
         {
            text: 'Montos totales',
            style: 'subheader'
         },


         //Totals 
         {
            style: 'tableExample',
            table: {
               widths: [200, '*'],
               headerRows: 1,
               // dontBreakRows: true,
               // keepWithHeaderRows: 1,
               body: [
                  [

                     //Tables Headers 
                     { text: 'Montos', style: 'tableHeader' },
                     { text: 'Total', style: 'tableHeader' }],


                  //Values of Reports 
                  [
                     //Column 1
                     'Devoluciones: ',
                     //Column 2
                     ' $ ' + total,
                  ],

                  [
                     //Column 1
                     'Impuestos: ',
                     //Column 2
                     ' $ ' + total,
                  ],

                  [
                     //Column 1
                     'Total Neto: ',
                     //Column 2
                     ' $ ' + total,
                  ],

                  [
                     //Column 1
                     'Total de ventas : ',
                     //Column 2
                     ' $ ' + total,
                  ],
               ]
            }
         },

      ],

      images: {
         "logo-smartpay": "https://res.cloudinary.com/dk3x6ubyi/image/upload/v1682698324/hi2vihfdtgg98ybwbxnn.png"
      },

      styles: {
         header: {
            fontSize: 16,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 10],
         },
         subheader: {
            fontSize: 12,
            alignment: 'left',
         },
         subheaderRight: {
            fontSize: 12,
            alignment: 'right',
         },


         tableExample: {
            margin: [0, 5, 0, 15]
         },
         tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
         }
      }

   }

   const [url, setUrl] = useState(null);


   const createPdf = () => {

      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.getBlob((blob) => {
         const url = URL.createObjectURL(blob);
         setUrl(url)

      })

      pdfGenerator.download();
   }

   return (
      <>

         <div className="px-4 py-5 my-5 text-center">
            <h1 className="display-5 fw-bold">Descargar Reporte en PDF</h1>
            <div className="col-lg-6 mx-auto">
               <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                  <button
                     type="button"
                     className="btn btn-primary btn-lg px-4 gap-3"
                     onClick={createPdf}
                  >
                     Descargar
                  </button>

                  {/* {
                     url && ( <div> {url}</div>)
                  } */}
               </div>
            </div>

            <div className='row gap-2 justify-content-center'>

               <div className="col-4 col-md-4 col-lg-3 mt-3" >
                  <input type="radio" className="btn-check"
                     name="option_payments" id="payment"
                  />
                  <label className="btn btn-outline-primary" htmlFor="payment">
                     <img src="..." className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" />
                     <p className='display-1 fw-bold'>
                        Metodo de Pago
                     </p>
                  </label>
               </div>

               <div className="col-4 col-md-4 col-lg-3 mt-3" >
                  <input type="radio" className="btn-check"
                     name="option_payments" id="payment"
                  />
                  <label className="btn btn-outline-primary" htmlFor="payment">
                     <img src="..." className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" />
                     <p className='display-1 fw-bold'>
                        Metodo de Pago
                     </p>
                  </label>
               </div>

               <div className="col-4 col-md-4 col-lg-3 mt-3" >
                  <input type="radio" className="btn-check"
                     name="option_payments" id="payment"
                  />
                  <label className="btn btn-outline-primary" htmlFor="payment">
                     <img src="..." className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" />
                     <p className='display-1 fw-bold'>
                        Metodo de Pago
                     </p>
                  </label>
               </div>

               <div className="col-4 col-md-4 col-lg-3 mt-3" >
                  <input type="radio" className="btn-check"
                     name="option_payments" id="payment"
                  />
                  <label className="btn btn-outline-primary" htmlFor="payment">
                     <img src="..." className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" />
                     <p className='display-1 fw-bold'>
                        Metodo de Pago
                     </p>
                  </label>
               </div>

            </div>
         </div>


      </>
   )
}

export default PdfMake