import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class DonleeFechtAPI {

    //Function to get the groups by branchoffice 
    static async getClientPhone(client_phone, token) {
        const res = await server(`/donlee/get_client/${client_phone}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

     //Function to registe new client
     static async registerNewClient(data, token) {
        const res = await server(`/donlee/create_client`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the ninjapp orders
    static async getNinjappOrders(minDate, maxDate, token) {
        const res = await server(`/donlee/ninyapp/orders/${minDate}/${maxDate}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the groups by branchoffice 
    static async getNinjappOrderDetails(order_id, token) {
        const res = await server(`/donlee/ninyapp/order/${order_id}'`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }


    //Function to assign or update the group of position by machine
    static async asingNinyappProductsToSmartpay(group_id, ninjapp_product_id, token) {
        const res = await server(`/donlee/ninyapp/products`, {
            method: "POST",
            data: {
                group_id, ninjapp_product_id
            },
            headers: { 'x-access-token': token },

        });
        return res;
    }


}