import React from 'react'
function VendingModalCategories({ categories, setGroups, getGroupsByMachineByCategory, getGroupsByMachine }) {


  return (
    <>
      {/*  Modal Info */}
      <div className="modal fade" id="CategoriesModal" tabindex="-1" aria-labelledby="CategoriesModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header p-5 pb-4 border-bottom-0">
              <h2 className="fw-bold mb-0 title-modal-categories  pt-2">
                <i className="uil uil-search-alt"></i>
                Categorias
              </h2>
              <button type="button" className="btn-close close-x text-muted border rounded-pill" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="categorie-list-menu">
                <ul>
                  <li className='w-100'>
                    <div className="button-container w-100  shadow-sm rounded" data-bs-dismiss="modal" onClick={() => (getGroupsByMachine())}>
                      <input type="radio" className="btn-check"
                        name="options-categories"
                        key={0} id="cateforieAll"
                        value={"todos"}
                      />
                      <label className="btn btn-outline-primary">
                        Ver Todos
                      </label>
                    </div>
                  </li>
                  {categories.map((Categorie) => (
                    <li className='w-100'>

                      <div className="button-container w-100 shadow-sm rounded" data-bs-dismiss="modal" value={Categorie.category_id} onClick={() => (getGroupsByMachineByCategory(Categorie.category_id))}>
                        <input type="radio" className="btn-check"
                          name="options-categories"
                          id={'categorie' + Categorie.name}
                          value={Categorie.category_id}
                        />


                        <div className='row justify-content-center alight-item-center d-flex  btn btn-outline-primary'>
                          <div className='col-sm-2'>
                            <img src={Categorie.img} width={'500px'} className='button-container-img-categories-vending'></img>
                          </div>
                          <div className='col-sm-10'>
                            <label className=" " htmlFor={'categorie' + Categorie.name}>
                              {Categorie.name}
                            </label>
                          </div>
                        </div>


                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default VendingModalCategories