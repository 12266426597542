
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ItemsFechtAPI } from '../../../api/Items';
import { ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';


function ModalAsingAndRemovedItemToBranchoffice({ branchoffice_id, load, itemsBranchoffice }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [items, setItems] = useState([])
    const [itemSelect, setItemSelect] = useState("")
    const [searchedItems, setSearchedItems] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsSelectList, setItemsSelectList] = useState([])
    const [deleteMode, setDeleteMode] = useState(false)
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getItemsByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await ItemsFechtAPI.getItemsbYBusiness(user.business_id, token)
            console.log(response.data.data);
            setItems(response.data.data)
            setSearchedItems(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const asingItemToBranchoffice = async (item_id) => {
        let data = {
            item_id: item_id
        }
        try {
            const response = await ItemsFechtAPI.asingItemToBranchoffice(branchoffice_id, data, token)
            console.log(response);
            ErrorAlert('', 'Producto individual asignado con exito', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error en la asignacion', 'error')
            console.log(err);
        }
    }

    const deleteItemToBranchoffice = async (item_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            "item_id": item_id
        }
        try {
            const response = await ItemsFechtAPI.deleteItemToBranchoffice(branchoffice_id, data, token)
            ErrorAlert('', 'El producto individual fue eliminado correctamente', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar el producto individual', 'error')
        }
    }


    //Filter the items by the term searched 
    const filteredItems = useMemo(() => {
        if (searchTerm === '') {
            return items
        }

        return searchedItems.filter(item => {
            const nameMatch = item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = item.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, items]);

    const handlerChecked = (e, item_id) => {
        if (e.target.checked == true) {
            if (itemsSelectList.length > 29) {
                ErrorAlert('', 'Superaste la cantidad seleccionada', 'warning')
                e.target.checked = false
            } else {
                setItemsSelectList([...itemsSelectList, item_id]);
            }
        } else {
            let filtrado = itemsSelectList.filter(id => id != item_id)
            setItemsSelectList(filtrado)
        }
    }

    const saveArrayItems = () => {
        if (itemsSelectList.length > 30) {
            ErrorAlert('', 'Solo puedes agregar 30 productos individuales a la ves', 'warning')
        } else {
            itemsSelectList.forEach((id, index) => {

                try {
                    if (deleteMode == true) {
                        deleteItemToBranchoffice(id)
                    } else {
                        asingItemToBranchoffice(id)
                    }

                    if (index == (itemsSelectList.length - 1)) { setItemsSelectList([]); setDeleteMode(false); setSearchTerm('') }
                } catch (err) {
                    ErrorAlert('', 'Uno de los productos ya estaba asignado', 'warning')
                }
            });
        }
    }

    const hadleModeDelete = () => {
        setShowModal(true)
        setDeleteMode(true)
        setItems(itemsBranchoffice)
        setSearchedItems(itemsBranchoffice)
    }



    useEffect(() => {

    }, [eventHandle])




    return (
        <>


            <div className='d-flex justify-content-center align-item-center'>
                <button className='btn btn-outline-primary btn-sm border-0'
                    onClick={() => (setShowModal(true), getItemsByBusiness())}
                >
                    <i className="uil uil-file-plus-alt"></i> Asignar
                </button>

                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => hadleModeDelete()}>
                    <i className="uil uil-trash-alt"></i> Eliminar varios
                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        {deleteMode == true ? <>
                            <i className="uil uil-trash-alt"></i>
                            Eliminar productos individuales de la sucursal</> : <><i className="uil uil-file-plus-alt"></i>Asignar productos individuales a la sucursal</>} </h3>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col sm="12 m-3">
                                <h3 className='text-center m-3'>Seleccione los productos individuales</h3>
                                <p className='text-center '>Maximo 30 productos individuales</p>
                                <div className="d-flex justify-centent-center align-items-center ">
                                    <form className="row g-3 align-content-center align-items-center">
                                        <div className="col-auto ">
                                            <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                        </div>
                                        <div className="col-auto">
                                            <input
                                                spellcheck="false"
                                                className="form-control"
                                                type="text"
                                                step="any"
                                                placeholder="Ingrese producto"
                                                onChange={e => setSearchTerm(e.target.value)}

                                            />
                                        </div>
                                    </form>
                                </div>

                                <div className="m-2 ">
                                    <span className='mb-0 text-secondary fw-bold' >Productos individuales seleccionados: {itemsSelectList.length}</span>
                                </div>
                            </Col>
                            {filteredItems.map(item => (
                                <Col sm="4">
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >

                                            <img
                                                className='imgitemsList m-1'
                                                src={item.img}
                                                alt={item.name}
                                            />
                                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                                <div className="fw-bold">{item.name}</div>
                                                <p className=''>{item.description}</p>
                                            </div>
                                            <div>
                                                <InputGroup className="">
                                                    <InputGroup.Checkbox aria-label="Checkbox for following text input" onChange={(e) => handlerChecked(e, item.item_id)} />
                                                </InputGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            ))}
                        </Row>
                    </Container>

                </Modal.Body>

                <Modal.Footer>
                    <div className='d-flex justify-content-between'>
                        <Button className='m-2' size="sm" onClick={() => (setShowModal(false), setItemsSelectList([]), setDeleteMode(false), setSearchTerm(''))}>Cerrar</Button>

                        {deleteMode == true ?
                            <Button className='m-2' size="sm" variant="danger" type="button" onClick={() => saveArrayItems()}>
                                Eliminar
                            </Button> :
                            <Button className='m-2' size="sm" variant="success" type="button" onClick={() => saveArrayItems()}>
                                Guardar
                            </Button>}
                    </div>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalAsingAndRemovedItemToBranchoffice