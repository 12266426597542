import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CustomerTransactionsFetchAPI {
    
    static async newTransaction(data) {
        console.log(data);
        const res = await server(`/customer_transaction`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': "multipart/form-data"},
        });
        return res
    }

    static async updateTransaction(data) {
        const res = await server(`/customer_transaction`, {
            method: "put",
            data: data,
        });
        return res
    }

    static async getOrders(business_code, branchoffice_code, status_id ) {
        const res = await server(`/customer_transaction/${business_code}/${branchoffice_code}/${status_id}`, {
            method: "get",
        });
        return res
    }

    static async getordersBycode(business_code, branchoffice_code, transaction_code){
        const res = await server(`/customer_transaction/${business_code}/business/${branchoffice_code}/branch/${transaction_code}/transaction`, {
            method: "get",
        });
        return res
    }
}




