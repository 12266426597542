import React from 'react'
import invoice from '../img/icons/receipt.png'
import devolution from '../img/icons/cashback.png'

function TransactionPending(props) {

   //We format the date and hour of the order
   let options = { year: 'numeric', month: 'long', day: 'numeric' };
   const date = new Date(props.data.createdAt).toLocaleDateString('es-Es', options)
   const hour = props.data.createdAt.split('T')[1].split(':')[0] + ':' + props.data.createdAt.split('T')[1].split(':')[1] + ' ' + (props.data.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')
   return (
      <>
         <div  className="px-4 py-3 border-bottom" key={props.data.order_id}>
            <div className=' d-flex gap-3'>
               <img className="flex-shrink-0 " src={props.data.order_type === "devolution" ? devolution : invoice} alt="twbs" width="32" height="32" />
               <div className="d-flex gap-2 w-100 justify-content-between">
                  <div>
                     <h6 className="mb-0 text-muted">Orden # {props.data.transaction_code}</h6>
                     <h6 className="mt-2"> Total: <strong> {props.data.total.toFixed(2)}</strong></h6>
                  </div>
                  <div>
                     <small className="text-nowrap text-primary fw-bold"> {props.data.order_type === 'comer aqui' ? 'Comer aqui' : props.data.order_type === 'devolution' ? "Devolución" : 'Para llevar'} </small>
                  </div>
                  <small className="text-nowrap text-danger">
                     <div> <i className="uil uil-process"></i> Pendiente</div>
                     <small className="text-nowrap text-primary ">{props.data.paymenthod}</small>
                  </small>
               </div>
            </div>
            <div className='d-flex gap-2 w-100 justify-content-between'>
               <small className='ms-5 text-muted '><i className="uil uil-calendar-alt"></i>{date}</small>
               <small className='text-muted '> <i className="uil uil-clock"></i>{hour}</small>
            </div>
         </div>

      </>
   )
}

export default TransactionPending