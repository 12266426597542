//React Components
import React, { useState, useEffect, useMemo } from 'react';
//Components 
import { Form } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
//API
import Swal from "sweetalert2";
import { GroupsFechtAPI } from '../../api/Groups';
import { CategoriesDashFetchAPI } from '../../api/CategoriesDash';
import ModalCreateCategories from './ModalCategories/ModalCreateCategorie';
import ModalEditCategorie from './ModalCategories/ModalEditCategories';
import ModalCategoryListProducts from './ModalCategories/ModalCategoryListProducts';
import ModalAsingGroupToBranchoffice from './ModalGroups/ModalAsingAndRemovedGroupToBranchoffice';
import { RenderIf } from '../utils/RenderIf';
import ModalAsingAndRemovedCategoryToBranchoffice from './ModalCategories/ModalAsingAndRemovedCategoryToBranchoffice';

const CateoriesTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        // We load the products list 
        //loadProducts();
        loadCategories();
    }, [eventHandler]);

    //Function to load actegories
    const loadCategories = async () => {
        let user = JSON.parse(localStorage.getItem("user"))
        try {
            if (branchoffice_id == 0) {
                const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)
                console.log(response)
                setCategories(response.data.data)
                setSearchedProducts(response.data.data)
            } else {
                const response = await CategoriesDashFetchAPI.getCategoriesByBranchOffice(branchoffice_id, token)
                console.log(response)


                setCategories(response.data.data)
                setSearchedProducts(response.data.data)
            }


        } catch (err) {
            setCategories([])
            setSearchedProducts([])
        }
    }


    //delete category
    const deleteCategorytoBranchoffice = async (category_id) => {
        let user = JSON.parse(localStorage.getItem("user"))

        let data = {
            category_id: category_id
        }

        try {


            const response = await CategoriesDashFetchAPI.deleteCategorytoBranchoffice(category_id, branchoffice_id, token);
            console.log((response))
            ErrorAlert('', 'Categoria eliminada', 'success')
            loadCategories()

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error, no se pudo eliminar la categoria', 'error')
        }
    }

    const beforeDelete = async (category_id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar esta categoria de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCategorytoBranchoffice(category_id)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino la categoria',
                    'error'
                )
            }
        })
    }


    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return categories
        }
        return searchedProducts.filter(categorie => {
            const nameMatch = categorie.category_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = categorie.secundaryName.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, categories]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let status = 1
        if (event.target.checked == false) {
            status = 0
        }
        try {

            if (branchoffice_id == 0) {

                const formData = new FormData();
                formData.set('status', status);

                //We update the data 
                let response = await CategoriesDashFetchAPI.UpdateCategories(formData, event.target.id, token);
                //We show the success alert 
                SuccessAlert(response.data.message);

            } else {
                let data = { status: status }

                //We update the data 
                let response = await CategoriesDashFetchAPI.UpdateCategoriesByBranchOffice(data, event.target.id, branchoffice_id, token);
                //We show the success alert 
                SuccessAlert(response.data.message);

            }

            // Recarga los categorias al cambiar el estado del switch
            loadCategories()

        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los productos al cambiar el estado del switch
            loadCategories()
        }
    };


    const viewCategories = () => {

        const list = filteredProducts.map(categorie => (
            <Col  sm="2"  md="6" lg="4" xl="4">
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={categorie.img}
                            alt={categorie.category_name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{categorie.category_name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                                <Form.Switch
                                    type="switch"
                                    id={categorie.category_id}
                                    label=""
                                    checked={categorie.status === 1 ? true : false}
                                    onChange={handleSwitchChange}
                                />
                                {categorie.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>


                            <p className=''>{categorie.secundaryName}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className='m-1'><ModalCategoryListProducts category={categorie}></ModalCategoryListProducts></div>
                                <div className='m-1'><ModalEditCategorie category={categorie} loadCategories={loadCategories}></ModalEditCategorie></div>


                                <div className='m-1'>   <RenderIf isTrue={branchoffice_id != 0}>
                                    <button className='btn btn-outline-danger btn-sm border-0'
                                        onClick={async () => beforeDelete(categorie.category_id)}
                                    >
                                        <i className="uil uil-trash-alt"></i>
                                    </button>
                                </RenderIf>
                                </div>

                            </div>
                        </div>


                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }

    return (
        <>
            <div className='board rounded shadow-sm p-sm-1 p-lg-4'>
                <div className="row bg-light rounded py-3 m-1 border border-light">
                    <div className='col-md-12'>
                        <h4 className='fs-4 fw-bold text-center'>{title}</h4>
                        <p className=' text-center'>Cantidad categorias: {categories.length}</p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-items-center p-2">
                        <div className="w-auto d-flex justify-content-end ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto ">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese producto"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 p-2">
                        <div className='d-flex justify-content-center align-item-center'>
                            <div className=''><ModalCreateCategories loadCategories={loadCategories}></ModalCreateCategories></div>
                            <RenderIf isTrue={branchoffice_id != 0}> <div className=''><ModalAsingAndRemovedCategoryToBranchoffice branchoffice_id={branchoffice_id} load={loadCategories} categoriesBranchoffice={categories}></ModalAsingAndRemovedCategoryToBranchoffice></div></RenderIf>
                        </div>
                    </div>


                </div>


                <div className="mt-3">

                    <Container>
                        <Row>
                            {viewCategories()}
                        </Row>
                    </Container>


                    {/* <div className=" border rounded table-responsive">
                        <table className='table table-hover table-sm' >
                            <thead className='table-light'>
                                <tr>
                                    <th>Activo</th>
                                    <th>Estado</th>
                                    <th>Imagen</th>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.map(categorie => (
                                    <tr key={categorie.category_id}>
                                        <td className='align-middle' >
                                            <Form.Switch
                                                type="switch"
                                                id={categorie.category_id}
                                                label=""
                                                checked={categorie.status === 1 ? true : false}
                                                onChange={handleSwitchChange}
                                            />
                                        </td>
                                        <td className='align-middle' >{categorie.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}</td>
                                        <td className='align-middle' >
                                            <img
                                                src={categorie.img}
                                                alt={categorie.category_name}
                                            />
                                        </td>
                                        <td className='align-middle'  >{categorie.category_name}</td>
                                        <td className='align-middle' >{categorie.secundaryName}</td>
                                        <td className='align-middle'>

                                            <ModalCategoryListProducts category={categorie}></ModalCategoryListProducts>
                                            <ModalEditCategorie category={categorie} loadCategories={loadCategories}></ModalEditCategorie>

                                            <RenderIf isTrue={branchoffice_id != 0}>
                                                <button className='btn btn-outline-danger btn-sm border-0'
                                                    onClick={async () => beforeDelete(categorie.category_id)}
                                                >
                                                    <i className="uil uil-trash-alt"></i>
                                                </button>
                                            </RenderIf>



                                            <></>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>*/}
                </div>
            </div>
        </>

    );
};

export default CateoriesTable;
