import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { GroupsFechtAPI } from '../../../api/Groups';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

function ModalAsinAndRemovedgGroupToBranchoffice({ branchoffice_id, load, productsBranchoffice }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [groups, setGroups] = useState([])
    const [groupSelect, setGroupSelect] = useState("")
    const [searchedProducts, setSearchedProducts] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [groupsSelectList, setGroupsSelectList] = useState([])
    const [statusRequest, setStatusRequest] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false)

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getGroupsByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await GroupsFechtAPI.getGroupsByBusiness(user.business_id, token)
            console.log(response.data.data);
            setGroups(response.data.data)
            setSearchedProducts(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const asingGroupToBranchoffice = async (group_id) => {
        let data = {
            group_id: group_id
        }

        //setStatusRequest(true)
        try {
            const response = await GroupsFechtAPI.asingGroupToBranchoffice(branchoffice_id, data, token)
            console.log(response);

            ErrorAlert('', 'Producto asignado con exito', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error en la asignacion', 'warning')
            console.log(err);
        }
    }

    const deleteGroupBYbranchoffice = async (group_id) => {
        let data = {
            group_id: group_id
        }

        //setStatusRequest(true)
        try {
            const response = await GroupsFechtAPI.deleteProductbybranchoffice(branchoffice_id, data, token)
            console.log(response);

            ErrorAlert('', "Producto removido con exito", "success")
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.data.response.data, "Error, no se pudo remover el producto", "error")
            console.log(err);
        }
    }

    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return groups
        }
        return searchedProducts.filter(group => {
            const nameMatch = group.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = group.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, groups]);


    const handlerChecked = (e, group_id) => {
        if (e.target.checked == true) {
            if (groupsSelectList.length > 29) {
                ErrorAlert('', 'Superaste la cantidad seleccionada', 'warning')
                e.target.checked = false
            } else {
                setGroupsSelectList([...groupsSelectList, group_id]);
            }
        } else {
            let filtrado = groupsSelectList.filter(id => id != group_id)
            setGroupsSelectList(filtrado)
        }
    }

    const saveArrayGroups = () => {

        if (groupsSelectList.length > 30) {
            ErrorAlert('', 'Solo puedes agregar 30 productos a la ves', 'warning')
        } else {
            groupsSelectList.forEach((id, index) => {

                try {
                    if (deleteMode == true) {
                        deleteGroupBYbranchoffice(id)
                    } else {
                        asingGroupToBranchoffice(id)
                    }

                    if (index == (groupsSelectList.length - 1)) { setGroupsSelectList([]); setDeleteMode(false); setSearchTerm('') }
                } catch (err) {
                    ErrorAlert('', 'Uno de los productos ya estaba asignado', 'warning')
                }
            });
        }
    }

    const hadleModeDelete = () => {
        setShowModal(true)
        setDeleteMode(true)
        setGroups(productsBranchoffice)
        setSearchedProducts(productsBranchoffice)
    }


    useEffect(() => {

    }, [eventHandle])




    return (
        <>
            <div className='d-flex justify-content-center align-item-center'>
                <button className='btn btn-outline-primary btn-sm border-0'
                    onClick={() => (setShowModal(true), getGroupsByBusiness())}
                >
                    <i className="uil uil-file-plus-alt"></i> Asignar
                </button>

                <button className='btn btn-outline-danger btn-sm border-0'
                    onClick={() => (hadleModeDelete())}
                >
                    <i className="uil uil-trash-alt"></i> Eliminar varios
                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>{deleteMode == true ? <>
                        <i className="uil uil-trash-alt"></i>
                        Eliminar productos de la sucursal</> : <><i className="uil uil-file-plus-alt"></i>Asignar producto a la sucursal</>} </h3>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col sm="12 m-3">
                                <h3 className='text-center m-3'>Seleccione los productos</h3>
                                <p className='text-center '>Maximo 30 productos</p>
                                <div className="d-flex justify-centent-center align-items-center ">
                                    <form className="row g-3 align-content-center align-items-center">
                                        <div className="col-auto ">
                                            <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                        </div>
                                        <div className="col-auto">
                                            <input
                                                spellcheck="false"
                                                className="form-control"
                                                type="text"
                                                step="any"
                                                placeholder="Ingrese producto"
                                                onChange={e => setSearchTerm(e.target.value)}

                                            />
                                        </div>
                                    </form>
                                </div>

                                <div className="m-2 ">
                                    <span className='mb-0 text-secondary fw-bold' >Productos seleccionados: {groupsSelectList.length}</span>
                                </div>
                            </Col>
                            {filteredProducts.map(group => (
                                <Col sm="4">
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >

                                            <img
                                                className='imgitemsList m-1'
                                                src={group.img}
                                                alt={groups.name}
                                            />
                                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                                <div className="fw-bold">{group.name}</div>
                                                <div className="">Precio: <b>${parseFloat(group.price).toFixed(2)}</b></div>
                                                <div className="">Impuesto: <b>{group.tax}</b></div>
                                                <p className=''>{group.description}</p>
                                            </div>
                                            <div>
                                                <InputGroup className="">
                                                    <InputGroup.Checkbox aria-label="Checkbox for following text input" onChange={(e) => handlerChecked(e, group.group_id)} />
                                                </InputGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>

                    <div className='d-flex justify-content-between'>
                        <Button className='m-2' size="sm" onClick={() => (setShowModal(false), setGroupsSelectList([]), setDeleteMode(false), setSearchTerm(''))}>Cerrar</Button>

                        {deleteMode == true ?
                            <Button className='m-2' size="sm" variant="danger" type="button" onClick={() => saveArrayGroups()}>
                                Eliminar
                            </Button> :
                            <Button className='m-2' size="sm" variant="success" type="button" onClick={() => saveArrayGroups()}>
                                Guardar
                            </Button>}
                    </div>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalAsinAndRemovedgGroupToBranchoffice