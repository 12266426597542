import React from 'react'

function CartButton({setStep, shoppingCart}) {
   return (
      <>
         <div>
            <button
               type="button"
               onClick={() => setStep(5)}
               className='m-0 btn-cart btn-lg  shadow-lg border-0 position-relative me-3'
               title='Carrito'
            >
               <svg id="shopping-cart" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.5,19A1.5,1.5,0,1,0,10,20.5,1.5,1.5,0,0,0,8.5,19ZM19,16H7a1,1,0,0,1,0-2h8.49121A3.0132,3.0132,0,0,0,18.376,11.82422L19.96143,6.2749A1.00009,1.00009,0,0,0,19,5H6.73907A3.00666,3.00666,0,0,0,3.92139,3H3A1,1,0,0,0,3,5h.92139a1.00459,1.00459,0,0,1,.96142.7251l.15552.54474.00024.00506L6.6792,12.01709A3.00006,3.00006,0,0,0,7,18H19a1,1,0,0,0,0-2ZM17.67432,7l-1.2212,4.27441A1.00458,1.00458,0,0,1,15.49121,12H8.75439l-.25494-.89221L7.32642,7ZM16.5,19A1.5,1.5,0,1,0,18,20.5,1.5,1.5,0,0,0,16.5,19Z"></path></svg>

               <span className="
                     position-absolute 
                     top-0 start-100 translate-middle
                     badge rounded-pill 
                     bg-warning kiosk-count text-dark">
                       {/* {amount.length} */}
                       {shoppingCart.length}
               </span>
            </button>
         </div>

      </>
   )
}

export default CartButton