import React from 'react'
//Component
import Header from "./components/Header"
function Notifier({ categories, updateData, nextStep, prevStep, skipToStep3 }) {
  return (
   <section className='kiosk-delivery '>

   <Header
     classContainer={"kiosk-categories-header scale-up-horizontal-left"}
     classImg={"kiosk-header-img-categories"}>
   </Header>

   <div> 
     <h5 className='kiosk-subtitle  scale-up-horizontal-left '>Notificador </h5>

     <div className='bg-light p-5 rounded'>
       <div className='p-5'>
       </div>

     </div>
   </div>
   <div className='my-2 scale-up-horizontal-left '>
     <h5 className='kiosk-subtitle'>Metodo de Pa</h5>
   </div>
   <div className='kiosk-delivery_section container-fluid'>

   </div>
   <div className="kiosk-delivery-footer ">
     <small onClick={prevStep}>
       © 2023 Smart Pay
     </small>
     <small onClick={nextStep}>
       Powered By Smart Retail Group
     </small>
   </div>
 </section >
  )
}

export default Notifier