import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Modal } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

import { RenderIf } from '../../utils/RenderIf';
import ModalSetting from '../ModalSetting';
import ModalNotification from '../ModalNotification';
//Api 
import { MachinesFetchAPI } from '../../../api/Machines';
import { HiDocumentReport } from 'react-icons/hi'
import { RiLogoutBoxFill } from 'react-icons/ri'
import { BsCartCheckFill } from 'react-icons/bs'
import { Button } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';

const NavBar = ({ setPageLoader }) => {
   //Params 
   const navigate = useNavigate();
   let { business_code, branchoffice_code } = useParams();
   let user = localStorage.getItem('user');
   let token = localStorage.getItem('x-access-machine-token');
   let branchoffice = localStorage.getItem('branchoffice');
   let user_token = localStorage.getItem('x-access-token');
   const [showModalSetting, setshowModalSetting] = useState(false)
   //Location page 
   const url_path = useLocation();

   if (localStorage.getItem("x-access-machine-token") == "") {
      let business = JSON.parse(localStorage.getItem("business"))
      //navigate(`/machines/login/`+business.code)
      window.location.href = '/machines/login/' + business.code;
      console.log("hello");
   }

   //console.log('user:', user)

   //We validate if the user is logged in
   if (user === null || user === undefined || user === '' || user === 'null') {
      user = {
         name: 'No registrado',
         img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
      }
   } else {
      user = JSON.parse(localStorage.getItem('user'))
   }

   //We validate if the branch
   if (branchoffice !== null && branchoffice !== undefined && branchoffice !== '') {
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
   }

   //Function to logout of the payment post
   const signout = async () => {
      let machine_token = localStorage.getItem('x-access-machine-token');
      let machine = JSON.parse(localStorage.getItem('machine'));

      //We logout the machine 
      try {
         const response = await MachinesFetchAPI.updateMachineStatus(machine.code, false, machine_token)
         console.log(response.data.success);

         if (response.data.success == true) {
            //We delete the tokens 
            localStorage.setItem('x-access-token', "");
            localStorage.setItem('x-access-machine-token', "");

            //We delete the machine and user info 
            localStorage.setItem('user', "");
            localStorage.setItem('machine', "");
            localStorage.setItem('branchoffice', "");
            localStorage.setItem('business', "");
            localStorage.setItem('table', "");

            //We redirect to the login of the users on the payment post 
            navigate(`/machines/login/${business_code}`);
         }
      } catch (err) {
         console.log(err)
         ErrorAlert("Error de crierre de sesion", "Lo sentimos", "error")
      }
   }

   const handleReload = () => {
      setPageLoader(1)
      ErrorAlert("Datos actualizados con exito", "Menu actualizado", "success")
   }

   useEffect(() => {
      //We check the data on the local storage 
      if (!url_path.pathname.includes('preaccount') && (user === '' || user === null || user === undefined || token === '' || branchoffice === '' || token === null || branchoffice === null || token === undefined || branchoffice === undefined || user_token === 'undefined' || user_token === 'null' || user_token === '' || user_token === null || user_token === undefined)) {
         navigate(`/machines/login/${business_code}`);
      }
   }, []);

   const [isVisible, setIsVisible] = useState(false);
   const buttonRef = useRef(null);
   const toggleVisibility = () => {
      setIsVisible(!isVisible);
   };

   const calculateDropdownPosition = () => {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      return {
         top: buttonRect.top + buttonRect.height,
         left: buttonRect.left,
      };
   };

   return (
      <>
         <div className='d-flex justify-content-between ms-2'>
            <button className=' btn btn-outline-primary bnt-lg border border-1 rounded-pill' onClick={() => (handleReload())}>
               <i className="uil uil-refresh user-name fw-bold "></i>
               <small className='fw-bold fw-bold mx-1 menu-label subtitle'> Actualizar</small>
            </button>


            <ModalNotification></ModalNotification>
            <div className="vr mx-2 text-secondary opacity-25"></div>
            <div className='bg-transparent '>
               <Dropdown size="sm">
                  <Dropdown.Toggle className=" btn-lg bg-gradient p-2" size="sm" id="dropdown-autoclose-true">
                     <span >
                        <i className="uil uil-user-square user-name"></i>
                        <span className='mx-1 menu-label'> Cajero:</span>
                        <strong className='user-name'>{user.name}</strong>
                     </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='dropdown-index'>
                     <Dropdown.Item >
                        <Link className="dropdown-item" to={`/payments/${business_code}/${branchoffice_code}`}>
                           <i className="uil uil-user-square"></i>  Caja de Pago
                        </Link>
                     </Dropdown.Item>
                     <Dropdown.Item>
                        <Link className="dropdown-item" to={`/payment/report/${business_code}/${branchoffice_code}`}>
                           <i className="uil uil-file-graph"></i>  Reportes
                        </Link>
                     </Dropdown.Item>
                     <Dropdown.Item >
                        <ModalSetting></ModalSetting>
                     </Dropdown.Item>
                     <hr className='dv my-0' />
                     <Dropdown.Item onClick={() => signout()}>
                        <i className="uil uil-signout"></i>
                        <span  >  Cerrar Sesión </span>
                     </Dropdown.Item>
                  </Dropdown.Menu>
               </Dropdown>
            </div>
         </div>
      </>
   )
}

export default NavBar