import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class DgiFetchAPI {

    //Function to get business by code 
    static async checkDGIContribuyent(ruc) {
        const res = await server(`/dgi/contribuyent`, {
            method: "post",
            data: {
                ruc
            }
        });
        return res
    }
}