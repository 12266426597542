
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class NotificationsFetchAPI {
    
    static async createNotification(data, token) {
        const res = await server(`/notification`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: data,
        });
        return res
    }

    static async getNotifications(branchoffice_code, business_code, token) {
        const res = await server(`/branch/notification/${branchoffice_code}/${business_code}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }


    static async deleteNotification(notification_id, token) {
        const res = await server(`/notification/${notification_id}`, {
            method: "delete",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getNotificationOne(notification_id, token) {
        const res = await server(`/notification/${notification_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getTypeNotifications(branchoffice_id, token) {
        const res = await server(`/branch/${branchoffice_id}/notification_type`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }
}

