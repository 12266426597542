import React from 'react';

import { ChatEngine } from 'react-chat-engine';
const SupportAdmin = () => {
    
    return (
        <ChatEngine 
        projectID="db6b9b71-a18b-44ab-a147-d6bf8bf4eecd"
        userName='SmartPay Bot'
        userSecret='Maitc'
        height='calc(100vh -20px)'
        />
    );
}

export default SupportAdmin;
