//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TransactionPending from "./TransactionPending"
import TransactionHistory from "../transactions/TransactionHistory";
import Navbar from '../NavBar/Navbar';
import Modal from 'react-bootstrap/Modal';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import OrderDetails from '../Orders/OrderDetails';
//External modules 
import moment from 'moment-timezone';
//Api 
import { OrdersFetchAPI } from '../../api/Orders';
//img
import empty from '../img/icons/empty.png'
import { Button } from 'bootstrap';

function Transactions() {
    //Params
    let { business_code, branchoffice_code } = useParams();
    //We get the user data 
    let token = localStorage.getItem("x-access-token")
    let business = JSON.parse(localStorage.getItem('business'));
    //States
    const [branchOrdersData, setBranchOrdersData] = useState([]);
    const [temporal, setTemporal] = useState([]);
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [userBusiness, setUserBusiness] = useState({});
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0
    });
    const [temporalOrdersData, setTemporalOrdersData] = useState([]);
    const [detailOrder, setDetailOrder] = useState();
    const [order_type, setOrderType] = useState("temporal")
    const [modalShow, setModalShow] = useState(false);
    const [reload, setReLoad] = useState();
    const [buttonDispacher, setButtonDispacher] = useState(false)
    const [colorAlert, setColorAlert] = useState("green")

    let intervalData = 0

    //Functions to show and hide the modal 
    const handleCloseModal = () => {setButtonDispacher(false); setModalShow(false)};
    const handleShowModal = () => setModalShow(true)

    //Function to gen the empty card to the transaction history 
    const EmptyCard = () => {
        return (
            <div className="m-3 w-auto ">
                <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                    <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />
                        <div className='text-center mt-3'>
                            <h5>Vacío</h5>
                            <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //we get orders by status by branchoffice
    const getOrdersByStatus = async () => {
        try {
            const response = await OrdersFetchAPI.getsOrdersByStatusByType(business_code, branchoffice_code, 3, token)
            setBranchOrdersData(response.data.orders);
           
            //This are for the search filter
            setTemporal(response.data.orders);

        } catch (err) {
             //We show the error alert 
             ErrorAlert('No tiene información')
             console.log(err);
        }
    }

    //we get orders by status by branchoffice
    const getOrdersByStatusPending = async () => {
        
        try {
            const response = await OrdersFetchAPI.getsOrdersByStatusByType(business_code, branchoffice_code, 1, token)
            setTemporalOrdersData(response.data.orders);
            intervalData = setInterval(callgetOrdersPending, 10000)

        } catch (err) {
             //We show the error alert 
             ErrorAlert('No tiene información')
             console.log(err);
          
        }

        
    }

    const callgetOrdersPending =() =>{
        clearInterval(intervalData)
        getOrdersByStatusPending()
    }

    const putOrderStatus =  async (data) => {
        try{
            data.state_id = 3
            const response = await OrdersFetchAPI.updateOrderStatus(data);
            console.log(response);

            handleCloseModal()
            getOrdersByStatus()
            callgetOrdersPending()
            SuccessAlert()
        }catch(err){
            console.log(err);
        }
    }

    //Function to populate the list orders 
    const listOrders = () => {

        const listOrders = branchOrdersData.map((data) => (
            <>
                <div  onClick={async () => {
                    await getOrderById(data.order_id);
                    handleShowModal();
                }}>
                    <TransactionHistory data={data} />
                </div>
            </>
        ));
        //We check if the list orders has data 
        if (listOrders.length === 0) {
            return EmptyCard()
        } else {
            return (listOrders)
        }
    }

    //Function to populate the temporal list orders 
    const listTemporalOrders = () => {
        const temporalOrders = temporalOrdersData.map((data) => (
            <>
                <div   onClick={async () => {
                    setButtonDispacher(true)
                    await getOrderById(data.order_id);
                    handleShowModal();
                }} >
                    <TransactionPending data={data} />
                </div>

            </>
        ));
        if (temporalOrders.length === 0) {
            return EmptyCard()
        } else {
            return (temporalOrders)
        }
    }

    //Function to populate the order details (modal)
    const detailOrderList = () => {
        return detailOrder === undefined ? "" : <div>
            <OrderDetails orderData={detailOrder} order_type={order_type} business_data={business} setLoad={setReLoad} />

            {buttonDispacher == true ? <><div className="  w-auto border border-success px-3 py-2 rounded" onClick={ async() =>(putOrderStatus(detailOrder))} >
                <div className="border-0  d-flex justify-content-center " aria-current="true">
                    <div className=' btn btn-success btn-upadate fw-bold m-0 px-3' >
                        <i className="uil uil-check-circle"></i>
                        <small> Despachar </small>
                    </div>
                </div>
            </div></>: <></>}
            
        </div>
    }

    //Function to search any result on the search filter 
    const handleChangeFiltro = (event) => {
        let resultFilter = [];
        if (event.target.value == "") {
            console.log(event.target.value);
            setBranchOrdersData(temporal);
        } else {
            for (
                let recorrer = 0;
                recorrer < branchOrdersData.length;
                recorrer++
            ) {
                const examinar = branchOrdersData[recorrer];

                const info = JSON.stringify(examinar);
                const resultado = info
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());

                if (resultado == true) {
                    resultFilter.push(examinar);

                    setBranchOrdersData(resultFilter);
                    console.log(resultFilter);
                }
            }
        }
    }

    //Function to get order by id (order_id)
    const getOrderById = async (order_id) => {
        try {
            const response = await OrdersFetchAPI.getOrderById(order_id);
            setDetailOrder(response.data.order)

            console.log(detailOrder)
            setOrderType("permanent")
        } catch (err) {
            setDetailOrder({})
            setOrderType("permanent")
            console.log(err)
        }
    }


    //Hook to charge data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {

               
                //We show the loading alert 
                LoadingAlert();
                //We set the branchoffices of the user 
                //setUserBranchs(user.branchoffices);
                //We set the business user 
                //setUserBusiness(user.business);
                //We search the branch orders for default we send 0 to show all 
                //getTransactionsByBranchOffice(business_code, selectedBranch.code, actualDate, maxDate, token);

                //Get orders by status
                getOrdersByStatus();
                getOrdersByStatusPending()

                //We show the success alert 
                SuccessAlert();
            } catch (err) {
                console.log(err);
            }

        })();
        //We set the load state 
        setReLoad(false)
    }, [reload, selectedBranch])

    return (

        <>
            <div className='container'>
                <div className='smart-pay-main'>
                    <div className=' d-flex justify-content-center'>
                        <h2 className='fs-2'>Transacciones del día  </h2>
                    </div>
                </div>
                <Row className='justify-content-md-center'>
                    {/* Branchoffice 
               <Col sm={12} md={12}>
                  <div className="">
                     <div className="d-flex gap-2 w-100 justify-content-center mb-4 pt-2">
                        <div className=' border px-3 py-2 rounded-pill shadow-sm'>
                           <p className="mb-0">
                              <strong>
                                 <i className="uil uil-store"></i>
                                 <select onChange={e => setSelectedBranch({ code: e.target.value })} className='form-select' name="user_branch" id="user_branch">
                                    <option value={0}>Mostrar todos</option>
                                    {branchList()}
                                 </select>
                              </strong>
                           </p>
                        </div>
                     </div>
                  </div>
               </Col>*/}
                  
                    <Col sm={12} md={6} lg={5}>
                        <div className=' payment-smart mx-3'>
                            <div className='text-bg-warning rounded text-center rounded' >
                                <h4 className='fs-4 fw-bold p-3'>Transacciones pendientes: <br></br>{temporalOrdersData.length}</h4>
                            </div>

                            <div className='transaction-wrapper border border-history bg-light'>
                                {/* Boton para actualizar las ordenes */}
                                <div className="  w-auto bg-white px-3 py-2 rounded" onClick={e => getOrdersByStatus(business_code, branchoffice_code, token)} >
                                    <div className="border-0  d-flex justify-content-center " aria-current="true">
                                        <div className=' btn btn-light btn-upadate fw-bold m-0 px-3' >
                                            <i className="uil uil-sync"></i>
                                            <small> Actualizar </small>
                                        </div>
                                    </div>
                                </div>
                                {/* <Empty /> */}
                                <div className=''>
                                    {listTemporalOrders()}
                                </div>
                            </div>
                        </div>
                    </Col>
                      {/* Historial transacciones*/}
                      <Col sm={12} md={6} lg={5}>
                        <div className='mx-lg-3'>
                            <div className='text-bg-success rounded text-center'>
                                <h4 className='fs-4 fw-bold p-3'>Transacciones realizadas: <br></br>{branchOrdersData.length}</h4>
                            </div>
                            <div className="w-auto bg-light px-3 py-2 border rounded mt-4">
                                <div className="border-0" >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="mb-0 text-secondary fw-bold">Buscador</h6>
                                        <div className='border-0 fw-bold fs-3'>
                                            <input
                                                spellcheck="false"
                                                className="form-control"
                                                type="text"
                                                step="any"
                                                onChange={handleChangeFiltro}
                                                placeholder="Filtar  "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='transaction-wrapper-2 border border-history '>
                                <div className="">
                                    {/* <Empty /> */}
                                    {listOrders()}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* Modal */}
            <Modal show={modalShow} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {detailOrderList()}
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Transactions