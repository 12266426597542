import React, { useEffect, useState } from 'react'
import { OrdersFetchAPI } from '../../api/Orders';
import { WpNotificationFetchAPI } from '../../api/WpNotification';
import Swal from "sweetalert2";

function PillOrder({ data }) {

   let data_table = 0
   const [ordersId, setOrdersId] = useState();

   //* ---------Update Send of the CLient Table----------  
   const updateOrder = async (data) => {
      console.log('Hola ACTUALIZACiON ');
      console.log(data);
      console.log(data.order_id)
    
      try {
         
         let dataNewStatusOrder = {            
            "state_id": 4,
            "order_id": data.order_id,
            
        }

         let respuesta = await OrdersFetchAPI.updateOrderStatus(dataNewStatusOrder)
         console.log(respuesta);

      } catch (error) {
         console.log('Hay un error ', error)

      }

      // console.log(data.table.isOnTable)
     
   }

   //* ----------Ask for the CLient Table---------- 
   const askTableOrder = async (data) => {
      console.log(data);
      let new_client_phone = ''
       console.log("Estoy preguntando por la orden deL numero", data.client_phone, "y numero de orden", data.code, typeof (data.client_phone))
       try {
         let dataNotification = {
            "branchoffice_id": data.branchoffice.id,
            "business_id": data.business.id,
            "notification_type": "asking_client_table",
            "phone_to_send": data.client_phone,
            "transaction": data.transaction_code,
         }

         let respuesta = await WpNotificationFetchAPI.sendTableNotification(dataNotification)
         console.log(respuesta);

      } catch (error) {
         console.log('Hay un error ', error)

      }
   }


   //* -----------Validate Table data for ID-------------------
   const askTable = async (id) => {
      // console.log(Object.keys(data))
      try {
         const response = await OrdersFetchAPI.getsOrderById(id)

         // console.log(response)
         setOrdersId(response.data.orders)
         let data = response.data.order

         //Validate if the table key exists
         if (data.hasOwnProperty("table")) {
            console.log("El objeto tiene la propiedad table");
            // **--------------------------------------
            Swal.fire({
               title: '¿La orden ha sido enviada?',
               icon: 'warning',
               confirmButtonColor: '#39a93d',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Si, la he enviado'
            }).then((result) => {
               if (result.isConfirmed) {
                  Swal.fire(
                     {
                        title: 'Buen Trabajo',
                        text: `Orden Completada! ${data.client_phone}`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                     }
                  )
                  // console.log(data.table.isOnTable)
                  //Sending Order Data
                  updateOrder(data);
               }
            })

         }
         else {
            console.log("El objeto NO tiene la propiedad Table");
            let init_table = 0
            if (data.table) {
               init_table = data.table.isOnTable
            }

            Swal.fire({
               title: 'Mesa No Registrada',
               text: `Consultar nuevamente al cliente la orden #${data.code}`,
               icon: 'warning',
               confirmButtonText: 'Si, preguntar',
               confirmButtonColor: '#39a93d',
            }).then((result) => {
               if (result.isConfirmed) {
                  Swal.fire(
                     {
                        title: 'Se ha consultado la mesa al cliente ',
                        text: `Esperando número de mesa del cliente! ${data.client_phone}`,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                     }
                  )

                  askTableOrder(data);

               }
            })
            

         }

      } catch (error) {
         console.log(error);
      }

   };


   //Validating the table value is a number 
   function codeTable() {
      let init_code = 0
      if (data.table) {
         init_code = data.table.code
      }
      return (
         typeof (init_code) == 'number' ? <small className='small bg-light text-secondary fs-6 badge text-wrap opacity-25'>No registrada</small> : init_code
      )
   }

   return (
      <>
         <div onClick={(e) => askTable(data.order_id, e)} className=" m-3 list-group border-history w-auto shadow-sm bg-white-sp">
            <div className="list-group-item list-group-item-action d-flex gap-3 py-2">
               {/* <img src="..." className="flex-shrink-0 rotate" alt="En progreso" width="41" height="40" /> */}
               <div className="row w-100 align-items-center">
                  <div className='col-6 text-center border-end'>
                     <h6 className="mb-0 text-muted align-text-bottom opacity-50 user-select-none"> Mesa  </h6>
                     <span className='mt-1 align-text-bottom fs-4 fw-bold text-secondary-sp user-select-none'>{codeTable()}</span>
                  </div>
                  <div className='col-6 text-center border-start '>
                     <h6 className="mb-0 text-muted align-text-bottom opacity-50 user-select-none"> Orden  </h6>
                     <span className='mt-1 align-text-bottom fs-4 fw-bold text-primary-sp user-select-none'> {data.transaction_code} </span>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default PillOrder