import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class GroupsFechtAPI {

    //Function to get the groups by business 
    static async newGroup(data, token) {
        const res = await server(`/group`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to insert on bulkk menu 
    static async insertMenuOnBulk(branchoffice_id, products, items, segments, categories, pos_integration_id, token) {
        const res = await server(`/groups`, {
            method: "POST",
            headers: { 'x-access-token': token },
            data: { 
                items,
                products,
                segments,
                categories,
                branchoffice_id,
                pos_integration_id
            }
        });
        return res
    }
    //Function to get the groups by business 
    static async getGroupsByBusiness(businessId, token) {
        const res = await server(`/group/${businessId}/business`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get the groups by branchoffice 
    static async getGroupsByBranchoffice(branchoffice_id, token) {
        const res = await server(`/group/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to update a group status of a branchoffice 
    static async updateGroupBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/group/${branchoffice_id}/branchoffice`,
            {
                method: "put",
                data: {
                    "group_id": data.group_id,
                    "status": data.status,
                    "user_id": data.user_id
                },
                headers: { 'x-access-token': token },
            });
        return res
    }


    //Function to get groups by machine and the position
    static async getGroupsByMachine(machine_branch_id, token) {
        const res = await server(`/vending/groups/${machine_branch_id}`,
            {
                method: "get",
                headers: { 'x-access-token': token },
            });
        return res
    }

    //Function to get group by category and position
    static async getGroupByCategoryAndPosition(machine_branch_id, category_id, token) {
        const res = await server(`/vending/groups/${machine_branch_id}/category/${category_id}`,
            {
                method: "get",
                headers: { 'x-access-token': token },
            });
        return res
    }

    //Function to get group by id_group
    static async getGroupById(group_id, token) {
        const res = await server(`/group/${group_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to get group by id_group
    static async getGroupByIdCategory(categoryId, token) {
        const res = await server(`/group_per_category/${categoryId}/category`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to post asing product to a category
    static async asingproductToCategory(category_id, group_id, token) {
        const res = await server(`/group_per_category/${group_id}/group/${category_id}/category`, {
            method: "POST",
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to delete category to the product
    static async deleteCategoryProduct(categoryId, data, token) {
        const res = await server(`/group_per_category/${categoryId}/category`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to delete category to the product
    static async deleteProductbybusiness(businessId, data, token) {
        const res = await server(`/group/${businessId}/business`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to delete branchoffice to the product
    static async deleteProductbybranchoffice(branchoffice_id, data, token) {
        const res = await server(`/group/${branchoffice_id}/branchoffice
        `, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to delete category to the product
    static async updateGroup(group_id, data, token) {
        const res = await server(`/group/${group_id}`, {
            method: "put",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to delete category to the product
    static async getUpcByGroupByBusiness(upc_code, data, token) {
        const res = await server(`/business/:business_id/group/upc_code/:upc_code`, {
            method: "put",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to delete category to the product
    static async deleteUpctoProduct(data, token) {
        const res = await server(`/group/upc_code`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to add upc code to product
    static async addUpcToGroup(data, token) {
        const res = await server(`/group/upc_code`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to delete segment to group
    static async deleteSegmenttoGroup(segment_id, data, token) {
        const res = await server(`/group_per_segment/${segment_id}/segment`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to Asing default item of a segment asigned on a group
    static async asingDefaultItemTosegmentOnGroup(segment_id, group_id, data, token) {
        const res = await server(`/segment_per_group/${segment_id}/segment/${group_id}/group`, {
            method: "put",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }


    //Funtion to asing group to branchoffice
    static async asingGroupToBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/group/${branchoffice_id}/branchoffice`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }







}