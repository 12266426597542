import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CashTempPosFetchAPI {
    static async createTempCash(data) {
        console.log(data);
        const res = await server(`/temp/order`, {
            method: "post",
            data: data,
        });
        return res
    }

    static async getTempCash(machine_code, bussiness_code, branchoffice_code) {
        const res = await server(`/temp/machine/order/${machine_code}/${bussiness_code}/${branchoffice_code}/false`, {
            method: "get",
        });
        return res
    }

    static async updateOrderTempCash(data) {
        const res = await server(`/temp/order`, {
            method: "delete",
            data: data,
        });
        return res
    }
}