import axios from 'axios';

const server = axios.create({
    baseURL: "http://localhost:8080",
    headers: { 'Content-Type': "application/json" },
});

export class PrintOrderLocalFetchAPI {

    static async postPrintOrder(data) {
        const res = await server(`/imprimir2`, {
            method: "post",
            data: data,
        });
        return res
    }
}