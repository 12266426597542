import axios from "axios";

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class OracleMicrosFetchAPI {

    //Function to get the micros params of the branchoffice selected 
    static async getMicrosMenuParams(branchoffice_id, token) {
        const res = await server(`/micros/menus?branchoffice_id=${branchoffice_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

    //Function to get menu on micros 
    static async getMicrosMenu(microsIp, RvcRef, employeeNum, host_url, token) {

        const res = await axios.get(`http://localhost:9472/api/micros/menus?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}&microsIp=${microsIp}`, { 
            headers: {
                Accept: 'application/json',
                'x-access-token': token
            },
        })
        return res
    }
}