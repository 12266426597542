import React, { useState, useEffect } from 'react'
//Component
import Header from "./components/Header"
import Promotions from './components/Promotions';
import { RenderIf } from '../../components/utils/RenderIf';
import CartButton from './components/CartButton';

function Restaurants({ icon, background, parthners, updateData, nextStep, prevStep, promotions, getSegmentsByPromotion, setAtivity, selectRestaurant, setStep, businessInfo }) {

  const [isButtonFocusedId, setButtonFocusId] = useState(null);
  const [items, setItems] = useState([{ parthner_id: businessInfo.id, logo: businessInfo.logo, parthner_name: businessInfo.name }])

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };


  useEffect(() => {



  }, []); // Agrega 'contador' como dependencia para que useEffect se ejecute cada vez que el contador cambie


  return (
    <>
      <section className='kiosk-container'
        style={{ backgroundImage: `url(${background})` }}
        onClick={() => setAtivity(1)}
      >
        <div className='carousel-container position-relative bg-primary'>
          <Promotions icon={icon} promotions={promotions} getSegmentsByPromotion={getSegmentsByPromotion} ></Promotions>
        </div>
        {/* <div className='position-absolute  w-100'>
          <div className='d-flex justify-content-between bg-transparent align-items-center mx-4 mt-2'>
            <Header
              classContainer={"kiosk-categories-header scale-up-horizontal-left"}
              classImg={"kiosk-header-img-categories"} />
          </div>
        </div> */}
        <div className='kiosk-container_section position-absolute w-100 bottom-0'>
          <div className='kiosk-container_section_lista' >
            <div className='kiosk-lista scroll-fast position-relative'>
              {parthners.map((parthner, index) => (
                <>
                  <div className={`kiosk-lista_items kiosk-lista_items position-relative  bounce-left mx-2 `}            
                 
                    type='button' key={index}
                    autoFocus={index === 0}
                    onClick={() => selectRestaurant(parthner)}>

                    <div className="card kiosk-card-item shadow  h-100"
                      id={parthner.parthner_id} value={parthner.parthner_id}
                    >
                      <div className='card-img-top bg-light h-100'>
                        <img src={parthner.logo} width="100%" alt="..." loading='lazy' />
                      </div>

                      <div className="card-body">
                        <span className='kiosk-text-card d-block text-center text-truncate'>
                          {parthner.parthner_name}
                        </span>
                      </div>
                    </div>     
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className=' bottom-0 start-50 translate-middle-x position-absolute '>
          <h2 className='text-center '>
            <span class="kiosk-container_section_title badge rounded-4  bg-warning text-dark shadow">
              Restaurantes</span>
          </h2>
        </div>
      </section>
    </>
  );
}
export default Restaurants