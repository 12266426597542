import React, { useState } from 'react'
import KeyPad from '../../components/Keypad/keypad';
//Component
import Header from "./components/Header"
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import { AuthFetchAPI } from '../../api/Auth';
import { CouponPosFetchAPI } from '../../api/Paymentpost/Coupon';
import { DiscountPosFetchAPI } from '../../api/Paymentpost/Discount';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

function ModalAplyCupon({ icon, background, showModalAplyCupon, setShowModalAplyCupon, setShoppingCart, shoppingCart, setEventHandler, setModalDiscount, invoiceTotal,
   invoiceTotalItbms,
   invoiceSubtotal,
   invoiceTotalDiscount, setAtivity, taxesArrayAll }) {
   //state of keypad number
   const [displayNumber, setDisplayNumber] = useState('');
   const [valueInput, setValueInput] = useState("");




   //Focus
   const [isButtonFocusedId, setButtonFocusId] = useState(null);

   const handleButtonFocus = (buttonId) => {
      setButtonFocusId(buttonId);
   };

   const handleButtonBlur = () => {
      setButtonFocusId(null);
   };





   //set code's of the sesion 
   const handleSaveCode = () => {
      setValueInput(displayNumber)
      setDisplayNumber('')//clear input of the keypad

      setShowModalAplyCupon(false)
      aplyCupon(displayNumber)
   }

   //
   const auhtCupon = async (code) => {
      console.log(code);
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         const response = await CouponPosFetchAPI.getCupon(code, token)

         let branchofficeArray = response.data.data.branchoffice;
         var data_filter = branchofficeArray.filter(element => element.code == branchoffice.code)
         console.log(data_filter);
         if (data_filter.length > 0) {
            console.log("El cupon si es valido");
            createTempDiscountCupon(code)
         } else {
            let icon = "warning"
            let title = "Cupon denegado."
            let message = "El cupon no es valido en esta sucursal"
            ErrorAlert(message, title, icon)
         }

      } catch (err) {
         console.log(err);

      }
   }

   //create orders temporal of discount type cupon
   const createTempDiscountCupon = async (code) => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let token = localStorage.getItem('x-access-machine-token');

      let jsonDiscountTemp = {
         "client_name": "0",
         "cupon_code": code,
         "is_cupon": true,
         "localizador": "0",
         "client_phone": "0",
         "dgi_qr": "0",
         "Orden": shoppingCart,
         "Descuento": "0",
         "Total": invoiceTotal,
         "itbms": invoiceTotalItbms,
         "taxes": taxesArrayAll,
         "subtotal": invoiceSubtotal,
         "machine_id": machine.id,
         "branchoffice_id": branchoffice.id,
         "tipoOrden": "comer aqui",
         "paymethod": 0,
         "is_discount_notification": true
      }
      try {
         const response = await DiscountPosFetchAPI.createTempDiscount(jsonDiscountTemp, token)
         if (response.data.message == 'Tempral order created') {
            getOrdersTempDiscount()
         }
      } catch (err) {
         console.log(err);
      }
   }

   // get order temporal discount
   const getOrdersTempDiscount = async () => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let business = JSON.parse(localStorage.getItem('business'))
      let token = localStorage.getItem('x-access-machine-token');

      try {
         const response = await DiscountPosFetchAPI.getTempDiscount(machine.code, business.code, branchoffice.code, token)

         let code = response.data.order.code
         let message = "Descuento aplicado con exito."
         let title = "Operacion exitosa"
         let icon = "success"

         let tempOrderDiscount = response.data.order.groups
         //format json 
         for (let i = 0; i < tempOrderDiscount.length; i++) {
            for (let s = 0; s < tempOrderDiscount[i].segments.length; s++) {
               tempOrderDiscount[i].segments[s].item_id = tempOrderDiscount[i].segments[s].item.item_id
               tempOrderDiscount[i].segments[s].name = tempOrderDiscount[i].segments[s].item.name
               tempOrderDiscount[i].segments[s].cantidadItem = tempOrderDiscount[i].segments[s].item.amount
               tempOrderDiscount[i].segments[s].description = tempOrderDiscount[i].segments[s].item.description
               tempOrderDiscount[i].segments[s].img = tempOrderDiscount[i].segments[s].item.img
            }
            tempOrderDiscount[i].DetalleSegmentos = tempOrderDiscount[i].segments
            tempOrderDiscount[i].cantidadItem = tempOrderDiscount[i].amount
            tempOrderDiscount[i].descuento = tempOrderDiscount[i].discount
         }

         setShoppingCart(tempOrderDiscount)
         setEventHandler(1)
         updateOrderDiscount(code, 3, message, title, icon)
         setShowModalAplyCupon(false)
         setModalDiscount(false)
      } catch (err) {
         console.log(err);
      }
   }

   //update orders temporal discount
   const updateOrderDiscount = async (code, status, message, title, icon) => {
      console.log(code);
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))
      let token = localStorage.getItem('x-access-machine-token');

      let jsonUpdate = {
         "order_code": code,
         "business_code": business.code,
         "branchoffice_code": branchoffice.code,
         "status": status
      }
      try {
         const response = await DiscountPosFetchAPI.updateOrderTemp(jsonUpdate, token)
         console.log(response);
         ErrorAlert(message, title, icon)
      } catch (err) {
         console.log(err);
      }

   }

   //function to aply cupon
   const aplyCupon = async (code) => {

      let message = ""
      let title = ""
      let icon = ""
      if (invoiceTotalDiscount > 0) {
         message = "No se pueden aplicar dos descuentos"
         title = "Ya existe un descuento aplicado."
         icon = "warning"
         ErrorAlert(message, title, icon)

      } else {
         let valueUpperCase = code.toUpperCase();
         let arrayInput = valueUpperCase.split("X");
         let codeCupon = arrayInput[1]
         auhtCupon(codeCupon)
      }
   }

   return (
      <>
         <Modal
            show={showModalAplyCupon}
            fullscreen
            onHide={() => setShowModalAplyCupon(false)}>
            <Modal.Body className='p-0 m-0'>
               <main className='keyboard-main h-100 d-flex  ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
                  <div className=''>
                     <section className='bd-highlight my-5'>
                        <Header
                           classContainer={"kiosk-delivery_header m-0 p-0"}
                           classImg={"kiosk-delivery_header_img"}>
                        </Header>
                        <h2 className='kiosk-delivery_title  text-center '>
                           ¡Hola! <br />
                           Ingrese el código del cupón
                        </h2>
                        {/* <h2 className="keyboard-title"><i className="uil uil-pricetag-alt"></i> </h2> */}
                     </section>

                     <section className='bd-highlight w-100'>
                        <KeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} />
                     </section>
                     <section className='bd-highlight row'>
                        {/* <div className='col-md-6'>
                           <button type="button" className=" btn btn-lg btn-vending-secondary btn-vending w-100 text-dark" onClick={() => (handleClearAllData())}>
                           <i className="uil uil-arrow-right"></i> Limpiar 
                           </button>
                        </div> */}
                        <div className='col-12'></div>
                     </section>
                  </div>
               </main>
            </Modal.Body>
            {/* <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => (setShowModalAplyCupon(false), setDisplayNumber(''))}>
                        <i className="uil uil-arrow-left"></i>
                        Regresar
                    </button>

                    <button type="button" className=" btn btn-success btn-vending-product mx-4 rounded" onClick={() => (handleSaveCode())}>
                        <i className="uil uil-arrow-right"></i> Siguiente
                    </button> */}

<div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
               <button  onClick={() => (setShowModalAplyCupon(false), setDisplayNumber(''))}
                  className={`btn btn-danger kiosk-subtitle-md kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4`}
               >Cancelar
               </button>

               <button onClick={() => (handleSaveCode())} className={`btn btn-success kiosk-subtitle-md kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}
               >    Ingresar Cupón 
               </button>
            </div>






         </Modal>

      </>
   )
}

export default ModalAplyCupon