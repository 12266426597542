import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';

import { ListGroup } from 'react-bootstrap';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';


function ModalCreateGroup({ loadProducts }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [taxes, setTaxes] = useState([])
    const [upc_input, setUpc_input] = useState('')
    const [taxesSelect, setTaxesSelect] = useState([])
    const [imgpreview, setImgpreview] = useState('')
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append("business_id", user.business_id);
        form.append("group_type", "infinite");
        const createGroup = Object.fromEntries(form.entries());
        createGroup.taxes=taxesSelect
        console.log(createGroup);
        console.log(taxesSelect);

        if (upc_input.length > 13) {
            try {
                //We update the data 
                let response = await GroupsFechtAPI.newGroup(createGroup, token);
                //We show the success alert 

                setTaxesSelect([])
                ErrorAlert('', 'Producto creado con exito', 'success')
                loadProducts();
                setImgpreview('')
                setShowModal(false);
            } catch (err) {
                ErrorAlert(err.response.data.error, 'Error de registro', 'error')
                console.log(err.response.data.error)
            }

        } else {
            ErrorAlert('', 'El upc ingresado tiene menos de 13 caracteres', 'error')
        }
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            setTaxes(response.data.data)
            console.log(response.data.data);

        } catch (err) {
            console.log(err);
        }
    }


    const viewTaxes = () => {
        const view = taxes.map((tax) => (
            <><option value={tax.id}>{tax.tax}</option></>
        ));

        return (view)
    }

    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    //filter checkbox in state true and save in state array
    const getChecked = (event) => {
        let dataTaxes = JSON.parse(event.target.value)
       

        if (event.target.checked == true) {
            setTaxesSelect([...taxesSelect, dataTaxes])
        } else if (event.target.checked == false) {
            for (let i = 0; i < taxesSelect.length; i++) {
                if (taxesSelect[i] == dataTaxes) {
                    taxesSelect.splice(i, 1)
                }
            }
        }
    }

    useEffect(() => {
        setEventHandle(0)

    }, [eventHandle])




    return (
        <>
            <div><button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModal(true), getTaxes())} ><i className="uil uil-plus-circle"></i> Nuevo</button></div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(''))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nuevo producto </h3>
                </Modal.Header>
                <Modal.Body>
                    <RenderIf isTrue={imgpreview != ''}>
                        <div className='d-flex align-item-center justify-content-center'>
                            <img
                                className='imgEditModal'
                                src={imgpreview} />
                        </div>

                        <div className='d-flex align-item-center justify-content-center m-3'>
                            <label htmlFor="upload-button">
                                <h5 className="text-center">Seleccione una imagen</h5>
                            </label>
                        </div>
                    </RenderIf>


                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre del producto"
                                name="name"
                                required
                                autoFocus
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder="Descripcion del producto"
                                name="description"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="$0.00"
                                name="price"
                                required
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Upc: minimo 13 caracteres</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="0000000000000000"
                                name="upc_code"
                                onChange={(e) => setUpc_input(e.target.value)}

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Preparacion</Form.Label>
                            <Form.Select aria-label="" name="preparation">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>

                        {/*<Form.Group className="mb-3" >
                            <Form.Label>Impuestos </Form.Label>
                            <Form.Select aria-label="" name="tax_id">
                                <option>Seleccione un impuesto</option>
                                {viewTaxes()}
                            </Form.Select>
    </Form.Group>*/}

                        <Form.Group className="mb-3" >
                            <Form.Label>Impuestos </Form.Label>
                            <div>
                                {taxes.map((tax, index) => (
                                    <>
                                        <Form.Check
                                            inline
                                            label={tax.tax}
                                            name="taxes"
                                            type='checkbox'
                                            value={JSON.stringify(tax.id)}
                                            onChange={(e) => getChecked(e)}
                                            id={`tax${tax.id}`}
                                        />
                                    </>
                                ))}
                            </div>
                        </Form.Group>





                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                            />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(''))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateGroup