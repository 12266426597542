import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { UsersFetchAPI } from '../../api/Users';
//Components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
//Alerts 
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';

export default function Users() {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))

    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [users, setUsers] = useState([]);
    const [authorizedUserRoles, setAuthorizedUserRole] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    //States for the modal 
    const [modalFields, setModalFields] = useState(
        [
            { name: 'name', label: 'Nombre', type: 'text', placeholder: 'Ingrese nombre', required: true },
            { name: 'email', label: 'Correo', type: 'email', placeholder: 'Ingrese correo', required: true},
            { name: 'phone', label: 'Telefono', type: 'phone', placeholder: 'Ingrese telefono', required: true},
            { name: 'idcard', label: 'Cedula', type: 'text', placeholder: 'Ingrese la cedula', required: true},
            { name: 'password', label: 'Contraseña', type: 'text', placeholder: 'Ingrese Contraseña', required: true},
            { name: 'confirmpassword', label: 'Confirmar contraseña', type: 'text', placeholder: 'Ingrese Contraseña', required: true},

        ]
    );
    const [formData, setFormData] = useState(null);

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);
        } catch (err) {
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
        }
    }

    //Functio to get the users 
    const getUsersByBranchofficeAndRoles = async () => {
        try {
            let branchoffices = user.branchoffices.map(element => element.branchoffice_id);
            //We get the users by branchoffice and role 
            const response = await UsersFetchAPI.getUsersByBranchofficesAndRole(branchoffices, [4, 5, 6, 7], localStorage.getItem('x-access-token'));
            //We set the users
            setUsers(response.data.users)
        } catch (err) {
            console.log(err);
        }
    }

    
    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };


    //Function to register the new user
    const newUser = async (e) => {
        e.preventDefault();
        try {
            //We update the data 
            //let response = await SmartpayWebhooksFetchAPI.newWebhook(formData, token);
            //We show the success alert 
            SuccessAlert('Subscripcion realizada');
            setEventHandler(1)
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error', 'error')
        }
    }


    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        getUsersByBranchofficeAndRoles();
        setEventHandler(0);

    }, [eventHandler]);


    return (
        <>
            {/**Navbar */}
            <NavBar />

            {/**Body header */}
            <div className='container'>
                <div className='smart-pay-main'>
                    <div className="d-flex justify-content-center">
                        <h2 className="fs-2">Usuarios</h2>
                    </div>
                </div>

                {/** Filters user */}
                <div>
                    <h4>Filtros</h4>
                    {/** Modal */}
                    <div className="row gx-3 aling-items-center bg-white p-2 my-3 border-sp shadow-sm">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                                <label htmlFor="">Sucursal</label>
                                <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                                    <option value={0}>Mostrar todos</option>
                                    {branchList()}
                                </select>
                            </div>

                        </div>

                    </div>
                    <Modal
                        button_text={"Nuevo usuario"}
                        modal_size={"lg"}
                        modal_header_text={"Nueva usuario"}
                        modal_body={modalFields}
                        submit={newUser}
                        handleFieldChange={handleFieldChange}
                    />
                    {/**Table user*/}
                    <Table
                        table_name={"Lista de usuarios"}
                        table_headers={['Imagen', 'Nombre', 'Cedula', 'Correo Electronico', 'Rol', 'Acciones']}
                        table_data={users}
                        table_type={"user_table"}
                    />
                </div>
            </div>

        </>
    )
}

