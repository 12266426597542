import React, { useState } from 'react'

function NumericKeyPad({ displayNumber, setDisplayNumber, maxString, placeHolderInput}) {
   //the state is declared in father component (displayNumber, setDisplayNumber)

   const handleNumberClick = (number) => {
      if(maxString == undefined){
         setDisplayNumber((prevDisplayNumber) => prevDisplayNumber + number);
      }else{
         if(displayNumber.length < maxString){
            setDisplayNumber((prevDisplayNumber) => prevDisplayNumber + number);
         }
      }
   };

   const handleClearClick = () => {
      setDisplayNumber('');
   };



   return (
      <>
         <div className='keyboard-main'>
            <div className='keyboard-pad d-flex flex-wrap'>
               <input type="text"
               
                  className='keypadPrimary-input_1  form-control'
                  placeholder={placeHolderInput == undefined ? '6000-0000' : placeHolderInput} value={displayNumber} readOnly
                  maxLength="4"
               />
               <button className='num  kiosk-key-letter' onClick={() => handleNumberClick('1')}>1</button>
               <button className='num  kiosk-key-letter ' onClick={() => handleNumberClick('2')}>2</button>
               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('3')}>3</button>

               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('4')}>4</button>
               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('5')}>5</button>
               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('6')}>6</button>

               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('7')}>7</button>
               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('8')}>8</button>
               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('9')}>9</button>

               <button className='num h6 text-muted border-0 visually-hidden' 
                ></button>
               <button className='num kiosk-key-letter ' onClick={() => handleNumberClick('0')}>0</button>
               <button className='num kiosk-key-letter ' onClick={() => handleClearClick()}>Borrar</button>

            </div>
         </div>
      </>
   )
}

export default NumericKeyPad