import React from 'react'
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
//Fonts 
import FontsRoboto from './Table/Fonts/Roboto-Bold.ttf'

Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },
   ],
});

const styles = StyleSheet.create({
   container: {
      marginTop: 15,
      marginBottom: 10,
      padding: 10,


   },
   text: {
      fontSize: 10,
   },
   textNormal: {
      fontSize: 10,
      fontFamily: 'Roboto'
   },
   alignText: {
      flexDirection: 'row',
   },

});


function Receiver({receptor_data, invoiceDate}) {

   let final_date = invoiceDate.split('T')[0]
   return (
      <>
         <View style={styles.container}>
            {/* Client Name  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Cliente:  </Text>
               <Text style={styles.text}>{receptor_data.name}</Text>
            </View>
            {/* Client rc/cd/psprt  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>RUC:  </Text>
               <Text style={styles.text}> {receptor_data.ruc}</Text>
            </View>

            {/* Client DV  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>DV:  </Text>
               <Text style={styles.text}>{receptor_data.dv}</Text>
            </View>
            {/* Direction Client  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Dirección: </Text>
               <Text style={styles.text}> {receptor_data.direction}</Text>
            </View>
            {/* Direction Client  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Telefono:</Text>
               <Text style={styles.text}>  </Text>
            </View>

            {/* Direction Client  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Fecha de Emisión: </Text>
               <Text style={styles.text}>{final_date}</Text>
            </View>

            {/* Direction Client  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Punto de Facturación: </Text>
               <Text style={styles.text}>001</Text>
            </View>
         </View>

      </>
   )
}

export default Receiver