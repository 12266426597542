import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Components
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import Simphony from './Oracle/Simphony';
import Micros from './Oracle/Micros';
//Utils 
import { RenderIf } from '../utils/RenderIf';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { PosIntegrationFetchAPI } from '../../api/PosIntegrations';

export default function IntegrationManager() {
    //Params 
    let { business_code } = useParams();
    //LocalStorage
    let user = localStorage.getItem('user')
    let token = localStorage.getItem('x-access-token');
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [eventHandler, setEventHandler] = useState(0);
    const [posIntegrationParams, setPosIntegrationParams] = useState([]);
    //We valilink if the user is logged in
    if (user === null || user === undefined || user === '') {
        user = {
            business: {
                posIntegration: {
                    name: "Ninguna"
                },
                pos_integration_id: null
            },
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Get integration params
    const getIntegrationParams = async () => {
        try {
            const params = await PosIntegrationFetchAPI.getPosIntegrationParamsByBranch(user.business.pos_integration_id, selectedBranch.id, token)
            //We set the params 
            setPosIntegrationParams(params.data.params_values);
        } catch (err) {
            setPosIntegrationParams([])
        }
    }

    //Hook to get the integration params every time the event handler changes 
    useEffect(() => {
        getIntegrationParams()
    }, [eventHandler])

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        setEventHandler(0);
    }, []);

    return (
        <>
            <NavBar />
            {/** Main container */}
            <div className="container">
                {/* Branch office data  */}
                <div className="container my-3">
                    <div className="smart-pay-main">
                        <div className="">
                            <div className=''>
                                <div className="row row-cols-lg-auto g-3 align-items-center justify-content-center bg-success p-2 text-white rounded py-3 mt-2 mx-3 border border-light">
                                    <div className='col-md-6'>
                                        <h2 className='text-center fw-bold'>{selectedBranch.name ? <>{selectedBranch.name}</> : <>Seleccione sucursal</>}</h2>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 ">
                                        <select onChange={e => getBranchoffice(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                            <option value={0}>Seleccione sucursal</option>
                                            {branchList()}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/**Depending of the integration we render the sincronization button this is for Simphony */}
                <RenderIf isTrue={user.business.pos_integration_id === 1}>
                    <Simphony
                        branchoffice={selectedBranch}
                        token={token}
                        pos_integration_id={user.business.pos_integration_id}
                    />
                </RenderIf>

                {/** This is for Micros*/}
                <RenderIf isTrue={user.business.pos_integration_id === 2}>
                    <Micros
                        branchoffice={selectedBranch}
                        token={token}
                        pos_integration_id={user.business.pos_integration_id}
                    />
                </RenderIf>
                {/** Table of integration params  */}
                <Table
                    table_name={`Parametros de: ${user.business.posIntegration.name}`}
                    table_headers={["Parametro", "Valor", "Descripcion"]}
                    table_data={posIntegrationParams}
                    table_type={"integration_param_table"}
                />
            </div>
        </>
    )
}
