import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class YappyFetchAPI {

    static async newYappyQr(data, token) {
        const res = await server(`/yappy`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getYappyTransaction(yappy_transaction_id, token) {
        const res = await server(`/yappy?yappy_transaction_id=${yappy_transaction_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

  
}