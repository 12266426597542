import React, { useEffect, useState } from 'react'

//img 
import empty_list from '../../assets/img/kds/notask.png'
//API
import { OrdersFetchAPI } from '../../api/Orders';

const KdsList = ({ updateOrderState, orders, eventHandler, setEventHandler, setSelectedOrder, token,selectOrderAnm }) => {
   const [data, setData] = useState([]);

   //We populate the segment list of the orders 
   const segmentsList = (segments) => {
      //We filter the segments 
      const list  = segments.map((segment) => (
         <small >
            <label className='ms-2 '>{segment.name + ': ' + segment.item.name} </label>
         </small>
      )); 
      return list; 
   }

   //We populate the products list of the orders
   const productList = (products) => {
      let list = products.map((product) => (
         <details open  key={product.group_id} >
         
               <summary>
                  <small >
                     <strong > {product.amount}x - </strong>
                     {product.name}
                  </small>
               </summary>
               {
                  segmentsList(product.segments)
               }
         </details>
      ))
      return <>{list}</>
   }



//Aniamtion Card Order 
   useEffect(() => {
      setData(orders);
      setEventHandler(0)
   }, [eventHandler]);


   const AnimationClick = () => {
      selectOrderAnm(true);
      // Restaurar la animación después de 1 segundo
      setTimeout(() => {
         selectOrderAnm(false);
       }, 500); 
    };
 

   return (
      <>


         {
            !data.length
               ? <>
                  <section className='d-flex justify-content-center'>
                     <div className='w-75 bg-white rounded kds-empty-orders'>
                        <div className='d-flex justify-content-center m-2'>
                           <img src={empty_list} className='kds-img-empty ' alt="Empty List" />
                        </div>
                        <h2 className='text-center fw-bold my-3' >Bandeja Vacía </h2>
                        <h4 className='text-center text-secondary'>
                           No tienes ningún pedido pendiente en esta comanda
                        </h4>
                     </div>
                  </section>
               </>
               : <>
                  <div className='row px-2 mt-2 gap-0'>
                     {data.map((item) => (
                        <div className='col-3' key={item._id} onClick={() => { setSelectedOrder({ ...item, data_selected: true }); updateOrderState(item._id, item.groups, item.status_id === 1 ? 2 : 2);}}>
                           <div className={
                              item.status_id > 1
                                 ? "bg-view card mb-3 position-relative text-dark "
                                 : "card mb-3 position-relative"}

                              key={item._id} 
                              onClick={AnimationClick}
                              >
                              <div
                                 className={
                                    item.status_id > 1
                                       ? "bg-view kds-card-header d-flex justify-content-between text-dark"
                                       : "kds-card-header d-flex justify-content-between"
                                 }

                              >
                                 <small className='mx-0'>{item.transaction_code}</small>
                                 <div className="vr"></div>
                                 <small className='text-uppercase'>
                                    {item.order_type}
                                 </small>
                                 <div className="vr"></div>
                                 <small>
                                    {item.createdAt.split('T')[1].split(':')[0] + ':' + item.createdAt.split('T')[1].split(':')[1] + ' ' + (item.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')}
                                 </small>
                              </div>
                              <div
                              
                              className="kds-card-body">
                                 <div>                                                                   
                                        {productList(item.groups)}                          
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               </>
         }
      </>
   )
}

export default KdsList