import React, { useState, useEffect }  from 'react'
//Component
import Header from "./components/Header"
import { RenderIf } from '../../components/utils/RenderIf'
import { Button, Modal } from 'react-bootstrap'
import QRCode from "react-qr-code";
import { YappyFetchAPI } from '../../api/Paymentpost/Yappy';


//pictures

import checkimg from './assets/img/check.png';
import logoYappy from './assets/img/yappy.png'
import cancel from './assets/img/cancel.png'
import loading from './assets/img/loading.gif'

function ModalYappyQr({ background, nextStep, prevStep, skipToStep3, paymentInfo, setAtivity, modalYappyQr, setModalYappyQr, dataYappy }) {
    let branchoffice = { id: "" }
    let dataOrder = { transaction_code: "", total: 0 };
    if (localStorage.getItem("branchoffice") != "" || localStorage.getItem("branchoffice") != undefined) {
        branchoffice = JSON.parse(localStorage.getItem("branchoffice"))
        dataOrder = JSON.parse(localStorage.getItem('Detail_order'))
    }

    const [routeQr, setRouteQr] = useState("0")

    const updatePage = () => {
        window.location.reload();
    }

    //let routeQr = "0"
    if(dataYappy.body){
        setRouteQr(dataYappy.body.hash)
    }
    

    const getYappyTransaction = async() =>{
        let token = localStorage.getItem('x-access-machine-token');
        let yappy_transaction_id=dataYappy.body.transactionId
        try{
            const response = await YappyFetchAPI.getYappyTransaction(yappy_transaction_id, token);
            console.log(response);
        }catch(err){
            console.log(err);
        }
    }


    useEffect(() => {
        //getYappyTransaction();
      }, []);

    return (
        <Modal
            show={modalYappyQr}
            fullscreen
            onHide={() => setModalYappyQr(false)}>
            <Modal.Body>
                <section className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(2)}>
                    <Header
                        classContainer={"kiosk-categories-header scale-up-horizontal-left mt-5"}
                        classImg={"kiosk-header-img-categories"}>
                    </Header>

                    <div>


                        <div className='p-5 rounded m-5'>
                            <div className='p-5'>

                                <main className='d-flex justify-content-center align-items-center h-100 '>

                                    <section className='border rounded p-4 w-100 position-relative m-3'>
                                        <div className='sections-messages'>

                                            <div className='px-4 pb-1 mt-5 text-center'>

                                                <img className='mx-auto mb-4 ' src={logoYappy} width='200px' height='200px' />
                                                <h1 className="display-6 fw-bold kiosk-subtitle">
                                                    Orden: {dataOrder.transaction_code}
                                                </h1>
                                                <h3 className='kiosk-subtitle-md'> Total: ${dataOrder.Total}</h3>

                                                <div className="m-5 d-flex align-items-center justify-content-center ">
                                                    <QRCode value={routeQr} size={300} bgColor="#282c34" fgColor="#fff" level="H" />
                                                </div>
                                                <p className='kiosk-subtitle-md m-2'> Escanea el qr con yappy para realizar el pago.</p>
                                            </div>
                                            <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success kiosk-subtitle-s">
                                                Método de Pago: {paymentInfo.payment}
                                            </span>
                                        </div>

                                    </section>


                                </main>
                                <div className='d-flex justify-content-center align-items-center '>
                                    <button className="btn btn-danger kiosk-subtitle-md  rounded  p-4 m-3" onClick={() => (setAtivity(5), setModalYappyQr(false))}>Cancelar transaccion</button>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="position-absolute w-100 d-flex justify-content-between bottom-0 start-0 ">
                        <small >
                            © 2023 Smart Pay
                        </small>
                        <small >
                            Powered By Smart Retail Group
                        </small>
                    </div>
                </section >
            </Modal.Body>
        </Modal>
    )
}

export default ModalYappyQr