//React Components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Components 
import Navbar from '../NavBar/Navbar';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import ProductTable from './ProductTable';
import SegmentTable from './SegmentTable';
import ItemTable from './ItemsTable';
import PromotionTable from './PromotionTable';
import CateoriesTable from './CategoriesTable';
import { Tabs, Tab } from 'react-bootstrap';

//API 
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import SupportBot from '../SupportBot/SupportBot';

const ProductStore = () => {
    //Params 
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0
    });
    //Event handler 
    const [eventHandler, setEventHandler] = useState(0);
    //Token
    let token = localStorage.getItem('x-access-token');

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }


    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Hook to charge the data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {
                console.log(user)
                //We set the branchoffices of the user 
                setUserBranchs(user.branchoffices);
            } catch (err) {
                console.log(err)
            }
        })();
    }, []);

    return (
        <>
            <Navbar />
            {/* Branch office data  */}
            <div className="container my-3">
                <div className="smart-pay-main">
                    <div className="">
                        <div className=''>
                            <div className="row row-cols-lg-auto g-3 align-items-center justify-content-center bg-success p-2 text-white rounded py-3 mt-2 mx-3 border border-light">
                                <div className='col-md-6'>
                                    <h2 className='text-center fw-bold'>{selectedBranch.name ? <>{selectedBranch.name}</> : <>Todas las sucursales</>}</h2>
                                </div>
                                <div className="col-6 col-md-6 col-lg-3 ">
                                    <select onChange={e => getBranchoffice(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                        <option value={0}>Mostrar todos</option>
                                        {branchList()}
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container ">
                {/**Tabs of page  */}
                <Tabs defaultActiveKey="all" variant="pills" className='rounded d-flex justify-content-center align-item-center'>

                    {/**Tab of products */}
                    <Tab eventKey="categorie" title="Categorias">
                        <div className='mx-lg-3 mt-4'>
                            {selectedBranch ? <>
                                <CateoriesTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title='Categorias de la Sucursal' />
                            </> : ''}
                        </div>
                    </Tab>
                    {/**Tab of products */}
                    <Tab eventKey="all" title="Productos">
                        <div className='mx-lg-3 mt-4'>
                            {selectedBranch ? <>
                                <ProductTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title='Productos de la Sucursal' />
                            </> : ''}
                        </div>
                    </Tab>

                    {/**Tabs of modifiers */}
                    <Tab eventKey="active" title="Acompañantes">
                        <Row className='justify-content-md-center'>
                            {/**List of products */}
                            <Col sm={12} lg={12}>
                                <div className='mx-lg-3 mt-4'>
                                    {selectedBranch ? <>
                                        <SegmentTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} eventHandler={eventHandler} token={token} user={user} title='Ampañantes de la sucursal' />
                                    </> : ''}
                                </div>
                            </Col>
                        </Row>
                    </Tab>

                    {/**Tabs of individual products */}
                    <Tab eventKey="inactive" title="Productos individuales">
                        <Row className='justify-content-md-center'>
                            {/**List of products */}
                            <Col sm={12} lg={12}>
                                <div className='mx-lg-3 py-4'>
                                    {selectedBranch ? <>
                                        <ItemTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} eventHandler={eventHandler} token={token} user={user} title='Productos individuales de la sucursal' />
                                    </> : ''}
                                </div>
                            </Col>
                        </Row>
                    </Tab>

                    {/** Tab of promotions */}
                    <Tab eventKey="promotions" title="Promociones">
                        <Row className='justify-content-md-center'>
                            {/**List of promotions */}
                            <Col sm={12} lg={12}>
                                <div className="mx-lg py-4">
                                    {selectedBranch ? <>
                                        <PromotionTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title='Promociones de la sucursal' />
                                    </> : ''}

                                </div>
                            </Col>
                        </Row>
                    </Tab>

                </Tabs>
                {/**This is for the bot 
                <SupportBot ></SupportBot>*/}
            </div >
        </>
    );
}

export default ProductStore;
