import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
//Components 
import { Row, Col, Button } from "react-bootstrap";
//Api 
import { CustomerTransactionsFetchAPI } from '../../api/CustomerTransactions';
import { BusinessFetchAPI } from '../../api/Business';
//Alerts 
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrrorAlet from '../Alerts/ErrorAlert';
//Sweet alert
import Swal from "sweetalert2";
import Navbar from '../NavBar/Navbar';

import { RiDeleteBack2Fill, RiSendPlaneFill } from 'react-icons/ri';

const OrderCustomers = () => {
   //*-----::: Vars:::-----
   let { business_code, branchoffice_code } = useParams();
   //States
   const [inputValue, setInputValue] = useState("");
   const [businessData, setBusinessData] = useState({
      name: ''
   });
   //Function to listen the events of the keyboard 
   const handleButtonClick = (value) => {
      //We check if the button selected is to delete the some value of the field
      if (value === "DELETE") {
         setInputValue(inputValue.slice(0, -1))
      } else {
         setInputValue(inputValue + value);
      }
   };

   //Function to create a customer transaction 
   const newTransaction = async (transaction_code) => {
      try {
         let data = {
            business_code, branchoffice_code,
            transaction_code, status_id: 1
         }
         //We create a customer transaction 
         const response = await CustomerTransactionsFetchAPI.newTransaction(data);
         console.log(response)
         //We show the alert 
         SuccessAlert("Registrada en pantalla");
      } catch (err) {
         //We check that the transaction code donsent come empty
         if (transaction_code === "") {
            ErrrorAlet("Campos vacios")
         } else {
            Swal.fire({
               title: 'Esta transaccion por realizar!',
               text: "¿Desea actualizar el estado de esta transaccion?",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'Confirmar',
               cancelButtonText: 'Cancelar'
            }).then(async (result) => {
               if (result.isConfirmed) {
                  try {
                     let data = {
                        business_code, transaction_code, status_id: 4
                     }
                     //we update the transaction 
                     await CustomerTransactionsFetchAPI.updateTransaction(data)
                     Swal.fire(
                        'Estado actualizado!',
                        'Transaccion entregada',
                        'success'
                     )
                  } catch (err) {
                     Swal.fire(
                        'Error inesperado actualizando!',
                        'error'
                     )
                     console.log(err)
                  }
               }
            })
         }

      }
   }

   //Function to get business data 
   useEffect(() => {
      (async () => {
         try {
            //We get the business
            const response_business = await BusinessFetchAPI.getBusinessByCode(business_code)
            //We set the business data 
            setBusinessData(response_business.data.business)
         } catch (err) {
            console.log(err);
         }
      })();
   }, []);

   return (
      <>
         <Navbar />
         <div className='container'>
            {/** Sucursal */}
            <div className='smart-pay-main'>
               <div className="">
                  <div className="d-flex gap-2 w-100 justify-content-center mb-4 pt-2">
                     <div className=' border px-3 py-1 rounded-pill shadow-sm'>
                        <p className="mb-0">
                           <strong>
                              <i className="uil uil-store"></i>
                              Empresa : {businessData.name}  &nbsp;
                           </strong>
                           {/* {user.name} */}
                        </p>
                     </div>
                  </div>
               </div>
            </div>

            {/* Campo de busqueda */}
            <Row className='justify-content-md-center'>
               <Col sm={12} lg={12}>
                  <div className='mx-lg-3'>
                     <div className='d-flex justify-content-center align-items-center align-content-center'>
                        <h4 className='fs-5'>Ingrese Transacción</h4>
                     </div>

                     <div className='border rounded container-content'>
                        <div className='section-keyboard'>
                           <input
                              spellcheck="false"
                              className="form-control-lg mb-3 "
                              type="text"
                              step="any"
                              value={inputValue}
                              placeholder="Ingrese alguna transaccion  "
                           />

                           <div className='keyboard d-flex flex-wrap'>

                              <span onClick={() => handleButtonClick("1")} className="num ">
                                 1
                              </span>
                              <span onClick={() => handleButtonClick("2")} className="num ">
                                 2
                              </span>
                              <span onClick={() => handleButtonClick("3")} className="num ">
                                 3
                              </span>
                              <span onClick={() => handleButtonClick("4")} className="num ">
                                 4
                              </span>
                              <span onClick={() => handleButtonClick("5")} className="num ">
                                 5
                              </span >
                              <span onClick={() => handleButtonClick("6")} className="num ">
                                 6
                              </span >
                              <span onClick={() => handleButtonClick("7")} className="num ">
                                 7
                              </span >
                              <span onClick={() => handleButtonClick("8")} className="num ">
                                 8
                              </span>
                              <span onClick={() => handleButtonClick("9")} className="num ">
                                 9
                              </span >
                              <span className="num bg-white invisible">
                              </span >
                              <span onClick={() => handleButtonClick("0")} className="num ">
                                 0
                              </span>

                              <span onClick={() => handleButtonClick("DELETE")} className="delete ">
                                 <RiDeleteBack2Fill /> Borrar
                              </span>

                              <button onClick={() => newTransaction(inputValue, 1)} className="btn btn-continue btn-lg">
                                 <RiSendPlaneFill />  Enviar
                              </button>
                           </div>

                        </div>


                     </div>

                  </div>
               </Col>
            </Row>
         </div>
      </>
   )
}

export default OrderCustomers