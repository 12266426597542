import React,{useState,  useEffect} from 'react'
//Component
import Header from "./components/Header"
import { RenderIf } from '../../components/utils/RenderIf'
import { Button } from 'react-bootstrap'
import QRCode from "react-qr-code";


//pictures

import checkimg from './assets/img/check.png';
import cancel from './assets/img/cancel.png'
import loading from './assets/img/loading.gif'

function EndTransaction({ background, updateData, nextStep, prevStep, skipToStep3, invoiceTotal, paymentInfo, order_code, setPageLoader, setAtivity }) {
    let branchoffice = { id: "" }
    if (localStorage.getItem("branchoffice") != "" || localStorage.getItem("branchoffice") != undefined) {
        branchoffice = JSON.parse(localStorage.getItem("branchoffice"))
    }

    const updatePage = () => {
        if (localStorage.getItem('branchoffice_original') != null) {
            localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
        }

        if (localStorage.getItem('machine_original') != null) {
            localStorage.setItem('machine', localStorage.getItem('machine_original'))
        }
        window.location.reload();
    }

    let routeQr = "https://smartpay.ai/order/" + order_code + "/branchoffice/" + branchoffice.id

    console.log(" entre a finalizar");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [sizeQr, setSizeQr] = useState(100);
  
    useEffect(() => {
      function handleResize() {
        setWindowWidth(window.innerWidth);
      }
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    useEffect(() => {
      if (windowWidth < 768) {
        setSizeQr(100);
      } else if (windowWidth >= 768 && windowWidth < 1081) {
        setSizeQr(300);
      } else {
        setSizeQr(400);
      }
    }, [windowWidth]);
    return (
        <section className='kiosk-delivery justify-content-center align-items-center' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(3)}>

            {/* <Header
                classContainer={"scale-up-horizontal-left mt-5"}
                classImg={"kiosk-header-img-categories"}>
            </Header> */}


            <div className='rounded  m-0 '>          

                    <main className='d-flex justify-content-center align-items-center h-100 '>

                        <section className='border rounded  w-100 position-relative bg-light'>
                            <div className='sections-messages'>

                                <div className='px-4 pb-1 mt-5 text-center'>

                                    <img className='mx-auto mb-4  loading-status-pay' src={checkimg}/>
                                    <h1 className="display-6 fw-bold kiosk-subtitle-md">
                                        Orden: {order_code}
                                    </h1>                                  
{/* Cmabiar responsive de el size d eqr por js  */}
                                    <div className="m-2 d-flex align-items-center justify-content-center ">
                                        <QRCode value={routeQr} size={sizeQr} bgColor="#282c34" fgColor="#fff" level="H" />
                                    </div>
                                    <h3 className='kiosk-subtitle-md border bg-primary-subtle rounded-4 p-4 text-primary text-center w-auto my-2'> Total: ${parseFloat(invoiceTotal).toFixed(2)}</h3>

                                    <p className='kiosk-subtitle-md m-2'> Escanea el qr para ver el detalle de la orden.</p>
                                </div>
                                <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success kiosk-subtitle-md">
                                    Método de Pago: {paymentInfo.payment}
                                </span>
                            </div>

                        </section>


                    </main>

                    <div className='d-flex justify-content-center align-items-center '>
                        <button className="btn btn-success kiosk-subtitle-md  rounded  p-4 mx-3" onClick={() => (setPageLoader(1), updatePage())}>Nueva Orden</button>
                    </div>

             

            </div>


            <div className="position-absolute w-100 d-flex justify-content-between bottom-0 start-0 ">

                <small >
                    © 2023 Smart Pay
                </small>
                <small >
                    Powered By Smart Retail Group
                </small>
            </div>
        </section >
    )
}

export default EndTransaction