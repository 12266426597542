import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { RenderIf } from '../utils/RenderIf';

import SuccessAlert from '../Alerts/SuccessAlert';

//Api 
import { CustomerTransactionsFetchAPI } from '../../api/CustomerTransactions';
import "./table_order.css"
//img
import error from './img/error.png'
import success from './img/success.png'
import bella from './img/bella1.gif'
import bellaSuccess from './img/bellahappy.gif'
import bellaError from './img/bellabad.gif'
import logo from './img/logo.png'
import notificationSound from './audio/notification.mp3'


function OrderList() {
    const [show, setShow] = useState(false)
    const [showModalImg, setShowModalImg] = useState(false)
    const [orders, setOrders] = useState([]);
    const [orderSelect, setOrderSelect] = useState({})
    const [modification, setModification] = useState(false)
    const [contentResponse, setContentResponse] = useState({gif: bella, img: "/", title: "Title", text: "text" })
    const [eventHandler, setEventHandler] = useState(0);
    const [imgInvoice, setImgInvoice] = useState("");

    let { business_code, branch_code } = useParams();
    let intervalModal = 0
    let intervalDatosOrders = 0
    const endModalResponse = () => { clearInterval(intervalModal); setShow(false) }
    const reloadData = () => { getOrders() }
    const handler = (datos) => {
        setContentResponse({gif: bella, img: success, title: "Orden #" + JSON.parse(datos).transaction_code, text: "¿Enviar pedido?" })
        setShow(true); setOrderSelect(JSON.parse(datos));
        setModification(true)

    }

    const viewImg = (url) => {
        console.log(url);
        setImgInvoice(url)
        setShowModalImg(true)
    }

   
    //get orders
    const getOrders = async () => {
        clearInterval(intervalDatosOrders)
        try {
            const response = await CustomerTransactionsFetchAPI.getOrders(business_code, branch_code, 1)
            //console.log(response);

            if (JSON.stringify(response.data.transactions) != JSON.stringify(orders)) {
                setOrders(response.data.transactions)
            }
            intervalDatosOrders = setInterval(() => { reloadData() }, 10000);
        } catch (err) {
            setOrders([])
            intervalDatosOrders = setInterval(() => { reloadData() }, 10000);
            setOrders([])
            console.log(err);
        }
    }

       //Function to gen the empty card to the transaction history 
   const EmptyCard = () => {
    return (
       <div className="card-order  border-sp m-3 w-auto ">
          <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
             <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                <img src={bella} alt="Vacio" width="100px" />
                <div className='text-center mt-3'>
                   <h5>Vacío</h5>
                   <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                </div>
             </div>
          </div>
       </div>
    )
 }

    //view orders
    const viewOrders = () => {
  
        const listOrders = orders.map((order) => (
            <>
                <div className='card-order m-2 d-flex align-items-center justify-content-center  row  border-sp p-3'>
                    <div className='col-sm-4'><h3 className='text-center '> Orden #{order.transaction_code}</h3></div>
                    <div className='col-sm-4'><h3 className='text-center '> Mesa: {order.table_num ? <>{order.table_num}</> : ""}</h3></div>
                    <div className='col-sm-4 d-flex align-items-center justify-content-center'  ><Button className='btn-lg m-1' type='Button' variant={'outline-primary'} data-img={order.img.url} onClick={e => viewImg(e.currentTarget.dataset.img)}><i className="uil uil-invoice"></i></Button><Button className='btn-lg m-1' type='Button' variant={'outline-success'} data-order={JSON.stringify(order)} onClick={e => handler(e.currentTarget.dataset.order)}><i className="uil uil-message"></i></Button></div>
                </div>
            </>
        ));
        
        if (orders.length === 0) {
            return EmptyCard()
         } else {
            return (listOrders)
         }
    }

    //update order
    const updateOrder = async () => {
        setShow(false)
        setModification(false)
        try {
            let data = {
                business_code, branchoffice_code: branch_code,
                transaction_code: orderSelect.transaction_code, status_id: 4,
                table_num: orderSelect.table_num
            }
            const response = await CustomerTransactionsFetchAPI.updateTransaction(data);
            setContentResponse({gif: bellaSuccess, img: success, title: "Operacion exitosa", text: "Orden numero " + orderSelect.transaction_code + " despachada a la mesa " + orderSelect.table_num })
            setShow(true)
            setEventHandler(1);
            intervalModal = setInterval(endModalResponse, 2000)

        } catch (err) {
            setContentResponse({gif: bellaError, img: error, title: "Upps hubo un error", text: "No se registro el despacho de la orden numero " + orderSelect.transaction_code })
            setShow(true)

            console.log(err);
        }
    }

    useEffect(() => {
        try {
            getOrders()
        } catch (err) {
            console.log(err);
        }
        setEventHandler(0)
    }, [eventHandler]);

 

    return (
        <>
            <div className='container-fluid containe-generate d-flex align-content-center justify-content-center'>

                <div className='col-md-6 '>
                    <div className='m-3 d-flex align-items-center justify-content-center'>
                        <img src={logo} className='img-fluid' width="100px"></img>
                    </div>

                    <div className='m-2 content-list-orders'>

                        {viewOrders()}

                    </div>
                </div>
            </div>

            <Modal
                show={show}
                size="md"
                onHide={() => setShow(false)}
                centered
            >
                <Modal.Body >
                    <div className='m-3 d-flex align-items-center justify-content-center'>
                        <img src={contentResponse.gif} className='img-fluid' width="200px"></img>
                    </div>

                    <RenderIf isTrue={modification == false} >
                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={contentResponse.img} className='img-fluid' width="50px"></img>
                        </div>
                    </RenderIf>

                    <div>
                        <h3 className='text-center'>{contentResponse.title}</h3>
                        <p className='text-center'>{contentResponse.text}</p>
                    </div>

                    <RenderIf isTrue={modification == true} >
                        <div className=' m-2 d-flex align-items-center justify-content-center'>
                            <Button type='Button' variant='danger' className='m-2 btn-lg' onClick={async () => (setShow(false))}>Volver</Button>
                            <Button type='Button' variant='success' className='m-2 btn-lg' onClick={async () => (updateOrder())}><i className="uil uil-message"></i>Enviar</Button>
                        </div>
                    </RenderIf>
                </Modal.Body>
            </Modal>


            <Modal
                show={showModalImg}
                size="md"
                onHide={() => setShowModalImg(false)}
                centered
            >
                <Modal.Header closeButton>Factura</Modal.Header>
                <Modal.Body >
                    <div className='m-3 d-flex align-items-center justify-content-center'>
                        <img src={imgInvoice} className='img-fluid' width="auto"></img>
                    </div>                
                </Modal.Body>
            </Modal>

       
        </>
    )
}

export default OrderList