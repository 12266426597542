import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { ItemsFechtAPI } from '../../../api/Items';
import { SegmentsFechtAPI } from '../../../api/Segments';
import { TaxesFetchAPI } from '../../../api/Taxes';

import ModalAddSegmentToitem from './ModalAddSegmentToItem';

function ModalViewItem({ item }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [segments, setSegments] = useState([])
    const [taxes, setTaxes] = useState([])
    const [segmentSelectNow, setSegmentSelectNow] = useState('')
    const [imgpreview, setImgpreview] = useState(item.img)
    const [segmentsSelect, setSegmentsSelect] = useState([])
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');


    const getSegmentsByItem = async () => {
        console.log(item);
        try {
            const response = await ItemsFechtAPI.getSegmentsByItem(item.item_id, token)
            console.log(response.data.segments);
            setSegments(response.data.segments)
        } catch (err) {
            console.log(err);
            setSegments([])
        }
    }


    const deleteItemToSegment = async (segment_id) => {
        let data = {
            "item_id": item.item_id
        }

        try {
            const response = await SegmentsFechtAPI.deleteItemtoSegment(segment_id, data, token)
            getSegmentsByItem()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el acompañante', 'error')
        }
    }





    useEffect(() => {

    }, [eventHandle])

    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={() => (setShowModal(true), getSegmentsByItem())}
            >
                <i className="uil uil-eye"></i>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle del producto individual  </h3>
                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview}
                            alt={item.name}
                        />
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <div className='mt-3'>
                                    <ListGroup as="ol" >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Nombre</div>
                                                {item.name}
                                            </div>

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Estado</div>
                                                {item.status == 1 ? <><Badge bg="success">Habilitado</Badge></> : <><Badge bg="danger">Deshabilitado</Badge></>}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Descripcion</div>
                                                {item.description}
                                            </div>

                                        </ListGroup.Item>



                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Preparacion</div>

                                                {item.preparation == 0 ? <>No</> : <>Si</>}
                                            </div>

                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col>
                                <div className='mt-3'>
                                    <ListGroup as="ol" >
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Acompañantes en los que esta asignado el producto indiviual


                                                    <ModalAddSegmentToitem item={item} load={getSegmentsByItem}></ModalAddSegmentToitem>
                                                </div>
                                                {/*<RenderIf isTrue={segments.length == 0}>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            No tiene acompañantes asignados
                                                        </div>
                                                    </ListGroup.Item>
                                                </RenderIf>*/}

                                                <ListGroup as="ol" >
                                                    {segments.map((segment) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {segment.name}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteItemToSegment(segment.segment_id)}>
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>

                                            </div>
                                        </ListGroup.Item>

                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >







        </>
    )
}

export default ModalViewItem