import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';

const ModalCategoriesPrinter = ({ printer }) => {
    const [showModalCategoriasPrinter, setShowModalCategoriasPrinter] = useState(false)
    const [categories, setCategories] = useState([])
    const [eventHandle, setEventHandle] = useState(0)
    let token = localStorage.getItem("x-access-machine-token")
    console.log(printer);
    const getCategories = async () => {

        try {
            const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, token)
            setCategories(response.data.categories)
        } catch (err) {
            console.log(err);
        }
    }

    const deleteCategorie = async (category_id) => {
        try {
            let data = {
                "category_id": category_id,
                "printer_id": printer.printer_id
            }
            const response = await CategriesPosFetchAPI.DeleteCategories(data, token)
            ErrorAlert("", "Categoria eliminada con exito", "success")
            setEventHandle(1)
            console.log(response);
        } catch (err) {

                ErrorAlert("", "Ocurrio un error", "error")
            

            console.log(err);
        }
    }

    useEffect(() => {
        getCategories();
        setEventHandle(0)
    }, [eventHandle])


    const viewCategories = () => {
        const listCategories = categories.map((categorie) => (
            <>
                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                    <div className="ms-5 me-auto ">
                        <div className="fw-bold ">{categorie.name}
                            <Button variant="outline-danger" className='m-2' onClick={async () => (deleteCategorie(categorie.category_id))}><i className="uil uil-trash-alt"></i></Button>
                        </div>

                    </div>
                </ListGroup.Item>
            </>
        ));

        return (listCategories)
    }



    return (
        <>
            <Button className='m-2' onClick={() => (setShowModalCategoriasPrinter(true), getCategories())}><i className="uil uil-eye"></i> Ver categorias</Button>

            <Modal
                show={showModalCategoriasPrinter}

                onHide={() => setShowModalCategoriasPrinter(false)}
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-print"></i> {printer.printer_name}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h3 className='text-center'>Categorias asignadas</h3>

                    <div className=' p-5 row alight-item-center d-flex justify-content-center'>
                        <div className='col-auto'>
                            <ListGroup as="ol" numbered>
                                {viewCategories()}
                            </ListGroup>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalCategoriasPrinter(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >




        </>
    )
}

export default ModalCategoriesPrinter