import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
//Internal components 
import Navbar from '../NavBar/Navbar';
import NavBarPost from '../PaymentPost/Nav/NavBar';
import { Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

//Components 
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import TransactionHistory from '../transactions/TransactionHistory';
import OrderDetails from '../Orders/OrderDetails';

//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { OrdersFetchAPI } from '../../api/Orders';
//External components 
import moment from 'moment-timezone';
//img
import empty from '../img/icons/empty.png'
import { RenderIf } from '../utils/RenderIf';

const CashierReport = () => {
    //Params
    let { business_code, branchoffice_code } = useParams();

    //States
    const [userOrders, setUserOrders] = useState([]);
    const [initialDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    const [searchedOrders, setSearchedOrders] = useState([]);
    const [branchOfficeData, setBranchOfficeData] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const [detailOrder, setDetailOrder] = useState();
    const [order_type, setOrderType] = useState("temporal");
    const [userBusiness, setUserBusiness] = useState({});
    const [reload, setReLoad] = useState();

    const [modalShow, setModalShow] = useState(false);
    const [data, setReportData] = useState({
        totalTaxSales: 0,
        subTotalSales: 0,
        totalSales: 0,
        totalDevolutions: 0,
        totalDiscounts: 0,
        business: '',
        user: '',
        machines: [],
        paymenthods: [],
        createdAt: '',
        orders: [],
        businessDatesOrders: []
    });
    //Local storage users 
    const user = JSON.parse(localStorage.getItem('user'));
    let machine = JSON.parse(localStorage.getItem('machine'));

    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true)

    //Function to gen the empty card to the transaction history 
    const EmptyCard = () => {
        return (
            <div className="m-3 w-auto ">
                <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                    <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />
                        <div className='text-center mt-3'>
                            <h5>Vacío</h5>
                            <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //We get the transactions by branchoffice 
    const getOrdersByUser = async (minDate, maxDate) => {
        try {
            //We show the loading data alert 
            LoadingAlert()
            //We call the orders of the branchoffice 
            const response = await OrdersFetchAPI.getOrdersByUser(business_code, user.user_code, minDate, maxDate);
            setUserOrders(response.data.orders);
            setSearchedOrders(response.data.orders);
            setReportData({
                totalTaxSales: response.data.analitics.totalTaxSales,
                subTotalSales: response.data.analitics.subTotalSales,
                totalSales: response.data.analitics.totalSales,
                business: response.data.analitics.business,
                user: response.data.analitics.user,
                machines: response.data.analitics.machines,
                paymenthods: response.data.analitics.paymenthods,
                createdAt: response.data.analitics.createdAt,
                orders: response.data.orders,
                businessDatesOrders: response.data.analitics.businessDate_orders,
                totalDevolutions: response.data.analitics.totalDevolutions,
                totalDiscounts: response.data.analitics.totalDiscounts
            })
            //We show the success alert 
            SuccessAlert()
        } catch (err) {
            //We show the error alert 
            ErrorAlert('No tiene información')
            console.log(err);
        }
    }


    //Filter the orders by term searched 
    const filteredOrders = useMemo(() => {
        if (searchTerm === '') {
            return userOrders
        }

        return searchedOrders.filter(order => {
            const transactionMatch = order.transaction_code.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            return transactionMatch;
        })
    }, [searchTerm, userOrders]);


    //Function to get order by id (order_id)
    const getOrderById = async (order_id) => {
        try {
            const response = await OrdersFetchAPI.getOrderById(order_id);
            setDetailOrder(response.data.order)
            console.log(detailOrder)
            setOrderType("permanent")
        } catch (err) {
            setDetailOrder({})
            setOrderType("permanent")
            console.log(err)
        }
    }

    //Function to populate the order details (modal)
    const detailOrderList = () => {
        return detailOrder === undefined ? "" : <OrderDetails orderData={detailOrder} order_type={order_type} business_data={userBusiness} setLoad={setReLoad} />
    }

    //Function to list the orders of the users 
    const listUserOrders = () => {
        const ordersList = filteredOrders.map((order) => (
            <>
                <div onClick={async () => {
                    await getOrderById(order.order_id);
                    handleShowModal();
                }}>
                    <TransactionHistory data={order} />
                </div>

            </>
        ));

        if (filteredOrders.length === 0) {
            return EmptyCard()
        } else {
            return ordersList
        }
    }




    //Hook to charge data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {
                //We search and set the branch info
                const branchInfo = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
                setBranchOfficeData(branchInfo.data.branchoffice);
                //We set the business user 
                setUserBusiness(user.business);
                await getOrdersByUser(initialDate, maxDate);
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    return (
        <>
            <RenderIf isTrue={machine == undefined || machine == null || machine == ""}>
                <Navbar />
            </RenderIf>

            <RenderIf isTrue={machine != undefined || machine != null || machine != ""}>
                <NavBarPost />
            </RenderIf>


            <div className="container">
                <div className="smart-pay-main">
                    <div className="d-flex justify-content-center">
                        <h2 className="fs-2">Reporte de usuario</h2>
                    </div>
                </div>

                <Row className='justify-content-md-center'>
                    <Col sm={12} md={12} >
                        <div className="">
                            <div className="d-flex gap-2 w-100 justify-content-center mb-4 pt-2">
                                <div className=' border px-3 py-2 rounded-pill shadow-sm'>
                                    <p className="mb-0">
                                        <strong>
                                            <i className="uil uil-store"></i>
                                            Sucursal : {branchOfficeData ? branchOfficeData.name : ''} &nbsp;
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {/* Historial transacciones*/}
                    <Col sm={12} md={6} lg={5}>
                        {/** Filtro */}
                        <div className='row justify-content-md-center'>
                            <div className="col-12 col-md-12 col-xl-12 col-lg-12 ">
                                <div className="border rounded-sp p-3 bg-white card-stats mb-xl-0">
                                    <div>
                                        <h6>Filtro</h6>
                                        <div className="login-form needs-validation">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="">
                                                        <input
                                                            onChange={(e) => setMinDate(e.target.value)}
                                                            type="date" name="date" id="" className="control mt-3" />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <button className="btn btn-success rounded mt-3"
                                                        onClick={e => getOrdersByUser(initialDate, `${initialDate}T23:59:59.000+00:00`)}
                                                        id="btn-analitycs">Aceptar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/** Buscador */}
                        <div className="w-auto bg-light px-3 py-2 border rounded mt-4">
                            <div className="border-0" >
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0 text-secondary fw-bold">Buscador</h6>
                                    <div className='border-0 fw-bold fs-3'>
                                        <input
                                            spellCheck="false"
                                            className="form-control"
                                            type="text"
                                            step="any"
                                            onChange={e => setSearchTerm(e.target.value)}
                                            placeholder="Filtar  "
                                        />
                                    </div>

                                    <RenderIf isTrue={machine != undefined || machine != null || machine != ""}>
                                        <Button variant="outline-secondary ">
                                            <i className="uil uil-print"></i> <span >Imprimir </span>
                                        </Button>
                                    </RenderIf>

                                </div>
                            </div>
                        </div>
                        {/**Lista de transacciones */}
                        <div className='transaction-wrapper-2 border border-history '>
                            <div className="">
                                {/* Paso de data al componente */}
                                {listUserOrders()}
                            </div>
                        </div>
                    </Col>
                </Row>


                {/* Modal detail order */}
                <Modal show={modalShow} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalles</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {detailOrderList()}
                    </Modal.Body>
                </Modal>
            </div>


        </>
    );
}

export default CashierReport;
