//React Components
import React, { useState, useEffect, useMemo } from 'react';
//Components
import { Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import ModalCreatePromotion from './ModalPromotions/ModalCreatrePromotion';
import ModalViewPromotion from './ModalPromotions/ModalViewPromotion';
import ModalListSegmentsByPromotion from './ModalPromotions/ModalListSegmentsByPromotion';
import ModalEditPromotion from './ModalPromotions/ModalEditPromotion';
import ModalAsingAndRemovedPromotionToBranchoffice from './ModalPromotions/ModalAsingAndRemovedPromotionToBranchoffice';
//Api 
import { PromotionsFechtAPI } from '../../api/Promotions';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
import Swal from 'sweetalert2';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
//Promotion table component
const PromotionTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    const [promotions, setPromotions] = useState([]);
    const [searchedPromotions, setSearchedPromotions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // We load the promotions list
        loadPromotions();
    }, [eventHandler]);

    //Function to load promotions
    const loadPromotions = async () => {
        try {
            //We search the promotions of the branchoffice
            let user = JSON.parse(localStorage.getItem('user'))

            if (branchoffice_id != 0) {
                console.log(branchoffice_id);
                const promotions = await PromotionsFechtAPI.getPromotionsByBranchoffice(branchoffice_id, token);
                console.log(promotions)
                let dataArray = promotions.data.promotions.sort(function (a, b) {
                    if (a.promotion_name.toLowerCase() < b.promotion_name.toLowerCase()) { return -1; }
                    if (a.promotion_name.toLowerCase() > b.promotion_name.toLowerCase()) { return 1; }
                    return 0;
                })

                console.log(dataArray);

                setPromotions(dataArray)
                setSearchedPromotions(dataArray)
            } else {
                console.log(branchoffice_id);
                const promotions = await PromotionsFechtAPI.getPromotionsByBusiness(user.business_id, token);
                console.log(promotions)
                console.log(promotions)
                let dataArray = promotions.data.data.sort(function (a, b) {
                    if (a.promotion_name.toLowerCase() < b.promotion_name.toLowerCase()) { return -1; }
                    if (a.promotion_name.toLowerCase() > b.promotion_name.toLowerCase()) { return 1; }
                    return 0;
                })

                console.log(dataArray);

                setPromotions(dataArray)
                setSearchedPromotions(dataArray)
            }
        } catch (err) {
            console.log(err);
            setPromotions([])
            setSearchedPromotions([])
        }
    };


    //Filter the promotions by the term searched
    const filteredPromotions = useMemo(() => {
        if (searchTerm === '') {
            return promotions
        }
        return searchedPromotions.filter(promotion => {
            const nameMatch = promotion.promotion_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = promotion.promotion_description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, promotions]);


    const beforeDelete = async (promotion_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar esta promocion de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deletePromotionByBrachoffice(promotion_id);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino la promocion',
                    'error'
                )
            }
        })
    }

    const deletePromotionByBrachoffice = async (promotion_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            "promotion_id": promotion_id
        }
        try {
            const response = await PromotionsFechtAPI.deletePromotionByBrachoffice(branchoffice_id, data, token)
            ErrorAlert('', "Promocion eliminada con exito", "success")
            loadPromotions();
        } catch (err) {
            ErrorAlert(err.data.response.data, "Error, no se pudo remover la promocion", "error")
            console.log(err);
        }
    }

    //Function to listen the swicht
    const handleSwitchChange = async (event) => {
        //We set the data to update
        let data = {
            status: event.target.checked,
            //promotion_id: event.target.id,
            //user_id: user.id
        }
        try {

            if (branchoffice_id != 0) {
                data.promotion_id = event.target.id

                //We update the data
                let response = await PromotionsFechtAPI.updatePromotionBranchoffice(branchoffice_id, data, token)
                console.log(response);
                //We show the success alert
                SuccessAlert(response.data.message);
            } else {
                //We update the data
                let response = await PromotionsFechtAPI.updatePromotionStatus(event.target.id, data, token)
                //We show the success alert
                SuccessAlert(response.data.message);
            }

            // Recarga los productos al cambiar el estado del switch
            loadPromotions();
        } catch (err) {
            //We show the error alert
            ErrorAlert(err.response.data.error);
        }
    }

    const viewPromotion = () => {

        const list = filteredPromotions.map(promotion => (
            <Col sm="2" md="6" lg="4" xl="4" >
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={promotion.img}
                            alt={promotion.promotion_name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{promotion.promotion_name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                                {/**Validation to enable disable promotions */}
                                <RenderIf isTrue={checkUserPermission('enable_disable_promotion', user.permissions) !== -1}>
                                    <Form.Switch
                                        type="switch"
                                        id={promotion.promotion_id}
                                        label=""
                                        checked={promotion.status === 1 ? true : false}
                                        onChange={handleSwitchChange}
                                    />
                                </RenderIf>
                                {promotion.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>

                            <div className="">Precio: <b>${parseFloat(promotion.price).toFixed(2)}</b></div>
                            <div className="">Impuesto: <b>{promotion.tax}</b></div>
                            <p className=''>{promotion.promotion_description}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className='m-1'><ModalListSegmentsByPromotion promotion={promotion}></ModalListSegmentsByPromotion></div>
                                {/**Validation to uppdate promotion */}
                                <RenderIf isTrue={checkUserPermission('update_promotions', user.permissions) !== -1}>
                                    <div className='m-1'><ModalEditPromotion promotion={promotion} loadPromotions={loadPromotions} ></ModalEditPromotion></div>
                                </RenderIf>

                                <div className='m-1'><ModalViewPromotion promotion={promotion}></ModalViewPromotion></div>

                                <div className='m-1'>
                                    {/**Validation to delete promotion */}
                                    <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_promotions', user.permissions) !== -1}>
                                        <button className='btn btn-outline-danger btn-sm border-0'
                                            onClick={async () => beforeDelete(promotion.promotion_id)}
                                        >
                                            <i className="uil uil-trash-alt"></i>
                                        </button>
                                    </RenderIf></div>

                            </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }

    return (
        <>
            <div className='board rounded shadow-sm p-sm-1 p-lg-4'>
                <div className="row bg-light rounded py-3  m-1 border border-light">
                    <div className='col-md-12'>
                        <h4 className='fs-4 fw-bold text-center'>{title}</h4>
                        <p className=' text-center'>Cantidad promociones: {promotions.length}</p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-item-center p-2">
                        <div className="w-auto d-flex justify-content-end ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto ">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese promocion"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 p-2">

                        <div className='d-flex justify-content-center align-item-center'>
                            {/**Validation to create promotions */}
                            <RenderIf isTrue={checkUserPermission('create_promotions', user.permissions) !== -1}>
                                <div className=''>
                                    <ModalCreatePromotion loadPromotions={loadPromotions}></ModalCreatePromotion>
                                </div>
                            </RenderIf>
                            {/**Validation to asing promotion to branchoffices */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_promotions', user.permissions) !== -1}>
                                <div className=''><ModalAsingAndRemovedPromotionToBranchoffice branchoffice_id={branchoffice_id} load={loadPromotions} promotionsBranchoffice={promotions} ></ModalAsingAndRemovedPromotionToBranchoffice></div>
                            </RenderIf>
                        </div>
                    </div>
                </div>


                <div className="mt-3">
                    <Container>
                        <Row>
                            {viewPromotion()}
                        </Row>
                    </Container>
                </div>
            </div >
        </>
    );
}

export default PromotionTable;
