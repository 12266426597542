import React, { useState } from 'react'
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad';
import KeyPad from '../../components/Keypad/keypad';
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import { AuthFetchAPI } from '../../api/Auth';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import logo from '../../components/img/logos/logo.png'
import loading from './assets/img/loading.gif'
//Component
import Header from "./components/Header"

function ModalLoader({ background, showModalLoader, setShowModalLoader }) {

    return (
        <>
            <Modal
                show={showModalLoader}
                fullscreen>
                <Modal.Body className='d-flex align-items-center' style={{ backgroundImage: `url(${background})` }} >
                    <section>
                        <div>
                            <Header
                                classContainer={"kiosk-categories-header scale-up-horizontal-left"}
                                classImg={"kiosk-header-img-categories"}>
                            </Header>
                            <div className='d-flex justify-content-center'>
                                <img src={loading} alt="Loader" />
                            </div>

                            <h1 className='kiosk-delivery_title  text-center '>
                                La solicitud de descuento será atendida pronto.
                            </h1>
                        </div>
                    </section>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default ModalLoader