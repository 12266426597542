import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class ProductsFetchAPI {

    static async getProducts(businessId) {
        const res = await server(`/group/${businessId}/business`, {
            method: "get"
        });
        return res
    }

    static async getdeatilByProduct(group_id) {
        const res = await server(`/group/${group_id}`, {
            method: "get"
        });
        return res
    }

    static async getsegmentByProduct(group_id) {
        const res = await server(`/segment_per_group/${group_id}/group`, {
            method: "get"
        });
        return res
    }




}