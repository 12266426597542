import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { SegmentsFechtAPI } from '../../../api/Segments';


function ModalCreateSegment({loadSegments}) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    const [segment_name, setSegment_name] = useState("")
    const [segment_description, setSegment_description] = useState("")
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    const createSegment = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        let data = {
            name: segment_name,
            description: segment_description,
            business_id: user.business_id
        }
        try{
            const response = await SegmentsFechtAPI.createSegment(data, token)
            loadSegments()
            ErrorAlert("", "Segmento registrado con exito", "success")
            setShowModal(false);
        }catch(err){
            console.log(err);
            ErrorAlert("", "Error de registro", "error")
        }
    }

    useEffect(() => {
        
    }, [eventHandle])




    return (
        <>

            <div><button className='btn btn-primary btn-sm border-0' onClick={() => setShowModal(true)} ><i className="uil uil-plus-circle"></i> Nuevo</button></div>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nuevo ampañante </h3>
                </Modal.Header>
                <Modal.Body>
         

                    <Form
                        className="needs-validation"
                        
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre de la categoria"
                              
                                required
                                onChange={(e) =>setSegment_name(e.target.value)}
                                
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                             placeholder="Descripcion de la categoria"
                             onChange={(e) =>setSegment_description(e.target.value)}
                             required />
                        </Form.Group>




                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="button" onClick={async(e) => createSegment()}>
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateSegment