import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { RenderIf } from '../../utils/RenderIf';
import { ItemsFechtAPI } from '../../../api/Items';


function ModalEditItem({ item, loadItems }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [imgpreview, setImgpreview] = useState(item.img)
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to register the item 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append("price", "0");
        form.append("item_type", "infinite");
        const createItem = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await ItemsFechtAPI.updateItem(item.item_id, createItem, token);
            //We show the success alert 

            ErrorAlert('', 'Producto individual actualizado con exito', 'success')
            loadItems();
            setImgpreview(item.img)
            setShowModal(false);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de actualizacion', 'error')
            console.log(err.response.data.error)
        }


    }

    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    useEffect(() => {
    }, [eventHandle])


    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={() => setShowModal(true)}
            >
                <i className="uil uil-pen"></i>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(item.img))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                    <i className="uil uil-pen"></i>
                        Editar Item </h3>
                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview} />
                    </div>

                    <div className='d-flex align-item-center justify-content-center m-3'>
                        <label htmlFor="upload-button">
                            <h5 className="text-center">Seleccione una imagen</h5>
                        </label>
                    </div>



                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={item.name}
                                name="name"
                                autoFocus
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder={item.description}
                                name="description"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Preparacion</Form.Label>
                            <Form.Select aria-label="" name="item_preparation">
                            <RenderIf isTrue={item.preparation == 0}>
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                </RenderIf>
                                <RenderIf isTrue={item.preparation == 1}>
                                    <option value={1}>Si</option>
                                    <option value={0}>No</option>
                                </RenderIf>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                hidden
                            />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(item.img))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditItem