
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class PagueloFacilFetchAPI {

    //Function to create a new payment 
    static async newPayment(cclw, transaction_code, paguelofacil_code, description, total, token) {
        const res = await server(`/paguelofacil`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                cclw,
                paguelofacil_code,
                transaction_code,
                description,
                total,
            },
        });
        return res
    }

      //Function to return a payment 
      static async returnPayment(cclw, codOper, description, total, auth_token, token) {
        const res = await server(`/return/paguelofacil`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                cclw,
                codOper,
                auth_token,
                description,
                total,
            },
        });
        return res
    }
}

