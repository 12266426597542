import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';


//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { SmartpayWebhooksFetchAPI } from '../../api/Smartpay.Webhooks';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';

export default function Webhook() {
    //Params 
    let { business_code } = useParams();
    //LocalStorage
    let user = localStorage.getItem('user')
    let token = localStorage.getItem('x-access-token');
    //For modal fields
    const [modalFields, setModalFields] = useState([{ name: 'url', label: 'Endpoind (url)', type: 'text', placeholder: 'Ingrese su url de destino', required: true }])
    const [formData, setFormData] = useState({
        url: '',
        webhook_event_id: '',
        branchoffice_id: '',
    });
    //States    
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [webhooksEvents, setWebhooksEvents] = useState([])
    const [eventHandler, setEventHandler] = useState(0);

    //We valilink if the user is logged in
    if (user === null || user === undefined || user === '') {
        user = {
            business: {
                posIntegration: {
                    name: "Ninguna"
                },
                pos_integration_id: null
            },
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }

    //Function to get webhooks available for smartpay
    const getWebhookEvents = async () => {
        try {
            //We get webhooks available for smartpay
            const response = await SmartpayWebhooksFetchAPI.getWebhookEvents(token);
            //We map the webhook event response and then we return the response on the format that we want
            let webhookoptions = response.data.webhooks_events.map(option => {
                return { label: option.name, value: option.id }
            });
            //We set the first option on the options array 
            webhookoptions.unshift({ label: "Seleccione...", value: null });
            //We map the webhook event response and then we return the response on the format that we want
            let branchoptions = user.branchoffices.map(option => {
                return { label: option.branchoffice_name, value: option.branchoffice_id }
            });
            //We set the first option on the options array 
            branchoptions.unshift({ label: "Seleccione...", value: null });
            //We set the field of select and the options on the modal fields 
            setModalFields([...modalFields, {
                name: 'webhook_event_id', label: 'Evento a suscribir', type: 'select', defaultValue: '', required: true,
                options: webhookoptions
            },
            {
                name: 'branchoffice_id', label: 'Seleccione la sucursal', type: 'select', defaultValue: '', required: true,
                options: branchoptions
            }])
        } catch (err) {
            //We set the branch office to 0
            setModalFields([...modalFields])
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get the webhooks by branchoffice 
    const getWebhookSubscriptionByBranchoffice = async () => {
        try {
            const response = await SmartpayWebhooksFetchAPI.getWebhooksByBranchoffice(selectedBranch.id, token);
            //We set the webhooks 
            setWebhooksEvents(response.data.webhooks)
        } catch (err) {
            setWebhooksEvents([])
        }
    }

    //Function to detect the change of value on the form 
    const handleFieldChange = (fieldName, value) => {
        setFormData({
            ...formData,
            [fieldName]: value,
        });
    };

    //Hook to get the integration params every time the event handler changes 
    useEffect(() => {
        getWebhookSubscriptionByBranchoffice();
        setEventHandler(0)
    }, [eventHandler])

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        getWebhookEvents()
        setEventHandler(0);
    }, []);

    //Function to register the webhook subscription 
    const submit = async (e) => {
        e.preventDefault();
        try {
            //We update the data 
            let response = await SmartpayWebhooksFetchAPI.newWebhook(formData, token);
            //We show the success alert 
            SuccessAlert('Subscripcion realizada');
            setEventHandler(1)
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error', 'error')
        }
    }


    //Function to remove the webhook subscription 
    const removeWebhook = async (webhook_id) => {
        try {
            //We update the data 
            let response = await SmartpayWebhooksFetchAPI.removeWebhook(webhook_id, token);
            //We show the success alert 
            SuccessAlert('Subscripcion al webhook removida');
            setEventHandler(1);
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error', 'error')
        }
    }

    return (
        <>
            <NavBar />
            {/**Main container */}
            < div className="container" >
                {/**Branchoffice data */}
                < div className="container my-3" >
                    <div className="smart-pay-main">
                        <div className="">
                            <div className=''>
                                <div className="row row-cols-lg-auto g-3 align-items-center justify-content-center bg-success p-2 text-white rounded py-3 mt-2 mx-3 border border-light">
                                    <div className='col-md-6'>
                                        <h2 className='text-center fw-bold'>{selectedBranch.name ? <>{selectedBranch.name}</> : <>Seleccione sucursal</>}</h2>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 ">
                                        <select onChange={e => getBranchoffice(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                            <option value={0}>Seleccione sucursal</option>
                                            {branchList()}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {/** Population of modal  */}
                <Modal
                    button_text={"Nueva subscripcion"}
                    modal_size={"large"}
                    modal_header_text={"Nueva subcripcion a webhook"}
                    modal_body={modalFields}
                    submit={submit}
                    handleFieldChange={handleFieldChange}

                />
                {/**Table of webhooks events subscribed  */}
                <Table
                    table_name={`Webhooks asociados`}
                    table_headers={["Evento asociado", "Url", "Acciones"]}
                    table_type={"webhook_table"}
                    table_data={webhooksEvents}
                    removeWebhook={removeWebhook}
                />
            </div >



        </>

    )
}
