import React, { useState, useEffect } from 'react'

function TimerScreen() {
    //let interval = setInterval(handlerScreen, 10000)
    const [count, setCount] = useState(0);
    
  

   


  return (
    <>

    </>
  )
}

export default TimerScreen