import React from 'react'
import Hero from './Hero'

function Index() {
  return (
    <>
      <Hero></Hero>

    </>
  )
}

export default Index