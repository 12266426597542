import React, { useState } from 'react'

function Keyboard({ displayKeyboard, setDisplayKeyboard }) {
   //the state is declared in father component (displayNumber, setDisplayNumber)

   const handleNumberClick = (number) => {
      setDisplayKeyboard((prevDisplayKeyboard) => prevDisplayKeyboard + number);
   };

   const handleClearClick = () => {
      setDisplayKeyboard('');
   };



   return (
      <>
         <div className='keyboard-main'>
            <div className='keyboard-pad d-flex flex-wrap'>
               <input type="text"
                  className='keypad-input form-control'
                  placeholder='---' value={displayKeyboard} readOnly
                  maxLength="4"
               />

               <button className='keyboardKey' onClick={() => handleNumberClick('1')}>1</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('2')}>2</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('3')}>3</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('4')}>4</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('5')}>5</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('6')}>6</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('7')}>7</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('8')}>8</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('9')}>9</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('0')}>0</button><br/>

               <button className='keyboardKey' onClick={() => handleNumberClick('q')}>q</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('w')}>w</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('e')}>e</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('r')}>r</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('t')}>t</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('y')}>y</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('u')}>u</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('i')}>i</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('o')}>o</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('p')}>p</button><br/>

               <button className='keyboardKey' onClick={() => handleNumberClick('a')}>a</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('s')}>s</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('d')}>d</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('f')}>f</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('g')}>g</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('h')}>h</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('j')}>j</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('k')}>k</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('l')}>l</button><br/>

               <button className='keyboardKey' onClick={() => handleNumberClick('z')}>z</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('x')}>x</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('c')}>c</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('v')}>v</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('b')}>b</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('n')}>n</button>
               <button className='keyboardKey' onClick={() => handleNumberClick('m')}>m</button><br/>
               <button className='keyboardKey' onClick={() => handleNumberClick('-')}>-</button><br/>

               <button className='delete h6 text-muted'
                  data-bs-dismiss="modal"
                  onClick={() => handleClearClick()} >Cancel</button>

               <button className='delete' onClick={() => handleClearClick()}>Borrar</button>

            </div>
         </div>
      </>
   )
}

export default Keyboard