import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
//Img
import logo_sp from '../img/logos/icon-black.png';
//Componenet App
import { Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import NavBar from './Nav/NavBar'
import { ProductsFetchAPI } from '../../api/Paymentpost/Products';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { NotificationsFetchAPI } from '../../api/Notidication'
import { Modal } from 'react-bootstrap';
import ModalPayMet from './ModalPayMet'
import ModalPendingOrders from './ModalPendingOrders';
import ModalDiscounts from './ModalDiscounts'
import ModalReturn from './ModalReturn';
import ModalCoupons from './ModalCoupons'
import ModalPaymet from './ModalPayMet';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import ModalCreatePreOrder from './ModalCreatePreOrder';
import ModalCreateOrderTable from './ModalCreateOrderTable';
//Img
import empty_img from './img/empty_cart.png';
import all_products from './img/all.png';
import sad_products from './img/sad.png'
import img_default from './img/logo_sp.png'
//Icons 
import Empty from '../utils/Empty';
import { HiShoppingCart } from 'react-icons/hi';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import AlertSmall from '../Alerts/AlertSmall';
const PaymentPost = () => {
   let index = 0;
   const navigate = useNavigate();
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("branchoffice") == "" || localStorage.getItem("table") == "") {
      let business = JSON.parse(localStorage.getItem("business"))
      navigate(`/tables/machines/login/` + business.code)
   }
   //States 
   const [number, setNumber] = useState(1);
   const [categories, setCategories] = useState([]);
   const [products, setProducts] = useState([]);
   const [dataPreOrder, setDataPreOrder] = useState({ pre_Order: false, _id: "" });
   const [viewProducts, setViewProducts] = useState([]);
   const [showModalSelectItem, setShowModalSelectItem] = useState(false);
   const [showUpdateAccount, setShowUpdateAccount] = useState(false);
   const [registerTurnOrder, setRegisterTurnOrder] = useState(0);
   const [selectProduct, setSelectProduct] = useState({
      img: '',
      name: '',
      img: '',
      name: '',
      price: ''
   });

   const [previewSegment, setPreviewSegment] = useState([])
   const [segments, setSegments] = useState([]);
   const [shoppingCart, setShoppingCart] = useState([]);
   //Event handler 
   const [eventHandlerPreviewsegment, setEventHandlerPreviewsegment] = useState(0);
   const [eventHandler, setEventHandler] = useState(0);
   const [pageLoader, setPageLoader] = useState(0)
   //Totals states 
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0)

   //filter
   const [temporalProducts, setTemporalProducts] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');

   //data client
   const [optionalData, setOptionalData] = useState(false);
   const [dataResponse, setDataResponse] = useState(false);
   const [ruc, setRuc] = useState(0);
   const [name, setName] = useState("Cliente generico");
   const [dv, setDv] = useState(0);
   const [checkDeliveryOrder, setCheckDeliveryOrder] = useState("comer aqui")

   //data checked discount
   const [productChecked, setProductChecked] = useState([]);
   const [preOrderInvoice, setPreOrderInvoice] = useState(false);
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [fullscreen, setFullscreen] = useState(true);


   //Function to increment the amount of products on the product detail modal
   const incrementNumber = () => {
      setNumber(number + 1);
   };

   //Function to decrement the amount of products on the product detail modal
   const decrementNumber = () => {
      //If the amount of product is more than 1 we decrement it
      if (number > 1) {
         setNumber(number - 1);
      }
   };


   const createNotification = async (body, type) => {

      if (type == 1) {
         Swal.fire({
            title: 'Deseas solicitar esta accion',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si enviar!',
            cancelButtonText: 'Cancelar',
         }).then(async (result) => {
            if (result.isConfirmed) {
               requestNotification(body)
            }
         })
      } else {
         requestNotification(body)
      }

   }

   const requestNotification = async (body) => {
      //We get the table  that want to do the pre account 
      let table = JSON.parse(localStorage.getItem('table'));

      let token = localStorage.getItem('x-access-machine-token');
      let machine = localStorage.getItem('machine');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))
      try {
         let data = {
            "title": table.name,
            "body": body,
            "machine_id": machine.id,
            "table_id": table.id,
            "branchoffice_code": branchoffice.code,
            "business_code": business.code
         }

         const response = await NotificationsFetchAPI.createNotification(data, localStorage.getItem('x-access-machine-token'));
         console.log(response);
         //ErrorAlert("", "Notificacion enviada", "success")

      } catch (err) {
         ErrorAlert("", "Error al enviada", "error")
         console.log(err);
      }
   }

   //Filter the products by the term searched 
   const filteredProducts = useMemo(() => {
      if (searchTerm === '') {
         return products
      }
      return viewProducts.filter(product => {
         const nameMatch = product.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
         const descriptionMatch = product.description.toLowerCase().includes(searchTerm.toLowerCase());
         return nameMatch || descriptionMatch;
      })
   }, [searchTerm, products]);

   //function to select type delivery order
   const selectTypyDeliveryOrder = (typeOrder) => {

      let intervalRadioButton = 0
      let temShopingCart = shoppingCart
      setCheckDeliveryOrder(typeOrder)

      const initShopingcard = () => {
         console.log("entre");
         clearInterval(intervalRadioButton)
         setShoppingCart(temShopingCart)
         setEventHandler(1)
         SuccessAlert()
      }

      if (typeOrder != "Mixto") {
         LoadingAlert()
         setShoppingCart([])
         setEventHandler(1)
         temShopingCart.forEach(product => {
            product.delivery_type = typeOrder
         })
         intervalRadioButton = setInterval(initShopingcard, 1000)
      }
   }

   //function get categories by brach office and by status
   const getCategoriesByBranchOffices = async (branchoffice_id, token) => {
      try {
         const response = await CategriesPosFetchAPI.getCategoriesByBranchOffices(branchoffice_id, 1, token);
         setCategories(response.data.data)
      } catch (error) {
         console.log(error);
      }
   }

   //function get products by branch offices and by status
   const getProductsByBranchOfficessByStatus = async (branchoffice_id, token) => {

      try {
         const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatus(branchoffice_id, 1, token)

         setProducts(response.data.data)
         setViewProducts(response.data.data)
         setTemporalProducts(response.data.data)

      } catch (err) {
         console.log(err);
      }
   }

   // function get segment by id product
   const getSegmentByProduct = async (group_id) => {
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         //For default we clean the segments of the state 
         setSegments([])
         //We get the active segments of the group
         const response = await ProductsFetchAPI.getSegmentsByProducts(group_id, 1, branchoffice.id, token);
         AlertSmall("Consulta exitosa")
         //ErrorAlert("", "Producto consultado", "success")
         //save item default by segment
         let arrayTem = new Array
         for (let k = 0; k < response.data.data.length; k++) {
            let segment = response.data.data[k]
            let itemDefault = 0
            let turnItem = false
            for (let f = 0; f < segment.items.length; f++) {
               if (segment.segment_item_id_default == segment.items[f].item_id) {
                  itemDefault = segment.items[f]
                  turnItem = true
               }
            }
            if (turnItem == true) {
               arrayTem.push({ segment: segment, item: itemDefault })
               setPreviewSegment(arrayTem)
            } else {
               arrayTem.push({ segment: segment, item: segment.items[0] })
               setPreviewSegment(arrayTem)
            }
         }

         //We set the segments 
         setSegments(response.data.data);
         setSelectProduct(response.data.group);

      } catch (err) {
         ErrorAlert("", "Error de consulta", "error")
         setShowModalSelectItem(false)
         setSegments([]);
         console.log(err);
      }
   }

   //view categories
   const listCategories = () => {
      const listCategorie = categories.map((categorie) => (
         <>
            {/* <option value={categorie.category_id} key={categorie.category_id}  >{categorie.category_name}</option> */}
            {/* <a className="nav-link"> {categorie.category_name}</a> */}
            <li>
               <div className="button-container">
                  <input type="radio" className="btn-check" name="options-outlined" id={categorie.category_id} value={categorie.category_id} autocomplete="off" onChange={(e) => selectCategorie(e.target.value)} />
                  <label className="btn btn-outline-primary rounded-pill px-2" htmlFor={categorie.category_id}>
                     <img className='rounded-circle' src={categorie.img} alt="Imagen 1" />
                     {categorie.category_name}
                  </label>
               </div>
            </li>
         </>
      ));
      return (listCategorie)
   }

   //function filter products by category
   const selectCategorie = async (category_id) => {
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      if (category_id == "todos") {
         setProducts(temporalProducts)
         setViewProducts(temporalProducts)
      } else {
         try {
            const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatusByCategories(category_id, branchoffice.id, 1, token)
            setProducts(response.data.data)
            setViewProducts(response.data.data)
         } catch (err) {
            setProducts([])
            setViewProducts([])
            console.log("No hay productos aqui ");
            console.log(err);
         }
      }
   }

   // function set product select
   const selectProductoInfo = async (info) => {
      setPreviewSegment([])

      //We set the amount of the product 
      setNumber(1);
      //We set for default the amount of the info product selected on 1 
      info.amount = 1;
      info.cantidadItem = 1;
      //We set the info of the product and segment 
      getSegmentByProduct(info.group_id)

   }

   //view product select
   const contentProduct = () => {
      const contentetProductInfo = (
         <>
            <div className='d-flex align-items-center justify-content-center m-2'>
               <img className='rounded-sp border bg-secondary ' width="300px" height="250px"
                  src={selectProduct.img} alt="img-product "
               />
            </div>
            <div className='product-content'>
               <h5 className='text-center '>{selectProduct.name}</h5>
               <p>{selectProduct.description}</p>
            </div>
            <div className='product-count'>
               <h4 className="fw-bold text-center mb-2" >{"$ " + selectProduct.price}</h4>
               {/* Cantidad/ Quantity*/}
               <div className='px-1 number-counter'>
                  <button onClick={decrementNumber}>-</button>
                  <p>{number}</p>
                  <button onClick={incrementNumber}>+</button>
               </div>
            </div>
         </>
      );
      return (contentetProductInfo)
   }

   //view segments by products
   const contentSegments = () => {
      const contentetProductInfo = segments.map((segment) => (
         <>
            <div>
               <h5 className='pt-1 fw-bold text-underline'>
                  <u>{segment.name}</u>
               </h5>
            </div>
            <div className='container '>
               <div className="row mt-1 ms-1">
                  {segmentsItems(segment)}
               </div>
            </div>
         </>
      ));
      return (contentetProductInfo)
   }

   //Function to populate the segment items
   const segmentsItems = (segment) => {

      let itemSegments = segment.items
      const listItems = itemSegments.map((item) => (
         <>

            <div className='col-12 col-md-4 col-lg-4 p-2 '>
               <input
                  className="list-group-item-check " type="radio" name={"listGroupCheckableRadios" + segment.segment_id}
                  id={"listGroupCheckableRadios" + segment.segment_id + item.item_id} onChange={() => (previewSegmentDetected(segment, item))}
                  defaultChecked={segment.segment_item_id_default === item.item_id || (!segment.segment_item_id_default && item.item_id === segment.items[0].item_id)}

               />
               <label className="list-group-item py-1 px-1 h-100" htmlFor={"listGroupCheckableRadios" + segment.segment_id + item.item_id}>
                  <div className="d-flex gap-3 py-3" >
                     <img src={item.img}
                        alt="Product" width="50" height="50" className="rounded-circle flex-shrink-0" />
                     <div className="d-flex gap-2 w-75 justify-content-around">
                        <div>
                           <h6 className="mb-0">{item.name}</h6>
                           <p className="mb-0 opacity-75">{item.description}</p>
                        </div>
                        <small className='fw-bold text-nowrap text-success'>{item.price == 0 ? "" : "+ $" + item.price}</small>
                     </div>
                  </div>
               </label>
            </div>

         </>
      ));
      return (listItems)
   }

   const previewSegmentDetected = (segment, item) => {
      let arrayTemporal = previewSegment
      if (previewSegment.length > 0) {
         let include = true;
         for (let i = 0; i < arrayTemporal.length; i++) {
            if (arrayTemporal[i].segment.segment_id == segment.segment_id) {
               arrayTemporal[i].item = item
               include = false
            }
         }
         if (include == true) {
            let jsonSegmentTem = { segment: segment, item: item }
            arrayTemporal.push(jsonSegmentTem)
         }
      } else {
         let jsonSegmentTem = { segment: segment, item: item }
         arrayTemporal.push(jsonSegmentTem)
      }
      setPreviewSegment(arrayTemporal)
      setEventHandlerPreviewsegment(1)
   }

   //Function to listen the swicht 
   const handleSwitchChange = async (event) => {
      //We set the data to update 

      let product = JSON.parse(event.target.value)
      let newDelivery_type = ""
      let validAllTypeOrder = true

      if (event.target.checked == true) {
         newDelivery_type = "comer aqui"
      } else {
         newDelivery_type = "para llevar"
      }

      let oldDelivery_typeItem = ""
      shoppingCart.forEach((item, index) => {
         if (JSON.stringify(item) == JSON.stringify(product)) {
            shoppingCart[index].delivery_type = newDelivery_type;
         }

         if (index !== 0) {
            if (item.delivery_type != oldDelivery_typeItem) {
               validAllTypeOrder = false
            }
         }
         oldDelivery_typeItem = item.delivery_type
      });

      if (validAllTypeOrder == false) {
         setCheckDeliveryOrder("mixto")
      } else {
         setCheckDeliveryOrder(newDelivery_type)
      }

      setShoppingCart(shoppingCart)
      setEventHandler(1)
   };


   const viewSelectSegmentItem = () => {
      const contentlistPreview = previewSegment.map((segmentpreview) => (
         <>
            <div className='col-sm-6 '>
               <h6 className="pt-1 fw-bold text-underline">
                  <u>{segmentpreview.segment.name}:</u>
               </h6>
               <div className='bg-light border-sp d-flex align-items-center justify-content-center' >
                  <div className='d-flex align-items-center justify-content-center'>
                     <img src={segmentpreview.item.img} className=" border-sp m-2 " width="30px" />
                  </div>
                  <div className='m-2 d-flex align-items-center justify-content-center'>
                     <h6>{segmentpreview.item.name}</h6>
                  </div>
               </div>
            </div>
         </>
      ));
      return (contentlistPreview)
   }

   //Function to add products to the shopping cart 
   const addProduct = (e) => {

      let temporalShopingCart = shoppingCart
      let turn = 0

      //------------------------------------------------------------
      e.preventDefault();
      //For default we set that the amount of the selected product depending the number 
      selectProduct.amount = number;
      selectProduct.cantidadItem = number;
      selectProduct.delivery_type = "comer aqui";
      selectProduct.unit_discount = 0;
      selectProduct.product_discount = 0;
      selectProduct.descuento = selectProduct.product_discount;
      //For default we initialice the segments empty of the selected product 
      selectProduct.segments = [];
      selectProduct.DetalleSegmentos = [];
      selectProduct.segments_string = ""
      //We set the segment items selected on the product selected 

      /*segments.forEach(segment => {
         segment.items.forEach(item => {
            console.log(item);
            //We get the radio buttons generated by id 
            let radiobutton = document.getElementById("listGroupCheckableRadios" + segment.segment_id + item.item_id);
            //We check the radio buttons checked and then we add the segment selected to the product selected
            if (radiobutton.checked) {
               selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax } })
               selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax } })
               selectProduct.price = selectProduct.price + item.price

            }
         })
      });*/
      let temporal_segment_string = ""
      for (let s = 0; s < segments.length; s++) {
         let segment = segments[s];
         for (let i = 0; i < segment.items.length; i++) {
            let item = segment.items[i]
            let radiobutton = document.getElementById("listGroupCheckableRadios" + segment.segment_id + item.item_id);

            if (radiobutton.checked) {
               temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax } })
               selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax } })
               selectProduct.price = selectProduct.price + item.price


            }
         }

      }

      //check item string segments into shopingcart
      for (let shopping = 0; shopping < temporalShopingCart.length; shopping++) {
         if (selectProduct.group_id == temporalShopingCart[shopping].group_id) {
            console.log(temporalShopingCart[shopping].segments_string);
            console.log(temporal_segment_string);
            if (temporalShopingCart[shopping].segments_string === temporal_segment_string) {
               console.log("este producto ya esta");
               temporalShopingCart[shopping].amount = (temporalShopingCart[shopping].amount + number)
               temporalShopingCart[shopping].cantidadItem = temporalShopingCart[shopping].amount
               turn = 1
            } else {

            }
         }
      }


      if (turn == 0) {
         //We add the new product to the shopping cart 
         setShoppingCart([...shoppingCart, selectProduct]);
         //We calculate the invoice totals 
         calculateInvoiceTotals([...shoppingCart, selectProduct])
         if (checkDeliveryOrder != "comer aqui") { setCheckDeliveryOrder("mixto") }
      } else {
         setShoppingCart(temporalShopingCart)
         //We calculate the invoice totals 
         calculateInvoiceTotals(temporalShopingCart)
      }


      let asholp = shoppingCart
      console.log('Carrito Products ', asholp);

      setPreviewSegment([])
      setSegments([])

   }

   //Function to delete a product of the shopping cart 
   const deleteProduct = (product_index) => {
      //We delete the product of the shopping cart list 
      shoppingCart.splice(product_index, 1);
      setShoppingCart([...shoppingCart]);
      //We recalculate the invoice totals 
      calculateInvoiceTotals([...shoppingCart])
   }

   //Function to incremet the product amount on the shopping cart 
   const incrementProductAmount = (product_index) => {
      //We search the product on the list 
      let product = shoppingCart[product_index];
      //We affect the product data 
      product.amount = product.amount + 1;
      product.cantidadItem = product.amount
      //We set the handler to recharge the data
      setEventHandler(1);
   };

   //Function to incremet the product amount on the shopping cart 
   const decrementProductAmount = (product_index) => {
      //We search the product on the list 
      let product = shoppingCart[product_index];
      //We affect the product data 
      product.amount = product.amount - 1;
      product.cantidadItem = product.amount

      //We check if the product amount is lower than 0
      if (product.amount <= 0) {
         //if is lower or equal to 0 we delete the product of the shopping cart list 
         shoppingCart.splice(product_index, 1);
         setShoppingCart([...shoppingCart])
      }
      //We set the handler to recharge the data
      setEventHandler(1)
   };

   //Function to calculate the invoice totals 
   const calculateInvoiceTotals = (products) => {

      //For every new calculate we set the totals 
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //if we dont we products we put the totals in 0 for default 
      if (products.length === 0) {
         //We calculate the totals of the invoice 
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
      } else {
         //For every product we calculate 
         for (let i = 0; i < products.length; i++) {
            let product_info = products[i]
            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product
            product_total_discount = (product_info.unit_discount * product_info.amount);
            //We set the total of the product 
            total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));
            //We set the itbms of the product 
            total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

            //We calculate the totals of the invoice 
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = invoiceSubtotal + total_product_price;
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
         }

         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);

      }
   }

   //Function to clear all the shopping cart 
   const clearShoppingCart = () => {
      if (shoppingCart.length > 0) {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-success',
               cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: 'Aun hay productos en la cartilla',
            text: "¿Esta seguro de cancelar la orden?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No!',
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
               swalWithBootstrapButtons.fire(
                  'Orden cancelada!',
                  '',
                  'success'
               )
               setShoppingCart([]);
               setProductChecked([])
               setEventHandler(1);
               setPageLoader(1)
            } else if (
               /* Read more about handling dismissals below */
               result.dismiss === Swal.DismissReason.cancel
            ) {
               swalWithBootstrapButtons.fire(
                  'La orden se mantiene!',
                  '',
                  'info'
               )
            }
         })
      } else {
         setShoppingCart([]);
         setProductChecked([])
         setEventHandler(1);
         setPageLoader(1)
      }
   }

   const handlePaymentRequestPreOrder = () => {
      createNotification("Solicitud de pago", 0)
      let title = 'Solicitud de pago enviada'
      let message = 'Espere un momento el supervisor esta procesando su orden....<br/> <br/>'
      LoadingAlert(title, message);
      getOrderById()
   }

   let intervalPreOrderPayment = 0
   const getOrderById = async () => {
      clearInterval(intervalPreOrderPayment)

      let token = localStorage.getItem('x-access-machine-token');
      try {
         const response = await PreOrderFetchAPI.getPreOrderById(dataPreOrder._id, token);
         intervalPreOrderPayment = setInterval(getOrderById, 10000)
      } catch (err) {
         ErrorAlert("La orden fue cobrada con exito", "Muchas gracias por su visita", "success")
         setShoppingCart([]);
         setProductChecked([])
         setEventHandler(1);
         setPageLoader(1)
         setShow(false)
         console.log(err);
      }
   }

   const buttonTypeOrdersDelivery = () => {
      let radioButtons = (
         <>
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" data-type="comer aqui" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "comer aqui"} />
            <label className="btn btn-outline-primary " for="btnradio1">Comer aquí</label>

            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" data-type="para llevar" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "para llevar"} />
            <label className="btn btn-outline-primary " for="btnradio2">Para llevar </label>

            <input type="radio" className="btn-check" name="btnradio" data-type="mixto" id="btnradio3" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "mixto"} disabled />
            <label className="btn btn-outline-primary " for="btnradio3">Mixto</label>
         </>
      )
      return (radioButtons)
   }

   const cancelAdd = () => {
      shoppingCart.length = shoppingCart.length - 1
      setShoppingCart(shoppingCart)
      setEventHandler(1)
   }

   //Hook to load the initial info
   useEffect(() => {
      (async () => {
         let token = localStorage.getItem('x-access-machine-token');


         let branchoffice = localStorage.getItem('branchoffice') !== '' ? JSON.parse(localStorage.getItem('branchoffice')) : '';

         try {
            //------------------------------------------------
            if (branchoffice !== '' && branchoffice !== null && branchoffice !== undefined) {
               getCategoriesByBranchOffices(branchoffice.id, token)
               getProductsByBranchOfficessByStatus(branchoffice.id, token)
            }
         } catch (err) {
            console.log(err);
         }
         setShoppingCart([])
         setPreOrderInvoice(false)
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setPageLoader(0)
         setCheckDeliveryOrder("comer aqui")
         setName("Cliente generico")
         setDv(0)
         setRuc(0)
         setRegisterTurnOrder(0)
         setDataResponse(false)
         setOptionalData(false)
         setDataPreOrder({ pre_Order: false, _id: "" })

      })();
   }, [pageLoader]);

   //Hook to recharge data of the shopping cart 
   useEffect(() => {



      setShoppingCart([...shoppingCart]);
      calculateInvoiceTotals([...shoppingCart])
      setEventHandler(0);
   }, [eventHandler]);

   //Hook to recharge data of preview segments
   useEffect(() => {
      setPreviewSegment([...previewSegment])
      setEventHandlerPreviewsegment(0)
   }, [eventHandlerPreviewsegment]);


   const buttons = [
      'uil uil-glass',
      'uil uil-glass-martini',
      'uil uil-bill',

   ];

   return (
      <>

         <div className='container-pre-account no-scroll'>
            <header className='position-relative bg-light border rounded-3'>
               <div className='header-preaccount bg-white'>
                  <div className="d-flex  align-items-center justify-content-between">            
                     {/* <Button className=' btn btn-light btn-sm' onClick={() => (handleReload())} type='button'> <i className="uil uil-refresh m-1"></i>Actualizar</Button> */}
                     <a href="javascript:location.reload()" className="d-flex bg-white align-items-center text-dark text-decoration-none">
                        <img
                           className="navbar-logo-post"
                           src={logo_sp} alt="Logo Smart Pay"
                        />
                        <span className="h5 pt-2 me-3 fw-bold align-self-center">Smart Pay</span>
                     </a>

                     <div className='mx-3'>
                        <span className="fw-bold">Mesa: {JSON.parse(localStorage.getItem('table')).name}</span>
                     </div>
                  </div>
               </div>
            </header>

            <div className="container-categories-pre ">
               <div className="container-categories-list-pre no-scroll">
                  <ul className="lista-botones">
                     <li>
                        <form className="button-container mt-2">
                           <div>
                              <input
                                 spellcheck="false"
                                 className="form-control rounded-pill py-2"
                                 type="text"
                                 step="any"
                                 placeholder="Buscar productos..."
                                 onChange={e => setSearchTerm(e.target.value)}
                              />
                           </div>
                        </form>
                     </li>

                     <li>
                        <div className="button-container ">
                           <input type="radio" className="btn-check"
                              name="options-outlined"
                              key={0} id="cateforieAll"
                              value={"todos"}
                              onChange={(e) => selectCategorie(e.target.value)}
                           />
                           <label className="btn btn-outline-primary rounded-pill px-2" htmlFor="cateforieAll">
                              <img className='rounded-circle' src={all_products} alt="Imagen 1" />
                              Todos
                           </label>
                        </div>
                     </li>
                     {listCategories()}

                  </ul>
               </div>
            </div>


            {/* Menu */}
            <div className="content-main-pre-account no-scroll ">
               <div className="gap-1 grid-main-preaccount mx-2" >
                  <main className="main-pre-account bg-light rounded-3 no-scroll ">
                     <div className='row  g-2  no-scroll menu-pre '>
                        {!filteredProducts.length
                           ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                           : filteredProducts.map((product) => (
                              <>
                                 <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-2 border-product"
                                    data-product={JSON.stringify(product)}
                                    onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                 >
                                    <div className="card border-0 shadow-sm rounded-sp ">
                                       <img src={product.img} className="card-img-pre-acoount" alt="Product" />

                                       <div className="card-body">
                                          <h5 className="card-title text-center fw-bold text-truncate text-dark"> {product.name}</h5>
                                          <h6 className="card-text text-center text-success fw-bold">${product.price}</h6>
                                       </div>
                                    </div>
                                 </div>
                              </>
                           ))
                        }
                     </div>
                  </main>
               </div>
            </div>
         </div>

         <aside className='buttons-accions'>
            <div className='buttons-accions-grid'>
               <div className='section-shopping-button d-flex align-items-center'>
                  <button type="button" className=" btn floating-button position-relative w-auto px-3" title='Mi Orden ' onClick={handleShow}>
                     <small className='fw-bold opacity-75 text-center '>
                        <i className="uil uil-receipt-alt mx-1"></i>Mi Orden
                     </small>
                     <div>
                        <span className='fw-bold'>
                           {invoiceTotal.toFixed(2)}
                        </span>
                        <span> $</span>
                     </div>
                     <span title='Cantidad de Productos'
                        className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success ">
                        <i className="uil uil-crockery"></i>   : {shoppingCart.length}
                     </span>
                  </button>
               </div>

               {/* Actions Buttons  */}
               <div className='section-acction d-flex align-items-center ms-2 '>
                  <div className="container-list-buttons no-scroll">
                     <ul>
                        {buttons.map((button, index) => (
                           <li>
                              <button className='btn btn-warning buttons-accions-pa rounded-circle' key={index} onClick={() => createNotification("Solicitud de atencion", 1)}>
                                 <i className={button}></i>
                              </button>
                           </li>
                        ))}
                     </ul>
                     {/* <span title='Cantidad de Productos'
                        className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success ">
                        <i className="uil uil-crockery"></i>   : 9
                     </span>                   */}
                  </div>
               </div>
            </div>
         </aside>
         {/* Shopping Cart  */}
         <div>

         </div>
         {/* Action Buttons */}




         {/* End Action Buttons */}


         <div className="no-scroll">
            <main className="w-100">
               <section className="mb-0 pb-0               
               border-start border-2"
               // ! section-menu-board 
               >

                  {/* <div className='header-main-menu'>
                     <div className='bd-highlight w-100'>
                        <header className="bg-primary">
                           <div className='w-100'>
                              <NavBar setPageLoader={setPageLoader} />
                           </div>
                        </header>
                     </div>

                  </div> */}
                  {/*  Categories  */}
                  {/* <div className="bg-white m-1 w-100">
                     <div className="container-list no-scroll">
                        <ul>
                           <li>
                              <form className="button-container">                
                                 <div>
                                    <input
                                       spellcheck="false"
                                       className="form-control rounded-pill"
                                       type="text"
                                       step="any"
                                       placeholder="Buscar productos..."
                                       onChange={e => setSearchTerm(e.target.value)}
                                    />
                                 </div>
                              </form>

                           </li>
                           <li>
                              <div className="button-container ">
                                 <input type="radio" className="btn-check"
                                    name="options-outlined"
                                    key={0} id="cateforieAll"
                                    value={"todos"}
                                    onChange={(e) => selectCategorie(e.target.value)}
                                 />
                                 <label className="btn btn-outline-primary rounded-pill border-0" htmlFor="cateforieAll">
                                    <img className='rounded-circle' src={all_products} alt="Imagen 1" />
                                    Todos
                                 </label>
                              </div>
                           </li>
                           {listCategories()}
                        </ul>
                     </div>
                  </div> */}
                  {/*  Products  */}
                  {/* <div className="bg-light p-2 ">
                     <div className='row'>
                        <div className='col-1'>
                           <div className='px-2 bg-danger mt-5'>
                              Botones de Accion
                           </div>

                           <div className='mt-5'>
                              <button className='btn btn-primary ' onClick={handleShow}>
                                 Shopping Cart
                                 12.00$
                              </button>
                           </div>
                        </div>

                        <div className='col-11'>
                           <div className='row product-table-menu g-2  no-scroll'>                             
                              {!filteredProducts.length
                                 ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                                 : filteredProducts.map((product) => (
                                    <>
                                       <div className="col-4 col-md-4 col-lg-2 border"                                        
                                          data-product={JSON.stringify(product)}                                    
                                          onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                       >
                                          <div className="card border-0 shadow-sm rounded-sp">
                                             <img src={product.img} className="card-img-top" alt="Product" />

                                             <div className="card-body">
                                                <h5 className="card-title h6 text-center text-dark"> {product.name}</h5>
                                                <h6 className="card-text text-center text-success fw-bold">${product.price}</h6>
                                             </div>
                                          </div>
                                       </div>
                                    </>
                                 ))
                              }                           
                           </div>
                        </div>
                     </div>
                  </div> */}
               </section>
               {/* :::::End 2::::: */}
            </main>
         </div >

         {/* <div className='shopping-wrapper border-inset border rounded-sp no-scroll'>
         </div> */}


         <Modal show={show} onHide={handleClose} fullscreen={fullscreen}>
            <Modal.Body>
               <h4 className='text-center fw-bold'><HiShoppingCart></HiShoppingCart> Carrito</h4>
               {/* <div className="w-100 mb-1" >
                  <div className='row gx-1'>
                     <div className="col-6 col-md-6 col-lg-3  ms-lg-0">
                        <ModalDiscounts productChecked={productChecked} shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} itbms={invoiceTotalItbms} discount={invoiceTotalDiscount} setLoad={setEventHandler} setShoppingCart={setShoppingCart} setProductChecked={setProductChecked} />
                     </div>
                     <div className="col-6 col-md-6 col-lg-3  ms-lg-0">
                        <ModalCoupons shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} itbms={invoiceTotalItbms} discount={invoiceTotalDiscount} setLoad={setEventHandler} setShoppingCart={setShoppingCart} />
                     </div>
                     <div className="col-6 col-md-6 col-lg-3  ms-lg-0">
                        <ModalPendingOrders preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} />

                     </div>
                     <div className="col-6 col-md-6 col-lg-3  ms-lg-0">
                        <ModalReturn />
                     </div>
                  </div> 
               </div>*/}
               <div className='bd-highlight  w-100'>
                  <div className="d-flex justify-content-between mb-2">

                     <div className="btn-group border btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                        {buttonTypeOrdersDelivery()}
                     </div>

                     <div className='d-flex justify-centent-end'>
                        {/*<ModalCreatePreOrder checkDeliveryOrder={checkDeliveryOrder} dv={dv} name={name} ruc={ruc} setDv={setDv} setName={setName} setRuc={setRuc} dataResponse={dataResponse} optionalData={optionalData} setDataResponse={setDataResponse} setOptionalData={setOptionalData} shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} tax={invoiceTotalItbms} discount={invoiceTotalDiscount} setPageLoader={setPageLoader} dataPreOrder={dataPreOrder} />*/}
                        {/*<button type="button" className="btn btn-outline-danger border btn-sm" onClick={clearShoppingCart} ><i className="uil uil-trash-alt"></i> Eliminar Todo </button>*/}
                     </div>
                  </div>
               </div>
               <div className='pila-shopping-cart border border-inset rounded no-scroll bg-light'>
                  <div>
                     {
                        !shoppingCart.length
                           ? <Empty title="Vacío" msg="No hay ningun producto agregado en el carrito" img={empty_img} />
                           : shoppingCart.map((product) => (
                              <div >
                                 <div className={product.discount || product.unit_discount > 0
                                    ? "d-flex border rounded border-warning border-2  mt-3 position-relative bg-white"
                                    : "d-flex shadow-sm rounded m-1 bg-white"}
                                 >
                                    <div className="flex-shrink-0">
                                       <img className='m-1 rounded shadow-sm bg-light border ' src={product.img} alt="..." width="80" height="80" />
                                    </div>
                                    <div className="flex-grow-1 m-1">
                                       <div className="d-flex bd-highlight">
                                          <div className="bd-highlight title-product-cart mt-1">{product.name} </div>
                                          <div className="ms-auto px-2 bd-highlight text-price-cart">{product.amount} x $ {product.price.toFixed(2)}</div>
                                       </div>
                                       <div className="d-flex bd-highlight text-muted opacity-75">
                                          <details open>
                                             <summary> <small> Detalles </small> </summary>
                                             {product.segments.map(item => (
                                                <small
                                                   className="badge bg-light text-dark">
                                                   {item.segment_name}: {item.name} ${item.price}
                                                </small>
                                             ))}
                                          </details>
                                       </div>

                                       {/*<div className="d-flex justify-content-between mt-1">
                                          <div className=" ">
                                             <span className='px-1 number-counter'>
                                                <button data-id={index} onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>-</button>
                                                <p>{product.amount}</p>
                                                <button data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button>
                                             </span>
                                          </div>
                                          <div className='text-capitalize'>
                                             <Form.Switch
                                                type="switch"
                                                id={product.group_id}
                                                value={JSON.stringify(product)}
                                                label={product.delivery_type}
                                                defaultChecked={product.delivery_type === "comer aqui" ? true : false}
                                                onChange={handleSwitchChange}
                                             />
                                          </div>
                                          <div className=" px-2 bd-highlight fw-bold">
                                             <button
                                                className='btn btn-outline-danger btn-sm border-0'
                                                title="Eliminar Producto"
                                                data-id={index++}
                                                onClick={e => deleteProduct(e.currentTarget.dataset.id)}>
                                                <i className="uil uil-trash-alt"></i>
                                             </button>
                                          </div>
                                       </div>*/}
                                    </div>

                                    <span className={product.discount || product.unit_discount > 0
                                       ? "position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning"
                                       : "visually-hidden"}>
                                       Descuento aplicado: - {product.unit_discount}
                                    </span>
                                 </div>
                              </div>
                           ))
                     }
                  </div>

               </div>
               <div className="px-2 bd-highlight  w-100">
                  <div className="mb-0 small lh-sm mt-1">
                     <div className="d-flex justify-content-between">
                        <small className="text-muted h6"> Descuento: </small>
                        <span className="text-warning text-secondary h6">
                           <span className="px-2 ">- $</span>
                           <strong> {invoiceTotalDiscount.toFixed(2)} </strong>
                        </span>
                     </div>
                     <div className="d-flex justify-content-between ">
                        <small className="text-muted h6"> Subtotal: </small>
                        <span className="text-black text-secondary h6">
                           <span className="px-2 ">$</span>{invoiceSubtotal.toFixed(2)}</span>
                     </div>
                     <div className="d-flex justify-content-between">
                        <small className=" text-muted h6"> Itbms:
                        </small><span className="text-black text-secondary h6">
                           <span className="px-2 ">$</span>{invoiceTotalItbms.toFixed(2)}</span>
                     </div>

                     <div className="d-flex justify-content-between total-shopping">
                        <strong className="text-black fw-bold h6 mt-1"> Total <small>(Incluye itbms)</small>: </strong>
                        <span className="text-black fw-bold h5">
                           <span className="px-2 ">$
                           </span>{invoiceTotal.toFixed(2)}</span>
                     </div>
                  </div>

               </div>
               {/* :::::1::::: */}
               {/* <div className="section-shopping-cart-pre  w-100">
                  <section className="w-100"> */}
               {/* Buttons Headers  */}

               {/* End Buttons Headers  */}
               {/* 2 */}

               {/*  */}

               {/* <div className="mb-auto px-1  bd-highlight w-100 shopping-wrapper border  border-inset rounded no-scroll bg-light" >

                     </div> */}

               {/* 3 */}

               {/* </section>
               </div>  */}
               {/* :::::End 1::::: */}

            </Modal.Body>
            <Modal.Footer className=' d-flex align-item-center justify-content-between'>
               <button className=' btn btn-primary' onClick={() => setShow(false)}>
                  <strong>Agregar más productos</strong>
               </button>

               <button onClick={() => (handlePaymentRequestPreOrder())} className=' btn btn-success'>
                  <strong>Solicitar pago</strong>
               </button>
               {/*<ModalPaymet preOrderInvoice={preOrderInvoice} dv={dv} name={name} ruc={ruc} setDv={setDv} setName={setName} setRuc={setRuc} dataResponse={dataResponse} optionalData={optionalData} setDataResponse={setDataResponse} setOptionalData={setOptionalData} shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} discount={invoiceTotalDiscount} tax={invoiceTotalItbms} setPageLoader={setPageLoader} dataPreOrder={dataPreOrder} checkDeliveryOrder={checkDeliveryOrder} registerTurnOrder={registerTurnOrder} setRegisterTurnOrder={setRegisterTurnOrder} />*/}
            </Modal.Footer>
         </Modal>

         {/* Modal-Products Information*/}

         <Modal
            show={showModalSelectItem}
            fullscreen
            onHide={() => setShowModalSelectItem(false)}
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header>
               <h5 className="modal-title" id="exampleModalLabel">Detalles de Producto</h5>
            </Modal.Header>
            <Modal.Body>
               <div className='row'>
                  {/* Product's Information*/}
                  <div className='col-12 col-md-5 col-lg-4  py-1 px-2 '>
                     <div className='position-info-suggest m-2'>
                        {/*<div className='position-fixed-info'>
                                 {contentProduct()}                                
                              </div>*/}
                        <div className='m-5 p-2'>
                           {contentProduct()}
                        </div>
                        <div className='mt-4 row'>
                           {viewSelectSegmentItem()}
                        </div>
                     </div>
                  </div>
                  {/* Product's Suggestions */}
                  <div className='col-12 col-md-7 col-lg-8  px-2'>
                     <div className='suggestions-over'>
                        <div className='suggestions-wrapper no-scroll h-100'>
                           {contentSegments()}
                        </div>
                     </div>
                     <Form>
                        <Form.Group className="m-3" controlId="exampleForm.ControlTextarea1">
                           <Form.Label>Notas:</Form.Label>
                           <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                     </Form>
                  </div>
               </div>

            </Modal.Body>
            <Modal.Footer>
               <button type="button" className="btn btn-danger btn-lg" onClick={() => (setShowModalSelectItem(false), setPreviewSegment([]))}>Cancelar</button>
               <button type="button" className="btn btn-success btn-lg" onClick={e => (setShowModalSelectItem(false), addProduct(e), setShowUpdateAccount(true))}>Agregar</button>
            </Modal.Footer>
         </Modal>


         <Modal
            show={showUpdateAccount}
            size="md"
            onHide={() => (setShowUpdateAccount(false), cancelAdd())}
            aria-labelledby="example-custom-modal-styling-title"
            centered
         >
            <div className='mt-2 text-center p-1'>
               <h4 className='fw-bold'><i className="uil uil-plus-square"></i> Agregar nuevo producto </h4>
            </div>


            <Modal.Body>
               <div className='d-flex justify-content-between mt-2'>
                  <button className='btn btn-danger border-0 btn-lg' onClick={() => (setShowUpdateAccount(false), cancelAdd())}> Cancelar </button>
                  <ModalCreateOrderTable setShowModalShopingCart={setShow} checkDeliveryOrder={checkDeliveryOrder} dv={dv} name={name} ruc={ruc} setDv={setDv} setName={setName} setRuc={setRuc} dataResponse={dataResponse} optionalData={optionalData} setDataResponse={setDataResponse} setOptionalData={setOptionalData} shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} tax={invoiceTotalItbms} discount={invoiceTotalDiscount} setPageLoader={setPageLoader} dataPreOrder={dataPreOrder} setShowUpdateAccount={setShowUpdateAccount} setDataPreOrder={setDataPreOrder} setCheckDeliveryOrder={setCheckDeliveryOrder} setShoppingCart={setShoppingCart} setInvoiceTotal={setInvoiceTotal} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotalItbms={setInvoiceTotalItbms} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setEventHandler={setEventHandler} />
               </div>
            </Modal.Body>
         </Modal>




      </>
   )
}

export default PaymentPost