import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
//Icons 
import { FaCcVisa, FaPhoneAlt } from 'react-icons/fa';




function ModalPhoneSaveYappy({showModalPhone, setShowModalPhone, setPhoneClientYappy, nextStepYappy}) {
   

    return (
        <>

              {/*input phone client */}
      <Modal
        show={showModalPhone}
        fullscreen
        onHide={() => setShowModalPhone(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >

        <Modal.Header className='border-0'>
          <strong> YAPPY </strong>
        </Modal.Header>
        <Modal.Body className='bg-pay-met'>
          <main className='d-flex justify-content-center align-items-center h-100 '>

            <section className='border rounded p-4 w-50 position-relative bg-white'>
              <div className='sections-messages'>

                <div className='mt-2 text-center p-1'>
                  <h4 className='fw-bold'> <FaPhoneAlt /> Ingrese numero de telefono </h4>
                </div>
                <div className="input-group input-group-lg">
                  <input type="text" className="form-control" placeholder='6000-0000' onChange={(e) => (setPhoneClientYappy(e.target.value))} />
                </div>

                <div className='d-flex justify-content-between mt-3'>
                  <button className='btn btn-danger btn-lg' onClick={() => setShowModalPhone(false)}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={() => nextStepYappy()}> Aplicar  </button>
                </div>
              </div>
            </section>
          </main>



        </Modal.Body>
      </Modal>

        </>
    )
}

export default ModalPhoneSaveYappy