import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { View } from '@react-pdf/renderer';
import videoInit from './videos/videoGeneric.mp4'
import { PublicityFechtAPI } from '../../api/Publicity';

function VendingIdel({ showModalIdel, setShowModalIdel, publicity, video }) {

    let token = localStorage.getItem("x-access-token");
    let machine = localStorage.getItem('machine_vending');
    
    
    const [handlerchange, setHandlerchange] = useState(0)
    


    if (machine != undefined && machine != "") {
        machine = JSON.parse(localStorage.getItem('machine_vending'))
    }

 
    let activity =0
 
    useEffect(() => {
        (async () => {
            
        })()
    }, []);

    
   
    //timer activity and time

    {/*useEffect(() => {
        const interval = setInterval(() => {
            activity =(activity+1);
            
          
            //setHandlerchange(1)
            //console.log("video " + activity);
        }, 15000); // Ejecutar cada 1000 ms (1 segundo)

        return () => {
            clearInterval(interval); // Limpiar el intervalo al desmontar el componente
        };
    }, []);*/}

    return (
        <div>
            <Modal
                show={showModalIdel}
                fullscreen
                onHide={() => setShowModalIdel(false)}
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Body className='p-0'>
                    <video src={video} type="video/mp4" autoPlay={"autoplay"} muted loop onClick={() => setShowModalIdel(false)} ></video>

                    <View style={{ flex: 1 }}>
                        <View style={{ borderWidth: 1, position: 'absolute', bottom: 0, left: 280, alignSelf: 'flex-end' }}>
                            <div className='d-flex justify-content-center alight-item-center'>
                                <Button className='btn btn-success btn-lg mt-1 px-5 btn-vending btn-vending-success ' onClick={() => { setShowModalIdel(false) }}><i className="uil uil-shop"></i> COMPRAR AQUI</Button>
                            </div>
                        </View>
                    </View>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default VendingIdel