import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';
import ModalDivisionPreOrder from './ModalDivisionPreOrder';

import Swal from "sweetalert2";
import imgPreOrder from './img/preOrder.png'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import { GiPlayerTime } from 'react-icons/gi';
import Empty from '../utils/Empty';


function ModalPendingOrders({ actvLite, actvPlus, preOrderInvoice, setPreOrderInvoice, setCheckDeliveryOrder, setProductChecked, setPageLoader, setEventHandler, shoppingCart, setDv, setName, setRuc, setDataResponse, setOptionalData, setShoppingCart, setInvoiceSubtotal, setInvoiceTotal, setInvoiceTotalDiscount, setInvoiceTotalItbms, setDataPreOrder, setTableInfo, setTaxesArrayAll }) {
   let title_modal = "Precuentas"
   const [show, setShow] = useState(false);
   const [preOrders, setPreOrders] = useState([]);
   const [selectPreOrder, setSelectPreOrder] = useState({ order: "" })
   let machine_token = localStorage.getItem('x-access-machine-token');
   //action generated 
   const getAll = async () => {

      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))
      getPreOrder(business.code, branchoffice.code, token)

      setShow(true)
   }

   //get pre orders
   const getPreOrder = async (business_code, branch_code, token) => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         const response = await PreOrderFetchAPI.getPreOrder(business_code, branch_code, token)
         console.log(response.data.pre_orders);

         let dataPreOrder = response.data.pre_orders
         let newArrayPreorder = []
         dataPreOrder.forEach(order => {
            if (branchoffice.code == order.branchoffice.code) {
               newArrayPreorder.push(order)
            }
         });
         setPreOrders(newArrayPreorder)
      } catch (err) {
         console.log(err);
         setPreOrders([])
      }
   }

   //delete preorder
   const deletePreOrder = async (code) => {
      LoadingAlert()
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))
      let user = JSON.parse(localStorage.getItem('user'));

      let jsonUpdate = {
         "order_code": code,
         "business_code": business.code,
         "branchoffice_code": branchoffice.code,
         "deleted": true,
         "status": 5,
         "user_id": user.id
      }
      try {
         //We save the preorder
         await DeletedTempOrdersFetchAPI.newDeletedTempOrder(jsonUpdate, machine_token);
         //We delete the preorder
         await PreOrderFetchAPI.deletePreOrder(jsonUpdate, machine_token)
         let message = "precuenta eliminada con exito."
         let title = "Precuenta eliminada"
         let icon = "success"
         ErrorAlert(message, title, icon)
         getAll()
      } catch (err) {
         console.log(err);
         ErrorAlert("Esta precuenta pertenece a otra sucursal", "Permiso denegado", "info")
      }
   }

   let intervalAply = 0
   const questionConfirmation = () => {
      if (shoppingCart.length > 0) {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-success',
               cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: 'Aun hay productos en la cartilla',
            text: "¿Quieres aplicar la precuenta?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si aplicar!',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
               swalWithBootstrapButtons.fire(
                  'Precuenta aplicada!',
                  'success'
               )
               setShoppingCart([]);
               setProductChecked([])
               LoadingAlert()
               intervalAply = setInterval(aplyPreOrder, 1000);

            } else if (
               /* Read more about handling dismissals below */
               result.dismiss === Swal.DismissReason.cancel
            ) {
               swalWithBootstrapButtons.fire(
                  'Precuenta no aplicada!',
                  '',
                  'info'
               )
            }
         })
      } else {
         setShoppingCart([]);
         setProductChecked([])
         LoadingAlert()
         intervalAply = setInterval(aplyPreOrder, 1000);

      }
   }


   const aplyPreOrder = () => {
      clearInterval(intervalAply)
      let message = ""
      let title = ""
      let icon = ""
      ErrorAlert(message, title, icon)
      if (selectPreOrder.order == "") {
         console.log("no hay pre cuenta seleccionada");
         message = "No ha selecciona ninguna precuenta."
         title = "Bebe seleccionar una precuenta."
         icon = "info"
         ErrorAlert(message, title, icon)
      } else {

         setDataPreOrder({ pre_Order: true, _id: selectPreOrder._id, code: selectPreOrder.code })
         setName(selectPreOrder.client_name)

         if (selectPreOrder.client_ruc && selectPreOrder.client_dv) {
            setRuc(selectPreOrder.client_ruc)
            setDv(selectPreOrder.client_dv)
         } else {
            setRuc("0")
            setDv("0")
         }

         if (selectPreOrder.table_id && selectPreOrder.table_code) {
            setTableInfo({table_id:selectPreOrder.table_id, table_code:selectPreOrder.table_code, table_name: selectPreOrder.table_name })
         } else {
            setTableInfo({table_id:0, table_code:"", table_name: "" })
         }

         setDataResponse(true)
         setOptionalData(true)
         setShow(false)

         console.log(selectPreOrder);

         //format json 
         for (let i = 0; i < selectPreOrder.groups.length; i++) {

            for (let s = 0; s < selectPreOrder.groups[i].segments.length; s++) {

               selectPreOrder.groups[i].segments[s].img = selectPreOrder.groups[i].segments[s].item.img
               selectPreOrder.groups[i].segments[s].item_id = selectPreOrder.groups[i].segments[s].item.item_id
               selectPreOrder.groups[i].segments[s].name = selectPreOrder.groups[i].segments[s].item.name
               selectPreOrder.groups[i].segments[s].segment_name = selectPreOrder.groups[i].segments[s].name
               selectPreOrder.groups[i].segments[s].cantidadItem = selectPreOrder.groups[i].segments[s].item.amount
               selectPreOrder.groups[i].segments[s].description = selectPreOrder.groups[i].segments[s].item.description
               selectPreOrder.groups[i].segments[s].tax = selectPreOrder.groups[i].segments[s].item.tax
            }
            selectPreOrder.groups[i].DetalleSegmentos = selectPreOrder.groups[i].segments
            selectPreOrder.groups[i].cantidadItem = selectPreOrder.groups[i].amount
            selectPreOrder.groups[i].descuento = selectPreOrder.groups[i].discount
         }

         console.log(selectPreOrder);
         console.log("Aplicando la orden");

         message = "Orden consultada."
         title = "Operacion exitosa"
         icon = "success"
         setPreOrderInvoice(true);
         setTaxesArrayAll(selectPreOrder.taxes)
         ErrorAlert(message, title, icon)
         setCheckDeliveryOrder(selectPreOrder.order_type)
         setShoppingCart(selectPreOrder.groups)
         setInvoiceTotal(selectPreOrder.total)
         setInvoiceSubtotal(selectPreOrder.subtotal)
         setInvoiceTotalItbms(selectPreOrder.tax)
         
         setInvoiceTotalDiscount(selectPreOrder.discount)
         setEventHandler(1)
      }
   }
   return (
      <>

         {actvPlus ?
            <button className='btn btn-outline-primary  border  rounded-pill me-1 shadow-sm bg-seconadary p-2 bg-opacity-10' onClick={() => getAll()}>
               <i className="uil uil-book-reader icon-mobile"></i>
               <small className='fw-bold label-responsive hidden-label-sm'>
                  {title_modal}
               </small>
            </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => getAll()}>
               <i className="uil uil-book-reader buttons-label icon-mobile h3"></i>
               <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
            </button>
         </div> : <></>}

         <Modal
            show={show}
            size="xl"
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
         >
            <div className='mt-2 text-center p-1'>
               <h4 className='fw-bold'>  <GiPlayerTime /> {title_modal} </h4>
            </div>
            <Modal.Body scrollable>
               <p className='fw-bold sticky-top bg-white'>Ordenes Pendientes </p>
               <div className="row">
                  {/* Init Component */}
                  {!preOrders.length
                     ? <Empty title="Vacío" msg="No hay ordenes pendientes" />
                     : preOrders.map((order) => (
                        <>
                           <div className='col-12 col-md-4 col-lg-4 p-2 rounded'>
                              <input
                                 className="list-group-item-check rounded" type="radio"
                                 autocomplete="off" value={JSON.stringify(order)} name="options-outlined" id={order.code + "preOrder"}
                                 onChange={(e) => (setSelectPreOrder(JSON.parse(e.target.value)))} />
                              <label className="list-group-item py-1 px-1 h-100"
                                 htmlFor={order.code + "preOrder"}
                              >
                                 <div className="d-flex gap-3 py-3 rounded" >
                                    <img src={imgPreOrder}
                                       alt="Product" width="50" height="50" className="rounded-circle flex-shrink-0" />
                                    <div className="d-flex gap-2 w-75 justify-content-around">
                                       <div>
                                          <h6 className="mb-0 fw-bold">Cliente: {order.client_name}</h6>
                                          <p className="mb-0 opacity-75">Order: {order.transaction_code}</p>
                                          <p className="mb-0 opacity-75">Fecha {order.createdAt.slice(0, 10)}</p>
                                          <span className="text-nowrap text-secondary h6">
                                             <i className="fa-solid fa-store"></i>&nbsp;
                                             Sucursal: {order.branchoffice.name}
                                          </span>
                                         
                                          <span className="text-nowrap text-secondary h6">
                                             <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                                             Máquina: {order.machine_code}
                                          </span>
                                          <span className="text-nowrap text-primary h6 d-block mb-2">
                                             <i className="fa-solid fa-cash-register"></i>&nbsp;
                                             {!order.machine_name ? 'No es caja' : order.machine_name} / {!order.user ? 'No es cajera' : order.user.name}
                                          </span>
                                          <span className="text-nowrap text-secondary h6 d-block">
                                             <i class="uil uil-list-ol"></i>
                                             Cantidad de productos: {order.groups.length}
                                          </span>
                                       </div>





                                    </div>

                                 </div>
                                 <div className='d-flex justify-content-between'>
                                    <ModalDivisionPreOrder order={order} getAll={getAll} ></ModalDivisionPreOrder>
                                    <button className='btn btn-danger btn-sm btn-block m-1'
                                       data-order={order.code}
                                       onClick={async (e) => deletePreOrder(e.currentTarget.dataset.order)}>
                                       <i className="uil uil-trash-alt"> Eliminar</i>
                                    </button>
                                 </div>
                              </label>
                           </div>


                        </>

                        // <div className="col-12 col-md-12 col-lg-4 mt-lg-2">
                        //    <label className="list-group-item align-items-center d-flex gap-3 ps-2 " htmlFor={order.code + "preOrder"} >
                        //       <input className="form-check-input flex-shrink-0" type="radio" autocomplete="off" value={JSON.stringify(order)} name="options-outlined" id={order.code + "preOrder"} onChange={(e) => (setSelectPreOrder(JSON.parse(e.target.value)))} />
                        //       <span className="pt-1 form-checked-content">
                        //          <a href="#" className="list-group-item-action d-flex gap-3 py-3" aria-current="true">
                        //             <img src={imgPreOrder} alt="twbs" width="70" height="70" className="rounded flex-shrink-0  " />

                        //             <div className="d-flex gap-2 w-100 justify-content-around">
                        //                <div>
                        //                   <h6 className="text-center mt-2">Order: {order.code}</h6>
                        //                   <h6 className="mb-0 text-center">{order.client_name}</h6>
                        //                   <p className="mb-0 opacity-75 text-center">{order.createdAt.slice(0, 10)}</p>
                        //                </div>
                        //             </div>
                        //             <h4 className="opacity-50 text-nowrap text-center m-3">$ {order.total.toFixed(2)}</h4>
                        //             <button className='btn btn-outline-danger btn-sm border-0' data-order={order.code} onClick={async (e) => deletePreOrder(e.currentTarget.dataset.order)}> <i className="uil uil-trash-alt"></i></button>
                        //          </a>
                        //       </span>
                        //    </label>                    
                        // </div>

                     ))
                  }
                  {/* End Component */}
               </div>
               <div className='d-flex justify-content-between mt-3'>
                  <button className='btn btn-danger btn-lg' onClick={() => setShow(false)}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={() => (questionConfirmation())}> Enviar al carrito  </button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   )
}

export default ModalPendingOrders