import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class OrderNumberFetchAPI {
    static async getOrderNumber(branchoffice_id, token) {

        const res = await server(`/last_order/${branchoffice_id}`,{
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async registerOrder(data, token) {
        const res = await server(`/order`,{
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }


}