import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

//Api 
import { CustomerTransactionsFetchAPI } from '../../api/CustomerTransactions';
import "./table_order.css"
//img
import error from './img/error.png'
import success from './img/success.png'
import bella from './img/bella1.gif'
import bellaSuccess from './img/bellahappy.gif'
import bellaError from './img/bellabad.gif'
import logo from './img/logo.png'
import { RenderIf } from '../utils/RenderIf';


function PageConfirmation() {

    const navigate = useNavigate();


    const [show, setShow] = useState(false)
    const [tableNumber, setTableNumber] = useState("")
    const [contentResponse, setContentResponse] = useState({gif: bella, img: "/", title: "Title", text: "text" })
    const [modification, setModification] = useState(false)
    const [updateTable, setUpdateTable] = useState(true)

    let { business_code, branch_code, table_num, transaction_code } = useParams();
    let intervalModal = 0
    const endModalResponse = () => { navigate("/confirmation/business/" + business_code + "/branchoffice/" + branch_code + "/table/" + tableNumber + "/transaction/" + transaction_code); clearInterval(intervalModal); setShow(false) }

    const getOrderByCode = async () => {
        try {
            const response = await CustomerTransactionsFetchAPI.getordersBycode(business_code, branch_code, transaction_code);
            console.log(response);
            handler();
        } catch (err) {
            setUpdateTable(false)
            console.log(err);
        }
    }

    const handler = () => {
        setShow(true); setModification(true);
        setContentResponse({gif: bella, img: "/", title: "Orden #" + transaction_code, text: "Ingrese el numero de mesa:" })
    };

    const sendOrder = async () => {
        setShow(false)
        setModification(false)
        try {
            let data = {
                business_code, branchoffice_code: branch_code,
                transaction_code, status_id: 1,
                table_num: tableNumber
            }
            const response = await CustomerTransactionsFetchAPI.updateTransaction(data);
            setContentResponse({gif: bellaSuccess, img: success, title: "Numero de orden recibida", text: "Pronto BellaQueen te llevara el pedido a la mesa." })
            setShow(true)
            intervalModal = setInterval(endModalResponse, 2000)
            console.log(response);
        } catch (err) {
            setContentResponse({gif: bellaError, img: error, title: "Upps hubo un error", text: "No se registro la cambio de mesa intentelo de nuevo por favor." })
            setShow(true)

            console.log(err);
        }
    }

    return (
        <>
            <div className='container-fluid containe-generate d-flex align-items-center justify-content-center ' >
                <div className='  content-initial border-sp' >
                    <div className=' d-flex  justify-content-xs-center justify-content-lg-start  position-absolute'>
                        <img src={logo} className='img-fluid' width="50px"></img>
                    </div>
                    
                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={bellaSuccess} className='img-fluid' width="200px"></img>
                        </div>

                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={success} className='img-fluid m-2' width="40px"></img>
                            <h3 className='text-center' >La orden: {transaction_code}</h3>
                        </div>

                        <RenderIf isTrue={updateTable == true}>
                            <p className='text-center'>Sera entregada por <strong>BellaQueen</strong> a la mesa <strong>{table_num}</strong> en un momento.</p>

                            <div className='m-2 d-flex align-items-center justify-content-center'>
                                <Button className='btn-lg' type='Button' onClick={async () => (getOrderByCode())} >Cambiar mesa</Button>
                            </div>
                        </RenderIf>

                        <RenderIf isTrue={updateTable == false}>
                            <p className='text-center'>Ya fue despachada. <strong>BellaQueen</strong> va en camino a la mesa <strong>{table_num}</strong>, no te muevas de la mesa por favor.</p>
                        </RenderIf>
                   
                </div>

                <Modal
                    show={show}
                    size="md"
                    onHide={() => setShow(false)}
                    centered
                >
                    <Modal.Body >
                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={contentResponse.gif} className='img-fluid' width="200px"></img>
                        </div>

                        <RenderIf isTrue={modification == false} >
                            <div className='m-3 d-flex align-items-center justify-content-center'>
                                <img src={contentResponse.img} className='img-fluid' width="50px"></img>
                            </div>
                        </RenderIf>

                        <div>
                            <h3 className='text-center'>{contentResponse.title}</h3>
                            <p className='text-center'>{contentResponse.text}</p>
                        </div>

                        <RenderIf isTrue={modification == true} >
                            <div className=' m-2 d-flex align-items-center justify-content-center'>
                                <input placeholder='Numero de la mesa' className=' border-sp p-2' type="number" onChange={(e) => (setTableNumber(e.target.value))}></input>
                            </div>
                            <div className=' m-2 d-flex align-items-center justify-content-center'>
                                <Button className='btn-lg' type='Button' onClick={async () => (sendOrder())}>Enviar</Button>
                            </div>
                        </RenderIf>
                    </Modal.Body>
                </Modal>
            </div>


        </>
    )
}

export default PageConfirmation