import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class MachinesFetchAPI {

    static async getMachinesByBranchOffice(branchoffice_id, token) {
        const res = await server(`/machines/${branchoffice_id}/type/1`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getMachinesByBranchOfficeAndType(branchoffice_id, type, token) {
        const res = await server(`/machines/${branchoffice_id}/type/${type}`, {
            method: "get",
            headers: { 'x-access-token': token },

        });
        return res
    }

    static async updateStateOrder(data) {
        const res = await server(`/temp/order`, {
            method: "delete",
            data: data,
        });
        return res
    }

    static async updateMachineStatus(machine_code, status, machine_token) {
        const res = await server(`/machines`, {
            method: "put",
            headers: {
                'x-access-token': machine_token
            },
            data: {
                machine_code, status
            },
        });
        return res
    }

    static async getmachineByid(machine_id, token){
        const res = await server(`/branch_machine/${machine_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            },
        });
        return res
    }

    //Function to get machine categories 
    static async getMachineCategories(machine_id, token) {
        const res = await server(`/category_per_machine/${machine_id}`, {
            method: "get",
            headers: { 'x-access-token': token },

        });
        return res
    }

    //Function to update machine category info 
    static async updateMachineCategory(machine_id, category_id, status, token){
        const res = await server(`/category_per_machine`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: {
                branch_machine_id: machine_id, 
                category_id, 
                status
            }

        });
        return res

    }


      //Function to update machine language
      static async updateMachine(machine_id, data, token){
        const res = await server(`/branch_machine/${machine_id}`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: data

        });
        return res

    }


      //Function to update machine language
      static async lastOrderMachine(machine_id, token){
        const res = await server(`/last_order/machine/${machine_id}`, {
            method: "get",
            headers: { 'x-access-token': token }

        });
        return res

    }
}

