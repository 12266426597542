import React, { useState } from 'react'
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad';
import KeyPad from '../../components/Keypad/keypad';
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import { AuthFetchAPI } from '../../api/Auth';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import logo from '../../components/img/logos/logo.png'

//Component
import Header from "./components/Header"

function ModalDataClient({ background, ruc, setRuc, dv, setDv, name, setName, showModalkeyDataClient, setShowModalkeyDataClient, setAtivity }) {
    //state of keypad number
    const [displayNumber, setDisplayNumber] = useState('');
    const [nameButton, setNameButton] = useState({ button: "Siguiente", title: "ruc" });
    const [stepData, setStepData] = useState(0);
    let token = localStorage.getItem("x-access-machine-token")
    //set code's of the sesion 
    const handleSaveCode = () => {
        if (stepData == 0) {
            setRuc(displayNumber);
            setDisplayNumber('')//clear input of the keypad
            nameButton.button = 'Siguiente';
            nameButton.title = 'dv'

            setStepData(1)
            authenticationDgi(displayNumber)
        } else if (stepData == 1) {
            setDv(displayNumber);

            setDisplayNumber('')//clear input of the keypad
            nameButton.button = 'Guardar datos';
            nameButton.title = 'nombre'
            setNameButton(nameButton)
            setStepData(2)

        } else if (stepData == 2) {
            setName(displayNumber);
            //LoadingAlert()
            setDisplayNumber('') //clear input of the keypad

            nameButton.button = 'Siguiente';
            nameButton.title = 'ruc'

            setShowModalkeyDataClient(false);
            setStepData(0)
        }
    }

    //function to authentication of ruc
    const authenticationDgi = async (ruc_valid) => {
        let message = ""
        let title = ""
        let icon = ""
        if (ruc_valid == "") {
            message = "No se ha ingresado ningun ruc."
            title = "Debes ingresar un ruc."
            icon = "warning"
            ErrorAlert(message, title, icon)
        } else {
            LoadingAlert()
            let data = { ruc: ruc_valid }
            try {
                const response = await AuthDgiFetchAPI.authenticationRuc(token, data)
                let dataDgi = response.data
                if (dataDgi.dgi_response.gResProc) {
                    if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
                        let dvDgi = dataDgi.dgi_response.dDV
                        if (dvDgi.length < 2) { dvDgi = "0" + dvDgi }
                        setDisplayNumber(dataDgi.dgi_response.dNomb)
                        setName(dataDgi.dgi_response.dNomb)
                        setDv(dvDgi)

                        nameButton.button = 'Guardar datos';
                        nameButton.title = 'nombre'
                        setNameButton(nameButton)
                        message = "Datos del clientes obtenidos por la DGI"
                        title = "Datos obtenido correctamente"
                        icon = "success"
                        ErrorAlert(message, title, icon)
                        setStepData(2)
                    } else {
                        message = "Ingrese los datos manualmente."
                        title = "Datos no encontrados"
                        icon = "warning"
                        ErrorAlert(message, title, icon)
                    }
                }
            } catch (err) {
                console.log(err);
                message = "Ingrese los datos manualmente."
                title = "Datos no encontrados"
                icon = "warning"
                ErrorAlert(message, title, icon)
            }
        }
    }



    return (
        <>
          <Modal
                show={showModalkeyDataClient}
                fullscreen
                onHide={() => setShowModalkeyDataClient(false)}>
                <Modal.Body className='p-0 m-0'>
                    <section className='kiosk-delivery' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(5)}>
                        {/* <Header
                            classContainer={"scale-up-horizontal-left"}
                            classImg={"kiosk-he
                            ader-img-categories"}>
                        </Header> */}

<h3 className='kiosk-delivery_title  text-center '>
                            Datos Ingresados
                        </h3>

                        <section className='keyboard-steps bd-highlight w-100 row g-3'>

                            <div className={ruc != "00000-00" ? 'col-6 keyboard-step bg-light border' : 'col-6 keyboard-step border'}>
                                <h3 className={ruc != "00000-00" ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>
                                    Ruc:

                                    <p
                                        className={ruc != "00000-00"
                                            ? 'keyboard-steps-number keyboard-steps-number-active text-center '
                                            : 'keyboard-steps-number text-center text-muted opacity-25'}>
                                        {ruc}

                                    </p>
                                </h3>

                            </div>
                            <div className={dv != '00' ? 'col-6 keyboard-step bg-light' : 'col-6 keyboard-step border '}>
                                <h3 className={dv != '00' ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>Dv
                                    <p className={dv != '00' ? 'keyboard-steps-number keyboard-steps-number-active text-center ' : 'keyboard-steps-number text-center text-muted opacity-25'}>{dv}</p></h3>
                            </div>
                            <div className={name != "Cliente generico" ? 'col-12 keyboard-step bg-light border' : 'col-12 keyboard-step border'}>
                                <h3 className={name != "Cliente generico" ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>Nombre</h3>
                                <p className={name != "Cliente generico" ? 'keyboard-steps-number keyboard-steps-number-active text-center ' : 'keyboard-steps-number text-center text-muted opacity-25'}>{name}</p>
                            </div>
                        </section>
                        <h2 className='kiosk-delivery_title  text-center '>
                            ¡Hola! Ingrese el <span className='fw-bold text-primary text-uppercase'> {nameButton.title}</span>
                        </h2>
                        
                        <section className='bd-highlight w-100'>
                            <KeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} />
                        </section>

                        {/* <section className='bd-highlight row'>
                            <div className='col-12'><button type="button" className=" btn btn-lg btn-vending btn-vending-success w-100" >
                                <i className="uil uil-arrow-right"></i> 
                            </button></div>
                        </section> */}                 
                    </section>
                </Modal.Body>



                <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
                    <button
                        onClick={() => (setShowModalkeyDataClient(false), setStepData(0))}
                        className={`btn btn-danger kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4`}


                    >Cancelar
                    </button>


                    <button
                        className={`btn btn-success kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}

                        onClick={() => (handleSaveCode())}


                    >  {nameButton.button}


                    </button>

                </div>

                

            </Modal>

        </>
    )
}

export default ModalDataClient