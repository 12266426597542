import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});


export class PosIntegrationFetchAPI {

    //Function to get the pos integration params by id 
    static async getPosIntegrationParamsByBranch(integration_id, branchoffice_id, token) {
        const res = await server(`/integration/${integration_id}/params_values/${branchoffice_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

}