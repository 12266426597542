import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'

import SuccessAlert from '../Alerts/SuccessAlert';
import "./table_order.css"

//Api 
import { CustomerTransactionsFetchAPI } from '../../api/CustomerTransactions';

//img
import imgBackgrount from './img/Burger-king-fondo.png'
import error from './img/error.png'
import success from './img/success.png'
import bella from './img/bella1.gif'
import bellaSuccess from './img/bellahappy.gif'
import bellaError from './img/bellabad.gif'
import logo from './img/logo.png'


function TableOrder() {

    const navigate = useNavigate();

    const [transaction_code, setTransaction_code] = useState("")
    const [imgInvoice, setImgInvoice] = useState(null)
    const [show, setShow] = useState(false)
    const [showLoad, setShowLoad]= useState(false)
    const [textload, setTextload]= useState("")
    const [contentResponse, setContentResponse] = useState({ gif: bella, img: "/", title: "Title", text: "text" })
    let { business_code, branch_code, table_num } = useParams();
    let intervalModal = 0
    const endModalResponse = () => { navigate("/confirmation/business/" + business_code + "/branchoffice/" + branch_code + "/table/" + table_num + "/transaction/" + transaction_code); clearInterval(intervalModal); setShow(false) }


    const sendOrder = async () => {
        setTextload("Enviando datos...")
        setShowLoad(true)
        try {
            let data = {
                business_code, branchoffice_code: branch_code,
                transaction_code, status_id: 1,
                table_num,
                files: imgInvoice,
            }
            const response = await CustomerTransactionsFetchAPI.newTransaction(data);
            setShowLoad(false)
            setContentResponse({ gif: bellaSuccess, img: success, title: "Numero de orden recibida", text: "Pronto BellaQueen te llevara el pedido a la mesa." })
            setShow(true)

            intervalModal = setInterval(endModalResponse, 2000)
            console.log(response);
        } catch (err) {
            setShowLoad(false)
            if (err.response.data.error == "Transaccion ya existente") {
                setContentResponse({ gif: bellaError, img: error, title: "Upps hubo un error", text: "Ya existe una transaccion con este numero de orden." })
            } else if (err.response.data.error == "Imagen faltante") {
                setContentResponse({ gif: bellaError, img: error, title: "Upps hubo un error", text: "No encontramos la foto de la factura." })
            } else {
                setContentResponse({ gif: bellaError, img: error, title: "Estas muy lejos", text: "Tomale foto mas cerca a la factura por favor." })

            }
            setShow(true)
            console.log(err.response.data.error);
        }
    }

    let intervalimgupload =0
    const handlerimg = (e) =>{
        setTextload("Subiendo foto...")
        setShowLoad(true)
        let file = e.target.files[0]
        const reader = new FileReader();

        reader.onload = (event) => {
            console.log(event);
            setImgInvoice(file);
            setShowLoad(false)
        };
    
        reader.readAsDataURL(file);

        //setImgInvoice(file) 
        //intervalimgupload = setInterval(eventInterval, 8000);
    }

    const eventInterval =() => {
        clearInterval(intervalimgupload)
        setShowLoad(false)
    }

    return (
        <>
            <div className='container-fluid containe-generate d-flex align-items-center justify-content-center ' >
                <div className='  content-initial border-sp' >

                    <div className=' d-flex  justify-content-xs-center justify-content-lg-start  position-absolute'>
                        <img src={logo} className='img-fluid' width="50px"></img>
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img src={bella} className='img-fluid' width="200px"></img>
                    </div>

                    <div className=''>
                        <h1 className='text-center' >Estas en la mesa {table_num}</h1>
                        <p className='text-center '>Ingresa los datos para que <strong>BellaQueen</strong> te lleve el pedido a la mesa.</p>
                    </div>

                    <div className="row mb-3">
                        <label for="input3" className="col-sm-4 col-form-label">Orden</label>
                        <div className="col-sm-8">
                            <input type="number" placeholder='Numero de orden' className="form-control" id="input3" onChange={(e) => (setTransaction_code(e.target.value))} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label for="inputFile3" className="col-sm-4 col-form-label">Foto de la factura</label>
                        <div className="col-sm-8">
                            <input type="file" className="form-control" accept="image/*" id="inputFile3"  onChange={(e) => (handlerimg(e))} />
                          
                        </div>
                    </div>

                    <div className=' m-1 '>
                        <Button className='btn-lg w-100' type='Button' onClick={async () => (sendOrder())}><i className="uil uil-message"></i> Enviar </Button>
                    </div>
                </div>

                <Modal
                    show={show}
                    size="md"
                    onHide={() => setShow(false)}
                    centered>

                    <Modal.Body >
                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={contentResponse.gif} className='img-fluid' width="200px"></img>
                        </div>

                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={contentResponse.img} className='img-fluid' width="50px"></img>
                        </div>
                        <div>
                            <h3 className='text-center'>{contentResponse.title}</h3>
                            <p className='text-center'>{contentResponse.text}</p>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal
                    show={showLoad}
                    size="md"
                    onHide={() => setShowLoad(false)}
                    centered
                    backdrop="static"

                >
                    <Modal.Body >
                    
                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <img src={bella} className='img-fluid' width="200px"></img>
                        </div>

                        <div className='m-3 d-flex align-items-center justify-content-center'>
                            <h3 className='text-center'>{textload}</h3>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>



        </>
    )
}

export default TableOrder