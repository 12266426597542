import React, { useState, useEffect } from 'react';
import { ChatEngineWrapper, Socket, ChatFeed } from 'react-chat-engine';
import { styles } from '../styles';

const ChatEngine = ({ visible, user, chat }) => {
    const [showChat, setShowChat] = useState(false)

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                setShowChat(true)
            }, 500)
        }
    })
    return (
        <div

            className='transition-5'
            style={{
                ...styles.chatEngineWindow,
                ...{
                    height: visible ? '100%' : '0%',
                    zIndex: visible ? '100' : '0',
                    width: '100%',
                    backgroundColor: 'white'
                }
            }}
        >
            {
                showChat  &&
                <ChatEngineWrapper>
                    <Socket
                        projectID={process.env.REACT_APP_CHATENGINE_PROYECTID}
                        userName={user.username}
                        userSecret={user.username}
                    />
                    <ChatFeed activeChat={chat.id} />
                </ChatEngineWrapper>
            }
        </div>
    );
}

export default ChatEngine;
