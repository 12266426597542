import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import QRCode from "react-qr-code";
import ErrorAlert from '../Alerts/ErrorAlert';
import yappy from "./img/yappy.png"
import YappyStep from "./img/YappyStep.png"

function ModalYappyQrPos({ modalYappyQr, setModalYappyQr, dataYappy, setDataHash, dataHash, hadleCancelYappy, total, orderNumber }) {
    const [show, setShow] = useState(true);
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))

    return (
        <>
            <Modal
                show={modalYappyQr}
                size='xl'
                onHide={() => setModalYappyQr(false)}
                backdrop="static"
                centered
            >

                <Modal.Body>
                <div className="bg-light border-sp d-flex align-items-center justify-content-center ">
                <img className='m-3' src={yappy} width="100px"></img>
                        <div className='mt-3' >
                            <h3 className=' blod' translate='no'>Total: ${total.toFixed(2)}</h3>
                            <p className='text-center'>Orden: {orderNumber}</p>
                        </div>
                    </div>
                    <div className="m-4 d-flex align-items-center justify-content-center ">
                        <QRCode value={dataHash} size={200}/>
                    </div>
                 

                    <h3 className='text-center'>Escanea el qr con el app de yappy.</h3>
                    <div className=" d-flex align-items-center justify-content-center ">
                        <img className='m-2' src={YappyStep} width="500px"></img>


                    </div>

                    <div className="m-3 d-flex align-items-center justify-content-center ">
                        <Button variant='danger btn-lg' onClick={() => hadleCancelYappy()}>Cancelar</Button>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default ModalYappyQrPos