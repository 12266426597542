import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class DiscountFetchAPI {

    //Function to get discounts by business
    static async getDiscountsByBusiness(business_id, token) {
        const res = await server(`/discount/${business_id}/business`, {
            method: "get", 
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    static async getDiscountByCode(code, token){
        const res = await server(`/discount/${code}/discount_code`, {
            method: "get",
            headers:{
                "x-access-token": token
            }
        });
        return res
    }
}