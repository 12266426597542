import axios from 'axios'; 



const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});


export class QrFetchAPI{ 

    static async analyzeQr(data){ 
        console.log(data)
        const res = await server(`/scan/qr`, {
            headers: {'Content-Type': "multipart/form-data"},
            method: 'POST', 
            data: {
                "files": data
            }
        }); 
        return res
    }
}