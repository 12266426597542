import React from 'react'
//Images
import refund from '../img/icons/refund.png'
import cashback from '../img/icons/cashback.png'
function TransactionPill(props) {
   return (
      <>
          {/* <div className="m-auto list-group border-history shadow-sm mt-3">
            <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
               <img src={props.data.is_devolution_notification == true ? cashback : refund} className="flex-shrink-0" alt="En progreso" width="41" height="40" />
               <div className="d-flex  w-100 justify-content-between align-items-center">
                  <div>
                     <h6 className="mb-0 text-muted">Orden #{props.data.transaction_code} </h6>
                     <h6 className='mt-1'>Total:<strong > {props.data.total.toFixed(2)}</strong ></h6>
                  </div>
                  <small className="text-nowrap text-primary h5">
                     <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                     Sucursal: {props.data.branchoffice.name}
                  </small>
                  <small className="text-nowrap text-primary h5">
                     <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                     Caja:{props.data.branchoffice.name}/
                  </small>
                  <small className="text-nowrap text-primary h5">
                     <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                     Máquina: {props.data.machine_code}
                  </small>
               </div>
            </a>
         </div>  */}

          <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white">
            <div className="flex-shrink-0 p-2">
               <img src={props.data.is_devolution_notification == true ? cashback : refund} alt="En progreso" width="41" height="40" />
            </div>
            <div className="flex-grow-1 ms-3">
               <div className=' d-flex justify-content-between mt-2 me-2'>
                  <h5 className="mb-0 text-muted">Orden #{props.data.transaction_code} </h5>
                  <h5 className='mt-1'>Total:<strong > {props.data.total.toFixed(2)} $</strong ></h5>
               </div>

               <div className='mt-1 me-2 d-flex justify-content-between'>
                  <span className="text-nowrap text-secondary h6">
                     <i className="fa-solid fa-store"></i>&nbsp;
                     Sucursal: {props.data.branchoffice.name}
                  </span>

                  <span className="text-nowrap text-secondary h6">
                     <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                     Máquina: {props.data.machine_code}
                  </span>
               </div>
               <span className="text-nowrap text-primary h6 d-block mb-2">
                  <i className="fa-solid fa-cash-register"></i>&nbsp;
                  {!props.data.machine_name ?'No es caja': props.data.machine_name} / {!props.data.user ? 'No es cajera': props.data.user.name}
               </span>

            </div>
         </div>
      </>
   )
}

export default TransactionPill