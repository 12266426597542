import { Button } from 'react-bootstrap';
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';

import { DiscountPosFetchAPI } from '../../api/Paymentpost/Discount';

//Img
import empty_img from './img/empty_cart.png';
//Icons 
import Empty from '../utils/Empty';
import { FaPercentage } from 'react-icons/fa';

function ModalDiscounts({ actvPlus, actvLite, productChecked, shoppingCart, total, subtotal, itbms, discount, setLoad, setShoppingCart, setProductChecked, taxesArrayAll }) {
   let title_modal = "Descuentos"
   const [show, setShow] = useState(false);
   let token = localStorage.getItem('x-access-machine-token')

   let instervalDicount = 0
   let index = 0;

   //filter checkbox in state true and save in state array
   const getChecked = (event) => {
      let dataProduct = JSON.parse(event.target.value)
      dataProduct.index = event.target.dataset.index;

      if (event.target.checked == true) {
         setProductChecked([...productChecked, dataProduct])
      } else if (event.target.checked == false) {
         for (let i = 0; i < productChecked.length; i++) {
            if (productChecked[i].index == event.target.dataset.index) {
               productChecked.splice(i, 1)
            }
         }
      }
   }

   //create orders temporal of discount
   let type = 0
   const createTempDiscount = async () => {

      type = 0
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let user = JSON.parse(localStorage.getItem('user'));

      let jsonDiscountTemp = {
         "client_name": "0",
         "cupon_code": "0",
         "is_cupon": false,
         "localizador": "0",
         "client_phone": "0",
         "dgi_qr": "0",
         "Orden": productChecked,
         "amount_order_product": productChecked.length,
         "Descuento": 0,
         "Total": total,
         "itbms": itbms,
         "taxes": taxesArrayAll,
         "subtotal": subtotal,
         "machine_id": machine.id,
         "branchoffice_id": branchoffice.id,
         "tipoOrden": "comer aqui",
         "paymethod": 0,
         "is_discount_notification": true,
         "user": {
            id: user.id,
            name: user.name,
            code: user.user_code
         }
      }

      try {
         const response = await DiscountPosFetchAPI.createTempDiscount(jsonDiscountTemp, token)

         if (response.data.message == 'Tempral order created') {
            getOrdersTempDiscount()
         }
      } catch (err) {
         console.log(err);
         console.log("ya hay una temporal");
         type = 1
         getOrdersTempDiscount()

      }
   }

   // get order temporal discount
   let countGetDiscount = 0 // count to limit get orders
   const getOrdersTempDiscount = async () => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let business = JSON.parse(localStorage.getItem('business'))
      clearInterval(instervalDicount)

      try {
         const response = await DiscountPosFetchAPI.getTempDiscount(machine.code, business.code, branchoffice.code, token)

         let code = response.data.order.code
         let message = ""
         let title = ""
         let icon = ""

         if (type == 0) {


            if (response.data.order.status_id == 1) {
               if (countGetDiscount <= 13) {
                  console.log(countGetDiscount);
                  instervalDicount = setInterval(getOrdersTempDiscount, 5000)
                  countGetDiscount++
               } else {
                  message = "Tiempo de espera agotado"
                  title = "Advertencia"
                  icon = "warning"
                  updateOrderDiscount(code, 5, message, title, icon) //state 5 delete order dicount
                  setProductChecked([])
                  setShow(false)
                  countGetDiscount = 0
               }
            } else if (response.data.order.status_id == 8) {//order temporal is cancel
               message = "Orden temporal cancelada"
               title = "Operacion denegada"
               icon = "error"
               updateOrderDiscount(code, 5, message, title, icon, type)
               setProductChecked([])
               setShow(false)
            } else if (response.data.order.status_id == 9) {//order temporal is success
               message = "Descuento aplicado con exito."
               title = "Operacion exitosa"
               icon = "success"

               let tempOrderDiscount = response.data.order.groups
               //format json 
               for (let i = 0; i < tempOrderDiscount.length; i++) {

                  for (let s = 0; s < tempOrderDiscount[i].segments.length; s++) {
                     tempOrderDiscount[i].segments[s].item_id = tempOrderDiscount[i].segments[s].item.item_id
                     tempOrderDiscount[i].segments[s].segment_name = tempOrderDiscount[i].segments[s].name
                     tempOrderDiscount[i].segments[s].name = tempOrderDiscount[i].segments[s].item.name
                     tempOrderDiscount[i].segments[s].cantidadItem = tempOrderDiscount[i].segments[s].item.amount
                     tempOrderDiscount[i].segments[s].description = tempOrderDiscount[i].segments[s].item.description
                     tempOrderDiscount[i].segments[s].img = tempOrderDiscount[i].segments[s].item.img

                  }
                  tempOrderDiscount[i].DetalleSegmentos = tempOrderDiscount[i].segments
                  tempOrderDiscount[i].cantidadItem = tempOrderDiscount[i].amount
                  tempOrderDiscount[i].descuento = tempOrderDiscount[i].discount

               }


               //filter product only with discount
               let arrayTempProduct = new Array
               for (let s = 0; s < shoppingCart.length; s++) {
                  for (let t = 0; t < tempOrderDiscount.length; t++) {
                     if (tempOrderDiscount[t].group_id == shoppingCart[s].group_id) {
                        console.log(" entre identificando id de grupo");
                        if (tempOrderDiscount[t].segments_string == shoppingCart[s].segments_string) {
                           console.log("Es igual");
                           if (shoppingCart[s].descuento > 0) {
                              arrayTempProduct.push(shoppingCart[s])
                           } else {
                              arrayTempProduct.push(tempOrderDiscount[t])
                           }
                        }
                     }
                  }
                  //------------------------
               };

               //filter array  not discount
               let responseArray = obtenerValoresDiferentes(shoppingCart, productChecked)
               setShoppingCart(arrayTempProduct.concat(responseArray));

               /*let arrayProducts = new Array
               if (tempOrderDiscount.length != shoppingCart.length) {
                  for (let sh = 0; sh < shoppingCart.length; sh++) {
                     for (let tem = 0; tem < tempOrderDiscount.length; tem++) {
                        console.log(tempOrderDiscount[tem].segments_string);
                        console.log(shoppingCart[sh].segment_string);
                        if (shoppingCart[sh].group_id == tempOrderDiscount[tem].group_id && shoppingCart[sh].segment_string == tempOrderDiscount[tem].segments_string) {
                           arrayProducts.push(tempOrderDiscount[tem])
                        } else { arrayProducts.push(shoppingCart[sh]) }
                     }
                  }
                  setShoppingCart(arrayProducts)
               } else {
   
                  setShoppingCart(tempOrderDiscount)
               }*/

               setLoad(1)
               updateOrderDiscount(code, 3, message, title, icon);
               //We clean the state of the products checked 
               setProductChecked([])
               setShow(false)
            }
         } else if (type == 1) {
            console.log("consulte la temporal remanente");
            updateOrderDiscount(code, 5, message, title, icon)
         }
      } catch (err) {
         console.log(err);
      }
   }

   //Attay filter shopingcart
   function obtenerValoresDiferentes(arr1, arr2) {
      var ArrayFilter = arr1.filter(function (elemento) {
         return !arr2.some(function (e) {
            return e.group_id == elemento.group_id && e.segments_string === elemento.segments_string;
         });
      });

      return ArrayFilter;
   }



   //update orders temporal discount
   const updateOrderDiscount = async (code, status, message, title, icon) => {

      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))

      let jsonUpdate = {
         "order_code": code,
         "business_code": business.code,
         "branchoffice_code": branchoffice.code,
         "status": status
      }
      try {
         const response = await DiscountPosFetchAPI.updateOrderTemp(jsonUpdate, token)

         if (type == 0) {
            ErrorAlert(message, title, icon)
         } else if (type == 1) {
            console.log("llame crear nuevamente despues de borrar la ya existente");
            createTempDiscount()
         }

      } catch (err) {
         console.log(err);
      }

   }

   //event to notification discount
   const aplyDiscount = async () => {
      let message = ""
      let title = ""
      let icon = ""

      /*if (discount > 0) {
         message = "No se pueden aplicar dos descuentos"
         title = "Ya existe un descuento aplicado."
         icon = "warning"
         ErrorAlert(message, title, icon)

      } else {
         if (productChecked.length > 0) {
            createTempDiscount()
            let title = 'Solicitud de decuento enviada'
            let message = 'Espere un momento el supervisor esta aprobando el descuento....<br/> <br/>'
            LoadingAlert(title, message);
         } else {

            message = "Debe seleccionar un producto."
            title = "No hay productos seleccionados."
            icon = "error"
            ErrorAlert(message, title, icon)
         }
      }*/


      if (productChecked.length > 0) {
         createTempDiscount()
         let title = 'Solicitud de decuento enviada'
         let message = 'Espere un momento el supervisor esta aprobando el descuento....<br/> <br/>'
         LoadingAlert(title, message);
      } else {

         message = "Debe seleccionar un producto."
         title = "No hay productos seleccionados."
         icon = "error"
         ErrorAlert(message, title, icon)

      }
   }


   return (
      <>
         {/* <button className='btn btn-outline-primary btn-sm border-0' onClick={() => setShow(true)}>
         <i className="uil uil-refresh "></i>
            <small className='fw-bold'>             
                            
            </small>
         </button> */}

         {actvPlus ? <button className='
         btn btn-outline-primary  border  rounded-pill me-1 shadow-sm bg-seconadary p-2 bg-opacity-10' onClick={() => setShow(true)}>
            <i className="uil uil-percentage icon-mobile"></i>
            <small className='fw-bold label-responsive  hidden-label-sm'>{title_modal}  </small>
         </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => setShow(true)}>
               <i className="uil uil-percentage buttons-label icon-mobile h3 "></i>
               <span className='h5 fw-bold mx-1 d-block text-center'> {title_modal} </span>
            </button>
         </div> : <></>}


         <Modal
            show={show}
            size="lg"
            onHide={() => (setShow(false), setProductChecked([]))}
            aria-labelledby="example-custom-modal-styling-title"
         >
            <div className='mt-2 text-center p-1'>
               <h4 className='fw-bold'> <FaPercentage />   {title_modal} </h4>
            </div>
            <Modal.Body>

               <p className='fw-bold'>Lista de Productos  </p>
               <div className="row">
                  {/* Validatopn Shoppint Cart Empty  */}
                  {
                     !shoppingCart.length
                        ? <Empty title="Vacío" msg="No hay ningún producto agregado en el carrito" img={empty_img} />
                        : shoppingCart.map(product => (

                           <div className="col-12 col-md-12 col-lg-4 mt-lg-1">
                              {/* <label className="list-group-item align-items-center py-2 d-flex gap-3 border rounded ps-2">
                                 <input className="form-check-input flex-shrink-0" type="checkbox" value={JSON.stringify(product)} data-index={index++} defaultChecked={false} name="checked1" onChange={(e) => (getChecked(e))} />
                                 <span className="form-checked-content">
                                    <div className="d-flex text-muted px-xs-2" >
                                       <img className="me-2 rounded bg-secondary m-1" src={product.img} width="42" height="42" />

                                       <div className="d-flex justify-content-start mb-0 lh-sm pt-3">
                                          <span className="text-dark fw-bold title-product ">{product.name}</span>
                                          <label className="fw-bold" >$ {product.price.toFixed(2)}</label>
                                       </div>
                                    </div>
                                 </span>
                              </label> */}

                              <input
                                 className="list-group-item-check rounded"
                                 type="checkbox" value={JSON.stringify(product)}
                                 data-index={index++} defaultChecked={false}
                                 id={product.name + "discount" + index}
                                 name="checked1" onChange={(e) => (getChecked(e))}
                                 disabled={product.descuento > 0 ? true : false} />

                              <label className="list-group-item py-1 px-1 h-100"
                                 htmlFor={product.name + "discount" + index}
                              >
                                 <div className="d-flex gap-3 py-3 rounded" >
                                    <img src={product.img}
                                       alt="Product" width="50" height="50" className="rounded flex-shrink-0" />
                                    <div className="d-flex gap-2 w-75 justify-content-around">
                                       <div>
                                          <h6 className="mb-0 fw-bold">Producto : {product.name}</h6>
                                          <p className="mb-0 opacity-75">Precio: {product.price.toFixed(2)}</p>
                                       </div>
                                    </div>
                                 </div>
                              </label>

                           </div>


                        ))
                  }
               </div>

               <div className='d-flex justify-content-between mt-3'>
                  <button className='btn btn-danger btn-lg' onClick={() => (setShow(false), setProductChecked([]))}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={() => (aplyDiscount())}> Solicitar descuento </button>
               </div>
            </Modal.Body>


            {/* <Button variant="danger" size="lg" onClick={() => (setShow(false))}>Cancelar</Button>
               <Button variant="success" size="lg" onClick={() => (aplyDiscount())}></Button> */}

         </Modal>


      </>
   )
}

export default ModalDiscounts