import React from 'react'

function Totals({ count, discount, subtotal, itbms, total }) {
   return (
      <>

         <div className='d-flex justify-content-center'>
            <span className='text-muted fs-5 fw-bold border border-2 rounded-pill px-2'>
               Cantidad de productos : {count.length}
            </span>

         </div>


         <div className="mb-0 small lh-sm mt-1">
            <div className="d-flex justify-content-between my-3">
               <small className="text-muted fs-3  subtitle fs-3  "> Descuento:</small>
               <span className="text-warning text-secondary  subtitle fs-3 " translate='no'>
                  <span className="px-2 subtitle">- $</span>
                  <strong> {discount.toFixed(2)} </strong>
               </span>
            </div>
            <div className="d-flex justify-content-between my-3 ">
               <small className="text-muted fs-3  subtitle"> Subtotal: </small>
               <span className="text-black text-secondary fs-3  subtitle" translate='no'>
                  <span className="px-2 subtitle">$</span>{subtotal.toFixed(2)}</span>
            </div>
            <div className="d-flex justify-content-between my-3">
               <small className=" text-muted fs-3  subtitle"> Itbms:
               </small><span className="text-black text-secondary fs-3  subtitle" translate='no'>
                  <span className="px-2 subtitle ">$</span>{itbms.toFixed(2)}</span>
            </div>

            <div className="d-flex justify-content-between my-3 total-shopping">
               <strong className="text-black fw-bold fs-2  mt-1 subtitle"> Total <small>(Incluye itbms)</small>: </strong>
               <span className="text-black fw-bold fs-2 subtitle" translate='no'>
                  <span className="px-2 ">$
                  </span>{total.toFixed(2)}</span>
            </div>
         </div>


      </>
   )
}

export default Totals