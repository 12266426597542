import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class OrdersFetchAPI {

    static async createOrder(data) {
        const res = await server(`/atm/temp/order`, {
            method: "post",
            data: data,
        });
        return res
    }

    static async getmachine(branchoffice_id, machine_type, token) {
        const res = await server('/machines/' + branchoffice_id + '/type/' + machine_type, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getOrder(user_code, business_code, inform_z) {
        const res = await server(`/atm/user/${user_code}/business/${business_code}/orders/${inform_z}/inform_z`, {
            method: "get"
        });
        return res
    }

    static async updateorderput(data) {
        const res = await server(`/atm/temp/order`, {
            method: "put",
            data: data
        });
        return res
    }

    static async closeBox(data) {
        const res = await server(`/informz`, {
            method: "post",
            data: data
        });
        return res
    }

    static async notification(data) {
        const res = await server(`/notification`, {
            method: "post",
            data: data
        });
        return res
    }

    static async validarRespuestaSolicitud(wamid, timesup) {
        const res = await server(`/message_response/${wamid}/${timesup}`, {
            method: "get",
        });
        return res
    }

    static async devolucion(data, order_code, business_code, branchoffice_code) {
        const res = await server(`/refund/order/${order_code}/${business_code}/${branchoffice_code}`, {
            method: "post",
            data: data
        });
        return res
    }

    static async getOrderBycode(order_code, business_code) {
        const res = await server(`/atm/order/${order_code}/${business_code}`, {
            method: "get"
        });
        return res
    }

    static async getUpdateBox(data, user_id) {
        console.log(data);
        const res = await server(`/user/${user_id}`, {
            method: "put",
            data: data
        });
        return res
    }

    static async analiticsTypeAtm(user_code, business_code, minDate, maxDate ) {
        const res = await server(`/atm/order/${user_code}/${business_code}/${minDate}/${maxDate}`, {
            method: "get"
        });
        return res
    }

    static async getUser(user_id, token) {
        const res = await server(`/user/${user_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    
    static async getBusiness(business_code, token) {
        const res = await server(`/business/${business_code}/business_code`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    

}