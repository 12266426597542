import React from 'react'
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
//Fonts 
import FontsRoboto from './Table/Fonts/Roboto-Bold.ttf'


Font.register({
   family: 'Roboto',
   fonts: [
      {
         src: FontsRoboto,
         fontWeight: 'bold',
      },
   ],
});

const styles = StyleSheet.create({
   container: {
      marginTop: 15,
      marginBottom: 10,
      padding: 10,
      width: '100%',
      height: '100%',

   },
   text: {
      fontSize: 10,
   },
   textNormal: {
      fontSize: 10,
      fontFamily: 'Roboto'
   },
   alignText: {
      flexDirection: 'row',
   },

});



function InfoDgi({ cufe, auth_protocol, invoiceDate }) {
   return (
      <>
         <View style={styles.container}>
            {/* Bussiness Name  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>Consulte por la clave de acceso en: </Text>
               <Text style={styles.text}> https://dgi-fep.mef.gob.pa/Consultas/FacturasPorCUFE</Text>
            </View>

            {/* Bussiness Name  */}
            <View style={styles.alignText}>
               <Text style={styles.textNormal}>CUFE: </Text>
               <Text style={styles.text}>{cufe} </Text>
            </View>

            {/* Bussiness Name  */}
{/*             <View style={styles.alignText}>
               <Text style={styles.textNormal}>Protocolo de autorización: </Text>
               <Text style={styles.text}> {auth_protocol}, de {invoiceDate}</Text>
            </View> */}
         </View>

      </>
   )
}

export default InfoDgi