import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class PositionFetchAPI {
    //Funtion to get position of machine
    static async getPositionTheMachine(machine_branch_id, token) {
        const res = await server(`/vending/machine/${machine_branch_id}`, {
            method: "get",
            headers: {'x-access-token': token},
        
        });
        return res;
    }

    //Function to assign or update the group of position by machine
    static async updatePositionToGroup(machine_branch_id, data, token){
        const res = await server(`/vending/groups/${machine_branch_id}`, {
            method: "POST",
            data: data,
            headers: {'x-access-token': token},
        
        });
        return res;
    }


        //Function to clean the position by machine
        static async cleanPositionsDisabled(machine_branch_id, data, token){
            const res = await server(`/vending/groups/${machine_branch_id}`, {
                method: "PUT",
                data: {groups: data},
                headers: {'x-access-token': token},
            
            });
            return res;
        }


}