import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { BuysFetchAPI } from '../../api/Buys';
// External Componets
import moment from 'moment-timezone';
// Internal Components 
import BuyTable from '../Table/Table';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Button, Modal, Row, Col } from 'react-bootstrap';

//Components 

const BuysTable = () => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    const [buysList, setBuysList] = useState([])
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [showModal, setShowModal] = useState(false);
    const [buyDetail, setBuyDetails] = useState(
        { products: [], total: 0 }
    );
    const [eventHandler, setEventHandler] = useState(0);
    const [buySelected, setBuySeleceted] = useState(null); 
    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
        } catch (err) {
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get buy by branch 
    const getBuysByBranch = async (branchoffice_id) => {
        try {
            const response = await BuysFetchAPI.getBuysByBranch(branchoffice_id, initialDate, maxDate, token);
            setBuysList(response.data.buys)
        } catch (err) {
            setBuysList([])
            //Error alert 
            ErrorAlert(err.response.data.error, "Error", "warning");
        }
    }

    //Function to get buy details 
    const getBuysDetails = async (buy_id) => {
        try {
            const response = await BuysFetchAPI.getBuysDetails(buy_id, token);
            setBuyDetails(response.data.buy)
        } catch (err) {
            
            //Error alert 
            console.log(err)
        }
    }
    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        setEventHandler(0);
    }, []);

    //Hook to load info when the event handler is activated 
    useEffect(() => { 
        console.log(buySelected)
        getBuysDetails(buySelected)
        setEventHandler(0)
    }, [eventHandler])

    //We charge the items of the order 
    const orderItems = () => {
        let items = buyDetail.products.map((product) => (
            <div className="d-flex text-muted pt-3 px-xs-2">
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{product.group_name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            {/* <span className='px-3'>x {product.buy_amount}</span> */}
                            <label className="fw-bold" translate='no'> x {product.buy_amount}</label>
                        </div>
                    </div>
                </div>
            </div>
        ));
        return <>{items}</>
    }
    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                        <label htmlFor="input_from">Sucursal</label>
                        <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                            <option value={0}>Seleccione...</option>
                            {branchList()}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e => getBuysByBranch(selectedBranch.id)} className="btn btn-success px-5">Aceptar</button>
                </div>
            </div>

            {/** Buy table */}
            <BuyTable
                table_name="Tabla de compras realizadas"
                table_headers={["Fecha de creacion", "Proveedor", "Factura de compra", "Total", 'Acciones']}
                table_type="buy_table"
                table_data={buysList}
                setShowModal={setShowModal}
                setEventHandler={setEventHandler}
                setBuySelected = {setBuySeleceted}
            />

            {/**Modal details  */}
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered>
                <Modal.Header>
                    Detalles de la compra
                </Modal.Header>
                <Modal.Body>
                    {/* Your card */}
                    <Row>
                        <Col sm={12} md={12}  >
                            <div className="py-4 px-lg-4 ">
                                <div className="head-section-sp mt-2">
                                    <div className="">
                                        <div className="mt-1 mb-3">
                                            <div className="d-flex justify-content-between align-items-center mb-3 ">
                                                <h5>
                                                    <span className="text-success">Factura: </span>
                                                    <span className="text-secondary"> # {buyDetail.invoice}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' d-flex justify-content-between text-secondary opacity-75'>
                                        <div> Productos comprados </div>
                                        <div> Precio </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 order-md-last">
                                            {/* Item cards */}
                                            {orderItems()}
                                            {/**Total details */}
                                            <div className="d-flex text-muted pt-3">
                                                <div className="pb-3 mb-0 small lh-sm border-top w-100">

                                                    <div className="d-flex justify-content-end mt-3">
                                                        <strong className="text-black h5"> Total: </strong>
                                                        <span className='text-black fw-bold h5'>
                                                            <span className='px-2 ' translate='no'>$ </span>
                                                            {buyDetail.total.toFixed(2)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BuysTable;
