import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class AuthFetchAPI {

    static async login(data) {
        const res = await server('/auth/login', {
            method: 'POST',
            data
        });
        return res
    }

    static async loginSmartPay(data) {
        const res = await server('/auth/loginsmartpay', {
            method: 'POST',
            data
        });
        return res
    }

    static async validtoken(data) {
        const res = await server("/auth/tokenIsValid", {
            method: "post",
            headers: { 'x-access-token': data },

        });
        return res;
    }
    static async loginBranchTable(data) {
        const res = await server('/auth/logintable', {
            method: 'POST',
            data
        });
        return res
    }



    static async me(data) {
        const res = await server("/auth/me", {
            method: "get",
            headers: { 'x-access-token': data },
        });
        return res;
    }

    //Function to authentication the machine (Vending machine)
    static async authMachine(business_code, branchoffice_code, machine_code) {
        const res = await server("/auth/loginsmartpay", {
            method: "POST",
            data: {
                "business_code": business_code,
                "branchoffice_code": branchoffice_code,
                "machine_code": machine_code
            },
        });
        return res;
    }

}