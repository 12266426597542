
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class EvertechFetchAPI {

    //Function to create a new payment 
    static async newPayment(secretKey, loginKey, language, transaction_code, evertech_code, description, total, token) {
        const res = await server(`/evertech`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                secretKey,
                loginKey,
                language,
                transaction_code,
                evertech_code,
                description,
                total,
            },
        });
        return res
    }

    //Function to get buys by branchoffice 
    static async getTransactionPaymentStatus(requestId, secretKey, loginKey, token) {
        const res = await server(`/evertech/${requestId}`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                secretKey,
                loginKey
            }
        });
        return res
    }

    //Function to return payment 
    static async returnPayment(secretKey, loginKey, paymentInternalReference, total, token) {
        const res = await server(`/return/evertech`, {
            method: "post", 
            headers: {'x-access-token': token},
            data: {
                secretKey, 
                loginKey, 
                paymentInternalReference, 
                total
            }
        })
        return res
    }
    
}

