import React from 'react'

function Footer() {

   function  getYearPresent() {
      var  date = new  Date();
      var  year_present = date.getFullYear();
      return  year_present ;
    }


   return (
      <>
         <div className="kiosk-delivery-footer ">
            <small className='text-muted'>
               © {getYearPresent()} Smart Pay
            </small>
            <small className='text-muted'>
               Powered By Smart Retail Group
            </small>
         </div>
      </>
   )
}

export default Footer