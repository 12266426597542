import React, { useState } from 'react'
//Component
import Header from "./components/Header"
//Imgs
import SmartPay_Logo from "./assets/img/logos/sp.png";
import { Modal } from 'react-bootstrap';
import ModalAplyDiscount from './ModalAplyDiscount';
import ModalAplyCupon from './ModalAplyCupin';

function ModalDiscount({ icon, background, showModalDiscount, setModalDiscount, setShoppingCart, shoppingCart, setEventHandler, invoiceTotal,
    invoiceTotalItbms,
    invoiceSubtotal,
    invoiceTotalDiscount, setAtivity }) {
    let theme_config = 1
    const [showModalAplyDiscount, setModalAplyDiscount] = useState(false)
    const [showModalAplyCupon, setShowModalAplyCupon] = useState(false)

    const [isButtonFocusedId, setButtonFocusId] = useState(null);

    const handleButtonFocus = (buttonId) => {
        setButtonFocusId(buttonId);
    };

    const handleButtonBlur = () => {
        setButtonFocusId(null);
    };
    return (
        <>

            <Modal
                show={showModalDiscount}
                fullscreen
                onHide={() => setModalDiscount(false)}>
                <Modal.Body>
                    <section style={{ backgroundImage: `url(${background})` }} className='kiosk-delivery' onClick={() => setAtivity(1)}>
                        <Header
                            classContainer={"kiosk-delivery_header m-0 p-0"}
                            classImg={"kiosk-delivery_header_img"}>
                        </Header>
                        <div className='kiosk-delivery_section scale-up-top'>
                            <h2 className='kiosk-delivery_title  text-center '>
                                ¡Hola! <br />
                                Escoja una opción
                            </h2>
                            <div className='kiosk-delivery-btn' >
                                <div className='kiosk-delivery_btns d-flex justify-content-center'>

                                    <button
                                        onClick={() => setModalAplyDiscount(true)}

                                        className={`btn  btn-outline-primary  btn-card-delivery  btn-lg m-2 
                                        shadow border border-5 position-relative
                                        ${isButtonFocusedId === 'discount' ? 'focused' : ''}`}
                                        onFocus={() => handleButtonFocus('discount')}
                                        onBlur={handleButtonBlur}
                                    >
                                        <div className='my-3'>
                                            <i className="uil uil-percentage btn-img-delivery"></i>
                                            {/* <UilUtensils size="240" /> */}
                                        </div>
                                        {/* <i className="uil uil-utensils btn-img-delivery"></i>  */}
                                        <span className='kiosk-text-card d-block '>
                                            Descuento
                                        </span>


                                        <div className="position-absolute postition-icon-md">
                                            {isButtonFocusedId === 'discount' && <img src={icon} alt="Hand" className='icon-focus' />}
                                        </div>
                                    </button>
                                    <button
                                        onClick={() => setShowModalAplyCupon(true)}

                                        className={`btn  btn-outline-primary  btn-card-delivery  btn-lg m-2 
                                        shadow border border-5 position-relative
                                        ${isButtonFocusedId === 'cupon' ? 'focused' : ''}`}
                                        onFocus={() => handleButtonFocus('cupon')}
                                        onBlur={handleButtonBlur}

                                    >
                                        <div className='my-3 '>
                                            <i className="uil uil-pricetag-alt btn-img-delivery"></i>
                                            {/* <UilShoppingBag size="240"  ></UilShoppingBag> */}
                                        </div>
                                        {/* <i className="uil uil-shopping-bag btn-img-delivery "></i> */}
                                        <span className='kiosk-text-card d-block'>
                                            Cupón
                                        </span>

                                        <div className="position-absolute postition-icon-md">
                                            {isButtonFocusedId === 'cupon' && <img src={icon} alt="Hand" className='icon-focus' />}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </section>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-start'>

                    {/* <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => setModalDiscount(false)} >
                        <i className="uil uil-arrow-left" ></i>
                        Regresar
                    </button> */}

                    <div className='d-flex position-absolute start-0 bottom-0 container-button'>



                    <button
                     type="button"
                     onClick={() => setModalDiscount(false)}
                     className={`m-0 btn-lg  shadow-lg border-0 button-ft_1
                   button-ft_1 position-relative
                  ${isButtonFocusedId === 'exit' ? 'focused' : ''}`}
                     onFocus={() => handleButtonFocus('exit')}
                     onBlur={handleButtonBlur}
                     title='Regresar'
                  >
                     <svg xmlns="http://www.w3.org/2000/svg"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                        className=""
                        id="left-arrow">
                        <path
                           d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

                     <div className="position-absolute postition-icon-md">
                        {isButtonFocusedId === 'exit' && <img src={icon} alt="Hand" className='icon-focus' />}
                     </div>
                  </button>
                       {/* <button className='btn btn-primary button-ft '
          onClick={prevStep}>
          <i className="bi bi-arrow-left-short "></i> Regresar 
        </button> */}
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalAplyDiscount icon={icon} background={background} showModalAplyDiscount={showModalAplyDiscount} setModalAplyDiscount={setModalAplyDiscount} setShoppingCart={setShoppingCart} shoppingCart={shoppingCart} setEventHandler={setEventHandler} setModalDiscount={setModalDiscount} invoiceTotal={invoiceTotal}
                invoiceTotalItbms={invoiceTotalItbms}
                invoiceSubtotal={invoiceSubtotal}
                invoiceTotalDiscount={invoiceTotalDiscount} setAtivity={setAtivity}></ModalAplyDiscount>

            <ModalAplyCupon icon={icon} background={background} showModalAplyCupon={showModalAplyCupon} setShowModalAplyCupon={setShowModalAplyCupon} setShoppingCart={setShoppingCart} shoppingCart={shoppingCart} setEventHandler={setEventHandler} setModalDiscount={setModalDiscount} invoiceTotal={invoiceTotal}
                invoiceTotalItbms={invoiceTotalItbms}
                invoiceSubtotal={invoiceSubtotal}
                invoiceTotalDiscount={invoiceTotalDiscount} setAtivity={setAtivity}></ModalAplyCupon>

        </>
    )
} export default ModalDiscount