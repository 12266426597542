import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});


export class OracleSimphonyFetchAPI {

    //Function to get the pos integration params by id 
    static async getSimphonyMenus(branchoffice_id, token) {
        const res = await server(`/simphony/menus?branchoffice_id=${branchoffice_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

    //Function to get simphony menu by id and branchcoffice 
    static async getSimphonyMenuById(simphonyMenuId, branchoffice_id, token){ 
        const res = await server(`/simphony/menu?branchoffice_id=${branchoffice_id}&menu_id=${simphonyMenuId}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

}