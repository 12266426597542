import React, { useState, useRef, useEffect } from 'react'
//Component
import Header from "./components/Header"
import { Hand } from 'react-flaticons';


function Products({ icon, selectCategorie, background, categorieSelect, categories, products, updateData, nextStep, prevStep, skipToStep3, getProductById, setAtivity, setCategorieSelect }) {
  const theme_config = 1
  const scrollRef = useRef(null);
  const [handlerChange, setHandlerChange] = useState(0);
  console.log(categorieSelect);
  const [isButtonFocusedId, setButtonFocusId] = useState(null);

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };  

    useEffect(() =>{
      setHandlerChange(0)
    }, [handlerChange])

  return (

    <section className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
       <div className='d-flex justify-content-center'>
        <Header
          classContainer={"scale-up-horizontal-left"}
          classImg={"kiosk-delivery_header_img"}>
        </Header>
      </div>
      <div className='container-list-kiosk '>
        <div className="container-list-kiosk_section bg-blur-sp-2   no-scroll mx-1 border mt-3 border-1">
          <ul>
            {categories.map((categorie) => (
              <>
               <li>
                  <div className={`button-container-kk   position-relative`}         >


                    <input type="radio" className="btn-check" name="options-outlined" id={categorie.category_id} value={categorie.category_id} autocomplete="off" checked={categorieSelect.category_id == categorie.category_id ? true : false} onChange={(e) => (setCategorieSelect(categorie), selectCategorie(e.target.value))} />
                    <label className="btn btn-outline-primary rounded-product-kiosk rounded-4 mx-lg-3" htmlFor={categorie.category_id}>
                      <img className='rounded' src={categorie.img} alt="Imagen 1" />
                      {categorie.category_name}
                    </label>                
                  </div>
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>
 

      <div className='kiosk-container_section '>
        <div className='row  button-ps mx-1'>
          {!products.length
            ? <>
              <div className='mt-5 '>
                 {/*<div className='d-flex justify-content-center mt-5'>
                  <img src={vacio}
                    className=" d-block image-empty text-center " alt="..." loading='lazy' />
                </div> */}
                <div className='d-block text-center fw-bold'>
                  ¡No hay productos disponibles en esta categoría
                  en este momento!
                </div>

              </div>   </>
            : products.map((product, index) => (
              <>
                <div className="col-4 col-xs-3 col-sm-3 col-md-4 col-lg-4  kiosk-card-item_product"
                  data-product={JSON.stringify(product)} key={index}   
                  
                  data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-content="Top popover"
                  >
                  <div className={`card kiosk-card-item  shadow h-100 w-100 position-relative`}
                    onClick={() => (getProductById(product))}>
                    <img src={product.img} className="card-img-top-products  " alt="..." loading='lazy' />
                    <div className="py-2">
                      <h5 className="card-title fw-bold kiosk-text-product 
                       text-dark text-truncate ms-1"> {product.name}</h5>

                      <div className='text-center'>
                        <h6 className="card-text
                      badge rounded-pill bg-success
                       fw-bold 
                      kiosk-text-card-product">${product.price.toFixed(2)}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))
          }
        </div>
      </div>
      {/* <button
        type="button"
        className='m-0  btn btn-exit-footer btn-lg  shadow-lg border-0 '
        onClick={prevStep}
        title='Regresar'
      >
        <svg xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          className=""
          id="left-arrow">
          <path
            d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path>
            
            </svg> 

            <label className='display-3 text-white fw-bold'>
          Volver 
        </label>
      </button> */}
      <div className='d-flex position-absolute start-0 bottom-0 container-button'>
        <button
          type="button"
          onClick={prevStep}
          className={`m-0 btn-lg  shadow-lg border-0
            button-ft_1 position-relative`}         
          title='Regresar'>
          <svg xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 24 24"
            className=""
            id="left-arrow">
            <path
              d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

    
        </button> 
      </div>

      {/* <div className="m-0 p-0">

         <small >
          © 2023 Smart Pay
        </small>
        <small onClick={nextStep}>
          Powered By Smart Retail Group
        </small>
      </div> */}
    </section >

  )
}

export default Products