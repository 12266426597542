import React from 'react'
//Component
import Header from "./components/Header"
import { RenderIf } from '../../components/utils/RenderIf'
import { Button } from 'react-bootstrap'
import QRCode from "react-qr-code";


//pictures

import checkimg from './assets/img/check.png';
import cancel from './assets/img/cancel.png'
import loading from './assets/img/loading.gif'

function CreateAndUpdatePreOrderEnd({ background, updateData, nextStep, prevStep, skipToStep3, invoiceTotal, preOrderInvoice , setPageLoader, setAtivity }) {
    let branchoffice = { id: "" }
    if (localStorage.getItem("branchoffice") != "" || localStorage.getItem("branchoffice") != undefined) {
        branchoffice = JSON.parse(localStorage.getItem("branchoffice"))
    }

    const updatePage = () => {
        if (localStorage.getItem('branchoffice_original') != null) {
            localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
        }

        if (localStorage.getItem('machine_original') != null) {
            localStorage.setItem('machine', localStorage.getItem('machine_original'))
        }
        window.location.reload();
    }

   
    console.log(" entre a finalizar");
    return (
        <section className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(3)}>

            <Header
                classContainer={"kiosk-categories-header scale-up-horizontal-left mt-5"}
                classImg={"kiosk-header-img-categories"}>
            </Header>

            <div>


                <div className='p-5 rounded m-5'>
                    <div className='p-5'>

                        <main className='d-flex justify-content-center align-items-center h-100 '>

                            <section className='border rounded p-4 w-100 position-relative m-3'>
                                <div className='sections-messages'>

                                    <div className='px-4 pb-1 mt-5 text-center'>

                                        <img className='mx-auto mb-4 ' src={checkimg} width='200px' height='200px' />
                                        <h1 className="display-6 fw-bold kiosk-subtitle">
                                            {preOrderInvoice == true?<>Orden actualizada con exito</>:<>Orden Creada con exito</>}
                                        </h1>
                                        <h3 className='kiosk-subtitle-md'> Total: ${parseFloat(invoiceTotal).toFixed(2)}</h3>

                                       
                                    </div>
                                    
                                </div>

                            </section>


                        </main>

                        <div className='d-flex justify-content-center align-items-center '>
                            <button className="btn btn-success kiosk-subtitle-md  rounded  p-4 m-3" onClick={() => (setPageLoader(1), updatePage())}>Volver al menu</button>
                        </div>

                    </div>

                </div>
            </div>

            <div className="position-absolute w-100 d-flex justify-content-between bottom-0 start-0 ">

                <small >
                    © 2023 Smart Pay
                </small>
                <small >
                    Powered By Smart Retail Group
                </small>
            </div>
        </section >
    )
}

export default CreateAndUpdatePreOrderEnd