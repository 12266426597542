import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import img_OutService from './assets/img/outService.png';


import Swal from 'sweetalert2';

function ModalOutService({ showModaloutService, setShowModaloutService}) {

  

    

    return (
        <>
            <Modal
                show={showModaloutService}
                size='lg'
                centered
            >
                <Modal.Body>
                    <div className='d-flex alight-item-center justify-content-center'>
                        <section className='mx-2'>
                            <div className='d-flex justify-content-center m-0'>
                                <img className='w-50 h-100' src={img_OutService} alt='Help Img' />
                            </div>

                            <div>
                                <div className='vendindg-title'>
                                    <h2 className='text-center text-title-help fw-bold'>Lo sentimos, esta maquina esta temporalmente cerrada</h2>
                                </div>
                                <div>
                                    <div className='m-4 vendindg-message alert alert-light'>
                                        <h3 className='text-center'>Pronto volvera al trabajo.</h3>
                                        <h3 className='text-center'>La pantalla se apagara en 5 segundos.</h3>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Modal.Body>
            </Modal>



        </>
    )
}

export default ModalOutService