import React, { useState, useEffect, useRef } from 'react';

const Camera = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [camera, setCamera] = useState('environment'); // 'user' para cámara frontal, 'environment' para cámara trasera
    const videoRef = useRef(null);

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: camera } });
            videoRef.current.srcObject = stream;
        } catch (error) {
            console.error(error);
        }
    };

    const takePhoto = () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/png');
        setImageSrc(dataUrl);
    };

    const switchCamera = () => {
        setCamera(camera === 'user' ? 'environment' : 'user');
        startCamera();
    };

    //Hook to charge data when the page is loaded 
    useEffect(() => {
        (async () => {
           try {
            startCamera()
           } catch (err) {
              console.log(err);
           }
  
        })();
       
     }, [])

return (
    <div>
        <button onClick={startCamera}>Start Camera</button>
        <button onClick={takePhoto}>Take Photo</button>
        <button onClick={switchCamera}>Switch Camera</button>
        {imageSrc && <img src={imageSrc} width="100px" alt="Captured Image" />}
        <video ref={videoRef} width="100px" autoPlay />
    </div>
);
};

export default Camera;