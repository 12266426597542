import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': 'application/json'}
})

export class WpNotificationFetchAPI { 
    static async sendTableNotification(data) {
        const res = await server(`/notification`, {
            method: "post",
            data:{
                "branchoffice_id": data.branchoffice_id,
                "business_id": data.business_id,
                "notification_type": "asking_client_table",
                "phone_to_send":data.phone_to_send,
                "transaction": data.transaction,
            }
        });
        return res
    }
}