import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { FilterOrderFetchAPI } from '../../api/Paymentpost/FilterOrder';
import { OrderReturnFetchAPI } from '../../api/Paymentpost/OrderReturn';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { FaHistory } from 'react-icons/fa';

import { MachinesFetchAPI } from '../../api/Machines';
import { PrinterFetchAPI } from '../../api/Printer';
import { OrdersFetchAPI } from '../../api/Orders';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';


function ModalReturn({actvLite, actvPlus}) {
   const [show, setShow] = useState(false);
   const [transaction_code, setTransaction_code] = useState("")
   const [order, setOrder] = useState({ order: "" })
   const [orderReturnSize, setOrderReturnSize] = useState(0)
   const [authorizationNumber, setAuthorizationNumber] = useState("")
   const [transactionId, setTransactionId] = useState("")
   const [referenceNumber, setReferenceNumber] = useState("")
   const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
   const [bank_voucher_response, setBank_voucher_response] = useState({ bank_voucher_response: "" })
   const [detailPayment, setDetailPayment] = useState([]);
   const [printers, setPrinters] = useState([]);
   let machine = localStorage.getItem('machine');
   let token = localStorage.getItem('x-access-machine-token');
   let user = localStorage.getItem('user');
   //get machine
   if (user !== '' && user !== null && user !== undefined) {
      user = JSON.parse(localStorage.getItem('user'))
   }

   if (machine !== '' && machine !== null && machine !== undefined) {
      machine = JSON.parse(localStorage.getItem('machine'))
   }

   //get order
   const getOrder = async () => {
      console.log("Estoy en consultar orden");
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'));

      try {

         const response = await FilterOrderFetchAPI.getOrder(transaction_code, branchoffice.code, business.code, token)
         console.log(response);
         let getorder = response.data.order

         //format json 
         for (let i = 0; i < getorder.groups.length; i++) {
            for (let s = 0; s < getorder.groups[i].segments.length; s++) {
               getorder.groups[i].segments[s].item_id = getorder.groups[i].segments[s].item.item_id
               getorder.groups[i].segments[s].name = getorder.groups[i].segments[s].item.name
               getorder.groups[i].segments[s].cantidadItem = getorder.groups[i].segments[s].item.amount
               getorder.groups[i].segments[s].description = getorder.groups[i].segments[s].item.description
               getorder.groups[i].segments[s].img = getorder.groups[i].segments[s].item.img
            }
            getorder.groups[i].DetalleSegmentos = getorder.groups[i].segments
            getorder.groups[i].cantidadItem = getorder.groups[i].amount
            getorder.groups[i].descuento = getorder.groups[i].discount
            //getorder.groups[i].taxtValue = getorder.groups[i].tax.amount
            //getorder.groups[i].tax = getorder.groups[i].tax.percentage
         }

         if (getorder.bank_voucher_response != "0" && getorder.order_type != "devolution") {
            getorder.bank_voucher_response = JSON.parse(getorder.bank_voucher_response)
         }

         setOrder(getorder)
         console.log(response);
         console.log(response.data.order.bank_voucher_response);
         setBank_voucher_response(response.data.order.bank_voucher_response);
         setDetailPayment(response.data.paymentmetod)
      } catch (err) {
         ErrorAlert('', 'Error de consulta', 'error')
         console.log(err);
      }
   }

   //get printer 
   let arrayPrintersTemp = []
   const getPrinter = async () => {
      try {
         const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token'), machine.id);

         let arrayPrinters = response.data.printers;
         let printer_Comanda = "";
         let printer_ticket = ""
         let printer_zebra = "";

         for (let i = 0; i < arrayPrinters.length; i++) {

            getCategoriesByprinter(arrayPrinters[i])

            if (arrayPrinters[i].printer_type == 'Tickets') {
               printer_ticket = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Comanda') {
               printer_Comanda = arrayPrinters[i].printer_name
            }
         }

         setPrinters(arrayPrintersTemp)
         setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket });

      } catch (err) {
         console.log(err);
      }
   }

   const getCategoriesByprinter = async (printer) => {

      try {
         const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
         printer.categories = response.data.categories

      } catch (err) {
         printer.categories = []
         console.log(err);
      }
      arrayPrintersTemp.push(printer)

   }

   //Function to create a temp order return 
   const createOrderTempReturn = async () => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'));

      let jsonDiscountTemp = {
         "client_name": order.client_name,
         "cupon_code": "0",
         "is_cupon": false,
         "localizador": "0",
         "client_phone": "0",
         "dgi_qr": "0",
         "Orden": order.groups,
         "Descuento": order.discount,
         "Total": order.total,
         "itbms": order.tax,
         "taxes": order.taxes,
         "subtotal": order.subtotal,
         "machine_id": machine.id,
         "branchoffice_id": branchoffice.id,
         "tipoOrden": order.order_type,
         "paymethod": order.paymenthod,
         "is_discount_notification": false,
         "is_devolution_notification": true,
         "order_code_reference": transaction_code, "user": {
            id: user.id,
            name: user.name,
            code: user.user_code
         }
      }
      //We check if the order is already a devolution 
      if (order.order_type === "devolution") {
         ErrorAlert('Este numero de orden, corresponde a una devolución', 'Error', 'warning')
      } else {
         try {
            //We create the temp order 
            await OrderReturnFetchAPI.createTempReturn(jsonDiscountTemp, token)
            LoadingAlert("Solicitud de devolución", "Espere un mientras el administrador aprueba la devolucion")
            getOrderTempReturn()
         } catch (err) {
            console.log(err);
            ErrorAlert('Hay un orden en curso', 'Error', 'warning')
            //getOrderTempReturn(0)
         }
      }


   }

   //get order temporal return
   let instervalReturn = 0
   let countReturn = 0
   let orderSize = 0
   const getOrderTempReturn = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'));
      let machine = JSON.parse(localStorage.getItem('machine'))
      clearInterval(instervalReturn)
      try {
         const response = await OrderReturnFetchAPI.getTempReturn(machine.code, business.code, branchoffice.code, transaction_code, token)
         console.log(response);
         console.log(response.data.order.groups.length);
         orderSize = response.data.order.groups.length
         setOrderReturnSize(response.data.order.groups.length)
         //-----------------------------------------------------------------

         let code = response.data.order.code
         let message = ""
         let title = ""
         let icon = ""


         if (response.data.order.status_id == 1) {
            if (countReturn <= 5) {
               instervalReturn = setInterval(getOrderTempReturn, 5000)
               countReturn++
            } else {
               message = "Tiempo de espera agotado"
               title = "Advertencia"
               icon = "warning"
               updateTemReturn(code, 5, message, title, icon) //state 5 delete order dicount

               setShow(false)
               countReturn = 0
            }
         } else if (response.data.order.status_id == 8) {//order temporal is cancel
            message = "Orden devolucion cancelada."
            title = "Operacion denegada"
            icon = "error"

            updateTemReturn(code, 5, message, title, icon)
            setShow(false)
         } else if (response.data.order.status_id == 9) {//order temporalsuccess
            message = "Devolucion aplicada con exito."
            title = "Operacion exitosa"
            icon = "success"

            if (bank_voucher_response == "0" || order.paymenthod.toUpperCase() === "CLAVE" || order.paymenthod.toUpperCase() === "YAPPY" || order.paymenthod.toUpperCase() === "MIXTO") {
               let newVoucher = { EMVStreamResponse: "0" };
               registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
            } else {
               voidCreditDebit(code, message, title, icon);
            }

            //updateTemReturn(code, 3, message, title, icon);
            setAuthorizationNumber(order.bank_voucher_response.authorizationNumber);
            setTransactionId(order.bank_voucher_response.transactionId)
            setReferenceNumber(order.bank_voucher_response.referenceNumber)
            //We clean the state of the products checked 
            setShow(false)
         }



         //-----------------------------------------------------------------
      } catch (err) {
         console.log(err);
         ErrorAlert("Error de transaccion de la temporal.", "Error de temporal", "error")
      }
   }

   //register order temporal thwn is aprobed
   const registerOrderTempReturn = async (code, status, message, title, icon, data_bank_voucher_response) => {
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'));

      try {
         const response = await OrderReturnFetchAPI.registerOrderReturn(transaction_code, business.code, branchoffice.code, token, data_bank_voucher_response);
         updateTemReturn(code, status, message, title, icon);
         printOrder(response.data.data.order_id, response.data.data.transaction_code);

      } catch (err) {
         console.log(err);
      }
   }

   // update order temporal return to delete
   const updateTemReturn = async (code, status, message, title, icon) => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))

      let jsonUpdate = {
         "order_code": code,
         "business_code": business.code,
         "branchoffice_code": branchoffice.code,
         "status": status
      }
      try {
         const response = await OrderReturnFetchAPI.updateOrderTempReturn(jsonUpdate, token)

         setOrder({ order: "" })
         ErrorAlert(message, title, icon)

      } catch (err) {
         console.log(err);
      }
   }

   //print order
   const printOrder = async (id_Orden_Imprimir, transaction_code_order) => {

      console.log(orderSize);
      let JsonImprimir = [{
         "type_ticket": "Nota a credito",
         "Printers": printers,
         "Nombre_maquina_impresora": infoPrinter.printer_ticket,
         "Nombre_cocina_impresora": infoPrinter.printer_comanda,
         "Nombre_zebra_impresora": "",
         "Id_Orden_Imprimir": id_Orden_Imprimir,
         "token": localStorage.getItem('x-access-machine-token'),
         "transaction_code": transaction_code_order,
         "amount_item": orderSize,
         "imprimir_comanda": "no",
      }]

      console.log(JsonImprimir);

      try {

         const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
         const response = await PrinterFetchAPI.printOrder(JsonImprimir, response_machine.data.data.windows_ip)
         console.log(response);
      } catch (err) {
         console.log(err);
      }
   }

   const voidCreditDebit = async (code, message, title, icon) => {
      console.log(bank_voucher_response);

      let authorizationNumber = bank_voucher_response.authorizationNumber;
      let referenceNumber = bank_voucher_response.referenceNumber;
      let systemTraceNumber = bank_voucher_response.systemTraceNumber;

      try {
         //request get ip windows
         const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));

         //request return total of the transaction
         const json = [{ Tipo: 'VOID', TipoVenta: "VOID", authorizationNumber: authorizationNumber, referenceNumber: referenceNumber, systemTraceNumber: systemTraceNumber, paymentMethod: { id: order.paymenthod_id, payment: order.paymenthod, payment_id: order.paymenthod_id, machine_id: machine.id, params: detailPayment } }];
         const response = await RequestLocalFetchAPI.postPaymentMethod(json, response_machine.data.data.windows_ip);
         console.log(response.data.data);
         let newVoucher = { EMVStreamResponse: response.data.data.EMVStreamResponse }
         console.log("hola");

         if (response.data.data.EMVStreamResponse.responseCodeDescription) {
            if (response.data.data.EMVStreamResponse.responseCodeDescription == "APROBADA") {
               registerOrderTempReturn(code, 3, message, title, icon, newVoucher);
            } else {
               ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
            }
         } else {
            ErrorAlert("No se aplico la devlucion.", "Error en la devolucion", "error")
         }

      } catch (err) {
         console.log(err);
      }
   }

   // view get order to return
   const cardGetOrder = () => {
      const cardOrder = order.groups.map((product) => (
         <>
            <div className="d-flex text-muted pt-3 px-xs-2 border-bottom">
               <img className="bd-placeholder-img flex-shrink-0 me-2 rounded bg-secondary" src={product.img} width="42" height="42" />
               <div className="pb-3 mb-0  lh-sm  w-100">
                  <div className="d-flex justify-content-between ">
                     {/* Name */}
                     <span className="text-dark fw-bold title-product "> {product.name} </span>
                     {/* Cantidad/ Quantity*/}
                     <span className='px-1 number-counter'>
                        <p>{product.amount}</p>
                     </span>
                     <label className="fw-bold" >$ {product.price.toFixed(2)} </label>
                  </div>
                  {/** Segment items */}
                  <details open>
                     <summary> <small> Detalles </small> </summary>
                     {product.segments.map(item => (
                        <div>
                           <small className=''>{item.segment_name}: {item.name} - ${item.price}</small>
                        </div>
                     ))}
                  </details>
               </div>
            </div>
         </>
      ));
      return (cardOrder)
   }

   let title_modal = "Devolución"
   return (
      <>
         {/* 
               <div className=" m-1 bg-white border rounded ">
                     <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => (setShow(true), getPrinter())}>
                        <i className="uil uil-book-reader buttons-label icon-mobile h3"></i>
                        <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
                     </button>
                  </div> */}






         {actvPlus ?
            <button className='btn btn-outline-primary  border  rounded-pill me-1 shadow-sm bg-seconadary p-2 bg-opacity-10' onClick={() => (setShow(true), getPrinter())}>
               <i className="uil uil-history-alt icon-mobile"></i>
               <small className='fw-bold hidden-label-sm '>
                  {title_modal}
               </small>
            </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => (setShow(true), getPrinter())}>
               <i className="uil uil-history-alt buttons-label icon-mobile h3"></i>
               <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
            </button>
         </div> : <></>}




         <Modal
            show={show}
            size="md"
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
            centered
         >
            <div className='mt-2 text-center p-1'>
               <h4 className='fw-bold'><FaHistory /> {title_modal} </h4>
            </div>


            <Modal.Body>
               <div className='row bg-light p-2'>
                  <div className="col-6 col-md-8">
                     <input type="text" className="form-control" placeholder='Número de orden ' onChange={(e) => (setTransaction_code(e.target.value))} />
                  </div>
                  <div className=' col-6 col-md-4'>
                     <button className='btn btn-primary' onClick={async () => (getOrder())}>Buscar orden</button>
                  </div>
               </div>

               <div className='p-2'>
                  {!order.groups ? "" : <>
                     <div className='row'>
                        <div className='col-sm-6'>
                           <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                              <div className="d-flex gap-2 w-100 justify-content-around">
                                 <div>
                                    <h5 className="mb-0">Orden - {order.transaction_code}</h5>
                                    <p className="mb-0 opacity-75">Fecha - {order.createdAt.slice(0, 10)}</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                        <div className='col-sm-6'>
                           <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                              <div className="d-flex gap-2 w-100 justify-content-around">
                                 <div>
                                    <h5 className="mb-0">{order.client_name}</h5>
                                    <p className="mb-0 opacity-75">ruc: {order.client_ruc} dv: {order.client_dv}</p>

                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                     <div className=''>
                        {cardGetOrder()}</div>
                     <div className=''>
                        <div className="order-total mt-2 mx-1">
                           <p className='text-size-order' >Descuento: <span>$ <strong>{order.discount.toFixed(2)}</strong></span> </p>
                           <p className='text-size-order '>Subtotal: <span>$ <strong>{order.subtotal.toFixed(2)}</strong></span></p>
                           {order.taxes.map((tax) => (
                              <><p className='text-size-order' >Impuestos {tax.tax}: <span>$ <strong>{tax.total.toFixed(3)}</strong></span> </p></>
                           ))}
                           <p className='text-size-order' > <b>Total con impuesto:</b>  <span>$ <strong>{order.total.toFixed(2)}</strong></span></p>
                        </div>
                     </div>
                  </>}
               </div>

               <div className='d-flex justify-content-between mt-2'>
                  <button className='btn btn-danger border-0 btn-lg' onClick={() => setShow(false)}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={async () => (createOrderTempReturn())}> Aplicar  </button>
               </div>

            </Modal.Body>
         </Modal>


      </>
   )
}

export default ModalReturn