import axios from 'axios';


const server = axios.create({
    baseURL: "http://localhost:4000",
    headers: { 'Content-Type': 'application/json' }
})

export class VendingFetchAPI {


    //Function operation vending machine 
    /*static async operation(data) {

        const despacho = {ruta: "http://192.168.1.26:9000/despachar", body: data};
        //const despacho = data;
        const despachoJson = JSON.stringify(despacho);
    
        let response = await axios.post("http://localhost:4000/api/despachar", despachoJson);
        return response;
    }*/

    static async operation(ipAndroid, data) {
        const res = await server(`/api/despachar`, {
            method: "post",
            data: {ruta: "http://"+ipAndroid+":9000/despachar", body: data},

        });
        return res
    }
    
    //SYCNSCAN: Scan all position of the machine
    //QUERYINIT: get result of the sycnscan
    //SETTEMPERATURA: set temperature of the machine in the  two object ({on: 0, off: 0 })
    //GETTEMP consult temperature of the machine
    //DESPACHAR: dispensed one position whith (datos:[{"X":'1', "Y":'1}])



    //PaymentMethod


    //Function to post temp
    static async
}