import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
//Component
import Header from "./components/Header"
import { RenderIf } from '../../components/utils/RenderIf'
import { Button } from 'react-bootstrap'
import ModalOutService from './ModalOutservice';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';



//api
import { MachinesFetchAPI } from '../../api/Machines';

import video from './assets/video/smartpay.mp4'


function Publicity() {
    let array_Video = []
    let branchoffice = ""
    let business = ""
    let machine = ""
    const [eventChange, setEventChange] = useState(0)
    const [indexNow, setIndexNow] = useState(0)
    const [publicityNow, setPublicityNow] = useState(0);
    const [showModaloutService, setShowModaloutService] = useState(false)
    const [stateSesionMachine, setStateSesionMachine] = useState(false);
    const [turn, setTurn] = useState(0)


    const navigate = useNavigate();


    if (localStorage.getItem("publicity") == null || localStorage.getItem("publicity") == undefined || localStorage.getItem("publicity") == "" || localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {

        array_Video.push({ video_url: video })
    } else {

        array_Video = JSON.parse(localStorage.getItem('publicity'));
        if (array_Video.length == 0) { array_Video.push({ video_url: video }) }

        branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
        machine = JSON.parse(localStorage.getItem('machine'));
        business = JSON.parse(localStorage.getItem("business"))
    }

    const redirection = () => {

        if (machine == "") {
            navigate('/kiosk');
        } else {
            getMachineById();
        }
    }



    const getMachineById = async () => {
        try {
            const response = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem("x-access-machine-token"))
            const branchofficeResponse = await BranchOfficeFetchAPI.getBranchOfficeByCode(business.code, branchoffice.code);
            let machine_status = response.data.data.machine_open;
            let branchoffice_status = branchofficeResponse.data.branchoffice
            if ( branchoffice_status.branchoffice_open == 1) {

                if(machine_status == 1){
                    navigate('/kiosk/' + business.code + '/' + branchoffice.code)
                }else{
                    setShowModaloutService(true);

                    setTimeout(() => {
                        shutdownMachine()
                    }, 5000);
    
                    console.log("La maquina esta fuera de servicio");
                }
                
            } else {
                setShowModaloutService(true);

                setTimeout(() => {
                    shutdownMachine()
                }, 5000);

                console.log("La sucursal esta fuera de servicio");
            }

        } catch (err) { console.log(err); }
    }

    const shutdownMachine = async () => {

        console.log("al intervalo");
        setShowModaloutService(false);

        let data = [{ "power": 2 }]
        try {
            const response = await RequestLocalFetchAPI.postShutdownMachine(data);
            console.log(response);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        // Función para manejar el intervalo
        const event = () => {
            // Incrementar el contador
            setPublicityNow((prevIndex) => (prevIndex + 1) % array_Video.length);
        };

        // Configurar el intervalo para que se ejecute cada segundo (1000 milisegundos)
        const intervalId = setInterval(event, 15000);

        // Función de limpieza para detener el intervalo cuando el componente se desmonta
        return () => {
            clearInterval(intervalId);
        };
    }, []); //Asegúrate de pasar un array vacío como segundo argumento para que el efecto se ejecute solo una vez al montar el componente



    return (
        <>
            <main>
                <section className='kiosk-delivery' onClick={() => redirection()} >
                    {/* <div>
                        <p>Contador: {contador}</p>
                    </div>*/}
                    <video  src={array_Video[publicityNow].video_url} type="video/mp4" autoPlay={"autoplay"} preLoad="auto" muted loop  ></video>
                </section>
            </main>

            <ModalOutService showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} ></ModalOutService>
        </>
    )
}

export default Publicity