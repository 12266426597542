import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { DivicesFechtAPI } from '../../api/Divices';
import { MachinesFetchAPI } from '../../api/Machines';
import VendingModalKeyCode from './VendingModalKeyCode';
import VendingModalSupport from './VendingModalSupport';
import VendingModalVoucher from './VendingModalVoucher';
import img_dispensed from './img/delivery.gif'
import QRCode from "react-qr-code";
import img_Error from './img/error.gif'
import img_support from './img/support.gif'
import img_check from './img/check.gif'


import Swal from 'sweetalert2';

function VendingDispensed({ itemReadyDispense, showModalDispense, setShowModalDispense, ready, statusError, setStatusError, voucher_Order, dataInfoPaymentSelect, setEventHandlerMenu, order_code_register, clearShoppingCart, voidCreditDebit, dataNoteCredit, setDataRutaQr, dataRutaQr, shoppingCart, registerOrderTempReturn }) {
  let token = localStorage.getItem('x-access-token');
  let machine = localStorage.getItem('machine_vending')
  const [contentSupport, setContentSupport] = useState({ title: "", body: [{ text: "" }, { text: "" }] })
  const [showModalvoucher, setshowModalvoucher] = useState(false);
  const [showModalSupport, setShowModalSupport] = useState(false);
  let routeQr = ""
  let branchoffice = ""
  if (localStorage.getItem('branchoffice_vending')) {
    if (localStorage.getItem('branchoffice_vending') != null || localStorage.getItem('branchoffice_vending') != '') {
      branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
      //routeQr = "192.168.1.12:3000/order/" + order_code_register + "/branchoffice/" + branchoffice.id
      routeQr = "https://smartpay.ai/order/" + order_code_register + "/branchoffice/" + branchoffice.id

    }
  }



  const viewItemreadyDispense = () => {
    const listDispense = itemReadyDispense.map((item) => (
      <>
        <div className='rounded border p-3 m-3' >
          <div className='d-flex  justify-content-between '>
            <img src={item.img} height="400px" width="400px"></img>
            <div className='m-3'>
              <h1 className='text-center display-1'>{item.name}</h1>
              <h3 className='text-center display-1'> ${item.price}</h3>
            </div>
            {item.status == "success" ? <><i className="uil uil-check-circle icon-button-params" ></i></> : <><i className="uil uil-exclamation-triangle icon-button-params"></i></>}
          </div>
        </div>
      </>
    ));
    return listDispense;
  }


  const handlerSuppor = async () => {
    registerOrderTempReturn({ EMVStreamResponse: "0" })
    machine = JSON.parse(localStorage.getItem('machine_vending'))
    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, token)
      console.log(response_machine);
      setContentSupport({ title: "Error de despacho", body: [{ "text": response_machine.data.data.machine_name + " no se despacho correctamente el producto en la posicion: "+ "X:" +shoppingCart[0].X+ ", Y:"+shoppingCart[0].Y+" de la orden: " + order_code_register }, { text: "informacion:" }] })
      setShowModalSupport(true)
      //clearShoppingCart();
      setShowModalDispense(false);

    } catch (err) {
      console.log(err);
    }

  }

  return (
    <>

      {/*Modal dispense item */}
      <Modal
        show={showModalDispense}
        fullscreen
        onHide={() => setShowModalDispense(false)}
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className='h-100'>
          <RenderIf isTrue={ready == true}>
            <section className='section-thanks h-100'>
              <div className='d-flex  align-items-center h-100 mensaje'>
                <div>
                  <h1 className='display-1 fw-bold my-5 text-center'>
                    ¡Gracias por comprar, vuelve pronto !
                  </h1>
                </div>
              </div>
              <div className="position-absolute bottom-0 end-0 fw-bold  opacity-25">
                <h2 className='px-2'>
                  Powered By Smart Retail Group
                </h2>
              </div>
            </section>

          </RenderIf>
          <RenderIf isTrue={ready != true}>
            
            <RenderIf isTrue={statusError != true}>
              <div className='d-flex  align-items-center h-100'>
                {itemReadyDispense.length == 0 ? <>
                  <section>
                    <h2 className='text-center display-1 fw-bold my-5'>Despachando productos</h2>
                    <img src={img_dispensed} className='mt-2' />
                  </section>
                </> : <>
                  <section>
                    <h1 className='text-center display-1 fw-bold my-5'> Productos despachados correctamente</h1>
                    <div className='m-3 p-3'>
                      {viewItemreadyDispense()}
                    </div>
                  </section>
                </>}
              </div>
              <div className="position-absolute bottom-0 end-0 fw-bold  opacity-25">
                <h2 className='px-2'>
                  Powered By Smart Retail Group
                </h2>
              </div>
            </RenderIf>

            
            <RenderIf isTrue={statusError == true}>
              <div className=' d-flex align-items-center h-100'>
                <div className='w-100' >
                  <RenderIf isTrue={dataNoteCredit == true}>
                    <div className='m-4'>
                      <h4 className='text-center display-1 fw-bold'>Detalle de la devolución</h4>
                    </div>
                  </RenderIf>
                  <div className="m-4  d-flex justify-content-center">
                    <QRCode value={routeQr} size={400} bgColor="#282c34" fgColor="#fff" level="H" />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <RenderIf isTrue={dataNoteCredit == true}>
                        <div className='d-flex justify-content-center'>
                          <img src={img_check} width={'200px'} />
                        </div>
                        <div className='d-flex justify-content-center'>
                          <h1 className='text-center display-1 fw-bold my-5'>Se realizó la  devolución correctamente.</h1>
                        </div>
                      </RenderIf>

                      <RenderIf isTrue={dataNoteCredit == false}>
                        <div className='d-flex justify-content-center'>
                          <img src={img_Error} width={'200px'} />
                        </div>
                        <div className='d-flex justify-content-center'>

                          <h1 className='text-center display-1 fw-bold my-5'>Ocurrio un error en el despacho</h1>
                        </div>
                      </RenderIf>

                    </Card.Title>
                    <Card.Text>
                      <RenderIf isTrue={dataNoteCredit == true}>
                        <div className='m-4'>
                          <h5 className='text-center text-muted display-4 fw-bold my-5'>Puedes escanear el QR, toma una foto para conservar tu comprobante.</h5>
                        </div>
                      </RenderIf>

                      <RenderIf isTrue={dataNoteCredit == false}>
                        <div className='m-4'>
                          <h5 className='text-center display-2 fw-bold my-5'>Se realizara la  devolución del monto pagado.</h5>
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={voucher_Order != "0"}>
                        <hr></hr>
                        <p className='text-center display-3 fw-bold my-5'> Método de pago: {dataInfoPaymentSelect}</p>
                        <h5 className='text-center display-3 fw-bold my-5'>Recibo de pago:</h5>
                        <VendingModalVoucher                         
                        showModalvoucher={showModalvoucher} 
                        setshowModalvoucher={setshowModalvoucher} 
                        voucher_Order={voucher_Order}>                          
                        </VendingModalVoucher>
                      
                      </RenderIf>



                    </Card.Text>
                    <RenderIf isTrue={dataNoteCredit == false}>
                      <div className='d-flex alight-item-center justify-content-center'>
                        <button className='btn btn-vending btn-vending-success' onClick={async () => { dataInfoPaymentSelect != "Yappy QR" && dataInfoPaymentSelect != "Yappy" && dataInfoPaymentSelect != "Clave" && dataInfoPaymentSelect != "Credito" ? voidCreditDebit() : handlerSuppor() }}>Continuar para solicitar  devolución</button>
                      </div>
                    </RenderIf>

                    <RenderIf isTrue={dataNoteCredit == true}>
                      <div className='d-flex justify-content-start'>
                        <button className='btn btn-vending btn-outline-secondary' onClick={async () => (clearShoppingCart())}>Cerrar</button>
                      </div>
                    </RenderIf>

                  </Card.Body>
                </div>
              </div>
            </RenderIf>
          </RenderIf>
        </Modal.Body>
      </Modal>


      


      <VendingModalSupport showModalSupport={showModalSupport} setShowModalSupport={setShowModalSupport} contentSupport={contentSupport} setEventHandlerMenu={setEventHandlerMenu} suportReturn={true} ></VendingModalSupport>

    </>
  )
}

export default VendingDispensed