import React, { useState } from 'react'
//Component
import Header from "./components/Header"
import Promotions from './components/Promotions';
import { RenderIf } from '../../components/utils/RenderIf';
import CartButton from './components/CartButton';

function Categories({ icon, setCategorieSelect, background, categories, updateData, nextStep, prevStep, skipToStep3, selectCategorie, promotions, getSegmentsByPromotion, setAtivity, setStep, shoppingCart, parthners, setPageLoader }) {

  const [isButtonFocusedId, setButtonFocusId] = useState(null);

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  let logo = ''

  if (localStorage.getItem('logo') != null || localStorage.getItem('logo') != undefined || localStorage.getItem('logo') != "") {
    logo = localStorage.getItem('logo')
  }

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };

  const handleReturnParthners = () => {// function reload request and clear params localstorage
    if (localStorage.getItem('branchoffice_original') != null) {
      localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
    }

    if (localStorage.getItem('machine_original') != null) {
      localStorage.setItem('machine', localStorage.getItem('machine_original'))
    }

    setPageLoader(1)
  }

  return (
    <>
      <section className='kiosk-categories' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>

        <div className='carousel-container position-relative bg-light'>

          <Promotions icon={icon} promotions={promotions} getSegmentsByPromotion={getSegmentsByPromotion} />

        </div>
        <div className='position-absolute  w-100'>
          <div className='d-flex justify-content-between 
          bg-transparent align-items-center mx-2 mt-3'>
            <Header
              classContainer={"scale-up-horizontal-left"}
              classImg={"kiosk-delivery_header_img "} />

            <RenderIf isTrue={shoppingCart.length > 0}>
              <CartButton setStep={setStep} shoppingCart={shoppingCart}></CartButton>
            </RenderIf>
          </div>
        </div>

        <div className='kiosk-delivery_section categories position-absolute w-100'>
          <div className='kiosk-container_section_lista' >
            <div className='kiosk-lista scroll-fast position-relative'>
              {categories.map((categorie, index) => (
                <>
                  <button className={`  btn kiosk-lista_items position-relative  bounce-left                  
                     ${isButtonFocusedId === categorie.category_id ? 'focused' : ''}`}
                    onFocus={() => handleButtonFocus(categorie.category_id)}
                    onBlur={handleButtonBlur}
                    type='button' key={index}
                    autoFocus={index === 0}
                    onClick={() => (selectCategorie(categorie.category_id, true), setCategorieSelect(categorie))}>

                    <div className="card kiosk-card-item shadow-lg h-100"
                      id={categorie.category_id} value={categorie.category_id}
                    >
                      <div className='card-img-top bg-light h-100'>
                        <img src={categorie.img === null || categorie.img === "" ? logo : categorie.img} className='rounded-4 ' width="100%" alt="..." loading='lazy' />
                      </div>

                      <div className="card-body">
                        <span className='kiosk-text-categories d-block text-center text-truncate'>
                          {categorie.category_name}
                        </span>
                      </div>
                    </div>
                  </button>
                </>
              ))}


            </div>
          </div>
        </div>


        <RenderIf isTrue={parthners.length > 0}>
          <div className='d-flex position-absolute start-0 bottom-0 container-button'>
            <button
              type="button"
              onClick={() => handleReturnParthners()}
              className={`m-0 btn-lg  shadow-lg border-0
            button-ft_1 position-relative
            ${isButtonFocusedId === 'exit' ? 'focused' : ''}`}
              onFocus={() => handleButtonFocus('exit')}
              onBlur={handleButtonBlur}
              title='Regresar'
            >
              <svg xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                className=""
                id="left-arrow">
                <path
                  d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

              <div className="position-absolute postition-icon-md">
                {isButtonFocusedId === 'exit' && <img src={icon} alt="Hand" className='icon-focus' />}
              </div>
            </button>
            {/* <button className='btn btn-primary button-ft '
          onClick={prevStep}>
          <i className="bi bi-arrow-left-short "></i> Regresar 
        </button> */}
          </div>
        </RenderIf>

        <div className=' bottom-0 start-50 translate-middle-x position-absolute'>
          <h2 className='text-center '>
            <span className="kiosk-container_section_title badge rounded-4  bg-warning text-dark">
              Categorias</span>
          </h2>
        </div>
      </section>
    </>
  );
}
export default Categories