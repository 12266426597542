//React Components 
import React, { useState, useEffect, useMemo } from 'react';
//Components
import { Table, Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import ModalCreateSegment from './ModalSegment/ModalCreateSegment';
import ModalSegmentListItems from './ModalSegment/ModalSegmentListItems';
import ModalEditSegment from './ModalSegment/ModalEditSegment';
import Swal from 'sweetalert2';
import ModalImport from './ModalImport';
import ModalAsingAndRemovedSegmentToBranchoffice from './ModalSegment/ModalAsingAndRemovedSegmentToBranchoffice';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
import { ListGroup } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

//API
import { SegmentsFechtAPI } from '../../api/Segments';

const SegmentTable = ({ branchoffice_id, eventHandler, token, title, user }) => {
    const [segments, setSegments] = useState([]);
    const [searchedSegments, setSearchedSegments] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // We load the segments list 
        loadSegments();
    }, [eventHandler]);


    const deleteSegmentByBranchoffice = async (segment_id) => {
        let data = {
            segment_id: segment_id
        }

        try {
            const response = await SegmentsFechtAPI.deleteSegemtOfBranchoffice(branchoffice_id, data, token)
            ErrorAlert('', 'Acompañante eliminado', 'success')
            loadSegments()

        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar el acompañante', 'error')

        }
    }

    const beforeDelete = async (segment_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar este acompañante?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSegmentByBranchoffice(segment_id)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino el acompañante',
                    'error'
                )
            }
        })
    }

    //Function to load segments
    const loadSegments = async () => {
        try {
            //We search the segments of the branchoffice 
            const segments = await SegmentsFechtAPI.getSegmentsByBranchoffice(branchoffice_id, token);
            setSegments(segments.data.data)
            setSearchedSegments(segments.data.data)

        } catch (err) {

            setSegments([])
            setSearchedSegments([])
        }

    };

    //Filter the segments by the term searched 
    const filteredSegments = useMemo(() => {
        if (searchTerm === '') {
            return segments
        }

        return searchedSegments.filter(segment => {
            const nameMatch = segment.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = segment.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, segments]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked,
            segment_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await SegmentsFechtAPI.updateSegmentBranchoffice(branchoffice_id, data, token)
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los segments al cambiar el estado del switch
            loadSegments();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error, 'Opss!', 'warning');
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los segments al cambiar el estado del switch
            loadSegments();
        }
    };


    const viewSegments = () => {

        const list = filteredSegments.map(segment => (
            <Col sm="2" md="6" lg="4" xl="4" >
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >

                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{segment.name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                                {/**We check if the user has permition to enable and disable segment */}
                                <RenderIf isTrue={checkUserPermission('enable_disable_segments', user.permissions) !== -1}>
                                    <Form.Switch
                                        type="switch"
                                        id={segment.segment_id}
                                        label=""
                                        checked={segment.status === 1 ? true : false}
                                        onChange={handleSwitchChange}
                                    />
                                </RenderIf>
                                {segment.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>


                            <p className=''>{segment.description}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className='m-1'><ModalSegmentListItems segment={segment} branchoffice_id={branchoffice_id} ></ModalSegmentListItems></div>
                                {/**We check if the user has permition to update segment */}
                                <RenderIf isTrue={checkUserPermission('update_segment', user.permissions) !== -1}>
                                    <div className='m-1'><ModalEditSegment segment={segment} loadSegments={loadSegments} ></ModalEditSegment></div>
                                </RenderIf>
                                <div className='m-1'>
                                    {/**Render the button remove if the user has permission */}
                                    <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_segment', user.permissions) !== -1}>
                                        <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => beforeDelete(segment.segment_id)}>
                                            <i className="uil uil-trash-alt"></i>
                                        </button>
                                    </RenderIf>
                                </div>

                            </div>
                        </div>


                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }

    return (
        <>

            <div className='board rounded shadow-sm p-sm-1 p-lg-4'>
                <div className="row bg-light rounded py-3 m-1 border border-light">
                    <div className='col-md-12'>
                        <h4 className='fs-4 fw-bold text-center'>{title}</h4>
                        <p className=' text-center'>Cantidad acompañantes: {segments.length}</p>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center align-item-center' p-2">
                        <div className="w-auto  d-flex justify-content-end ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar </span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese producto"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 p-2">
                        <div className='d-flex justify-content-center align-item-center'>
                            {/**Validation to create segment */}
                            <RenderIf isTrue={checkUserPermission('create_segment', user.permissions)!== -1}>
                                <div className=''><ModalCreateSegment loadSegments={loadSegments}></ModalCreateSegment></div>
                                <div className=''><ModalImport title={"acompañante"} load={loadSegments}></ModalImport></div>
                            </RenderIf>
                            {/**Validation to eliminate segment */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_segment', user.permissions)!==-1}>
                                <div className=''><ModalAsingAndRemovedSegmentToBranchoffice branchoffice_id={branchoffice_id} load={loadSegments} segmentsBranchoffice={segments}></ModalAsingAndRemovedSegmentToBranchoffice></div>
                            </RenderIf>

                        </div>
                    </div>
                </div>
                <div className="w-auto border-history shadow-sm mt-3">
                    <Container>
                        <Row>
                            {viewSegments()}
                        </Row>
                    </Container>
                </div>

            </div>

        </>

    );
};





export default SegmentTable;
