import React, { useState } from 'react'
import KeyPad from '../../components/Keypad/keypad';
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad';
//Component
import Header from "./components/Header";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { DonleeFechtAPI } from '../../api/Donlee';

function ModalDonLeePoints({ icon, background, showModalDonleePoints, setShowModalDonLeePoints, setShowModalDonLeeRegister, formatJsonOrder, setClientPhone, businesssConfig, nextStep, setShowModalNotificator, setAtivity }) {
    //state of keypad number
    const [displayNumber, setDisplayNumber] = useState('');
    const [valueInput, setValueInput] = useState("");

    //Focus
    const [isButtonFocusedId, setButtonFocusId] = useState(null);

    const handleButtonFocus = (buttonId) => {
        setButtonFocusId(buttonId);
    };

    const handleButtonBlur = () => {
        setButtonFocusId(null);
    };


    const getClientByPhone = async () => {
        
        let token = localStorage.getItem('x-access-machine-token')
        if (displayNumber == "") {
            ErrorAlert(" ", "Debes ingresar el numero", "info");
        } else {
            setClientPhone(displayNumber);
            try {
                const response = await DonleeFechtAPI.getClientPhone(displayNumber, token)
                console.log(response);
                console.log(response.data.client);

                if(response.data.client.length == 0 ){
                    console.log("No se encontro el numero de celular");
                    ErrorAlert("", "Cliente no encontrado", "warning");
                    setShowModalDonLeePoints(false);
                    setShowModalDonLeeRegister(true);
                }else{
                    ErrorAlert("", "(:  Gracias por su visita "+ response.data.client[0].name + ":D", "success");
                    
                    formatJsonOrder();
                    setShowModalDonLeePoints(false);
                    setShowModalNotificator(true);
                }
              
            } catch (err) {
                console.log(err);
                ErrorAlert(" ", "Ocurrio un error en la consulta", "error")
            }
        }
    }

    return (
        <>
            <Modal
                show={showModalDonleePoints}
                fullscreen
                onHide={() => setShowModalDonLeePoints(false)}>
                <Modal.Body className='p-0 m-0'>
                    <main className='keyboard-main h-100 d-flex  justify-content-center align-items-center' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
                        <div className=''>
                            <section className='bd-highlight'>
                                <Header
                                    classContainer={"kiosk-delivery_header m-0 p-0"}
                                    classImg={"kiosk-delivery_header_img"}>
                                </Header>
                                <div className='d-flex justify-content-center alignt-item-center'>
                                    <h2 className='kiosk-delivery_title  text-center '>
                                        Ingresa tu número de celular
                                    </h2>
                                </div>
                            </section>
                            <section className='bd-highlight w-100 mb-5'>
                                <NumericKeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} maxString={8} />
                            </section>
                        </div>
                    </main>
                </Modal.Body>
                {/* <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => (setShowModalAplyCupon(false), setDisplayNumber(''))}>
                        <i className="uil uil-arrow-left"></i>
                        Regresar
                    </button>

                    <button type="button" className=" btn btn-success btn-vending-product mx-4 rounded" onClick={() => (handleSaveCode())}>
                        <i className="uil uil-arrow-right"></i> Siguiente
                    </button> */}

                <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
                    {/* 1 */}
                    <button
                        type="button"
                        onClick={() => (setShowModalDonLeePoints(false), setDisplayNumber(''))}
                        className={`m-0 btn-lg  kiosk-subtitle-md   shadow-lg border-0 button-ft_1
                   button-ft_1 position-relative
                  ${isButtonFocusedId === 'exit' ? 'focused' : ''}`}
                        onFocus={() => handleButtonFocus('exit')}
                        onBlur={handleButtonBlur}
                        title='Regresar'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            className=""
                            id="left-arrow">
                            <path
                                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

                        <div className="position-absolute postition-icon-md">
                            {isButtonFocusedId === 'exit' && <img src={icon} alt="Hand" className='icon-focus' />}
                        </div>
                    </button>
                    {/* 2 */}
                    <div className="m-2 ">
                        <button className={`btn btn-success kiosk-subtitle-md w-100 rounded-4 border px-5 py-3 h-100 position-relative           
                     ${isButtonFocusedId === 'nextNotificator' ? 'focused' : ''}`}
                            onFocus={() => handleButtonFocus('nextNotificator')}
                            onBlur={handleButtonBlur}
                            onClick={() => getClientByPhone()}
                        >
                            Continuar

                            <div className="position-absolute postition-icon-md">
                                {isButtonFocusedId === 'nextNotificator' && <img src={icon} alt="Hand" className='icon-focus' />}
                            </div>
                        </button>
                    </div>
                </div>

            </Modal>

        </>
    )
}

export default ModalDonLeePoints