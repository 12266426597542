import React, { useEffect, useState } from 'react'
import PillOrder from './PillOrder';
import { useParams } from "react-router-dom";

//Img
import logo_sp from '../img/logos/logo.png';
import empty from '../img/icons/empty.png'
//Api 
import { OrdersFetchAPI } from '../../api/Orders';
//Sweet alert
import Swal from "sweetalert2";


function ListOrders() {
   let { business_code, branchoffice_code } = useParams();
   let order_type = 'comer aqui'
   let status_id = 1

   const [ordersData, setOrdersData] = useState([]);
   // console.log(params)
   //  let { business_code } = useParams();

   const orders = async (token) => {
      try {
         const response = await OrdersFetchAPI.getsOrdersByStatusByType(business_code, branchoffice_code, status_id, token)
         console.log(response)
         setOrdersData(response.data.orders)
      } catch (error) {
         console.log('Error Vacio', error);
      }
   };

   const ListEmpty = () => {
      return (
         <>
            <div className=" m-3 w-auto ">
               <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                  <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                     <img src={empty} alt="Vacio" width="35px" height="35px" />
                     <div className='text-center mt-3'>
                        <h5>Vacío</h5>
                        <h6 className="mb-0 text-secondary fw-bold">No hay ordenes registradas</h6>
                     </div>
                  </div>
               </div>
            </div>

         </>
      )

   }

   const listOrders = () => {
      console.log("He consultadoya ", ordersData)
      const getdata = ordersData
      const listOrdersData = getdata.map((data) => (
         <>
            <PillOrder
               key={data._id}
               data={data}
            />
         </>
      ))
      return (<>{listOrdersData}</>)
   }

   //Update Orders (<>{(getdata.length != 0) ? listOrdersData :  <ListEmpty/> }</>)
   function updateOrders() {

      orders()
      console.log('Actuliazando ');
      const Toast = Swal.mixin({
         toast: true,
         position: 'bottom',
         showConfirmButton: false,
         timer: 3000,
         timerProgressBar: true,
         background: '#39a93d',
         color: '#fff',
         didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
         }
      })

      Toast.fire({
         icon: 'success',
         title: 'Ordenes Actualizadas',

      })
   }

   //Ejecutar Funcion cuando carge pagina 
   useEffect(() => {
      (async () => {
         let token = localStorage.getItem('x-access-token');
         try {
            await orders(token)
         } catch (err) {
            console.log(err);
         }
      })();
   }, []);

   return (
      <>
         <div className='d-flex justify-content-end'>
            <button className='btn btn-light btn-sm ' title="Cerrar Sesión actual" ><span className='text-danger'><i className="uil uil-signout"></i> Salir</span></button>
         </div>
         <main className='main-orders-views '>
            <div className='d-flex justify-content-center'>
               <img src={logo_sp} alt="" width="120" />
            </div>
            <div className='mx-3'>
               <div className='d-flex justify-content-between align-items-center align-content-center'>
                  <h4 className='fs-5'>Ordenes</h4>
                  <div className='d-flex justify-content-center mb-2'>
                     <div className=' btn btn-success btn-upadate ' onClick={() => updateOrders()}>
                        <i className="uil uil-sync"></i> Actualizar ordenes
                     </div>
                  </div>
               </div>
               <div className='orders-wrapper border-inset border border-history bg-light'>
                  {listOrders()}
               </div>
            </div>
         </main>
      </>
   )
}

export default ListOrders