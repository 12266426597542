//API
import { EvertechFetchAPI } from "../../api/Evertech";
import { OrderReturnFetchAPI } from '../../api/Paymentpost/OrderReturn.jsx';
import { PagueloFacilFetchAPI } from "../../api/PagueloFacil";
//Alert 
import ErrorAlert from "../Alerts/ErrorAlert";
import SuccessAlert from "../Alerts/SuccessAlert.jsx";

//Function to return a payment by evertech 
const evertechReturnPayment = async (secretKey, loginKey, paymentEvertechInternalReference, total, token, transaction_code, business_code, branchoffice_code, bank_voucher_response, setLoad) => {
    let error = false;
    let evertec_response;
    try {
        //We make the return on evertech 
        evertec_response = await EvertechFetchAPI.returnPayment(secretKey, loginKey, paymentEvertechInternalReference, total, token);
    } catch (err) {
        error = true
        ErrorAlert(err.response.data.error.message, 'Error', 'error')
    }

    //We check if some error ocurs on the return evertech payment 
    if (error === false) {
        try {
            bank_voucher_response.EMVStreamResponse = evertec_response
            await OrderReturnFetchAPI.registerOrderReturn(transaction_code, business_code, branchoffice_code, token, JSON.stringify(bank_voucher_response))
            SuccessAlert('Devolucion realizada');
            //We recharge the page
            setLoad(1)
        } catch (err) {
            console.log(err)
            ErrorAlert("Error al registrar la devolucion (ya se realizo)", 'Error', 'error')
        }
    }
}


//Functipn to return a payment by paguelofacil 
const pagueloFacilReturnPayment = async (cclw, codOper, description, total, token, transaction_code, paguelofacil_auth_token, business_code, branchoffice_code, bank_voucher_response, setLoad) => {
    let error = false
    let paguelofacil_response;
    try {
        //We make the return on evertech 
        paguelofacil_response = await PagueloFacilFetchAPI.returnPayment(cclw, codOper, description, total, paguelofacil_auth_token, token)
    } catch (err) {
        error = true
        ErrorAlert(err.response.data.error.headerStatus.code === 623 ? 'Transaccion ya devuelta' : 'Ocurrio un error inesperado', 'Error', 'error')
    }

    //We check if some error ocurs on the return evertech payment 
    if (error === false) {
        try {
            bank_voucher_response.EMVStreamResponse = paguelofacil_response
            await OrderReturnFetchAPI.registerOrderReturn(transaction_code, business_code, branchoffice_code, token, JSON.stringify(bank_voucher_response))
            SuccessAlert('Devolucion realizada');
            //We recharge the page
            setLoad(1)
        } catch (err) {
            console.log(err)
            ErrorAlert("Error al registrar la devolucion (ya se realizo)", 'Error', 'error')
        }
    }
}

export const ReturnPayments = (props) => {
    //We check the return type that we want to apply
    if (props.returnType === "Evertech") {
        //We make the return 
        evertechReturnPayment(props.secretKey, props.loginKey, props.paymentEvertechInternalReference, props.total, props.token, props.transaction_code, props.business_code, props.branchoffice_code, props.bank_voucher_response, props.setLoad);
    }

    //We check the return type that we want to apply
    if (props.returnType === "PagueloFacil") {
        //We make the return 
        pagueloFacilReturnPayment(props.cclw, props.codOper, `Devolucion de la transaccion ${props.transaction_code}`, props.total, props.token, props.transaction_code, props.pagueloFacilAuthToken, props.business_code, props.branchoffice_code, props.bank_voucher_response, props.setLoad);

    }

}