import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CouponPosFetchAPI {
    static async getCupon(code_coupon, token) {
        const res = await server(`/cupon/${code_coupon}/cupon_code`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }
}