import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrdersFetchAPI } from '../../api/CreateOrder';
import { RenderIf } from "../utils/RenderIf";
import UserContext from "../../context/UserContext";
//External Components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//img
import invoice from '../img/icons/receipt.png'
import bill from '../img/icons/bill.png'
import refund from '../img/icons/refund.png'
import empty from '../img/icons/empty.png'
//Sweet alert
import Swal from "sweetalert2";

function RequestPayment() {
    const navigate = useNavigate();
    const token = localStorage.getItem('x-access-token')
    let intervalopregunta = 0
    let limitepreguntas = 0

    //--------------------------------------------------------------------------------
    /*const timeintervalupdate = 30000
    let intervaloupdate = setInterval( async () => {
        await getorders();
    }, timeintervalupdate);*/
    //--------------------------------------------------ESTADOS---------------------------------------------

    const [itbms, setitbms] = useState(0)
    const [subtotal, setsubtotal] = useState(0)
    const [total, settotal] = useState(0)


    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));


    const [machinedata, setmachine] = useState({
        machine: [],
    });

    const [listorders, setlistorders] = useState({
        listorders: [],
    });

    const [option, setOption] = useState({
        option: "Principal",
    });

    const [ordernowatm, setordernowatm] = useState({ order: [] })

    const [temporal, setTemporal] = useState({
        listorders: [],
    });

    //------------------------------------------------EVENTOS-----------------------------------------------------

    //valida la sesion y el estado de apertura o cierre de la caja
    validaraperturacaja()
    function validaraperturacaja() {

        if (token == "" || !token) {
            navigate('/login/9912')
        }


        if (user.on_atm == 1) {
            option.option = "Payment"
        } else if (user.on_atm == 0) {
            option.option = "Principal"
        }

    }

    //devuelve a seccion de apertura de caja
    const showCards = async (option) => {
        setOption({
            option: option,
        });

        await getmachine()
        await getorders()

    };

    // este evento suma itbms y subtotal
    function handleChange2(event) {
        let temp = 0
        if (event.target.value == '' || event.target.value === null) {
            console.log("event.target.value");
            setsubtotal(0)
            temp = 0.00
        } else {
            setsubtotal(event.target.value)
            temp = parseFloat(event.target.value)
        }
        settotal((temp + parseFloat(itbms)))

    }
    // este evento suma itbms y subtotal
    function handleChange(event) {
        let temp = 0
        if (event.target.value == '' || event.target.value === null) {
            setitbms(0);
            temp = 0.00
        } else {
            setitbms(event.target.value)
            temp = parseFloat(event.target.value)
        }
        settotal((temp + parseFloat(subtotal)))

    }

    //filtro para buscar cualquier coincidencia
    function handleChangeFiltro(event) {
        let resultFilter = [];

        if (event.target.value == "") {
            console.log(event.target.value);
            setlistorders({ listorders: temporal.listorders });
        } else {
            for (
                let recorrer = 0;
                recorrer < listorders.listorders.length;
                recorrer++
            ) {
                const examinar = listorders.listorders[recorrer];

                const info = JSON.stringify(examinar);
                const resultado = info
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());

                if (resultado == true) {
                    resultFilter.push(examinar);

                    setlistorders({
                        listorders: resultFilter,

                    });
                    console.log(resultFilter);
                }
            }
        }
    }

    //---------------------------------------------------Validacion de intervalo--------------------------------
    /* const validacionOntervalo =  async() =>{
 
         clearInterval(timeintervalupdate)
         let respuesta = await getorders()
 
         if(respuesta){
 
         }
     }*/
    //------------------------------------------------REQUEST-----------------------------------------------------

    //consultar maquinas disponibles
    const getmachine = async () => {
        try {
            const respuesta = await OrdersFetchAPI.getmachine(
                user.branchoffices[0].branchoffice_id, 5, token
            );
            setmachine({ machine: respuesta.data.data });
            console.log(respuesta);

        } catch (error) {
            console.log(error)
        }
    };

    const getuser = async () => {
        try{
            
            const respuesta = await OrdersFetchAPI.getUser(user.id, token);
            //user.on_atm =respuesta.data.data.on_atm;
            //user.dateBusiness = respuesta.data.data.dateBusines
           
            setUser(respuesta.data.user)
            console.log(respuesta.data.user);
            localStorage.setItem('user', JSON.stringify(user))
           

        }catch(error){

        }
    }

    //consulta listado de transacciones
    const getorders = async () => {
        try {
            let inform_z = false
            const respuesta = await OrdersFetchAPI.getOrder(
                user.user_code, user.business.code, inform_z
            );
            setlistorders({ listorders: respuesta.data.orders })
            setTemporal({ listorders: respuesta.data.orders })
            setordernowatm({ order: respuesta.data.order_inprogress })
            console.log(respuesta)
        } catch (error) {
            console.log(error)
        }
    };


    const getBusiness = async () =>{
        try{
            const respuesta = await OrdersFetchAPI.getBusiness(user.business.code, token);
            console.log(respuesta)

        } catch(error){

        }
    }

    //actualiza la orden seleccionada
    const updateorder = async (order, state) => {

        try {

            let data = {
                "order_code": order,
                "business_code": user.business.code,
                "branchoffice_code": user.branchoffices[0].code,
                "status": state
            }
            const respuesta = await OrdersFetchAPI.updateorderput(data);

            //--------------------------alert-------------------------
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Transaccion cancelada'
            })
            //-------------------------------------------------------------
            await getorders();
            console.log(respuesta)

        } catch (error) {
            console.log(error)
        }
    }

    //actualizaacion de cierre de caja en el usuario
    const updatdeUserBox = async (on_atm) => {

        try {
            let data = {
                "on_atm": on_atm
            }
            const response = await OrdersFetchAPI.getUpdateBox(data, user.id);
            //console.log(response);
            user.on_atm = 0;

            getuser();
            //localStorage.setItem('user', JSON.stringify(user));
        } catch (error) {

        }
    }

    //Cierre y generacion de informe z
    const informeZCierre = async () => {
        try {
            //Busca la fecha mas vieja y la mas nueva
            let arr1 = listorders.listorders[0].businessDate.split('T')
            let minDate = arr1[0]

            let sizearrList = (listorders.listorders.length - 1)
            let arr2 = listorders.listorders[sizearrList].businessDate.split('T')
            let maxDatetemp = arr2[0].split('-')

            let dayDateZ = (parseFloat(maxDatetemp[2]) + 1)
            let maxDate = maxDatetemp[0] + "-" + maxDatetemp[1] + "-" + dayDateZ
            //-----------------------------------------------------------------------

            let data = {
                "user_code": user.user_code,
                "business_code": user.business.code,
                "minDate": minDate,
                "maxDate": maxDate,
            }

            //--------------------------alert-------------------------
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })


            Toast.fire({
                icon: 'success',
                title: 'Informe z generado'
            })
            //-------------------------------------------------------------

             updatdeUserBox(0)
            await getorders()

            //We redirect to the report page 
            navigate(`/informz/${maxDate}/${minDate}/${user.user_code}/${user.business.code}`)
        } catch (error) {

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'error',
                title: 'No hay transacciones para generar informe z'
            })

        }
    }

    //notificacion, solicitud de devolucion
    const devNotification = async (data) => {

        let machine_code = 0
        for (let recorrer = 0; recorrer < machinedata.machine.length; recorrer++) {
            if (machinedata.machine[recorrer].machine_per_branch_id == data.machine_id) { machine_code = machinedata.machine[recorrer].code; console.log(machine_code); }
        }
        try {
            let dataNotificacion = {
                "machine": machine_code,
                "branchoffice_id": data.branchoffice.id,
                "business_id": data.business.id,
                "amount_to_return": data.total,
                "notification_type": "cash_back"
            }

            const respuesta = await OrdersFetchAPI.notification(dataNotificacion);

            //--------------------------alert-------------------------
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Devolucion notificada'
            })
            //-------------------------------------------------------------


            intervalopregunta = setInterval(async () => {
                await validacionRespuesta(respuesta.data.message_id, data)
            }, 10000);


        } catch (error) {
            console.log(error)
        }
    }

    //pregunta respuesta de solicitud de devolucion
    const validacionRespuesta = async (wamid, data) => {
        try {
            let respuesta = await OrdersFetchAPI.validarRespuestaSolicitud(wamid, 0)

            if (respuesta.data.data.user_response == "Si") {
                clearInterval(intervalopregunta)

                await aplicardevolucion(data)


            } else if (respuesta.data.data.user_response == "No") {
                clearInterval(intervalopregunta)
                //--------------------------alert-------------------------
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'La solicitud de devolucion fue negada'
                })
                //-------------------------------------------------------------

            }

            console.log(respuesta)
        } catch (error) {
            console.log("error")
            limitepreguntas = (limitepreguntas + 1)

            if (limitepreguntas == 5) {
                let respuesta = await OrdersFetchAPI.validarRespuestaSolicitud(wamid, 1)

                clearInterval(intervalopregunta)

                //--------------------------alert-------------------------
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'error',
                    title: 'La solicitud no fue respondida'
                })
                //-------------------------------------------------------------

            }
        }
    }

    //aplicar devolucion de producto
    const aplicardevolucion = async (data) => {

        try {
            console.log(data)
            let datadevolucion = {
                "order_code": data.code,
                "business_code": data.business.code,
                "branchoffice_code": data.branchoffice.code,
            }
            let respuesta = await OrdersFetchAPI.devolucion(datadevolucion, data.code, data.business.code, data.branchoffice.code)
            console.log(respuesta)

            await getorders()

        } catch (error) {
            console.log(error.response.error)
        }
    }

    // enviar orden
    const submit = async (event) => {
        event.preventDefault();
        const form = new FormData(event.target);
        form.append("business_code", user.business.code);
        form.append("branchoffice_code", user.branchoffices[0].code);
        form.append("user_code", user.user_code);
        form.append("businessDate", user.dateBusiness);
        let newproduct = Object.fromEntries(form.entries());

        try {
            const response = await OrdersFetchAPI.createOrder(form);
            await getorders();
            //--------------------------alert-------------------------
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: 'Orden de pago creada'
            })
            //-------------------------------------------------------------


        } catch (error) {
            console.log(error);
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.response.data.error,
                showConfirmButton: false,
                timer: 1500
            })

        }
    };

    //Aqui actualizamos el usuario una ves se valida que la fecha de negocio si esta disponible
    const OpenBoxSubmit = async (update) => {
        try {

            const response = await OrdersFetchAPI.getUpdateBox(update, user.id);
                user.on_atm = 1;
                user.dateBusiness = update.dateBusiness

                getuser();
                //localStorage.setItem('user', JSON.stringify(user));
                await getorders();
                //--------------------------alert-------------------------
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Caja Abierta'
                })
           
        } catch (error) {

            console.log(error)

            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'info',
                title: error.response.data.error,
            })
   
        }
    }


    // Validacion de disponiblidad de fecha de negocio para abrir la caja
    const submitcaja = async (event) => {

        console.log(user)

        event.preventDefault();
        const form = new FormData(event.target);
        form.append("on_atm", 1);
        let update = Object.fromEntries(form.entries());
        
        try {
            //validacion de disponibilidad fecha de negocio
            let dateMin = update.dateBusiness
            let date = dateMin.split('-')
            let dateMax = date[0] + "-" + date[1] + "-" + (parseFloat(date[2]) + 1)

            //Si el request devuelve algo aqui quiere decir que la fecha de negocio ya fue utlizada por lo cual no lo validaremos y no enviaremos a la apertura
            let validacion = await OrdersFetchAPI.analiticsTypeAtm(user.user_code, user.business.code, dateMin, dateMax)
            
               //--------------------------alert-------------------------
               const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })



            if(validacion.data.analitics.businessDate_orders.length == 0){
                OpenBoxSubmit(update)//aperturamos la caja y actualizamos los parametros del usuario
            }else{
                Toast.fire({
                    icon: 'info',
                    title: 'Esta fecha de negocio ya fue utilizada, intenta con otra.'
                })
            }
            //-------------------------------------------------------------
        } catch (error) {
            console.log(error);
            //-------------------------------------------------------------
        }
    };
    //-----------------------------------------------------------------------------------------------------

    useEffect(() => {
        (async () => {
            try {
        
                await getuser();
                await getmachine();
                await getorders();
                


            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    //-----------------------------------CONSTRUCCION DE LAYOUT-----------------------------------------------


    // lista las maquinas asignadas
    const listmachine = () => {
        const getdata = machinedata.machine
        const content = getdata.map((data) => (
            <>
                <option value={data.code} >{data.code}  </option>
                {/*<input className="list-group-item-check" type="radio" name="machine_code" id="machinesOptions1" value= {data.code} checked/>
            <label className="list-group-item py-3" for="machinesOptions1">
                {data.code}
                 <span className="d-block small opacity-50">With support text underneath to add more detail</span> 
            </label>*/}
            </>
        ));
        return (<>{content}</>)
    }

    //accion de cambio de estado de orden tempoarl
    const changeStade = (data) => {
        let state = 5
        
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: '¿Estas seguro?',
            text: "¿Quieres cancelar esta transaccion en curso?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                updateorder(data.order_code, state);
            }
        })

    };

    // accion devolucion
    const notificarDevolucion = async (data) => {

        console.log(data)
        if (data.status_id == 3) {


            if (data.devolution == false) {

                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: 'Notificar devolucion de transaccion: #' + data.code,
                    text: "¿Quieres solicitar devolucion de la transaccion?",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Si, cancelar',
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        llamadonotificacion()
                    }
                })

            } else if (data.devolution == true) {

                //--------------------------alert-------------------------
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'info',
                    title: 'Esta transaccion ya tiene una devolucion'
                })
                //-------------------------------------------------------------
            }
        }

        const llamadonotificacion = async () => {
            await devNotification(data)
        };
    }

    //event de cierrede caja
    const cierreZ = async () => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Cerrar caja',
            text: "¿Quieres cerrar caja?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                showCards("Principal");
                llamadoCierreZrequest();
            }
        })

        const llamadoCierreZrequest = async () => {
            await informeZCierre()

        };


    }

    //plantilla de transaccion en curso
    const ordernow = () => {
        const getdata = ordernowatm.order
        //console.log(getdata)
        const content = getdata.map((data) => (
            <>
                <div onClick={(event) => changeStade(data, event)} className=" m-auto mt-3 mx-lg-3 list-group border-history w-auto shadow-sm ">
                    <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                        <img src={refund} className="flex-shrink-0 rotate"  alt="En progreso" width="41" height="40" />
                        <div className="d-flex  w-100 justify-content-between align-items-center">
                            <div>
                                <h6 className="mb-0 text-muted">Orden # {data.order_code}</h6>
                                <h6 className='mt-1'>Total:&nbsp;$ <strong>{data.total.toFixed(2)}</strong ></h6>
                            </div>
                            <small className="text-nowrap text-success">
                                <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                                Máquina:{data.machine_code}
                            </small>
                        </div>
                    </a>
                </div>
            </>
        ));
        return (<>

            {(user.on_atm == 1) ? <>

                <div className="  w-auto bg-white px-3 py-2 rounded">
                    <div className="border-0  d-flex justify-content-center " aria-current="true">
                        {/* <i className="uil uil-money-stack"></i> */}
                        <div className="">
                            <div>
                                <h6 className="mb-0 text-success fw-bold">Caja Abierta: {user.dateBusiness.slice(0, 10)}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </> : <div className="  w-auto bg-white px-3 py-2 rounded">
                <div className="border-0  d-flex justify-content-center " aria-current="true">
                    {/* <i className="uil uil-money-stack"></i> */}
                    <div className="">
                        <div>
                            <h6 className="mb-0 text-secondary fw-bold">Caja cerrada</h6>
                        </div>
                    </div>
                </div>
            </div>
                // <div className=" m-3 list-group border-history w-auto shadow-sm ">
                //     <a href="#" className="border-danger list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                //         <i className="uil uil-money-bill-slash"></i>
                //         <div className="d-flex gap-2 w-100 justify-content-center">
                //             <div>
                //                 <h6 className="mb-0 text-muted">Caja Cerrada</h6>
                //             </div>
                //         </div>
                //     </a>
                // </div>
            }
            {(getdata.length == 0) ? <>
                {/* <div className=" m-3 w-auto border-history">
                    <div className="list-group-item list-group-item-action d-flex py-4 px-4 gap-3 bg-light " aria-current="true">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />

                        <div className="d-flex w-100 justify-content-center align-items-center">
                            <div>
                                <h6 className="mb-0 text-secondary fw-bold">No hay ordenes en curso </h6>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className=" m-3 w-auto ">
                    <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                        <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                            <img src={empty} alt="Vacio" width="35px" height="35px" />
                            <div className='text-center mt-3'>
                                <h5>Vacío</h5>
                                <h6 className="mb-0 text-secondary fw-bold">No hay transacciones en curso </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </> :
                <>
                    {content}
                </>}</>)
    }

    // plantilla de historial de transacciones
    const listordersall = () => {
        const arr = [];
        const getdata = listorders.listorders
        for (let recorrer = 0; recorrer < getdata.length; recorrer++) { arr.push(getdata[recorrer]) }
        const reversed = arr.reverse()

        const content = reversed.map((data) => (
            <>
                <div className=" px-4 py-3 border-bottom" onClick={(event) => notificarDevolucion(data, event)} >
                    <div className=' d-flex gap-3'>
                        <img className="flex-shrink-0  " src={invoice} alt="twbs" width="32" height="32"  />
                        <div className="d-flex gap-2 w-100 justify-content-between">
                            <div>
                                <h6 className="mb-0 text-muted">Orden #{data.code}</h6>
                                <h6 className="mt-2 ms-5"> Total:&nbsp;  $  <strong>{data.total.toFixed(2)}</strong></h6>
                            </div>

                            { //Check if message failed
                                (data.status_id == 2)
                                    ? <><small className=" text-nowrap text-danger">
                                        <i className="uil uil-times"></i>Transacción en proceso
                                    </small></>
                                    : <>{(data.status_id == 3) ? <small className="text-nowrap text-success">
                                        <div> <i className="fa-solid fa-check"></i> Realizada</div>
                                        <small className=" m-2 text-nowrap text-primary">{data.paymenthod}</small>
                                    </small>
                                        : <>{(data.status_id == 5) ? <small className=" text-nowrap text-danger">
                                            <span><i className="fa-solid fa-xmark"></i> Cancelada</span>                                            
                                        </small>
                                            : <>{(data.status_id == 6) ? <small className=" text-nowrap text-secondary ">
                                                <i className="uil uil-arrow-up-right"></i>
                                                Devolución de: #{(data.devolution_order_reference) ? <>{data.devolution_order_reference.code}</> : <></>}
                                            </small>
                                                : <>Null</>}</>}</>}</>
                            }
                        </div>
                    </div>
                    <div className='d-flex gap-2 w-100 justify-content-between'>
                        <small className='ms-5 text-muted '><i className="uil uil-calendar-alt"></i> : {data.createdAt.slice(0, 10)}</small>
                        <small className='text-muted '> <i className="uil uil-clock"></i> : {data.createdAt.slice(11, 16)}</small>
                    </div>
                </div>


            </>
        ));
        return (<>{(getdata.length == 0) ? <>
            <div className=" m-3 w-auto ">
                <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                    <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />
                        <div className='text-center mt-3'>
                            <h5>Vacío</h5>
                            <h6 className="mb-0 text-secondary fw-bold">No hay transacciones registradas</h6>
                        </div>
                    </div>
                </div>
            </div>
        </> : <>{content}</>}</>)
    }

    //----------------
    function actualizarorders() {

        getorders()

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Ordenes actualizadas'
        })
    }

    return (
        <>
            <div className='container-smart-pay payment-main'>
                <RenderIf isTrue={option.option === "Principal"}>
                    <div className="cover-container border-history d-flex w-100 h-100 p-3 mx-auto flex-column bg-dark">
                        <main className="px-3 text-white ">
                            <h1>Apertura de caja de cobro</h1>
                            <form className="login-form needs-validation" onSubmit={submitcaja} encType="multipart/form-data">
                                <div className='d-flex justify-content-center col-12'>
                                    <label className='d-flex justify-content-start my-1 text-white' htmlFor="">Fecha de negocio:</label>
                                    <input
                                        name='dateBusiness'
                                        spellcheck="false"
                                        className="control"
                                        type="date"
                                        step="any"
                                        required
                                    />
                                </div>

                                <div className=' d-flex justify-content-center login-form'>
                                    <button className="control w-50" type="submit">
                                        Abrir caja
                                    </button>
                                </div>
                            </form>
                        </main>
                    </div>
                </RenderIf>

                <RenderIf isTrue={option.option === "Payment"}>
                    <Row className='justify-content-md-center'>

                        {/* User */}
                        <Col sm={12} md={12}>
                            <div className="payment-sp">
                                <div className="d-flex gap-2 w-100 justify-content-center mb-4 pt-2">
                                    <div className=' border px-3 py-2 rounded-pill shadow-sm'>
                                        <p className="mb-0">
                                            <strong>
                                                <i className="uil uil-user-circle"></i>
                                                Cajero: &nbsp;
                                            </strong>
                                            {user.name}
                                        </p>
                                    </div>
                                    {/*  Sing Out*/}
                                    {/* <button className=' btn btn-outline-danger'> 
                                        <i className="uil uil-sign-out-alt"></i> 
                                        Cerrar  sesion
                                    </button>                               */}
                                </div>
                            </div>

                        </Col>
                        {/* Pagos */}
                        <Col sm={12} md={8} lg={4}>
                            <div className=' payment-smart mb-5'>
                                <div className='text-center'>
                                    <h4 className='fs-4 fw-bold'>Área de Pago</h4>
                                </div>
                                <main className=" border-history border p-4 ">
                                    <div className='payment-form'>
                                        {/* Formulario de envio de dinero  */}
                                        <form className="login-form needs-validation" onSubmit={submit} encType="multipart/form-data">
                                            <p className='fs-6 fw-bold'>Ingrese sus datos: </p>
                                            <div className="row">
                                                <div className='col-12 col-lg-6'>
                                                    <label className='d-flex justify-content-start my-1 ' htmlFor="">Subtotal:</label>
                                                    <input
                                                        id='subtotal'
                                                        name='subtotal'
                                                        spellcheck="false"
                                                        className="control"
                                                        type="number"
                                                        step="any"
                                                        placeholder="0.00 "
                                                        onChange={handleChange2}
                                                        required
                                                    />
                                                </div>

                                                <div className='col-12 col-lg-6'>
                                                    <label className='d-flex justify-content-start my-1' htmlFor="">Impuestos:</label>
                                                    <input
                                                        id='itbms'
                                                        name='itbms'
                                                        spellcheck="false"
                                                        className="control"
                                                        type="number"
                                                        step="any"
                                                        placeholder="0.00 "
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>



                                                <div className='col-12 my-3'>
                                                    <label className='d-flex justify-content-center my-1' htmlFor="" >Total:  </label>
                                                    <input
                                                        id='total'
                                                        name='total'
                                                        spellcheck="false"
                                                        className="control rango"
                                                        type="number"
                                                        step="any"
                                                        placeholder="0.00 "
                                                        value={total}
                                                        readonly
                                                        hidden
                                                        required
                                                    />
                                                    <h2 className="m-2 text-center"><strong>$ {total.toFixed(2) == NaN ? <>0.00</> : <>{total.toFixed(2)}</>}</strong></h2>
                                                </div>

                                                <div className='col-12 col-lg-12'>
                                                    <label className='d-flex justify-content-start my-1' htmlFor="">Número de orden: </label>
                                                    <input
                                                        id='order_code'
                                                        name='order_code'
                                                        spellcheck="false"
                                                        className="control"
                                                        type="number"
                                                        step="any"
                                                        placeholder="0000 "
                                                        required
                                                    />
                                                </div>
                                                <div className='col-12 col-lg-12'>
                                                    <label className='d-flex justify-content-start my-2' htmlFor="">Máquina de destino: </label>
                                                    <select className="control" name="machine_code" id="">
                                                        {listmachine()}
                                                    </select>
                                                </div>


                                            </div>
                                            <button className="control mt-2" type="submit">
                                                Enviar
                                            </button>
                                        </form>
                                    </div>
                                </main>
                            </div>
                        </Col>
                        {/* Historial transacciones*/}
                        <Col sm={12} md={6} lg={4}>
                            <div className=' payment-smart mx-3'>
                                <div className='text-center'>
                                    <h4 className='fs-4 fw-bold'>Transacciones en curso</h4>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <div className=' btn btn-light btn-upadate fw-bold' onClick={() => actualizarorders()}>
                                        <i className="uil uil-sync"></i> Actualizar
                                    </div>
                                </div>
                                <div className='transaction-wrapper border border-history bg-light'>
                                    {ordernow()}
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div className='mx-lg-3'>
                                <div className='text-center'>
                                    <h4 className='fs-4 fw-bold'>Historial de Transacciones</h4>
                                </div>
                                <div className='d-flex justify-content-center '>
                                    <button className='btn btn-light btn-close-box border-0 fw-bold' onClick={() => cierreZ()}>
                                        <i className="uil uil-signout"></i> Cerrar Caja
                                    </button>
                                </div>
                                {/* <div className="form-control-feedback">
                                        <i className="uil uil-search mt-3"></i>
                                    </div> */}
                                {/* <div className="form-group us-search">
                                    <input
                                        spellcheck="false"
                                        className="control mb-3 mt-3"
                                        type="text"
                                        step="any"
                                        onChange={handleChangeFiltro}
                                        placeholder="Filtro"
                                    />
                                </div> */}
                                <div className="w-auto bg-light px-3 py-2 border rounded mt-4">
                                    <div className="border-0" >
                                        {/* <i className="uil uil-money-stack"></i> */}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h6 className="mb-0 text-secondary fw-bold">Buscador</h6>
                                            <div className='border-0 fw-bold fs-3'>
                                                <input
                                                    spellcheck="false"
                                                    className="form-control"
                                                    type="text"
                                                    step="any"
                                                    onChange={handleChangeFiltro}
                                                    placeholder="Buscar orden "
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='transaction-wrapper-2 border border-history '>
                                    <div className="">
                                        {listordersall()}
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </RenderIf>
            </div>
        </>
    )


}

export default RequestPayment