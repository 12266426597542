import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class BusinessFetchAPI {

    static async getBusiness(business_code) {
        const res = await server(`/business/${business_code}/business_code`, {
            method: "get"
        });
        return res
    }
}