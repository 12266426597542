import React from 'react'

export default function CardTotals({total_sales, total_discounts, total_itbms}) {
    return (
        <div className="row justify-content-md-center mt-4">
            <div className="col-xl-8 col-lg-12 ">
                <div className="card-stats mb-4 mb-xl-0 shadow-sm border rounded-sp p-3 bg-white">
                    <div className="card-body">
                        <div className="row justify-content-md-center">
                            <div className="col-12 mb-4">
                                <div className='d-flex justify-content-between'>
                                    <h5 className="card-title ">Total de Ventas</h5>
                                    <span className="text-muted " id="analitycs-date-card"><i className="fa-solid fa-calendar-days"></i>  </span>
                                </div>
                                <div className="text-center my-3">
                                    <span className="h1 font-weight-bold" id="">{parseFloat(total_sales).toFixed(2)}<span>$</span></span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className='d-flex justify-content-around'>
                                    <div>
                                        <h6 className="card-title  ">Total Descuentos</h6>
                                        <div className="h3">
                                            <span id="">{parseFloat(total_discounts).toFixed(2)}</span> $
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className="card-title ">Impuestos</h6>
                                        <div className="h3">
                                            <span id="">{parseFloat(total_itbms).toFixed(2)}</span> $
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
