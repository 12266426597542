import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class AuthDgiFetchAPI {

    static async authenticationRuc(token, data) {
        const res = await server(`/dgi/contribuyent`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

  
}