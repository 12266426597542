//Modules 
import io from 'socket.io-client';

const Socket = (query_object) => {
    //We gt the config of the socket 
    let server = process.env.REACT_APP_API_URL.split('/api')
    // Connection with the sockets server
    const socket = io(`${server[0]}`,
        {
            transports: ['websocket', 'polling'],
            query: query_object
        });
    return socket
}

export default Socket;
