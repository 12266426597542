import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class DivicesFechtAPI {

    //Function to get the groups by branchoffice 
    static async getDivices(machine_branch_id, token) {
        const res = await server(`/branch_machine/${machine_branch_id}` , {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

}