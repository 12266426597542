import React from 'react'
import Swal from 'sweetalert2'; 

function AlertSmall({message , icon}) {
    console.log(message);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
    
    return (
        //We show a loading alert 
        Toast.fire({
            icon: 'success',
            title: 'Consulta exitosa'
          })
    )
}

export default AlertSmall