import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import { RenderIf } from '../../components/utils/RenderIf';
import QRCode from "react-qr-code";
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import imgCheck from "./img/check.gif"
import VendingModalVoucher from './VendingModalVoucher';

function VendingModalConfirmation({ clearShoppingCart, orderCode, orderNumber, total, showModalConfirmation, setShowModalConfirmation, intervalNewOrder, voucher_Order, dataInfoPaymentSelect }) {
    const [showModalvoucher, setshowModalvoucher] = useState(false);
    let routeQr = ""
    let branchoffice = ""
    if (localStorage.getItem('branchoffice_vending')) {
        if (localStorage.getItem('branchoffice_vending') != null || localStorage.getItem('branchoffice_vending') != '') {
            branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
            //routeQr = "192.168.1.5:3000/order/" + orderCode + "/branchoffice/" + branchoffice.id
            routeQr = "https://smartpay.ai/order/" + orderCode + "/branchoffice/" + branchoffice.id
        }
    }

    const newOrder = () => {
        let message = "Gracias por su compra."
        let title = "Transaccion exitosa"
        let icon = 'success'

        clearShoppingCart()
        ErrorAlert(message, title, icon)
    }
    //console.log(routeQr);

    return (
        <>
            <Modal
                show={showModalConfirmation}
                size="lg"
                onHide={() => setShowModalConfirmation(true)}
                backdrop="static"
                centered
            >

                <Modal.Body>
                    <div className="m-4 d-flex align-items-center justify-content-center ">
                        <QRCode value={routeQr} size={400} bgColor="#282c34" fgColor="#fff" level="H" />
                    </div>
                    <div className="bg-light">
                        {/* <img className='m-2' src={imgCheck} width="60px"></img> */}
                        <div className='my-4 py-4 border text-center'>
                            <h2 className=' display-1 '>Orden:
                                <span className=' display-2 fw-bold'>
                                    {orderNumber} 
                                </span>
                            </h2>

                        </div>
                        <div className='my-4 py-4 border text-center'>
                            <p className='  display-2 '>Total de la orden:
                                <span className=' fw-bold display-2'>
                                  <br />  ${total.toFixed(2)}
                                </span>
                            </p>
                        </div>
                        <div className='my-4 py-4 border text-center'>
                            <p className=' display-2 '>Metodo de pago: </p>
                            <span className='fw-bold display-2 '>{dataInfoPaymentSelect}</span>

                        </div>

                    </div>

                    <RenderIf isTrue={voucher_Order != "0"}>
                        <hr></hr>
                        <h5 className='text-center display-1'>Recibo de pago:</h5>
                        <div className='mt-2 d-flex alight-item-center justify-content-center'>
                            <VendingModalVoucher showModalvoucher={showModalvoucher} setshowModalvoucher={setshowModalvoucher} voucher_Order={voucher_Order}></VendingModalVoucher>

                        </div>
                        <hr></hr>
                    </RenderIf>

                    <div className="m-3 d-flex align-items-center justify-content-center ">
                        <button className='btn-vending btn-outline-primary btn-lg fw-bold' onClick={() => newOrder()}>Nueva orden</button>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default VendingModalConfirmation