import React from 'react';
//Internal Components
import Navbar from '../NavBar/Navbar';
import BuyForm from './BuyForm';
//Components 
import { Tabs, Tab } from 'react-bootstrap';
import BuysTable from './BuysTable';

const Buys = () => {
    return (
        <>
            {/** Navbar */}
            <Navbar />
            {/** Main container */}
            <div className="container">
                <div className='smart-pay-main'>
                    <div className=' d-flex justify-content-center'>
                        <h2 className='fs-2'>Compras </h2>
                    </div>
                </div>
           
            {/**Tabs */}
            <Tabs defaultActiveKey="all" variant='pills' className='mt-4 nav-scroller d-flex justify-content-center'>
                {/**Tab of general report */}
                <Tab eventKey="all" title={<><i className="fa-solid fa-house"></i> Ingresar compra</>}>
                    <div className='mx-lg-3 mt-4'>
                        <BuyForm />
                    </div>
                </Tab>
                <Tab eventKey="active" title={<><i className="fa-solid fa-money-check-dollar"></i> Compras realizadas</>}>
                    <div className="mx-lg-3 mt-4">
                        <BuysTable />
                    </div>
                </Tab>
            </Tabs>
            </div>

        </>
    );
}

export default Buys;
