
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { SegmentsFechtAPI } from '../../../api/Segments';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';

function ModalAsingAndRemovedSegmentToBranchoffice({ branchoffice_id, load, segmentsBranchoffice }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [segments, setSegments] = useState([])
    const [segmentSelect, setSegmentSelect] = useState("")
    const [searchedSegments, setSearchedSegments] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [segmentsSelectList, setSegmentsSelectList] = useState([])
    const [deleteMode, setDeleteMode] = useState(false)
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getSegmentsByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await SegmentsFechtAPI.getSegmentByBusiness(user.business_id, token)
            console.log(response.data.data);
            setSegments(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const handlerChecked = (e, segment_id) => {
        if (e.target.checked == true) {
            if (segmentsSelectList.length > 29) {
                ErrorAlert('', 'Superaste la cantidad seleccionada', 'warning')
                e.target.checked = false
            } else {
                setSegmentsSelectList([...segmentsSelectList, segment_id]);
            }
        } else {
            let filtrado = segmentsSelectList.filter(id => id != segment_id)
            setSegmentsSelectList(filtrado)
        }
    }

    //Filter the segments by the term searched 
    const filteredSegments = useMemo(() => {
        if (searchTerm === '') {
            return segments
        }

        return searchedSegments.filter(segment => {
            const nameMatch = segment.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = segment.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, segments]);

    const asingSegmentToBranchoffice = async (segment_id) => {
        let data = {
            segment_id: segment_id
        }
        try {
            const response = await SegmentsFechtAPI.asingSegmentToBranchoffice(branchoffice_id, data, token)
            console.log(response);
            ErrorAlert('', 'Acompañante asignado con exito', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error en la asignacion', 'error')
            console.log(err);
        }
    }

    const deleteSegmentByBranchoffice = async (segment_id) => {
        let data = {
            segment_id: segment_id
        }

        try {
            const response = await SegmentsFechtAPI.deleteSegemtOfBranchoffice(branchoffice_id, data, token)
            ErrorAlert('', 'Acompañante eliminado', 'success')
            load()
            setShowModal(false)

        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar el acompañante', 'error')

        }
    }

    const saveArraySegments = () => {
        if (segmentsSelectList.length > 30) {
            ErrorAlert('', 'Solo puedes agregar 30 categorias a la ves', 'warning')
        } else {
            segmentsSelectList.forEach((id, index) => {

                try {
                    if (deleteMode == true) {
                        deleteSegmentByBranchoffice(id)
                    } else {
                        asingSegmentToBranchoffice(id)
                    }

                    if (index == (segmentsSelectList.length - 1)) { setSegmentsSelectList([]); setDeleteMode(false); setSearchTerm('') }
                } catch (err) {
                    ErrorAlert('', 'Una de las categorias ya estaba asignada', 'warning')
                }
            });
        }
    }

    const hadleModeDelete = () => {
        setShowModal(true)
        setDeleteMode(true)
        setSegments(segmentsBranchoffice)
        setSearchedSegments(segmentsBranchoffice)
    }



    useEffect(() => {

    }, [eventHandle])




    return (
        <>


            <div className='d-flex justify-content-center align-item-center'>
                <button className='btn btn-outline-primary btn-sm border-0'
                    onClick={() => (setShowModal(true), getSegmentsByBusiness())}
                >
                    <i className="uil uil-file-plus-alt"></i> Asignar
                </button>

                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => hadleModeDelete()}>
                    <i className="uil uil-trash-alt"></i> Eliminar varios
                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        {deleteMode == true ? <>
                            <i className="uil uil-trash-alt"></i>
                            Eliminar acompañantes de la sucursal</> : <><i className="uil uil-file-plus-alt"></i>Asignar acompañantes a la sucursal</>} </h3>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col sm="12 m-3">
                                <h3 className='text-center m-3'>Seleccione los acompañantes</h3>
                                <p className='text-center '>Maximo 30 acompañantes</p>
                                <div className="d-flex justify-centent-center align-items-center ">
                                    <form className="row g-3 align-content-center align-items-center">
                                        <div className="col-auto ">
                                            <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                        </div>
                                        <div className="col-auto">
                                            <input
                                                spellcheck="false"
                                                className="form-control"
                                                type="text"
                                                step="any"
                                                placeholder="Ingrese el acompañante"
                                                onChange={e => setSearchTerm(e.target.value)}

                                            />
                                        </div>

                                    </form>
                                </div>
                                <div className="m-2 ">
                                    <span className='mb-0 text-secondary fw-bold' >Acompañantes seleccionados: {segmentsSelectList.length}</span>
                                </div>
                            </Col>
                            {filteredSegments.map(segment => (
                                <Col sm="4">
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >

                                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                                <div className="fw-bold">{segment.name}</div>
                                                <p className=''>{segment.description}</p>
                                            </div>
                                            <div>
                                                <InputGroup className="">
                                                    <InputGroup.Checkbox aria-label="Checkbox for following text input" onChange={(e) => handlerChecked(e, segment.segment_id)} />
                                                </InputGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            ))}
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between'>
                        <Button className='m-2' size="sm" onClick={() => (setShowModal(false), setSegmentsSelectList([]), setDeleteMode(false), setSearchTerm(''))}>Cerrar</Button>

                        {deleteMode == true ?
                            <Button className='m-2' size="sm" variant="danger" type="button" onClick={() => saveArraySegments()}>
                                Eliminar
                            </Button> :
                            <Button className='m-2' size="sm" variant="success" type="button" onClick={() => saveArraySegments()}>
                                Guardar
                            </Button>}
                    </div>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalAsingAndRemovedSegmentToBranchoffice