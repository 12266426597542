import React from 'react'
import NavigationBar from './NavigationBar'
import "./pos.css"

function PayExmple() {


  return (
    <>
    </>

  )
}

export default PayExmple