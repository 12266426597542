import React from 'react'

function Index() {
  return (
    <>
      Menu Principal
    </>
  )
}

export default Index