import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import ConfirmationAlert from '../Alerts/ConfirmationAlert';
import { PrinterFetchAPI } from '../../api/Printer';
import { MachinesFetchAPI } from '../../api/Machines';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';


function ModalCreatePreOrder({ checkDeliveryOrder, dv, name, ruc, setDv, setName, setRuc, dataResponse, optionalData, setDataResponse, setOptionalData, shoppingCart, total, subtotal, tax, discount, setPageLoader, dataPreOrder, taxesArrayAll }) {
   const [show, setShow] = useState(false);
   const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
   const [printers, setPrinters] = useState([]);
   const [dataOrder, setDataOrder] = useState({});
   const [tableNumber, setTableNumber]= useState(0);

   let title_modal = "Crear precuenta"
   let machine = JSON.parse(localStorage.getItem('machine'));
   let user = JSON.parse(localStorage.getItem('user'));




   let date = new Date().toJSON().slice(0, 10)

   const getPreOrderByid = async () => {
      let machine_token = localStorage.getItem('x-access-machine-token');
      if (dataPreOrder.pre_Order == true) {
         try {
            const response = await PreOrderFetchAPI.getPreOrderById(dataPreOrder._id, machine_token);
            setDataOrder(response.data.pre_order)
            setTableNumber(response.data.pre_order.table_name);
         } catch (err) {
            console.log(err);
         }
      }
   }


   //function to authentication of ruc
   const authenticationDgi = async () => {
      let message = ""
      let title = ""
      let icon = ""
      if (ruc == "") {
         message = "No se ha ingresado ningun ruc."
         title = "Debes ingresar un ruc."
         icon = "warning"
         ErrorAlert(message, title, icon)
      } else {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let data = { ruc: ruc }
         try {
            const response = await AuthDgiFetchAPI.authenticationRuc(token, data)
            let dataDgi = response.data
            if (dataDgi.dgi_response.gResProc) {
               if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
                  let dvDgi = dataDgi.dgi_response.dDV
                  if (dvDgi.length < 2) { dvDgi = "0" + dvDgi }
                  setName(dataDgi.dgi_response.dNomb)
                  setDv(dvDgi)
                  message = "Datos del clientes obtenidos por la DGI"
                  title = "Datos obtenido correctamente"
                  icon = "success"
                  ErrorAlert(message, title, icon)
                  setDataResponse(true)
               } else {
                  setOptionalData(true)
                  message = "Ingrese los datos manualmente."
                  title = "Datos no encontrados"
                  icon = "warning"
                  ErrorAlert(message, title, icon)
               }
            }
         } catch (err) {
            console.log(err);
            setOptionalData(true)
            message = "Ingrese los datos manualmente."
            title = "Datos no encontrados"
            icon = "warning"
            ErrorAlert(message, title, icon)
         }
      }
   }

   //create preOrder
   const createPreOrder = async () => {

      if (shoppingCart.length > 0) {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'))

         console.log(shoppingCart);
         let json = {
            "client_name": name,
            "localizador": "00",
            "table_name":tableNumber,
            "client_phone": "0",
            "ruc": ruc,
            "dv": dv,
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": shoppingCart,
            "Descuento": discount,
            "Total": total,
            "itbms": tax,
            "subtotal": subtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": checkDeliveryOrder,
            "paymethod": 0,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "taxes": taxesArrayAll,
            "is_pre_order": true, "user": {
               id: user.id,
               name: user.name,
               code: user.user_code
            }
         }

         try {
            const response = await PreOrderFetchAPI.createPreOrder(json, token)

            printOrder(response.data.newTempOrder._id, response.data.newTempOrder.code)
            setShow(false)
            let message = "Precuenta creada con exito."
            let title = "Operacion exitosa."
            let icon = "success"
            ErrorAlert(message, title, icon)
            setPageLoader(1)
         } catch (err) {
            console.log(err);
         }
      } else {
         ErrorAlert("Debes agregar  algo al carrito", "No hay productos en el carrito", "info")
      }
   }

   //function action print order
   let arrayPrintersTemp = []

   const getPrinter = async () => {
      try {
         const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token'), machine.id);

         let arrayPrinters = response.data.printers;
         let printer_Comanda = "";
         let printer_ticket = "";
         let printer_zebra = "";
         for (let i = 0; i < arrayPrinters.length; i++) {
            getCategoriesByprinter(arrayPrinters[i])
            if (arrayPrinters[i].printer_type == 'Tickets') {
               printer_ticket = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Comanda') {
               printer_Comanda = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Zebra') {
               printer_zebra = arrayPrinters[i].printer_name;
            }
         }

         setPrinters(arrayPrintersTemp)
         setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket, printer_zebra: printer_zebra });

      } catch (err) {
         console.log(err);
      }
   }



   const getCategoriesByprinter = async (printer) => {

      try {
         const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
         printer.categories = response.data.categories

      } catch (err) {
         printer.categories = []
         console.log(err);
      }
      arrayPrintersTemp.push(printer)

   }

   //print order
   const printOrder = async (id_Orden_Imprimir, transaction_code) => {
      let JsonImprimir = [{
         "type_ticket": "precuenta",
         "Printers": printers,
         "Nombre_maquina_impresora": infoPrinter.printer_ticket,
         "Nombre_cocina_impresora": infoPrinter.printer_comanda,
         "Nombre_zebra_impresora": infoPrinter.printer_zebra,
         "Id_Orden_Imprimir": id_Orden_Imprimir,
         "token": localStorage.getItem('x-access-machine-token'),
         "transaction_code": transaction_code,
         "amount_item": shoppingCart.length,
         "imprimir_comanda": "si",
      }]

      console.log(JsonImprimir);

      try {
         const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
         const response = await PrinterFetchAPI.printPreOrder(JsonImprimir, response_machine.data.data.windows_ip)
         console.log(response);
      } catch (err) {
         console.log(err);
      }
   }

   // view get order to return
   const cardGetOrder = () => {
      const cardOrder = shoppingCart.map((product) => (
         <>
            <div className="d-flex text-muted pt-3 px-xs-2 border-bottom">
               <img className="bd-placeholder-img flex-shrink-0 me-2 rounded bg-secondary" src={product.img} width="42" height="42" />
               <div className="pb-3 mb-0  lh-sm  w-100">
                  <div className="d-flex justify-content-between ">
                     {/* Name */}
                     <span className="text-dark fw-bold title-product "> {product.name} </span>
                     {/* Cantidad/ Quantity*/}
                     <span className='px-1 number-counter'>
                        <p>{product.amount}</p>
                     </span>
                     <label className="fw-bold" translate='no'>$ {product.price.toFixed(2)} </label>
                  </div>
                  {/** Segment items */}
                  <details open>
                     <summary> <small> Detalles </small> </summary>
                     {product.segments.map(item => (
                        <div>
                           <small className='' >{item.segment_name}: {item.name} - <p translate='no'>${item.price}</p></small>
                        </div>
                     ))}
                  </details>
               </div>
            </div>
         </>
      ));
      return (cardOrder)
   }


   // update pre order
   const updatePreOrder = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      console.log(shoppingCart);
      let json = {
         "client_name": name,
         "localizador": "00",
         "table_name":tableNumber,
         "ruc": ruc,
         "dv": dv,
         "is_discount_notification": false,
         "_id": dataPreOrder._id,
         "order_type": checkDeliveryOrder,
         "groups": shoppingCart,
         "taxes": taxesArrayAll,
         "discount": discount,
         "total": total,
         "tax": tax,
         "subtotal": subtotal,
      }
      try {
         const response = await PreOrderFetchAPI.updateOrderTempPreOrder(json, token)
         console.log(response);
         printOrder(response.data._id, response.data.code)
         setShow(false)
         let message = "Precuenta actualizada con exito."
         let title = "Operacion exitosa"
         let icon = "success"
         setPageLoader(1)
         ErrorAlert(message, title, icon)

      } catch (err) {
         let message = ""
         let title = "Ocurrio un error"
         let icon = "error"
         ErrorAlert(message, title, icon)
      }
   }

   const handlerChangeDataOrder = () => {


      if (dataPreOrder.pre_Order == true) {
         getPreOrderByid()
      }
      ErrorAlert("", "Nombre guardado", "success")
   }

   useEffect(() => {

   }, [dataOrder])

   return (
      <>
         <button className='btn btn-primary  border border-1 rounded-pill'
            onClick={() => (setShow(true), getPrinter(), getPreOrderByid())}>

            <small className='fw-bold buttons-label ' >
               <i className="uil uil-receipt"></i>
               <span className='fw-bold fw-bold mx-1 menu-label subtitle'>Crear Precuenta</span>
            </small>

         </button>

         <Modal
            show={show}
            size="md"
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
         >
            <Modal.Header closeButton>
               <Modal.Title id="example-custom-modal-styling-title">
                  {title_modal}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>


               <div className='row d-flex  justify-content-center alight-item-center'>
                  <div className="col-sm-6 d-flex  justify-content-around">
                     <div>
                        <h6 className=" text-center">Nombre de la cuenta:</h6>
                        <h3 className=" text-center">{dataOrder.client_name != name ? name : dataOrder.client_name} </h3>
                     </div>
                  </div>
                  <div className="col-sm-6 d-flex  justify-content-around">
                     <div>
                        <h6 className=" text-center">Mesa:</h6>
                        <h3 className=" text-center">{tableNumber} </h3>
                     </div>
                  </div>
               </div>

               <div className='d-flex '>

                  <details className=''>
                     <summary className='sumamary-text '>Cambiar datos de la precuenta</summary>

                     {/*<div>{dataResponse == false ?
                        <Form>
                           {optionalData == true ? <>
                              <div className='d-flex align-items-center justify-content-center'>
                                 <p className='text-size-order' > <b>Ruc:</b>  <span>{ruc}</span></p>
                              </div>

                              <div>
                                 <Form.Group className="mb-3 " controlId="formBasicText">
                                    <Form.Label >Ingresa un nombre</Form.Label>
                                    <Form.Control placeholder="Nombre" onChange={(e) => (setName(e.target.value))} />
                                 </Form.Group>
                                 <div className='col-xs-2'>
                                    <Form.Group className="mb-3 " controlId="formBasicText">
                                       <Form.Label >Ingresa un dv</Form.Label>
                                       <Form.Control placeholder="00" onChange={(e) => (setDv(e.target.value))} />
                                    </Form.Group></div>

                                 <div className='d-flex align-items-center justify-content-center'><Button className='m-3' variant="danger" type="button" onClick={() => (setOptionalData(false))}>
                                    Cancelar
                                 </Button>
                                    <Button className='m-3' variant="primary" type="button" onClick={() => (setDataResponse(true))} >
                                       Cargar datos
                                    </Button></div>
                              </div></> :
                              <><div className='d-flex align-items-center justify-content-center'>
                                 <Form.Group className="mb-3 " controlId="formBasicEmail">
                                    <Form.Label >Ingresa un ruc</Form.Label>
                                    <Form.Control placeholder="0-000-000-0" onChange={(e) => (setRuc(e.target.value))} />
                                 </Form.Group>
                                 <Button className='m-3' variant="primary" type="button" onClick={() => (authenticationDgi())}>
                                    Validar
                                 </Button>
                              </div></>}
                        </Form> : <>
                           <p className='text-size-order m-2 text-center' ><span><strong>{name}</strong></span> </p>
                           <div className="order-total m-1">
                              <p className='text-size-order' ><b>Ruc:</b> <span>{ruc}</span> </p><p className='text-size-order' ><b>Dv:</b> <span>{dv}</span></p>
                           </div>
                           <Button variant="primary" type="button" onClick={() => (setDataResponse(false))}>Cambiar datos </Button></>}
                     </div>*/}

                     <div>
                        <Form>

                           <div>

                              <Form.Group className="mb-3 " controlId="formBasicText">
                                 <Form.Label >Ingresa un nombre</Form.Label>
                                 <Form.Control placeholder={name} onChange={(e) => (setName(e.target.value))} />
                              </Form.Group>

                              <Form.Group className="mb-3 " controlId="formBasicText">
                                 <Form.Label >Ingresa el numero de la mesa</Form.Label>
                                 <Form.Control placeholder={tableNumber} onChange={(e) => (setTableNumber(e.target.value))} />
                              </Form.Group>

                           </div>
                        </Form>
                     </div>


                  </details>
               </div>

               <hr />

               <div className='p-3'>
                  <div className='row'>
                     <div className='col-sm-12'>
                        <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                           <div className="d-flex gap-2 w-100 justify-content-around">
                              <div>
                                 <h5 className="mb-0 text-center">Orden actual</h5>
                                 <p className="mb-0 opacity-75 text-center">Fecha - {date} </p>
                              </div>
                           </div>
                        </a>
                     </div>

                  </div>
                  <div className=''>
                     {cardGetOrder()}</div>
                  <div className=''>
                     <div className="order-total mt-2 mx-1">
                        <p className='text-size-order' >Descuento: <span translate='no'>$ <strong>{discount.toFixed(2)}</strong></span> </p>
                        <p className='text-size-order '>Subtotal: <span translate='no'>$ <strong>{subtotal.toFixed(2)}</strong></span></p>
                        <p className='text-size-order' >Impuestos: <span translate='no'>$ <strong>{tax.toFixed(2)}</strong></span> </p>
                        <p className='text-size-order' > <b>Total con impuesto:</b>  <span translate='no'>$ <strong>{total.toFixed(2)}</strong></span></p>
                     </div>
                  </div>

               </div>


            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-around'>
               <Button variant="danger" size="lg" onClick={() => (setShow(false), setPageLoader(1))}>Cancelar</Button>
               {dataPreOrder.pre_Order && dataPreOrder.pre_Order == true ? <>
                  <Button variant="primary" size="lg" onClick={async () => (updatePreOrder())}>Actualizar precuenta</Button>
               </> : <>
                  <Button variant="success" size="lg" onClick={() => (createPreOrder())} >Crear precuenta</Button>
               </>}
            </Modal.Footer>
         </Modal>


      </>
   )
}

export default ModalCreatePreOrder