import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class BusinessFetchAPI {

    //Function to get business by code 
    static async getBusinessByCode(business_code) {
        const res = await server(`/business/${business_code}/business_code`, {
            method: "get"
        });
        return res
    }

    //Function to get business by code 
    static async getBusinessByidConfig(business_id, token) {
        const res = await server(`/business/config/${business_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

       //Function to get the machine by branchoffice 
       static async getBusinessById(business_id, token) {
        const res = await server(`/business/${business_id}`, { 
            method: "get",
            headers: {'x-access-token': token },
        });
        return res
    }

       //Function to get the machine by branchoffice 
       static async getBusinessByActiveConfig(business_id, token) {
        const res = await server(`/business/config/${business_id}`, { 
            method: "get",
            headers: {'x-access-token': token },
        });
        return res
    }

    


}