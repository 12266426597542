import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { RenderIf } from '../../utils/RenderIf';
import { PromotionsFechtAPI } from '../../../api/Promotions';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';

function ModalEditPromotion({ promotion, loadPromotions }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [imgpreview, setImgpreview] = useState(promotion.img)
    const [groups, setGroups] = useState([])
    const [groupsPromotion, setGroupPromotion] = useState({group:""})
    const [taxes, setTaxes] = useState([])
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        const editPromotion = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await PromotionsFechtAPI.updatePromotion(promotion.promotion_id, editPromotion, token);
            //We show the success alert 

            ErrorAlert('', 'Promocion actualizacion con exito', 'success')
            loadPromotions();
            setImgpreview(promotion.img)
            setShowModal(false);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de actualizacion', 'error')
            console.log(err.response.data.error)
        }
    }

    const getGroupsByBusiness = async() =>{
        user = JSON.parse(localStorage.getItem('user'))
        try{
            const response = await GroupsFechtAPI.getGroupsByBusiness(user.business_id, token)
            console.log(response);
            setGroups(response.data.data)
        }catch(err){
            console.log(err);
        }
    }

    const getGroupsById = async() =>{
        user = JSON.parse(localStorage.getItem('user'))

        try{
            const response = await GroupsFechtAPI.getGroupById(promotion.group_id, token)
            console.log(response);
            setGroupPromotion(response.data.data)
        }catch(err){
            console.log(err);
        }
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    useEffect(() => {
    }, [eventHandle])


    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={(e) => (setShowModal(true), getGroupsByBusiness(), getTaxes(), getGroupsById())}
            >
                <i className="uil uil-pen"></i>
            </button>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(promotion.img))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nueva promocion </h3>
                </Modal.Header>
                <Modal.Body>
                    
                        <div className='d-flex align-item-center justify-content-center'>
                            <img
                                className='imgEditModal'
                                src={imgpreview} />
                        </div>

                        <div className='d-flex align-item-center justify-content-center m-3'>
                            <label htmlFor="upload-button">
                                <h5 className="text-center">Seleccione una imagen</h5>
                            </label>
                        </div>

                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >


                        <Form.Group className="mb-3">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={promotion.price}
                                name="price"
                                
                                
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder={promotion.promotion_description}
                                name="description"
                                
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Fecha de expiracion: {promotion.expirationDate}</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Descripcion del producto"
                                name="expirationDate"
                                
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Producto</Form.Label>
                            <Form.Select aria-label="" name="group_id" >
                                <option value={groupsPromotion.group_id}>{groupsPromotion.name}</option>
                                {groups.map(
                                    group => (
                                    <option value={group.group_id}>{group.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

            

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                 
                                hidden/>
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(promotion.img))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditPromotion