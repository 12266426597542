import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { PrinterFetchAPI } from '../../api/Printer';
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';

const ModalAddCategories = ({ printer }) => {
    const [showAddModalCategories, setShowAddModalCategories] = useState(false)
    const [categories, setCategories] = useState([])
    const [selectCategorie, setSelectCategorie] = useState({categorie: ""})
    let branchoffice = localStorage.getItem('branchoffice');
    let business = localStorage.getItem('business')
    let token = localStorage.getItem('x-access-machine-token');

     //We validate if the branch
     if (branchoffice !== null && branchoffice !== undefined && branchoffice !== '') {
        branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    }

    if (business !== null && business !== undefined && business !== '') {
        business = JSON.parse(localStorage.getItem('business'))
    }

    //function get categories by brach office and by status
    const getCategoriesByBusiness = async () => {
        try {
            const response = await CategriesPosFetchAPI.getCategoriesByBusiness(business.id, token);
            console.log(response.data.data);
            setCategories(response.data.data)
        } catch (error) {
           
            console.log(error);
        }
    }

    const viewCategories = () => {
        const listCategories = categories.map((categorie) => (
            <>
                <option value={JSON.stringify(categorie)}>{categorie.category_name}</option>
            </>
        ));

        return (listCategories)
    }

    const handle = (e) => {
        setSelectCategorie(JSON.parse(e.target.value))
    }

    const addCategorie = async() => {
        try{
            let data = {
                "category_id": selectCategorie.category_id,
                "printer_id": printer.printer_id
            }
            const response = await CategriesPosFetchAPI.addCategories(data, token)
            ErrorAlert("Registro de categoria exitoso", "Realizado", "success")
            setShowAddModalCategories(false)
            console.log(response);
        }catch(err){

            if(err.response.data.error == "Impresora y categoria ya asignados"){
                ErrorAlert("", "Categoria ya asignados", "error")
            }else{
                ErrorAlert("", "Error al registrar", "error")
            }
            
            console.log(err);
        }
    }




    return (
        <>
            <Button className='m-2' onClick={() => (getCategoriesByBusiness(), setShowAddModalCategories(true))} ><i className="uil uil-book-medical"></i> Agregar categorias</Button>

            <Modal
                show={showAddModalCategories}
                onHide={() => setShowAddModalCategories(false)}
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-print"></i> {printer.printer_name}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h3 className='text-center'><i className="uil uil-book-medical"></i> Agregar nueva categoria</h3>
                    <div className='container d-flex justify-content-center alight-item-center m-3'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <Form>
                                    <Form.Select size="lg" onChange={(e) => (handle(e))}>
                                        <option>Seleccione una categoria</option>
                                        {viewCategories()}
                                    </Form.Select>
                                  
                                </Form>
                            </div>

                        </div>
                    </div>

                    <div className='d-flex justify-content-between mt-2'>
                        <button className='btn btn-danger border-0 btn-lg' onClick={() => setShowAddModalCategories(false)} > Cancelar </button>
                        <button className='btn btn-success btn-lg' onClick={async() => (addCategorie())} > Guardar  </button>
                    </div>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default ModalAddCategories