import React, { useState, useEffect } from 'react'
import CardPayment from './CardPayment'
import QRCode from "react-qr-code";

import { FilterOrderFetchAPI } from '../../api/Paymentpost/FilterOrder';
import { PaymentMethodFetchAPI } from '../../api/Paymentpost/PaymentMethod';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { PrintOrderLocalFetchAPI } from '../../api/Paymentpost/PrintOrder';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
import { CashTempPosFetchAPI } from '../../api/Paymentpost/OrderTemporalCash';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';
import { YappyFetchAPI } from '../../api/Paymentpost/Yappy'
import ModalPhoneSaveYappy from './ModalPhoneSaveYappy';
import Modal from 'react-bootstrap/Modal'
import ModalConfirmation from './ModalConfirmation';
import ModalPaymentMixto from './ModalPaymentMixto';
import ModalPaymentManual from './ModalPaymentManual';
import ModalYappyQrPos from './ModalYappyQrPos';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import ConfirmationAlert from '../Alerts/ConfirmationAlert';
import { RenderIf } from '../utils/RenderIf';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import Cashierpost from '../cahsier/Cashierpost';
import { MachinesFetchAPI } from '../../api/Machines';
import { PrinterFetchAPI } from '../../api/Printer';
import { MicrosFetchAPI } from '../../api/Micros';
import { EvertechFetchAPI } from '../../api/Evertech';

import ModalDonlee from './ModalDonlee';
//Sweet alert
import Swal from "sweetalert2";

//Img
import empty_img from './img/empty_pays.png';
import checkimg from './img/check.png';
import cancel from './img/cancel.png'
import sadImg from './img/sad.png'
import loading from './img/loading.gif'
//Icons 
import { FaCcVisa, FaPhoneAlt } from 'react-icons/fa';
import Empty from '../utils/Empty';
import SuccessAlert from '../Alerts/SuccessAlert';


const PaymentPost = ({ preOrderInvoice, dv, name, ruc, setDv, setName, setRuc, dataResponse, optionalData, setDataResponse, setOptionalData, shoppingCart, total, subtotal, discount, tax, setPageLoader, dataPreOrder, checkDeliveryOrder, registerTurnOrder, setRegisterTurnOrder, client_phone, setClient_phone, locator, setLocator, dataClient, setDataClient, listPaymentReady, setlistPaymentReady, amountPaymentReady, setAmountPaymenReady, tableInfo, taxesArrayAll }) => {

  const [paymentMethod, setPaymentMethod] = useState([])
  const [show, setShow] = useState(false);
  const [showModalProcess, setShowModalProcess] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(false);
  const [showModalPageYappy, setShowModalPageYappy] = useState(false);
  const [printers, setPrinters] = useState([]);

  //cashier states
  const [modalShow, setModalShow] = useState(false);
  const [modalShowErrorRegisterOrder, setModalShowErrorRegisterOrder] = useState(false)
  const [detailOrder, setDetailOrder] = useState()
  const [amountOrder, setAmountOrder] = useState()

  const [paymentInfo, setPaymentInfo] = useState({ payment: "" })
  const [showModalMixto, setShowModalMixto] = useState(false)
  const [showPaymentManual, setPaymentManual] = useState(false)
  const [datatransaction_code, setDataTransaction_code] = useState("");
  const [order_code, setOrderCode] = useState("");
  const [orderNumber, setOrderNumber] = useState("0")
  const [orderNumberNow, setOrderNumberNow] = useState("0")
  const [orderNumbeTemp, setOrderNumberTemp] = useState({ code: "", temp_id: "" })
  const [responseQr, setResponseQr] = useState(false)
  const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago" })
  const [phoneClientYappy, setPhoneClientYappy] = useState("")
  const [windows_ip, setWindows_ip] = useState("");
  const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
  const [EMVStreamResponse, setEMVStreamResponse] = useState({ EMVStreamResponse: "" })
  const [change, setChange] = useState(0);
  const [tempMethodPayment, setTempMethodPayment] = useState(0)
  const [amountPending, setAmountPending] = useState(0)
  const [lastOrder_id, setLastOrder_id] = useState("");

  //Yappy QR
  const [statePayment, setStatePayment] = useState(false);
  const [modalYappyQr, setModalYappyQr] = useState(false);
  const [dataYappy, setDataYappy] = useState({});
  const [dataHash, setDataHash] = useState("");
  const [statusRequestYappy, setStatusRequestYappy] = useState(false);

  let ventanaYappy = 0;

  //avi-ina
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  let machine = localStorage.getItem('machine');
  let business = localStorage.getItem('business');

  //We validate if the machine is logged in
  if (business !== null && business !== undefined && business !== '') {
    business = JSON.parse(localStorage.getItem('business'))
  }

  //We validate if the machine is logged in
  if (machine !== null && machine !== undefined && machine !== '') {
    machine = JSON.parse(localStorage.getItem('machine'))
  }


  let instervalCash = 0
  console.log(shoppingCart);

  //Functions to show and hide the modal cashier
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true)

  //function to authentication of ruc
  const authenticationDgi = async () => {
    let message = ""
    let title = ""
    let icon = ""
    if (ruc == "") {
      message = "No se ha ingresado ningun ruc."
      title = "Debes ingresar un ruc."
      icon = "warning"
      ErrorAlert(message, title, icon)
    } else {
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let data = { ruc: ruc }
      try {
        const response = await AuthDgiFetchAPI.authenticationRuc(token, data)
        let dataDgi = response.data
        if (dataDgi.dgi_response.gResProc) {
          if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
            let dvDgi = dataDgi.dgi_response.dDV
            if (dvDgi.length < 2) { dvDgi = "0" + dvDgi }
            setName(dataDgi.dgi_response.dNomb)
            setDv(dvDgi)
            message = "Datos del clientes obtenidos por la DGI"
            title = "Datos obtenido correctamente"
            icon = "success"
            ErrorAlert(message, title, icon)
            setDataResponse(true)
          } else {
            setOptionalData(true)
            message = "Ingrese los datos manualmente."
            title = "Datos no encontrados"
            icon = "warning"
            ErrorAlert(message, title, icon)
          }
        }
      } catch (err) {
        console.log(err);
        setOptionalData(true)
        message = "Ingrese los datos manualmente."
        title = "Datos no encontrados"
        icon = "warning"
        ErrorAlert(message, title, icon)
      }
    }
  }

  // get order number in process
  const getOrderNumber = async (branchoffice_id, token) => {
    try {
      const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice_id, token)
      console.log(response);
      setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
      setOrderNumberNow((parseInt(response.data.last_transaction_code) + 1));
    } catch (err) {
      console.log(err);
    }
  }

  //function get payment method by machine
  const getPaymentMethod = async (machine_id, token) => {
    try {
      const response = await PaymentMethodFetchAPI.getPaymentMethod(machine_id, token);
      setPaymentMethod(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  //save info of payment
  const selectPayment = (e) => {
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))


    getOrderNumber(branchoffice.id, token)
    if (e.target.checked == true) {
      let payment = JSON.parse(e.target.value)
      setPaymentInfo(payment)
    }
  }

  //function process payment of the order
  const processPayment = async () => {
    let title = 'Cargando....'
    let message = 'Cargando información....<br/> <br/>'
    let namePayment = paymentInfo.payment
    //-----------------------------------------------------
    setBotonDeshabilitado(true);
    if (namePayment != "") {
      if (shoppingCart.length > 0) {
        if (namePayment == 'Efectivo') {
          title = 'Esperando aprobacion....'
          message = 'El administrador esta aprobando el pago....<br/> <br/>'
          //LoadingAlert(title, message)
          //createOrderCash("Efectivo")


          setDetailOrder({ total: total, code: "0000" })
          handleShowModal()

        } else if (namePayment == 'Credito') {
          title = 'Generando orden a credito....'
          message = 'Espere por favor....<br/> <br/>'
          LoadingAlert(title, message)
          registernewOrder(0)
        } else if (namePayment == 'Yappy') {
          setShowModalPhone(true)
          setChange(0);
        } else if (namePayment == 'Yappy QR') {
          console.log(" Estoy en yappy qr");
          setShowModalProcess(true);
          GenerateYappyTransaction()
        } else if (namePayment == "Mixto") {
          setAmountPending((parseFloat(total).toFixed(2) - parseFloat(amountPaymentReady).toFixed(2)))
          setShowModalMixto(true)
        } else if (namePayment === 'Evertech') {
          console.log('aqui')
          requestEvertechPayment();
          setChange(0);
        } else {
          console.log(paymentInfo)
          if (paymentInfo.integration.id == 1) {
            console.log("Integracion de bac")
            requestPyament();
          } else if (paymentInfo.integration.id == 3) {
            console.log("Integracion de Manual")
            setPaymentManual(true)
          }



          setChange(0);
        }

        setTimeout(() => {
          setTempMethodPayment(1);
        }, 15000);
      } else {
        ErrorAlert("No hay productos en el carrito", "Agrega un producto", "info");
      }

    } else {
      ErrorAlert("No hay metodo de pago.", "Selecciona un metodo de pago", "info");
      setBotonDeshabilitado(false)
    }
  }

  //Function to process the evertech payment 
  const requestEvertechPayment = async () => {
    setShowModalProcess(true);
    try {

      console.log(paymentInfo)
      console.log(total.toFixed(2))
    } catch (err) {

    }
  }
  //decimal reduction
  const decimalReduction = (value) => {

    let string = value.toString()
    let arrayString = string.split(".");
    let newDecimalString = []
    let sample = ''
    if (arrayString[1] != undefined) {
      newDecimalString = arrayString[1].slice(0, 2);
      sample = arrayString[0] + "." + newDecimalString
      console.log("Hice la reduccion");
    } else {
      console.log("Array: " + arrayString);
      console.log("Decimal: " + newDecimalString);
      console.log("Nuevo valor: " + sample);
      sample = string;
    }

    let endValue = parseFloat(sample)
    return endValue
  }

  //genrate yappy transaction
  const GenerateYappyTransaction = async () => {
    if (statePayment == false) {
      setStatePayment(true)
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let machine = JSON.parse(localStorage.getItem('machine'));
      let data = {
        "machine_id": machine.id,
        "total": decimalReduction(total).toString(),
        "subtotal": decimalReduction(total).toString(),
        "tax": "0",
        "discount": "0",
        "transaction_code": orderNumber,
        "description": "Smartpay - orden:" + orderNumber,
        "params": paymentInfo.params
      }
      try {
        const response = await YappyFetchAPI.newYappyQr(data, token)
        setStatePayment(false)
        console.log(response);
        ErrorAlert("", "Todo listo para que realices el pago", "info")
        setDataYappy(response.data);
        setDataHash(response.data.yappy_transaction.body.hash)
        console.log(response.data.yappy_transaction.body.hash);
        setModalYappyQr(true)
        setStatusRequestYappy(true)
        //getYappyTransaction(response.data)

      } catch (err) {
        setStatePayment(false)
        ErrorAlert("", "Lo sentimos yappy no esta disponible", "warning")
        console.log(err);
      }
    } else {
      console.log("peticion en proceso");
    }
  }

  const hadleCancelYappy = (text) => {

    if (text != undefined) {
      setPaymentApproval({ status: 2, description: text });
    } else {
      setPaymentApproval({ status: 2, description: "CANCELADO" });
    }


    setModalYappyQr(false);
    setStatusRequestYappy(false)
    console.log("cancele yappy");

    setTimeout(() => {
      setShowModalProcess(false);
      setPaymentInfo({ payment: "", payment_id: 0 })

      setPaymentApproval({ status: 0, description: "Procesando pago..." })
    }, 3000);
  }

  const getYappyTransaction = async () => {
    console.log(dataYappy);
    let token = localStorage.getItem('x-access-machine-token');

    try {
      let yappy_transaction_id = dataYappy.yappy_transaction.body.transactionId
      console.log(yappy_transaction_id);

      const response = await YappyFetchAPI.getYappyTransaction(yappy_transaction_id, token);
      console.log(response);

      let status = response.data.yappy_transaction.body.status;

      switch (status) {
        case 'PENDING':
          console.log("Transaccion pendiente");
          break;

        case 'COMPLETED':
          console.log("Transaccion aprobada");

          setModalYappyQr(false)
          setStatusRequestYappy(false)
          setPaymentApproval({ status: 1, description: "APROBADO" })
          setShow(false)
          setTimeout(() => {

            registernewOrder(0);
            setShowModalProcess(false);
            setPaymentApproval({ status: 0, description: "Procesando pago..." })
          }, 3000);

          break;

        case 'DECLINED':
          setPaymentInfo({ payment: "" })
          setDataHash("")
          hadleCancelYappy('DECLINED')

          break;

        case 'EXPIRED':
          setPaymentInfo({ payment: "" })
          setDataHash("");
          hadleCancelYappy('EXPIRED')
          break;

        case 'FAILED':
          setPaymentInfo({ payment: "" })
          setDataHash("")
          hadleCancelYappy('FAILED')
          break;

        case 'VOIDED':
          setPaymentInfo({ payment: "" })
          setDataHash("")
          hadleCancelYappy('VOIDED')
          break;

      }

    } catch (err) {
      console.log(err);
      setPaymentInfo({ payment: "" })
      setDataHash("")
      hadleCancelYappy("ERROR DE CONSULTA")
    }

  }
  //---------------================================================================



  //next step phone number yappy
  const nextStepYappy = () => {
    setShowModalPhone(false)
    setShowModalProcess(true);

    //request c# yappy
    requestPyament(orderNumber);
    //createOrderCash("Yappy")
  }


  const getCategoriesByprinter = async (printer) => {

    try {
      const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, localStorage.getItem('x-access-machine-token'))
      printer.categories = response.data.categories

    } catch (err) {
      printer.categories = []
      console.log(err);
    }
    arrayPrintersTemp.push(printer)

  }

  //function action print order
  let arrayPrintersTemp = []
  const getPrinter = async () => {
    try {
      const response = await PrinterFetchAPI.getPrinter(localStorage.getItem('x-access-machine-token'), machine.id);

      let arrayPrinters = response.data.printers;
      let printer_Comanda = "";
      let printer_ticket = "";
      let printer_zebra = "";
      for (let i = 0; i < arrayPrinters.length; i++) {
        getCategoriesByprinter(arrayPrinters[i])
        if (arrayPrinters[i].printer_type == 'Tickets') {
          printer_ticket = arrayPrinters[i].printer_name
        } else if (arrayPrinters[i].printer_type == 'Comanda') {
          printer_Comanda = arrayPrinters[i].printer_name
        } else if (arrayPrinters[i].printer_type == 'Zebra') {
          printer_zebra = arrayPrinters[i].printer_name;
        }
      }

      setPrinters(arrayPrintersTemp)
      setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket, printer_zebra: printer_zebra });

    } catch (err) {
      console.log(err);
    }
  }


  //function of cash status
  const statusCash = (status) => {
    if (status == 1) {//aprobed
      //function register order
      registernewOrder(0)
      handleCloseModal()
    } else if (status == 2) {//cancel
      handleCloseModal()
    }
  }

  //get order
  const getLastOrderMachineNow = async () => {
    console.log("Estoy en consultar orden");
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let business = JSON.parse(localStorage.getItem('business'));

    try {
      console.log(orderNumberNow);

      const response = await FilterOrderFetchAPI.getLastOrderOfMachine(machine.id, token)
      console.log(response);
      let order_last_number = orderNumberNow.toString()

      console.log("Numero de ordenes:");
      console.log(order_last_number);
      console.log(response.data.last_order.transaction_code);
      if (order_last_number.length < 4) {
        order_last_number = "0" + order_last_number
      }

      console.log("Totales:");
      console.log(total);
      console.log(response.data.last_order.total);

      if (order_last_number == response.data.last_order.transaction_code && total == response.data.last_order.total) {
        setModalShowErrorRegisterOrder(false)
        setDataTransaction_code(response.data.last_order.transaction_code)
        console.log(response);
        setShow(false)
        setResponseQr(true);
        let title = "ORDEN: " + response.data.last_order.transaction_code
        let text = "Total de la orden: $" + total.toFixed(2)

        if (dataPreOrder.pre_Order == true) { deleteTempPreOrder(response.data.last_order.transaction_code, response.data.last_order.order) }
        //ConfirmationAlert(title, text, setPageLoader)
        printOrder(response.data.last_order.order_id, response.data.last_order.transaction_code);
        setTempMethodPayment(0);
        setRegisterTurnOrder(1);

        //We show a success message when the order is approved when the type of payment is credit 
        if (paymentInfo.payment_id === 10) {
          SuccessAlert("Orden a credito generada", "Orden generada", "success")
        } else {
          SuccessAlert("Orden registrada con exito", "Orden generada", "success")
        }
      } else {
        registernewOrder(0)
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.error === "No existe la ordern") {
        //registernewOrder(0)
      }
    }
  }

  //function action request pyment local
  const requestPyament = async (yappy_Temp_id) => {
    setShowModalProcess(true);
    let JsonTr = {}

    if (paymentInfo.payment == "Yappy") {
      JsonTr = [{
        Tipo: 'SALE',
        Producto: 'COBRAR',
        Itbms: tax.toFixed(2),
        Descuento: discount.toFixed(2),
        Monto: total.toFixed(2),
        TipoVenta: paymentInfo.payment.toUpperCase(),
        paymentMethod: paymentInfo,
        orderid: yappy_Temp_id,
        tel: "507" + phoneClientYappy,
        total: total.toFixed(2),
        subtotal: subtotal.toFixed(2),
        taxes: tax.toFixed(2),
      }];
    } else {

      let methodPayment = ""
      if (paymentInfo.payment == "MasterCard") {
        methodPayment = "MC"
      } else {
        methodPayment = paymentInfo.payment
      }

      JsonTr = [{
        Tipo: 'SALE',
        Producto: 'COBRAR',
        Itbms: tax.toFixed(2),
        Descuento: discount.toFixed(2),
        Monto: total.toFixed(2),
        TipoVenta: methodPayment.toUpperCase(),
        paymentMethod: paymentInfo,
        orderid: orderNumber,
      }];
    }

    try {

      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
      setWindows_ip(response_machine.data.data.windows_ip);

      const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr, response_machine.data.data.windows_ip);


      setEMVStreamResponse(response.data.data.EMVStreamResponse)
      let responseCode = response.data.data.EMVStreamResponse.responseCode;
      let description = response.data.data.EMVStreamResponse.responseCodeDescription

      if (paymentInfo.payment != "Yappy") {
        if (responseCode == "00") {
          setPaymentApproval({ status: 1, description: description });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: description });
        }
      } else {
        if (responseCode == "20") {
          setPaymentApproval({ status: 1, description: description });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: description });
        }
      }

      setTimeout(() => {
        if (paymentInfo.payment != "Yappy") {
          if (responseCode == "00") {
            registernewOrder(response.data.data.EMVStreamResponse);
          }
        } else {
          if (responseCode == "20") {
            registernewOrder(0);
          }
        }
        setShowModalProcess(false);
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
      }, 3000);


      //origin yappy selection
      {/*console.log(response)
      if (paymentInfo.payment != "Yappy") {
        setEMVStreamResponse(response.data.data.EMVStreamResponse)
        let responseCode = response.data.data.EMVStreamResponse.responseCode;
        let description = response.data.data.EMVStreamResponse.responseCodeDescription

        if (responseCode == "00") {
          setPaymentApproval({ status: 1, description: description });
          setShow(false);
        } else {
          setPaymentApproval({ status: 2, description: description });
        }

        setTimeout(() => {
          if (responseCode == "00") {
            registernewOrder(response.data.data.EMVStreamResponse);
          }
          setShowModalProcess(false);
          setPaymentApproval({ status: 0, description: "Procesando pago..." })
        }, 3000);
      } else {

        if (response.data.data == "") {
          setPaymentApproval({ status: 3, description: "Ocurrio un error." });
          setEMVStreamResponse({ EMVStreamResponse: "" });


        } else {
          ventanaYappy = window.open(response.data.data, "test", "");
        }

      }*/}

      setBotonDeshabilitado(false);
    } catch (err) {
      console.log(err);
    }
  }

  //create order cash temporal
  let type = 0
  const createOrderCash = async (type_method) => {
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let machine = JSON.parse(localStorage.getItem('machine'))
    type = 0
    let json = {
      "client_name": "Estimado cliente",
      "localizador": "",
      "client_phone": "",
      "dgi_qr": "",
      "Orden": shoppingCart,
      "Descuento": discount,
      "Total": total,
      "itbms": tax,
      "taxes": taxesArrayAll,
      "subtotal": subtotal,
      "machine_id": machine.id,
      "branchoffice_id": branchoffice.id,
      "tipoOrden": "comer aqui",
      "paymethod": paymentInfo.id,
      "is_discount_notification": false,
      "type_method": type_method,
      "transaction_code": orderNumber,


    }

    try {
      const response = await CashTempPosFetchAPI.createTempCash(json, token)

      if (type_method == "Yappy") {
        console.log(response.data.newTempOrder._id);
        requestPyament(response.data.newTempOrder.code);
        setOrderNumberTemp({ code: response.data.newTempOrder.code, temp_id: response.data.newTempOrder._id })
      }

      console.log(response);
      getOrdersTempCash();
    } catch (err) {
      console.log(err.response.data.error);
      type = 1
      console.log("Hay una temporal");
      getOrdersTempCash();
      /*let erro_progress = err.response.data.error
      //!Error : Machine has an order in progress
      if (erro_progress === 'La maquina ya tiene una orden en progreso') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'La máquina ya tiene una orden en progreso',
        })
      }*/
      console.log(err);
    }
  }

  //get order temp cash
  let countGetCash = 0 // count to limit get orders
  const getOrdersTempCash = async () => {

    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let machine = JSON.parse(localStorage.getItem('machine'))
    let business = JSON.parse(localStorage.getItem('business'))
    clearInterval(instervalCash)
    try {
      const response = await CashTempPosFetchAPI.getTempCash(machine.code, business.code, branchoffice.code)
      console.log(response);
      let code = response.data.order.code
      let message = ""
      let title = ""
      let icon = ""

      if (type == 0) {
        if (countGetCash == 0) {
          if (paymentInfo.payment != "Yappy") {
            handleShowModal();
          }
          setDetailOrder(response.data.order);
        }

        if (response.data.order.status_id == 1) {
          if (countGetCash <= 100) {
            instervalCash = setInterval(getOrdersTempCash, 5000)
            countGetCash++
          } else {
            message = "Tiempo de espera agotado"
            title = "Advertencia"
            icon = "warning"

            if (paymentInfo.payment == "Yappy") {
              ventanaYappy.close();
              setPaymentApproval({ status: 3, description: "Tiempo de espera agotado." });
            }
            handleCloseModal()
            updateOrderDiscount(code, 5, message, title, icon)
            countGetCash = 0
          }

          //!Fixed Eroor: Correjir mensjae y  poner loading 
        } else if (response.data.order.status_id == 8) {//order temporal is cancel

          title = "Operacion cancelada"
          message = "Orden temporal cancelada"
          icon = "error"

          setBotonDeshabilitado(false);

          if (paymentInfo.payment == "Yappy") {
            ventanaYappy.close();
            setPaymentApproval({ status: 3, description: "Ocurrio un error." });
          }
          updateOrderDiscount(code, 5, message, title, icon)


        } else if (response.data.order.status_id == 7) {//order temporal is confirm
          message = "El pago fue aprobado."
          title = "Pago exitoso"
          icon = "success"

          if (paymentInfo.payment == "Yappy") {
            ventanaYappy.close();
            setPaymentApproval({ status: 1, description: "Pago exitoso." });
          }

          if (paymentInfo.payment == "Efectivo") {
            setShow(false)
          }
          updateOrderDiscount(code, 3, message, title, icon)

          //function register order
          registernewOrder(0)
        }
      } else if (type == 1) {
        console.log("Consulte la temporal remanante");
        updateOrderDiscount(code, 5, message, title, icon)
      }
    } catch (err) {
      console.log(err);
    }
  }

  //cancel temporal transaction yappy
  const cancelTempYappy = () => {
    let message = "Orden temporal cancelada"
    let title = "Operacion cancelada"
    let icon = "error"
    updateOrderDiscount(orderNumbeTemp.code, 5, message, title, icon)
  }

  //cancel method payment in process
  const cancelMethodPayment = () => {
    setTempMethodPayment(0);
    setPaymentApproval({ status: 0, description: "Procesando pago..." })

    //hidden screen modal 
    setShowModalProcess(false);

    //if condition payments
    {/*if (paymentInfo.payment == "Yappy") {
      let message = "Orden temporal cancelada"
      let title = "Operacion cancelada"
      let icon = "error"
      updateOrderDiscount(orderNumbeTemp.code, 5, message, title, icon)
    } else {
      setShowModalProcess(false);
    }*/}
  }

  //function try again yappy
  const try_again_yappy = () => {
    setPaymentApproval({ status: 0, description: "Procesando pago..." })
    requestPyament(orderNumbeTemp.temp_id);
  }

  //update orders temporal discount
  const updateOrderDiscount = async (code, status, message, title, icon) => {
    console.log(code);
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let business = JSON.parse(localStorage.getItem('business'))

    let jsonUpdate = {
      "order_code": code,
      "business_code": business.code,
      "branchoffice_code": branchoffice.code,
      "status": status
    }
    try {
      const response = await CashTempPosFetchAPI.updateOrderTempCash(jsonUpdate)
      console.log(paymentInfo.payment);

      if (type == 0) {
        if (paymentInfo.payment == "Yappy") {
          setTimeout(() => {
            setShowModalProcess(false);
            setPaymentApproval({ status: 0, description: "Procesando pago..." });
          }, 3000);
        }

        if (status == 5) {
          ErrorAlert(message, title, icon)
        }

      } else if (type == 1) {
        console.log("Solicite crear nuevamente la temporal");
        createOrderCash(paymentInfo.payment)
      }
    } catch (err) {
      console.log(err);
    }

  }

  // create transacction in micros
  const createCheckMicrosOrder = async (ip, RvcRef, employeeNum, host_url, orderData, token) => {
    try {
      const response = await MicrosFetchAPI.createCheckMicros(ip, RvcRef, employeeNum, host_url, orderData, token);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  const createCheckMicrosOrderLocal = async (ip, RvcRef, employeeNum, host_url, orderData, token) => {
    let data = {
      Ruta: `http://${ip}:9472/api/micros/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`,
      Type: "POST",
      Header: { "x-access-token": token },
      Body: orderData
    }
    console.log("entre a peticion local micros");

    try {
      const response = await RequestLocalFetchAPI.postMicros(data);
      console.log(response);
    } catch (err) {
      console.log(err);
    }

  }

  //Register order aprobed
  const registernewOrder = async (detailEMVStreamResponse) => {
    let token = localStorage.getItem('x-access-machine-token')
    LoadingAlert("Registrando orden", "Espere un momento por favor...")
    /*setTimeout(() => {
      LoadingAlert("Registrando orden", "Espere un momento por favor...")
    }, 2000);*/


    if (registerTurnOrder == 0) {
      console.log("Entre a registrar la orden");
      setModalShowErrorRegisterOrder(false)
      let user = JSON.parse(localStorage.getItem('user'));
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'));
      let json = {
        "user_id": user.id,
        "EMVStreamResponse": detailEMVStreamResponse,
        "transaction_code": orderNumber,
        "client_name": name,
        "id_Client": 0,
        "ruc": ruc,
        "dv": dv,
        "localizador": locator,
        "client_phone": client_phone,
        "dgi_qr": "0",
        "Orden": shoppingCart,
        "Descuento": discount,
        "Total": total.toFixed(2),
        "itbms": tax.toFixed(2),
        "taxes": taxesArrayAll,
        "subtotal": subtotal.toFixed(2),
        "machine_id": machine.id,
        "branchoffice_id": branchoffice.id,
        "tipoOrden": checkDeliveryOrder,
        "paymethod": paymentInfo.payment_id,
        "paymethods": listPaymentReady,
        "paymenthod_integration_id": paymentInfo.integration.id,
        "table_name": tableInfo.table_name,
        "table_id": tableInfo.table_id,
        "table_code": tableInfo.table_code,
      }
      try {

        const response = await OrderNumberFetchAPI.registerOrder(json, token)
        setDataTransaction_code(response.data.data.transaction_code)
        setOrderCode(response.data.data.order_code)
        console.log(response);
        setShow(false)
        setResponseQr(true);
        let title = "ORDEN: " + response.data.data.transaction_code
        let text = "Total de la orden: $" + total.toFixed(2)

        if (dataPreOrder.pre_Order == true) { deleteTempPreOrder(response.data.data.transaction_code, response.data.data.order_code) }
        //ConfirmationAlert(title, text, setPageLoader)
        printOrder(response.data.data.order_id, response.data.data.transaction_code);
        setTempMethodPayment(0);
        setRegisterTurnOrder(1);

        //type integration micros
        if (response.data.data.micros_params != null) {
          //params micros
          let micros_params = response.data.data.micros_params;
          let url = micros_params.host_url
          let stringUrl = url.split('/');
          let ip = stringUrl[2].split(':');

          //createCheckMicrosOrder(ip[0], micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token);
          createCheckMicrosOrderLocal(ip[0], micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token);
        } else {
          console.log("no hay parametros de micros");
        }

        //We show a success message when the order is approved when the type of payment is credit 
        if (paymentInfo.payment_id === 10) {
          SuccessAlert("Orden a credito generada", "Orden generada", "success")
        } else {
          SuccessAlert("Orden registrada con exito", "Orden generada", "success")
        }
      } catch (err) {
        //valid to id of transaction 
        try {
          let token = localStorage.getItem('x-access-machine-token');
          const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);
          console.log(response.data.last_order._id)


          if (response.data.last_order._id == lastOrder_id) {
            console.log("La transaccion no habia sido regitrada")
            console.log(err);
            registerTurnOrder = 0;
            setRegisterTurnOrder(registerTurnOrder)
            ErrorAlert("", "Ocurrio un error", "error")
            setModalShowErrorRegisterOrder(true)
          } else {
            console.log("La transaccion ya habia sido regitrada")
            setDataTransaction_code(response.data.last_order.transaction_code)
            setOrderCode(response.data.last_order.order_code)
            console.log(response);
            setShow(false)
            setResponseQr(true);
            let title = "ORDEN: " + response.data.last_order.transaction_code
            let text = "Total de la orden: $" + total.toFixed(2)

            if (dataPreOrder.pre_Order == true) { deleteTempPreOrder(response.data.last_order.transaction_code, response.data.last_order.order_code) }
            //ConfirmationAlert(title, text, setPageLoader)
            printOrder(response.data.last_order.order_id, response.data.last_order.transaction_code);
            setTempMethodPayment(0);
            setRegisterTurnOrder(1);

            //We show a success message when the order is approved when the type of payment is credit 
            if (paymentInfo.payment_id === 10) {
              SuccessAlert("Orden a credito generada", "Orden generada", "success")
            } else {
              SuccessAlert("Orden registrada con exito", "Orden generada", "success")
            }
          }

        } catch (err) {
          console.log(err)
        }

        /*
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })
  
        swalWithBootstrapButtons.fire({
          title: 'Lo sentimos ocurrio un error',
          text: "La orden no se registro correctamente.",
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: 'Intentar nuevamente',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            registernewOrder(0);
          } else if (
            
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              'La transaccion no fue salvada',
              'No podras recueperar la transaccion',
              'error'
            )
          }
        })*/
      }
    } else {
      console.log("Hay una orden en proceso");
    }
  }

  //print order
  const printOrder = async (id_Orden_Imprimir, transaction_code) => {
    let type_ticket = ""
    let imprimir_comanda = ""
    if (preOrderInvoice == true) {
      //type_ticket = "factura_precuenta"
      type_ticket = "factura"
      imprimir_comanda = "no"
    } else {
      type_ticket = "factura"
      imprimir_comanda = "si"
    }

    let arrayPrinters = []
    if (dataPreOrder.pre_Order == true) {
      printers.forEach((printer) => {
        if (printer.printer_type == "Tickets") {
          arrayPrinters.push(printer)
        }
      });

    } else {
      printers.forEach((printer) => {
        arrayPrinters.push(printer)
      });
    }

    let JsonImprimir = [{
      "type_ticket": type_ticket,
      "Printers": arrayPrinters,
      "Nombre_maquina_impresora": infoPrinter.printer_ticket,
      "Nombre_cocina_impresora": infoPrinter.printer_comanda,
      "Nombre_zebra_impresora": infoPrinter.printer_zebra,
      "Id_Orden_Imprimir": id_Orden_Imprimir,
      "token": localStorage.getItem('x-access-machine-token'),
      "transaction_code": transaction_code,
      "amount_item": shoppingCart.length,
      "imprimir_comanda": imprimir_comanda,
    }]
    console.log(JsonImprimir);
    try {
      const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
      const response = await PrinterFetchAPI.printOrder(JsonImprimir, response_machine.data.data.windows_ip)
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }



  //delete pre order
  const deleteTempPreOrder = async (transaction_code, orden_code) => {

    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
    let business = JSON.parse(localStorage.getItem('business'));
    let user = JSON.parse(localStorage.getItem('user'));

    let jsonUpdate = {
      "order_code": dataPreOrder.code,
      "business_code": business.code,
      "branchoffice_code": branchoffice.code,
      "deleted": false,
      "status": 3,
      "user_id": user.id,
      "finished_order_transaction_code": transaction_code,
      "finished_order_code": orden_code
    }
    try {

      //We save the preorder
      await DeletedTempOrdersFetchAPI.newDeletedTempOrder(jsonUpdate, token);

      const response = await PreOrderFetchAPI.deletePreOrder(jsonUpdate, token)
      console.log(response);
    } catch (err) {
      console.log(err);
    }

  }

  const lastOrderMachineRequest = async () => {
    let token = localStorage.getItem('x-access-machine-token');
    try {
      const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);
      console.log(response.data.last_order._id)
      setLastOrder_id(response.data.last_order._id)

    } catch (err) {
      console.log(err)
    }
  }

  // const listmethod = () => {
  //   console.log(paymentMethod);
  //   const listMethods = paymentMethod.map((info) => (
  //     <>
  //       {/* <div className=' col-md-4 m-2'></div> */}
  //     </>
  //   ));
  //   return (listMethods)
  // }

  const functionGetRequest = async (turn) => {
    //Variables 
    let token = localStorage.getItem('x-access-machine-token');
    let branchoffice = localStorage.getItem('branchoffice') !== '' ? JSON.parse(localStorage.getItem('branchoffice')) : ''
    let machine = localStorage.getItem('machine') !== '' ? JSON.parse(localStorage.getItem('machine')) : ''
    try {
      //We check the data on the local storage
      if (machine !== '' && machine !== null && machine !== undefined) {
        getPaymentMethod(machine.id, token)
        if (turn == 1) {
          getOrderNumber(branchoffice.id, token)
        }

      }

      //------------------------------------------------

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    (async () => {
      functionGetRequest(1)
    })();
  }, [])

  useEffect(() => {
    let timer = 0
    if (statusRequestYappy == true) {
      console.log("enter en el intervalo");
      timer = setInterval(async () => getYappyTransaction(), 5000); //INTERVAL GET YAPPY STATUS TRANSACTION

      return () => clearInterval(timer)
    } else {
      return () => clearInterval(timer) //CLEAR INTERVAL
    }

  }, [statusRequestYappy, dataYappy]);

  const [fullscreen, setFullscreen] = useState(true);
  return (
    <>

      <button
        className='btn btn-success px-5 btn-lg w-100'
        onClick={() => (setShow(true), getPrinter(), setBotonDeshabilitado(false), setPaymentInfo({ payment: "" }), lastOrderMachineRequest(), functionGetRequest())}>
        <strong >Pagar: </strong><strong translate='no'>${total.toFixed(2)}</strong>
      </button>

      <Modal
        show={show}
        fullscreen={fullscreen}
        onHide={() => setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <h3 className='text-center my-4 fw-bold '>
          Pagos
        </h3>

        <Modal.Body>
          <div className='row '>
            <div className='col-md-6 '>
              <h5 className='text-primary fw-bold'>Seleccione un método de pago</h5>
              <div className='row'>
                {/* Validation Payments Methods */}
                {
                  !paymentMethod.length
                    ? <Empty title="Vacío" msg="No hay métodos de pago asignados" img={empty_img} />
                    : paymentMethod.map(option => (

                      // <div>
                      //   <input type="radio" className="btn-check" name="options-outlined" id={option.id+"payment"} autocomplete="off" value={JSON.stringify(option)} onChange={(e) => (selectPayment(e))} />
                      //   <label className="btn btn-outline-success" htmlFor={option.id+"payment"} >{option.payment}</label>
                      // </div>

                      <div className="col-4 col-md-4 col-lg-3 mt-3" key={option.id + option.integration.id}>
                        <small className='fw-bold mx-3 '>
                          {option.integration.name}
                        </small>
                        <input type="radio" className="btn-check"
                          name="option_payments" id={option.id + "payment" + option.id + option.integration.id} value={JSON.stringify(option)} onChange={(e) => (selectPayment(e))}
                        />
                        <label className="btn btn-outline-primary" htmlFor={option.id + "payment" + option.id + option.integration.id}>
                          <img src={option.img} className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" />
                          <small className='fw-bold mx-4 '>
                            {option.payment}
                          </small>
                        </label>
                      </div>
                    ))
                }
                {/* 

                {/* {listmethod()} */}

              </div>

              <RenderIf isTrue={business.id == 13}>
                <ModalDonlee client_phone={client_phone} setClient_phone={setClient_phone} locator={locator} setLocator={setLocator} dataClient={dataClient} setDataClient={setDataClient}></ModalDonlee>
              </RenderIf>

            </div>
            <div className='col-md-6 border rounded shadow-sm'>
              <div>
                <div className="order-total mt-2 mx-1 mb-auto">
                  <p className='text-size-order '>Subtotal: <span>$ <strong>{subtotal.toFixed(2)}</strong></span></p>
                  <p className='text-size-order' >Descuento: <span>$ <strong>{discount.toFixed(2)}</strong></span> </p>
                  <p className='text-size-order' >Impuestos: <span>$ <strong>{tax.toFixed(2)}</strong></span> </p>
                  <p className='text-size-order' > <b>Total con impuesto:</b>  <span>$ <strong>{total.toFixed(2)}</strong></span></p>
                </div>
                <div>
                  <hr />
                  <div className='d-flex '>
                    <details open>
                      <summary className='sumamary-text'>Personalizar factura</summary>
                      <div>{dataResponse == false ?
                        <Form>
                          { /*if the client's ruc is not authenticated, it shows the form of name and dv*/}
                          {optionalData == true ? <>
                            <div className='d-flex align-items-center justify-content-center'>
                              <p className='text-size-order' > <b>Ruc:</b>  <span>{ruc}</span></p>
                            </div>

                            <div>
                              <Form.Group className="mb-3 " controlId="formBasicText">
                                <Form.Label >Ingresa un nombre</Form.Label>
                                <Form.Control placeholder="Nombre" onChange={(e) => (setName(e.target.value))} />
                              </Form.Group>
                              <div className='col-xs-2'>
                                <Form.Group className="mb-3 " controlId="formBasicText">
                                  <Form.Label >Ingresa un dv</Form.Label>
                                  <Form.Control placeholder="00" onChange={(e) => (setDv(e.target.value))} />
                                </Form.Group></div>

                              <div className='d-flex align-items-center justify-content-center'><Button className='m-3' variant="danger" type="button" onClick={() => (setOptionalData(false))}>
                                Cancelar
                              </Button>
                                <Button className='m-3' variant="primary" type="button" onClick={() => (setDataResponse(true))} >
                                  Cargar datos
                                </Button></div>
                            </div></> :
                            <><div className='d-flex align-items-center justify-content-center'>
                              <Form.Group className="mb-3 " controlId="formBasicEmail">
                                <Form.Label >Ingresa un ruc</Form.Label>
                                <Form.Control placeholder="0-000-000-0" onChange={(e) => (setRuc(e.target.value))} />
                              </Form.Group>
                              <Button className='m-3' variant="primary" type="button" onClick={() => (authenticationDgi())}>
                                Validar
                              </Button>
                            </div></>}
                        </Form> : <>
                          <p className='text-size-order m-2 text-center' ><span><strong>{name}</strong></span> </p>
                          <div className="order-total m-1">
                            <p className='text-size-order' ><b>Ruc:</b> <span>{ruc}</span> </p><p className='text-size-order' ><b>Dv:</b> <span>{dv}</span></p>
                          </div>
                          <Button variant="primary" type="button" onClick={() => (setDataResponse(false))}>Cambiar datos </Button></>}
                      </div>
                    </details>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mt-3'>
              <button className='btn btn-danger border-0 btn-lg m-2' onClick={() => (setShow(false))}>Cancelar </button>
              <button className='btn btn-success btn-lg m-2' onClick={() => (processPayment())}>Realizar Pago </button>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary">
            Cerrar
          </Button>
        </Modal.Footer> */}
        {/* <Modal.Footer>
          <div className='d-flex  justify-content-between'>
            <button className='button-payment btn btn-danger btn-lg'
              onClick={() => setShow(false)} >
              Volver
            </button>
            <button className='button-payment btn btn-success btn-lg'
              disabled={botonDeshabilitado}
              onClick={() => (processPayment())}>
              Realizar Pago
            </button>
          </div>
        </Modal.Footer> */}

      </Modal>
      <div > {responseQr == true ? <ModalConfirmation setResponseQr={setResponseQr} orderCode={order_code} setPageLoader={setPageLoader} orderNumber={datatransaction_code} total={total} change={change} setChange={setChange} /> : ""}</div>


      {/* Modal */}
      <Modal
        fullscreen
        show={modalShow}
        onHide={handleCloseModal}
      >
        <h3 className="text-center  fw-bold ">Pagos</h3>

        <Modal.Body className='bg-light'>

          <div className='container'>
            <div className='text-primary h4 bg-primary p-2 bg-opacity-10 shadow-sm rounded'>
              <div className='d-flex justify-content-between'>
                <div className='px-1 mx-5 display-5' >
                  Orden:  <span className='fw-bold'> {!datatransaction_code ? "0000" : datatransaction_code}</span></div>
                <div className='px-1 mx-5 display-5' >
                  Monto a pagar: <span className='fw-bold'> ${amountOrder} </span>
                </div>
              </div>
            </div>
          </div>

          <Cashierpost setChange={setChange} setAmountOrder={setAmountOrder} setOrderNumber={setOrderNumber} setModalShow={setModalShow} order_data={detailOrder} statusCash={statusCash} />
        </Modal.Body>
      </Modal>


      {/*Process payment Loading Modal */}
      <Modal
        fullscreen
        show={showModalProcess}
      >

        <Modal.Header className='border-0'>
          <strong> PAGOS </strong>
        </Modal.Header>
        <Modal.Body className='bg-pay-met'>
          <main className='d-flex justify-content-center align-items-center h-100 '>

            <section className='border rounded p-4 w-50 position-relative bg-white'>
              <div className='sections-messages'>
                <RenderIf isTrue={paymentApproval.status == 0}>
                  <div className='px-4 pb-1 mt-2 text-center'>
                    <img className='mx-auto mb-4 ' src={loading} width='100px' height='100px' />
                    <h2 className="display-6 fw-bold">
                      {paymentApproval.description}
                    </h2>
                    {tempMethodPayment == 1 ? <Button onClick={() => (cancelMethodPayment())}>Cancelar</Button> : ""}
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                    Método de Pago: {paymentInfo.payment}
                  </span>
                </RenderIf>
                <RenderIf isTrue={paymentApproval.status == 1}>
                  <div className='px-4 pb-1 mt-5 text-center'>
                    <img className='mx-auto mb-4 ' src={checkimg} width='100px' height='100px' />
                    <h2 className="display-6 fw-bold">
                      ¡Éxito!
                    </h2>
                    <p> Su pago ha sido procesado correctamente.</p>
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                    Método de Pago: {paymentInfo.payment}
                  </span>
                </RenderIf>
                <RenderIf isTrue={paymentApproval.status == 2}>
                  <div className='px-4 pb-1 mt-5 text-center'>
                    <img className='mx-auto mb-4 ' src={cancel} width='100px' height='100px' />
                    <h2 className="fw-bold">
                      {paymentApproval.description}
                    </h2>
                    <p> Por favor, verifique la información e intente nuevamente.</p>
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                    Método de Pago: {paymentInfo.payment}
                  </span>

                </RenderIf>

                <RenderIf isTrue={paymentApproval.status == 3}>
                  <div className='px-4 pb-1 mt-5 text-center'>
                    <img className='mx-auto mb-4 ' src={cancel} width='100px' height='100px' />
                    <h2 className="fw-bold">
                      {paymentApproval.description}
                    </h2>
                    <p>Por favor, verifique la información e intente nuevamente.</p>
                  </div>
                  <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                    Método de Pago: {paymentInfo.payment}
                  </span>

                  <div className='d-flex justify-content-center alight-item-center m-2'>
                    <Button className='m-2' onClick={() => (cancelTempYappy())}>Volver</Button>
                    <Button className='m-2 ' onClick={() => (try_again_yappy())}> Intentar de nuevo</Button>
                  </div>

                </RenderIf>
              </div>

              {/* <div className="px-4 pb-1 mt-5 text-center">
                <img className="mx-auto mb-4 bg-secondary rounded " src={paymentInfo.img} alt="" width="72" height="57" />
                <h2 className="display-6 fw-bold">{paymentApproval.description}</h2>
              </div> */}
            </section>

          </main>



          <div className="">
            {/* <div className='d-flex justify-content-center alight-item-center m-2'>
              <img className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" src={paymentInfo.img}></img>
              <div className=' m-3'>
                <h3 className='text-center'>{paymentApproval.description}</h3>
              </div>
            </div>  */}
          </div>

        </Modal.Body>
      </Modal>


      {/*input phone client */}
      <ModalPhoneSaveYappy showModalPhone={showModalPhone} setShowModalPhone={setShowModalPhone} setPhoneClientYappy={setPhoneClientYappy} nextStepYappy={nextStepYappy}></ModalPhoneSaveYappy>

      {/*Modal payment manual*/}
      <ModalPaymentManual showPaymentManual={showPaymentManual} setPaymentManual={setPaymentManual} paymentInfo={paymentInfo} registernewOrder={registernewOrder} statusActivity={true}></ModalPaymentManual>


      {/*<Modal
        show={showModalPhone}
        fullscreen
        onHide={() => setShowModalPhone(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >

        <Modal.Header className='border-0'>
          <strong> YAPPY </strong>
        </Modal.Header>
        <Modal.Body className='bg-pay-met'>
          <main className='d-flex justify-content-center align-items-center h-100 '>

            <section className='border rounded p-4 w-50 position-relative bg-white'>
              <div className='sections-messages'>

                <div className='mt-2 text-center p-1'>
                  <h4 className='fw-bold'> <FaPhoneAlt /> Ingrese numero de telefono </h4>
                </div>
                <div className="input-group input-group-lg">
                  <input type="text" className="form-control" placeholder='6000-0000' onChange={(e) => (setPhoneClientYappy(e.target.value))} />
                </div>

                <div className='d-flex justify-content-between mt-3'>
                  <button className='btn btn-danger btn-lg' onClick={() => setShowModalPhone(false)}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={() => nextStepYappy()}> Aplicar  </button>
                </div>
              </div>
            </section>
          </main>



        </Modal.Body>
      </Modal>*/}


      {/*input phone client 
      <Modal
        show={showModalPageYappy}
        fullscreen
        onHide={() => setShowModalPageYappy(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >

        <Modal.Header className='border-0'>
          <strong> YAPPY </strong>
        </Modal.Header>
        <Modal.Body className='bg-pay-met'>

          <iframe src="https://watch?v=www.google.com"></iframe>

        </Modal.Body>
      </Modal>*/}


      <Modal
        size='lg'
        show={modalShowErrorRegisterOrder}
        onHide={() => setModalShowErrorRegisterOrder(false)}
        backdrop="static"
        centered>
        <Modal.Body>
          <h1 className='text-center m-3'>Lo sentimos ocurrio un error</h1>
          <h5 className='text-center'>La orden no pudo ser registrada</h5>
          <div className='d-flex alight-item-center justify-content-center'>
            <button className='m-3 btn btn-danger btn-lg' onClick={() => setModalShowErrorRegisterOrder(false)}> Cancelar </button>
            <button className=' m-3 btn btn-success btn-lg' onClick={() => getLastOrderMachineNow()}> Intentar nuevamente</button>
          </div>
        </Modal.Body>

      </Modal>

      <ModalYappyQrPos modalYappyQr={modalYappyQr} setModalYappyQr={setModalYappyQr} dataYappy={dataYappy} setDataHash={setDataHash} dataHash={dataHash} hadleCancelYappy={hadleCancelYappy} total={total} orderNumber={orderNumber}></ModalYappyQrPos>
      <ModalPaymentMixto showModalMixto={showModalMixto} setShowModalMixto={setShowModalMixto} paymentMethod={paymentMethod} total={total} subtotal={subtotal} discount={discount} tax={tax} orderNumber={orderNumber} registernewOrder={registernewOrder} listPaymentReady={listPaymentReady} setlistPaymentReady={setlistPaymentReady} amountPaymentReady={amountPaymentReady} setAmountPaymenReady={setAmountPaymenReady} shoppingCart={shoppingCart} amountPending={amountPending} setAmountPending={setAmountPending} aprovedManualPaymentacardMixto={0} ></ModalPaymentMixto>
    </>
  )
}

export default PaymentPost



