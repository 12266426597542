import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import { CouponPosFetchAPI } from '../../api/Paymentpost/Coupon';
import { DiscountPosFetchAPI } from '../../api/Paymentpost/Discount';
import ErrorAlert from '../Alerts/ErrorAlert';
import { FaTicketAlt } from 'react-icons/fa';

function ModalCoupons({ actvPlus,actvLite, shoppingCart, total, subtotal, itbms, discount, setLoad, setShoppingCart, taxesArrayAll }) {
   let title_modal = "Cupones "
   const [show, setShow] = useState(false);
   const [valueInput, setValueInput] = useState("");
   const [orderCuponTemp, setOrderCuponTemp] = useState({})

   //
   const auhtCupon = async (code) => {
      console.log(code);
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         const response = await CouponPosFetchAPI.getCupon(code, token)

         let branchofficeArray = response.data.data.branchoffice;
         var data_filter = branchofficeArray.filter(element => element.code == branchoffice.code)
         console.log(data_filter);
         if (data_filter.length > 0) {
            console.log("El cupon si es valido");
            createTempDiscountCupon(code)
         } else {
            let icon = "warning"
            let title = "Cupon denegado."
            let message = "El cupon no es valido en esta sucursal"
            ErrorAlert(message, title, icon)
         }

      } catch (err) {
         ErrorAlert('', 'Error no se puedo aplicar el cupon', 'error')
         console.log(err);

      }
   }

   //create orders temporal of discount type cupon
   const createTempDiscountCupon = async (code) => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let token = localStorage.getItem('x-access-machine-token');
      let jsonDiscountTemp = {
         "client_name": "0",
         "cupon_code": code,
         "is_cupon": true,
         "localizador": "0",
         "client_phone": "0",
         "dgi_qr": "0",
         "Orden": shoppingCart,
         "Descuento": "0",
         "Total": total,
         "itbms": itbms,
         "taxes": taxesArrayAll,
         "subtotal": subtotal,
         "machine_id": machine.id,
         "branchoffice_id": branchoffice.id,
         "tipoOrden": "comer aqui",
         "paymethod": 0,
         "is_discount_notification": true
      }
      try {
         const response = await DiscountPosFetchAPI.createTempDiscount(jsonDiscountTemp, token )
         if (response.data.message == 'Tempral order created') {
            getOrdersTempDiscount()
         }
      } catch (err) {
         ErrorAlert('', 'Error no se puedo aplicar el cupon', 'error')
         console.log(err);
      }
   }

   // get order temporal discount
   const getOrdersTempDiscount = async () => {
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let machine = JSON.parse(localStorage.getItem('machine'))
      let business = JSON.parse(localStorage.getItem('business'))

      let token = localStorage.getItem('x-access-machine-token');
      try {
         const response = await DiscountPosFetchAPI.getTempDiscount(machine.code, business.code, branchoffice.code, token)

         let code = response.data.order.code
         let message = "Descuento aplicado con exito."
         let title = "Operacion exitosa"
         let icon = "success"

         let tempOrderDiscount = response.data.order.groups

         setOrderCuponTemp(response.data.order);
         console.log(response.data.order);
         //format json 
         for (let i = 0; i < tempOrderDiscount.length; i++) {
            for (let s = 0; s < tempOrderDiscount[i].segments.length; s++) {
               tempOrderDiscount[i].segments[s].item_id = tempOrderDiscount[i].segments[s].item.item_id
               tempOrderDiscount[i].segments[s].name = tempOrderDiscount[i].segments[s].item.name
               tempOrderDiscount[i].segments[s].cantidadItem = tempOrderDiscount[i].segments[s].item.amount
               tempOrderDiscount[i].segments[s].description = tempOrderDiscount[i].segments[s].item.description
               tempOrderDiscount[i].segments[s].img = tempOrderDiscount[i].segments[s].item.img
            }
            tempOrderDiscount[i].DetalleSegmentos = tempOrderDiscount[i].segments
            tempOrderDiscount[i].cantidadItem = tempOrderDiscount[i].amount
            tempOrderDiscount[i].descuento = tempOrderDiscount[i].discount
         }

         setShoppingCart(tempOrderDiscount)
         setLoad(1)
         updateOrderDiscount(code, 3, message, title, icon)
         setShow(false)
      } catch (err) {
         console.log(err);
      }
   }

   //update orders temporal discount
   const updateOrderDiscount = async (code, status, message, title, icon) => {
      console.log(code);
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      let business = JSON.parse(localStorage.getItem('business'))
      let token = localStorage.getItem('x-access-machine-token');
      let jsonUpdate = {
         "order_code": code,
         "business_code": business.code,
         "branchoffice_code": branchoffice.code,
         "status": status
      }
      try {
         const response = await DiscountPosFetchAPI.updateOrderTemp(jsonUpdate, token)
         console.log(response);
         ErrorAlert(message, title, icon)
      } catch (err) {
         console.log(err);
      }

   }




   //function to aply cupon
   const aplyCupon = async () => {

      let message = ""
      let title = ""
      let icon = ""
      if (discount > 0) {
         message = "No se pueden aplicar dos descuentos"
         title = "Ya existe un descuento aplicado."
         icon = "warning"
         ErrorAlert(message, title, icon)

      } else {


         let valueUpperCase = valueInput.toUpperCase();
         let arrayInput = valueUpperCase.split("X");
         let codeCupon = arrayInput[1]
         auhtCupon(codeCupon)
      }
   }

   return (
      <>
 
         {actvPlus ? 
         <button className='btn btn-outline-primary  border  rounded-pill me-1 shadow-sm bg-seconadary p-2 bg-opacity-10' 
         onClick={() => setShow(true)}>

            <i className="uil uil-ticket icon-mobile" ></i>
            <small className='fw-bold label-responsive  hidden-label-sm'>
               {title_modal}
            </small>
         </button> : <></>}


         {actvLite ? <div className=" m-1 bg-white border rounded ">
            <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={() => setShow(true)}>
               <i className="uil uil-ticket  buttons-label icon-mobile h3"></i>
               <span className='h5 fw-bold mx-1 d-block text-center'>   {title_modal} </span>
            </button>
         </div> : <></>}


         <Modal
            show={show}
            size="md"
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
            centered
         >
            <div className='mt-2 text-center p-1'>
               <h4 className='fw-bold'> <FaTicketAlt />  {title_modal} </h4>
            </div>
            <Modal.Body>
               <div className="input-group input-group-lg">
                  <input type="text" className="form-control" placeholder='Ingrese código de cupon' onChange={(e) => (setValueInput(e.target.value))} />
               </div>

               <div className='d-flex justify-content-between mt-3'>
                  <button className='btn btn-danger btn-lg' onClick={() => setShow(false)}> Cancelar </button>
                  <button className='btn btn-success btn-lg' onClick={() => aplyCupon()}> Aplicar  </button>
               </div>
            </Modal.Body>
         </Modal>


      </>
   )
}

export default ModalCoupons