import React, { useState, useEffect } from 'react'
import KeyPad from '../../components/Keypad/keypad';
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad';
//Component
import Header from "./components/Header";
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import { DonleeFechtAPI } from '../../api/Donlee';

function ModalDonLeeRegister({ icon, background, showModalDonleeRegister, setShowModalDonLeeRegister, formatJsonOrder, setClientPhone, businesssConfig, nextStep, setShowModalNotificator, clientPhone, setAtivity }) {
    //state of keypad number
    const [displayNumber, setDisplayNumber] = useState('');
    const [eventChange, setEventChange] = useState(0)
    const [valueInput, setValueInput] = useState("");
    const [stepInput, setStepInput] = useState(0);
    const [labelStep, setLabelStep] = useState("Ingrese su nombre");
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [placeHolderInput, setplaceHolderInput] = useState("Nombre")

    //Focus
    const [isButtonFocusedId, setButtonFocusId] = useState(null);

    const handleButtonFocus = (buttonId) => {
        setButtonFocusId(buttonId);
    };

    const handleButtonBlur = () => {
        setButtonFocusId(null);
    };

    const handlerAction = () => {

        switch (stepInput) {
            case 0:
                setname(displayNumber);
                setplaceHolderInput('ejemplo@ejemplo.com')
                setLabelStep("Ingrese un email");
                setStepInput(1);
                setEventChange(1);
                setDisplayNumber('');
                break;
            case 1:
                setStepInput(0);
                setDisplayNumber('');
                setLabelStep("Ingrese su nombre");
                setplaceHolderInput('Nombre')
                console.log("Listo");
                registerNewClient();
                break;
             
           
        }
    }

    const registerNewClient = async() =>{
        let token = localStorage.getItem('x-access-machine-token')
        let data = {
            client_phone: clientPhone,
            client_email: email,
            client_name: name
        }
        try{
            const response =  await DonleeFechtAPI.registerNewClient(data, token);
            console.log(response);

            ErrorAlert("", "(:  Gracias por registrarte "+ name + ":D", "success");
            setShowModalDonLeeRegister(false);
            setShowModalNotificator(true);

        }catch(err){
            console.log(err);
            ErrorAlert(" ", "Ocurrio un error en la consulta", "error")
        }
    }

    const clearInputs = () =>{
        setname("")
        setemail("")
        setStepInput(0);
        setDisplayNumber('');
        setLabelStep("Ingrese su nombre");
        setplaceHolderInput('Nombre')
        setEventChange(1);
    }



    useEffect(() => {
        setEventChange(0)
    }, [eventChange]);

    return (
        <>
            <Modal
                show={showModalDonleeRegister}
                fullscreen
                onHide={() => setShowModalDonLeeRegister(false)}>
                <Modal.Body>
                    <main className='keyboard-main h-100 d-flex  ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(1)}>
                        <div className=''>
                            <section className='bd-highlight my-5'>
                                <Header
                                    classContainer={"kiosk-delivery_header m-0 p-0"}
                                    classImg={"kiosk-delivery_header_img"}>
                                </Header>
                                <div className='d-flex justify-content-center alight-item-center'><h2 className='kiosk-delivery_title  text-center '>
                                    {labelStep}
                                </h2></div>

                                {/* <h2 className="keyboard-title"><i className="uil uil-pricetag-alt"></i> </h2> */}
                            </section>
                            <section className='keyboard-steps bd-highlight w-100 row g-3'>
                                <div className={name != '' ? 'col-6 keyboard-step bg-light' : 'col-6 keyboard-step'}>
                                    <h3 className={name != '' ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>Nombre
                                        <p className={name != '' ? 'keyboard-steps-number keyboard-steps-number-active text-center ' : 'keyboard-steps-number text-center text-muted opacity-25'}>{name}</p></h3>
                                </div>
                                <div className={email != "" ? 'col-6 keyboard-step bg-light' : 'col-6 keyboard-step'}>
                                    <h3 className={email != "" ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>Email</h3>
                                    <p className={email != "" ? 'keyboard-steps-number keyboard-steps-number-active text-center ' : 'keyboard-steps-number text-center text-muted opacity-25'}>{email}</p>
                                </div>
                            </section>

                            <section className='bd-highlight w-100'>
                                <KeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} placeHolderInput={placeHolderInput} />
                            </section>
                            <section className='bd-highlight row'>
                                {/* <div className='col-md-6'>
                           <button type="button" className=" btn btn-lg btn-vending-secondary btn-vending w-100 text-dark" onClick={() => (handleClearAllData())}>
                           <i className="uil uil-arrow-right"></i> Limpiar 
                           </button>
                        </div> */}
                                <div className='col-12'></div>
                            </section>
                        </div>
                    </main>
                </Modal.Body>
                {/* <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => (setShowModalAplyCupon(false), setDisplayNumber(''))}>
                        <i className="uil uil-arrow-left"></i>
                        Regresar
                    </button>

                    <button type="button" className=" btn btn-success btn-vending-product mx-4 rounded" onClick={() => (handleSaveCode())}>
                        <i className="uil uil-arrow-right"></i> Siguiente
                    </button> */}

                <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
                    {/* 1 */}
                    <button
                        type="button"
                        onClick={() => (setShowModalDonLeeRegister(false), setDisplayNumber(''))}
                        className={`m-0 btn-lg  shadow-lg border-0 button-ft_1
                   button-ft_1 position-relative
                  ${isButtonFocusedId === 'exit' ? 'focused' : ''}`}
                        onFocus={() => handleButtonFocus('exit')}
                        onBlur={handleButtonBlur}
                        title='Regresar'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            className=""
                            id="left-arrow">
                            <path
                                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>

                        <div className="position-absolute postition-icon-md">
                            {isButtonFocusedId === 'exit' && <img src={icon} alt="Hand" className='icon-focus' />}
                        </div>
                    </button>
                    {/* 2 */}
                    <div className="d-flex justify-content-center align-item-center m-2 ">
                        <button className={`btn btn-primary kiosk-subtitle w-100 rounded-4 border px-5 py-3 h-100 position-relative           
                     ${isButtonFocusedId === 'clear' ? 'focused' : ''}`}
                            onFocus={() => handleButtonFocus('clear')}
                            onBlur={handleButtonBlur}
                            onClick={() => clearInputs()}
                            
                        >
                            Limpiar

                            <div className="position-absolute postition-icon-md">
                                {isButtonFocusedId === 'clear' && <img src={icon} alt="Hand" className='icon-focus' />}
                            </div>
                        </button>

                        <button className={`btn btn-success kiosk-subtitle w-100 rounded-4 border px-5 py-3 h-100 position-relative           
                     ${isButtonFocusedId === 'nextNotificator' ? 'focused' : ''}`}
                            onFocus={() => handleButtonFocus('nextNotificator')}
                            onBlur={handleButtonBlur}
                            onClick={() => handlerAction()}
                            
                        >
                            Continuar

                            <div className="position-absolute postition-icon-md">
                                {isButtonFocusedId === 'nextNotificator' && <img src={icon} alt="Hand" className='icon-focus' />}
                            </div>
                        </button>


                    </div>
                </div>

            </Modal>

        </>
    )
}

export default ModalDonLeeRegister