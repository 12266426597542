import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";
import product from '../img/products/product-1.jpg';

function CheckOrder({ name, ...props }) {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //Function to populate the products list 
  const productsList = () => {
    let rows = props.products.map((product) => (
      <div className="order-card">
        <img className="order-image" src={product.img} />
        <div className="order-detail">
          <p>{product.name}</p>
          x <input type="text"  value="1" />
        </div>
        <h6 className="order-price fw-bold">$ <span>{product.suggested_price}</span> </h6>
      </div>
    ));

  return (
    <>
      {rows.length === 0 ? 'No tiene productos' : rows}
    </>
  )
}
return (
  <>

    <i className="button-nav-sp uil uil-shopping-cart" onClick={handleShow} ></i>

    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title><i className='uil uil-shopping-cart'></i> Carrito </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* <div className="dashboard-order">
            <h3>Order Menu</h3> <div className="order-address">
              <p>Datos de cliente </p>
              <h4>24 calle abajo, Panama</h4>
            </div>
          </div> */}
        {/* Ordenes  */}
        <div className='texte-center'>
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-dark">Tú carrito</span>
            {/* <span className="badge bg-success rounded-pill">3</span> */}
          </h4>
        </div>
        <div className="order-wrapper">
          {productsList()}
          <div className="order-total">
            <p>Subtotal: <span>$ <strong>{props.subtotal_order.toFixed(2)}</strong></span></p>
            <p>Impuestos: <span>$ <strong>{props.tax_order.toFixed(2)}</strong></span> </p>
            <p>Total:  <span>$ <strong>{props.total_order.toFixed(2)}</strong></span> </p>
          </div>
        </div>
        <button className="btn btn-success button-promo  w-100 border-0">
          Pagar
        </button>

      </Offcanvas.Body>
    </Offcanvas>
  </>
)
}

function Orientation({ products,total_order, subtotal_order, tax_order  }) {
  console.log(products);
  return (
    <>
      {['right'].map((placement, idx) => (
        <CheckOrder key={idx} placement={placement} name={placement} products={products} total_order = {total_order} subtotal_order= {subtotal_order} tax_order={tax_order} />
      ))}
    </>
  );
}


export default Orientation