import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class ParthnerFetchAPI {
    static async getBusinessParthners(token) {
        const res = await server(`/parthner`, {
            method: "get",
            headers: {'x-access-token': token },
        });
        return res
    }

  

    
}