import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';

import { Button } from 'react-bootstrap'



import Swal from 'sweetalert2';

function VendingModalVoucher({ showModalvoucher, setshowModalvoucher, voucher_Order }) {

    return (
        <>

            <div className='d-flex alight-item-center justify-content-center'>
                <Button className='btn btn-vending btn-vending-success' onClick={async () => (setshowModalvoucher(true))}>Ver Recibo</Button>
            </div>

            {/*Modal view voucher */}
            <Modal
                show={showModalvoucher}
                size='lg'
                centered

                onHide={() => setshowModalvoucher(false)}>
                <Modal.Header className='h1' closeButton>
                    <Modal.Title className='display-4 fw-bold text-primary'>Recibo</Modal.Title>
                </Modal.Header>
                <Modal.Body className='h-100'>
                    <div className="voucher-container d-flex justify-content-center">

                        <pre className="voucher-text">
                            {voucher_Order}
                        </pre>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default VendingModalVoucher