import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class ProductsFetchAPI {

    static async getProductsByBranchOfficessByStatus(branchoffice_id, status, token) {
        const res = await server(`/group/${branchoffice_id}/branchoffice/${status}/status`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getProductsByBranchOfficessByStatusByCategories(category_Id, branchoffice_id, status, token) {
        const res = await server(`/group_per_category/${category_Id}/category/${status}/status/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getSegmentsByProducts(group_id, status, branchoffice_id, token) {
        const res = await server(`/segment_per_group/${group_id}/group/${status}/status/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

  
}