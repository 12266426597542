import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class CategriesPosFetchAPI {
    static async getCategoriesByBranchOffices(branchoffice_id, status, token) {
        const res = await server(`/category_per_branchoffice/${branchoffice_id}/branchoffice/${status}/status`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getCategoriesByBusiness(business_id, token) {
        const res = await server(`/category_per_business/${business_id}/business`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async getCategoriesByPrinters(printer_id, token) {
        const res = await server(`/category_per_printer/${printer_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async addCategories(data, token) {
        const res = await server(`/category_per_printer`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    static async DeleteCategories(data, token) {
        const res = await server(`/category_per_printer`, {
            method: "delete",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    
}