import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';



import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';


function ModalEditGroup({ product, loadProducts }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    const [imgpreview, setImgpreview] = useState(product.img)
    const [upc_list, setUpc_list] = useState([])
    const [taxes, setTaxes] = useState([])

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append('group_type', 'infinite')
        const editGroup = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await GroupsFechtAPI.updateGroup(product.group_id, editGroup, token);
            //We show the success alert 
            console.log(response)

            ErrorAlert('', 'Producto editada con exito', 'success')
            loadProducts();
            setShowModal(false);
            setImgpreview(product.img)
        } catch (err) {
            setImgpreview(product.img)
            ErrorAlert(err.response.data.error, 'Error en la edicion', 'error')
            console.log(err)
        }
    }


    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);

            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }


    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }




    useEffect(() => {

        
        setEventHandle(0)

    }, [eventHandle])




    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={() => (setShowModal(true), getTaxes())}
            >
                <i className="uil uil-pen"></i>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(product.img))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Editar Categoria </h3>
                </Modal.Header>
                <Modal.Body>
                    

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview}
                            alt={product.name}
                        />
                    </div>

                    <div className='d-flex align-item-center justify-content-center m-3'>
                        <label htmlFor="upload-button">
                            <h5 className="text-center">Actualizar imagen</h5>
                        </label>
                    </div>
                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={product.name}
                                name="name"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Descripcion</Form.Label>
                            <Form.Control
                                as="textarea" rows={3}
                                placeholder={product.description}
                                name="description"

                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={product.price}
                                name="price"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Preparacion</Form.Label>
                            <Form.Select aria-label="" name="preparation">
                                <RenderIf isTrue={product.branchoffice_preparation == 0}>
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                </RenderIf>
                                <RenderIf isTrue={product.branchoffice_preparation == 1}>
                                    <option value={1}>Si</option>
                                    <option value={0}>No</option>
                                </RenderIf>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Impuesto del item</Form.Label>
                            <Form.Select aria-label="" name="tax" >
                            <option value={product.taxt_id}>{product.tax}</option>
                                {taxes.map(tax => (
                                    <option value={tax.id}>{tax.tax}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        {/*<Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                {viewTypeCategory()}
                            </Form.Select>
                </Form.Group>*/}



                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control
                                id="upload-button"
                                type="file"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                hidden
                            />
                        </Form.Group>




                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(product.img))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditGroup