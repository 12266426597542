import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { PrinterFetchAPI } from '../../api/Printer';
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';

const ModalEditIp = ({ printer, setEventHandle }) => {
    const [showModalEditIp, setshowModalEditIp] = useState(false)
    const [ip_Update, setIp_Update] = useState("")
    let token = localStorage.getItem('x-access-machine-token');
    let machine = localStorage.getItem('machine');

    if(machine != undefined || machine != null || machine != ""){
        machine = JSON.parse(localStorage.getItem('machine'));
    }

     //function action print order
     const UpdatePrinterIp = async () => {
        LoadingAlert()
        try {
            const response = await PrinterFetchAPI.updateIpPrinter(token, machine.id, printer.printer_id, ip_Update);
            console.log(response);
            ErrorAlert("Ip actualizada con exito", " Exito", "success")
            setEventHandle(1)
            setshowModalEditIp(false);

        } catch (err) {
            ErrorAlert("Error", "Ocurrio un error", "error")
            console.log(err);
        }
    }

    return (
        <>
            <Button className='m-2' onClick={() => (setshowModalEditIp(true))} ><i className="uil uil-edit"></i> Editar IP</Button>

            <Modal
                show={showModalEditIp}
                onHide={() => setshowModalEditIp(false)}
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-print"></i> {printer.printer_name}</h3>
                </Modal.Header>
                <Modal.Body>
                    <h3 className='text-center'><i className="uil uil-edit"></i> Editar IP address</h3>
                    <div className='container d-flex justify-content-center alight-item-center m-3'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <Form>
                                    <Form.Group className="mb-3" >
                                        <Form.Control placeholder={printer.ip} onChange={(e) => (setIp_Update(e.target.value))} />
                                    </Form.Group>
                                </Form>
                            </div>

                        </div>
                    </div>

                    <div className='d-flex justify-content-between mt-2'>
                        <button className='btn btn-danger border-0 btn-lg' onClick={() => setshowModalEditIp(false)} > Cancelar </button>
                        <button className='btn btn-success btn-lg' onClick={async() => (UpdatePrinterIp())}> Guardar  </button>
                    </div>

                </Modal.Body>

            </Modal>

        </>
    )
}

export default ModalEditIp