import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});



export class DeletedTempOrdersFetchAPI {


    //Function to get temporal orders by code 
    static async newDeletedTempOrder(data, token) {
        const res = await server(`/deleted_temp_orders`, {
            method: "post",
            data,
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get all the report info 
    static async getDeletedTransactionReport(business_code, branchoffice_code, minDate, maxDate, user_id, token) {
        const res = await server(`/deleted_temp_orders/${branchoffice_code}/${business_code}/${minDate}/${maxDate}/${user_id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }

    //Function to get all the report info 
    static async getDeletedTransactionById(_id, token) {
        const res = await server(`/deleted_temp_orders/${_id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res
    }



}




