import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import { FaCreditCard } from 'react-icons/fa';



function ModalPaymentManual({ showPaymentManual, setPaymentManual, paymentInfo, registernewOrder, statusActivity, aprovedManualPaymentacardMixto }) {
    
    const [numberReference, setNumberReference] = useState(0)
    let machine = localStorage.getItem('machine');
    let token = localStorage.getItem('x-access-machine-token');
    //get machine
    if (machine !== '' && machine !== null && machine !== undefined) {
        machine = JSON.parse(localStorage.getItem('machine'))
    }


    let title_modal = "Pago con " + paymentInfo.payment

    const handlerRegisterOrder = () => {

        if (statusActivity == true) {
            registernewOrder({voucher:  numberReference})
        } else if (statusActivity == false){

            //let temporalVoucher = "Terminald ID:                   10201497\r\n            ***  VENTA  ***             \r\nSMART PAY            512846XXXXXX8515(L)\r\nAUTH: 000789                 TRX: 127991\r\nREF:                            35679242\r\n\r\nFECHA: 11/10/2023                4:57 PM\r\n\r\nTOTAL:                         USD. 5.35\r\n\r\n\r\n       FIRMA: ___________________       \r\n                                        \r\n            DEBIT MASTERCARD            \r\nDF:                       A0000000041010\r\nARQC:                   3B6185F8F695DBED\r\nUN:                             D7BAE16C\r\nTVR:                          0000008001\r\nAID:                      A0000000041010\r\n\r\n           ****** FIN  ******           \r\n\r\n- - - - - - - - - - - - - - - - - - - -\r\n        ***  COPIA CLIENTE  ***         \r\n\r\nTerminald ID:                   10201497\r\n            ***  VENTA  ***             \r\nSMART PAY            512846XXXXXX8515(L)\r\nAUTH: 000789                 TRX: 127991\r\nREF:                            35679242\r\n\r\nFECHA: 11/10/2023                4:57 PM\r\n\r\nTOTAL:                         USD. 5.35\r\n            DEBIT MASTERCARD            \r\nDF:                       A0000000041010\r\nARQC:                   3B6185F8F695DBED\r\nUN:                             D7BAE16C\r\nTVR:                          0000008001\r\nAID:                      A0000000041010\r\n\r\n           ****** FIN  ******           \r\n"
            aprovedManualPaymentacardMixto("Aprobado", numberReference)
        }

        setPaymentManual(false)
        setNumberReference(0)
    }
    return (
        <>

            <Modal
                show={showPaymentManual}
                size="md"
                onHide={() => setPaymentManual(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <div className='mt-2 text-center p-1'>
                    <h4 className='fw-bold'><FaCreditCard /> {title_modal} </h4>
                </div>

                <Modal.Body>

                    <div className='row bg-light p-2'>
                    <span className='px-3'>Campo no obligatorio</span>
                        <div className="col-12 col-md-12">
                            <input type="text" className="form-control" placeholder='Número de aurorizacion ' onChange={ (e) => setNumberReference(e.target.value)} />

                        </div>
                    </div>
                    <div className='d-flex justify-content-between mt-2'>
                        <button className='btn btn-danger border-0 btn-lg' onClick={() => setPaymentManual(false)}> Cancelar </button>
                        <button className='btn btn-success btn-lg' onClick={() => { handlerRegisterOrder() }}> Finalizar pago  </button>
                    </div>

                </Modal.Body>
            </Modal>


        </>
    )
}

export default ModalPaymentManual