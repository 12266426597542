//React Components 
import React, { useState, useEffect, useMemo } from 'react';
//Components
import { Table, Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import ModalCreateItem from './ModalItems.jsx/ModalCreateItem';
import ModalEditItem from './ModalItems.jsx/ModalEditItem';
import ModalViewItem from './ModalItems.jsx/ModalViewItem';
import ModalImport from './ModalImport';
import Swal from 'sweetalert2';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
import ModalAsingAndRemovedItemToBranchoffice from './ModalItems.jsx/ModalAsingAndRemovedItemToBranchoffice';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
//API
import { ItemsFechtAPI } from '../../api/Items';

const ItemTable = ({ branchoffice_id, eventHandler, token, title, user }) => {
    const [items, setItems] = useState([]);
    const [searchedItems, setSearchedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // We load the items list 
        loadItems();
    }, [eventHandler]);

    //Function to load items
    const loadItems = async () => {
        try {
            //We search the items of the branchoffice 
            const items = await ItemsFechtAPI.getItemsByBranchoffice(branchoffice_id, token);
            console.log(items)
            setItems(items.data.data)
            setSearchedItems(items.data.data)
        } catch (err) {
            setItems([])
            setSearchedItems([])
        }
    };

    //Filter the items by the term searched 
    const filteredItems = useMemo(() => {
        if (searchTerm === '') {
            return items
        }

        return searchedItems.filter(item => {
            const nameMatch = item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = item.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, items]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked,
            item_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await ItemsFechtAPI.updateItemBranchoffice(branchoffice_id, data, token)
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los items al cambiar el estado del switch
            loadItems();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error, 'Opss!', 'warning');
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los items al cambiar el estado del switch
            loadItems();
        }
    };

    const beforeDelete = async (item_id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar este producto individual de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItemToBranchoffice(item_id)
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino el producto individual',
                    'error'
                )
            }
        })
    }

    const deleteItemToBranchoffice = async (item_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            "item_id": item_id
        }
        try {
            const response = await ItemsFechtAPI.deleteItemToBranchoffice(branchoffice_id, data, token)
            ErrorAlert('', 'El producto individual fue eliminado correctamente', 'success')
            loadItems()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar el producto individual', 'error')
        }
    }



    const viewItem = () => {

        const list = filteredItems.map(item => (
            <Col sm="2" md="6" lg="4" xl="4">
                <ListGroup as="ol" className='m-1' >
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={item.img}
                            alt={item.name}
                        />
                        <div className="ms-2 me-auto d-inline-block text-truncate">
                            <div className="fw-bold">{item.name}</div>
                            <div className='d-flex justify-constent-center align-items-center'>
                                {/**Validation if the user can enable disable items */}
                                <RenderIf isTrue={checkUserPermission('enable_disable_items', user.permissions) !== -1}>
                                    <Form.Switch
                                        type="switch"
                                        id={item.item_id}
                                        label=""
                                        checked={item.status === 1 ? true : false}
                                        onChange={handleSwitchChange}
                                    />
                                </RenderIf>
                                {item.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>}
                            </div>

                            <p className=''>{item.description}</p>
                            <div className=' d-flex align-item-center justify-content-center'>
                                <div className='m-1'><ModalViewItem item={item} ></ModalViewItem></div>
                                {/**Validation to edit items */}
                                <RenderIf isTrue={checkUserPermission('update_items', user.permissions) !== -1}>
                                    <div className='m-1'><ModalEditItem item={item} loadItems={loadItems}></ModalEditItem></div>
                                </RenderIf>
                                <div className='m-1'>
                                    {/**Validation to delete items */}
                                    <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_items', user.permissions) !== -1}>
                                        <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => beforeDelete(item.item_id)}>
                                            <i className="uil uil-trash-alt"></i>
                                        </button>
                                    </RenderIf>
                                </div>
                            </div>
                        </div>


                    </ListGroup.Item>
                </ListGroup>
            </Col>


        ))
        return (list)
    }

    return (
        <>
            <div className='board rounded shadow-sm p-sm-1 p-lg-4'>

                <div className="row bg-light rounded py-3 m-1 border border-light">
                    <div className='col-md-12'>
                        <h4 className='fs-4 fw-bold text-center'>{title}</h4>
                        <p className=' text-center'>Cantidad productos individuales: {items.length}</p>
                    </div>
                    <div className="col-md-6 p-2 d-flex justify-content-center align-items-center">
                        <div className="w-auto d-flex justify-content-end ">
                            <form className="row g-3 align-content-center align-items-center">
                                <div className="col-auto">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese producto"
                                        onChange={e => setSearchTerm(e.target.value)}

                                    />
                                </div>
                            </form>
                        </div></div>
                    <div className="col-md-6 p-2">
                        <div className='d-flex justify-content-center align-items-center'>
                            {/**Validation if the user can create items */}
                            <RenderIf isTrue={checkUserPermission('create_items', user.permissions) !== -1}>
                                <div className=''> <ModalCreateItem loadItems={loadItems}></ModalCreateItem></div>
                                <div className=''><ModalImport title={"producto individual"} load={loadItems}></ModalImport></div>
                            </RenderIf>
                            {/**Validation to asing item to branchoffice */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_items', user.permissions) !== -1}>
                                <div className=''>
                                    <ModalAsingAndRemovedItemToBranchoffice branchoffice_id={branchoffice_id} load={loadItems} itemsBranchoffice={items}></ModalAsingAndRemovedItemToBranchoffice>
                                </div>
                            </RenderIf>

                        </div>
                    </div>

                </div>

                <div className="mt-3">
                    <Container>
                        <Row>
                            {viewItem()}
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default ItemTable;
