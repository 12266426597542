import React from "react";
// import NavBar from "../../../NavBar";
//Imgs
import team_1 from "../../img/team/1.jpeg";
// import team_2 from "../img/team/2.jpeg";
// import team_3 from "../img/team/3.jpeg";
// import team_4 from "../img/team/4.jpeg";
// import team_5 from "../img/team/6.jpeg";
// import team_6 from "../img/team/7.jpeg";
function Team() {
  return (
    <>
      <div className="p-8 bg-white  rounded-lg shadow mt-12">
        <h1 className="text-center text-3xl font-bold text-gray-700">
          Equipo profesional
        </h1>
        <p className="text-center mb-12 mt-2 text-xl font-normal text-gray-700 dark:text-gray-700">
          <span className="text-color-sp font-bold" > Smart Pay </span>
           el mejor equipo del mundo
        </p>
        <div className="grid gap-4 grid-cols-1 grid-rows-1 sm:grid-cols-3 ">
          {/* 1 */}
          <div className="p-4  hover:shadow">
            <div className="text-center mb-4 opacity-90">
              <a href="#" className="block relative">
                 <img
                  alt="profil"
                  src={team_1}
                  className="mx-auto object-cover rounded-full h-40 w-40 "
                  width="40"
                /> 
              </a>
            </div>
            <div className="text-center">
              <p className="text-2xl  font-bold text-color-sp ">
                Moises Rodríguez
              </p>
              <p className="text-xl text-gray-500 font-normal">
                CEO
              </p>
            </div>
          </div>               
       </div>
      </div>
    </>
  );
}

export default Team;
