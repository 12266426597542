import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { SegmentsFechtAPI } from '../../../api/Segments';
import { ItemsFechtAPI } from '../../../api/Items';
import { TaxesFetchAPI } from '../../../api/Taxes';
import Select from 'react-select';

function ModalAddItemSegment({ segment, load }) {
    const [eventHandle, setEventHandle] = useState(0);
    const [itemSelectAsing, setItemSelectAsing] = useState([])
    const [showModalAsing, setShowModalAsing] = useState(false)
    const [itemSelectNow, setItemSelectNow] = useState("")
    const [price, setPrice] = useState("")
    const [tax_id, setTax_id] = useState("")
    const [taxes, setTaxes] = useState([]);
    const [taxesSelect, setTaxesSelect] = useState([])


    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getItemsByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await ItemsFechtAPI.getItemsbYBusiness(user.business_id, token)

            let ItemInfo = []
            response.data.data.forEach(item => {
                ItemInfo.push({ value: item.item_id, label: item.name }) 
            });

            setItemSelectAsing(ItemInfo)
        } catch (err) {
            console.log(err);
        }
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);

            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    //filter checkbox in state true and save in state array
    const getChecked = (event) => {
        let dataTaxes = JSON.parse(event.target.value);

        if (event.target.checked == true) {
            setTaxesSelect([...taxesSelect, dataTaxes])
        } else if (event.target.checked == false) {
            for (let i = 0; i < taxesSelect.length; i++) {
                if (taxesSelect[i] == dataTaxes) {
                    taxesSelect.splice(i, 1)
                }
            }
        }
    }


    const asingProductToSegment = async () => {
        let data = {
            price: price,
            taxes: taxesSelect,
        }

        try {
            const response = await SegmentsFechtAPI.asingItemsToSegment(itemSelectNow, segment.segment_id, data, token)
            setTaxesSelect([])
            load()
            setShowModalAsing(false)
            ErrorAlert('', 'Item asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    const handleSaveTableSelect = (option) =>{
        setItemSelectNow(option.value);
     }


    useEffect(() => {


    }, [eventHandle])




    return (
        <>


            <button className='btn btn-outline-primary btn-sm border-0' onClick={() => (setShowModalAsing(true), getItemsByBusiness(), getTaxes())}> <i className="uil uil-plus">Asignar producto individual</i>
            </button>

            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar producto individual </h3>
                </Modal.Header>
                <Modal.Body>


                    <Form
                        className="needs-validation"

                    >

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Item individual</Form.Label>
                            <Select
                                className='w-100'
                                options={itemSelectAsing}
                                onChange={handleSaveTableSelect}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="$0.00"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Impuestos </Form.Label>
                            <div>
                                {taxes.map((tax, index) => (
                                    <>
                                        <Form.Check
                                            inline
                                            label={tax.tax}
                                            name="taxes"
                                            type='checkbox'
                                            value={JSON.stringify(tax.id)}
                                            onChange={(e) => getChecked(e)}
                                            id={`tax${tax.id}`}
                                        />
                                    </>
                                ))}
                            </div>
                        </Form.Group>

                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="button" className='m-3' onClick={() => asingProductToSegment()} >
                                Guardar
                            </Button>
                        </div>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalAddItemSegment