import React from 'react'
import { Link } from "react-router-dom"
import noMatch from '../img/backgrounds/bg-sp.png'
function NoMatch() {
  return (
    <>
      <section className="px-4 py-24 mx-auto max-w-7xl bg-light">
        <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-4">
              <img
                src={noMatch}
                alt="Bootstrap Themes" width="400" height="400" loading="lazy" />
            </div>
            <div className="col-lg-8">
              <h1 className="display-5 fw-bold lh-1 mb-3 text-color-sp">Lo siento, parece que la página que buscas no se encuentra disponible en este momento.</h1>
              <div className="justify-content-start">
                <Link to='/'>
                  <button type="button"
                    className="btn btn-lg bg-color-sp fw-bold border rounded shadow-sm">
                    <i className="uil uil-arrow-left"></i>
                    Ir a pagina a principal
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default NoMatch