import React, { useState, useRef } from 'react'

import ModalInfoTransaction from './ModalInfoTransaction';

import logo_sp from '../../assets/img/logos/icon-black.png';




function AdminPreAccount() {

   const [viewTableInfo, setviewTableInfo] = useState(false);

   const toggleviewTableInfo = () => {
      setviewTableInfo(!viewTableInfo);
   };

   //Data Example for tests
   const itemsData = [
      { id: 1, name: 'Client 1', users: 10, info: 'Información detallada sobre el client  1.' },
      { id: 2, name: 'Client 2', users: 5, info: 'Información detallada sobre el  client 2.' },
      { id: 3, name: 'Client 3', users: 8, info: 'Información detallada sobre el  client 3.' },
      { id: 4, name: 'Client 4', users: 3, info: 'Información detallada sobre el  client 4.' },
      { id: 5, name: 'Client 5', users: 15, info: 'Información detallada sobre el client  5.' },
      { id: 6, name: 'Client 6', users: 1, info: 'Información detallada sobre el client  6.' },
      { id: 7, name: 'Client 7', users: 3, info: 'Información detallada sobre el client  7.' }
   ];


   const [selectedItem, setSelectedItem] = useState(null);
   const containerRef = useRef(null);
   const handleItemClick = (item) => {
      setSelectedItem(item);

      if (containerRef.current) {
         containerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
         });
      }
   };

   const handleBackClick = () => {
      setSelectedItem(null);
   };


   const TableInformation = () => {

      return <>

      </>
   }

   const userIcons = (count) => {
      const icons = [];
      for (let i = 0; i < count; i++) {
         icons.push(<div key={i} className=' display-5'>
            <i className="uil uil-user-square text-dark"></i>
         </div>
         );
      }
      return icons;
   };


   return (
      <>
         <div className='kds-main'>
            <header className="py-1 px-1  border-bottom  bg-white fixed-top">
               <nav className="border-bottom border-3 bg-white px-1 fixed-top ">
                  <div className="">
                     <div className="d-flex  align-items-center justify-content-between">
                        <div className='d-flex  bd-highlight  align-items-center'>
                           <a href="javascript:location.reload()"
                              className="d-flex align-items-center 
                            flex-fill bd-highlight 
                            text-dark text-decoration-none">
                              <img
                                 className="navbar-logo-post"
                                 src={logo_sp}
                                 alt="Logo Smart Pay"
                              />
                              <span className="h5 pt-2 me-3 fw-bold align-self-center text-uppercase">Smart Pay</span>
                           </a>
                           <div className='flex-fill bd-highlight '>
                           </div>

                        </div>
                        <div className='flex-fill bd-highlight me-2 '>
                        </div>
                        {/* <NavBar setPageLoader={setPageLoader}></NavBar> */}

                     </div>
                  </div>
               </nav>
            </header>
            <main className="container-parent ">
               <div className="container-pos-left kds-view shadow-sm no-scroll">
                  <div className="flex-container">
                     <div className="item1 mt-5">
                        <div className='d-flex justify-content-center pt-3'>
                           <h4 className='text-dark fw-bold text-center'>
                              <i className="uil uil-envelope-question"></i>
                              Solicitudes
                           </h4>
                        </div>
                     </div>
                     <div className="item2 py-0 px-1">
                        <div className=" px-1  bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-light" >
                           <div className="d-flex shadow-sm rounded m-1 bg-white mt-1">
                              <div className="flex-grow-1 m-1">
                                 <div className="d-flex justify-content-center">
                                    <div className="h5 mt-1 text-primary fw-bold">
                                       <i className="uil uil-envelope-star mx-1"></i>    [ Motivo de Solicitud ]
                                    </div>
                                 </div>
                                 <div className="d-flex justify-content-between mt-2">
                                    <p className='fw-bold text-muted text-center w-100 py-2'>
                                       Mesa 10
                                    </p>
                                    <p className='fw-bold text-muted text-center w-100 py-2'>
                                       [Nombre de CLiente x]
                                    </p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="item3"></div>
                  </div>
               </div>

               <div className="container-pos-right no-scroll">
                  <section className='h-100'>
                     <div className="flex-container">
                        <div className="item1 mt-5">
                           <div className='d-flex justify-content-center pt-3'>
                              <h4 className='text-dark fw-bold'>
                                 <i className="uil uil-table"></i>
                                 Mesas
                              </h4>
                           </div>
                        </div>
                        <div className="item2 py-0 px-1">
                           <div className="">
                              {selectedItem ? (
                                 <>

                                    <div className="shadow-sm rounded m-1 bg-white" ref={containerRef}  >
                                       <h4 className="mb-3">
                                          <i className="uil uil-arrow-left btn btn-outline-info border-0 fs-3" onClick={handleBackClick} >
                                          </i> {selectedItem.name} / Mesa: {selectedItem.name}
                                       </h4>


                                       {/* <p>Cantidad de Usuarios: {selectedItem.users}</p>
                                          <p>{selectedItem.info}</p> */}


                                       <div className="row g-5 mx-2">
                                          <div className="col-md-5 col-lg-8 order-md-last">
                                             <h4 className="d-flex justify-content-between align-items-center mb-3">
                                                <span className="text-primary">Ordenes </span>
                                                <span className="badge bg-primary rounded-pill">3</span>
                                             </h4>
                                             <input type="text" id="fname" name="fname" autofocus />
                                             <ul className="list-group mb-3">
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                   <div>
                                                      <h6 className="my-0 title-product-table ">Product name 82817327 3737373 </h6>
                                                      <small className="text-muted">Brief description</small>
                                                   </div>
                                                   <span className="text-muted">x 1</span>
                                                   <span className="text-muted">$12.00
                                                      <i className="uil uil-trash-alt  btn btn-outline-danger btn-sm border-0 mx-2 px-2"></i>
                                                   </span>

                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                   <div>
                                                      <h6 className="my-0">Second product</h6>
                                                      <small className="text-muted">Brief description</small>
                                                   </div>
                                                   <span className="text-muted">$8</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                                   <div>
                                                      <h6 className="my-0">Third item</h6>
                                                      <small className="text-muted">Brief description</small>
                                                   </div>
                                                   <span className="text-muted">$5</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between bg-light">
                                                   <div className="text-success">
                                                      <h6 className="my-0">Promo code</h6>
                                                      <small>EXAMPLECODE</small>
                                                   </div>
                                                   <span className="text-success">−$5</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">
                                                   <span>SubTotal</span>
                                                   <strong>$20</strong>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">
                                                   <span>Impuesto</span>
                                                   <strong>$20</strong>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">
                                                   <span>Total</span>
                                                   <strong>$20</strong>
                                                </li>
                                             </ul>


                                          </div>
                                          <div className="col-md-7 col-lg-4">
                                             <h4 className="mb-3">Clientes </h4>
                                             <form className="needs-validation" novalidate="">
                                             </form>
                                          </div>
                                       </div>
                                    </div></>

                              ) : (
                                 <>

                                    <section className='list-tables-user m-0'>
                                       {itemsData.map((item) => (
                                          <div className=" px-1  bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-light" >
                                             <div className="d-flex shadow-sm rounded m-1 bg-white" key={item.id} onClick={() => handleItemClick(item)}>
                                                <div className="flex-grow-1 m-1">
                                                   <div className="d-flex justify-content-start">
                                                      <div className="h5 mt-1 text-primary fw-bold">
                                                         <i className="uil uil-envelope-star mx-1"></i>    Mesa: {item.id} - {item.name}
                                                      </div>
                                                   </div>
                                                   <div className="d-flex justify-content-between   align-items-center m-2 ">
                                                      {/* Cantidad de Usuarios: {item.users} */}


                                                      <div className='d-flex justify-content-center'>
                                                         {/* {userIcons(item.users)} */}
                                                         <div className='fs-5 text-muted '>
                                                            <i className="uil uil-user-square bg-light"></i>
                                                            Clientes en mesa: {item.users}
                                                         </div>
                                                      </div>
                                                      <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                         <button type="button" className="btn btn-outline-primary border-0" title='Ver'>
                                                            <i className="uil uil-eye display-6"></i></button>
                                                         <button type="button" className="btn btn-outline-danger border-0" title='Borrar'>
                                                            <i className="uil uil-trash-alt display-6"></i></button>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div></div>
                                       ))}
                                    </section>


                                 </>


                              )}
                           </div>
                        </div>
                        <div className="item3 d-flex justify-content-end text-muted ">
                           <small className='brand-text position-absolute bottom-0 end-0'>
                              Powered By Smart Retail Group
                           </small>
                        </div>
                     </div>
                  </section>
               </div>
            </main>
         </div >


      </>
   )
}

export default AdminPreAccount