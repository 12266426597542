import React, { useEffect, useState } from 'react';

//Internal Components
import Navbar from '../NavBar/Navbar';
import GeneralReport from './tabs/GeneralReports';
//Components 
import { Tabs, Tab } from 'react-bootstrap';
import PaymentMethodReports from './tabs/PaymentMethodReports';
import ProductSalesReport from './tabs/ProductSalesReport';
import BuysAndSalesReport from './tabs/BuysAndSalesReport';
import PreAccountReport from './tabs/PreAccountReport';
const ReportStore = () => {

    return (
        <>
            {/** Navbar */}
            <Navbar />
            {/** Main container */}
            <div className="container">
                <div className='smart-pay-main'>
                    <div className=' d-flex justify-content-center'>
                        <h2 className='fs-2'>Reportes </h2>
                    </div>
                </div>

                {/**Tabs */}
                <Tabs defaultActiveKey="all" variant='pills' className='mt-4 nav-scroller d-flex justify-content-center'>
                    {/**Tab of general report */}
                    <Tab eventKey="all" title={<><i className="fa-solid fa-house"></i> General</>}>
                        <div className='mx-lg-3 mt-4'>
                            <GeneralReport />
                        </div>
                    </Tab>
                    <Tab eventKey="active" title={<><i className="fa-solid fa-money-check-dollar"></i> Metodos de pago</>}>
                        <div className="mx-lg-3 mt-4">
                            <PaymentMethodReports />
                        </div>
                    </Tab>
                    <Tab eventKey="inactive" title={<><i className="fa-solid fa-bottle-water"></i> Productos</>}>
                        <div className="mx-lg-3 mt-4">
                            <ProductSalesReport />
                        </div>
                    </Tab>
                    <Tab eventKey="reactive" title={<><i className="fa-solid uil-shopping-bag"></i> Entrada y salida de productos</>}>
                        <div className="mx-lg-3 mt-4">
                            <BuysAndSalesReport />
                        </div>
                    </Tab>
                    <Tab eventKey="preaccounts" title={<><i className="fa-solid uil-shopping-bag"></i> Pre cuentas eliminadas</>}>
                        <div className="mx-lg-3 mt-4">
                            <PreAccountReport />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default ReportStore;
