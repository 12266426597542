import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../../api/BranchOffices';
import { UsersFetchAPI } from '../../../api/Users';
import { OrdersFetchAPI } from '../../../api/Orders';

// External Componets
import moment from 'moment-timezone';
import Select from 'react-select';

//Internal components 
import ReportTable from '../table/ReportTable';
import SuccessAlert from '../../Alerts/SuccessAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';


const ProductSalesReport = () => {
    //Params
    let { business_code } = useParams();
    //We get the token 
    let token = localStorage.getItem('x-access-token');
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    //States 
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [userBusiness, setUserBusiness] = useState({
        id: 0
    });
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    //States of search select 
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [cashiers, setCashiers] = useState([]);
    const [machineCase, setMachineCase] = useState(false);

    const [data, setReportData] = useState({
        product_sales: []
    });

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            console.log(response)
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
            setCashiers([])
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Functio to get cashier users depending of the branch selected
    const getCashiers = async (branchSelected) => {
        let response;
        try {
            //We get the users depending the branch selected 
            if (branchSelected === 0) {
                //If the branch selected is 0 it means that we want the users of the business (all)
                //We get the user cashiers of the business
                response = await UsersFetchAPI.getUsesByBusinessAndRole(userBusiness.id, [4]);
            } else {
                //We get the user cashiers of the branch selected
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchSelected, 4);
            }
            setCashiers(response.data.users)
        } catch (err) {
            setCashiers([])
        }
    }

    //The default option of the select input 
    const defaultOption = [{ label: 'Seleccione...', value: 0 }, { label: 'Terminales de autoservicio', value: 1 }];

    //Get users (cashiers)
    const usersList = cashiers.map((user) => ({
        label: user.user_name + ' ' + user.lastName + ': ' + user.idCard,
        value: user.user_id
    }));

    //Function to get the report of the branch office 
    const getReport = async (dateMin, dateMax) => {
        try {
            //We show a loading alert 
            LoadingAlert();
            //We get the sales info for the reports
            const sales = await OrdersFetchAPI.getProductSalesReport(dateMin, dateMax, selectedBranch.id, 0, token);

            //We set the info 
            setReportData({
                product_sales: sales.data.total_product_sales,
            });

            //We show a success alert
            SuccessAlert();
        } catch (err) {
            //We show a error alert 
            ErrorAlert('No tiene información')
            console.log(err)
        }
    }
/* 
    const downloadPDF = async (e) => {
        e.preventDefault();

        const blobUrl = URL.createObjectURL(
            new Blob([], { type: "application/pdf" })
        );
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = "archivo.pdf";
        a.click();
        URL.revokeObjectURL(blobUrl);
    } */

    //Event to detect the user selected on search select 
    const handleChangeUsers = (option) => {
        //We check if the option is null 
        if (option.value === 0) {
            setSelectedUserOption(null);
            setMachineCase(false);
        } if (option.value === 1) {
            setMachineCase(true);
            setSelectedUserOption(option);
        }
        else {
            setSelectedUserOption(option);
            setMachineCase(false);
        }
    };

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We set the business user 
        setUserBusiness(user.business);
    }, []);

    //Hook to load the users depending the selection of branch 
    useEffect(() => {
        //We get the users 
        getCashiers(selectedBranch.id)
    }, [selectedBranch]);


    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                        <label htmlFor="input_from">Sucursal</label>
                        <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                            <option value={0}>Mostrar todos</option>
                            {branchList()}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e => getReport(initialDate, maxDate)} className="btn btn-success px-5">Aceptar</button>
                </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
                <h5 className="mt-4">Reporte de ventas por metodo de pago </h5>
                <div className="mt-3 mb-2">
                    <a href={`${process.env.REACT_APP_API_URL}/excel/productsales/${initialDate}/${maxDate}/${selectedBranch.id}/0/${userBusiness.id}/download`} className="btn btn-success">
                        <i className="fa-solid fa-file-excel"></i>
                        Descargar Excel</a>
                </div>

            </div>

            {/**Table */}
            <ReportTable
                table_name="Tabla de ventas por producto"
                table_headers={["Nombre del producto", "Unidades vendidas", "Total vendido"]}
                table_type="products_report"
                table_data={data.product_sales} />

        </div>


    );
}

export default ProductSalesReport;
