import React, {useState }  from 'react'

//img 
import empty_list from '../../assets/img/kds/notask.png'

const KdsView = ({ selectedOrder, updateOrderState,requestAnimation}) => {

  //We populate the products list of the order
  const productList = (products) => {
    let list = products.map((product) => (
      < >
        <div className='bg-white  rounded kds-view-details py-2'>
          <div>
            <div className='text-dark text-center'>
              <strong className='h3 fw-bold'> {product.amount}x - </strong>
              <span className='h2 fw-bold'> {product.name}</span>
            </div>
          </div>
          {
            product.segments.map((segment) => (
              <div className="my-3">
                <span className='h5 text-muted opacity-75'> {segment.name} </span>
                <p className='ms-2 fw-bold h4'>{segment.item.name} </p>
              </div>
            ))
          }
        </div>
      </>
    ))
    return <>{list}</>
  }





  return (
    <>
      {
        !selectedOrder.transaction_code
          ? <>
            <section className='d-flex justify-content-center mt-5 '>
              <div className='rounded kds-empty-orders'>
                <div className='d-flex justify-content-center m-2'>
                  <img src={empty_list} className='kds-img-empty ' alt="Empty List" />
                </div>
                <h2 className='text-center fw-bold my-3' >Vista Vacía </h2>
                <h4 className='text-center text-secondary'>
                  No has elegido ninguna orden para ver
                </h4>
              </div>
            </section>
          </>
          : 
          <>
            <div className='flex-container'>
              <div   className={`item1 border-bottom shadow-sm no-scroll ${requestAnimation ? "scale-up-horizontal-left" : ""}`}>
                <div className="d-flex justify-content-between fw-bold">
                  <span className='mx-0 '>#{selectedOrder.transaction_code}</span>
                  <div className="vr"></div>
                  <span className='text-uppercase'>
                    {selectedOrder.order_type}
                  </span>
                  <div className="vr"></div>
                  <span>
                    <i className="uil uil-clock">
                    </i>
                    {selectedOrder.createdAt.split('T')[1].split(':')[0] + ':' + selectedOrder.createdAt.split('T')[1].split(':')[1] + ' ' + (selectedOrder.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')}
                  </span>
                </div>
              </div>
              <div className={`item2 ${requestAnimation ? "scale-up-horizontal-left" : ""}`}>
                {productList(selectedOrder.groups)}
              </div>
              <div className="item3 border-top shadow-sm">
                <div className=''>
                  <div className="d-grid gap-2 col-10 mx-auto">
                    <button className="btn btn-success bg-succes bg-gradient"
                      onClick={e => updateOrderState(selectedOrder._id, selectedOrder.groups, 3)}
                      type="button">
                      Listo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  )
}

export default KdsView