import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';


//Apis
import { PrinterFetchAPI } from '../../api/Printer';
import { ProductsFetchAPI } from '../../api/Paymentpost/Products';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { PaymentMethodFetchAPI } from '../../api/Paymentpost/PaymentMethod';
import { SegmentsFechtAPI } from '../../api/Segments';
import { PromotionsFechtAPI } from '../../api/Promotions';
import { OrderNumberFetchAPI } from '../../api/Paymentpost/OrderNumber';
import { MachinesFetchAPI } from '../../api/Machines';
import { PublicityFechtAPI } from '../../api/Publicity';
import { NotificationsFetchAPI } from '../../api/Notidication'
import { Modal } from 'react-bootstrap';
import { MicrosFetchAPI } from '../../api/Micros';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import { ParthnerFetchAPI } from '../../api/Parthner';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { OrdersFetchAPI } from '../../api/Orders';
import Restaurants from './Restaurants';
import SupportBot from '../../components/SupportBot/SupportBot';

//External modules 
import moment from 'moment-timezone';



//Layout
import Dashboard from "./Dashboard";
import Categories from "./Categories";
import Products from './Products';
import InfoProduct from "./InfoProduct";
import OrderSummary from './OrderSummary';
import Notifier from './Notifier';
import Payments from './Payments';
import StatusPayment from './StatusPayment';
import EndTransaction from './EndTransaction';
import ModalInfoMachine from './ModalInfoMachine';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import CreateAndUpdatePreOrderEnd from './CreateAndUpdatePreOrderEnd';
import HistoryTransaction from './HistoryTransaction';



//Imgs
import SmartPay_Logo from "./assets/img/logos/sp.png";
import ForHere from "./assets/img/icons/for_here.svg";
import ToGo from "./assets/img/icons/to_go.svg";
//import background from "./assets/img/bg/13.png"
import hand_0 from './assets/img/icons/hand_1.png'
import hand_1 from './assets/img/icons/hand.gif'
//CSS
import "../../assets/css/kiosk_main.css"
import { BusinessFetchAPI } from '../../api/Business';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';


function KioskMainEcommers() {

   const { business_code, branchoffice_code, machine_code_table } = useParams();
   let index = 0;
   let machine = "";
   let business = "";
   let branchoffice = "";
   let external_menu = 0;
   //const [branchoffice, setBranchoffice] = useState("")
   let params = false
   let token = localStorage.getItem("x-access-machine-token")


   const navigate = useNavigate();
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {
      navigate(`/kiosk/stant/login/${business_code}/${branchoffice_code}/${machine_code_table}`)
   } else {
      params = true
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      //setBranchoffice(JSON.parse(localStorage.getItem('branchoffice')))
      machine = JSON.parse(localStorage.getItem('machine'));
      business = JSON.parse(localStorage.getItem("business"));
      external_menu = JSON.parse(localStorage.getItem("external_menu"));
   }

   const [step, setStep] = useState(external_menu);
   const [formData, setFormData] = useState({
      name: '',
      email: '',
      address: '',
      phone: '',
   });

   //States 
   const [background, setBackground] = useState("");
   const [businessInfo, setBusinessInfo] = useState({})
   const [statusLanguage, setStatusLanguage] = useState(0)
   const [printers, setPrinters] = useState([])
   const [infoPrinter, setInfoPrinter] = useState({})
   const inputRefLog = useRef(null);
   const [infoMachine, setInfoMachine] = useState({ code: "", language: { name: "" }, machine_name: "" })
   const [showModalInfoMachine, setShowModalInfoMachine] = useState(false)
   const [number, setNumber] = useState(1);
   const [promotions, setPromotions] = useState([])
   const [categories, setCategories] = useState([]);
   const [categorieSelect, setCategorieSelect] = useState({ category_id: "" })
   const [products, setProducts] = useState([]);
   const [paymentMethod, setPaymentMethod] = useState([])
   const [dataPreOrder, setDataPreOrder] = useState({ pre_Order: false, _id: "" });
   const [viewProducts, setViewProducts] = useState([]);
   const [showModalSelectItem, setShowModalSelectItem] = useState(false);
   const [showUpdateAccount, setShowUpdateAccount] = useState(false);
   const [registerTurnOrder, setRegisterTurnOrder] = useState(0);
   const [amountGroupSelect, setAmountGroupSelect] = useState(1);
   const [businessConfig, setBusinessConfig] = useState({});
   const [historyTransaction, setHistoryTransaction] = useState({"orders":[], "total": 0})
   const [selectProduct, setSelectProduct] = useState({
      group: {
         img: '',
         name: '',
         price: '',
         description: '',
      }
   });
   const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago..." })
   const [paymentInfo, setPaymentInfo] = useState({ payment: "", payment_id: 0 })
   const [detail_Payment_bank, setDetail_Payment_bank] = useState(0)
   const [previewSegment, setPreviewSegment] = useState([])
   const [segments, setSegments] = useState([]);
   const [shoppingCart, setShoppingCart] = useState([]);
   const [locator, setLocator] = useState("0");
   const [orderNumber, setOrderNumber] = useState("0")
   const [lastOrder_id, setLastOrder_id] = useState("");
   const [datatransaction_code, setDataTransaction_code] = useState("");
   const [order_code, setOrderCode] = useState("");
   const [activity, setAtivity] = useState(0);
   const [priority, setPriority] = useState(0);
   const [parthners, setParthners] = useState([]);
   //Event handler 
   const [eventHandlerPreviewsegment, setEventHandlerPreviewsegment] = useState(0);
   const [eventHandler, setEventHandler] = useState(0);
   const [pageLoader, setPageLoader] = useState(0)
   //Totals states 
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0);
   const [preOrder, setPreOrder] = useState({ total: 0, transaction_code: "000" });
   const [taxesArrayAll, setTaxesArrayAll] = useState([]);

   //filter
   const [temporalProducts, setTemporalProducts] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');

   //data client
   const [optionalData, setOptionalData] = useState(false);
   const [dataResponse, setDataResponse] = useState(false);
   const [ruc, setRuc] = useState('00000-00');
   const [name, setName] = useState("Cliente generico");
   const [dv, setDv] = useState("00");
   const [checkDeliveryOrder, setCheckDeliveryOrder] = useState("")
   const [clientPhone, setClientPhone] = useState('')
   const [minDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
   const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00');
   //data checked discount
   const [productChecked, setProductChecked] = useState([]);
   const [preOrderInvoice, setPreOrderInvoice] = useState(false);
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [fullscreen, setFullscreen] = useState(true);


   window.onload = function () {
      document.addEventListener("contextmenu", function (e) {
         e.preventDefault();
      }, false);
   }

   //get details of the business
   const getBusinessById = async (statusParthners) => {
      try {
         const response = await BusinessFetchAPI.getBusinessById(business.id, token)
         console.log(response.data.data);
         setBusinessInfo(response.data.data);

         /*if (statusParthners == 1) {
            getParthners(response.data.data);
         } else {
            setStep(0)
         }*/

         setStep(0)

         localStorage.setItem('logo', response.data.data.logo)

      } catch (err) {
         console.log(err);
      }
   }

   //function get config 
   const getBusinessConfig = async () => {
      try {
         const response = await BusinessFetchAPI.getBusinessByActiveConfig(business.id, token);
         //console.log(response.data.data);
         setBackground(response.data.data.background)
         localStorage.setItem('businessConfig', JSON.stringify(response.data.data))

         setBusinessConfig(response.data.data);
      } catch (err) {
         console.log(err);
      }
   }




   //function get  publicity
   const getPublicity = async () => {
      try {
         const repsonse = await PublicityFechtAPI.getPublicity(machine.id, token);
         localStorage.setItem('publicity', JSON.stringify(repsonse.data.publicity));

      } catch (err) {
         console.log(err);
      }
   }

   //function get machine info
   const getInfoMachine = async (machine_id, statusParthnerMachine) => {
      try {
         const response = await MachinesFetchAPI.getmachineByid(machine_id, token)
         let infoMachineJson = response.data.data
         setInfoMachine(response.data.data)
         console.log(response.data.data);

         if (statusParthnerMachine == true) {
            let jsonNewMachine = {
               id: infoMachineJson.machine_per_branch_id,
               code: infoMachineJson.code,
               is_logged: 0,
               name_type_machine: infoMachineJson.name_type_machine,
               machine_type_id: infoMachineJson.machine_type_id,
               printers: infoMachineJson.printers,
               machine_config: infoMachineJson.machine_config
            }

            localStorage.setItem('machine', JSON.stringify(jsonNewMachine));
            machine = jsonNewMachine
            getPaymentMethod(machine.id, token)

         }

      } catch (err) {
         console.log(err);
      }
   }

   //function get promotions by branchoffice
   const getPromotionByBranchoffice = async (branchoffice_id, token) => {
      try {
         const response = await PromotionsFechtAPI.getPromotionsByBranchoffice(branchoffice_id, token)
         //filter promotion by status 1
         let dataTemp = [];
         response.data.promotions.forEach((promotionNow) => {
            if (promotionNow.status == 1) {
               dataTemp.push(promotionNow)
            }
         })

       
         setPromotions(dataTemp)
      } catch (err) {
         console.log(err);
      }
   }

   //function get payment method by machine
   const getPaymentMethod = async (machine_id, token) => {
      try {
         const response = await PaymentMethodFetchAPI.getPaymentMethod(machine_id, token);
         setPaymentMethod(response.data.data);

         //get last order machine
         lastOrderMachineRequest()

      } catch (error) {
         console.log(error);
      }
   }



   //function get segments of promotions
   const getSegmentsByPromotion = async (promotion) => {
      try {
         const response = await SegmentsFechtAPI.getSegmentsByPromotion(promotion.promotion_id, token)

         promotion.name = promotion.promotion_name
         promotion.description = promotion.promotion_description
         promotion.group_type = "infinite";
         promotion.preparation = 0;
         
         setSelectProduct({ group: promotion })
         setSegments(response.data.data)

         //next Step to the product info
         setStep(4);
      } catch (err) {
         console.log(err);
      }
   }

   //function get categories by brach office and by status
   const getCategoriesByBranchOffices = async (branchoffice_id, token) => {
      try {
         const response = await CategriesPosFetchAPI.getCategoriesByBranchOffices(branchoffice_id, 1, token);
         setCategories(response.data.data)
      } catch (error) {
         console.log(error);
      }
   }

   //function get products by branch offices and by status
   const getProductsByBranchOfficessByStatus = async (branchoffice_id, token) => {
      try {
         const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatus(branchoffice_id, 1, token)
         setProducts(response.data.data)
         setViewProducts(response.data.data)
         setTemporalProducts(response.data.data)

      } catch (err) {
         console.log(err);
      }
   }

   //function get product by id
   const getProductById = async (group) => {
      try {
         const response = await ProductsFetchAPI.getSegmentsByProducts(group.group_id, 1, branchoffice.id, token)
         setSelectProduct(response.data)
         setSegments(response.data.data)

         nextStep();
      } catch (err) {
         console.log(err);
      }
   }


   //get parthners of the business
   const getParthners = async (data) => {
      try {
         const response = await ParthnerFetchAPI.getBusinessParthners(token);
         //setParthners(response.data.parthners);
         let array = []
         array.push({ parthner_id: data.id, logo: data.logo, parthner_name: data.name })

         response.data.parthners.forEach((parthner) => {
            array.push(parthner)
         });

         setParthners(array);
         setStep(1);
      } catch (err) {
         setStep(0)
         console.log(err);
      }
   }

   //save and select parthner
   const selectRestaurant = (data) => {
      console.log(data);

      if (data.parthner_id == businessInfo.id) {

         if (localStorage.getItem('branchoffice_original') != null) {
            localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
            branchoffice = JSON.parse(localStorage.getItem('branchoffice_original'))
         }

         if (localStorage.getItem('machine_original') != null) {
            localStorage.setItem('machine', localStorage.getItem('machine_original'))
            machine = JSON.parse(localStorage.getItem('machine_original'))
         }


         getRequest()
      } else {


         let jsonNewParthner = { id: data.parthner_branchoffice_id, name: data.parthner_name, code: data.code, branchoffice_open: null, branchoffice_printer: null, branchoffice_support_phone: data.phone }

         branchoffice = jsonNewParthner
         localStorage.setItem('branchoffice', JSON.stringify(jsonNewParthner))

         if (data.parthner_branch_machine_id != null) {

            getInfoMachine(data.parthner_branch_machine_id, true)
         }

         getRequest()
      }

      function getRequest() {
         localStorage.setItem('logo', data.logo)
         getCategoriesByBranchOffices(branchoffice.id, token)
         getProductsByBranchOfficessByStatus(branchoffice.id, token)
         getPromotionByBranchoffice(branchoffice.id, token)

         getOrderNumber();
         //getInfoMachine();
         //getPrinter();
         setStep(0);
      }




   }


   //function get last id order of machine 
   const lastOrderMachineRequest = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let machine = JSON.parse(localStorage.getItem('machine'));
      try {
         const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);
         setLastOrder_id(response.data.last_order._id)
      } catch (err) {
         console.log(err)
      }
   }

   // get order number in process
   const getOrderNumber = async () => {
      try {
         const response = await OrderNumberFetchAPI.getOrderNumber(branchoffice.id, token)
         //console.log(response);
         setOrderNumber((parseInt(response.data.last_transaction_code) + 1));
      } catch (err) {
         console.log(err);
      }
   }

   //function filter products by category
   const selectCategorie = async (category_id, turn) => {

      console.log(branchoffice.id);
      if (category_id == "todos") {
         setProducts(temporalProducts)
         setViewProducts(temporalProducts)
      } else {
         try {
            const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatusByCategories(category_id, branchoffice.id, 1, token)
            setProducts(response.data.data)
            setViewProducts(response.data.data)

            //decision statement to move to the next step
            if (turn == true) {
               nextStep();
            }

         } catch (err) {
            setProducts([])
            setViewProducts([])
            //console.log("No hay productos aqui ");
            console.log(err);
         }
      }
   }

   let arrayPrintersTemp = []
   const getCategoriesByprinter = async (printer) => {

      try {
         const response = await CategriesPosFetchAPI.getCategoriesByPrinters(printer.printer_id, token)
         printer.categories = response.data.categories

      } catch (err) {
         printer.categories = []
         console.log(err);
      }
      arrayPrintersTemp.push(printer)

   }

   //get printers of the machine

   const getPrinter = async () => {
      try {
         const response = await PrinterFetchAPI.getPrinter(token, machine.id);
         //console.log(response);
         let arrayPrinters = response.data.printers;
         let printer_Comanda = "";
         let printer_ticket = "";
         let printer_zebra = "";
         for (let i = 0; i < arrayPrinters.length; i++) {
            getCategoriesByprinter(arrayPrinters[i])
            if (arrayPrinters[i].printer_type == 'Tickets') {
               printer_ticket = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Comanda') {
               printer_Comanda = arrayPrinters[i].printer_name
            } else if (arrayPrinters[i].printer_type == 'Zebra') {
               printer_zebra = arrayPrinters[i].printer_name;
            }
         }

         setPrinters(arrayPrintersTemp)
         setInfoPrinter({ printer_comanda: printer_Comanda, printer_ticket: printer_ticket, printer_zebra: printer_zebra });

      } catch (err) {
         console.log(err);
      }
   }

   //Function to calculate the invoice totals
   const calculateInvoiceTotals = (products) => {

      //For every new calculate we set the totals
      let taxesArray = [];
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //if we dont we products we put the totals in 0 for default
      if (products.length === 0) {
         //We calculate the totals of the invoice
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setTaxesArrayAll(taxesArray)
      } else {
         //For every product we calculate
         for (let i = 0; i < products.length; i++) {
            let product_info = products[i]
            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product
            product_total_discount = (product_info.unit_discount * product_info.amount);
            //We set the total of the product
            total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));
            
            
            //Calculate tax by group--------------------------------
            let taxesGroup = product_info.taxes;
            taxesGroup.forEach(tax => {

               //calculate tax  value and add it to array
               let valueTax = (product_info.amount * ((product_info.price - product_info.unit_discount) * tax.taxtValue));
               tax.total = valueTax;
               //Identifier tax of the group position
               var index = taxesArray.findIndex(function (info, i) {
                  return info.tax_id === tax.tax_id
               });

               if (index < 0) {
                  let taxInfoNow = {
                     "dTasaITBMSValue": tax.dTasaITBMSValue,
                     "percentage": tax.percentage,
                     "tax": tax.tax,
                     "tax_id": tax.tax_id,
                     "taxtValue": tax.taxtValue,
                     "total": valueTax
                  }
                  taxesArray.push(taxInfoNow);
               } else {
                  taxesArray[index].total = (taxesArray[index].total + valueTax)
               }
               total_product_itbms = (total_product_itbms + valueTax);

            });
            //-------------------------------------------------------

            
            //We set the itbms of the product
            //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

            //We calculate the totals of the invoice
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = invoiceSubtotal + total_product_price;
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
         }
         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);
         setTaxesArrayAll(taxesArray);
      }

      formatJsonOrder()
   }


   const formatJsonOrder = (payment, voucher) => {
      let dataPayment = payment
      let data_transaction_voucher = 0
      let table = JSON.parse(localStorage.getItem('table'))

      if (voucher != undefined) {
         data_transaction_voucher = voucher
      }

      if (!payment) {
         //console.log("entre aqui");
         dataPayment = { payment_id: 0, integration: { id: 0 } }
      }
      if (params == true) {
         let json = {
            "user_id": 0,
            "EMVStreamResponse": data_transaction_voucher,
            "transaction_code": orderNumber,
            "client_name": name,
            "id_Client": 0,
            "ruc": ruc,
            "dv": dv,
            "localizador": locator,
            "client_phone": clientPhone,
            "dgi_qr": "0",
            "Orden": shoppingCart,
            "Descuento": invoiceTotalDiscount,
            "Total": invoiceTotal.toFixed(2),
            "itbms": invoiceTotalItbms.toFixed(2),
            "taxes": taxesArrayAll,
            "subtotal": invoiceSubtotal.toFixed(2),
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": checkDeliveryOrder,
            "paymethod": dataPayment.payment_id,
            "paymethods": [],
            "paymenthod_integration_id": dataPayment.integration.id,
            "table_name": table.name,
            "table_id": table.id,
            "table_code": table.code,
         }

         localStorage.setItem('Detail_order', JSON.stringify(json));
      }
   }

   // create transacction in micros
   const createCheckMicrosOrder = async (ip, RvcRef, employeeNum, host_url, orderData, token) => {
      try {
         const response = await MicrosFetchAPI.createCheckMicros(ip, RvcRef, employeeNum, host_url, orderData, token);
         console.log(response);
      } catch (err) {
         console.log(err);
      }
   }

   const getPreOrderByTable = async () => {
      let table = JSON.parse(localStorage.getItem('table'))
      try {
         const response = await PreOrderFetchAPI.getTablePreOrder(table.code, business_code, branchoffice_code, token)
         console.log("data Preorder");
         console.log(response);

         setPreOrderInvoice(true)
         setPreOrder(response.data.orders[0])
         aplyPreOrder(response.data.orders[0])
         setTaxesArrayAll(response.data.orders[0].taxes)
      } catch (err) {
         setPreOrderInvoice(false)
         console.log(err);
      }
   }

   const aplyPreOrder = (selectPreOrder) => {
      //format json 
      for (let i = 0; i < selectPreOrder.groups.length; i++) {
         for (let s = 0; s < selectPreOrder.groups[i].segments.length; s++) {

            selectPreOrder.groups[i].segments[s].img = selectPreOrder.groups[i].segments[s].item.img
            selectPreOrder.groups[i].segments[s].item_id = selectPreOrder.groups[i].segments[s].item.item_id
            selectPreOrder.groups[i].segments[s].name = selectPreOrder.groups[i].segments[s].item.name
            selectPreOrder.groups[i].segments[s].segment_name = selectPreOrder.groups[i].segments[s].name
            selectPreOrder.groups[i].segments[s].cantidadItem = selectPreOrder.groups[i].segments[s].item.amount
            selectPreOrder.groups[i].segments[s].description = selectPreOrder.groups[i].segments[s].item.description
            selectPreOrder.groups[i].segments[s].tax = selectPreOrder.groups[i].segments[s].item.tax

         }
         selectPreOrder.groups[i].DetalleSegmentos = selectPreOrder.groups[i].segments
         selectPreOrder.groups[i].cantidadItem = selectPreOrder.groups[i].amount
         selectPreOrder.groups[i].descuento = selectPreOrder.groups[i].discount
         selectPreOrder.groups[i].preOrder = true
      }

      setInvoiceTotal(selectPreOrder.total)
      setInvoiceSubtotal(selectPreOrder.subtotal)
      setInvoiceTotalItbms(selectPreOrder.tax)
      setInvoiceTotalDiscount(selectPreOrder.discount)
      setShoppingCart(selectPreOrder.groups)
   }

   const createCheckMicrosOrderLocal = async (ip, RvcRef, employeeNum, host_url, orderData, token) => {
      let data = {
         Ruta: `http://${ip}:9472/api/micros/checks?RvcRef=${RvcRef}&employeeNum=${employeeNum}&host_url=${host_url}`,
         Type: "POST",
         Header: { "x-access-token": token },
         Body: orderData
      }
      console.log("entre a peticion local micros");

      try {
         const response = await RequestLocalFetchAPI.postMicros(data);
         console.log(response);
      } catch (err) {
         console.log(err);
      }

   }

   //Register order aprobed
   const registernewOrder = async () => {
      let token = localStorage.getItem('x-access-machine-token')

      //LoadingAlert("Registrando orden", "Espere un momento por favor...")

      if (registerTurnOrder == 0) {
         //setModalShowErrorRegisterOrder(false)
         let dataOrder = JSON.parse(localStorage.getItem('Detail_order'))

         try {

            const response = await OrderNumberFetchAPI.registerOrder(dataOrder, token)
            setDataTransaction_code(response.data.data.transaction_code)
            setOrderCode(response.data.data.order_code)
            //console.log(response);
            //setShow(false)
            //setResponseQr(true);
            //let title = "ORDEN: " + response.data.data.transaction_code
            //let text = "Total de la orden: $" + invoiceTotal.toFixed(2)

            //if (dataPreOrder.pre_Order == true) { deleteTempPreOrder() }
            //ConfirmationAlert(title, text, setPageLoader)

            //print order
            printOrder(response.data.data.order_id, response.data.data.transaction_code);
            //setTempMethodPayment(0);
            setRegisterTurnOrder(1);

            //type integration micros
            if (response.data.data.micros_params != null) {

               //params micros
               let micros_params = response.data.data.micros_params;
               let url = micros_params.host_url
               let stringUrl = url.split('/');
               let ip = stringUrl[2].split(':');

               //createCheckMicrosOrder(ip[0], micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token)
               createCheckMicrosOrderLocal(ip[0], micros_params.RvcRef, micros_params.employeeNum, micros_params.host_url, micros_params, token);
            } else {
               console.log("no hay parametros de micros");
            }

            //We show a success message when the order is approved when the type of payment is credit 
            //if (paymentInfo.payment_id === 10) {
            //SuccessAlert("Orden a credito generada", "Orden generada", "success")
            // } else {
            //SuccessAlert("Orden registrada con exito", "Orden generada", "success")
            //}

            //End transaction
            setStep(8)
         } catch (err) {
            //valid to id of transaction 
            try {
               const response = await MachinesFetchAPI.lastOrderMachine(machine.id, token);
               //console.log(response.data.last_order._id)


               if (response.data.last_order._id == lastOrder_id) {
                  //console.log("La transaccion no habia sido regitrada")
                  console.log(err);
                  registerTurnOrder = 0;
                  setRegisterTurnOrder(registerTurnOrder)
                  //ErrorAlert("", "Ocurrio un error", "error")
                  //setModalShowErrorRegisterOrder(true)
               } else {
                  //console.log("La transaccion ya habia sido regitrada")
                  setDataTransaction_code(response.data.last_order.transaction_code)
                  setOrderCode(response.data.last_order.order_code)
                  //console.log(response);
                  //setShow(false)
                  //setResponseQr(true);
                  //let title = "ORDEN: " + response.data.last_order.transaction_code
                  //let text = "Total de la orden: $" + total.toFixed(2)

                  //if (dataPreOrder.pre_Order == true) { deleteTempPreOrder() }
                  //ConfirmationAlert(title, text, setPageLoader)

                  //print order
                  printOrder(response.data.last_order.order_id, response.data.last_order.transaction_code);
                  //setTempMethodPayment(0);
                  setRegisterTurnOrder(1);

                  //We show a success message when the order is approved when the type of payment is credit 
                  if (paymentInfo.payment_id === 10) {
                     //SuccessAlert("Orden a credito generada", "Orden generada", "success")
                  } else {
                     //SuccessAlert("Orden registrada con exito", "Orden generada", "success")
                  }
               }

            } catch (err) {
               console.log(err)
            }
         }
      } else {
         console.log("Hay una orden en proceso");
      }
   }

   //print order
   const printOrder = async (id_Orden_Imprimir, transaction_code) => {
      let type_ticket = ""
      let imprimir_comanda = ""
      if (preOrderInvoice == true) {
         //type_ticket = "factura_precuenta"
         type_ticket = "factura"
         imprimir_comanda = "no"
      } else {
         type_ticket = "factura"
         imprimir_comanda = "si"
      }

      let arrayPrinters = []
      if (dataPreOrder.pre_Order == true) {
         printers.forEach((printer) => {
            if (printer.printer_type == "Tickets") {
               arrayPrinters.push(printer)
            }
         });

      } else {
         printers.forEach((printer) => {
            arrayPrinters.push(printer)
         });
      }

      let JsonImprimir = [{
         "type_ticket": type_ticket,
         "Printers": arrayPrinters,
         "Nombre_maquina_impresora": infoPrinter.printer_ticket,
         "Nombre_cocina_impresora": infoPrinter.printer_comanda,
         "Nombre_zebra_impresora": infoPrinter.printer_zebra,
         "Id_Orden_Imprimir": id_Orden_Imprimir,
         "token": token,
         "transaction_code": transaction_code,
         "amount_item": shoppingCart.length,
         "imprimir_comanda": imprimir_comanda,
      }]
      try {
         const response = await PrinterFetchAPI.printOrder(JsonImprimir, infoMachine.windows_ip)
         console.log(response);
      } catch (err) {
         console.log(err);
      }
   }

   //view categories
   const listCategories = () => {
      const listCategorie = categories.map((categorie) => (
         <>
            {/* <option value={categorie.category_id} key={categorie.category_id}  >{categorie.category_name}</option> */}
            {/* <a className="nav-link"> {categorie.category_name}</a> */}
            <li>
               <div className="button-container">
                  <input type="radio" className="btn-check" name="options-outlined" id={categorie.category_id} value={categorie.category_id} autocomplete="off" onChange={(e) => selectCategorie(e.target.value)} />
                  <label className="btn btn-outline-primary rounded-pill px-2" htmlFor={categorie.category_id}>
                     <img className='rounded-circle' src={categorie.img} alt="Imagen 1" />
                     {categorie.category_name}
                  </label>
               </div>
            </li>
         </>
      ));
      return (listCategorie)
   }

   //Filter the products by the term searched 
   const filteredProducts = useMemo(() => {
      if (searchTerm === '') {
         return products
      }
      return viewProducts.filter(product => {
         const nameMatch = product.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
         const descriptionMatch = product.description.toLowerCase().includes(searchTerm.toLowerCase());
         return nameMatch || descriptionMatch;
      })
   }, [searchTerm, products]);


   const updatePage = () => {
      window.location.reload();
   }

   const getOrdersSuccessTable = async () => {
      let machine = JSON.parse(localStorage.getItem('machine'));
      let table = JSON.parse(localStorage.getItem('table'));
      try {

         const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyTable(business_code, branchoffice_code, minDate, maxDate, 0, 0, machine.id, table.id, token)
         console.log("HISTORIAL DE LA MESA");
         console.log(response);

         let total = 0
         response.data.orders.forEach(transaction => {
           total = total + transaction.total
         });
         setHistoryTransaction({"orders":response.data.orders, "total": total})
      } catch (err) {
         console.log(err);
      }
   }

   //Hook to load the initial info
   useEffect(() => {
      (async () => {

         try {
            //------------------------------------------------
            if (branchoffice !== '' && branchoffice !== null && branchoffice !== undefined) {

               const responseBranchOfficeInfo = await BranchOfficeFetchAPI.getBranchOfficesByid(branchoffice.id, token)
               console.log(responseBranchOfficeInfo.data.data.branchoffice);
               localStorage.setItem('external_menu', responseBranchOfficeInfo.data.data.branchoffice.external_menu);
               //statusParthners = responseBranchOfficeInfo.data.data.branchoffice.external_menu

               getBusinessById(responseBranchOfficeInfo.data.data.branchoffice.external_menu);

               getCategoriesByBranchOffices(branchoffice.id, token)
               getProductsByBranchOfficessByStatus(branchoffice.id, token)
               getPromotionByBranchoffice(branchoffice.id, token)
               getPaymentMethod(machine.id, token)
               getOrderNumber();
               getInfoMachine(machine.id);
               getPrinter();
               getPreOrderByTable();
               getOrdersSuccessTable()


               //focus input scan code
               inputRefLog.current.focus()
            }
         } catch (err) {
            console.log(err);
         }



         setStep(external_menu);

         setShoppingCart([])
         setTaxesArrayAll([]);
         setPreOrderInvoice(false)
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setPageLoader(0)
         setCheckDeliveryOrder("comer aqui")
         setName("Cliente generico")
         setDv("00")
         setRuc("00000-00")
         setRegisterTurnOrder(0)
         setDataResponse(false)
         setOptionalData(false)
         setDataPreOrder({ pre_Order: false, _id: "" })
         setDetail_Payment_bank(0)
         setOrderNumber("0")
         setLastOrder_id("")
         formatJsonOrder()
         setPaymentApproval({ status: 0, description: "Procesando pago..." })
         getBusinessConfig();
         getPublicity();
         setAtivity(0);
         setPriority(0);


      })();
   }, [pageLoader]);

   //Hook to recharge data of the shopping cart
   useEffect(() => {
      formatJsonOrder()
      setShoppingCart([...shoppingCart]);
      calculateInvoiceTotals([...shoppingCart])
      setEventHandler(0);
   }, [eventHandler]);




   const nextStep = () => {
      setStep(step + 1);
   };

   const prevStep = () => {
      setStep(step - 1);

      console.log(step);
   };

   const updateData = (newData) => {
      setFormData(newData);
   };

   const submitForm = () => {
      // Aquí puedes enviar los datos a través de una función o API
      console.log('Datos enviados:', formData);
   };

   const skipToMenu = () => {
      // Ir directamente al Paso 3
      setStep(2);
   };

   const skipToStep3 = () => {
      // Ir directamente al Paso 3
      setStep(3);
   };

   //change input code scan
   const handleChange = (e) => {
      let arrayCodeString = ""
      if (e.key === 'Enter') {
         let inputValue = e.target.value
         arrayCodeString = inputValue.split("X");

         if (business.code == arrayCodeString[3] && branchoffice.code == arrayCodeString[2] && machine.code == arrayCodeString[1]) {

            setShowModalInfoMachine(true)
            document.getElementById('inputCodeQrData').value = ""
         } else {
            ErrorAlert('', 'Codigo incorrecto', 'error')
            document.getElementById('inputCodeQrData').value = ""
         }
      }
   }


   useEffect(() => {
      // Establecer un intervalo
      const intervalId = setInterval(() => {
         // Validar el valor actualizado del contador con una instrucción if

         if (priority == 0) {
            switch (activity) {
               case 0:
                  // activity 0 determines that there is no activity
                  if (localStorage.getItem('branchoffice_original') != null) {
                     localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
                  }

                  if (localStorage.getItem('machine_original') != null) {
                     localStorage.setItem('machine', localStorage.getItem('machine_original'))
                  }
                  //navigate(`/kiosk/publicity`);
                  break;
               case 1:
                  // activity 1 determines that there is navigation activity
                  console.log('REINICIAR');
                  setAtivity(0);
                  break;
               case 2:
                  break;
               case 5:
                  setAtivity(6);
                  break;
               case 6:
                  setAtivity(7);
                  break;
               case 7:
                  setAtivity(1);
                  break;
            }

         } else {

            //priority event
            //console.log(" hay prioridad");
            if (activity == 3) {//actividad 3 determina que a finalizado la transaccion
               setAtivity(4);
            } else if (activity == 4) {//activity 4 redirects to the first step and restarts the transaction
               setAtivity(0);
               setPageLoader(1)
               setPriority(0)
               updatePage();
            }
         }
      }, 60000);

      // Limpiar el intervalo al desmontar el componente
      return () => clearInterval(intervalId);
   }, [activity, priority]); // Agrega 'contador' como dependencia para que useEffect se ejecute cada vez que el contador cambie


   return (
      <>
         {/* Kiosk main container,shopping steps */}
         <main className='kiosk  position-relative '
         // onClick={() => inputRefLog.current.focus()}
         >

            {step === 0 && (
               <Dashboard
                  icon={hand_1}
                  background={background}
                  data={formData}
                  updateData={updateData}
                  nextStep={nextStep}
                  skipToStep3={skipToStep3}
                  setCheckDeliveryOrder={setCheckDeliveryOrder}
                  statusLanguage={statusLanguage}
                  setStatusLanguage={setStatusLanguage}
                  setAtivity={setAtivity}
                  parthners={parthners}
                  setStep={setStep}
                  preOrder={preOrder}
                  preOrderInvoice={preOrderInvoice} />
            )}
            {step === 1 && (
               <Restaurants
                  icon={hand_1}
                  background={background}
                  parthners={parthners}
                  updateData={updateData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  promotions={promotions}
                  getSegmentsByPromotion={getSegmentsByPromotion}
                  setAtivity={setAtivity}
                  selectRestaurant={selectRestaurant}
                  setStep={setStep}
                  businessInfo={businessInfo}
               />
            )}

            {step === 2 && (
               <Categories
                  icon={hand_1}
                  background={background}
                  setCategorieSelect={setCategorieSelect}
                  categories={categories}
                  data={formData}
                  updateData={updateData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  selectCategorie={selectCategorie}
                  promotions={promotions}
                  getSegmentsByPromotion={getSegmentsByPromotion}
                  setAtivity={setAtivity}
                  setStep={setStep}
                  shoppingCart={shoppingCart}
                  parthners={parthners}
               />
            )}
            {step === 3 && (
               <Products
                  icon={hand_1}
                  background={background}
                  selectCategorie={selectCategorie}
                  categorieSelect={categorieSelect}
                  data={formData}
                  categories={categories}
                  products={filteredProducts}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  getProductById={getProductById}
                  setAtivity={setAtivity}
                  setCategorieSelect={setCategorieSelect} />
            )}
            {step === 4 && (
               <InfoProduct
                  icon={hand_1}
                  background={background}
                  data={formData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  selectProduct={selectProduct}
                  segments={segments}
                  amountGroupSelect={amountGroupSelect}
                  setAmountGroupSelect={setAmountGroupSelect}
                  shoppingCart={shoppingCart}
                  setShoppingCart={setShoppingCart}
                  calculateInvoiceTotals={calculateInvoiceTotals}
                  setAtivity={setAtivity} />
            )}

            {step === 5 && (
               <OrderSummary
                  icon={hand_1}
                  background={background}
                  data={formData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  linkMenu={skipToMenu}
                  shoppingCart={shoppingCart}
                  setShoppingCart={setShoppingCart}
                  invoiceTotal={invoiceTotal}
                  invoiceTotalItbms={invoiceTotalItbms}
                  invoiceSubtotal={invoiceSubtotal}
                  invoiceTotalDiscount={invoiceTotalDiscount}
                  setEventHandler={setEventHandler}
                  setPageLoader={setPageLoader}
                  setLocator={setLocator}
                  setClientPhone={setClientPhone}
                  clientPhone={clientPhone}
                  formatJsonOrder={formatJsonOrder}
                  business={business}
                  setAtivity={setAtivity}
                  parthners={parthners}
                  preOrderInvoice={preOrderInvoice}
                  preOrder={preOrder}
                  setStep={setStep}
                  taxesArrayAll={taxesArrayAll}


               />
            )}
            {step === 6 && (
               <Payments
                  icon={hand_1}
                  background={background}
                  data={formData}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  submitForm={submitForm}
                  paymentMethod={paymentMethod}
                  setPageLoader={setPageLoader}
                  paymentApproval={paymentApproval}
                  setPaymentApproval={setPaymentApproval}
                  paymentInfo={paymentInfo}
                  setPaymentInfo={setPaymentInfo}
                  shoppingCart={shoppingCart}
                  formatJsonOrder={formatJsonOrder}
                  setDetail_Payment_bank={setDetail_Payment_bank}
                  setStep={setStep}
                  registernewOrder={registernewOrder}
                  ruc={ruc}
                  setRuc={setRuc}
                  name={name}
                  setName={setName}
                  dv={dv}
                  setDv={setDv}
                  setAtivity={setAtivity}
                  setPriority={setPriority}

               />
            )}
            {step === 7 && (
               <StatusPayment
                  icon={hand_1}
                  background={background}
                  data={formData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  invoiceTotal={invoiceTotal}
                  invoiceTotalItbms={invoiceTotalItbms}
                  invoiceSubtotal={invoiceSubtotal}
                  invoiceTotalDiscount={invoiceTotalDiscount}
                  paymentApproval={paymentApproval}
                  paymentInfo={paymentInfo}
               />
            )}
            {step === 8 && (
               <EndTransaction
                  icon={hand_1}
                  background={background}
                  data={formData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  invoiceTotal={invoiceTotal}
                  order_code={order_code}
                  paymentInfo={paymentInfo}
                  setPageLoader={setPageLoader}
                  setAtivity={setAtivity}
               />
            )}
            {step === 9 && (
               <CreateAndUpdatePreOrderEnd
                  icon={hand_1}
                  background={background}
                  data={formData}
                  prevStep={prevStep}
                  nextStep={nextStep}
                  invoiceTotal={invoiceTotal}
                  preOrderInvoice={preOrderInvoice}
                  setPageLoader={setPageLoader}
                  setAtivity={setAtivity}
               />
            )}

            {step === 10 && (
               <HistoryTransaction
                  icon={hand_1}
                  background={background}
                  data={formData}
                  nextStep={nextStep}
                  skipToStep3={skipToStep3}
                  setStep={setStep}
                  preOrder={preOrder}
                  preOrderInvoice={preOrderInvoice}
                  historyTransaction={historyTransaction}
               />
            )}



            {/* {step === 7 && (
               <Notifier
                  data={formData}
                  prevStep={prevStep}
                  nextStep={nextStep} />
            )}*/}

            <div className='position-absolute'>
               <input id="inputCodeQrData" className='input-sp-qr w-25' ref={inputRefLog} inputMode="none" type="text" onKeyPress={(e) => handleChange(e)} autoFocus></input>
            </div>
         </main>
         <ModalInfoMachine showModalInfoMachine={showModalInfoMachine} setShowModalInfoMachine={setShowModalInfoMachine} infoMachine={infoMachine} business={business} branchoffice={branchoffice} ></ModalInfoMachine>
         {/**This is for the bot 
                <SupportBot ></SupportBot>*/}
      </>
   )
}

export default KioskMainEcommers