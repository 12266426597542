import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import QRCode from "react-qr-code";
import ErrorAlert from '../Alerts/ErrorAlert';
import imgCheck from "./img/check.png"

function ModalConfirmation({ setResponseQr, setPageLoader,orderCode, orderNumber, total, change, setChange}) {
    const [show, setShow] = useState(true);
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let routeQr = "https://smartpay.ai/order/" + orderCode + "/branchoffice/" + branchoffice.id

   
    const newOrder = () => {
        let message = "Gracias por su compra."
        let title = "Transaccion exitosa"
        let icon = 'success'

        setChange(0)
        setPageLoader(1)
        setResponseQr(false)
        ErrorAlert(message, title, icon)
    }
    console.log(routeQr);

    return (
        <>
            <Modal
                show={show}
                size="md"
                onHide={() => setShow(false)}
                backdrop="static"
                centered
            >

                <Modal.Body>
                    <div className="m-4 d-flex align-items-center justify-content-center ">
                        <QRCode value={routeQr} size={150} bgColor="#282c34" fgColor="#fff" level="H" />
                    </div>
                    <div className="bg-light border-sp d-flex align-items-center justify-content-center ">
                        <img className='m-2' src={imgCheck} width="60px"></img>
                        <div >
                            <h1 className='text-center'>Orden: {orderNumber}</h1>
                            <p className='text-center'>Total de la orden: <p translate='no'>${total.toFixed(2)}</p></p>
                             {change > 0? <><h3 className='text-center text-danger'>Total del cambio: <p translate='no'>${change}</p></h3></> : ""}
                        </div>

                    </div>

                    <div className="m-3 d-flex align-items-center justify-content-center ">
                        <Button variant='success btn-lg' onClick={() => newOrder()}>Nueva orden</Button>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    )
}

export default ModalConfirmation