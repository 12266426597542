//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

//----Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Tabs, Tab } from 'react-bootstrap';
import TransactionPill from "./TransactionPill";
import TransactionHistory from "./TransactionHistory";
import Navbar from '../NavBar/Navbar';
import Modal from 'react-bootstrap/Modal';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import OrderDetailsTemporal from '../Orders/OrderDetailsTemporal';
import OrderDetailPreOrder from '../Orders/OrderDetailPreOrder';
import OrderDetails from '../Orders/OrderDetails';

//------APIS
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { MachinesFetchAPI } from '../../api/Machines';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';
import { OrdersFetchAPI } from '../../api/Orders';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';

//----IMG
import cashback from '../img/icons/cashback.png'
import refund from '../img/icons/refund.png'
import deleted from '../img/icons/deleted.png'
import success from '../img/icons/success.png'
import empty from '../img/icons/empty.png'

//External modules 
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

function TransactionsTemporal() {
   //Params
   let { business_code } = useParams();
   //We get the user data 
   let user = JSON.parse(localStorage.getItem('user'));
   let token = localStorage.getItem('x-access-token');
   //States
   const [branchOrdersData, setBranchOrdersData] = useState([]);
   const [temporal, setTemporal] = useState([]);
   const [userBranchoffices, setUserBranchs] = useState([]);
   const [userBusiness, setUserBusiness] = useState({});
   const [selectedBranch, setSelectedBranch] = useState({
      code: user.branchoffices[0].code
   });
   const [temporalOrdersData, setTemporalOrdersData] = useState([]);
   const [temporalOrdersDeletedData, setTemporalOrdersDeletedData] = useState([]);
   const [temporalOrdersSuccessData, setTemporalOrdersSuccessData] = useState([]);
   const [detailOrder, setDetailOrder] = useState();
   const [order_type, setOrderType] = useState("temporal")
   const [modalShow, setModalShow] = useState(false);
   const [reload, setReLoad] = useState();
   const [orderData, setOrderData] = useState({});
   const [minDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
   const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00');
   const [statusDetailModalDinamic, setStatusDetailDinamic] = useState(true);
   const [paymentInfo, setPaymentInfo] = useState({});
   const [branchOfficeDataOrder, setBranchofficeDataOrder] = useState({
      "ruc": "",
      "dv": "",
      "name": "",
      "ruc_type": "",
      "direction": "",
      "dgi_name": "",
   });


   //Variables 
   //We get the actual date 
   const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')



   //Functions to show and hide the modal 
   const handleCloseModal = () => setModalShow(false);
   const handleShowModal = () => setModalShow(true);

   //Function to populate the users branch 
   const branchList = () => {
      const listBracnh = userBranchoffices.map((branch, index) => (
         <>
            <option value={branch.code}>{branch.branchoffice_name}</option>
         </>
      ));
      return listBracnh
   }

   //Function to gen the empty card to the transaction history 
   const EmptyCard = () => {
      return (
         <div className="m-3 w-auto ">
            <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                  <img src={empty} alt="Vacio" width="35px" height="35px" />
                  <div className='text-center mt-3'>
                     <h5>Vacío</h5>
                     <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                  </div>
               </div>
            </div>
         </div>
      )
   }



   /*---------------------------------------------PRE ORDERS PENDING------------------------------------------------------------------------------------------------ */

   //get pre orders
   const getPreOrder = async (business_code, branch_code, token) => {
      try {
         const response = await PreOrderFetchAPI.getPreOrder(business_code, branch_code, token)
         console.log(response.data.pre_orders);

         let dataPreOrder = response.data.pre_orders

         let newArrayPreorder = []
         dataPreOrder.forEach(order => {
            if (selectedBranch.code == order.branchoffice.code) {
               newArrayPreorder.push(order)
            }
         });

         setBranchOrdersData(newArrayPreorder);
         //This are for the discount temporal orders 
         setTemporalOrdersData(newArrayPreorder);
         //This are for the search filter
         setTemporal(newArrayPreorder);
      } catch (err) {
         console.log(err);
         setBranchOrdersData([]);
         //This are for the discount temporal orders 
         setTemporalOrdersData([]);
         //This are for the search filter
         setTemporal([]);
         //ErrorAlert('', 'No hay precuentas abriertas', 'info');
      }
   }

   //Function to populate the temporal list orders 
   const listTemporalOrders = () => {
      const temporalOrders = temporalOrdersData.map((data) => (
         <>

            <div onClick={() => { setOrderData(data); setStatusDetailDinamic(true); handleShowModal() }} >

               <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white">
                  <div className="flex-shrink-0 p-2">
                     <img src={refund} alt="En progreso" width="41" height="40" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                     <div className=' d-flex justify-content-between mt-2 me-2'>
                        <h5 className="mb-0 text-muted">Orden #{data.code} </h5>
                        <h5 className='mt-1'>Total:<strong > {data.total.toFixed(2)} $</strong ></h5>
                     </div>
                     <span className="text-nowrap text-primary h6 d-block mb-2">
                        <i class="uil uil-user-circle"></i>&nbsp;
                        Cliente: {data.client_name}
                     </span>

                     <div className='mt-1 me-2 d-flex justify-content-between'>
                        <span className="text-nowrap text-secondary h6">
                           <i className="fa-solid fa-store"></i>&nbsp;
                           Sucursal: {data.branchoffice.name}
                        </span>

                        <span className="text-nowrap text-secondary h6">
                           <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                           Máquina: {data.machine_code}
                        </span>
                     </div>
                     <span className="text-nowrap text-primary h6 d-block mb-2">
                        <i className="fa-solid fa-cash-register"></i>&nbsp;
                        {!data.machine_name ? 'No es caja' : data.machine_name} / {!data.user ? 'No es cajera' : data.user.name}
                     </span>
                     <span className="text-nowrap text-secondary h6 d-block ">
                        <i className="uil uil-book-medical">Creada: </i>
                        <span className='text-dark'>
                           <i class="uil uil-calendar-alt"></i>{" " + data.createdAt.split("T")[0] + " / "}
                        </span>
                        <span className='text-primary'>
                           <i class="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                        </span>
                     </span>

                  </div>
               </div>
            </div>

         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }

   //update preOrders of the branchoffice
   const handlerUpdatePreOrder = () => {
      getPreOrder(business_code, selectedBranch.code, token);
   }


   /*---------------------------------------DELETED TRANSACTIONS------------------------------------------------------------------------------------------------------ */


   // function get order deleted
   const getDeleteTemporal = async () => {
      try {
         const response = await DeletedTempOrdersFetchAPI.getDeletedTransactionReport(business_code, selectedBranch.code, minDate, maxDate, user.id, token);
         console.log(response.data.temp_orders);


         let dataOrders = response.data.temp_orders;

         let dataSuccess = []
         let dataDeleted = []
         dataOrders.forEach((item) => {
            if (item.deleted == true) {
               dataDeleted.push(item)
            } else {
               dataSuccess.push(item);
            }
         });

         setTemporalOrdersDeletedData(dataDeleted);
         setTemporalOrdersSuccessData(dataSuccess)

      } catch (err) {
         console.log(err);
         setTemporalOrdersDeletedData([]);
      }

   }

   //Function to populate the temporal list orders deleted
   const listTemporalOrdersDeleted = () => {
      const temporalOrders = temporalOrdersDeletedData.map((data) => (
         <>

            <div onClick={() => { setStatusDetailDinamic(false); getDeleteOrderTemporalById(data._id); }} >

               <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white">
                  <div className="flex-shrink-0 p-2">
                     <img src={deleted} alt="En progreso" width="41" height="40" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                     <div className=' d-flex justify-content-between mt-2 me-2'>
                        <h5 className="mb-0 text-muted">Orden #{data.code} </h5>
                        <h5 className='mt-1'>Total:<strong > {data.total.toFixed(2)} $</strong ></h5>
                     </div>

                     <span className="text-nowrap text-primary h6 d-block mb-2">
                        <i className="fa-solid fa-cash-register"></i>&nbsp;
                        {data.user.name}
                     </span>

                     <span className="text-nowrap text-secondary h6 d-block ">
                        <i class="uil uil-book-medical">Creada: </i>
                        <span className='text-success'>
                           <i class="uil uil-calendar-alt"></i>{" " + data.createdAt.split("T")[0] + " / "}
                        </span>
                        <span className='text-primary'>
                           <i class="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                        </span>
                     </span>

                     <span className="text-nowrap text-secondary h6 d-block">
                        <i class="uil uil-trash-alt">Eliminada: </i>
                        <span className='text-danger'>
                           <i class="uil uil-calendar-alt"></i>{" " + data.deletedAt.split("T")[0] + " / "}
                        </span>
                        <span className='text-primary'>
                           <i class="uil uil-clock"></i>{" Hora: " + data.deletedAt.split("T")[1].split(".")[0]}
                        </span>
                     </span>

                  </div>
               </div>
            </div>

         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }

   // get Order deleted by id
   const getDeleteOrderTemporalById = async (_id) => {
      try {
         const response = await DeletedTempOrdersFetchAPI.getDeletedTransactionById(_id, token);
         //get info machine by id
         const response_Data_machine = await MachinesFetchAPI.getmachineByid(response.data.deleted_order.machine_id, token)

         let dataOrder = response.data.deleted_order

         //add param machine name to the order object
         dataOrder.machine_name = response_Data_machine.data.data.machine_name
         setOrderData(dataOrder);



         handleShowModal()
      } catch (err) {
         console.log(err);
      }
   }

   /*---------------------------------------PRE ORDER PAYMENT------------------------------------------------------------------------------------------------------ */

   //Function to populate the temporal list orders deleted
   const listTemporalOrdersSuccess = () => {
      const temporalOrders = temporalOrdersSuccessData.map((data) => (
         <>

            <div onClick={() => { setStatusDetailDinamic(false); getTransactioByCode(data.finished_order_reference.transaction_code) }} >

               <div className="d-flex align-items-center border-history shadow-sm mt-3 mx-1 bg-white">
                  <div className="flex-shrink-0 p-2">
                     <img src={success} alt="En progreso" width="41" height="40" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                     <div className=' d-flex justify-content-between mt-2 me-2'>
                        <h5 className="mb-0 text-muted">Orden #{data.code} </h5>
                        <h5 className='mt-1'>Total:<strong > {data.total.toFixed(2)} $</strong ></h5>
                     </div>

                     <span className="text-nowrap text-primary h6 d-block mb-2">
                        <i className="fa-solid fa-cash-register"></i>&nbsp;
                        {data.user.name}
                     </span>

                     <span className="text-nowrap text-secondary h6 d-block ">
                        <i className="uil uil-book-medical">Creada: </i>
                        <span className='text-dark'>
                           <i class="uil uil-calendar-alt"></i>{" " + data.createdAt.split("T")[0] + " / "}
                        </span>
                        <span className='text-primary'>
                           <i class="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                        </span>
                     </span>

                     <span className="text-nowrap text-secondary h6 d-block">
                        <i className="uil uil-file-check-alt">Cobrado: </i>
                        <span className='text-success'>
                           <i class="uil uil-calendar-alt"></i>{" " + data.deletedAt.split("T")[0] + " / "}
                        </span>
                        <span className='text-primary'>
                           <i class="uil uil-clock"></i>{" Hora: " + data.deletedAt.split("T")[1].split(".")[0]}
                        </span>
                     </span>

                  </div>
               </div>
            </div>

         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }


   const getTransactioByCode = async (transaction_code) => {
      try {
         const response = await OrdersFetchAPI.getTransactionByCode(transaction_code, business_code, selectedBranch.code, token)
         let dataOrder = response.data.order
         //get info machine by id
         const response_Data_machine = await MachinesFetchAPI.getmachineByid(response.data.order.machine_id, token)

         //We get the branchoffice info 
         const response_branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(dataOrder.business.code, dataOrder.branchoffice.code);
         //add param machine name to the order object
         setBranchofficeDataOrder(response_branch.data.branchoffice)
         dataOrder.machine_name = response_Data_machine.data.data.machine_name
         dataOrder.paymentInfo = response.data.paymentmetod
         setOrderData(response.data.order);
         handleShowModal()
      } catch (err) {
         console.log(err);
      }
   }



   //Hook to charge data when the page is loaded 
   useEffect(() => {
      (async () => {
         try {
            //We show the loading alert 
            //LoadingAlert();
            //We set the branchoffices of the user 
            setUserBranchs(user.branchoffices);
            //
            //We set the business user 
            setUserBusiness(user.business);
            //We search the branch orders for default we send 0 to show all 
            //getTransactionsByBranchOffice(business_code, selectedBranch.code, actualDate, maxDate);

            if (selectedBranch.code != 0) {
               getPreOrder(business_code, selectedBranch.code, token)
               getDeleteTemporal()
            } else {
               setBranchOrdersData([]);
               //This are for the discount temporal orders 
               setTemporalOrdersData([]);
               //This are for the search filter
               setTemporal([]);
               Swal.fire({
                  title: "Debes seleccionar una sucursal",
                  text: "No hay sucursal seleccionada",
                  icon: "warning"
               });
            }

            //We show the success alert 

         } catch (err) {
            console.log(err);
         }

      })();
      //We set the load state 
      setReLoad(false)
   }, [reload, selectedBranch])



   return (

      <>
         <Navbar />
         <div className='container'>
            <div className='smart-pay-main'>
               <div className=' d-flex justify-content-center'>
               </div>
            </div>
            <Row className='justify-content-md-center'>
               {/* Branchoffice */}
               <Col sm={12} md={12}>
                  <div className="">
                     <div className="container-header-sp p-3 my-3 text-dark border border-1 rounded shadow-sm">
                        {/* <img className="me-3" src="/docs/5.3/assets/brand/bootstrap-logo-white.svg" alt="" width="48" height="38"> */}
                        <div className="lh-1">
                           <h1 className="h5 mb-0 text-dark lh-1 fw-bold"><i class="uil uil-invoice"></i> Precuentas</h1>
                        </div>

                        <form className='filter-sp-trn'>
                           <div className="d-block">
                              <select onChange={e => setSelectedBranch({ code: e.target.value })} className='form-select' name="user_branch" id="user_branch">
                                 {branchList()}
                              </select>
                           </div>
                        </form>
                     </div>


                     {/* */}
                  </div>
               </Col>


               <div className="container ">
                  {/**Tabs of page  */}
                  <Tabs defaultActiveKey="OrderPending" variant="pills" className='rounded d-flex justify-content-center align-item-center'>

                     {/**Tab of products */}
                     <Tab eventKey="OrderPending" title="Precuentas pendientes">
                        <div className='mx-lg-3 mt-4'>
                           <div className=' payment-smart mx-3'>
                              <div className='text-center'>
                                 <h4 className='fs-4 fw-bold'>Precuentas pendientes</h4>
                              </div>

                              <div className='transaction-wrapper border border-history bg-light'>
                                 {/* Boton para actualizar las ordenes */}
                                 <div className="  w-auto bg-white px-3 py-2 rounded" onClick={e => getPreOrder(business_code, selectedBranch.code, token)} >
                                    <div className="border-0  d-flex justify-content-center " aria-current="true">
                                       <div className=' btn btn-light btn-upadate fw-bold m-0 px-3' >
                                          <i className="uil uil-sync"></i>
                                          <small> Actualizar </small>
                                       </div>
                                    </div>
                                 </div>
                                 {/* <Empty /> */}
                                 <div className=''>
                                    {/* Paso de data al componente */}
                                    {listTemporalOrders()}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Tab>
                     {/**Tab of pre order deleted */}
                     <Tab eventKey="OrderDelete" title="Precuentas eliminadas">
                        <div className='mx-lg-3 mt-4'>


                           <div className=' payment-smart mx-3'>
                              <div className='text-center'>
                                 <h4 className='fs-4 fw-bold'>Precuentas eliminadas</h4>
                              </div>

                              <div className='transaction-wrapper border border-history bg-light'>
                                 {/* Boton para actualizar las ordenes */}


                                 <div className="  w-auto bg-white px-3 py-2 rounded"  >


                                    <div className='mt-3 '>
                                       <div className="login-form needs-validation d-flex justify-content-start ">
                                          <div className="" >
                                             <h4 className='text-center m-2'>Fecha:</h4>
                                          </div>
                                          <div className="">
                                             <input type="date" name="date" id=""
                                                onChange={e => (setMinDate(e.target.value + "T00:00:00.000+00:00"), setMaxDate(e.target.value + "T23:59:59.000+00:00"))} className="control" />
                                          </div>
                                          <div className="">
                                             <button className="btn btn-success rounded" id="btn-analitycs" onClick={async () => getDeleteTemporal()}>Aceptar</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 {/* <Empty /> */}
                                 <div className=''>
                                    {/* Paso de data al componente */}
                                    {listTemporalOrdersDeleted()}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Tab>
                     {/**Tab of pre order payment */}
                     <Tab eventKey="OrderSuccess" title="Precuentas realizadas">
                        <div className='mx-lg-3 mt-4'>


                           <div className=' payment-smart mx-3'>
                              <div className='text-center'>
                                 <h4 className='fs-4 fw-bold'>Precuentas realizadas</h4>
                              </div>

                              <div className='transaction-wrapper border border-history bg-light'>
                                 {/* Boton para actualizar las ordenes */}


                                 <div className="  w-auto bg-white px-3 py-2 rounded"  >


                                    <div className='mt-3 '>
                                       <div className="login-form needs-validation d-flex justify-content-start ">
                                          <div className="" >
                                             <h4 className='text-center m-2'>Fecha:</h4>
                                          </div>
                                          <div className="">
                                             <input type="date" name="date" id=""
                                                onChange={e => (setMinDate(e.target.value + "T00:00:00.000+00:00"), setMaxDate(e.target.value + "T23:59:59.000+00:00"))} className="control" />
                                          </div>
                                          <div className="">
                                             <button className="btn btn-success rounded" id="btn-analitycs" onClick={async () => getDeleteTemporal()}>Aceptar</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 {/* <Empty /> */}
                                 <div className=''>
                                    {/* Paso de data al componente */}
                                    {listTemporalOrdersSuccess()}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Tab>
                  </Tabs>
                  {/**This is for the bot */}

               </div >
            </Row>
         </div>
         {/* Modal */}
         <Modal show={modalShow} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <Modal.Title>Detalles</Modal.Title>
            </Modal.Header>
            <Modal.Body>

               <OrderDetailPreOrder orderData={orderData} userBranchoffices={userBranchoffices} handlerUpdatePreOrder={handlerUpdatePreOrder} handleCloseModal={handleCloseModal} statusDetailModalDinamic={statusDetailModalDinamic} branchOfficeData={branchOfficeDataOrder}></OrderDetailPreOrder>

            </Modal.Body>
         </Modal>
      </>
   )
}

export default TransactionsTemporal