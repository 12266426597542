import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class PublicityFechtAPI {

        static async getPublicity (machine_id, token, ) {
            const res = await server(`/machine/${machine_id}/publicity/status?status=1`, {
                method: "GET",
                headers: {
                    "x-access-token": token
                }
            });
            return res 
        }

    
}