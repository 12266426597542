import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class PreOrderFetchAPI {

    static async createPreOrder(data, token) {
        const res = await server(`/temp/order`, {
            method: "post",
            data: data,
            headers: {'x-access-token': token},
        });
        return res
    }

    static async getPreOrder(business_code, branch_code, token) {
        const res = await server(`/pre_order/temp/machine/order/${business_code}/${branch_code}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    static async getPreOrderById(_id, token) {
        const res = await server(`/temp/order/${_id}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }
    


    static async updateOrderTempPreOrder(data, token) {
        const res = await server(`/temp/order`, {
            method: "put",
            data: data,
            headers: {'x-access-token': token},

        });
        return res
    }

    static async deletePreOrder(data, token) {
        const res = await server(`/temp/order`, {
            method: "delete",
            data: data,
            headers: {'x-access-token': token},
        });
        return res
    }

    static async getTablePreOrder(table_code, business_code, branchoffice_code,  token) {
        const res = await server(`/temp/table/order/${table_code}/${business_code}/${branchoffice_code}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }
}