import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Index from './pages/Index'
// import logo from './logo.svg';

//Components
import LandingPage from './components/_index';
import Team from './components/landing/team/Team';
import NoMatch from "./components/layout/NoMatch";
import Menu from "./components/menu/Menu";
import Order from './components/Order';
import ScanQr from './components/ScanQr/ScanQr';
import Login from './components/login/Login';
import LoginCashier from './components/login/LoginCashier';
import ShoppingCart from "./components/shopping_cart/ShoppingCart";
import RequestPayment from "./components/request_payment/RequestPayment";
import Biller from "./components/biller/Biller";
import Transactions from './components/transactions/Transactions';
import TransactionsTemporal from "./components/transactions/TransactionTemporal";
import Publicity from "./layouts/Kiosk/Publicity.jsx";

import Buys from "./components/Buys/Buys";

import AnaliticStore from "./components/analitics/AnaliticStore";
import ReportStore from "./components/reports/ReportStore";
import ListOrders from "./components/lists_orders/ListOrders";
//Vending 
import Vending from './pages/Vending.jsx'
//API 
import { AuthFetchAPI } from "./api/Auth";

//context 
import UserContext from "./context/UserContext";
import ProductStore from "./components/products/ProductStore";
import MachineList from './components/cahsier/MachineList';
import CashierReport from "./components/cahsier/CashierReport";
import OrderCustomers from "./components/order_customers/CustomersOrders";
import PaymentPost from "./components/PaymentPost/PaymentPost";
import SmartpayWebhook from './components/Webhook/Webhook.jsx'
import ModalPaymet from "./components/PaymentPost/ModalPayMet"
import TableOrder from "./components/table_order/TableOrder";
import PageConfirmation from "./components/table_order/PageConfirmation";
import OrderList from "./components/table_order/OrdersList";
import Camera from "./components/Camera"
import AnaliticsPayment from "./components/PaymentPost/AnaliticsPayment";
import ListOrdersStatus from "./components/Status_orders/ListOrdersStatus";
import PosPayment from "./layouts/PostPayments/PosPayment";
import PdfMake from "./pages/PdfMake";
import Users from "./components/Users/Users.jsx";

//Pre Account
import PreAccount from "./components/PaymentPost/PreAccount";
import PreAccountLogin from "./layouts/PreAccount/PreAccountLogin";
import AdminPreAccount from './layouts/PreAccount/AdminPreAccount'

//Integrations managment 
import IntegrationManager from "./components/integrations/IntegrationManager.jsx";

//Examples
import PayExmple from "./components/PaymentPost/PayExmple";
import Example from "./pages/ExampleGif";

//CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/js/bootstrap.esm'
import './App.css';
import './index.css';
import Kds from "./pages/Kds";
import NinyappTransaction from "./components/PaymentPost/NinyappTransaction";
import KioskMain from "./layouts/Kiosk/KioskMain.jsx";
import KioskMainEcommers from "./layouts/Kiosk_ecommers_stant/KioskMainEcommers.jsx";
import LoginKioskMachine from "./layouts/Kiosk/LoginKioskMachine.jsx";
import LoginKioskMachineEcommerStant from "./layouts/Kiosk_ecommers_stant/LoginKioskMachineEcommerStant.jsx";

//Icons
import 'bootstrap-icons/font/bootstrap-icons.css'
import * as Unicons from '@iconscout/react-unicons';
import SupportAdmin from "./components/SupportBot/SupportAdmin/SupportAdmin.jsx";






function App() {
  const [userData, setUserData] = useState({
    token: undefined,
    user: undefined
  });
  const checkLogin = async () => {
    let token = localStorage.getItem('x-access-token');

    //esta variable sme ayuda a saber la url actual y separarlas por / para saber su posicion actual
    let urlActual = window.location.href.split('/')
    let posicionUrl = urlActual[(urlActual.length - 2)]




    //We check if the user has the token 
    if (token == null) {
      localStorage.setItem('x-access-token', '');
      token = ''
    }/*else if(posicionUrl == "login" && token != ''){
      window.location.href = "/request_pay";
    }else if(token == '' && posicionUrl != "login"){
      window.location.href = "/login";
    }*/

    try {
      const tokenres = await AuthFetchAPI.validtoken(token);
      // console.log(tokenres)
      if (tokenres.data === true) {

        const user = await AuthFetchAPI.me(token);
        setUserData({ token: token, user: user.data })
      }
    } catch (error) {
      window.location.href = "/login";
    }
  }

  useEffect(() => {
    (async () => {
      try {
        // await checkLogin()
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  return (
    <>
      <Router>
        <UserContext.Provider value={{ userData, setUserData }}>
          <Routes>
            {/* Root */}
            <Route path="/main_menu" element={<Index />} />
            {/* Index Main Menu  */}
            <Route exact path="/" element={<LandingPage />} />
            {/* Menu */}
            <Route path="/menu/:business_code" element={<Menu />} />
            <Route path="/team" element={<Team />} />
            {/** Users */}
            <Route path="/users/:business_code" element={<Users />} />
            
            {/* <Route path="/contact" element={<Contact/>} />*/}
            {/* Logins */}
            <Route path="/login/:business_code" element={<Login />} />
            <Route path="/dispatcher/login/:business_code" element={<Login />} />
            <Route path="/cashier/login/:business_code" element={<Login />} />
            <Route path="/machines/login/:business_code" element={<Login />} />
            <Route path="/tables/machines/login/:business_code" element={<Login />} />
            <Route path="/tables/login/:business_code/:branchoffice_code" element={<Login />} />
            <Route path="/comanda/login/:business_code" element={<Login />} />
            <Route path="/customer/comanda/login/:business_code/:branchoffice_code" element={<Login />} />
            {/**Sub login para post */}
            <Route path="/machine/cashier/login/:business_code/:branchoffice_code" element={<LoginCashier />} />
            {/**Sub login para post */}
            <Route path="/machine/cashier/login/:business_code/:branchoffice_code/:machine_code" element={<LoginCashier />} />
            {/**Orders */}
            <Route path="/order/:order_code/branchoffice/:branchoffice_id" element={<Order />} />
            <Route path="/request_pay" element={<RequestPayment />} />
            <Route path="/biller" element={<Biller />} />

            <Route path="/list_orders/:business_code/:branchoffice_code" element={<ListOrders />} />
            <Route path="/orders_customers/:business_code/:branchoffice_code" element={<OrderCustomers />} />
            <Route path="/post" element={<page_menu />} />

            {/**Transaction of admins */}
            <Route path="/transactions/:business_code" element={<Transactions />} />
            <Route path="/analitics/:business_code" element={<AnaliticStore />} />
            <Route path="/reports/:business_code" element={<ReportStore />} />

            {/**Transaction of cashiers  */}
            <Route path="/cashier/:business_code/:branchoffice_code" element={<MachineList />} />
            <Route path="/cashier/report/:business_code/:branchoffice_code" element={<CashierReport />} />

            {/** Products of admins */}
            <Route path="/products/:business_code" element={<ProductStore />} />

            {/** Integration of admins */}
            <Route path="/manager/integration/:business_code" element={<IntegrationManager />} />

            {/* Ecommerce */}
            <Route path="/shopping_cart" element={<ShoppingCart />} />
            <Route path="/scanqr" element={<ScanQr />} />

            {/* Payment Post */}
            <Route path="/payments/:business_code/:branchoffice_code" element={<PaymentPost />} />
            <Route path="/payment" element={<ModalPaymet />} />
            <Route path="/smartpos" element={<PosPayment />} />
            {/* Ninja aPP */}
            <Route path="/ninyapp_transacction/:business_code/:branchoffice_code" element={<NinyappTransaction />} />


            {/*Register Table orders*/}
            <Route path="/business/:business_code/branchoffice/:branch_code/table/:table_num" element={<TableOrder />}></Route>
            <Route path="/confirmation/business/:business_code/branchoffice/:branch_code/table/:table_num/transaction/:transaction_code" element={<PageConfirmation />}></Route>
            <Route path="/orders/business/:business_code/branchoffice/:branch_code/list" element={<OrderList />}></Route>
            <Route path="/payment/report/:business_code/:branchoffice_code" element={<AnaliticsPayment />} />

            {/*List order by status and update orders status*/}
            <Route path="/list/status/:business_code/:branchoffice_code" element={<ListOrdersStatus />}></Route>

            {/*Vending */}
            <Route path="/vending" element={<Vending />}></Route>

            <Route path="/camera" element={<Camera />}></Route>
            <Route path="/temporal/:business_code" element={<TransactionsTemporal />}></Route>
            {/* KDS */}
            <Route path="/kds/:business_code/:branchoffice_code" element={<Kds></Kds>} ></Route>


            {/* Pre Account */}
            <Route path="/preaccount/:business_code/:branchoffice_code" element={<PreAccount />} />
            <Route path="/kiosk" element={<LoginKioskMachine />} />
            <Route path="/kiosk/stant/login/:business_code/:branchoffice_code/:machine_code_table" element={<LoginKioskMachineEcommerStant />} />
            <Route path="/kiosk/publicity" element={<Publicity />}></Route>
            <Route path="/kiosk/:business_code/:branchoffice_code" element={<KioskMain />} />
            <Route path="/kiosk/ecommers/stant/:business_code/:branchoffice_code/:machine_code_table" element={<KioskMainEcommers />} />
            <Route path="/login_pre" element={<PreAccountLogin></PreAccountLogin>}></Route>
            <Route path="/pre_account" element={<PreAccount></PreAccount>} ></Route>
            <Route path="/admin_pre_account" element={<AdminPreAccount></AdminPreAccount>}></Route>

            {/* PDF Maker */}
            <Route path="/pdf_report" element={<PdfMake></PdfMake>} ></Route>

            {/** Buys */}
            <Route path="/buys/:business_code" element={<Buys />} />
            Monto recibido
            <Route path="/example_post" element={<PayExmple></PayExmple>} ></Route>
            {/**Support bot admin */}
            <Route path="/support" element={<SupportAdmin />} />
            {/**Smartpay Webhook  */}
            <Route path="/webhook/:business_code" element={<SmartpayWebhook></SmartpayWebhook>}></Route>
            {/* <Route path="/example_post" element={<Example></Example>} ></Route> */}
            {/* No Match link */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
