import React from 'react';
import logo from '../../assets/img/logos/icon-black.png'


function PreAccountLogin() {
   return (
      <>
         <main className='pre-account-login d-flex justify-content-center align-content-center vh-100'>
            <section className="form-signin">
               <form>
                  <h2 className="h3 mb-3 fw-bold text-center">Bienvenido</h2>
                  <div className='d-flex justify-content-center'>
                     <img className="mb-4" src={logo} alt="Logo" width="72" height="100%" />
                  </div>
                  
                  <h2 className="h3 mb-3 fw-normal text-center">Acceso al Menú</h2>

                  <div className="form-floating my-4">
                     <input type="text" className="form-control rounded-sp" placeholder="Ingrese Nombre" />
                     <label for="floatingInput">Ingrese Nombre</label>
                  </div>
                  <button className="w-100 btn btn-lg btn-primary rounded-pill py-2">Ingresar</button>
               </form>
            </section>
         </main>
      </>
   )
}

export default PreAccountLogin