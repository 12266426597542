import React, { useEffect, useState } from 'react'
import Header from "./components/Header"
import { Modal } from 'react-bootstrap'
import { LanguagesFetchAPI } from '../../api/Languages'
import LoadingAlert from '../../components/Alerts/LoadingAlert'
import { RenderIf } from '../../components/utils/RenderIf'
import { UilSave } from '@iconscout/react-unicons'


function ModalKioskLanguage({ icon,background, statusLanguage, setStatusLanguage, setAtivity }) {
  const [showModalLanguage, setShowModalLanguage] = useState(false);
  let token = localStorage.getItem("x-access-machine-token")
  const [languages, setLanguages] = useState([])
  const theme_config = 1;
  const [isButtonFocused, setButtonFocus] = useState(false);

  const handleButtonFocus = () => {
    setButtonFocus(true);
  };

  const handleButtonBlur = () => {
    setButtonFocus(false);
  };
  //get languages 
  const getLanguages = async () => {
    try {
      const response = await LanguagesFetchAPI.getLanguages(token);
      setLanguages(response.data.languages)
    } catch (err) {
      console.log(err);
    }
  }

  //change Langage
  const changeLanguage = (language) => {
    if (statusLanguage == 0) {
      setStatusLanguage(1);
      document.cookie = "googtrans=/" + language;
      new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')
      setShowModalLanguage(false)
    } else {
      LoadingAlert("Restaurando idioma...", "Espere un momento por favor")
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    }
  }

  {/*view languages*/ }
  const viewLanguages = () => {

    const listItems = languages.map((language) => (
      <>
        <div className='m-3'>
          <div className='btn btn-outline-primary  d-block border border-3 rounded-pill p-3 ' onClick={() => changeLanguage(language.code)}>
            <span className='kiosk-subtitle-md  p-3  text-wrap px-3'>{language.name}</span>
          </div>
        </div>
      </>
    ));
    return (listItems)
  }

  useEffect(() => {
    getLanguages()
  }, []);
  return (
    <>
      {/* <button

        className='btn btn-primary mx-4 rounded kiosk-subtitle-md '
        onClick={() => (setShowModalLanguage(true))}>

        <i className="uil uil-english-to-chinese"></i>

        Idioma</button> */}


      <button onClick={() => (setShowModalLanguage(true))}
        className={`btn btn-outline-primary btn-language-kk border border-5 shadow rounded-pill 
        py-2 scale-hover-sm position-relative 
        ${isButtonFocused ? 'focused' : ''}`}
        onFocus={handleButtonFocus}
        onBlur={handleButtonBlur}
        // autoFocus
      >
        <i className="uil uil-english-to-chinese display-5-kk"></i>
        <span className='kiosk-text-card'>
          Idioma
        </span>
        {/* Image Hand  */}
        <div className="position-absolute postition-icon-sm">
          {isButtonFocused && <img src={icon} alt="Hand" className='icon-focus' />}
        </div>
      </button>
      <Modal
        centered
        className='modal-dialog-kk'
        size="lg"
        show={showModalLanguage}
        onHide={() => setShowModalLanguage(false)}>
        <Modal.Body >

          <section style={{ backgroundImage: `url(${background})` }} className='kiosk-delivery' onClick={() => setAtivity(1)}>
            <div className='position-relative' >
              <div className='position-absolute start-0  rounded-circle p-1' >
                <i className="uil uil-english-to-chinese display-4 ms-1 text-secondary"></i>
              </div>
            </div>
            {/* <Header
              classContainer={"kiosk-delivery_header m-0 p-0"}
              classImg={"kiosk-delivery_header_img"}>
            </Header> */}
            <div className='kiosk-delivery_section scale-up-top mt-5'>
              <h2 className='kiosk-text-title-lang text-center'>
                Elige tu idioma preferido:
              </h2>

              {viewLanguages()}

            </div>
            <div className='text-muted text-center'>
              <i className="uil uil-info-circle me-2"></i>
              Selecciona el idioma que prefieras. ¡Gracias!
            </div>
          </section>
        </Modal.Body>

        <div className='d-flex  container-button m-2'>
          <button
            onClick={() => (setShowModalLanguage(false))}
            type="button"      
            className={`m-0
               btn-lg  shadow-lg border-0
            button-ft_1 position-relative `}

            title='Regresar'
          >
            <svg xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              className=""
              id="left-arrow">
              <path
                d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>


          </button>
        </div>

      </Modal>
    </>
  )
}

export default ModalKioskLanguage