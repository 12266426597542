import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class FilterOrderFetchAPI {
    static async getOrder(transaction_code, branch_code, business_code, token) {
        const res = await server(`/order/${transaction_code}/transaction_code/${branch_code}/branchoffice/${business_code}/business`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    static async getLastOrderOfMachine(machine_id, token) {
    
        const res = await server(`/last_order/machine/${machine_id}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }
}