import "./style.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import QrReader from "react-qr-reader";
import Swal from 'sweetalert2';
//API 
import { QrFetchAPI } from '../../api/ScannerQr';

const ScanQr = () => {
  const [selected, setSelected] = useState("environment");
  const [result, setResult] = useState("No result");
  const [startScan, setStartScan] = useState(false);
  const navigate = useNavigate();

  const handleScan = async (scanData) => {
    if (scanData && scanData !== null) {
      setResult(scanData);
      setStartScan(false);
      await analyzeUrl(scanData);
    } else {
      setResult('Error analizando')
    }
  };
  const handleError = (err) => {
    setResult(err);
    console.error(err);
  };

  const previewStyle = {
    height: 240,
    width: 320
  };


  //Function to read the file input 
  const readFile = async (e) => {
    e.preventDefault();
    if (startScan) {
      Swal.fire({
        icon: 'warning',
        title: 'Ya esta escaneado, desactive el escaner para analizar la foto',
      })
    } else {
      try {
        const response = await QrFetchAPI.analyzeQr(e.target.files[0]);
        handleScan(response.data.file_response.result);
      } catch (err) {
        if (err.response.status && err.response.status === 500) {
          handleError(err.response.data.error);
        } else {
          handleError('Error analizando')
        }
      }
    }

  }

  //Url analyze 
  const analyzeUrl = async (url) => {
    try {
      //We search the domain 
      let index = url.indexOf('https://smartpay.ai/order')
      //We search on the url the domain
      if (index !== -1) {
        let order = url.split('https://smartpay.ai')
        //We put the order on the url to pass the page
        navigate(order[1])

      } else {
        Swal.fire({
          icon: "error",
          title: 'Code QR invalid'
        })
      }
    } catch (err) {
      if (err.response.status !== 500) {
        handleError(err.response.data.message)
      } else {
        handleError("Error analizando")
      }
    }
  }


  return (
    <>
       <br />
       <br />
       <br />
      <div className="QR mt-5 my-5">
        <h1>Lector QR</h1>

        <button onClick={() => { setStartScan(!startScan) }}>
          {startScan ? "Parar" : "Escanear"}
        </button>

        {startScan && (
          <>
            <h1>Escaneá el codigo QR</h1>
            <h2>Apuntá con la camara al codigo, se detectará automáticamente</h2>

            <select name="camera" onChange={(e) => setSelected(e.target.value)}>
              <option value={"environment"}>Cámara trasera</option>
              <option value={"front"}>Cámara delantera</option>
            </select>

            <QrReader
              facingMode={selected}
              delay={500}
              style={previewStyle}
              onScan={handleScan}
              onError={handleError}
            />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        <input type="file" name="files" accept="image/*" encType="multipart/form-data"
          onChange={e => {
            readFile(e)
          }}
          onClick={e => {
            e.target.value = null
          }}
        />
        <p>{result}</p>
      </div>
    </>
  );
};

export default ScanQr;