import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { SegmentsFechtAPI } from '../../../api/Segments';

function ModalEditSegment({ segment, loadSegments }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [name, setName] = useState("");
    const [description, setDesciption] = useState("");

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    //Function to register the product 
    const UpdateSegment = async (e) => {
        e.preventDefault();

        let data = {
            name: name,
            description: description,
        }
  
        try {
            //We update the data 
            let response = await SegmentsFechtAPI.updateSegment(segment.segment_id, data, token);
            //We show the success alert 
            console.log(response)

            ErrorAlert('', 'Acompañante editada con exito', 'success')
            loadSegments()
            setShowModal(false);
            
        } catch (err) {
          
            ErrorAlert('', 'Error en la edicion', 'error')
            console.log(err)
        }
    }


    useEffect(() => {
      
    }, [eventHandle])

    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={() => setShowModal(true)}
            >
                <i className="uil uil-pen"></i>
            </button>

            <Modal
                show={showModal}
                onHide={(e) => (setShowModal(false))}
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Editar acompañante </h3>
                </Modal.Header>
                <Modal.Body>
                    {/*<Form className="needs-validation" onSubmit={submit} encType="multipart/form-data">
                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label></Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Imagen"
                                name="file"
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name" >
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" placeholder="Nombre categoria" name="name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="secundaryName">
                            <Form.Label>Nombre secundario</Form.Label>
                            <Form.Control type="text" placeholder="Nombre secundario" name="secundaryName" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="category_type">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                <option value="P">Producto</option>
                                <option value="S">Servicio</option>

                            </Form.Select>
                        </Form.Group>
                        <Button type="submit" value="Submit" >Guardar</Button>
                    </Form>*/}

                    <Form
                        className="needs-validation"
                      
                    >

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={segment.name}
                                onChange={(e) => setName(e.target.value)} 
                               

                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                             placeholder={segment.description}
                             onChange={(e) => setDesciption(e.target.value)} 
                              />
                        </Form.Group>


                        {/*<Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                {viewTypeCategory()}
                            </Form.Select>
                </Form.Group>*/}


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="button" onClick={(e) => UpdateSegment(e)}>
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalEditSegment