import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { ListGroup } from 'react-bootstrap';
import { GroupsFechtAPI } from '../../../api/Groups';
import Select from 'react-select';

function ModalViewGroup({ product, loadProducts }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [infoGroup, setInfoGroup] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesSelect, setCategoriesSelect] = useState([])
    const [categorySelectNow, setCategorySelectNow] = useState('')
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [showModalAddUpc, setShowModalAddUpc] = useState(false);
    const [showModalTaxes, setShowModalTaxes] = useState(false);
    const [newUpc_code, setNewUpc_code] = useState('')
    const [taxes_list, setTaxes_list] = useState([])
    const [imgpreview, setImgpreview] = useState(product.img);
    const [taxSelect, setTaxSelect] = useState("");
    const [taxes, setTaxes] = useState([])
    const [upc_List, setUpc_List] = useState([])
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');


    //get group by id
    const getGroupById = async () => {
        try {
            const repsonse = await GroupsFechtAPI.getGroupById(product.group_id, token)
            setInfoGroup(repsonse.data.data)
            setCategories(repsonse.data.data.categories)
            setUpc_List(repsonse.data.data.upc_codes)
            setTaxes_list(repsonse.data.data.taxes)
            console.log(repsonse.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteTaxByGroupId = async (tax_id) => {
        let data = {
            tax_id: tax_id,
            group_id: infoGroup.id
        }
        try {
            const response = await TaxesFetchAPI.deleteTaxByGroupId(data, token);
            console.log(response);
            getGroupById();

        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo remover el impuestro', 'error')
        }

    }

    //delete category relaiton by group
    const deleteCategoryToProduct = async (category_id) => {
        let data = {
            group_id: product.group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteCategoryProduct(category_id, data, token)
            getGroupById()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo remover la categoria', 'error')
        }
    }

    //get Categories
    const getCategoriesByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)

            console.log(response.data.data);
            let categorieInfo = []
            response.data.data.forEach(category => {
                categorieInfo.push({ value: category.category_id, label: category.category_name })
            });

            setCategoriesSelect(categorieInfo)
        } catch (err) {
            console.log(err)
        }
    }

    const asingTaxToGroup = async () => {
        let data = {
            tax_id: taxSelect,
            group_id: infoGroup.id
        }
        try {
            const response = await TaxesFetchAPI.asingTaxToGroup(data, token)
            console.log(response);
            getGroupById()
            setShowModalTaxes(false)
            ErrorAlert('', 'Impuesto asignado correctamente', 'success')
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    //add category to product
    const asingProductToCategory = async () => {
        try {
            const response = await GroupsFechtAPI.asingproductToCategory(categorySelectNow, product.group_id, token)
            getGroupById()
            setShowModalAsing(false)
            ErrorAlert('', 'Categoria asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    const deleteUpcToProduct = async (upc_code_id) => {

        if (upc_List.length == 1) {
            ErrorAlert('', 'El producto debe tener minimo 1 codigo upc', 'error')
        } else {
            let data = {
                "group_id": product.group_id,
                "upc_code_id": upc_code_id
            }
            try {
                const response = await GroupsFechtAPI.deleteUpctoProduct(data, token)
                getGroupById()

            } catch (err) {
                console.log(err)
                ErrorAlert(err.response.data.error, 'Error no se pudo remover', 'error')
            }
        }
    }



    const addUpcCodeToProduct = async () => {
        user = JSON.parse(localStorage.getItem('user'))

        if (newUpc_code.length < 13) {
            ErrorAlert('', 'El upc debe contener minimo 13 caracteres', 'error')
        } else {
            let data = {
                "group_id": product.group_id,
                "upc_code": newUpc_code,
                "business_id": user.business_id
            }

            try {
                const response = await GroupsFechtAPI.addUpcToGroup(data, token)
                getGroupById()
                setShowModalAddUpc(false)
                ErrorAlert('', 'Upc asignado correctamente', 'success')
            } catch (err) {
                console.log(err);
                ErrorAlert(err.response.data.error, 'Error no se pudo asignar el upc', 'error')
            }
        }
    }

    const handleSaveTableSelect = (option) => {
        setCategorySelectNow(option.value);
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            console.log(response);
            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {

    }, [eventHandle])

    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={() => (setShowModal(true), getGroupById(), getCategoriesByBusiness())}
            >
                <i className="uil uil-eye"></i>
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle del producto  </h3>
                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={product.img}
                            alt={product.name}
                        />
                    </div>
                    <Container>
                        <Row>
                            <Col>
                                <div className='mt-3'>
                                    <ListGroup as="ol" >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Nombre</div>
                                                {infoGroup.name}
                                            </div>

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Estado</div>
                                                {product.status == 1 ? <><Badge bg="success">Habilitado</Badge></> : <><Badge bg="danger">Deshabilitado</Badge></>}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Descripcion</div>
                                                {infoGroup.description}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Precio</div>
                                                ${infoGroup.price}
                                            </div>


                                        </ListGroup.Item>


                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Preparacion</div>

                                                {infoGroup.preparation == 0 ? <>No</> : <>Si</>}
                                            </div>
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Tipo</div>
                                                {infoGroup.group_type}
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col>
                                <div className='mt-3'>

                                    <ListGroup as="ol" >
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Impuestos
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => (getTaxes(), setShowModalTaxes(true))}>
                                                        <i className="uil uil-plus">Agregar impuestos</i>
                                                    </button>
                                                </div>
                                                {/*<RenderIf isTrue={categories.length == 0}>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            No tiene categoria
                                                        </div>
                                                    </ListGroup.Item>
                                                </RenderIf>*/}
                                                <ListGroup as="ol" >
                                                    {taxes_list.map((tax) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {tax.tax}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteTaxByGroupId(tax.tax_id)} >
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>


                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Categorias
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => setShowModalAsing(true)}>
                                                        <i className="uil uil-plus">Agregar categoria</i>
                                                    </button>
                                                </div>
                                                {/*<RenderIf isTrue={categories.length == 0}>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            No tiene categoria
                                                        </div>
                                                    </ListGroup.Item>
                                                </RenderIf>*/}

                                                <ListGroup as="ol" >
                                                    {categories.map((category) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {category.name}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteCategoryToProduct(category.category_id)}>
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>

                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Codigos upc:
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => setShowModalAddUpc(true)}>
                                                        <i className="uil uil-plus">Agregar un upc</i>
                                                    </button>
                                                </div>

                                                {/*
                                                <RenderIf isTrue={upc_List.length == 0}>
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto" >
                                                            No tiene upc
                                                        </div>
                                                    </ListGroup.Item>
                                                </RenderIf>*/}

                                                <ListGroup as="ol" >
                                                    {upc_List.map((upc) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {upc.upc_code}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => deleteUpcToProduct(upc.id)}>
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >


            {/*Modal add  to product*/}
            <Modal
                show={showModalTaxes}
                onHide={() => (setShowModalTaxes(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar impuesto</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>
                        <select className='form-select m-3' name="user_branch" id="user_branch" onChange={(e) => setTaxSelect(e.target.value)}>
                            <option >Seleccione un impuesto</option>
                            {taxes.map(tax => (
                                <option value={tax.id}>{tax.tax}</option>
                            ))}
                        </select>
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingTaxToGroup()} >
                            Guardar
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalTaxes(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/*Modal add category to product*/}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar Categoria</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>
                        <Select
                            name="user_branch" id="user_branch"
                            className='w-100 m-3'
                            options={categoriesSelect}
                            onChange={handleSaveTableSelect}
                        />
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingProductToCategory()}>
                            Guardar
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>



            {/*Modal add up to product*/}
            <Modal
                show={showModalAddUpc}
                onHide={() => (setShowModalAddUpc(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar upc</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form className="needs-validation">

                        <div className='d-flex justify-content-center align-item-center'>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Ingrese un upc</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="000000000000"
                                    name="name" onChange={(e) => setNewUpc_code(e.target.value)} />
                            </Form.Group>
                            <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => addUpcCodeToProduct()}>
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAddUpc(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalViewGroup