
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class SuppliersFetchAPI {
    
    //Function to get the business suppliers 
    static async getBusinessSupplier(business_id, token){
        const res = await server(`/supplier/${business_id}/business`, {
            method: "get",
            headers: { 'x-access-token': token }
        });
        return res
    }
    
}

