import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap';
//Api
import { OracleSimphonyFetchAPI } from '../../../api/OracleSimphony';
import { GroupsFechtAPI } from '../../../api/Groups';
//Alerts
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';

export default function Simphony({ branchoffice, token, pos_integration_id }) {
    const [showModal, setShowModal] = useState(false)
    const [simphonyMenus, setSimphonyMenus] = useState([]);
    const [simphonyMenuSelected, setSimphonyMenuSelected] = useState(null);
    const [simphonyMenu, setSimphonyMenu] = useState();

    //Function to get the menus of simphony 
    const getSimphonyMenus = async () => {
        try {
            //We make the request just if we have a selected branchoffice 
            if (branchoffice !== 0) {
                const menus = await OracleSimphonyFetchAPI.getSimphonyMenus(branchoffice.id, token)
                setSimphonyMenus(menus.data.simphony_response.items);

            }
        } catch (err) {
            setSimphonyMenus([])
        }
    }

    //Function to get simphony menu by id 
    const getSimphonyMenuById = async (menuSelected) => {
        try {
            const menu = await OracleSimphonyFetchAPI.getSimphonyMenuById(menuSelected, branchoffice.id, token);
            setSimphonyMenu(menu.data.simphony_response);

        } catch (err) {
            setSimphonyMenu(null)
        }
    }

    //Function to insert menu on bulk
    const insertMenuOnBulk = async () => {
        try {
            let products = [];
            let categories = [];
            let segments = [];
            let items = [];
            let integrationTaxes = [];

            // Función para agregar productos y categorías
            function addProductAndCategories(productId, productName, productPrices, productImages, segmentRefs, productTaxes, categoryInfo) {
                let productPrice = 0;
                let productTax = 0;
                //We checkc if the product has more than one price 
                if (productPrices.length > 1) {
                    let index = productPrices.findIndex(price => price.name === 'Default' || price.name === 'SM');
                    //If we dont find any product we just add the first price 
                    if (index === undefined || index === -1) {
                        productPrice = productPrices[0].price;
                        productTax = integrationTaxes.find(element => element.taxRateId === productTaxes[0].taxClassRef);
                    } else {
                        productPrice = productPrices[index].price;
                        productTax = integrationTaxes.find(element => element.taxRateId === productTaxes[index].taxClassRef);
                    }
                } else {
                    productPrice = productPrices[0].price;
                    productTax = integrationTaxes.find(element => element.taxRateId === productTaxes[0].taxClassRef);
                }
                // Almacenar el producto en el array de productos con su ID
                products.push({ id: productId, group_type: "infinite", productImages: productImages === undefined ? [] : productImages, preparation: 0, external_reference: productId, segmentsRefs: segmentRefs === undefined ? [] : segmentRefs, description: productName, name: productName, price: productPrice, pos_integration_id: pos_integration_id.pos_integration_id, tax_id: productTax === undefined ? 4 : productTax.smartpayTaxId });

                // Iterar sobre los IDs de categorías y almacenarlas en el array de categorías
                categoryInfo.forEach(category => {
                    let index = categories.findIndex(cat => cat.id === category.id);
                    // Check of coincidences of the categories
                    if (index === -1) {
                        categories.push({ name: category.name, description: category.name, id: category.id, category_type: 1, categoryImages: [], external_reference: category.id, productsReference: [productId] });
                    } else {
                        //If the category is on the array we get if the reference of the product is on the category
                        let referenceCoincidence = categories[index].productsReference.some(pr => pr === productId);
                        //If the product is not on the category we insert it 
                        if (!referenceCoincidence) {
                            categories[index].productsReference.push(productId);
                        }
                    }
                });
            }

            //Menu items
            simphonyMenu.menuItems.forEach(element => {
                // We check if the menuItem has any definition 
                if (element.definitions) {
                    // Verificar si al menos una definición tiene slus
                    if (element.definitions.some(definition => definition.slus)) {
                        // We get the product name 
                        const productName = element.name["en-US"];
                        const productId = element.menuItemId;
                        //We get the prices of the product 
                        const productPrices = Array.from(
                            new Set(element.definitions
                                .filter(definition => definition.prices)
                                .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name }))))
                        );

                        //We get the segmentRefs of the product 
                        const segmentsRefs = Array.from(
                            new Set(element.definitions
                                .filter(definition => definition.condimentGroupRules)
                                .flatMap(definition => definition.condimentGroupRules.map(segment => (segment.condimentGroupRef))))
                        )

                        //We get the product img of the product 
                        const productImages = Array.from(
                            new Set(element.definitions
                                .filter(definition => definition.images)
                                .flatMap(definition => definition.images.map(image => ({
                                    url: image.url,
                                    altText: image.altText,
                                    description: image.description,
                                    size: image.size
                                }))))
                        )
                        //We match the taxes of the product 
                        const productTaxes = Array.from(
                            new Set(element.definitions.map(definition => ({ taxClassRef: definition.taxClassRef }))))

                        // We get the categories of the products 
                        const categorys = Array.from(
                            new Set(
                                element.definitions
                                    .filter(definition => definition.slus)
                                    .flatMap(definition => definition.slus.map(slu => ({ id: slu.sluId, name: slu.name["en-US"] })))
                            )
                        );
                        //We call the function to add the products and categories 
                        addProductAndCategories(productId, productName, productPrices, productImages, segmentsRefs, productTaxes, categorys);
                    }
                }
            });

            //Individual items 
            simphonyMenu.condimentItems.forEach(element => {
                const itemName = element.name['en-US'];
                const itemId = element.condimentId;
                const itemPrices = Array.from(
                    new Set(element.definitions
                        .filter(definition => definition.prices)
                        .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name })))
                    )
                )
                let itemPrice = 0;
                //We checkc if the item has more than one price 
                if (itemPrices.length > 1) {
                    let index = itemPrices.find(price => price.name === 'Default');

                    //If we dont find any product we just add the first price 
                    if (index === undefined) {
                        itemPrice = itemPrices[0].price;
                    } else {
                        itemPrice = index.price
                    }
                } else {
                    itemPrice = itemPrices[0].price
                }

                //We get the images of the items 
                const itemImages = Array.from(
                    new Set(element.definitions
                        .filter(definition => definition.images)
                        .flatMap(definition => definition.images.map(image => ({
                            url: image.url,
                            altText: image.altText,
                            description: image.description,
                            size: image.size
                        }))))
                )

                //We get put the information 
                items.push({ name: itemName, id: itemId, itemImages: itemImages === undefined ? [] : itemImages, price: itemPrice, item_type: "infinite", item_preparation: 0, description: itemName, external_reference: itemId, pos_integration_id: pos_integration_id.pos_integration_id });
            })

            //Segments 
            simphonyMenu.condimentGroups.forEach(element => {
                const segmentName = element.name["en-US"];
                const segmentId = element.condimentGroupId;
                const itemsRefs = element.condimentItemRefs;
                segments.push({ name: segmentName, description: segmentName, id: segmentId, itemsRefs: itemsRefs, external_reference: segmentId, pos_integration_id: pos_integration_id.pos_integration_id })
            });

            //Combo meals 
            simphonyMenu.comboMeals.forEach((element, index) => {
                //We check if the memnuItem Ref exist 
                if (element.menuItemRef) {
                    let comboRef = simphonyMenu.menuItems.find(product => product.menuItemId === element.menuItemRef);

                    //We check if the item of reference of the combo have some definition 
                    if (comboRef.definitions) {
                        // We get the product name 
                        const productName = "Combo " + comboRef.name["en-US"];
                        //const productId = element.comboMealId;
                        const productId = element.menuItemRef;

                        //We get the prices of the product 
                        const productPrices = Array.from(
                            new Set(comboRef.definitions
                                .filter(definition => definition.prices)
                                .flatMap(definition => definition.prices.map(price => ({ price: price.price, name: price.name }))))
                        );

                        //We get the segmentRefs of the product 
                        const segmentsRefs = Array.from(
                            new Set(comboRef.definitions
                                .filter(definition => definition.condimentGroupRules)
                                .flatMap(definition => definition.condimentGroupRules.map(segment => (segment.condimentGroupRef))))
                        )

                        //We get the product img of the product 
                        const productImages = Array.from(
                            new Set(comboRef.definitions
                                .filter(definition => definition.images)
                                .flatMap(definition => definition.images.map(image => ({
                                    url: image.url,
                                    altText: image.altText,
                                    description: image.description,
                                    size: image.size
                                }))))
                        )
                        //We match the taxes of the product 
                        const productTaxes = Array.from(
                            new Set(comboRef.definitions.map(definition => ({ taxClassRef: definition.taxClassRef }))))

                        // We get the categories of the products 
                        const categorys = Array.from(
                            new Set(
                                comboRef.definitions
                                    .filter(definition => definition.slus)
                                    .flatMap(definition => definition.slus.map(slu => ({ id: slu.sluId, name: slu.name["en-US"] })))
                            )
                        );
                        //We call the function to add the products and categories 
                        addProductAndCategories(productId, productName, productPrices, productImages, segmentsRefs, productTaxes, categorys);
                    }
                }
            })

            //Request to insert menu on bulk 
            const res = await GroupsFechtAPI.insertMenuOnBulk(branchoffice.id, products, items, segments, categories, pos_integration_id, token)
            //Success message 
            SuccessAlert(res.data.message)
        } catch (err) {
            console.log(err)
            //Error alert 
            ErrorAlert(err)
        }
    }

    return (
        <div>
            {/** Button to syncronice menu */}
            <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                <button onClick={() => (setShowModal(true), getSimphonyMenus())} type="button" className="btn btn-success px-5">Sincronizar menu</button>
            </div>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
            >
                <Modal.Header className='text-center'>
                    <h3>
                        Integracion habilitada: Simphony V19.5
                    </h3>

                </Modal.Header>
                <Modal.Body>
                    {/**We make a validation depending if the user already select a branchoffice */}
                    {branchoffice.code !== 0 ?
                        <>
                            Sucursal a sincronizar: {branchoffice.name}
                            <Form>
                                <Form.Group className="mb-3" controlId="clientId">
                                    <Form.Label>Seleccione menu a sincronizar</Form.Label>
                                    <Form.Select aria-label="" name="simphonyMenus" onChange={e => getSimphonyMenuById(e.target.value)}>
                                        <option value={null}>Seleccione...  </option>
                                        {simphonyMenus.map(item => (
                                            <option value={item.menuId}>{`ID: ${item.menuId} - name:${item.name}`}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <div className="mt-3 d-flex justify-content-end">
                                    <Button size="sm" variant="success" type="button" className='m-3' onClick={() => (LoadingAlert('Sincronizando menu...', 'Espere por favor...'), insertMenuOnBulk())} >
                                        Guardar
                                    </Button>
                                </div>
                            </Form>
                        </>
                        : "Seleccione una sucursal"}

                </Modal.Body>
            </Modal>
        </div>
    )
}
