import React, { useState } from 'react'
//Imgs
import SmartPay_Logo from "../assets/img/logos/sp.png";
import ModalKioskLanguage from '../ModalKioskLanguage';
function Header({ classContainer, classImg }) {
   let logo = localStorage.getItem('logo');

   if(localStorage.getItem != undefined || localStorage.getItem != null || localStorage.getItem != "" ){
      let businessLogo=JSON.parse(localStorage.getItem('businessConfig'))
     if (businessLogo != undefined || businessLogo != null) {
      //logo = businessLogo.logo
   }
     
   }

   return (
      <>
         <div className={classContainer}>
            <img src={logo} 
            className={classImg + " rounded m-3"} alt="Logo SmartPay"
            loading='lazy' />
         </div>         
      </>
   )
}

export default Header