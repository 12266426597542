import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, Link, useLocation } from 'react-router-dom'
//Utils
import Socket from '../../components/utils/Socket.config';
//Componenet App
import { Form, Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';

import Swal from "sweetalert2";
import NavBar from './Nav/NavBar'
import { ProductsFetchAPI } from '../../api/Paymentpost/Products';
import { CategriesPosFetchAPI } from '../../api/Paymentpost/CategoriesPos';
import { Modal } from 'react-bootstrap';
import ModalPayMet from './ModalPayMet'
import { RenderIf } from '../utils/RenderIf';
import ModalPendingOrders from './ModalPendingOrders';
import ModalDiscounts from './ModalDiscounts'
import ModalReturn from './ModalReturn';
import ModalCoupons from './ModalCoupons'
import ModalPaymet from './ModalPayMet';
import ModalCreatePreOrder from './ModalCreatePreOrder';
import { PromotionsFechtAPI } from '../../api/Promotions';
import { MachinesFetchAPI } from '../../api/Machines';
import AlertSmallInfo from '../Alerts/AlertSmallInfo';

//Img
import logo_sp from '../../assets/img/logos/icon-black.png';
import empty_img from './img/empty_cart.png';
import promotion_img from './img/promos.png'
import all_products from './img/all.png';
import sad_products from './img/emptyassing.png'
import img_default from './img/imagen.png'
//Icons
import Empty from '../utils/Empty';
import { HiShoppingCart } from 'react-icons/hi';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import AlertSmall from '../Alerts/AlertSmall';
import { SegmentsFechtAPI } from '../../api/Segments';
import { BusinessFetchAPI } from '../../api/Business';
import Totals from './Totals';
//Config
import jsonConfig from '../../config/posPlus.json';
//import jsonConfig from '../../config/posLite.json';



const PaymentPost = () => {
   let index = 0;
   const navigate = useNavigate();
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("user") == "" || localStorage.getItem("branchoffice") == "") {
      let business = JSON.parse(localStorage.getItem("business"))
      navigate(`/machines/login/` + business.code)
   }

   let { business_code, branchoffice_code } = useParams();
   //States
   const [businessLogo, setBusinessLogo] = useState("")
   const [number, setNumber] = useState(1);
   const [client_phone, setClient_phone] = useState("00")
   const [amountPaymentReady, setAmountPaymenReady] = useState(0.00)
   const [locator, setLocator] = useState("0");
   const [dataClient, setDataClient] = useState([]);
   const [categories, setCategories] = useState([]);
   const [products, setProducts] = useState([]);
   const [dataPreOrder, setDataPreOrder] = useState({ pre_Order: false, _id: "" });
   const [viewProducts, setViewProducts] = useState([]);
   const [allProducts, setAllProducts] = useState([]);
   const [showModalSelectItem, setShowModalSelectItem] = useState(false);
   const [listPaymentReady, setlistPaymentReady] = useState([])
   const [registerTurnOrder, setRegisterTurnOrder] = useState(0);
   const [notesItem, setNotesItem] = useState("");
   const [inputFocusState, setInputFocusState] = useState(true);
   const [previewPriceItemsegmentSelect, setPreviewPriceItemsegmentSelect] = useState(0)
   const [selectProduct, setSelectProduct] = useState({
      img: '',
      name: '',
      img: '',
      name: '',
      price: ''
   });

   const [previewSegment, setPreviewSegment] = useState([])
   const [segments, setSegments] = useState([]);
   const [shoppingCart, setShoppingCart] = useState([]);
   //Event handler
   const [eventHandlerPreviewsegment, setEventHandlerPreviewsegment] = useState(0);
   const [eventHandler, setEventHandler] = useState(0);
   const [pageLoader, setPageLoader] = useState(0)
   //Totals states
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0)
   const [taxesArrayAll, setTaxesArrayAll] = useState([])
   //filter
   const [temporalProducts, setTemporalProducts] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');


   //PreOrder Table info
   const [tableInfo, setTableInfo] = useState({ table_id: 0, table_code: "0", table_name: "" })



   //data client
   const [optionalData, setOptionalData] = useState(false);
   const [dataResponse, setDataResponse] = useState(false);
   const [ruc, setRuc] = useState(0);
   const [name, setName] = useState("Cliente generico");
   const [dv, setDv] = useState(0);
   const [checkDeliveryOrder, setCheckDeliveryOrder] = useState("comer aqui")
   let machine = localStorage.getItem('machine');

   const inputRefLog = useRef(null);
 /*   setTimeout(() => {
      inputRefLog.current.focus()
   }, 3000); */

   //We validate if the branch
   if (machine !== null && machine !== undefined && machine !== '') {
      machine = JSON.parse(localStorage.getItem('machine'))
   }

   //data checked discount
   const [productChecked, setProductChecked] = useState([]);
   const [preOrderInvoice, setPreOrderInvoice] = useState(false);

   //Mobile Modal
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   //Function to increment the amount of products on the product detail modal
   const incrementNumber = () => {
      setNumber(number + 1);
   };

   const getInfoBusiness = async () => {
      let infoBusiness = JSON.parse(localStorage.getItem("business"))
      try {
         const response = await BusinessFetchAPI.getBusinessById(infoBusiness.id, localStorage.getItem("x-access-machine-token"));
         console.log(response.data.data.logo);
         setBusinessLogo(response.data.data.logo);
      } catch (err) {
         console.log(err);
      }
   }


   
   //Function to decrement the amount of products on the product detail modal
   const decrementNumber = () => {
      //If the amount of product is more than 1 we decrement it
      if (number > 1) {
         setNumber(number - 1);
      }
   };

   //Filter the products by the term searched
   const filteredProducts = useMemo(() => {
      if (searchTerm === '') {
         return products
      }
      return viewProducts.filter(product => {

         const nameMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
         const descriptionMatch = product.description.toLowerCase().includes(searchTerm.toLowerCase());
         //const upc_code_match = product.upc_codes.some((upc) => upc.upc_code === searchTerm);
         return nameMatch || descriptionMatch 
         
      })
   }, [searchTerm, products]);

   //function to select type delivery order
   const selectTypyDeliveryOrder = (typeOrder) => {

      let intervalRadioButton = 0
      let temShopingCart = shoppingCart
      setCheckDeliveryOrder(typeOrder)

      const initShopingcard = () => {
         clearInterval(intervalRadioButton)
         setShoppingCart(temShopingCart)
         setEventHandler(1)
         SuccessAlert()
      }

      if (typeOrder != "Mixto") {
         LoadingAlert()
         setShoppingCart([])
         setEventHandler(1)
         temShopingCart.forEach(product => {
            product.delivery_type = typeOrder
         })
         intervalRadioButton = setInterval(initShopingcard, 1000)
      }
   }

   //function get categories by brach office and by status
   const getCategoriesByBranchOffices = async (branchoffice_id, token) => {
      try {
         const response = await CategriesPosFetchAPI.getCategoriesByBranchOffices(branchoffice_id, 1, token);
         setCategories(response.data.data)
      } catch (error) {
         console.log(error);
      }
   }

   //function get products by branch offices and by status
   const getProductsByBranchOfficessByStatus = async (branchoffice_id, token) => {

      try {
         const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatus(branchoffice_id, 1, token)
         setAllProducts(response.data.data)
         setProducts(response.data.data)
         setViewProducts(response.data.data)
         setTemporalProducts(response.data.data)

      } catch (err) {
         console.log(err);
      }
   }

   // function get segment by id product
   const getSegmentByProduct = async (group_id) => {
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      try {
         //For default we clean the segments of the state
         setSegments([])
         //We get the active segments of the group
         const response = await ProductsFetchAPI.getSegmentsByProducts(group_id, 1, branchoffice.id, token);
         AlertSmall("Consulta exitosa")
         //ErrorAlert("", "Producto consultado", "success")
         //save item default by segment

         let validItemsSgments = true
         let arrayTem = new Array
         for (let k = 0; k < response.data.data.length; k++) {
            let segment = response.data.data[k]
            let itemDefault = 0
            let turnItem = false

            if (segment.items.length == 0) {
               validItemsSgments = false
               console.log("no habia items en este segmento")
            } else {
               for (let f = 0; f < segment.items.length; f++) {
                  if (segment.segment_item_id_default == segment.items[f].item_id) {

                     console.log("este es el item default")
                     itemDefault = segment.items[f]
                     turnItem = true
                  }
               }

               if (turnItem == true) {
                  arrayTem.push({ segment: segment, item: itemDefault })
                  setPreviewSegment(arrayTem)
               } else {
                  console.log("Este producto no tiene default")
                  arrayTem.push({ segment: segment, item: segment.items[0] })
                  setPreviewSegment(arrayTem)
               }
            }
         }

         //setPreviewPriceItemsegmentSelect(response.data.group.price)

         if (validItemsSgments = true) {

            console.log("Data ahora");
            console.log(response.data);
            let segmentsTemp = response.data.data
            let priceGroupTemp = response.data.group.price
            segmentsTemp.forEach((segmentTempI) => {
               let defaultCheckedSegment = segmentTempI.segment_item_id_default
               let items = segmentTempI.items

               if (defaultCheckedSegment == null || defaultCheckedSegment == "" || defaultCheckedSegment == undefined) {
                  priceGroupTemp = (priceGroupTemp + items[0].price);
               } else {
                  items.forEach((itemTemp) => {
                     if (itemTemp.item_id == defaultCheckedSegment) {
                        priceGroupTemp = (priceGroupTemp + itemTemp.price);
                     }
                  });
               }

            });

            //We set the segments 
            setSegments(response.data.data);
            setSelectProduct(response.data.group);

            setPreviewPriceItemsegmentSelect(priceGroupTemp)
         } else {

            ErrorAlert("Hay un segmento sin items", "Error de consulta", "error")
            setShowModalSelectItem(false)
            setSegments([]);
         }

      } catch (err) {
         ErrorAlert("Verificar los segmentos he items del producto", "Error de consulta", "error")
         setShowModalSelectItem(false)
         setSegments([]);
         console.log(err);
      }
   }

   // function get segment by id product
   const getSegmentByPromotion = async (promotion) => {
      LoadingAlert()
      let token = localStorage.getItem('x-access-machine-token');

      try {
         //For default we clean the segments of the state 
         setSegments([])
         //We get the active segments of the group
         const response = await SegmentsFechtAPI.getSegmentsByPromotion(promotion.promotion_id, token)
         AlertSmall("Consulta exitosa")

         let promotionJson = response.data
         promotionJson.group = promotion
         console.log(response)
         //ErrorAlert("", "Producto consultado", "success")
         //save item default by segment



         let validItemsSgments = true
         let arrayTem = new Array
         for (let k = 0; k < promotionJson.data.length; k++) {
            let segment = promotionJson.data[k]
            let itemDefault = 0
            let turnItem = false

            if (segment.items.length == 0) {
               validItemsSgments = false
               console.log("no habia items en este segmento")
            } else {
               for (let f = 0; f < segment.items.length; f++) {
                  if (segment.segment_item_id_default == segment.items[f].item_id) {
                     itemDefault = segment.items[f]
                     turnItem = true
                  }
               }


               if (turnItem == true) {
                  arrayTem.push({ segment: segment, item: itemDefault })
                  setPreviewSegment(arrayTem)
               } else {
                  arrayTem.push({ segment: segment, item: segment.items[0] })
                  setPreviewSegment(arrayTem)
               }
            }
         }


         //setPreviewPriceItemsegmentSelect(response.data.group.price)

         if (validItemsSgments = true) {

            console.log(promotionJson.data);
            let segmentsTemp = promotionJson.data
            let priceGroupTemp = promotionJson.group.price
            segmentsTemp.forEach((segmentTempI) => {
               let defaultCheckedSegment = segmentTempI.segment_item_id_default
               let items = segmentTempI.items

               if (defaultCheckedSegment == null || defaultCheckedSegment == "" || defaultCheckedSegment == undefined) {
                  priceGroupTemp = (priceGroupTemp + items[0].price);
               } else {
                  items.forEach((itemTemp) => {
                     if (itemTemp.item_id == defaultCheckedSegment) {
                        priceGroupTemp = (priceGroupTemp + itemTemp.price);
                     }
                  });
               }

            });


            //We set the segments 
            setSegments(promotionJson.data);
            setSelectProduct(promotionJson.group);

            setPreviewPriceItemsegmentSelect(priceGroupTemp)
         } else {
            ErrorAlert("Hay un segmento sin items", "Error de consulta", "error")
            setShowModalSelectItem(false)
            setSegments([]);
         }
      } catch (err) {
         ErrorAlert("Verificar los segmentos he items de la promocion", "Error de consulta", "error")
         setShowModalSelectItem(false)
         setSegments([]);
         console.log(err);
      }
   }

   //view categories
   const listCategories = () => {
      const listCategorie = categories.map((categorie) => (
         <>
            {/* <option value={categorie.category_id} key={categorie.category_id}  >{categorie.category_name}</option> */}
            {/* <a className="nav-link"> {categorie.category_name}</a> */}
            <li>
               <div className="button-container">
                  <input type="radio" className="btn-check" name="options-outlined" id={categorie.category_id} value={categorie.category_id} autocomplete="off" onChange={(e) => selectCategorie(e.target.value)} />
                  <label className="btn btn-outline-primary rounded-pill border" htmlFor={categorie.category_id}>
                     <img className='rounded-circle' src={categorie.img === null ? img_default : categorie.img} alt="Imagen 1" />
                     {categorie.category_name}
                  </label>
               </div>
            </li>
         </>
      ));
      return (listCategorie)
   }

   const changeLanguage = (languague) => {

      document.cookie = "googtrans=/" + languague;


      {/*if (languague === 'es') {
         document.cookie = "googtrans=" + "/es";
      } else {
         document.cookie = "googtrans=" + "/es/zh-CN";
      }*/}
      new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')



   }

   const promotions = async () => {
      console.log("Estoy en promociones")

      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));

      try {
         const response = await PromotionsFechtAPI.getPromotionsByBranchoffice(branchoffice.id, token)
         console.log(response.data.promotions)

         let arrayPromotions = response.data.promotions;

         arrayPromotions.forEach((promotion) => {
            promotion.cloudinary_id = null
            promotion.id = promotion.promotion_id
            promotion.suggested_price = 0
            promotion.group_type = "infinite"
            promotion.status = 1
            promotion.preparation = 0
            promotion.expiration_date = promotion.expirationDate
            promotion.batch = null
            promotion.presentation = null
            promotion.brand_id = null
            promotion.especial_discount = 0
            promotion.dTasaITBMSValue = "01"



            promotion.name = promotion.promotion_name
            promotion.description = promotion.promotion_description
            promotion.upc_codes = [{ upc_code: "" }];
         })

         setProducts(arrayPromotions)
         setViewProducts(arrayPromotions)

      } catch (err) {
         setProducts([])
         setViewProducts([])
         console.log("No hay productos aqui ");
         console.log(err)
      }
   }

   //function filter products by category
   const selectCategorie = async (category_id) => {

      //clean input filter menu
      document.getElementById("searchInput").value = "";
      setSearchTerm("");

      let token = localStorage.getItem('x-access-machine-token');
      let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
      if (category_id == "todos") {
         setProducts(temporalProducts)
         setViewProducts(temporalProducts)
      } else if (category_id == "promociones") {
         promotions();
      } else {
         try {
            const response = await ProductsFetchAPI.getProductsByBranchOfficessByStatusByCategories(category_id, branchoffice.id, 1, token)
            setProducts(response.data.data)
            setViewProducts(response.data.data)
         } catch (err) {
            setProducts([])
            setViewProducts([])
            console.log("No hay productos aqui ");
            console.log(err);
         }
      }
   }


   // function set product select
   const selectProductoInfo = async (info) => {
      //clean input filter menu
      document.getElementById("searchInput").value = "";
      setSearchTerm("");

      setPreviewSegment([]);

      //We set the amount of the product
      setNumber(1);
      //We set for default the amount of the info product selected on 1
      info.amount = 1;
      info.cantidadItem = 1;
      //We set the info of the product and segment 


      if (info.promotion_id) {
         getSegmentByPromotion(info)
      } else {
         getSegmentByProduct(info.group_id)
      }

      //getSegmentByProduct(info.group_id)


   }

   //view product select
   const contentProduct = () => {
      const contentetProductInfo = (
         <>
            <div className='d-flex align-items-center justify-content-center m-2'>
               <img className='rounded-sp border bg-secondary ' width="300px" height="250px"
                  src={selectProduct.img === null || selectProduct.img === '' ? businessLogo : selectProduct.img} alt="img-product "
               />
            </div>
            <div className='product-content'>
               <h5 className='text-center '>{selectProduct.name}</h5>
               <p>{selectProduct.description}</p>
            </div>
            <div className='product-count'>

               {/*temporal renderif for change price product*/}
               <RenderIf isTrue={selectProduct.name == "Medicamentos"}>
                  <div className='m-5'>
                     <h3 >Modificar precio</h3>
                     <div className="col-6 col-md-8">

                        <label>Precio</label>
                        <input type="text" className="form-control" placeholder={"$ " + selectProduct.price} onChange={(e) => (handlerchangePriceProductGeneric(e))} />
                     </div>
                     <div className="col-6 col-md-8">
                        <label>Impuesto $</label>
                        <input type="text" className="form-control" placeholder={"$" + selectProduct.taxtValue} onChange={(e) => (handlerchangeTaxProductGeneric(e))} />
                     </div>

                  </div>
               </RenderIf>
               <h6 className="fw-bold text-center mb-2" >Precio base: <p translate='no'>${parseFloat(selectProduct.price).toFixed(2)}</p> </h6>
               <h5 className="fw-bold text-center mb-2" translate='no'>{"$ " + previewPriceItemsegmentSelect.toFixed(2)}</h5>
               {/* Cantidad/ Quantity*/}
               <div className='px-1 d-flex justify-content-center align-items-center'>
                  <button
                     className="round-button decrement m-1"
                     onClick={decrementNumber}>-</button>
                  <button
                     className="round-count">{number}</button>
                  <button
                     className="round-button increment  m-1" onClick={incrementNumber}>+</button>
               </div>
            </div>
         </>
      );
      return (contentetProductInfo)
   }

   const handlerchangePriceProductGeneric = (e) => {
      console.log(selectProduct);
      console.log(e.target.value);
      selectProduct.price = parseFloat(e.target.value)
      console.log(selectProduct.price);
      setSelectProduct(selectProduct)
   }

   const handlerchangeTaxProductGeneric = (e) => {
      console.log(e.target.value);
      selectProduct.taxtValue = parseFloat(e.target.value)
      console.log(selectProduct.price);
      setSelectProduct(selectProduct)
   }

   //view segments by products
   const contentSegments = () => {
      const contentetProductInfo = segments.map((segment) => (
         <>
            <div>
               <h5 className='pt-1 fw-bold text-underline'>
                  <u>{segment.name}</u>
               </h5>
            </div>
            <div className='container '>
               <div className="row mt-1 ms-1">
                  {segmentsItems(segment)}
               </div>
            </div>
         </>
      ));
      return (contentetProductInfo)
   }

   //Function to populate the segment items
   const segmentsItems = (segment) => {

      //validates the item by default and if it does not exist, sets the first position
      let itemSegments = segment.items
      let turnDefault = false
      itemSegments.forEach((item) => {
         if (segment.segment_item_id_default == item.item_id) {
            turnDefault = true
            item.turnDefault = true
         }
      })

      //set first position 
      if (turnDefault == false) { itemSegments[0].turnDefault = true }

      const listItems = itemSegments.map((item) => (
         <>

            <div className='col-12 col-md-4 col-lg-4 p-2 '>
               <input
                  className="list-group-item-check " type="radio" name={"listGroupCheckableRadios" + segment.segment_id}
                  id={"listGroupCheckableRadios" + segment.segment_id + item.item_id} onChange={() => (previewSegmentDetected(segment, item))}
                  //defaultChecked={segment.segment_item_id_default === item.item_id || (!segment.segment_item_id_default && item.item_id === segment.items[0].item_id)}
                  defaultChecked={item.turnDefault}
               />
               <label className="list-group-item py-1 px-1 h-100" htmlFor={"listGroupCheckableRadios" + segment.segment_id + item.item_id}>
                  <div className="d-flex gap-3 py-3" >
                     <img src={item.img === null ? img_default : item.img}
                        alt="Product" width="50" height="50" className="rounded-circle flex-shrink-0" />
                     <div className="d-flex gap-2 w-75 justify-content-around">
                        <div>
                           <h6 className="mb-0">{item.name}</h6>
                           <p className="mb-0 opacity-75">{item.description}</p>
                        </div>
                        <small className='fw-bold text-nowrap text-success' translate='no'>{item.price == 0 ? "" : "+ $" + item.price}</small>
                     </div>
                  </div>
               </label>
            </div>

         </>
      ));
      return (listItems)
   }

   const previewSegmentDetected = (segment, item) => {
      let arrayTemporal = previewSegment

      if (previewSegment.length > 0) {
         let include = true;
         for (let i = 0; i < arrayTemporal.length; i++) {
            if (arrayTemporal[i].segment.segment_id == segment.segment_id) {
               arrayTemporal[i].item = item

               include = false
            }
         }
         if (include == true) {
            let jsonSegmentTem = { segment: segment, item: item }

            arrayTemporal.push(jsonSegmentTem)
         }
      } else {
         let jsonSegmentTem = { segment: segment, item: item }

         arrayTemporal.push(jsonSegmentTem)
      }

      let pricePreviewTemp = selectProduct.price
      arrayTemporal.forEach((i) => {
         pricePreviewTemp = (pricePreviewTemp + parseFloat(i.item.price))
      });

      setPreviewPriceItemsegmentSelect(pricePreviewTemp);

      setPreviewSegment(arrayTemporal)
      setEventHandlerPreviewsegment(1)
   }

   //Function to listen the swicht
   const handleSwitchChange = async (event) => {
      //We set the data to update

      let product = JSON.parse(event.target.value)
      let newDelivery_type = ""
      let validAllTypeOrder = true

      if (event.target.checked == true) {
         newDelivery_type = "comer aqui"
      } else {
         newDelivery_type = "para llevar"
      }

      let oldDelivery_typeItem = ""
      shoppingCart.forEach((item, index) => {
         if (JSON.stringify(item) == JSON.stringify(product)) {
            shoppingCart[index].delivery_type = newDelivery_type;
         }

         if (index !== 0) {
            if (item.delivery_type != oldDelivery_typeItem) {
               validAllTypeOrder = false
            }
         }
         oldDelivery_typeItem = item.delivery_type
      });

      if (validAllTypeOrder == false) {
         setCheckDeliveryOrder("mixto")
      } else {
         setCheckDeliveryOrder(newDelivery_type)
      }

      setShoppingCart(shoppingCart)
      setEventHandler(1)
   };

   const viewSelectSegmentItem = () => {
      const contentlistPreview = previewSegment.map((segmentpreview) => (
         <>
            <div className='col-sm-6 '>
               <h6 className="pt-1 fw-bold text-underline">
                  <u>{segmentpreview.segment.name}:</u>
               </h6>
               <div className='bg-light border-sp d-flex align-items-center justify-content-center' >
                  <div className='d-flex align-items-center justify-content-center'>
                     <img src={segmentpreview.item.img === null || segmentpreview.item.img === '' ? businessLogo : segmentpreview.item.img} className=" border-sp m-2 " width="30px" />
                  </div>
                  <div className='m-2 d-flex align-items-center justify-content-center'>
                     <h6>{segmentpreview.item.name}</h6>
                  </div>
               </div>
            </div>
         </>
      ));
      return (contentlistPreview)
   }


   //Function to add format products to the shopping cart 
   const addProductUpc = (selectProduct, segmentsTemp) => {
      //------------------------------------------------------------

      //For default we set that the amount of the selected product depending the number 
      if (selectProduct.img === null || selectProduct.img === "") {
         selectProduct.img = businessLogo
      }
      selectProduct.amount = number;
      selectProduct.cantidadItem = number;
      selectProduct.delivery_type = "comer aqui";
      selectProduct.note = notesItem;
      selectProduct.unit_discount = 0;
      selectProduct.product_discount = 0;
      selectProduct.descuento = selectProduct.product_discount;
      selectProduct.discount = selectProduct.product_discount;
      //For default we initialice the segments empty of the selected product
      selectProduct.segments = [];
      selectProduct.DetalleSegmentos = [];
      selectProduct.segments_string = ""
      //We set the segment items selected on the product selected

      let temporal_segment_string = ""
      segmentsTemp.forEach((segment) => {
         let defaultCheckedSegment = segment.segment_item_id_default
         let items = segment.items

         if (defaultCheckedSegment == null || defaultCheckedSegment == "" || defaultCheckedSegment == undefined) {
            let item = items[0]
            temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
            selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
            selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
            selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
            selectProduct.price = selectProduct.price + item.price

         } else {
            items.forEach((item) => {

               if (item.item_id == defaultCheckedSegment) {
                  temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
                  selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
                  selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
                  selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: 1, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: 1, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
                  selectProduct.price = selectProduct.price + item.price
               }

            });
         }
      });

      addProductSelectToShoppingcart(selectProduct, temporal_segment_string)

      let asholp = shoppingCart
      console.log('Carrito Products ', asholp);

      setPreviewSegment([])
      setSegments([])
      setNotesItem("")

      //--------------------
      handlerInputFocus()
   }


   //Function to add format products to the shopping cart 
   const addProduct = (e) => {

      console.log(selectProduct)
      //------------------------------------------------------------
      e.preventDefault();
      //For default we set that the amount of the selected product depending the number 
      if (selectProduct.img === null || selectProduct.img === "") {
         selectProduct.img = businessLogo
      }
      selectProduct.amount = number;
      selectProduct.cantidadItem = number;
      selectProduct.delivery_type = "comer aqui";
      selectProduct.note = notesItem;
      selectProduct.unit_discount = 0;
      selectProduct.product_discount = 0;
      selectProduct.descuento = selectProduct.product_discount;
      selectProduct.discount = selectProduct.product_discount;
      //For default we initialice the segments empty of the selected product 
      selectProduct.segments = [];
      selectProduct.DetalleSegmentos = [];
      selectProduct.segments_string = ""
      //We set the segment items selected on the product selected 

      let temporal_segment_string = ""
      for (let s = 0; s < segments.length; s++) {
         let segment = segments[s];

         for (let i = 0; i < segment.items.length; i++) {
            let item = segment.items[i]
            let radiobutton = document.getElementById("listGroupCheckableRadios" + segment.segment_id + item.item_id);

            if (radiobutton.checked) {
               temporal_segment_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments_string = selectProduct.segments_string + " " + segment.name + ": " + item.name
               selectProduct.segments.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
               selectProduct.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: number, item: { name: item.name, description: item.description, img: item.img, price: item.price, item_id: item.item_id, amount: number, taxtValue: item.taxtValue, tax: item.tax, external_reference: item.external_reference } })
               selectProduct.price = selectProduct.price + item.price
            }
         }

      }


      if (selectProduct.promotion_id) {
         addpromotionToShoppiingCart()
      } else {
         addProductSelectToShoppingcart(selectProduct, temporal_segment_string)
      }


      setPreviewSegment([])
      setSegments([])
      setNotesItem("")

   }

   const addpromotionToShoppiingCart = () => {
      let turn = 0
      shoppingCart.forEach((product) => {

         if (product.promotion_id) {
            if (product.promotion_id == selectProduct.promotion_id) {
               /*if (selectProduct.segments_string == product.segments_string) {
                  console.log("este producto ya esta");
                  shoppingCart.amount = (shoppingCart.amount + number)
                  shoppingCart.cantidadItem = shoppingCart.amount
                  turn = 1
               }*/
               ErrorAlert("Puedes incrementar la cantidad del mismo en el carrito.", "Ya existe esta promocion en el carrito.", "warning");
               turn = 1
            }
         }

      });

      if (turn == 0) {
         //We add the new product to the shopping cart
         setShoppingCart([...shoppingCart, selectProduct]);
         //We calculate the invoice totals
         calculateInvoiceTotals([...shoppingCart, selectProduct])

         if (checkDeliveryOrder != "comer aqui") { setCheckDeliveryOrder("mixto") }
      } else {
         setShoppingCart(shoppingCart)
         //We calculate the invoice totals
         calculateInvoiceTotals(shoppingCart)
      }

      selectCategorie("promociones")
   }



   //add product to shopingcart 
   const addProductSelectToShoppingcart = (selectProductItem, temporal_segment_string) => {

      let temporalShopingCart = shoppingCart
      let turn = 0
      //check item string segments into shopingcart
      for (let shopping = 0; shopping < temporalShopingCart.length; shopping++) {

         if (selectProductItem.group_id == temporalShopingCart[shopping].group_id) {

            console.log(temporalShopingCart[shopping].segments_string);
            console.log(temporal_segment_string);
            if (temporalShopingCart[shopping].segments_string === temporal_segment_string) {
               console.log();
               if (temporalShopingCart[shopping].descuento == 0) {
                  console.log("este producto ya esta");
                  temporalShopingCart[shopping].amount = (temporalShopingCart[shopping].amount + number)
                  temporalShopingCart[shopping].cantidadItem = temporalShopingCart[shopping].amount
                  turn = 1
               }
            } else {

            }
         }

      }

      if (turn == 0) {
         //We add the new product to the shopping cart 
         setShoppingCart([...shoppingCart, selectProductItem]);
         //We calculate the invoice totals 
         calculateInvoiceTotals([...shoppingCart, selectProductItem])
         if (checkDeliveryOrder != "comer aqui") { setCheckDeliveryOrder("mixto") }
      } else {
         setShoppingCart(temporalShopingCart)
         //We calculate the invoice totals
         calculateInvoiceTotals(temporalShopingCart)
      }
   }

   //Function to delete a product of the shopping cart
   const deleteProduct = (product_index) => {
      //We delete the product of the shopping cart list
      shoppingCart.splice(product_index, 1);
      setShoppingCart([...shoppingCart]);
      //We recalculate the invoice totals
      calculateInvoiceTotals([...shoppingCart])

      if (preOrderInvoice == true) {
         if (shoppingCart.length == 0) {
            setPageLoader(1);
         }
      }
   }

   //Function to incremet the product amount on the shopping cart
   const incrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingCart[product_index];
      //We affect the product data
      product.amount = product.amount + 1;
      product.cantidadItem = product.amount;
      product.descuento = product.descuento + product.unit_discount;
      product.discount = product.discount + product.unit_discount;
      //We set the handler to recharge the data
      setEventHandler(1);
   };

   //Function to incremet the product amount on the shopping cart
   const decrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingCart[product_index];
      //We affect the product data
      product.amount = product.amount - 1;
      product.cantidadItem = product.amount
      product.descuento = product.descuento - product.unit_discount;
      product.discount = product.discount - product.unit_discount;
      //We check if the product amount is lower than 0
      if (product.amount <= 0) {
         //if is lower or equal to 0 we delete the product of the shopping cart list
         shoppingCart.splice(product_index, 1);
         setShoppingCart([...shoppingCart])
      }
      //We set the handler to recharge the data
      setEventHandler(1)
   };


    //decimal reduction
    const decimalReduction = (value) => {
        
      let string = value.toString()
      let arrayString = string.split(".");
      let newDecimalString = []
      let sample =''
      if(arrayString[1] !=undefined){
        newDecimalString = arrayString[1].slice(0, 2);
        sample =arrayString[0] + "." + newDecimalString
        console.log("Hice la reduccion");
      }else{
        console.log("Array: " + arrayString);
        console.log("Decimal: " + newDecimalString);
        console.log("Nuevo valor: "+ sample);
        sample = string;
      }

      let endValue = parseFloat(sample)
      return endValue
  }


   //Function to calculate the invoice totals
   const calculateInvoiceTotals = (products) => {

      console.log("Calculando el total de la factura");
      console.log(products);
      let taxesArray = [];
      //For every new calculate we set the totals
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //if we dont we products we put the totals in 0 for default
      if (products.length === 0) {
         //We calculate the totals of the invoice
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setTaxesArrayAll(taxesArray)
      } else {
         //For every product we calculate
         for (let i = 0; i < products.length; i++) {
            let product_info = products[i]
            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product
            product_total_discount = (product_info.unit_discount * product_info.amount);
            //We set the total of the product
            total_product_price = (product_info.amount * (product_info.price - product_info.unit_discount));



            //Calculate tax by group--------------------------------
            let taxesGroup = product_info.taxes;
            taxesGroup.forEach(tax => {

               //calculate tax  value and add it to array
          
               let valueTax = decimalReduction((product_info.amount * ((product_info.price - product_info.unit_discount) * tax.taxtValue)));
               tax.total= valueTax;
               //Identifier tax of the group position
               var index = taxesArray.findIndex(function (info, i) {
                  return info.tax_id === tax.tax_id
               });

               if (index < 0) {
                  let taxInfoNow = {
                     "dTasaITBMSValue": tax.dTasaITBMSValue,
                     "percentage": tax.percentage,
                     "tax": tax.tax,
                     "tax_id": tax.tax_id,
                     "taxtValue": tax.taxtValue,
                     "total": valueTax
                  }
                  taxesArray.push(taxInfoNow);
               } else {
                  taxesArray[index].total = (taxesArray[index].total + valueTax)
               }
               total_product_itbms = (total_product_itbms + valueTax);

            });
            //-------------------------------------------------------

            //We set the itbms of the product
            //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * taxTotalByGroup));

            //We calculate the totals of the invoice
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = decimalReduction((invoiceSubtotal + total_product_price));
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms;
         }
         invoiceTotal = decimalReduction((Math.round(invoiceTotal * 100) / 100));
         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);
         setTaxesArrayAll(taxesArray);

      }
   }

   //Function to clear all the shopping cart
   const clearShoppingCart = () => {
      if (shoppingCart.length > 0) {
         const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
               confirmButton: 'btn btn-success',
               cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
         })

         swalWithBootstrapButtons.fire({
            title: 'Aun hay productos en la cartilla',
            text: "¿Estás seguro de que deseas eliminar todos los productos de tu carrito? Esta acción no se puede deshacer.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si!',
            cancelButtonText: 'No!',
            reverseButtons: true
         }).then((result) => {
            if (result.isConfirmed) {
               swalWithBootstrapButtons.fire(
                  'Orden cancelada!',
                  '',
                  'success'
               )
               setShoppingCart([]);
               setProductChecked([])
               setEventHandler(1);
               setPageLoader(1)
            } else if (
               /* Read more about handling dismissals below */
               result.dismiss === Swal.DismissReason.cancel
            ) {
               swalWithBootstrapButtons.fire(
                  'La orden se mantiene!',
                  '',
                  'info'
               )
            }
         })
      } else {
         setShoppingCart([]);
         setProductChecked([])
         setEventHandler(1);
         setPageLoader(1)
      }
   }

   const buttonTypeOrdersDelivery = () => {
      let radioButtons = (
         <>
            <input type="radio" className="btn-check " name="btnradio" id="btnradio1" data-type="comer aqui" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "comer aqui"} />
            <label className="btn btn-outline-primary border rounded-pill " for="btnradio1">
               <small className='fw-bold fs-6 mx-1' ><i className="uil uil-arrow-down"></i>
                  Comer aquí
               </small>
            </label>

            <input type="radio" className="btn-check " name="btnradio" id="btnradio2" data-type="para llevar" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "para llevar"} />
            <label className="btn btn-outline-primary border rounded-pill mx-1" for="btnradio2">
               <small className='fw-bold fs-6 mx-1' ><i className="uil uil-arrow-up-right"></i>
                  Para llevar
               </small>
            </label>

            <input type="radio" className="btn-check " name="btnradio" data-type="mixto" id="btnradio3" autocomplete="off" onChange={(e) => (selectTypyDeliveryOrder(e.target.dataset.type))} checked={checkDeliveryOrder === "mixto"} disabled />
            <label className="btn btn-outline-primary border rounded-pill" for="btnradio3">
               <small className='fw-bold fs-6 mx-1' >
                  <i className="uil uil-exchange-alt"></i>
                  Mixto
               </small>
            </label>
         </>
      )
      return (radioButtons)
   }

   //action focur input upc
   const handlerInputFocus = () => {
      AlertSmallInfo()
      inputRefLog.current.focus();
   }



   const handleChange = async (e) => {

      if (e.key === 'Enter') {

         let inputValue = e.target.value
         let product_search = 0
         allProducts.forEach((product) => {
            let upc_codes = product.upc_codes;
            upc_codes.forEach((upc) => {
               if (upc.upc_code == inputValue) {
                  product_search = product
               }
            })
         });

         console.log(allProducts)

         LoadingAlert("Buscando producto", "Espere un momento por favor...")
         try {

            let token = localStorage.getItem('x-access-machine-token');
            let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
            const response = await ProductsFetchAPI.getSegmentsByProducts(product_search.group_id, 1, branchoffice.id, token);

            ErrorAlert("", "Producto encontrado.", "success");
            console.log("Esta es la peticion del segmento")
            console.log(response.data.group);
            setSelectProduct(response.data.group)

            let segmentsTemp = response.data.data
            //let temporal_segment_string = ""

            addProductUpc(response.data.group, segmentsTemp)

         } catch (err) {
            ErrorAlert("Error de consulta", "Producto no encontrado.", "error");
            console.log(err)
         }

         //setSearchTerm(inputValue)
         document.getElementById("input_upc").value = ""
      }
   }


   //Hook to load the initial info
   useEffect(() => {
      (async () => {
         let token = localStorage.getItem('x-access-machine-token');

         let branchoffice = localStorage.getItem('branchoffice') !== '' ? JSON.parse(localStorage.getItem('branchoffice')) : '';
         getInfoBusiness();
         try {
            //------------------------------------------------
            if (branchoffice !== '' && branchoffice !== null && branchoffice !== undefined) {
               getCategoriesByBranchOffices(branchoffice.id, token)
               getProductsByBranchOfficessByStatus(branchoffice.id, token)
            }
         } catch (err) {
            console.log(err);
         }

         //clean input filter menu
         document.getElementById("searchInput").value = "";
         setSearchTerm("");

         
         setShoppingCart([]);
         setTaxesArrayAll([]);
         setlistPaymentReady([])
         setAmountPaymenReady(0.00)
         setPreOrderInvoice(false)
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setPageLoader(0)
         setCheckDeliveryOrder("comer aqui")
         setName("Cliente generico")
         setDv(0)
         setRuc(0)
         setRegisterTurnOrder(0)
         setClient_phone("00")
         setLocator("0")
         setDataClient([]);
         setDataResponse(false)
         setOptionalData(false)
         setDataPreOrder({ pre_Order: false, _id: "" })
         handlerInputFocus();

      })();

   }, [pageLoader]);

   //Hook to recharge data of the shopping cart
   useEffect(() => {
      setShoppingCart([...shoppingCart]);
      calculateInvoiceTotals([...shoppingCart])
      setEventHandler(0);
   }, [eventHandler]);

   //Hook to recharge data of preview segments
   useEffect(() => {
      setPreviewSegment([...previewSegment])
      setEventHandlerPreviewsegment(0)
   }, [eventHandlerPreviewsegment]);

   // layoutConfig, setlayoutConfig
   const [responseMachine, setResponseMachine] = useState(null);

   //layout config
   const [layoutConfig, setLayoutConfig] = useState([]);
   const [layoutConfigBtn, setLayoutConfigBtn] = useState([]);

   const [btnLayout, setBtnLayout] = useState({

      couponPlus: null,
      couponLite: null,

      discountsPlus: null,
      discountsLite: null,

      pendingOrdersPlus: null,
      pendingOrdersLite: null,

      returnPlus: null,
      returnLite: null,

      menuLite: null,


   });


   useEffect(() => {
      (async () => {
         try {
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
            let code_language = response_machine.data.data.language.code

            setLayoutConfig(response_machine.data.data.machine_config)
            setLayoutConfigBtn(response_machine.data.data.machine_config.buttons.coupons.actvLite)

            setBtnLayout({
               couponPlus: response_machine.data.data.machine_config.buttons.coupons.actvPlus,
               couponLite: response_machine.data.data.machine_config.buttons.coupons.actvLite,

               discountsPlus: response_machine.data.data.machine_config.buttons.discounts.actvPlus,
               discountsLite: response_machine.data.data.machine_config.buttons.discounts.actvLite,

               pendingOrdersPlus: response_machine.data.data.machine_config.buttons.pendingOrders.actvPlus,
               pendingOrdersLite: response_machine.data.data.machine_config.buttons.pendingOrders.actvLite,

               returnPlus: response_machine.data.data.machine_config.buttons.return.actvPlus,
               returnLite: response_machine.data.data.machine_config.buttons.return.actvLite,

               menuLite: response_machine.data.data.machine_config.buttons.menu.actvLite,

            })


            // console.log(response_machine.data.data.machine_config);




            if (code_language != "es") {
               changeLanguage(code_language)
            }

         } catch (err) {

         }
      })()
   }, [])

   //btnLayout.menuLite buttons.menu.actvLite
   console.log("Contendor Main ", layoutConfig.mainContainer);
   console.log("Mesas:", btnLayout.pendingOrdersPlus);


   return (
      <>

         {/* <header>
            <NavBar setPageLoader={setPageLoader} />
         </header>  */}
         <div className='kds-main'>
            {/* !layoutConfig.mainContainer */}
            <main className="container-parent ">
               <div className={
                  //Condition add new class xl
                  !layoutConfig.mainContainer
                     ? "container-pos-left-xl kds-view border sm no-scroll"
                     : "container-pos-left kds-view border  border sm no-scroll"
               }

               >
                  <div className="flex-container">
                     {/* MODULO */}
                     <header className='mx-1'>
                        <nav className={!layoutConfig.mainContainer
                           ? " px-1 fixed-top-sp border-bottom bg-white "
                           : "  px-1 fixed-top border-bottom bg-white"
                        }>
                           <div className="">
                              <div className="d-flex  align-items-center justify-content-between">
                                 <div className='d-flex  bd-highlight  align-items-center'>
                                    <a href="javascript:location.reload()"
                                       className="d-flex align-items-center flex-fill bd-highlight text-dark text-decoration-none">
                                       <img
                                          className="navbar-logo-post"
                                          src={logo_sp} alt="Logo Smart Pay" />
                                       <span className="h5 pt-2 me-3 fw-bold align-self-center text-uppercase title-1 position-relative">

                                          POS
                                          {/* Version Pos */}
                                          {!layoutConfig.mainContainer
                                             ? <><span className="top-100 start-50 translate-middle ">
                                                <small className='text-muted fs-6  opacity-25 fw-lighter'>Lite </small></span></>
                                             : <><span className="top-100 start-50 translate-middle ">
                                                <small className='text-muted fs-6  opacity-25 fw-lighter'> Plus</small></span></>
                                          }
                                       </span>

                                    </a>

                                    {/*Buttons Group V. Plus*/}
                                    {
                                       layoutConfig.mainContainer

                                          ?
                                          <>
                                             <div className='flex-fill bd-highlight container-hidden-mobile my-1'>

                                                {

                                                   btnLayout.discountsPlus
                                                      ? <>
                                                         <ModalDiscounts
                                                            actvPlus={btnLayout.discountsPlus}
                                                            actvLite={btnLayout.discountsLite}
                                                            productChecked={productChecked}
                                                            shoppingCart={shoppingCart}
                                                            total={invoiceTotal}
                                                            subtotal={invoiceSubtotal}
                                                            itbms={invoiceTotalItbms}
                                                            discount={invoiceTotalDiscount}
                                                            setLoad={setEventHandler}
                                                            setShoppingCart={setShoppingCart} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll} />
                                                      </>
                                                      : <></>
                                                }


                                                {
                                                   btnLayout.couponPlus
                                                      ? <>
                                                         <ModalCoupons
                                                            actvPlus={btnLayout.couponPlus}
                                                            actvLite={btnLayout.couponLite}

                                                            shoppingCart={shoppingCart}
                                                            total={invoiceTotal}
                                                            subtotal={invoiceSubtotal}
                                                            itbms={invoiceTotalItbms}
                                                            discount={invoiceTotalDiscount}
                                                            setLoad={setEventHandler}
                                                            setShoppingCart={setShoppingCart}
                                                            taxesArrayAll={taxesArrayAll} />
                                                      </>
                                                      : <></>
                                                }

                                                {
                                                   btnLayout.pendingOrdersPlus
                                                      ? <>

                                                         <ModalPendingOrders
                                                            actvPlus={btnLayout.pendingOrdersPlus}
                                                            actvLite={btnLayout.pendingOrdersLite}


                                                            preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} />



                                                      </>
                                                      : <>

                                                      </>
                                                }



                                                {
                                                   btnLayout.returnPlus
                                                      ? <>

                                                         <ModalReturn
                                                            actvPlus={btnLayout.returnPlus}
                                                            actvLite={btnLayout.returnLite}
                                                         />
                                                      </>
                                                      : <>

                                                      </>
                                                }

                                             </div>

                                          </>
                                          :
                                          <>

                                          </>
                                    }


                                 </div>

                                 {
                                    layoutConfig.mainContainer

                                       ?
                                       <>
                                          <div className='flex-fill bd-highlight me-2 search-bar'>
                                             <form className="button-container w-100">
                                                <div>
                                                   <input
                                                      id='searchInput'
                                                      spellcheck="false"
                                                      className="form-control rounded-pill form-control-sm mx-2 bg-gray"
                                                      type="text"
                                                      step="any"
                                                      placeholder="Buscar productos..."
                                                      onChange={e => setSearchTerm(e.target.value)}
                                                   />

                                                </div>
                                             </form>

                                          </div>

                                       </>
                                       :
                                       <>

                                       </>
                                 }


                                 <NavBar setPageLoader={setPageLoader}></NavBar>

                              </div>
                           </div>
                        </nav>
                     </header>
                     {/* MODULO */}
                     {/* Agregar mt-5 en responsive  */}
                     <div className="header-pos">
                        {/* MODULO */}
                        <div className='d-flex justify-content-between  align-items-center m-1 mt-3'>
                           <div className=''>
                              <div className='fw-bold px-2 fs-4'>
                                 <i className="uil uil-receipt-alt"></i>
                                 Orden
                              </div>
                              {preOrderInvoice == true ? <><Badge pill bg="warning" text="dark">
                                 Precuenta activa
                              </Badge></> : <></>}

                           </div>

                           <div>
                              <button onClick={() => (handlerInputFocus())}
                                 className="btn btn-outline-warning  border border-1 rounded-pill me-1" >
                                 <i className="uil uil-qrcode-scan"></i>
                                 Upc
                              </button>
                              {layoutConfig.mainContainer
                                 ? <>
                                    <ModalCreatePreOrder
                                       checkDeliveryOrder={checkDeliveryOrder}
                                       dv={dv} name={name} ruc={ruc}
                                       setDv={setDv}
                                       setName={setName}
                                       setRuc={setRuc}
                                       dataResponse={dataResponse}
                                       optionalData={optionalData}
                                       setDataResponse={setDataResponse}
                                       setOptionalData={setOptionalData}
                                       shoppingCart={shoppingCart}
                                       total={invoiceTotal}
                                       subtotal={invoiceSubtotal}
                                       tax={invoiceTotalItbms} discount={invoiceTotalDiscount}
                                       setPageLoader={setPageLoader} dataPreOrder={dataPreOrder}
                                       taxesArrayAll={taxesArrayAll}
                                    />
                                 </>
                                 : <></>}


                              <button type="button" className="btn btn-danger border border-1 rounded-pill ms-1"
                                 onClick={clearShoppingCart} >
                                 <small className='fw-bold '>
                                    <i className="uil uil-trash-alt buttons-label"></i>
                                    {/* <span className='hidden-text'>
                                       Eliminar Todo
                                    </span> */}
                                 </small>
                              </button>
                           </div>

                           {/* 
                           {/* 
                           <div className=''>
                              <Link className='btn btn-outline-primary border btn-sm' to={`/ninyapp_transacction/${business_code}/${branchoffice_code}`}>
                                 <small className='fw-bold'>
                                    <i className="uil uil-receipt"></i>
                                    NinjaApp
                                 </small>
                              </Link>
                           </div> */}
                           <input id="input_upc" className='input-upc' placeholder="Buscar Upc..." ref={inputRefLog} inputMode="none" type="text" onKeyPress={(e) => handleChange(e)} autoFocus ></input>
                        </div>

                     </div>
                     {/* MODULO */}
                     <div className="item2 px-1  ">
                        <div className={
                           !shoppingCart.length
                              ? "   bd-highlight shopping-wrapper border-pos rounded no-scroll d-flex align-items-center justify-content-center"
                              : "   bd-highlight shopping-wrapper border-pos border-success-pos rounded no-scroll bg-light"
                        }
                           onClick={() => { handlerInputFocus() }}>

                           <div >
                              {
                                 !shoppingCart.length
                                    ? <Empty title="Vacío" msg="No hay ningun producto agregado en el carrito" img={empty_img} />
                                    : shoppingCart.map((product) => (
                                       <div >
                                          <div className={product.discount || product.unit_discount > 0
                                             ? "d-flex border rounded border-warning border-2  mt-3 position-relative bg-white"
                                             : "d-flex rounded m-1 bg-white mt-1 card-pos"}
                                          >
                                             <div className="flex-shrink-0">
                                                <img className='m-1 rounded  bg-light border ' src={product.img === null || product.img === '' ? businessLogo : product.img} alt="..." width="80" height="80" />
                                             </div>
                                             <div className="flex-grow-1 m-1">
                                                <div className="d-flex bd-highlight">
                                                   <div className={layoutConfig.mainContainer
                                                      ? "bd-highlight title-product-cart mt-1 text-primary"
                                                      : "bd-highlight title-product-cart-xl mt-1 text-primary"}
                                                   >
                                                      {product.name}
                                                   </div>
                                                   <div className={layoutConfig.mainContainer
                                                      ? "ms-auto px-2 bd-highlight text-price-cart"
                                                      : "ms-auto px-2 bd-highlight text-price-cart-xl"}


                                                      translate='no'>$ {product.price.toFixed(2)}</div>
                                                </div>
                                                <div NameclassName="d-flex bd-highlight text-muted opacity-75">
                                                   <details open>
                                                      <summary> <small> Detalles </small> </summary>
                                                      {product.segments.map(item => (
                                                         <div className=" badge border border-light text-dark text-nowrap m-1">
                                                            <h6 className='d-block text-muted' >
                                                               {item.segment_name}:
                                                            </h6>
                                                            <h6> {item.name} <strong translate='no'>${item.price}</strong></h6>
                                                         </div>
                                                      ))}
                                                   </details>
                                                </div>
                                                <RenderIf isTrue={product.note != ""}>
                                                   <small className='text-danger p-1' >
                                                      Nota: {product.note}
                                                   </small>
                                                </RenderIf>
                                                <div className="d-flex justify-content-between align-items-center mt-1">
                                                   <div className='px-1 d-flex justify-content-between align-items-center'>
                                                      <RenderIf isTrue={product.descuento == 0}><button
                                                         className="round-button decrement"
                                                         data-id={index}
                                                         onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>-</button></RenderIf>
                                                      <button
                                                         className="round-count">{product.amount}</button>
                                                      <RenderIf isTrue={product.descuento == 0}> <button
                                                         className="round-button increment"
                                                         data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button></RenderIf>
                                                   </div>
                                                   {/* <div className=" ">
                                                      <span className='px-1 number-counter'>
                                                         <button 
                                                         data-id={index} onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>-</button>
                                                         <p className='text-dark fw-bold'>{product.amount}</p>
                                                         <button data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button>
                                                      </span>
                                                   </div>  */}

                                                   {layoutConfig.mainContainer

                                                      ? <>
                                                         <small className='text-capitalize mt-1'>
                                                            <Form.Switch
                                                               type="switch"
                                                               id={product.group_id}
                                                               value={JSON.stringify(product)}
                                                               label={product.delivery_type}
                                                               defaultChecked={product.delivery_type === "comer aqui" ? true : false}
                                                               onChange={handleSwitchChange}
                                                            />
                                                         </small>

                                                      </>

                                                      : <></>}

                                                   <div className=" px-2 bd-highlight fw-bold">
                                                      <button
                                                         className='btn btn-outline-danger btn-sm border-0 rounded-circle '
                                                         title="Eliminar Producto"
                                                         data-id={index++}
                                                         onClick={e => deleteProduct(e.currentTarget.dataset.id)}>
                                                         <i className="uil uil-trash-alt icon-delete p-1"></i>
                                                      </button>
                                                   </div>
                                                </div>
                                             </div>

                                             <span className={product.discount || product.unit_discount > 0
                                                ? "position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning"
                                                : "visually-hidden"} translate='no'>
                                                Descuento aplicado: - {product.unit_discount}
                                             </span>
                                          </div>
                                       </div>
                                    ))
                              }
                           </div>
                        </div>

                        {/* MODULO */}
                     </div>
                     {/* MODULO */}

                     {/* MODULO */}
                     {layoutConfig.mainContainer
                        ? <>
                           <div>
                              {/* MODULO */}
                              <div className='w-100 d-flex justify-content-center'>
                                 <div className="btn-group  btn-group-sm " role="group" aria-label="Basic radio toggle button group">
                                    {buttonTypeOrdersDelivery()}
                                 </div>
                              </div>
                           </div>
                           <div className="item3  ">
                              <div className="mb-0 small lh-sm mt-1">
                                 <div className="d-flex justify-content-between">
                                    <small className="text-muted h6 subtitle"> Descuento:</small>
                                    <span className="text-warning text-secondary h6 subtitle" translate='no'>
                                       <span className="px-2 subtitle">- $</span>
                                       <strong> {invoiceTotalDiscount.toFixed(2)} </strong>
                                    </span>
                                 </div>
                                 <div className="d-flex justify-content-between ">
                                    <small className="text-muted h6 subtitle"> Subtotal: </small>
                                    <span className="text-black text-secondary h6 subtitle" translate='no'>
                                       <span className="px-2 subtitle">$</span>{invoiceSubtotal.toFixed(2)}</span>
                                 </div>

                                 {taxesArrayAll.map((tax) => (
                                    <>
                                       {tax.total > 0 ?
                                          <div className="d-flex justify-content-between">
                                             <small className=" text-muted h6 subtitle"> Impuesto {tax.tax}:
                                             </small><span className="text-black text-secondary h6 subtitle" translate='no'>
                                                <span className="px-2 subtitle ">$</span>{tax.total.toFixed(2)}</span>
                                          </div> : <></>}
                                    </>
                                 ))}



                                 <div className="d-flex justify-content-between total-shopping">
                                    <strong className="text-black fw-bold h6 mt-1 subtitle"> Total <small>(Incluye itbms)</small>: </strong>
                                    <span className="text-black fw-bold h5 subtitle" translate='no'>
                                       <span className="px-2 ">$
                                       </span>{invoiceTotal.toFixed(2)}</span>
                                 </div>
                              </div>
                              <div className=' mb-0 '>
                                 <ModalPaymet
                                    preOrderInvoice={preOrderInvoice}
                                    dv={dv} name={name} ruc={ruc}
                                    setDv={setDv} setName={setName}
                                    setRuc={setRuc}
                                    dataResponse={dataResponse}
                                    optionalData={optionalData}
                                    setDataResponse={setDataResponse}
                                    setOptionalData={setOptionalData}
                                    shoppingCart={shoppingCart}
                                    total={invoiceTotal}
                                    subtotal={invoiceSubtotal}
                                    discount={invoiceTotalDiscount}
                                    tax={invoiceTotalItbms}
                                    setPageLoader={setPageLoader}
                                    dataPreOrder={dataPreOrder}
                                    checkDeliveryOrder={checkDeliveryOrder}
                                    registerTurnOrder={registerTurnOrder}
                                    setRegisterTurnOrder={setRegisterTurnOrder}
                                    client_phone={client_phone}
                                    setClient_phone={setClient_phone} locator={locator}
                                    setLocator={setLocator} dataClient={dataClient}
                                    setDataClient={setDataClient}
                                    listPaymentReady={listPaymentReady}
                                    setlistPaymentReady={setlistPaymentReady}
                                    amountPaymentReady={amountPaymentReady}
                                    setAmountPaymenReady={setAmountPaymenReady}
                                    tableInfo={tableInfo}
                                    taxesArrayAll={taxesArrayAll}
                                 />
                              </div>

                              {/* Mobile menu Bar */}
                              <div className="item3 mobile-bar border-top py-2 overflow-auto">
                                 < div className=
                                    {
                                       !btnLayout.discountsPlus && !btnLayout.couponPlus && !btnLayout.pendingOrdersPlus && !btnLayout.returnPlus
                                          ? 'd-flex justify-content-center w-100 '
                                          : 'd-flex justify-content-between w-100 '

                                    }
                                 >

                                    {
                                       btnLayout.discountsPlus
                                          ? <>
                                             <ModalDiscounts
                                                actvPlus={btnLayout.discountsPlus}
                                                productChecked={productChecked}
                                                shoppingCart={shoppingCart}
                                                total={invoiceTotal}
                                                subtotal={invoiceSubtotal}
                                                itbms={invoiceTotalItbms}
                                                discount={invoiceTotalDiscount}
                                                setLoad={setEventHandler}
                                                setShoppingCart={setShoppingCart} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll} />
                                          </>
                                          : <></>
                                    }


                                    {
                                       btnLayout.couponPlus
                                          ? <>
                                             <ModalCoupons
                                                actvPlus={btnLayout.couponPlus}
                                                shoppingCart={shoppingCart}
                                                total={invoiceTotal}
                                                subtotal={invoiceSubtotal}
                                                itbms={invoiceTotalItbms}
                                                discount={invoiceTotalDiscount}
                                                setLoad={setEventHandler}
                                                setShoppingCart={setShoppingCart}
                                                taxesArrayAll={taxesArrayAll} />

                                          </>
                                          : <></>
                                    }

                                    {layoutConfig.mainContainer
                                       ?
                                       <button className="btn btn-outline-primary btn-sm border border-1 rounded-pill me-1" type="button" onClick={() => setShow(true)}>
                                          <i className="uil uil-plus-circle display-6 buttons-label icon-mobile h3"></i>
                                          <span className='label-responsive  hidden-label-sm'>   Menú </span>
                                       </button>
                                       : <></>}

                                    {
                                       btnLayout.pendingOrdersPlus
                                          ? <>
                                             <ModalPendingOrders
                                                actvPlus={btnLayout.couponPlus}
                                                preOrderInvoice={preOrderInvoice}
                                                setPreOrderInvoice={setPreOrderInvoice}
                                                setCheckDeliveryOrder={setCheckDeliveryOrder}
                                                setProductChecked={setProductChecked}
                                                setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} />

                                          </>
                                          : <></>
                                    }
                                    {
                                       btnLayout.returnPlus
                                          ? <>
                                             <ModalReturn
                                                actvPlus={btnLayout.returnPlus}
                                             />
                                          </>
                                          : <></>
                                    }
                                 </div>
                              </div>
                           </div>
                        </>
                        : <></>

                     }
                     {/* MODULO */}
                     {!layoutConfig.mainContainer
                        ? <>
                           <div className="item3 mobile-bar border-top shadow-sm py-2 ">
                              <div className="  ">
                                 <div className="mb-0 small lh-sm mt-1">
                                    <div className="d-flex justify-content-between">
                                       <small className="text-muted h6 subtitle"> Descuento:</small>
                                       <span className="text-warning text-secondary h6 subtitle" translate='no'>
                                          <span className="px-2 subtitle">- $</span>
                                          <strong> {invoiceTotalDiscount.toFixed(2)} </strong>
                                       </span>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                       <small className="text-muted h6 subtitle"> Subtotal: </small>
                                       <span className="text-black text-secondary h6 subtitle" translate='no'>
                                          <span className="px-2 subtitle">$</span>{invoiceSubtotal.toFixed(2)}</span>
                                    </div>

                                    {taxesArrayAll.map((tax) => (
                                       <>
                                          {tax.total > 0 ?
                                             <div className="d-flex justify-content-between">
                                                <small className=" text-muted h6 subtitle"> Impuesto {tax.tax}:
                                                </small><span className="text-black text-secondary h6 subtitle" translate='no'>
                                                   <span className="px-2 subtitle ">$</span>{tax.total.toFixed(2)}</span>
                                             </div> : <></>}
                                       </>
                                    ))}

                                    <div className="d-flex justify-content-between total-shopping">
                                       <strong className="text-black fw-bold h6 mt-1 subtitle"> Total <small>(Incluye itbms)</small>: </strong>
                                       <span className="text-black fw-bold h5 subtitle" translate='no'>
                                          <span className="px-2 ">$
                                          </span>{invoiceTotal.toFixed(2)}</span>
                                    </div>
                                 </div>
                                 <div className=' mb-0 '>
                                    <ModalPaymet
                                       preOrderInvoice={preOrderInvoice}
                                       dv={dv} name={name} ruc={ruc}
                                       setDv={setDv} setName={setName}
                                       setRuc={setRuc}
                                       dataResponse={dataResponse}
                                       optionalData={optionalData}
                                       setDataResponse={setDataResponse}
                                       setOptionalData={setOptionalData}
                                       shoppingCart={shoppingCart}
                                       total={invoiceTotal}
                                       subtotal={invoiceSubtotal}
                                       discount={invoiceTotalDiscount}
                                       tax={invoiceTotalItbms}
                                       setPageLoader={setPageLoader}
                                       dataPreOrder={dataPreOrder}
                                       checkDeliveryOrder={checkDeliveryOrder}
                                       registerTurnOrder={registerTurnOrder}
                                       setRegisterTurnOrder={setRegisterTurnOrder}
                                       client_phone={client_phone}
                                       setClient_phone={setClient_phone} locator={locator}
                                       setLocator={setLocator} dataClient={dataClient}
                                       setDataClient={setDataClient}
                                       listPaymentReady={listPaymentReady}
                                       setlistPaymentReady={setlistPaymentReady}
                                       amountPaymentReady={amountPaymentReady}
                                       setAmountPaymenReady={setAmountPaymenReady}
                                       tableInfo={tableInfo}
                                       taxesArrayAll={taxesArrayAll}
                                    />
                                 </div>
                              </div>
                              {/* Mobile menu Bar */}
                              <div className='mobile-bar border-top py-2 overflow-auto'>
                                 < div className='d-flex justify-content-between w-100 '>

                                    {
                                       btnLayout.discountsLite
                                          ? <>
                                             <ModalDiscounts
                                                actvPlus={btnLayout.discountsLite}
                                                productChecked={productChecked}
                                                shoppingCart={shoppingCart}
                                                total={invoiceTotal}
                                                subtotal={invoiceSubtotal}
                                                itbms={invoiceTotalItbms}
                                                discount={invoiceTotalDiscount}
                                                setLoad={setEventHandler}
                                                setShoppingCart={setShoppingCart} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll} />
                                          </>
                                          : <></>
                                    }

                                    {
                                       btnLayout.couponLite
                                          ? <>
                                             <ModalCoupons
                                                actvPlus={btnLayout.couponLite}
                                                shoppingCart={shoppingCart}
                                                total={invoiceTotal}
                                                subtotal={invoiceSubtotal}
                                                itbms={invoiceTotalItbms}
                                                discount={invoiceTotalDiscount}
                                                setLoad={setEventHandler}
                                                setShoppingCart={setShoppingCart}
                                                taxesArrayAll={taxesArrayAll} />

                                          </>
                                          : <></>
                                    }
                                    {/* Menu Lite Model */}
                                    {btnLayout.menuLite ?
                                       <button className="btn btn-outline-primary btn-sm border border-1 rounded-pill me-1" type="button" onClick={() => setShow(true)}>
                                          <i className="uil uil-plus-circle display-6 buttons-label icon-mobile h3"></i>
                                          <span className='label-responsive  hidden-label-sm'>   Menú </span>
                                       </button>
                                       : <></>}

                                    {
                                       btnLayout.pendingOrdersLite
                                          ? <>
                                             <ModalPendingOrders
                                                actvPlus={btnLayout.couponLite}
                                                preOrderInvoice={preOrderInvoice}
                                                setPreOrderInvoice={setPreOrderInvoice}
                                                setCheckDeliveryOrder={setCheckDeliveryOrder}
                                                setProductChecked={setProductChecked}
                                                setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll}
                                             />

                                          </>
                                          : <></>
                                    }

                                    {
                                       btnLayout.returnLite
                                          ? <>
                                             <ModalReturn
                                                actvPlus={btnLayout.returnLite}
                                             />
                                          </>
                                          : <></>
                                    }
                                 </div>
                              </div>

                           </div>
                        </>
                        : <></>

                     }
                     {/* MODULO */}
                  </div>
               </div>
               {/* MODULO */}
               <div className="container-pos-right no-scroll ">
                  <section className='h-100'>

                     {layoutConfig.mainContainer
                        ?
                        <>
                           {layoutConfig.mainContainer && <>
                              <div className="flex-container">
                                 <div className="item1 mt-5">
                                    <div className='d-flex justify-content-between'>
                                       <span className='fw-bold px-2'>
                                          <i className="uil uil-apps"></i>
                                          Menú
                                       </span>
                                    </div>

                                    {
                                       !categories.length
                                          ? ''//No hay categorias Asignadas
                                          : <>
                                             <div className="bg-light   position-relative categories-list-post rounded border border-inset ">
                                                <div className="container-list no-scroll mx-1">
                                                   <ul>
                                                      <li>
                                                         <div className="button-container ">
                                                            <input type="radio" className="btn-check"
                                                               name="options-outlined"
                                                               key={0} id="cateforieAll"
                                                               value={"todos"}
                                                               onChange={(e) => selectCategorie(e.target.value)}
                                                            />
                                                            <label className="btn btn-outline-primary rounded-pill border" htmlFor="cateforieAll">
                                                               <img className='rounded-circle' src={all_products} alt="Imagen 1" />
                                                               Ver Todos
                                                            </label>
                                                         </div>
                                                      </li>
                                                      <li>
                                                         <div className="button-container ">
                                                            <input type="radio" className="btn-check"
                                                               name="options-outlined"
                                                               id="promociones"
                                                               value={"promociones"}
                                                               onChange={(e) => selectCategorie(e.target.value)}
                                                            />
                                                            <label className="btn btn-outline-primary rounded-pill border" htmlFor="promociones">
                                                               <img className='rounded-circle' src={promotion_img} alt="Imagen 2" />
                                                               Promociones
                                                            </label>
                                                         </div>
                                                      </li>
                                                      {listCategories()}
                                                   </ul>
                                                </div>
                                                <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                                                   Categorias
                                                </span>
                                             </div>
                                          </>
                                    }

                                 </div>

                                 <div className="item2 py-0 px-1">
                                    <div className=" px-1  bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-light" >
                                       {/* <div className='row g-2  no-scroll pt-1'>
                                          {!filteredProducts.length
                                             ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                                             : filteredProducts.map((product) => (
                                                <>
                                                   <div className="col-4 col-md-4 col-lg-2"
                                                      data-product={JSON.stringify(product)}
                                                      onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                                   >
                                                      <div className="card border shadow-sm ">
                                                         <img src={product.img} className="card-img-top-sp rounded" alt="Producto" />

                                                         <div className="card-body">
                                                            <h5 className="title-product-pos h6 text-center text-dark"> {product.name}</h5>
                                                            <h6 className="card-text text-center text-success fw-bold" translate='no'>${product.price}</h6>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </div> */}
                                       <div className='row  g-2  no-scroll menu-pre '>
                                          {!filteredProducts.length
                                             ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                                             : filteredProducts.map((product) => (
                                                <>
                                                   <div className="col-4 col-xs-3 col-sm-3 col-md-3 col-lg-2 border-product"
                                                      data-product={JSON.stringify(product)}
                                                      onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                                   >
                                                      <div className="card border-0 shadow-sm rounded-sp ">
                                                         <img src={product.img === null || product.img === '' ? businessLogo : product.img} className="card-img-pre-acoount" alt="Product" loading='lazy' />

                                                         <div className="card-body">
                                                            <h5 className="card-title text-center fw-bold text-truncate text-dark"> {product.name}</h5>
                                                            <h6 className="card-text text-center text-success fw-bold">${product.price}</h6>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </>
                                             ))
                                          }
                                       </div>
                                    </div>
                                 </div>
                                 <div className="item3 d-flex justify-content-end text-muted ">
                                    <small className='brand-text position-absolute bottom-0 end-0'>
                                       Powered By Smart Retail Group
                                    </small>
                                 </div>
                              </div>
                           </>}
                        </>
                        :
                        <>
                           <div className="flex-container">
                              <header className='mx-1'>
                                 <nav className="px-1 fixed-top-sp border-bottom">
                                    <div className="">
                                       <div className="d-flex  align-items-center justify-content-between">
                                          <div className='d-flex  bd-highlight  align-items-center'>
                                             <span className="h5 pt-2 me-3 fw-bold align-self-center title-1">Gestión de Transacciones</span>
                                          </div>
                                          <div>
                                             {
                                                jsonConfig.buttons.menu.topNav

                                                   ?
                                                   <>
                                                      <button className='btn btn-outline-primary btn-sm border border-1 rounded-pill mx-1' onClick={() => setShow(true)}>
                                                         <i className="uil uil-bars"></i>
                                                         <small className='fw-bold fw-bold mx-1 menu-label'>Productos</small>
                                                      </button>
                                                   </>
                                                   :
                                                   <>
                                                   </>
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </nav>
                              </header>
                              {/* <button className='btn btn-primary btn-sm'
                                 onClick={toggleContenedor1} >

                              Menu lIte de bootnes de menu 

                              </button> */}
                              <div className="item1">
                                 <div className="d-flex flex-row  justify-content-start overflow-auto border-end border-start border-2">
                                    {

                                       !layoutConfig.mainContainer

                                          ?
                                          <>

                                             {
                                                btnLayout.menuLite

                                                   ?
                                                   <>
                                                      <div className="  m-1  bg-white border rounded">
                                                         <button className="btn btn-outline-primary  py-2 border-0" type="button"
                                                            onClick={() => setShow(true)}
                                                         >
                                                            <i className="uil uil-list-ul buttons-label icon-mobile h3"></i>
                                                            <span className='h5 fw-bold mx-1 d-block text-center'>
                                                               Menú
                                                            </span>
                                                         </button>
                                                      </div>
                                                   </>
                                                   :
                                                   <>
                                                   </>
                                             }

                                             {/* Btn Lite Version  */}
                                             {
                                                btnLayout.discountsLite
                                                   ?
                                                   <>
                                                      <ModalDiscounts actvLite={btnLayout.discountsLite} productChecked={productChecked} shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} itbms={invoiceTotalItbms} discount={invoiceTotalDiscount} setLoad={setEventHandler} setShoppingCart={setShoppingCart} setProductChecked={setProductChecked} taxesArrayAll={taxesArrayAll} />
                                                   </>
                                                   :
                                                   <>
                                                   </>
                                             }



                                             {/* Btn Lite Version  */}
                                             {
                                                btnLayout.couponLite
                                                   ?
                                                   <>
                                                      <ModalCoupons actvLite={btnLayout.couponLite} shoppingCart={shoppingCart} total={invoiceTotal} subtotal={invoiceSubtotal} itbms={invoiceTotalItbms} discount={invoiceTotalDiscount} setLoad={setEventHandler} setShoppingCart={setShoppingCart} taxesArrayAll={taxesArrayAll} />
                                                   </>
                                                   :
                                                   <>
                                                   </>
                                             }


                                             {/* Btn Lite Version  */}
                                             {
                                                btnLayout.pendingOrdersLite
                                                   ?
                                                   <>
                                                      <ModalPendingOrders actvLite={btnLayout.pendingOrdersLite} preOrderInvoice={preOrderInvoice} setPreOrderInvoice={setPreOrderInvoice} setCheckDeliveryOrder={setCheckDeliveryOrder} setProductChecked={setProductChecked} setPageLoader={setPageLoader} setEventHandler={setEventHandler} shoppingCart={shoppingCart} setDv={setDv} setName={setName} setRuc={setRuc} setDataResponse={setDataResponse} setOptionalData={setOptionalData} setShoppingCart={setShoppingCart} setInvoiceSubtotal={setInvoiceSubtotal} setInvoiceTotal={setInvoiceTotal} setInvoiceTotalDiscount={setInvoiceTotalDiscount} setInvoiceTotalItbms={setInvoiceTotalItbms} setDataPreOrder={setDataPreOrder} setTableInfo={setTableInfo} setTaxesArrayAll={setTaxesArrayAll} />
                                                   </>
                                                   :
                                                   <>
                                                   </>
                                             }

                                             {/* Btn Lite Version  */}
                                             {
                                                btnLayout.returnLite
                                                   ?
                                                   <>
                                                      <ModalReturn actvLite={btnLayout.returnLite} />
                                                   </>
                                                   :
                                                   <>
                                                   </>
                                             }

                                          </>
                                          :
                                          <>

                                          </>
                                    }
                                    {/* <div className="  m-1  bg-white border rounded">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button" onClick={toggleContenedor1}>
                                          <i className="uil uil-list-ul buttons-label icon-mobile h3"></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>
                                             Menú
                                          </span>
                                       </button>
                                    </div> */}
                                    {/* <div className=" m-1 bg-white border rounded ">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button">
                                          <i className="uil uil-percentage buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>  Descuentos </span>
                                       </button>
                                    </div>
                                    <div className="m-1 bg-white border rounded ">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button">
                                          <i className="il uil-history-alt buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>
                                             Devolución
                                          </span>
                                       </button>
                                    </div> */}
                                    {/* <div className="m-1 bg-white border rounded">
                                       <button className="btn btn-outline-primary  py-2 border-0" type="button">
                                          <i className="uil uil-ticket buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center d-block text-center'>
                                             Cupones
                                          </span>
                                       </button>
                                    </div>

                                    <div className="m-1 bg-white border rounded">
                                       <button className="btn btn-outline-primary py-2 border-0" type="button">
                                          <i className="uil uil-book-reader buttons-label icon-mobile h3 "></i>
                                          <span className='h5 fw-bold mx-1 d-block text-center'>
                                             Mesas
                                          </span>
                                       </button>
                                    </div> */}
                                 </div>
                              </div>
                              <div className='item2 '>

                              </div>
                              <div className="item3 m-1 py-0 px-1 border mx-2 shadow-sm bg-white">
                                 <div className='m-2'>
                                    <h4 className='fw-bold  text-primary'>
                                       <i className="uil uil-shopping-cart"></i> Información de Orden
                                    </h4>

                                    <Totals
                                       count={shoppingCart}
                                       discount={invoiceTotalDiscount}
                                       subtotal={invoiceSubtotal}
                                       itbms={invoiceTotalItbms}
                                       total={invoiceTotal}
                                    />
                                    <div className=' mb-0'>
                                       <ModalPaymet
                                          preOrderInvoice={preOrderInvoice}
                                          dv={dv} name={name} ruc={ruc}
                                          setDv={setDv} setName={setName}
                                          setRuc={setRuc}
                                          dataResponse={dataResponse}
                                          optionalData={optionalData}
                                          setDataResponse={setDataResponse}
                                          setOptionalData={setOptionalData}
                                          shoppingCart={shoppingCart}
                                          total={invoiceTotal}
                                          subtotal={invoiceSubtotal}
                                          discount={invoiceTotalDiscount}
                                          tax={invoiceTotalItbms}
                                          setPageLoader={setPageLoader}
                                          dataPreOrder={dataPreOrder}
                                          checkDeliveryOrder={checkDeliveryOrder}
                                          registerTurnOrder={registerTurnOrder}
                                          setRegisterTurnOrder={setRegisterTurnOrder}
                                          client_phone={client_phone}
                                          setClient_phone={setClient_phone} locator={locator}
                                          setLocator={setLocator} dataClient={dataClient}
                                          setDataClient={setDataClient}
                                          listPaymentReady={listPaymentReady}
                                          setlistPaymentReady={setlistPaymentReady}
                                          amountPaymentReady={amountPaymentReady}
                                          setAmountPaymenReady={setAmountPaymenReady}
                                          tableInfo={tableInfo}
                                          taxesArrayAll={taxesArrayAll}
                                       />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </>
                     }
                  </section>
               </div>
               {/* MODULO */}
            </main>
         </div >

         {/* Modal-Products Information*/}
         <Modal Modal
            show={showModalSelectItem}
            fullscreen
            onHide={() => setShowModalSelectItem(false)}>
            <Modal.Header>
               <h5 className="modal-title" id="exampleModalLabel">Detalles de Producto</h5>
            </Modal.Header>
            <Modal.Body>
               <div className='row'>
                  {/* Product's Information*/}
                  <div className='col-12 col-md-5 col-lg-4  py-1 px-2 '>
                     <div className='position-info-suggest m-2'>
                        {/*<div className='position-fixed-info'>
                                 {contentProduct()}
                              </div>*/}
                        <div className='m-5 p-2'>
                           {contentProduct()}
                        </div>
                        <div className='mt-4 row'>
                           {viewSelectSegmentItem()}
                        </div>
                     </div>
                  </div>
                  {/* Product's Suggestions */}
                  <div className='col-12 col-md-7 col-lg-8  px-2'>
                     <div className='suggestions-over'>
                        <div className='suggestions-wrapper no-scroll h-100'>
                           {contentSegments()}
                        </div>
                     </div>
                     <Form>
                        <Form.Group className="m-3" controlId="exampleForm.ControlTextarea1">
                           <Form.Label>Notas:</Form.Label>
                           <Form.Control as="textarea" rows={3} onChange={(e) => (setNotesItem(e.target.value))} />
                        </Form.Group>
                     </Form>
                  </div>
               </div>

            </Modal.Body>
            <Modal.Footer>
               <button type="button" className="btn btn-danger btn-lg" onClick={() => (setShowModalSelectItem(false), setPreviewSegment([]))}>Cancelar</button>
               <button type="button" className="btn btn-success btn-lg" onClick={e => (setShowModalSelectItem(false), setShow(false), addProduct(e))}>Agregar</button>
            </Modal.Footer>
         </Modal>


         {/* Modal Mobile */}
         <Modal
            show={show}
            className='rouneded'
            onHide={() => setShow(false)}
            fullscreen
            aria-labelledby="example-custom-modal-styling-title"
         >
            <Modal.Header closeButton className='py-1'>
               <Modal.Title >
                  Menú
               </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
               <div className="flex-container">
                  <div className='sticky-top sticky-sm-top bg-white'>
                     <div className='d-flex justify-content-around'>
                        <small className='text-dark fw-bold'>
                           <i className="uil uil-search-alt"></i>
                           Buscar
                        </small>
                        <form className="button-container">
                           <div>
                              <input
                                 id="searchInput"
                                 spellcheck="false"
                                 className="form-control rounded-pill form-control-sm mx-2 bg-gray"
                                 type="text"
                                 step="any"
                                 placeholder="Buscar productos..."
                                 onChange={e => setSearchTerm(e.target.value)}
                              />
                           </div>
                        </form>
                     </div>
                     {/* <div className='flex-fill bd-highlight me-2 mb-4'>

                     </div> */}
                     <div className="item1">
                        <div className='d-flex justify-content-start'>
                           <small className='text-dark fw-bold'>
                              <i className="uil uil-apps"></i>
                              Categorias
                           </small>
                        </div>

                        {
                           !categories.length
                              ? ''//No hay categorias Asignadas
                              : <>
                                 <div className="bg-light   position-relative categories-list-post rounded border border-inset ">
                                    <div className="container-list no-scroll mx-1">
                                       <ul>
                                          <li>
                                             <div className="button-container ">
                                                <input type="radio" className="btn-check"
                                                   name="options-outlined"
                                                   key={0} id="cateforieAll"
                                                   value={"todos"}
                                                   onChange={(e) => selectCategorie(e.target.value)}
                                                />
                                                <label className="btn btn-outline-primary rounded-pill border" htmlFor="cateforieAll">
                                                   <img className='rounded-circle' src={all_products} alt="Imagen 1" loading='lazy' />
                                                   Ver Todos
                                                </label>
                                             </div>
                                          </li>

                                          <li>
                                             <div className="button-container ">
                                                <input type="radio" className="btn-check"
                                                   name="options-outlined"
                                                   id="promociones"
                                                   value={"promociones"}
                                                   onChange={(e) => selectCategorie(e.target.value)}
                                                />
                                                <label className="btn btn-outline-primary rounded-pill border" htmlFor="promociones">
                                                   <img className='rounded-circle' src={promotion_img} alt="Imagen 2" loading='lazy' />
                                                   Promociones
                                                </label>
                                             </div>
                                          </li>
                                          {listCategories()}
                                       </ul>
                                    </div>
                                    {/* <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                                          Categorias
                                       </span> */}
                                 </div>
                              </>
                        }
                     </div>
                  </div>


                  <div className="item2 py-0 px-1">
                     <div className='d-flex justify-content-start pt-3'>
                        <small className='text-dark fw-bold'>
                           <i className="uil uil-apps"></i>
                           Productos
                        </small>
                     </div>
                     <div className=" px-1 pb-4 bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-light" >
                        {/* <div className='row g-2  no-scroll pt-1'>
                           {!filteredProducts.length
                              ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                              : filteredProducts.map((product) => (
                                 <>
                                    <div className="col-4 col-md-2 col-lg-2 col-xl-2 col-xxl-1"
                                       data-product={JSON.stringify(product)}

                                       onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                    >
                                       <div className="card border shadow-sm ">
                                          <img src={product.img} className="card-img-top-sp rounded" alt="Producto" />

                                          <div className="card-body">
                                             <h5 className="title-product-pos h6 text-center text-dark"> {product.name}</h5>
                                             <h6 className="card-text text-center text-success fw-bold">${product.price}</h6>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              ))
                           }
                        </div> */}
                        <div className='row  g-2  no-scroll menu-pre '>
                           {!filteredProducts.length
                              ? <Empty title="Vacío" msg="No hay ningun producto asignado en esta categoria" img={sad_products} />
                              : filteredProducts.map((product) => (
                                 <>
                                    <div className="col-4 col-xs-3 col-sm-3 col-md-2 col-lg-1 border-product"
                                       data-product={JSON.stringify(product)}
                                       onClick={(e) => (setShowModalSelectItem(true), selectProductoInfo(product))}
                                    >
                                       <div className="card border-0 shadow-sm rounded-sp ">
                                          <img src={product.img} className="card-img-pre-acoount" alt="Product" loading='lazy' />

                                          <div className="card-body">
                                             <h5 className="card-title text-center fw-bold text-truncate text-dark"> {product.name}</h5>
                                             <h6 className="card-text text-center text-success fw-bold">${product.price}</h6>
                                          </div>
                                       </div>
                                    </div>
                                 </>
                              ))
                           }
                        </div>
                     </div>

                  </div>
               </div>

            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                  Cerrar
               </Button>
            </Modal.Footer>
         </Modal>



      </>
   )
}

export default PaymentPost
