import React from 'react'
import invoice from '../img/icons/receipt.png'

function MachineCard({machine_name,  machine_code, machine_type}) {
   return (
      <>
         <div className=" px-4 py-3 border-bottom" key={machine_code}>
            <div className=' d-flex gap-4'>
               <img className="flex-shrink-0  " src={invoice} alt="twbs" width="32" height="32" />
               <div className="d-flex gap-2 w-100 justify-content-between">
                  <div>
                     <h6 className="mb-0 text-muted">Maquina: {machine_name}</h6>
                     <h6 className='mt-1'>Codigo:<strong > {machine_code}</strong ></h6>
                  </div>
                  <div>
                     <small className="text-nowrap text-primary fw-bold"> {machine_type} </small>
                  </div>
                  <small className="text-nowrap text-success">
                     <div> <i className="fa-solid fa-check"></i> En linea</div>
                  </small>
               </div>
            </div>

         </div>

      </>
   )
}

export default MachineCard