import React, { useState, useEffect } from 'react'
//Component
import Header from "./components/Header"
import ModalKioskLanguage from './ModalKioskLanguage';
import { OrdersFetchAPI } from '../../api/Orders';
import Footer from './components/Layout/Footer';
import { RenderIf } from '../../components/utils/RenderIf';
import ModalOrderDetails from './ModalOrderDetails';

//Icons
import { UilUtensils, UilShoppingBag } from '@iconscout/react-unicons'
import refund from './assets/img/refund.png'
import invoice from './assets/img/receipt.png'

function HistoryTransaction({ icon, background, data, nextStep, skipToStep3, setStep, preOrder, preOrderInvoice, historyTransaction }) {

  const [isButtonFocusedId, setButtonFocusId] = useState(null);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [showModalOrderDetails, setShowMOdalOrderDetails] = useState(false);
  const [orderData, setOrderData] = useState({ transaction_code: 0, branchoffice: { name: "" }, discount: 0, subtotal: 0, tax: 0, total: 0, groups: [], taxes: [] });

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };



  const getOrder = async (data) => {
    try {
      let token = localStorage.getItem('x-access-machine-token');
      const response = await OrdersFetchAPI.getOrderById(data._id, token)
      console.log(response.data.order);
      setOrderData(response.data.order)
      setShowMOdalOrderDetails(true)
    } catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
  }, [])

  return (
    <>
      <section className='kiosk-delivery'
        style={{ backgroundImage: `url(${background})` }}>
        {/* Headaer */}
        <div className='d-flex justify-content-between align-items-center'>
          <Header
            classContainer={"kiosk-delivery_header m-0 p-0"}
            classImg={"kiosk-delivery_header_img"}>
          </Header>

        </div>
        {/* Content */}
        <div className='kiosk-delivery_section margin-tp '>
          <h2 className='kiosk-delivery_title  text-center '>
            Transacciones del día

          </h2>
          {/* TODO: Hacer componente la parte de los botones de delivery  */}

          <div className='d-flex align-items-center justify-content-center' >

            <div className=' kiosk-content-transactions'>

              {

                !historyTransaction.orders.length
                  ? <> 
                  
                  <div className='d-flex justify-content-between align-items-center border p-5 mt-5 bg-blur-sp-2 rounded-4'>
                      <h2 className='fw-bold kiosk-subtitle-md text-dark '>
                        No hay Transacciones aún
                      </h2>
                  </div>
                  
                   </>
                  : <>

                    {historyTransaction.orders.map((order) => (
                      <>

                        <div className="d-flex align-items-center border-history justify-content-between shadow-sm  p-3 bg-white m-5" onClick={() => (getOrder(order))} >
                          <div className="flex-shrink-0 p-2">
                            <img src={invoice} alt="En progreso" width="150" height="150" />
                          </div>
                          <div className="flex-grow-2 m-3">
                            <div className='mt-2 me-2'>
                              <h3 className='mt-1 kiosk-text-card'><strong > {order.table.name} </strong ></h3>
                              <h5 className="mb-0 text-muted kiosk-text-card">Orden cerrada #{order.transaction_code} </h5>
                              <h5 className='mt-1 kiosk-text-card'>Metodo pago:<strong > {order.paymenthod} </strong ></h5>

                            </div>
                          </div>
                          <h1 className='mt-1 kiosk-text-card'>Total: <br></br><strong > ${order.total.toFixed(2)} </strong ></h1>
                        </div>
                      </>
                    ))}
                  </>
              }



            </div>
          </div>




          <div className='position-absolute w-100 d-flex 
          justify-content-between align-items-center  bottom-0 start-0'>
            <button
              type="button"
              onClick={() => setStep(0)}
              className={`m-0 btn-lg  shadow-lg border-0
            button-ft_1 position-relative ms-3`}

              title='Regresar'
            >
              <svg xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                className=""
                id="left-arrow">
                <path
                  d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path></svg>



            </button>



            <button
              className={`btn btn-success kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-75 position-relative rounded-4`}


            >Total:  ${historyTransaction.total.toFixed(2)}


            </button>

          </div>

        </div>
        <ModalOrderDetails icon={icon} orderData={orderData} showModalOrderDetails={showModalOrderDetails} setShowMOdalOrderDetails={setShowMOdalOrderDetails}></ModalOrderDetails>
      </section>


    </>
  )
}

export default HistoryTransaction