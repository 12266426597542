//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Components 
import Navbar from '../NavBar/Navbar';
import TransactionHistory from "../transactions/TransactionHistory";
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import Modal from 'react-bootstrap/Modal';
//Internal components 
import ReportTable from '../reports/table/ReportTable';
import OrderDetails from '../Orders/OrderDetails';

//External modules 
import moment from 'moment-timezone';

//API
import { OrdersFetchAPI } from '../../api/Orders';

//img
import empty from '../img/icons/empty.png'

//Utils 
import { RenderIf } from '../utils/RenderIf';

function AnaliticStore() {
  //Params
  let { business_code } = useParams();
  //We get the user data 
  let user = localStorage.getItem('user') !== '' || localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : '';
  let token = localStorage.getItem('x-access-token')
  //States
  const [branchOrdersData, setBranchOrdersData] = useState([]);
  const [branchAnaliticsData, setBranchAnaliticsData] = useState([]);
  const [temporal, setTemporal] = useState([]);
  const [minDate, setMinDate] = useState(new Date().toJSON().slice(0, 10));
  const [userBranchoffices, setUserBranchs] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({
    code: 0
  });
  const [ordersPaymentMethod, setOrdersPaymentMethod] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [detailOrderModalShow, setDetailOrderModalShow] = useState(false);
  const [detailOrder, setDetailOrder] = useState();
  const [order_type, setOrderType] = useState("temporal")
  const [reload, setReLoad] = useState(0);

  //Variables
  //We get the actual date 
  const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')
  //We put the actual date in rank of all the day 
  const maxDate = actualDate + 'T23:59:59.000+00:00';

  //Functions to show and hide the modal 
  const handleCloseModal = () => setModalShow(false);
  const handleShowModal = () => setModalShow(true);
  const handleCloseDetailOrderModal = () => setDetailOrderModalShow(false);
  const handleShowDetailOrderModal = () => setDetailOrderModalShow(true);


  //Function to gen the empty card to the transaction history 
  const EmptyCard = () => {
    return (
      <div className="m-3 w-auto ">
        <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
          <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
            <img src={empty} alt="Vacio" width="35px" height="35px" />
            <div className='text-center mt-3'>
              <h5>Vacío</h5>
              <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
            </div>
          </div>
        </div>
      </div>
    )
  }

  //Function to populate the list orders 
  const listOrders = () => {

    const listOrders = branchOrdersData.map((data) => (
      <>
        <div onClick={async () => {
          await getOrderById(data._id);
          //We show the detail modal 
          handleShowDetailOrderModal()
        }}>
          <TransactionHistory data={data} />
        </div>
      </>
    ));
    //We check if the list orders has data 
    if (listOrders.length === 0) {
      return EmptyCard()
    } else {
      return (listOrders)
    }
  }


  //Function to get order by id (order_id)
  const getOrderById = async (order_id) => {
    try {
      const response = await OrdersFetchAPI.getOrderById(order_id);
      setDetailOrder(response.data.order)
      setOrderType("permanent")
    } catch (err) {
      setDetailOrder({})
      setOrderType("permanent")
      console.log(err)
    }
  }

  //Function to populate the order details (modal)
  const detailOrderList = () => {
    return detailOrder === undefined ? "" : <OrderDetails orderData={detailOrder} order_type={order_type} business_data={user.business} setLoad={setReLoad} />
  }

  //We get the transactions by branchoffice 
  const getTransactionsByBranchOffice = async (business_code, branchoffice_code, minDate, maxDate) => {
    try {
      //We show the loading data alert 
      //LoadingAlert()
      //We call the orders of the branchoffice 
      const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, branchoffice_code, minDate, maxDate, 0, user.id, token);
      setBranchOrdersData(response.data.orders);
      setTemporal(response.data.orders);
      setBranchAnaliticsData([response.data.analitics]);
    } catch (err) {
      //We show the error alert 
      ErrorAlert('No tiene información')
      console.log(err);
    }
  }

  //Function to search any result on the search filter 
  function handleChangeFiltro(event) {
    let resultFilter = [];
    if (event.target.value == "") {
      console.log(event.target.value);
      setBranchOrdersData(temporal);
    } else {
      for (
        let recorrer = 0;
        recorrer < branchOrdersData.length;
        recorrer++
      ) {
        const examinar = branchOrdersData[recorrer];

        const info = JSON.stringify(examinar);
        const resultado = info
          .toLowerCase()
          .includes(event.target.value.toLowerCase());

        if (resultado == true) {
          resultFilter.push(examinar);

          setBranchOrdersData(resultFilter);
          console.log(resultFilter);
        }
      }
    }
  }


  //Fucntion to populate cards count 
  const CardsCounts = (data) => {
    const getdata = data;
    const content = getdata.map((data) => (
      <>
        <div className="col-xl-4 col-md-6 col-sm-6 col-6 mb-4 ">
          <div className="card border rounded-sp shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div className="align-self-center">
                  <RenderIf isTrue={data.name.toLowerCase() === 'visa'}>
                    <i className="fa-brands fa-cc-visa text-primary fa-3x"></i>
                  </RenderIf>
                  <RenderIf isTrue={data.name.toLowerCase() === 'yappy' || data.name.toLowerCase() === 'yappy qr'}>
                    <i className="fa-solid fa-comments text-secondary fa-3x"></i>
                  </RenderIf>
                  <RenderIf isTrue={data.name.toLowerCase() === 'mastercard'}>
                    <i className="fa-brands fa-cc-mastercard text-warning fa-3x"></i>
                  </RenderIf>
                  <RenderIf isTrue={data.name.toLowerCase() === 'clave'}>
                    <i className="fa-solid fa-credit-card text-danger fa-3x"></i>
                  </RenderIf>
                  <RenderIf isTrue={data.name.toLowerCase() === 'efectivo'}>
                    <i className="fa-solid fa-sack-dollar text-success fa-3x"></i>
                  </RenderIf>
                  <RenderIf isTrue={data.name.toLowerCase() === 'amex'}>
                    <i className="fa-brands fa-cc-amex text-info fa-3x"></i>
                  </RenderIf>
                  <RenderIf isTrue={data.name.toLowerCase() === 'nota de credito' || data.name.toLowerCase() === 'credito'}>
                    <i className="fa-solid fa-clipboard text-secondary fa-3x"></i>
                  </RenderIf>
                </div>
                <div className="text-end">
                  <h3>{parseFloat(data.total).toFixed(2)} <span>$</span></h3>
                  <p className="card-title text-muted fw-bold"> {data.name}</p>
                </div>
              </div>
              <div className="card-read-more" onClick={e => { setModalShow(true); getPaymentMethodOrders(data.paymentmethod_id) }}>
                <a className="btn btn-link btn-block">Detalles</a>
              </div>
            </div>
          </div>
        </div>
      </>
    ))
    return (
      <>
        {content}
      </>
    )
  }

  //Fucntion to populate cards bra 
  const branchofficeCards = (data) => {
    const getdata = data;
    const content = getdata.map((data) => (
      <>
        <div className="col-xl-4 col-md-6 col-sm-6 col-6 mb-4 ">
          <div className="card border rounded-sp shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between px-md-1">
                <div className="align-self-center">
                  <i className="fa-solid fa-building text-success fa-3x"></i>
                </div>
                <div className="text-end">
                  <h3>{parseFloat(data.total).toFixed(2)} <span>$</span></h3>
                  <p className="card-title text-muted fw-bold"> {data.branchoffice_name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ))
    return (
      <>
        {content}
      </>
    )
  }


  //Function to get the payment method orders 
  const getPaymentMethodOrders = async (paymentmethod_id) => {
    try {
      //We call the orders of the branchoffice 
      const response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethod(business_code, selectedBranch.code, minDate, maxDate, paymentmethod_id, token);
      console.log(response)
      setOrdersPaymentMethod(response.data.orders)
    } catch (err) {
      console.log(err)
    }
  }

  //Function to populate the repor table modal 
  const reportDetail = (general_sales) => {
    return <ReportTable table_name="Tabla de ventas detalladas" table_headers={["Fecha", "Hora", "Orden", "Total", "Subtotal", "Itbms", "Descuento", "Cufe"]} table_type="paymentmethod_report" table_data={general_sales} />

  }

  //Function to view analitics 
  const viewAnalitics = () => {

    const content = branchAnaliticsData.map((data) => (
      <>
        <div className="row justify-content-md-center mt-4">
          {/* <h4> Ventas </h4> */}
          <div className="col-xl-8 col-lg-12 ">
            <div className="card-stats mb-4 mb-xl-0 shadow-sm border rounded-sp p-3 bg-white">
              <div className="card-body">
                <div className="row justify-content-md-center">
                  <div className="col-12 mb-4">
                    <div className='d-flex justify-content-between'>
                      <h5 className="card-title ">Total de Ventas</h5>
                      <span className="text-muted " id="analitycs-date-card"><i className="fa-solid fa-calendar-days"></i>  </span>
                    </div>
                    <div className="text-center my-3">
                      <span className="h1 font-weight-bold" id="">{(!data.totalSales) ? <>0.00</> : <>{parseFloat(data.totalSales).toFixed(2)}</>} <span>$</span></span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className='d-flex justify-content-around'>
                      <div>
                        <h6 className="card-title  ">Total Neto</h6>
                        <div className="h3">
                          <span id="">{(!data.subTotalSales) ? <>0.00</> : <>{parseFloat(data.subTotalSales).toFixed(2)}</>}</span> $
                        </div>
                      </div>
                      <div>
                        <h6 className="card-title ">Impuestos</h6>
                        <div className="h3">
                          <span id="">{(!data.totalTaxSales) ? <>0.00</> : <> {parseFloat(data.totalTaxSales).toFixed(2)}</>} </span> $
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- 2 --> */}
        <div className="mt-lg-3 ">
          <div className="row mt-2 border-sp pb-4 bg-white">
            <h4> Métodos de pagos  </h4>
            <div className="main-content">
              <div className="row justify-content-md-center ">
                {CardsCounts(data.paymenthods)}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- 2 --> */}
        <div className="mt-lg-3 ">
          <div className="row mt-2 border-sp pb-4 bg-white">
            <h4> Sucursales  </h4>
            <div className="main-content">
              <div className="row justify-content-md-center ">
                {branchofficeCards(data.branchofficeTotals)}
              </div>
            </div>
          </div>
        </div>
      </>

    ));
    return (<>{content}</>)
  }

  //Function to populate the users branch 
  const branchList = () => {
    const listBracnh = userBranchoffices.map((branch) => (
      <>
        <option value={branch.code}>{branch.branchoffice_name}</option>
      </>
    ));
    return listBracnh
  }

  //Hook to charge data when the page is loaded 
  useEffect(() => {
    (async () => {
      try {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We search the branch orders 
        getTransactionsByBranchOffice(business_code, selectedBranch.code, actualDate, maxDate)
      } catch (err) {
        console.log(err);
      }
    })();
    setReLoad(0)
  }, [reload]);


  return (
    <>
      <Navbar />

      <div className="container">
        <div className='smart-pay-main'>
          <div className='d-flex justify-content-center'>
            <h2 className='fs-2'>Analíticas </h2>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className='col-12 col-md-12 col-xl-8 col-lg-6 '>
            <div className='row justify-content-md-center'>
              <h4> Filtro </h4>
              <div className="col-12 col-md-12 col-xl-8 col-lg-12 ">
                <div className="border rounded-sp p-3 bg-white card-stats mb-xl-0">
                  <div>
                    <h6>Sucursal</h6>
                    <div className="login-form needs-validation">
                      <div className="row">
                        <div className="col-12">
                          <div className="">
                            <select onChange={e => setSelectedBranch({ code: e.target.value })} className='form-select' name="user_branch" id="user_branch">
                              <option value={0}>Mostrar todos</option>
                              {branchList()}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <h6>Fecha</h6>
                    <div className="login-form needs-validation">
                      <div className="row">
                        <div className="col-8">
                          <div className="">
                            <input
                              onChange={(e) => setMinDate(e.target.value)}
                              type="date" name="date" id="" className="control mt-3" />
                          </div>
                        </div>
                        <div className="col-4">
                          <button className="btn btn-success rounded mt-3" onClick={e => getTransactionsByBranchOffice(business_code, selectedBranch.code, minDate, `${minDate}T23:59:59.000+00:00`)} id="btn-analitycs">Aceptar</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {viewAnalitics()}


          </div>
          <div className='col-10 col-md-12 col-xl-4 col-lg-12 '>
            <h4> Historial de transacciones  </h4>

            <div className="w-auto bg-light px-3 py-2 border rounded mt-4">
              <div className="border-0" >
                {/* <i className="uil uil-money-stack"></i> */}
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="mb-0 text-secondary fw-bold">Buscador</h6>
                  <div className='border-0 fw-bold fs-3'>
                    <input
                      spellcheck="false"
                      className="form-control"
                      type="text"
                      step="any"
                      onChange={handleChangeFiltro}
                      placeholder="Buscar orden "
                    />

                  </div>
                </div>
              </div>
            </div>

            <div className='transaction-wrapper-3 border border-history '>
              <div className="list-group ">
                {listOrders()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal of report details orders (table) payment methods*/}
      <Modal className="modal-lg" show={modalShow} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reportDetail(ordersPaymentMethod)}
        </Modal.Body>
      </Modal>

      <Modal className="modal" show={detailOrderModalShow} onHide={handleCloseDetailOrderModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailOrderList()}
        </Modal.Body>
      </Modal>

    </>
  )
}

export default AnaliticStore