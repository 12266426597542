import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class BranchOfficeFetchAPI {
    //Function to get branchoffice data by code
    static async getBranchOfficeByCode(business_code, branchoffice_code) {
        const res = await server(`/branchoffice/${branchoffice_code}/business/${business_code}`, {
            method: "get"
        });
        return res
    }

    //Function to get branchoffices by business
    static async getBranchOfficesByBusiness(business_id) {
        const res = await server(`/business/${business_id}/branchoffice`, {
            method: "GET"
        })
        return res
    }

    //Function to get branchoffices by business
    static async getBranchOfficesByid(branchoffice_id, token) {
        const res = await server(`/branchoffice/${branchoffice_id}`, {
            method: "get",
            headers: {'x-access-token': token },
        })
        return res
    }

      //Function to update branchoffices 
      static async updateBranchoffice(branchoffice_id, data, token) {
        const res = await server(`/branchoffice/${branchoffice_id}`, {
            method: "put",
            data: data,
            headers: {'x-access-token': token },
        })
        return res
    }



}