import React, { useEffect, useState } from 'react'
import {useParams, Link } from 'react-router-dom'
// import NavBar from '../NavBar'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import logo_sp from '../img/logos/icon-black.png';
import Row from 'react-bootstrap/Row';

import { BusinessFetchAPI } from '../../api/ecommers/business';
import { ProductsFetchAPI } from '../../api/ecommers/products';
import { CategriesFetchAPI } from '../../api/ecommers/categoriesMenu';

import { MdOutlineFastfood } from "react-icons/md";
//Imgs
import { TbBottle } from "react-icons/tb";

import { CiPizza, CiBurger } from "react-icons/ci";
import banner_2 from '../img/banner/banner.png';
import product from '../img/products/product-1.jpg';
//Components shopping cart 
import ShoppingCart from '../check_order/CheckOrder';


function Menu() {
    //Url params
    const { business_code } = useParams();
    //States
    const [business, setBusiness] = useState({});
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [detailProduct, setdetailProduct] = useState({});
    const [segmentProduct, setsegmentProduct] = useState([]);
    const [productsView, setproductsView] = useState([]);
    const [shoppingCartProducts, setShoppingCartProducts] = useState([]);
    let [totalOrder, setTotalOrder] = useState(0);
    let [subtotalOrder, setSubtotalOrder] = useState(0);
    let [taxOrder, setTaxOrder] = useState(0);
    //------------------------------------------Request-----------------------------------

    //Consultar data inicial 
    const getInitialData = async () => {
        try {
            const response_business = await BusinessFetchAPI.getBusiness(business_code)
            const respuesta_Product = await ProductsFetchAPI.getProducts(response_business.data.business.id)
            const respuesta_categorie = await CategriesFetchAPI.getCategories(response_business.data.business.id)
            //console.log(respuesta_Product);
            setBusiness(response_business.data.business)
            setProducts(respuesta_Product.data.data)
            setproductsView(respuesta_Product.data.data)
            setCategories(respuesta_categorie.data.data)

        } catch (err) {
            console.log(err)
        }
    }

    //consulta los productos por categorias
    const getproductsbycategories = async (category_id) => {
        try {
            const response = await CategriesFetchAPI.getProductsByCategories(category_id)
            setproductsView(response.data.data)

        } catch (error) {
            console.log(error);
        }
    }

    //consultar el detalle del producto
    const getDeatilByProduct = async (group_id) => {
        try {
            const response_segment = await ProductsFetchAPI.getsegmentByProduct(group_id) //consulta los segmentos del producto
            const response_detail = await ProductsFetchAPI.getdeatilByProduct(group_id)

            setsegmentProduct(response_segment.data.data)
            setdetailProduct(response_detail.data.data)

        } catch (error) {
            console.log(error);
        }
    }

    //--------------------------------Eventos---------------------------------
    //event de cierrede caja
    const todosViews = async () => {
        setproductsView(products)
    }

    //filtro para buscar cualquier coincidencia
    function handleChangeFiltro(event) {
        let resultFilter = [];

        if (event.target.value == "") {
            console.log(event.target.value);
            setproductsView(products);
        } else {
            for (let recorrer = 0; recorrer < productsView.length; recorrer++) {
                const examinar = productsView[recorrer];

                const info = JSON.stringify(examinar);
                const resultado = info
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());

                if (resultado == true) {
                    resultFilter.push(examinar);

                    setproductsView(resultFilter);
                    console.log(resultFilter);
                }
            }
        }
    }

    //----------------------------------------------------Templates-----------------------------------------------

    //plantilla categorias
    const viewcategories = () => {
        const getdata = categories
        //console.log(getdata)
        const content = getdata.map((data) => (
            <>
                <a className='categories-sp' onClick={() => getproductsbycategories(data.category_id)}> {data.category_name}  </a>
            </>
        ));
        return (<>{content}</>)
    }

    //plantilla card productos
    const viewproducts = () => {
        const getdata = productsView
        const content = getdata.map((data) => (
            <>
                {/* <div className="menu-card shadow-sm" onClick={() => getDeatilByProduct(data.group_id)}>
                    <img className="card-image" src={data.img} alt="imagen de producto" onClick={handleShow} />
                    <div className="card-detail">
                        <h4 onClick={handleShow} >{data.name}<span>{data.suggested_price}</span> </h4>
                        <p className='description-product'>{data.description}</p>
                    </div>
                    <div className="btn-group w-100" role="group" aria-label="Basic example">
                        <button type="button" className="btn btn-outline-secondary border-0" onClick={handleShow} > <i className="uil uil-eye"></i> Ver</button>
                        <button type="button" className="btn btn-outline-success border-0" onClick={() => addProduct(data.group_id)}><i className="uil uil-plus"></i> Agregar a pedido</button>
                    </div>
                </div> */}

                <div className="col">
                    <div className="card mb-3 shadow-sm" onClick={() => getDeatilByProduct(data.group_id)}>
                        <img src={data.img} className="card-img-top" alt="..." onClick={handleShow} />
                        <div className="card-body">
                            <h5 className="card-title">{data.name}</h5>
                            <p className="card-text description-product ">{data.description}</p>
                            <div className="btn-group w-100" role="group" aria-label="Basic example">
                                <button type="button" className="btn btn-outline-secondary border-0" onClick={handleShow} > <i className="uil uil-eye"></i> Ver</button>
                                <button type="button" className="btn btn-outline-success border-0" onClick={() => addProduct(data.group_id)}><i className="uil uil-plus"></i> Agregar</button>
                            </div>                            </div>
                    </div>
                </div>

                {/* <div className="col">
                    <div className="card" onClick={() => getDeatilByProduct(data.group_id)}>
                        <img src={data.img} className="card-img-top" alt="..." onClick={handleShow} />
                        <div className="card-body">
                            <h5 className="card-title">{data.name}</h5>
                            <p className="card-text">{data.description}</p>
                        </div>
                        <div className="btn-group w-100" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-outline-secondary border-0" onClick={handleShow} > <i className="uil uil-eye"></i> Ver</button>
                            <button type="button" className="btn btn-outline-success border-0" onClick={() => addProduct(data.group_id)}><i className="uil uil-plus"></i> Agregar a pedido</button>
                        </div>
                    </div>
                </div> */}
            </>
        ));
        return (<>{content}</>)
    }

    //plantilla de segmentos
    const viewsegment = () => {

        const getdata = segmentProduct
        console.log(getdata)
        const content = getdata.map((data) => (
            <>
                <li className="list-group-item"><strong>{data.name}</strong></li>


                <form >
                    <div className='row gap-1 justify-content-center' >
                        {viewsegmentitems(data.items)}
                    </div>
                </form>


                {/* <form >
                        <div className='d-flex gap-1 m-1 col-12'>
                           
                        </div>
                    </form> */}

            </>
        ));
        return (<>{content}</>)
    }

    //plantilla de productos del segmento
    const viewsegmentitems = (data) => {
        const getdata = data
        console.log(getdata)
        const content = getdata.map((data) => (
            //             <div className='row'>
            //                 <img className="card-image" src={data.img} alt="imagen de producto" /> */}
            //                 {<input className="list-group-item-check" type="radio" name="machine_code" id={"check" + data.item_id} value={data} />

            //                 <label className="list-group-item py-3" for={"check" + data.item_id}>

            //                     {data.name}
            //                     <span className="d-block small opacity-50">{data.description}</span>
            //                 </label> 

            // </div>   
            <div className='col-md-6 col-xs-6 col-lg-6'>
                <div className="list-group list-group-checkable border-0 ">
                    <input className="list-group-item-check pe-none" type="radio" name="machine_code" id={"check" + data.item_id} value={data} />
                    <label className="list-group-item rounded-3 py-3" for={"check" + data.item_id}>
                        {data.name}
                        {/* <span className="d-block small opacity-50">
                        <span className="d-block small opacity-50">{data.description}</span>
                    </span> */}
                    </label>
                </div>
            </div>

        ));
        return (<>{content}</>)
    }

    //plantilla detalle del producto
    const viewDetailProduct = () => {
        const getdata = [detailProduct]
        const content = getdata.map((data) => (
            <>

                <Modal.Header closeButton>
                    <Modal.Title> {data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <img className="card-image-modal" src={data.img} alt="imagen de producto" />
                    </div>
                    <div className="card-detail">
                        <h4>{data.name}<span>$ {data.suggested_price}</span> </h4>
                        <p >{data.description}</p>
                        <Container>
                            <Row>
                                {viewsegment()}

                            </Row>
                        </Container>
                        <div className='row'>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-seocndary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="success" onClick={handleClose}>
                        Agregar al pedido
                    </Button>
                </Modal.Footer>

            </>
        ));
        return (<>{content}</>)
    }

    //Function to add products to the shopping cart 
    const addProduct = async (group_id) => {
        try {
            const product_selected = await ProductsFetchAPI.getdeatilByProduct(group_id);

            //We calculate the total of the product 
            let total_product = product_selected.data.data.suggested_price;

            //We get the subtotal of the order 
            subtotalOrder = subtotalOrder + total_product;
            setSubtotalOrder(subtotalOrder);

            //We get the itbms 
            let total_itbms_product = product_selected.data.data.suggested_price * 0.07;
            taxOrder = taxOrder + total_itbms_product;
            setTaxOrder(taxOrder);
            //We get the total of the order 
            totalOrder = taxOrder + subtotalOrder;
            setTotalOrder(totalOrder);

            //We set the product 
            setShoppingCartProducts([...shoppingCartProducts, product_selected.data.data]);
        } catch (err) {
            console.log(err)
        }
    }


    //-----------------------------------------------------------------------------------------------------

    useEffect(() => {
        (async () => {
            try {
                getInitialData();

            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (data) => setShow(true);
    
    return (
        <>
            <nav className="navbar clear-nav-bar shadow-sm">
                <h1 className="navbar-logo">
                    <img className="navbar-logo-icon" src={logo_sp} />
                    <span className="h5 navbar-logo-text">Smart Pay</span>
                </h1>
                <div className="navbar-menu">
                    <Link to="/menu/9912" className="button-nav-sp uil uil-list-ui-alt" />
                    <Link to="/request_pay" className="button-nav-sp uil uil-bill">
                    </Link>
                    <Link to="/reports" className="button-nav-sp uil uil-file-graph">
                    </Link>
                    {/* <Link to="/profile" className="button-nav-sp uil uil-user-circle"></Link> */}
                    <ShoppingCart products={shoppingCartProducts} total_order={totalOrder} subtotal_order={subtotalOrder} tax_order={taxOrder} ></ShoppingCart>

                </div>
            </nav>
            <div className='main-menu '>
                {/** Shopping cart */}
                {/**Menu */}
                <h2 className='text-center'>
                    Menú
                </h2>

                <div className='menu-banner'>
                    <img src={banner_2} alt="" />
                    <div className='menu-banner-promo'>
                        {/* <h2><span>The best Restaurant </span> <br />
                            La mejor comida del mundo
                        </h2> */}
                    </div>
                </div>
                <h2 className='menu-title'>
                    Categorias
                </h2>
                {/* Diferents Dishes/ categories  */}
                <div className='menu-options'>
                    {/* <a href="#"> Recomendados</a> */}
                    <a className='categories-sp' onClick={() => todosViews()}> Todos   </a>
                    {/* <a > Promociones   </a> */}
                    {viewcategories()}
                    {/*<a href="#"><TbBottle></TbBottle> Promociones</a>
                    <a href="#"><TbBottle></TbBottle> Bebidas </a>
                    <a href="#"><MdOutlineFastfood /> Combos </a>
                    <a href="#"><CiPizza></CiPizza> Pizzas </a>
                    <a href="#"><CiBurger></CiBurger> Hamburguesas </a>*/}
                    {/* <a href="#"> Acompañamientos  </a> */}
                    <a href="#"> Acompañamientos  </a>
                </div>
             
                <input
                    spellcheck="false"
                    className="control m-3"
                    type="text"
                    step="any"
                    onChange={handleChangeFiltro}
                    placeholder="Filtro "
                />

                <div className="menu-content">
                    {viewproducts()}
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                {viewDetailProduct()}
            </Modal>
        </>

    )
}

export default Menu