import React, { useState, useEffect } from 'react'
//Component
import { RenderIf } from '../../components/utils/RenderIf';
import { Button, Modal } from 'react-bootstrap'
import QRCode from "react-qr-code";
// import {QRCodeSVG} from 'qrcode.react';


//pictures

import checkimg from './img/check.gif';
import YappyStep from './img/YappyStep.png';
import logoYappy from './img/yappy.png';
import cancel from './img/error.gif';
import loading from './img/loading.gif';

function ModalYappyQr({ background, paymentInfo, modalYappyQr, setModalYappyQr, dataYappy, setDataHash, dataHash, hadleCancelYappy, invoiceTotal, orderNumber }) {
    let branchoffice = { id: "" }
    let dataOrder = { transaction_code: "", total: 0 };
    if (localStorage.getItem("branchoffice") != "" || localStorage.getItem("branchoffice") != undefined) {
        branchoffice = JSON.parse(localStorage.getItem("branchoffice"))
        dataOrder = JSON.parse(localStorage.getItem('Detail_order'))
    }

    //const [routeQr, setRouteQr] = useState("0")

    const updatePage = () => {
        window.location.reload();
    }




    useEffect(() => {

    }, []);

    return (
        <Modal
            show={modalYappyQr}
            fullscreen
            onHide={() => setModalYappyQr(false)}>
            <Modal.Body>
                <section className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} >


                    <div>


                        <div className='p-5 rounded m-5'>
                            <div className='p-5'>

                                <main className='d-flex justify-content-center align-items-center h-100 '>

                                    <div>
                                        <section className='border rounded p-4 w-100 position-relative m-3'>
                                            <div className='sections-messages'>

                                                <div className='px-4 pb-1 mt-5 text-center'>

                                                    <img className='mx-auto mb-4 ' src={logoYappy}  height='200px' />
                                                    <h1 className="display-6 fw-bold kiosk-subtitle">
                                                        Orden: {orderNumber}
                                                    </h1>
                                                    <h3 className='kiosk-subtitle-md'> Total: ${invoiceTotal}</h3>

                                                    <div className="m-5 d-flex align-items-center justify-content-center ">
                                                        <QRCode value={dataHash} size={300} />
                                                    </div>
                                                    <p className='kiosk-subtitle-md m-2'> Escanea el qr con yappy para realizar el pago.</p>
                                                </div>
                                                <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success kiosk-subtitle-s">
                                                    Método de Pago: {paymentInfo.payment}
                                                </span>
                                            </div>


                                        </section>
                                        <section className='border rounded p-4 w-100 position-relative m-3'>
                                            <div className='sections-messages'>
                                                <div className='px-4 pb-1 mt-5 text-center'>
                                                    <img className='mx-auto mb-4 ' src={YappyStep} height='250px' />
                                                </div>

                                            </div>
                                        </section>
                                    </div>
                                </main>
                                

                                <div className='d-flex justify-content-center align-items-center '>
                                    <button className="btn btn-danger kiosk-subtitle-md  rounded  p-4 m-3" onClick={() => ((setDataHash(""), hadleCancelYappy(), setModalYappyQr(false)))}>Cancelar transaccion</button>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="position-absolute w-100 d-flex justify-content-between bottom-0 start-0 ">
                        <small >
                            © 2023 Smart Pay
                        </small>
                        <small >
                            Powered By Smart Retail Group
                        </small>
                    </div>
                </section >
            </Modal.Body>
        </Modal>
    )
}

export default ModalYappyQr