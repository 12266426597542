import React from 'react'
//img
import empty from '../assets/img/vending/empty.png'

function Empty(props){
   return (
      <>
         <div className="d-flex justify-content-center align-items-center opacity-50">
            <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                  <img className='empty-img' src={!props.img? empty : props.img} alt="Vacio"  />
                  <div className='text-center mt-3'>
                     <h5 className='empty-title fw-bold' ><strong>{props.title}</strong></h5>
                     <p className="empty-msg  mb-0 text-secondary fw-bold">
                        ¡{props.msg}!
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Empty