//React components 
import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom';

//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

//Internal components 
import Cashier from './Cashier';
import MachineCard from "./MachineCard";
import Navbar from '../NavBar/Navbar';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';

//Api 
import { MachinesFetchAPI } from '../../api/Machines';
import { OrdersFetchAPI } from '../../api/Orders';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';

//img
import empty from '../img/icons/empty.png'

function MachineList() {
   //Params
   let { business_code, branchoffice_code } = useParams();
   //States
   const [branchOfficeData, setBranchOfficeData] = useState();
   const [branchMachines, setBranchMachines] = useState([]);
   const [searchedMachines, setSearchedMachines] = useState([]);
   const [searchTerm, setSearchTerm] = useState('');
   const [modalShow, setModalShow] = useState(false);
   const [detailOrder, setDetailOrder] = useState()
   const [amountOrder, setAmountOrder] = useState()
   const [orderNumber, setOrderNumber] = useState()

   //Functions to show and hide the modal 
   const handleCloseModal = () => setModalShow(false);
   const handleShowModal = () => setModalShow(true)

   //Function to gen the empty card to the transaction history 
   const EmptyCard = () => {
      return (
         <div className="m-3 w-auto ">
            <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                  <img src={empty} alt="Vacio" width="35px" height="35px" />
                  <div className='text-center mt-3'>
                     <h5>Vacío</h5>
                     <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   //We get the machines by branchoffice 
   const getMachinesByBranchOffice = async (branchoffice_id, token) => {
      try {
         let smartpay_machine = []
         let smartpay_pos_machine = []
         try {
            //We call the machines info (smartpay machine type)
            smartpay_machine = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branchoffice_id, 1, token);

         } catch (err) {
            smartpay_machine = []
         }

         try {
            //We call the machines info (smartpay pos machine type )
            smartpay_pos_machine = await MachinesFetchAPI.getMachinesByBranchOfficeAndType(branchoffice_id, 3, token);

         } catch (err) {
            smartpay_pos_machine = []
         }

         let machines = smartpay_machine.data.data.concat(smartpay_pos_machine.data.data)
         setBranchMachines(machines);
         setSearchedMachines(machines);
      } catch (err) {
         //We show the error alert 
         ErrorAlert('No tiene información');
         setBranchMachines([]);
         setSearchedMachines([]);
      }
   }

   //Filter the machines by term searched 
   const filteredMachines = useMemo(() => {
      if (searchTerm === '') {
         return branchMachines
      }

      return searchedMachines.filter(machine => {
         const nameMatch = machine.machine_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
         const machineCodeMatch = machine.code.toLowerCase().includes(searchTerm.toLowerCase());
         return nameMatch || machineCodeMatch;
      })
   }, [searchTerm, branchMachines]);


   //Function to populate the list machines 
   const listMachines = () => {

      const listMachines = filteredMachines.map((machine) => (
         <>
            <div onClick={async () => { getTemporalMachineOrder(machine.code) }}>
               <MachineCard machine_name={machine.machine_name} machine_code={machine.code} machine_type={machine.name} />
            </div>
         </>
      ));

      //We check if the list orders has data 
      if (listMachines.length === 0) {
         return EmptyCard()
      } else {
         return (listMachines)
      }
   }

   //Function to get the temporal order of the machine 
   const getTemporalMachineOrder = async (machine_code) => {
      try {
         //We get the temporal cash effective order of the machine 
         const response = await OrdersFetchAPI.getTempOrderByMachine(machine_code, business_code, branchoffice_code, false);
         setDetailOrder(response.data.order);
         //We show the modal of the order 
         handleShowModal();
      } catch (err) {
         ErrorAlert("Esta maquina no tiene ordenes en progreso")
      }
   }



   //Hook to charge data when the page is loaded 
   useEffect(() => {
      (async () => {
         //Variables 
         let token = localStorage.getItem('x-access-token');
         try {
            //We search and set the branch info
            const branchInfo = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setBranchOfficeData(branchInfo.data.branchoffice);
            //We get the machines of the branchoffice 
            getMachinesByBranchOffice(branchInfo.data.branchoffice.id, token)
            //We show the success alert 
            SuccessAlert();
         } catch (err) {
            console.log(err);
         }
      })();
   }, [])

   return (
      <>
         <Navbar />
         <div className='container'>
            <div className='smart-pay-main'>
               <div className='d-flex justify-content-center'>
                  <h2 className='fs-2'>Maquinas</h2>
               </div>
            </div>
            <Row className='justify-content-md-center'>
               {/* User */}
               <Col sm={12} md={12}>
                  <div className="">
                     <div className="d-flex gap-2 w-100 justify-content-center mb-4 pt-2">
                        <div className=' border px-3 py-2 rounded-pill shadow-sm'>
                           <p className="mb-0">
                              <strong>
                                 <i className="uil uil-store"></i>
                                 Sucursal : {branchOfficeData ? branchOfficeData.name : ''} &nbsp;
                              </strong>
                              {/* {user.name} */}
                           </p>
                        </div>
                     </div>
                  </div>
               </Col>
               {/* Historial transacciones*/}
               <Col sm={12} md={6} lg={5}>
                  <div className='mx-lg-3'>
                     <div className='text-center'>
                        <h4 className='fs-4 fw-bold'>Lista de maquinas</h4>
                     </div>
                     <div className="w-auto bg-light px-3 py-2 border rounded mt-4">
                        <div className="border-0" >
                           <div className="d-flex justify-content-between align-items-center">
                              <h6 className="mb-0 text-secondary fw-bold">Buscador</h6>
                              <div className='border-0 fw-bold fs-3'>
                                 <input
                                    spellCheck="false"
                                    className="form-control"
                                    type="text"
                                    step="any"
                                    onChange={e => setSearchTerm(e.target.value)}
                                    placeholder="Filtar  "
                                 />
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className='transaction-wrapper-2 border border-history '>
                        <div className="">
                           {/* <Empty /> */}
                           {/* Paso de data al componente */}
                           {listMachines()}
                        </div>
                     </div>
                  </div>
               </Col>
            </Row>
         </div>

         {/* Modal */}
         <Modal
            size="lg"
            show={modalShow}
            onHide={handleCloseModal}>
            <Modal.Header closeButton>
               <Modal.Title>Orden: {orderNumber} Monto: ${amountOrder} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Cashier setAmountOrder={setAmountOrder} setOrderNumber={setOrderNumber} setModalShow={setModalShow} order_data={detailOrder} />
            </Modal.Body>
         </Modal>
      </>
   )
}

export default MachineList