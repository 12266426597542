import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class LanguagesFetchAPI {

    static async getLanguages( token) {
        const res = await server(`/languages`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }


}

