import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import LoadingAlert from '../../components/Alerts/LoadingAlert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { RenderIf } from '../../components/utils/RenderIf';
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap';
import { DivicesFechtAPI } from '../../api/Divices';
import { WhatsappFetchAPI } from '../../api/Whatsapp';
import { MachinesFetchAPI } from '../../api/Machines';
import VendingModalKeyCode from './VendingModalKeyCode';
import img_dispensed from './img/delivery.gif'
import img_Error from './img/error.gif'
import img_support from './img/support.gif'
import ModalVendingPause from './ModalVendingPause';


import Swal from 'sweetalert2';

function VendingModalSupport({ showModalSupport, setShowModalSupport, contentSupport, setEventHandlerMenu, suportReturn }) {
  let token = localStorage.getItem('x-access-token');
  let machine = localStorage.getItem('machine_vending')
  let business = localStorage.getItem('business_vending')
  let branchoffice = localStorage.getItem('branchoffice_vending')

  const [showModalVendingPause, setShowModalVendingPause] = useState(false)
  const [stringType, setStringType] = useState("")
  const [eventHandlerKey, setEventHandlerKey] = useState(0)
  const buttons_key = [
    "1", "2", "3", "4", "5", "6", "7", "8", "9", "0"
  ];

  const sumString = (key) => {

    let newString = stringType + key
    console.log(newString);
    setStringType(newString)
    setEventHandlerKey(1)
  }

  const pause = () => {
    setShowModalSupport(false)
    setShowModalVendingPause(true)

    setTimeout(() => {
      localStorage.setItem('countNotification', 0);
      console.log("maquina restaurada");
      setShowModalVendingPause(false)
    }, 60000);
  }
  const refresh = () => {
    if(suportReturn == true){
      window.location.reload(true);
    }
  }

  const sendNotification = async () => {
    let countNotification = 0

    if (localStorage.getItem('countNotification') == "" || localStorage.getItem('countNotification') == undefined || localStorage.getItem('countNotification') == null) {
      localStorage.setItem('countNotification', 0)
    } else {
      countNotification = parseInt(localStorage.getItem('countNotification'));

      if (countNotification > 2 || stringType.length > 8 || stringType.length < 8 || stringType[0] != 6) {
        console.log("la maquina estara deshabilidata por unos segundos");
        // restore machine
        pause();
      } else {
        let newValue = (countNotification + 1);
        localStorage.setItem('countNotification', newValue);


        LoadingAlert("Enviando mensaje")
        branchoffice = JSON.parse(localStorage.getItem('branchoffice_vending'))
        business = JSON.parse(localStorage.getItem('business_vending'))
        console.log(contentSupport);

        console.log(branchoffice.branchoffice_support_phone);
        let dataSupport = {
          "notification_type": "smartpay_notification",
          "branchoffice_id": branchoffice.id,
          "business_id": business.id,
          "phone_to_send": branchoffice.branchoffice_support_phone,
          "header_text": contentSupport.title,
          "message_body": [{ "text": contentSupport.body[0].text }, { "text": contentSupport.body[1].text + " Contacto del cliente: " + stringType }]
        }

        let dataClient = {
          "notification_type": "smartpay_notification",
          "branchoffice_id": branchoffice.id,
          "business_id": business.id,
          "phone_to_send": "507" + stringType,
          "header_text": "Solicitud de soporte recibida",
          "message_body": [{ "text": "maquina vending" }, { "text": "ayuda nos pondremos en contacto con usted para resolver lo mas pronto posible" }]
        }

        //send message to the support
        sendMessage(dataSupport, 1)

        //send message to the client
        setTimeout(() => {
          sendMessage(dataClient, 2)
        }, 5000);
      }
    }

  }

  const sendMessage = async (data, turn) => {
    try {
      const response = await WhatsappFetchAPI.sendNotificationWhatsapp(data, token)
      console.log(response);
      if (turn == 2) {
        setEventHandlerMenu(1)
        setShowModalSupport(false)
        ErrorAlert("Solicitud de soporte enviada", "Envio exitoso", "success")
        refresh();
      }

    } catch (err) {
      ErrorAlert("Opps..", "No se pudo enviar el mensaje", "error")
      console.log(err);
    }
  }



  //Hook to load the initial info
  useEffect(() => {
    setEventHandlerKey(0)
  }, [eventHandlerKey]);

  return (
    <>
      <Modal
        show={showModalSupport}
        size='lg'
        centered
        onHide={() => setShowModalSupport(false)}
      >
        <Modal.Body>
          <div className='d-flex alight-item-center justify-content-center'>
            <section className='mx-2'>
              <div className='d-flex justify-content-center m-0'>
                <img className='w-50 h-100' src={img_support} alt='Help Img' />
              </div>

              <div>
                <div className='vendindg-title'>
                  <h2 className='text-center text-title-help fw-bold'>Por favor, ingrese un número de contacto</h2>
                </div>
                <div>
                  <div className='m-4 vendindg-message alert alert-light'>
                    <h3 className='text-center'>Nuestro servicio técnico lo contactará para solucionar su problema.</h3>
                  </div>
                  <div className='container'>
                    <div className='row '>
                      <div className="col-12 border rounded  border-3 shadow-sm my-4">
                        <h2 className='text-center display-1 fw-bold py-3'>{stringType == "" ? <>6000-0000</> : <>{stringType}</>}</h2>
                      </div>
                    </div>

                    <div className='row'>
                      {buttons_key.map((button) => (
                        <div className='col-4  mt-4'>
                          <div className='border border-sp border-3 p-5 shadow-sm' onClick={() => (sumString(button))}>
                            <h4 className='text-center fw-bold h1'>{button}</h4>
                          </div>
                        </div>
                      ))}
                      <div className='col-sm-8 mt-4'>
                        <div className='border border-sp border-3 p-5 shadow-sm' onClick={() => (setStringType(""), setEventHandlerKey(1))} >
                          <h4 className='text-center fw-bold h1'><i className="uil uil-backspace"></i> Borrar</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-5'>
                    <button className='btn  btn-lg m-1 btn-vending btn-vending-danger'
                      onClick={async () => (setShowModalSupport(false), setStringType(""), refresh())} >
                      <i className="uil uil-times-circle"></i>  Cerrar
                    </button>
                    <button className='btn btn-success btn-lg m-1 btn-vending btn-vending-success'
                      onClick={async () => (sendNotification(), setStringType(""))} >
                      <i className="uil uil-message"></i> Enviar
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
      </Modal>

      <ModalVendingPause showModalVendingPause={showModalVendingPause} setShowModalVendingPause={setShowModalVendingPause}></ModalVendingPause>

    </>
  )
}

export default VendingModalSupport