import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import ConfirmationAlert from '../Alerts/ConfirmationAlert';
import { PrinterFetchAPI } from '../../api/Printer';
import { MachinesFetchAPI } from '../../api/Machines';
import { AuthDgiFetchAPI } from '../../api/Paymentpost/AuthDgi';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { NotificationsFetchAPI } from '../../api/Notidication';



function ModalCreateOrderTable({setShowModalShopingCart, checkDeliveryOrder, dv, name, ruc, setDv, setName, setRuc, dataResponse, optionalData, setDataResponse, setOptionalData, shoppingCart, total, subtotal, tax, discount, setPageLoader, dataPreOrder, setShowUpdateAccount, setDataPreOrder, setCheckDeliveryOrder, setShoppingCart, setInvoiceTotal, setInvoiceSubtotal, setInvoiceTotalItbms, setInvoiceTotalDiscount, setEventHandler}) {
   const [show, setShow] = useState(false);
   const [infoPrinter, setInfoPrinter] = useState({ printer_comanda: "", printer_ticket: "" })
   let title_modal = "Crear precuenta"
   let machine = JSON.parse(localStorage.getItem('machine'));


   let date = new Date().toJSON().slice(0, 10)


   //function to authentication of ruc
   const authenticationDgi = async () => {
      let message = ""
      let title = ""
      let icon = ""
      if (ruc == "") {
         message = "No se ha ingresado ningun ruc."
         title = "Debes ingresar un ruc."
         icon = "warning"
         ErrorAlert(message, title, icon)
      } else {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let data = { ruc: ruc }
         try {
            const response = await AuthDgiFetchAPI.authenticationRuc(token, data)
            let dataDgi = response.data
            if (dataDgi.dgi_response.gResProc) {
               if (dataDgi.dgi_response.gResProc.dCodRes == "0680") {
                  let dvDgi = dataDgi.dgi_response.dDV
                  if (dvDgi.length < 2) { dvDgi = "0" + dvDgi }
                  setName(dataDgi.dgi_response.dNomb)
                  setDv(dvDgi)
                  message = "Datos del clientes obtenidos por la DGI"
                  title = "Datos obtenido correctamente"
                  icon = "success"
                  ErrorAlert(message, title, icon)
                  setDataResponse(true)
               } else {
                  setOptionalData(true)
                  message = "Ingrese los datos manualmente."
                  title = "Datos no encontrados"
                  icon = "warning"
                  ErrorAlert(message, title, icon)
               }
            }
         } catch (err) {
            console.log(err);
         }
      }
   }

   const createNotification = async (body) => {
   let table = JSON.parse(localStorage.getItem('table')); 
    let token = localStorage.getItem('x-access-machine-token');
    let machine = localStorage.getItem('machine');
    let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
    let business = JSON.parse(localStorage.getItem('business'))
    try{
        let data  = {
           "title": table.name,
           "body": body,
           "machine_id": machine.id,
           "table_id": table.id,
           "branchoffice_code": branchoffice.code,
           "business_code": business.code
       }
       
        const response = await NotificationsFetchAPI.createNotification(data, localStorage.getItem('x-access-machine-token'));
        console.log(response);
        //ErrorAlert("", "Notificacion enviada", "success")

     }catch(err){
        ErrorAlert("", "Error al enviada", "error")
        console.log(err);
     }


   
 }

   //create preOrder
   const createPreOrder = async () => {

      if (shoppingCart.length > 0) {
         LoadingAlert()
         let table = JSON.parse(localStorage.getItem('table')); 
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'))


         console.log(ruc);
         console.log(dv);
         console.log(shoppingCart);
         let json = {
            "client_name": name,
            "localizador": "00",
            "client_phone": "0",
            "ruc": ruc,
            "table_id": table.id,
            "dv": dv,
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": shoppingCart,
            "Descuento": discount,
            "Total": total,
            "itbms": tax,
            "subtotal": subtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": checkDeliveryOrder,
            "paymethod": 0,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "is_pre_order": true
         }

         try {
            const response = await PreOrderFetchAPI.createPreOrder(json, token)
            setDataPreOrder({ pre_Order: true, _id: response.data.newTempOrder._id });
           setShow(false)
            let message = "Precuenta creada con exito."
            let title = "Operacion exitosa."
            let icon = "success"
            ErrorAlert(message, title, icon)
            setShowUpdateAccount(false);

            createNotification( "La mesa a creado la orden #:" +response.data.newTempOrder.code )
            setPageLoader(1)
          
                getOrderById(response.data.newTempOrder._id)
                setShowModalShopingCart(true)
        
           
         } catch (err) {
            console.log(err);
         }
      }else{
         ErrorAlert("Debes agregar  algo al carrito", "No hay productos en el carrito", "info")
      }
   }


   const getOrderById = async (_id) => {
    let token = localStorage.getItem('x-access-machine-token');
    try{
        const response = await PreOrderFetchAPI.getPreOrderById(_id, token);
        aplyPreOrder(response.data.pre_order)
    }catch(err){
        console.log(err);
    }
   }

   const aplyPreOrder = (selectPreOrder) => {
    let message = ""
    let title = ""
    let icon = ""
    
    if (selectPreOrder.order == "") {
       console.log("no hay pre cuenta seleccionada");
       message = "No ha selecciona ninguna precuenta."
       title = "Bebe seleccionar una precuenta."
       icon = "info"
       ErrorAlert(message, title, icon)
    } else {

       setDataPreOrder({ pre_Order: true, _id: selectPreOrder._id, code: selectPreOrder.code })
       setName(selectPreOrder.client_name)

       if (selectPreOrder.client_ruc && selectPreOrder.client_dv) {
          setRuc(selectPreOrder.client_ruc)
          setDv(selectPreOrder.client_dv)
          console.log("entre a ruc");
       } else {
          setRuc("0")
          setDv("0")
       }

       setDataResponse(true)
       setOptionalData(true)
       setShow(false)

       console.log(selectPreOrder);

       //format json 
       for (let i = 0; i < selectPreOrder.groups.length; i++) {

          for (let s = 0; s < selectPreOrder.groups[i].segments.length; s++) {

             selectPreOrder.groups[i].segments[s].img = selectPreOrder.groups[i].segments[s].item.img
             selectPreOrder.groups[i].segments[s].item_id = selectPreOrder.groups[i].segments[s].item.item_id
             selectPreOrder.groups[i].segments[s].name = selectPreOrder.groups[i].segments[s].item.name
             selectPreOrder.groups[i].segments[s].segment_name = selectPreOrder.groups[i].segments[s].name
             selectPreOrder.groups[i].segments[s].cantidadItem = selectPreOrder.groups[i].segments[s].item.amount
             selectPreOrder.groups[i].segments[s].description = selectPreOrder.groups[i].segments[s].item.description
             selectPreOrder.groups[i].segments[s].tax = selectPreOrder.groups[i].segments[s].item.tax
          }
          selectPreOrder.groups[i].DetalleSegmentos = selectPreOrder.groups[i].segments
          selectPreOrder.groups[i].cantidadItem = selectPreOrder.groups[i].amount
          selectPreOrder.groups[i].descuento = selectPreOrder.groups[i].discount
       }

       console.log(selectPreOrder);


       message = "Orden consultada."
       title = "Operacion exitosa"
       icon = "success"
       
       //ErrorAlert(message, title, icon)
       setCheckDeliveryOrder(selectPreOrder.order_type)
       setShoppingCart(selectPreOrder.groups)
       setInvoiceTotal(selectPreOrder.total)
       setInvoiceSubtotal(selectPreOrder.subtotal)
       setInvoiceTotalItbms(selectPreOrder.tax)
       setInvoiceTotalDiscount(selectPreOrder.discount)
       setEventHandler(1)
    }


 }

   

  


   // view get order to return
   const cardGetOrder = () => {
      const cardOrder = shoppingCart.map((product) => (
         <>
            <div className="d-flex text-muted pt-3 px-xs-2 border-bottom">
               <img className="bd-placeholder-img flex-shrink-0 me-2 rounded bg-secondary" src={product.img} width="42" height="42" />
               <div className="pb-3 mb-0  lh-sm  w-100">
                  <div className="d-flex justify-content-between ">
                     {/* Name */}
                     <span className="text-dark fw-bold title-product "> {product.name} </span>
                     {/* Cantidad/ Quantity*/}
                     <span className='px-1 number-counter'>
                        <p>{product.amount}</p>
                     </span>
                     <label className="fw-bold" >$ {product.price.toFixed(2)} </label>
                  </div>
                  {/** Segment items */}
                  <details open>
                     <summary> <small> Detalles </small> </summary>
                     {product.segments.map(item => (
                        <div>
                           <small className=''>{item.segment_name}: {item.name} - ${item.price}</small>
                        </div>
                     ))}
                  </details>
               </div>
            </div>
         </>
      ));
      return (cardOrder)
   }


   // update pre order
   const updatePreOrder = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      console.log(shoppingCart);
      let json = {
         "client_name": name,
         "ruc": ruc,
         "dv": dv,
         "is_discount_notification": false,
         "_id": dataPreOrder._id,
         "order_type": checkDeliveryOrder,
         "groups": shoppingCart,
         "dicount": discount,
         "total": total,
         "tax": tax,
         "subtotal": subtotal,
      }
      try {
         const response = await PreOrderFetchAPI.updateOrderTempPreOrder(json, token)
         console.log(response);
         
         setShow(false)
         let message = "Precuenta actualizada con exito."
         let title = "Operacion exitosa"
         let icon = "success"
         setPageLoader(1)
         setShowUpdateAccount(false);

         createNotification( "La mesa actualizo su orden #:" +response.data.code )
         ErrorAlert(message, title, icon);
         getOrderById(response.data._id)
         setShowModalShopingCart(true)

      } catch (err) {
         let message = ""
         let title = "Ocurrio un error"
         let icon = "error"

         ErrorAlert(message, title, icon)
      }
   }

   return (
      <>
        {/* <button className='btn btn-outline-primary border btn-sm'
            onClick={(e) => ( setShow(true))}>
            Ordenar
   </button>*/}

         {dataPreOrder.pre_Order && dataPreOrder.pre_Order == true ? <>
                  <Button variant="primary" size="lg" onClick={async () => (updatePreOrder())}>Agregar a la orden</Button>
               </> : <>
                  <Button variant="success" size="lg" onClick={() => (createPreOrder())} >Ordenar</Button>
               </>}

         <Modal
            show={show}
            size="md"
            centered
            onHide={() => setShow(false)}
            aria-labelledby="example-custom-modal-styling-title"
         >
            <Modal.Header closeButton>
               <Modal.Title id="example-custom-modal-styling-title">
                  {title_modal}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>

               <div className='d-flex '>
                  <details className=''>
                     <summary className='sumamary-text'>Personalizar factura</summary>
                     <div>{dataResponse == false ?
                        <Form>
                           { /*if the client's ruc is not authenticated, it shows the form of name and dv*/}
                           {optionalData == true ? <>
                              <div className='d-flex align-items-center justify-content-center'>
                                 <p className='text-size-order' > <b>Ruc:</b>  <span>{ruc}</span></p>
                              </div>

                              <div>
                                 <Form.Group className="mb-3 " controlId="formBasicText">
                                    <Form.Label >Ingresa un nombre</Form.Label>
                                    <Form.Control placeholder="Nombre" onChange={(e) => (setName(e.target.value))} />
                                 </Form.Group>
                                 <div className='col-xs-2'>
                                    <Form.Group className="mb-3 " controlId="formBasicText">
                                       <Form.Label >Ingresa un dv</Form.Label>
                                       <Form.Control placeholder="00" onChange={(e) => (setDv(e.target.value))} />
                                    </Form.Group></div>

                                 <div className='d-flex align-items-center justify-content-center'><Button className='m-3' variant="danger" type="button" onClick={() => (setOptionalData(false))}>
                                    Cancelar
                                 </Button>
                                    <Button className='m-3' variant="primary" type="button" onClick={() => (setDataResponse(true))} >
                                       Cargar datos
                                    </Button></div>
                              </div></> :
                              <><div className='d-flex align-items-center justify-content-center'>
                                 <Form.Group className="mb-3 " controlId="formBasicEmail">
                                    <Form.Label >Ingresa un ruc</Form.Label>
                                    <Form.Control placeholder="0-000-000-0" onChange={(e) => (setRuc(e.target.value))} />
                                 </Form.Group>
                                 <Button className='m-3' variant="primary" type="button" onClick={() => (authenticationDgi())}>
                                    Validar
                                 </Button>
                              </div></>}
                        </Form> : <>
                           <p className='text-size-order m-2 text-center' ><span><strong>{name}</strong></span> </p>
                           <div className="order-total m-1">
                              <p className='text-size-order' ><b>Ruc:</b> <span>{ruc}</span> </p><p className='text-size-order' ><b>Dv:</b> <span>{dv}</span></p>
                           </div>
                           <Button variant="primary" type="button" onClick={() => (setDataResponse(false))}>Cambiar datos </Button></>}
                     </div>
                  </details>
               </div>

               <hr />

               <div className='p-3'>
                  <div className='row'>
                     <div className='col-sm-12'>
                        <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                           <div className="d-flex gap-2 w-100 justify-content-around">
                              <div>
                                 <h5 className="mb-0 text-center">Orden actual</h5>
                                 <p className="mb-0 opacity-75 text-center">Fecha - {date} </p>
                              </div>
                           </div>
                        </a>
                     </div>

                  </div>
                  <div className=''>
                     {cardGetOrder()}</div>
                  <div className=''>
                     <div className="order-total mt-2 mx-1">
                        <p className='text-size-order' >Descuento: <span>$ <strong>{discount.toFixed(2)}</strong></span> </p>
                        <p className='text-size-order '>Subtotal: <span>$ <strong>{subtotal.toFixed(2)}</strong></span></p>
                        <p className='text-size-order' >Impuestos: <span>$ <strong>{tax.toFixed(2)}</strong></span> </p>
                        <p className='text-size-order' > <b>Total con impuesto:</b>  <span>$ <strong>{total.toFixed(2)}</strong></span></p>
                     </div>
                  </div>

               </div>


            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-around'>
               <Button variant="danger" size="lg" onClick={() => (setShow(false), setShowUpdateAccount(false))}>Cancelar</Button>
               {dataPreOrder.pre_Order && dataPreOrder.pre_Order == true ? <>
                  <Button variant="primary" size="lg" onClick={async () => (updatePreOrder())}>Actualizar precuenta</Button>
               </> : <>
                  <Button variant="success" size="lg" onClick={() => (createPreOrder())} >Crear precuenta</Button>
               </>}
            </Modal.Footer>
         </Modal>


      </>
   )
}

export default ModalCreateOrderTable