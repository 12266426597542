import React from 'react'
import HeroImage from './img/backgrounds/bg-sp.png'


function Hero() {
  return (
   <>
   {/* <section className=' bg-gray-100 smart-pay-hero'>
      <div className="px-4 py-12 mt-10 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-28 lg:py-12">
        <div className="flex flex-wrap items-center mx-auto max-w-7xl">
          <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
            <div>
              <div className="relative w-full max-w-lg"> */}
                {/* Shapes  */}
                {/* <div className="absolute top-0 rounded-full bg-color-sp blur-2xl  -left-4 w-72 h-72 animate-blob">
                </div>
                <div className="absolute rounded-full bg-color-sp -bottom-24 blur-2xl right-20 w-72 h-72 mix-blend-multiply filter animate-blob animation-delay-4000"></div> */}
                {/* <div className='relative'>
                  <img className="image-hero  object-cover object-center mx-auto rounded-lg" alt="hero" src={HeroImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
            <h1 className="mb-8 text-4xl font-bold leading-none tracking-tighter text-neutral-900 md:text-7xl lg:text-5xl">

              La forma  más fácil, rápida,
           
                <span className='text-white p-0 text-color-sp focus-brand'> automatizada </span> 
                y 
                <span className='text-white p-0 text-color-sp focus-brand'> versátil </span> 
 

              de recibir pagos en todos tus negocios. 
            </h1>
            <p className="mb-8 text-lg leading-relaxed text-left text-gray-500">
              Es una plataforma de autoservicio que facilita y automatiza el proceso de selección y pago de productos,
            </p>

          </div>
        </div>
      </div>
    </section> */}
      <div className="container col-xxl-8 px-4 py-5">
          <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img src={HeroImage} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
            </div>
            <div className="col-lg-6">
              <h1 className="display-4 fw-bold lh-1 mb-3 text-color-sp ">En manteminiento </h1>
              <p className="lead display-6 " >Pronto volveremos para innovar y automatizar los métodos de pago de tu negocio. </p>
            </div>
          </div>
        </div>
   </>
  )
}

export default Hero