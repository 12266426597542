import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { RenderIf } from '../../utils/RenderIf';
import { ItemsFechtAPI } from '../../../api/Items';


function ModalCreateItem({ loadItems }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [imgpreview, setImgpreview] = useState('')
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append("business_id", user.business_id);
        form.append("price", '0');
        form.append("item_type", "infinite");
        const createItem = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await ItemsFechtAPI.createSingleItem(createItem, token);
            //We show the success alert 

            ErrorAlert('', 'Producto individual creado con exito', 'success')
            loadItems();
            setImgpreview('')
            setShowModal(false);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de registro', 'error')
            console.log(err.response.data.error)
        }


    }

    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }

    useEffect(() => {
    }, [eventHandle])


    return (
        <>

            <div><button className='btn btn-primary btn-sm border-0' onClick={() => setShowModal(true)} ><i className="uil uil-plus-circle"></i> Nuevo</button></div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(''))}
                size='large'
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-apps"></i>
                        Nuevo producto individual </h3>
                </Modal.Header>
                <Modal.Body>
                    <RenderIf isTrue={imgpreview != ''}>
                        <div className='d-flex align-item-center justify-content-center'>
                            <img
                                className='imgEditModal'
                                src={imgpreview} />
                        </div>

                        <div className='d-flex align-item-center justify-content-center m-3'>
                            <label htmlFor="upload-button">
                                <h5 className="text-center">Seleccione una imagen</h5>
                            </label>
                        </div>
                    </RenderIf>


                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <Form.Group className="mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre del producto"
                                name="name"
                                required
                                autoFocus
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" >
                            <Form.Label>Decripcion</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                placeholder="Descripcion del producto"
                                name="description"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" >
                            <Form.Label>Preparacion</Form.Label>
                            <Form.Select aria-label="" name="item_preparation">
                                <option value="0">No</option>
                                <option value="1">Si</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label></Form.Label>
                            <Form.Control type="file"
                                id="upload-button"
                                placeholder="Imagen"
                                name="files"
                                onChange={(e) => changeimg(e)}
                                required />
                        </Form.Group>


                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit">
                                Guardar
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false), setImgpreview(''))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalCreateItem