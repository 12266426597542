import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import qr_img from '../../assets/img/vending/qr.png'
import MachineKeyCode from './MachineKeyCode';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

//IMG
import scanQR2 from './assets/img/scanyourqr.gif'


//api
import { AuthFetchAPI } from '../../api/Auth';

function LoginKioskMachine() {
   const navigate = useNavigate();
   const [codeString, setCodeString] = useState("")
   const [showModalKeyCode, setShowModalKeyCode] = useState(false)
   const [codeSesion, setCodeSesion] = useState({
      business_code: "0000",
      branchoffice_code: "0000",
      machine_code: "0000",
   });

   //redirection the page
   const redirection = () => {
      navigate(`/kiosk/${codeSesion.business_code}/${codeSesion.branchoffice_code}`)
   }

   const Valid = () => {
      if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {

      } else {

         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
         let machine = JSON.parse(localStorage.getItem('machine'));
         let business = JSON.parse(localStorage.getItem("business"))

         //console.log(`/kiosk/${business.code}/${branchoffice.code}`);
         navigate(`/kiosk/${business.code}/${branchoffice.code}`)
      }
   }

   const inputRefLog = useRef(null);
   setTimeout(() => {
      inputRefLog.current.focus()
   }, 3000);

   //reload the page
   const reload = () => {
      window.location.reload()
   }


   const authMachineVending = async () => {
      try {
         const response = await AuthFetchAPI.authMachine(codeSesion.business_code, codeSesion.branchoffice_code, codeSesion.machine_code);
         console.log(response);
         SuccessAlert()


         //save data auth in localStorage
         localStorage.setItem('x-access-machine-token', response.data.token);
         localStorage.setItem('business', JSON.stringify(response.data.business));
         localStorage.setItem('external_menu', 0);
         localStorage.setItem('branchoffice', JSON.stringify(response.data.branchoffice));
         localStorage.setItem('branchoffice_original', JSON.stringify(response.data.branchoffice));
         localStorage.setItem('machine', JSON.stringify(response.data.machine));
         setShowModalKeyCode(false)


         //interval of reload the page
         setTimeout(() => {
            redirection()
         }, 3000);
      } catch (err) {

         setCodeSesion({
            business_code: "0000",
            branchoffice_code: "0000",
            machine_code: "0000",
         });

         ErrorAlert('', err.response.data.error, 'error')
         document.getElementById("inputCodeQr").value = ""
         // message, title, icon
         console.log(err);
      }
   }

   const handleChange = (e) => {

      if (e.key === 'Enter') {
         LoadingAlert()
         let inputValue = e.target.value
         console.log(inputValue);

         let arrayCodeString = inputValue.split("X");
         console.log(arrayCodeString);

         codeSesion.business_code = arrayCodeString[3]
         codeSesion.branchoffice_code = arrayCodeString[2]
         codeSesion.machine_code = arrayCodeString[1]
         setCodeSesion(codeSesion)
         authMachineVending()

      }
   }

   useEffect(() => {
      Valid()
   }, [])
   return (
      <>

         <main className='vending' onClick={() => (inputRefLog.current.focus())}>
            {/* <div className="vending-content d-flex align-items-center flex-column bd-highlight">
               <div className='vendindg-title  bd-highlight  py-4 border'>
                  <h2 className="fw-bold mb-0 text-center">Escanee el Código QR</h2>
               </div>
               <div className='vendindg-img mb-auto px-1 bd-highlight border ' >
                  <div className="d-flex justify-content-center gap-4  align-self-center ">
                     <img src={qr_img} alt="QR Img" className='vending-qr' />
                  </div>
               </div>
               <div className='vending-button bd-highlight border'>
                  <div className="d-flex justify-content-center">
                     <div className='vending-button-content'>
                        <h2 className="fw-bold mb-1 text-center button-sub-title ">O utiliza</h2>
                        <button type="button" className="btn btn-lg btn-vending mt-1 w-100" onClick={() => (setShowModalKeyCode(true))}>
                           Introducir el código de forma manual
                        </button>
                     </div>
                  </div>
               </div>
            </div> */}
            <div className="d-flex align-items-center flex-column bd-highlight">
               <div className='vendindg-title  bd-highlight  pt-1'>
                  <h2 className='fw-bold mb-0 text-center text-title-help'>
                     Validación de Usuario
                  </h2>
               </div>
               <div className='container  mt-5'>
                  <div className='vendindg-message  bd-highlight  py-2'>
                     <h2 className="mb-0 text-center text-title-help">
                        Por favor, coloque el código QR en el escáner de la máquina
                     </h2>
                  </div>
                  <div className='vendindg-img bd-highlight my-5' >
                     <div className="d-flex justify-content-center  align-self-center ">
                        <img src={scanQR2} alt="QR Img" className='vending-qr' loading='lazy' />
                     </div>
                  </div>
                  <div className='vending-button bd-highlight'>
                     <div className="d-flex justify-content-center">
                        <div className='vending-button-content'>
                           <h2 className="fw-bold mb-1 text-center button-sub-title ">O utilice  </h2>

                           <button type="button" className="btn 
                                        btn-lg                                      
                                        mt-1 w-100
                                        btn-other                                         
                                        border-0
                                        text-primary
                                        "

                              onClick={() => (setShowModalKeyCode(true))}>
                              Ingresar el código de forma manual
                           </button>
                        </div>
                     </div>
                  </div>

               </div>

            </div>
         </main>
         <input id="inputCodeQr" className='vending-input-qr' ref={inputRefLog} inputMode="none" type="text" onKeyPress={(e) => handleChange(e)} autoFocus></input>

         <MachineKeyCode
            codeSesion={codeSesion}
            setCodeSesion={setCodeSesion}
            authMachineVending={authMachineVending}
            showModalKeyCode={showModalKeyCode}
            setShowModalKeyCode={setShowModalKeyCode}
         />


         <div className="position-absolute bottom-0 end-0 FW-BOLD  opacity-25">
            <h2 className='px-2'>
               Powered By Smart Retail Group
            </h2>
         </div>
      </>
   )
}

export default LoginKioskMachine