import React from 'react'
import smartpay from '../../assets/img/logos/logo.png'

function PosPayment() {
   return (
      <>
         <main className='container-fluid main-pos-smartpay'>
            <div className="px-4 py-5 my-5 text-center">
               <img className="d-block mx-auto" src={smartpay} alt="Log Smart Pay" width="300" height="300" />
               <h2>Servidor Iniciado </h2>
            </div>
         </main>

      </>
   )
}

export default PosPayment