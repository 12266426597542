import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';

import Swal from "sweetalert2";
import imgPreOrder from './img/preOrder.png'
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import { GiPlayerTime } from 'react-icons/gi';
import Empty from '../utils/Empty';
import ModalDetailPreOrder from './ModalDetailPreOrder';
import ModalDivisionByProduct from './ModalDivisionByProduct';
import refund from '../img/icons/refund.png'


function ModalDivisionByProductPart({ order, setShowDivision, getAll }) {
    const [showPartsByProducts, setShowPartsByProducts] = useState(false);
    const [showByProduct, setShowByProduct] = useState(false);
    const [dataOrder, setDataOrder] = useState({});
    const [division, setDivision] = useState(0);
    const [parts, setParts] = useState([])


    const newPartsOrder = () => {
        let arrayParts = [];

        for (let index = 0; index < order.groups.length; index++) {
            arrayParts.push((index + 1));
        }
        setParts(arrayParts);
        console.log(arrayParts);
    }

    const actionDivisionParts = () => {
        if(order.groups.length > 1){
            setShowPartsByProducts(true)
        }else{
            ErrorAlert("", "Esta precuenta solo tiene un producto", "warning")
        }
        
    }

    useEffect(() => {
        newPartsOrder();
    }, [])


    return (
        <>
            <button type="button" class="btn btn-outline-primary btn-lg btn-block m-3 p-5" onClick={() => actionDivisionParts()}><div><i class="uil uil-minus-path display-1"></i><h4 className='fw-bold'> Por productos </h4></div></button>

            <Modal
                show={showPartsByProducts}
                fullscreen
                onHide={() => setShowPartsByProducts(false)}
                aria-labelledby="example-custom-modal-styling-title"
            >

                <Modal.Body scrollable>

                    <div>
                        <div className='mt-2 text-center p-1'>
                            <h4 className='fw-bold'>  <i class="uil uil-apps"></i> Dividir en partes iguales </h4>
                        </div>

                        <div >
                            <ModalDetailPreOrder orderData={order}></ModalDetailPreOrder>
                        </div>
                    </div>

                    <div className='mt-2 text-center p-1 mt-5'>
                        <h4 className='fw-bold'><i class="uil uil-ellipsis-v"></i> Selecciona en cuantas cuentas separaras los productos </h4>
                    </div>

                    <div className='container'>

                        <div className='d-flex justify-content-center alight-item-center row'>
                            {parts.map((index) => (
                                <>
                                    {index > 1 ? <>
                                        <div className='col-sm d-flex justify-content-center alight-item-center'><button type="button" class="btn btn-outline-primary btn-lg btn-block m-3 p-5" onClick={() => (setDivision(index), setShowByProduct(true))}><div><h4 className='fw-bold'><i class="uil uil-minus-path"></i> {index} </h4></div></button></div>
                                    </> : <></>}
                                </>
                            ))}
                        </div>
                    </div>




                </Modal.Body>

                <Modal.Footer>
                    <div className='d-flex justify-content-between mt-3'>
                        <button className='btn btn-danger btn-lg' onClick={() => setShowPartsByProducts(false)} > Cancelar </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <ModalDivisionByProduct order={order} setShowDivision={setShowDivision} getAll={getAll} setShowPartsByProducts={setShowPartsByProducts} showByProduct={showByProduct} setShowByProduct={setShowByProduct} division={division}></ModalDivisionByProduct>
        </>

    )
}

export default ModalDivisionByProductPart