import React, { useState } from 'react'
import KeyPad from '../../components/Keypad/keypad';
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad';
//Component
import Header from "./components/Header"
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import { AuthFetchAPI } from '../../api/Auth';
import { CouponPosFetchAPI } from '../../api/Paymentpost/Coupon';
import { DiscountPosFetchAPI } from '../../api/Paymentpost/Discount';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

function Modalnotificator({ icon, background, showModalNotificator, setShowModalNotificator, formatJsonOrder, setLocator, businesssConfig, nextStep, setAtivity = { setAtivity } }) {
   //state of keypad number
   const [displayNumber, setDisplayNumber] = useState('');
   const [valueInput, setValueInput] = useState("");




   //Focus
   const [isButtonFocusedId, setButtonFocusId] = useState(null);

   const handleButtonFocus = (buttonId) => {
      setButtonFocusId(buttonId);
   };

   const handleButtonBlur = () => {
      setButtonFocusId(null);
   };


   const handlersaveLocator = async () => {
      setLocator(displayNumber);
      formatJsonOrder();
      setShowModalNotificator(false);
      nextStep();

   }

   return (
      <>
         <Modal
            show={showModalNotificator}
            fullscreen
            onHide={() => setShowModalNotificator(false)}>
            <Modal.Body className='p-0 m-0'>
               <main className='keyboard-main h-100 d-flex  justify-content-center align-items-center  ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(5)}>
                  <div className=''>
                     <section className='bd-highlight mt-1'>
                        <Header
                           classContainer={"kiosk-delivery_header m-0 p-0"}
                           classImg={"kiosk-delivery_header_img"}>
                        </Header>
                        <div className='d-flex justify-content-center alight-item-center'><h2 className='kiosk-delivery_title  text-center '>
                           ¡Hola! Ingrese numero de localizador
                        </h2>
                           {/* <img src={businesssConfig.locator_img} height={"50"} width={"50"} className='m-3'></img> */}

                        {/* <h2 className="keyboard-title"><i className="uil uil-pricetag-alt"></i> </h2> */}

                        </div>
                     </section>

                     <section className='bd-highlight w-100'>
                        <NumericKeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} maxString={4} placeHolderInput={"000"} />
                     </section>
                     <section className='bd-highlight row'>
                        {/* <div className='col-md-6'>
                           <button type="button" className=" btn btn-lg btn-vending-secondary btn-vending w-100 text-dark" onClick={() => (handleClearAllData())}>
                           <i className="uil uil-arrow-right"></i> Limpiar 
                           </button>
                        </div> */}
                        <div className='col-12'></div>
                     </section>
                  </div>
               </main>
            </Modal.Body>
            {/* <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => (setShowModalAplyCupon(false), setDisplayNumber(''))}>
                        <i className="uil uil-arrow-left"></i>
                        Regresar
                    </button>

                    <button type="button" className=" btn btn-success btn-vending-product mx-4 rounded" onClick={() => (handleSaveCode())}>
                        <i className="uil uil-arrow-right"></i> Siguiente
                    </button> */}

            <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
               <button
                  onClick={() => (setShowModalNotificator(false), setDisplayNumber(''))}
                  className={`btn btn-danger kiosk-subtitle-md kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4`}>
                  Regresar
               </button>
               <button
                  className={`btn btn-success kiosk-subtitle-md kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}
                  onClick={() => handlersaveLocator()}>
                  Continuar
               </button>
            </div>

         </Modal>

      </>
   )
}

export default Modalnotificator