import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { ItemsFechtAPI } from '../../../api/Items';
import { TaxesFetchAPI } from '../../../api/Taxes';

function ModalEditItemTosegment({ item, segment, load }) {
    const [eventHandle, setEventHandle] = useState(0);
    const [showModal, setShowModal] = useState(false)
    const [taxes, setTaxes] = useState([]);
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append("segment_id", segment.segment_id
        );

        const updateItemToSegment = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await ItemsFechtAPI.updataItemTosegment(item.item_id, updateItemToSegment, token);
            //We show the success alert 

            ErrorAlert('', 'Item actualizado con exito', 'success')
            load();

            setShowModal(false);
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de actualizacion', 'error')
            console.log(err.response.data.error)

        }
    }

    const getTaxes = async () => {

        console.log(segment)
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

 



    useEffect(() => {

    }, [eventHandle])




    return (
        <>
            <button className='btn btn-outline-primary btn-sm border-0'
                onClick={(e) => (setShowModal(true), getTaxes())}
            >
                <i className="uil uil-edit"></i>
            </button>



            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-edit"></i>Editar item del segmento</h3>
                </Modal.Header>
                <Modal.Body>

                    <ListGroup as="ol" >
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <img
                                className='imgitemsList m-1'
                                src={item.img}
                                alt={item.name}
                            />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{item.name}</div>
                                {item.description}
                            </div>
                        </ListGroup.Item>
                    </ListGroup>


                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >
                        <Form.Group className="mb-3" controlId="segment_per_item_price">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={parseFloat(item.price).toFixed(2)}
                                name="segment_per_item_price"
                                required
                            />
                        </Form.Group>



                       

                        {/*<Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Impuesto del item</Form.Label>
                            <Form.Select aria-label="" name="tax" required >
                                <option value={item.taxt_id}>{item.tax}</option>
                                {taxes.map(tax => (
                                    <option value={tax.id}>{tax.tax}</option>
                                ))}
                            </Form.Select>
                                </Form.Group>*/}

                   

                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="submit" className='m-3'  >
                                Guardar
                            </Button>
                        </div>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalEditItemTosegment