import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import LoadingAlert from '../Alerts/LoadingAlert';
import Empty from '../utils/Empty';
import ErrorAlert from '../Alerts/ErrorAlert';
import { FaHistory } from 'react-icons/fa';
import { RenderIf } from '../utils/RenderIf';
import { MachinesFetchAPI } from '../../api/Machines';
import { CashTempPosFetchAPI } from '../../api/Paymentpost/OrderTemporalCash';
import { PrinterFetchAPI } from '../../api/Printer';
import { OrdersFetchAPI } from '../../api/Orders';
import { RequestLocalFetchAPI } from '../../api/Paymentpost/RequestLocal';
import ModalPhoneSaveYappy from './ModalPhoneSaveYappy';
import ModalPaymentManual from './ModalPaymentManual';
import Cashierpost from '../cahsier/Cashierpost';

import loading from './img/loading.gif'
import empty_img from './img/empty_pays.png';
import checkimg from './img/check.png';
import cancel from './img/cancel.png'
import sadImg from './img/sad.png'



function ModalPaymentMixto({ showModalMixto, setShowModalMixto, paymentMethod, total, subtotal, discount, tax, orderNumber, registernewOrder, listPaymentReady, setlistPaymentReady, amountPaymentReady, setAmountPaymenReady, shoppingCart, amountPending, setAmountPending, change, setChange }) {
    const [paymentInfo, setPaymentInfo] = useState({ payment: "" })
    const [showModalAmount, setShowModalAmount] = useState(false)
    const [amountPayment, setAmountPayment] = useState(0)
    const [showModalProcess, setShowModalProcess] = useState(false);
    const [paymentApproval, setPaymentApproval] = useState({ status: 0, description: "Procesando pago" })
    const [tempMethodPayment, setTempMethodPayment] = useState(0)
    const [showModalPhone, setShowModalPhone] = useState(false);
    const [phoneClientYappy, setPhoneClientYappy] = useState("0");
    const [EMVStreamResponseMixto, setEMVStreamResponseMixto] = useState(0)
    //const [EMVStreamResponseMixto, setEMVStreamResponseMixto] = useState(0)
    //const [change, setChange] = useState(0);
    const [orderNumbeTemp, setOrderNumberTemp] = useState({ code: "", temp_id: "" })
    const [numberOrderMixto, setNumberOrderMixto] = useState("")
    const [showPaymentManual, setPaymentManual] = useState(false)


    let ventanaYappy = 0;
    let instervalCash = 0

    //cashier states
    const [modalShow, setModalShow] = useState(false);
    const [modalShowErrorRegisterOrder, setModalShowErrorRegisterOrder] = useState(false)
    const [detailOrder, setDetailOrder] = useState()
    const [amountOrder, setAmountOrder] = useState()

    //Functions to show and hide the modal cashier
    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true)



    //save info of payment
    const selectPayment = (e) => {
        if (e.target.checked == true) {
            let payment = JSON.parse(e.target.value)
            setPaymentInfo(payment)
        }
    }

    //next step phone number yappy
    const nextStepYappy = () => {
        setShowModalPhone(false)
        setShowModalProcess(true);

        //request c# yappy
        requestPyament(orderNumber);
        //createOrderCash("Yappy")
    }

    const statusCash = (status) => {
        if (status == 1) {//aprobed
            //function register payment

            let newAmountReady = (parseFloat(amountPayment) + parseFloat(amountPaymentReady))
            let statusPaymentTurn = false;
            for (let i = 0; i < listPaymentReady.length; i++) {
                if (listPaymentReady[i].paymethod_id == paymentInfo.payment_id) {
                    let newTotal = (parseFloat(listPaymentReady[i].total) + parseFloat(amountPayment))
                    listPaymentReady[i].total = newTotal.toFixed(2)
                    setlistPaymentReady(listPaymentReady)
                    statusPaymentTurn = true
                }
            }

            if (statusPaymentTurn == false) {
                setlistPaymentReady([...listPaymentReady, { name: paymentInfo.payment, paymethod_id: paymentInfo.payment_id, total: parseFloat(amountPayment).toFixed(2) }]);
            }

            console.log(newAmountReady);
            setAmountPending((parseFloat(total).toFixed(2) - parseFloat(newAmountReady).toFixed(2)))
            setAmountPaymenReady(newAmountReady)

            setTimeout(() => {
                setAmountPayment(0)
                setShowModalProcess(false);
                setPaymentApproval({ status: 0, description: "Procesando pago..." })

            }, 1500);


            handleCloseModal()
        } else if (status == 2) {//cancel
            setShowModalProcess(false);
            handleCloseModal()
        }
    }

    //function process payment of the order
    const processPayment = async () => {

        if (parseFloat(amountPayment) > parseFloat(amountPending).toFixed(2)) {
            console.log("Ingresado: " + parseFloat(amountPayment));
            console.log("Pendiente: " + parseFloat(amountPending).toFixed(2));
            ErrorAlert("", "El valor ingresado supera el valor pendiente", "warning")
        } else {
            setShowModalProcess(true);
            setShowModalAmount(false)
            let title = 'Cargando....'
            let message = 'Cargando información....<br/> <br/>'
            let namePayment = paymentInfo.payment
            //-----------------------------------------------------
            //setBotonDeshabilitado(true);

            if (namePayment != "") {
                if (namePayment == 'Efectivo') {
                    title = 'Esperando aprobacion....'
                    message = 'El administrador esta aprobando el pago....<br/> <br/>'
                    //LoadingAlert(title, message)
                    //createOrderCash("Efectivo")

                    //show cash modal
                    setDetailOrder({ total: parseFloat(amountPayment), code: "0000" })
                    handleShowModal();
                } else if (namePayment == 'Yappy') {
                    setShowModalPhone(true)
                    //setChange(0);
                } else {

                    if (paymentInfo.integration.id == 1) {
                        console.log("integracion con bac")
                        requestPyament();
                    } else if (paymentInfo.integration.id == 3) {
                        console.log("metodo de pago manual")
                        setPaymentManual(true)
                        setShowModalProcess(false);
                    }

                    //setChange(0);
                }

                setTimeout(() => {
                    setTempMethodPayment(1);
                }, 15000);
            } else {
                ErrorAlert("No hay metodo de pago.", "Selecciona un metodo de pago", "info");
                //setBotonDeshabilitado(false)
            }
        }
    }

    //create order cash temporal
    let type = 0
    const createOrderCash = async (type_method) => {
        let token = localStorage.getItem('x-access-machine-token');
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let machine = JSON.parse(localStorage.getItem('machine'))
        type = 0
        let json = {
            "client_name": "Estimado cliente",
            "localizador": "",
            "client_phone": "",
            "dgi_qr": "",
            "Orden": shoppingCart,
            "Descuento": 0.00,
            "Total": amountPayment,
            "itbms": 0.00,
            "subtotal": amountPayment,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": "comer aqui",
            "paymethod": paymentInfo.id,
            "is_discount_notification": false,
            "type_method": type_method,
            "transaction_code": orderNumber
        }

        try {
            const response = await CashTempPosFetchAPI.createTempCash(json, token)

            if (type_method == "Yappy") {
                console.log(response.data.newTempOrder._id);
                requestPyament(response.data.newTempOrder.code);
                setOrderNumberTemp({ code: response.data.newTempOrder.code, temp_id: response.data.newTempOrder._id })
            }

            console.log(response);
            getOrdersTempCash();
        } catch (err) {
            console.log(err.response.data.error);
            type = 1
            console.log("Hay una temporal");
            getOrdersTempCash();

            console.log(err);
        }
    }


    //get order temp cash
    let countGetCash = 0 // count to limit get orders
    const getOrdersTempCash = async () => {

        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let machine = JSON.parse(localStorage.getItem('machine'))
        let business = JSON.parse(localStorage.getItem('business'))
        clearInterval(instervalCash)
        try {
            const response = await CashTempPosFetchAPI.getTempCash(machine.code, business.code, branchoffice.code)
            console.log(response);
            let code = response.data.order.code
            let message = ""
            let title = ""
            let icon = ""

            if (type == 0) {
                if (countGetCash == 0) {
                    if (paymentInfo.payment != "Yappy") {
                        handleShowModal();
                    }
                    setDetailOrder(response.data.order);
                }

                if (response.data.order.status_id == 1) {
                    if (countGetCash <= 100) {
                        instervalCash = setInterval(getOrdersTempCash, 5000)
                        countGetCash++
                    } else {
                        message = "Tiempo de espera agotado"
                        title = "Advertencia"
                        icon = "warning"

                        if (paymentInfo.payment == "Yappy") {
                            ventanaYappy.close();
                            setPaymentApproval({ status: 3, description: "Tiempo de espera agotado." });
                        }
                        handleCloseModal()
                        updateOrderDiscount(code, 5, message, title, icon)
                        countGetCash = 0
                    }

                    //!Fixed Eroor: Correjir mensjae y  poner loading 
                } else if (response.data.order.status_id == 8) {//order temporal is cancel

                    title = "Operacion cancelada"
                    message = "Orden temporal cancelada"
                    icon = "error"

                    //setBotonDeshabilitado(false);

                    if (paymentInfo.payment == "Yappy") {
                        ventanaYappy.close();
                        setPaymentApproval({ status: 3, description: "Ocurrio un error." });
                    }
                    updateOrderDiscount(code, 5, message, title, icon)


                } else if (response.data.order.status_id == 7) {//order temporal is confirm
                    message = "El pago fue aprobado."
                    title = "Pago exitoso"
                    icon = "success"

                    if (paymentInfo.payment == "Yappy") {
                        ventanaYappy.close();
                        setPaymentApproval({ status: 1, description: "Pago exitoso." });
                    }

                    if (paymentInfo.payment == "Efectivo") {
                        //setShow(false)
                    }
                    updateOrderDiscount(code, 3, message, title, icon)

                    let newAmountReady = (parseFloat(amountPayment) + parseFloat(amountPaymentReady))
                    let statusPaymentTurn = false;
                    for (let i = 0; i < listPaymentReady.length; i++) {
                        if (listPaymentReady[i].paymethod_id == paymentInfo.payment_id) {
                            let newTotal = (parseFloat(listPaymentReady[i].total) + parseFloat(amountPayment))
                            listPaymentReady[i].total = newTotal.toFixed(2)
                            setlistPaymentReady(listPaymentReady)
                            statusPaymentTurn = true
                        }
                    }

                    if (statusPaymentTurn == false) {
                        setlistPaymentReady([...listPaymentReady, { name: paymentInfo.payment, paymethod_id: paymentInfo.payment_id, total: parseFloat(amountPayment).toFixed(2) }]);
                    }

                    console.log(newAmountReady);
                    setAmountPending((parseFloat(total).toFixed(2) - parseFloat(newAmountReady).toFixed(2)))
                    setAmountPaymenReady(newAmountReady)

                    setTimeout(() => {
                        setAmountPayment(0)
                        setShowModalProcess(false);
                        setPaymentApproval({ status: 0, description: "Procesando pago..." })

                    }, 1500);

                }
            } else if (type == 1) {
                console.log("Consulte la temporal remanante");
                updateOrderDiscount(code, 5, message, title, icon)
            }
        } catch (err) {
            console.log(err);
        }
    }

    //update orders temporal discount
    const updateOrderDiscount = async (code, status, message, title, icon) => {
        console.log(code);
        let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
        let business = JSON.parse(localStorage.getItem('business'))

        let jsonUpdate = {
            "order_code": code,
            "business_code": business.code,
            "branchoffice_code": branchoffice.code,
            "status": status
        }
        try {
            const response = await CashTempPosFetchAPI.updateOrderTempCash(jsonUpdate)
            console.log(paymentInfo.payment);

            if (type == 0) {
                if (paymentInfo.payment == "Yappy") {
                    setTimeout(() => {
                        setShowModalProcess(false);
                        setPaymentApproval({ status: 0, description: "Procesando pago..." });
                    }, 3000);
                }


                ErrorAlert(message, title, icon)


            } else if (type == 1) {
                console.log("Solicite crear nuevamente la temporal");
                createOrderCash(paymentInfo.payment)
            }
        } catch (err) {
            console.log(err);
        }

    }


    //function action request pyment local
    const requestPyament = async (yappy_Temp_id) => {
        setShowModalProcess(true);
        let JsonTr = {}

        if (paymentInfo.payment == "Yappy") {
            JsonTr = [{
                Tipo: 'SALE',
                Producto: 'COBRAR',
                Itbms: tax.toFixed(2),
                Descuento: discount.toFixed(2),
                Monto: parseFloat(amountPayment).toFixed(2),
                TipoVenta: paymentInfo.payment.toUpperCase(),
                paymentMethod: paymentInfo,
                orderid: yappy_Temp_id,
                tel: "507" + phoneClientYappy,
                total: parseFloat(amountPayment).toFixed(2),
                subtotal: parseFloat(amountPayment).toFixed(2),
                taxes: 0.00,
            }];
        } else {

            let methodPayment = ""
            if (paymentInfo.payment == "MasterCard") {
                methodPayment = "MC"
            } else {
                methodPayment = paymentInfo.payment
            }

            JsonTr = [{
                Tipo: 'SALE',
                Producto: 'COBRAR',
                Itbms: 0.00,
                Descuento: 0.00,
                Monto: parseFloat(amountPayment).toFixed(2),
                TipoVenta: methodPayment.toUpperCase(),
                paymentMethod: paymentInfo,
                orderid: orderNumber,
            }];
        }

        try {
            let machine = JSON.parse(localStorage.getItem('machine'))
            const response_machine = await MachinesFetchAPI.getmachineByid(machine.id, localStorage.getItem('x-access-machine-token'));
            const response = await RequestLocalFetchAPI.postPaymentMethod(JsonTr, response_machine.data.data.windows_ip);

            //setEMVStreamResponseMixto(response.data.data.EMVStreamResponse)

            if(EMVStreamResponseMixto.voucher){
                setEMVStreamResponseMixto({voucher: EMVStreamResponseMixto.voucher +"\r\n\r\n- - - - - - - - - - - - - - - - - - - -\r\n"+ response.data.data.EMVStreamResponse.voucher})
            }else{
                setEMVStreamResponseMixto(response.data.data.EMVStreamResponse)
            }

            let responseCode = response.data.data.EMVStreamResponse.responseCode;
            let description = response.data.data.EMVStreamResponse.responseCodeDescription;



            function successPayment() {
                setPaymentApproval({ status: 1, description: description });
                let newAmountReady = (parseFloat(amountPayment) + parseFloat(amountPaymentReady))
                let statusPaymentTurn = false;
                for (let i = 0; i < listPaymentReady.length; i++) {
                    if (listPaymentReady[i].paymethod_id == paymentInfo.payment_id) {
                        listPaymentReady[i].total = (parseFloat(listPaymentReady[i].total) + parseFloat(amountPayment))
                        setlistPaymentReady(listPaymentReady)
                        statusPaymentTurn = true
                    }
                }

                if (statusPaymentTurn == false) {
                    setlistPaymentReady([...listPaymentReady, { name: paymentInfo.payment, paymethod_id: paymentInfo.payment_id, total: parseFloat(amountPayment).toFixed(2) }]);
                }

                setAmountPending((parseFloat(total).toFixed(2) - parseFloat(newAmountReady).toFixed(2)))
                setAmountPaymenReady(newAmountReady)
            }

            //condition 
            console.log(response)
            if (paymentInfo.payment != "Yappy") {

                if (responseCode == "00") {
                    successPayment()
                } else {
                    setPaymentApproval({ status: 2, description: description });

                }

            } else {

                if (responseCode == "20") {
                    successPayment()
                } else {
                    setPaymentApproval({ status: 2, description: description });
                }

                {/*condition yappy temp
                if (response.data.data == "") {
                    setPaymentApproval({ status: 3, description: "Ocurrio un error." });

                } else {
                    ventanaYappy = window.open(response.data.data, "test", "");
                }*/}

            }

            setTimeout(() => {
                setAmountPayment(0)
                setShowModalProcess(false);
                setPaymentApproval({ status: 0, description: "Procesando pago..." })

            }, 3000);

        } catch (err) {
            setAmountPayment(0)
            //-----------------------------------------------------------------------------
            console.log(err);

        }
    }

    //register payment aproved manual
    const aprovedManualPaymentacardMixto = (description, reference) => {

        if(EMVStreamResponseMixto.voucher){
            setEMVStreamResponseMixto({voucher: EMVStreamResponseMixto.voucher +"\r\n\r\n- - - - - - - - - - - - - - - - - - - -\r\n"+ reference})
        }else{
            setEMVStreamResponseMixto({voucher: reference})
        }
        
        setPaymentApproval({ status: 1, description: description });
        let newAmountReady = (parseFloat(amountPayment) + parseFloat(amountPaymentReady))
        let statusPaymentTurn = false;
        for (let i = 0; i < listPaymentReady.length; i++) {
            if (listPaymentReady[i].paymethod_id == paymentInfo.payment_id) {
                listPaymentReady[i].total = (parseFloat(listPaymentReady[i].total) + parseFloat(amountPayment))
                setlistPaymentReady(listPaymentReady)
                statusPaymentTurn = true
            }
        }

        if (statusPaymentTurn == false) {
            setlistPaymentReady([...listPaymentReady, { name: paymentInfo.payment, paymethod_id: paymentInfo.payment_id, total: parseFloat(amountPayment).toFixed(2)  }]);
        }

        setAmountPending((parseFloat(total).toFixed(2) - parseFloat(newAmountReady).toFixed(2)))
        setAmountPaymenReady(newAmountReady)


        //---------------------------------------------------------------------------------
        setAmountPayment(0)
        setShowModalProcess(false);
        setPaymentApproval({ status: 0, description: "Procesando pago..." })

    }

    //cancel temporal transaction yappy
    const cancelTempYappy = () => {
        let message = "Orden temporal cancelada"
        let title = "Operacion cancelada"
        let icon = "error"
        updateOrderDiscount(orderNumbeTemp.code, 5, message, title, icon)
    }

    //cancel method payment in process
    const cancelMethodPayment = () => {
        setTempMethodPayment(0);
        setPaymentApproval({ status: 0, description: "Procesando pago..." })

        setShowModalProcess(false);
        {/*if (paymentInfo.payment == "Yappy") {
            let message = "Orden temporal cancelada"
            let title = "Operacion cancelada"
            let icon = "error"
            updateOrderDiscount(orderNumbeTemp.code, 5, message, title, icon)
        } else {
            setShowModalProcess(false);
        }*/}
    }

    //function try again yappy
    const try_again_yappy = () => {
        setPaymentApproval({ status: 0, description: "Procesando pago..." })
        requestPyament(orderNumbeTemp.temp_id);
    }


    //function action select payment
    const handlerPaymentStatus = () => {
        if (paymentInfo.payment != "") {
            setShowModalAmount(true)
        } else {
            ErrorAlert("No hay metodo de pago.", "Selecciona un metodo de pago", "info");
        }
    }




    return (
        <>

            <Modal
                fullscreen
                show={showModalMixto}
                onHide={() => setShowModalMixto(false)}
                backdrop="static"
                centered>

                <h3 className='text-center my-4 fw-bold '>
                    Pago mixto
                </h3>
                <Modal.Body>
                    <div className='row '>
                        <div className='col-md-6 '>
                            <h5 className='text-primary fw-bold'>Seleccione un método de pago</h5>
                            <div className='row'>
                                {/* Validation Payments Methods */}
                                {
                                    !paymentMethod.length
                                        ? <Empty title="Vacío" msg="No hay métodos de pago asignados" img={empty_img} />
                                        : paymentMethod.map(option => (

                                            // <div>
                                            //   <input type="radio" className="btn-check" name="options-outlined" id={option.id+"payment"} autocomplete="off" value={JSON.stringify(option)} onChange={(e) => (selectPayment(e))} />
                                            //   <label className="btn btn-outline-success" htmlFor={option.id+"payment"} >{option.payment}</label>
                                            // </div>

                                            <> {option.payment != "Credito" && option.payment != "Mixto" ? <>
                                                <div className="col-4 col-md-4 col-lg-3 mt-3" key={option.id}>
                                                    <small className='fw-bold mx-3 '>
                                                        {option.integration.name}
                                                    </small>
                                                    <input type="radio" className="btn-check"
                                                        name="option_payments" id={option.id + "paymentMixto"} value={JSON.stringify(option)} onChange={(e) => (selectPayment(e))}
                                                    />
                                                    <label className="btn btn-outline-primary" htmlFor={option.id + "paymentMixto"}>
                                                        <img src={option.img} className="img-pay-met bg-success rounded px-2" alt="Imagen Payments" />
                                                        <small className='fw-bold mx-4'>
                                                            {option.payment}
                                                        </small>
                                                    </label>
                                                </div>
                                            </> : <></>}
                                            </>
                                        ))
                                }
                                {/* 

                {/* {listmethod()} */}

                            </div>

                        </div>
                        <div className='col-md-6 border rounded shadow-sm'>
                            <div>
                                <div className="order-total mt-2 mx-1 mb-auto">
                                    <p className='text-size-order' > <b>Total con impuesto:</b>  <span>$ <strong>{total.toFixed(2)}</strong></span></p>

                                    <hr />

                                    {listPaymentReady.map(payment => (
                                        <>
                                            <p className='text-size-order' >{payment.name}: <span>$ <strong>{parseFloat(payment.total).toFixed(2)}</strong></span> </p>
                                        </>
                                    ))}
                                    <p className='text-size-order text-danger' > <b>Saldo pendiente:</b>  <span>$ <strong>{(amountPending).toFixed(2)}</strong></span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between mt-3'>

                        <RenderIf isTrue={parseFloat(amountPending).toFixed(2) == 0.00}>
                            <button className='btn btn-success btn-lg m-2' onClick={() => (registernewOrder(EMVStreamResponseMixto), setShowModalMixto(false), setEMVStreamResponseMixto(0))} >Finalizar pago </button>
                        </RenderIf>

                        <RenderIf isTrue={parseFloat(amountPending).toFixed(2) > 0.00}>
                            <button className='btn btn-danger border-0 btn-lg m-2' onClick={() => setShowModalMixto(false)} >Cancelar </button>
                            <button className='btn btn-success btn-lg m-2' onClick={() => handlerPaymentStatus()} >Realizar Pago </button>
                        </RenderIf>
                    </div>

                </Modal.Footer>
            </Modal>


            {/*Modal save amount payment select*/}
            <Modal
                show={showModalAmount}
                fullscreen
                onHide={() => setShowModalAmount(false)}
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >

                <Modal.Header className='border-0'>
                    <strong> {paymentInfo.payment} </strong>
                </Modal.Header>
                <Modal.Body className='bg-pay-met'>
                    <main className='d-flex justify-content-center align-items-center h-100 '>

                        <section className='border rounded p-4 w-50 position-relative bg-white'>
                            <div className='sections-messages'>

                                <div className='mt-2 text-center p-1'>
                                    <h4 className='fw-bold'> <i className="uil uil-dollar-alt"></i>  Ingrese un monto </h4>
                                </div>

                                <div className="input-group input-group-lg">
                                    <input type="number" className="form-control" placeholder='$0.00' onChange={(e) => (setAmountPayment(e.target.value))} />
                                </div>

                                <div className='border rounded shadow-sm mt-3 p-3'>
                                    <h4>Detalle</h4>
                                    <hr></hr>
                                    <div className="order-total mt-2 mx-1 mb-auto">
                                        <p className='text-size-order text-danger' >Saldo pendiente: <span>$ <strong>{(amountPending).toFixed(2)}</strong></span> </p>
                                        <p className='text-size-order text-warning' >Nuevo saldo: <span>$ <strong>{amountPayment != "" && amountPayment != null && amountPayment != undefined ? <>{(amountPending - amountPayment).toFixed(2)}</> : <>{amountPending.toFixed(2)}</>}</strong></span> </p>
                                        <p className='text-size-order' > <b>Total a pagar:</b>  <span>$ <strong>{total.toFixed(2)}</strong></span></p>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between mt-3'>
                                    <button className='btn btn-danger btn-lg' onClick={() => setShowModalAmount(false)}> Cancelar </button>
                                    <button className='btn btn-success btn-lg' onClick={async () => (processPayment())} > Aplicar  </button>
                                </div>
                            </div>
                        </section>
                    </main>
                </Modal.Body>
            </Modal>


            {/*Process payment Loading Modal */}
            <Modal
                fullscreen
                show={showModalProcess}
            >

                <Modal.Header className='border-0'>
                    <strong> PAGOS </strong>
                </Modal.Header>
                <Modal.Body className='bg-pay-met'>
                    <main className='d-flex justify-content-center align-items-center h-100 '>

                        <section className='border rounded p-4 w-50 position-relative bg-white'>
                            <div className='sections-messages'>
                                <RenderIf isTrue={paymentApproval.status == 0}>
                                    <div className='px-4 pb-1 mt-2 text-center'>
                                        <img className='mx-auto mb-4 ' src={loading} width='100px' height='100px' />
                                        <h2 className="display-6 fw-bold">
                                            {paymentApproval.description}
                                        </h2>
                                        {tempMethodPayment == 1 ? <Button onClick={() => cancelMethodPayment()} >Cancelar</Button> : ""}
                                    </div>
                                    <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-secondary">
                                        Método de Pago: {paymentInfo.payment}
                                    </span>
                                </RenderIf>

                                <RenderIf isTrue={paymentApproval.status == 1}>
                                    <div className='px-4 pb-1 mt-5 text-center'>
                                        <img className='mx-auto mb-4 ' src={checkimg} width='100px' height='100px' />
                                        <h2 className="display-6 fw-bold">
                                            ¡Éxito!
                                        </h2>
                                        <p> Su pago ha sido procesado correctamente.</p>
                                    </div>
                                    <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                                        Método de Pago: {paymentInfo.payment}
                                    </span>
                                </RenderIf>
                                <RenderIf isTrue={paymentApproval.status == 2}>
                                    <div className='px-4 pb-1 mt-5 text-center'>
                                        <img className='mx-auto mb-4 ' src={cancel} width='100px' height='100px' />
                                        <h2 className="fw-bold">
                                            {paymentApproval.description}
                                        </h2>
                                        <p> Por favor, verifique la información e intente nuevamente.</p>
                                    </div>
                                    <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                                        Método de Pago: {paymentInfo.payment}
                                    </span>

                                </RenderIf>

                                <RenderIf isTrue={paymentApproval.status == 3}>
                                    <div className='px-4 pb-1 mt-5 text-center'>
                                        <img className='mx-auto mb-4 ' src={cancel} width='100px' height='100px' />
                                        <h2 className="fw-bold">
                                            {paymentApproval.description}
                                        </h2>
                                        <p>Por favor, verifique la información e intente nuevamente.</p>
                                    </div>
                                    <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger">
                                        Método de Pago: {paymentInfo.payment}
                                    </span>

                                    <div className='d-flex justify-content-center alight-item-center m-2'>
                                        <Button className='m-2' onClick={() => (cancelTempYappy())}>Volver</Button>
                                        <Button className='m-2 ' onClick={() => (try_again_yappy())}> Intentar de nuevo</Button>
                                    </div>

                                </RenderIf>
                            </div>
                        </section>
                    </main>
                </Modal.Body>
            </Modal>


            {/*input phone client */}
            <ModalPhoneSaveYappy showModalPhone={showModalPhone} setShowModalPhone={setShowModalPhone} setPhoneClientYappy={setPhoneClientYappy} nextStepYappy={nextStepYappy}></ModalPhoneSaveYappy>


            {/* Modal cashier */}
            <Modal
                fullscreen
                show={modalShow}
                onHide={handleCloseModal}>
                <Modal.Header>

                    <div className='container'>
                        <div className='text-primary h4 bg-primary p-2 bg-opacity-10 shadow-sm rounded'>
                            <div className='d-flex justify-content-between'>
                                <div className='px-1 mx-5 display-5' >
                                    Orden:  <span className='fw-bold'> {!orderNumber ? "0000" : orderNumber}</span></div>
                                <div className='px-1 mx-5 display-5' >
                                    Monto a pagar: <span className='fw-bold'> ${amountOrder} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Cashierpost setChange={setChange} setAmountOrder={setAmountOrder} setOrderNumber={setNumberOrderMixto} setModalShow={setModalShow} order_data={detailOrder} statusCash={statusCash} />
                </Modal.Body>
            </Modal>

            {/*Modal payment manual*/}
            <ModalPaymentManual showPaymentManual={showPaymentManual} setPaymentManual={setPaymentManual} paymentInfo={paymentInfo} registernewOrder={registernewOrder} statusActivity={false} aprovedManualPaymentacardMixto={aprovedManualPaymentacardMixto}></ModalPaymentManual>




        </>
    )
}

export default ModalPaymentMixto