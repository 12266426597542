import React, { useState, useEffect } from 'react';
import imagesData from '../data/images.json';
import { PromotionsFechtAPI } from '../../../api/Promotions';


function Promotions({ icon, promotions, getSegmentsByPromotion }) {
   const [isButtonFocusedId, setButtonFocusId] = useState(null);
   
   const handleButtonFocus = (buttonId) => {
      setButtonFocusId(buttonId);
   };

   const handleButtonBlur = () => {
      setButtonFocusId(null);
   };

   const [currentIndex, setCurrentIndex] = useState(0);

   const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % promotions.length);

   };

   const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
         prevIndex === 0 ? promotions.length - 1 : prevIndex - 1
      );

   };


   useEffect(() => {
      const intervalId = setInterval(() => {
         setCurrentIndex((prevIndex) => (prevIndex + 1) % promotions.length);
      }, 8000);

      return () => clearInterval(intervalId);
   }, [promotions]);


   return (
      <>
         <div className="carousel-container">
            {promotions.map((promotion, index) => (
               <div
                  key={index}
                  className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
                  onClick={async () => getSegmentsByPromotion(promotion)}
               >
                  <img src={promotion.img} alt={`slide ${index}`} loading='lazy' />
               </div>
            ))}
         </div>
         <div className='position-absolute btn-direction w-100 d-flex justify-content-between'>

            {/* 1 */}
            <button className={`nav-button rounded-circle shadow position-relative
                     ${isButtonFocusedId === 'LEFT' ? 'focused' : ''}`}
               onClick={prevSlide}
               onFocus={() => handleButtonFocus('LEFT')}
               onBlur={handleButtonBlur}
            >
               <i className="uil uil-angle-left-b"></i>

               <div className="position-absolute postition-icon-promotion">
                  {isButtonFocusedId === 'LEFT' &&
                     <img src={icon} alt="Hand" className='icon-focus' />
                  }
               </div>
            </button>

            {/* 2 */}
            <button className={`nav-button rounded-circle shadow position-relative
                     ${isButtonFocusedId === 'RIGHT' ? 'focused' : ''}`}
               onFocus={() => handleButtonFocus('RIGHT')}
               onBlur={handleButtonBlur}
               onClick={nextSlide}>
               <i className="uil uil-angle-right-b "></i>

               <div className="position-absolute postition-icon-promotion">
                  {isButtonFocusedId === 'RIGHT' && <img src={icon} alt="Hand" className='icon-focus' />}
               </div>
            </button>
         </div>



      </>
   )
}

export default Promotions