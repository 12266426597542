import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


//Class to the calls of api of smartpay webhooks 
export class SmartpayWebhooksFetchAPI {

    //Function to get webhooks by branchoffice 
    static async getWebhooksByBranchoffice(branchoffice_id, token) {
        const res = await server(`/smartpay/webhook?branchoffice_id=${branchoffice_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }

    //Function to get webhooks available for smartpay
    static async getWebhookEvents(token) {
        const res = await server(`/smartpay/webhook/events`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res
    }
    //Function to register a webhook
    static async newWebhook(data, token) {
        const res = await server(`/smartpay/webhook`, {
            method: "post",
            data: data,
            headers: { 'x-access-token': token },
        });
        return res
    }

    //Function to remove a webhook
    static async removeWebhook(webhook_id, token) {
        const res = await server(`/smartpay/webhook?webhook_id=${webhook_id}`, {
            method: "delete",
            headers: { 'x-access-token': token },
        });
        return res
    }
}