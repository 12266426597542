import React from 'react';
function Biller() {
  return (
    <>
        <div>
                <div className=' payment-smart payment-sp'>
                    <div className='text-center'>
                        <h1 className='fs-2'>Facturador</h1>
                    </div>

                    <main className="biller-card border shadow-sm">
                        {/* <RenderIf isTrue={option.option === "Principal"}> */}
                            <span className='fs-5 fw-bold d-flex  justify-content-start'>Ingrese sus datos </span>
                            <div className='payment-form'>
                                {/* Formulario de envio de dinero  */}
                                <form className="login-form">
                                    <div className="row">
                                        <div className='col-6'>
                                            <label className='d-flex justify-content-start my-1 ' htmlFor="">Sub total </label>
                                            <input
                                                spellcheck="false"
                                                className="control"
                                                type="number"
                                                step="any"
                                                placeholder="0.00 "
                                                required
                                            />
                                        </div>

                                        <div className='col-6'>
                                            <label className='d-flex justify-content-start my-1' htmlFor="">Impuestos (itbms)</label>
                                            <input
                                                spellcheck="false"
                                                className="control"
                                                type="number"
                                                step="any"
                                                placeholder="0.00 "
                                                required
                                            />
                                        </div>

                                    </div>

                                    <div className=''>
                                        <label className='d-flex justify-content-start my-1' htmlFor="">Total </label>
                                        <input
                                            spellcheck="false"
                                            className="control"
                                            type="number"
                                            step="any"
                                            placeholder="0.00 "
                                            required
                                        />
                                    </div>

                                    <div className=''>
                                        <label className='d-flex justify-content-start my-1' htmlFor="">Número de orden </label>
                                        <input
                                            spellcheck="false"
                                            className="control"
                                            type="number"
                                            step="any"
                                            placeholder="0.00 "
                                            required
                                        />
                                    </div>



                                    <button className="control" type="submit">
                                        Siguiente
                                    </button>
                                </form>
                            </div>
       

                    </main>

                </div>
            </div>
    </>
  )
}

export default Biller