import React, { useState } from 'react'
import NumericKeyPad from '../../components/numeric_keypad/NumericKeyPad'
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap';
import { AuthFetchAPI } from '../../api/Auth';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import LoadingAlert from '../../components/Alerts/LoadingAlert';

import logo from '../../components/img/logos/logo.png'

function VendingModalKeyCode({ codeSesion, setCodeSesion, authMachineVending, showModalKeyCode, setShowModalKeyCode }) {
   //state of keypad number
   const [displayNumber, setDisplayNumber] = useState('');
   const [nameButton, setNameButton] = useState({ button: "Siguiente", title: "empresa" });


   //set code's of the sesion 
   const handleSaveCode = () => {
      if (codeSesion.business_code == "0000") {
         codeSesion.business_code = displayNumber;

         setDisplayNumber('')//clear input of the keypad
         nameButton.button = 'Siguiente';
         nameButton.title = 'Sucursal'

      } else if (codeSesion.branchoffice_code == "0000") {
         codeSesion.branchoffice_code = displayNumber;

         setDisplayNumber('')//clear input of the keypad
         nameButton.button = 'Iniciar sesion';
         nameButton.title = 'Máquina'

      } else if (codeSesion.machine_code == "0000") {
         codeSesion.machine_code = displayNumber;
         LoadingAlert()
         setDisplayNumber('') //clear input of the keypad
         authMachineVending()
         nameButton.button = 'Siguiente';
         nameButton.title = 'Empresa'
      }
   }

   //reset data of the sesion
   const handleClearAllData = () => {
      nameButton.button = 'Siguiente'
      setCodeSesion({ business_code: "0000", branchoffice_code: "0000", machine_code: "0000", });
      setDisplayNumber('');
      Swal.fire({
         title: 'Se han borrado todos los datos de sesión. Por favor, ingrese nuevamente.',
         imageUrl: 'https://4.bp.blogspot.com/-BSVi3LL3KAY/UtcAHycLOeI/AAAAAAAAicg/gw1LjfAzHz4/s1600/barrer+gregar+blogdeimagenes+(3).gif',
         imageWidth: 400,
         imageHeight: 400,
         timer: 4000,
         showConfirmButton: false,
         imageAlt: 'Clean image',
      })
   }

   return (
      <>
         <Modal
            show={showModalKeyCode}
            fullscreen
            onHide={() => setShowModalKeyCode(false)}>
            <Modal.Body>
               <main className='keyboard-main h-100 d-flex align-items-center '>
                  <div className=' d-flex flex-column bd-highlight container'>
                     {/* <header className='d-flex justify-content-center'>
                              <img src={logo} alt="Logo" width="300" height="300" />
                           </header> */}
                     <section className='bd-highlight my-5'>
                        <h2 className="keyboard-title">Ingrese el código de {nameButton.title}</h2>
                     </section>
                     <section className='keyboard-steps bd-highlight w-100 row g-3'>
                        <div className={codeSesion.business_code != '0000' ? 'col-4 keyboard-step bg-light' : 'col-4 keyboard-step'}>
                           <h3 className={codeSesion.business_code != '0000' ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>
                              Código Empresa
                           </h3>
                           <p
                              className={
                                 codeSesion.business_code != '0000'
                                    ? 'keyboard-steps-number keyboard-steps-number-active text-center '
                                    : 'keyboard-steps-number text-center text-muted opacity-25'}>
                              {codeSesion.business_code}

                           </p>
                        </div>
                        <div className={codeSesion.branchoffice_code != '0000' ? 'col-4 keyboard-step bg-light' : 'col-4 keyboard-step'}>
                           <h3 className={codeSesion.branchoffice_code != '0000' ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>Código Sucursal</h3>
                           <p className={codeSesion.branchoffice_code != '0000' ? 'keyboard-steps-number keyboard-steps-number-active text-center ' : 'keyboard-steps-number text-center text-muted opacity-25'}>{codeSesion.branchoffice_code}</p>
                        </div>
                        <div className={codeSesion.machine_code != '0000' ? 'col-4 keyboard-step bg-light' : 'col-4 keyboard-step'}>
                           <h3 className={codeSesion.machine_code != '0000' ? 'keyboard-steps-label text-center text-muted' : 'keyboard-steps-label text-center text-muted opacity-25'}>Código Sucursal</h3>
                           <p className={codeSesion.machine_code != '0000' ? 'keyboard-steps-number keyboard-steps-number-active text-center ' : 'keyboard-steps-number text-center text-muted opacity-25'}>{codeSesion.machine_code}</p>
                        </div>
                     </section>
                     <section className='bd-highlight w-100'>
                        <NumericKeyPad displayNumber={displayNumber} setDisplayNumber={setDisplayNumber} />
                     </section>
                     <section className='bd-highlight row'>
                        {/* <div className='col-md-6'>
                           <button type="button" className=" btn btn-lg btn-vending-secondary btn-vending w-100 text-dark" onClick={() => (handleClearAllData())}>
                           <i className="uil uil-arrow-right"></i> Limpiar 
                           </button>
                        </div> */}
                        <div className='col-12'><button type="button" className=" btn btn-lg btn-vending btn-vending-success w-100" onClick={() => (handleSaveCode())}>
                        <i className="uil uil-arrow-right"></i> {nameButton.button}
                        </button></div>
                     </section>
                  </div>
               </main>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start'>

               <button className='btn btn-primary btn-vending-product mx-4 rounded' onClick={() => (setShowModalKeyCode(false))}>
                  <i className="uil uil-arrow-left"></i>
                  Regresar
               </button>
            </Modal.Footer>
         </Modal>

         <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
            <div className="modal-dialog modal-fullscreen">
               <div className="modal-content">
                  <div className="modal-body">

                  </div>
                  {/* <div className="modal-footer">
                     <button className="btn btn-primary" data-bs-dismiss="modal" >Back to first</button>
                  </div> */}
               </div>
            </div>
         </div>
      </>
   )
}

export default VendingModalKeyCode