import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';

function ModalAsingAndRemovedCategoryToBranchoffice({ branchoffice_id, load, categoriesBranchoffice }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [categories, setCategories] = useState([])
    const [categorySelect, setCategorySelect] = useState("")
    const [deleteMode, setDeleteMode] = useState(false)
    const [searchedCategories, setSearchedCategories] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [categoriesSelectList, setCategoriesSelectList] = useState([])


    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getCategoryByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)
            console.log(response.data.data);
            setCategories(response.data.data)
            setSearchedCategories(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const asingCategoryToBranchoffice = async (category_id) => {

        try {
            const response = await CategoriesDashFetchAPI.asingCategoryToBranchoffice(category_id, branchoffice_id, token)
            console.log(response);
            ErrorAlert('', 'Categoria asignado con exito', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
            console.log(err);
        }
    }

    const handlerChecked = (e, category_id) => {
        if (e.target.checked == true) { 

            if(categoriesSelectList.length >29){
                ErrorAlert('', 'Superaste la cantidad seleccionada', 'warning')
                e.target.checked = false
            }else{
                setCategoriesSelectList([...categoriesSelectList, category_id]); 
            }
        } else {
            let filtrado = categoriesSelectList.filter(id => id != category_id)
            setCategoriesSelectList(filtrado)
        }
    }

    //delete category
    const deleteCategorytoBranchoffice = async (category_id) => {
        let user = JSON.parse(localStorage.getItem("user"))

        let data = {
            category_id: category_id
        }
        try {
            const response = await CategoriesDashFetchAPI.deleteCategorytoBranchoffice(category_id, branchoffice_id, token);
            console.log((response))
            ErrorAlert('', 'Categoria eliminada', 'success')
            load()
            setShowModal(false)

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar la categoria', 'error')
        }
    }

    //Filter the categories by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return categories
        }
        return searchedCategories.filter(categorie => {
            const nameMatch = categorie.category_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = categorie.secundaryName.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, categories]);

    const saveArrayCategories = () => {
        if (categoriesSelectList.length > 30) {
            ErrorAlert('', 'Solo puedes agregar 30 categorias a la ves', 'warning')
        } else {
            categoriesSelectList.forEach((id, index) => {

                try {
                    if (deleteMode == true) {
                        deleteCategorytoBranchoffice(id)
                    } else {
                        asingCategoryToBranchoffice(id)
                    }

                    if (index == (categoriesSelectList.length - 1)) { setCategoriesSelectList([]); setDeleteMode(false); setSearchTerm('') }
                } catch (err) {
                    ErrorAlert('', 'Una de las categorias ya estaba asignada', 'warning')
                }
            });
        }
    }

    const hadleModeDelete = () => {
        setShowModal(true)
        setDeleteMode(true)
        setCategories(categoriesBranchoffice)
        setSearchedCategories(categoriesBranchoffice)
    }


    useEffect(() => {

    }, [eventHandle])

    return (
        <>


            <div className='d-flex justify-content-center align-item-center'>
                <button className='btn btn-outline-primary btn-sm border-0'
                    onClick={() => (setShowModal(true), getCategoryByBusiness())}
                >
                    <i className="uil uil-file-plus-alt"></i> Asignar
                </button>

                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => (hadleModeDelete())}>
                    <i className="uil uil-trash-alt"></i> Eliminar varios
                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        {deleteMode == true ? <>
                            <i className="uil uil-trash-alt"></i>
                            Eliminar categorias de la sucursal</> : <><i className="uil uil-file-plus-alt"></i>Asignar categorias a la sucursal</>} </h3>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Col sm="12 m-3">
                                <h3 className='text-center m-3'>Seleccione las categorias</h3>
                                <p className='text-center '>Maximo 30 categorias</p>
                                <div className="d-flex justify-centent-center align-items-center ">
                                    <form className="row g-3 align-content-center align-items-center">
                                        <div className="col-auto ">
                                            <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                        </div>
                                        <div className="col-auto">
                                            <input
                                                spellcheck="false"
                                                className="form-control"
                                                type="text"
                                                step="any"
                                                placeholder="Ingrese la categoria"
                                                onChange={e => setSearchTerm(e.target.value)}

                                            />
                                        </div>

                                    </form>
                                </div>
                                <div className="m-2 ">
                                    <span className='mb-0 text-secondary fw-bold' >Categorias seleccionados: {categoriesSelectList.length}</span>
                                </div>
                            </Col>
                            {filteredProducts.map(categorie => (
                                <Col sm="4">
                                    <ListGroup as="ol" className='m-2' >
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >

                                            <img
                                                className='imgitemsList m-1'
                                                src={categorie.img}
                                                alt={categorie.category_name}
                                            />
                                            <div className="ms-2 me-auto d-inline-block text-truncate">
                                                <div className="fw-bold">{categorie.category_name}</div>
                                                <p className=''>{categorie.secundaryName}</p>
                                            </div>
                                            <div>
                                                <InputGroup className="">
                                                    <InputGroup.Checkbox aria-label="Checkbox for following text input" onChange={(e) => handlerChecked(e, categorie.category_id)} />
                                                </InputGroup>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            ))}
                        </Row>
                    </Container>



                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-between'>
                        <Button className='m-2' size="sm" onClick={() => (setShowModal(false), setCategoriesSelectList([]), setDeleteMode(false), setSearchTerm(''))}>Cerrar</Button>

                        {deleteMode == true ?
                            <Button className='m-2' size="sm" variant="danger" type="button" onClick={() => saveArrayCategories()}>
                                Eliminar
                            </Button> :
                            <Button className='m-2' size="sm" variant="success" type="button" onClick={() => saveArrayCategories()}>
                                Guardar
                            </Button>}
                    </div>
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ModalAsingAndRemovedCategoryToBranchoffice