import React, { useState } from 'react'
//Component
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Header from "./components/Header"
import { RenderIf } from '../../components/utils/RenderIf';
import { MachinesFetchAPI } from '../../api/Machines';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import ModalOutService from './ModalOutservice';
import ModalDiscount from './ModalDiscount';
import ModalDonleeKiosk from './ModalDonLeeKiosk';
import CartButton from './components/CartButton'

function OrderSummary({ icon, categories, background, updateData, nextStep, prevStep, skipToStep3, linkMenu, shoppingCart, setShoppingCart, invoiceTotal,
  invoiceTotalItbms, invoiceSubtotal, invoiceTotalDiscount, setEventHandler, setPageLoader, setLocator, setClientPhone, clientPhone, formatJsonOrder, business, setAtivity, taxesArrayAll, dataDiscountApply, setdataDiscountApply }) {

  const [showModalDiscount, setModalDiscount] = useState(false)
  const [showModalDonlee, setShowModalDonlee] = useState(false);
  const [isButtonFocusedId, setButtonFocusId] = useState(null);
  const [showModaloutService, setShowModaloutService] = useState(false);
  const navigate = useNavigate();
  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };
  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };

  const updatePage = () => {
    if (localStorage.getItem('branchoffice_original') != null) {
      localStorage.setItem('branchoffice', localStorage.getItem('branchoffice_original'))
    }

    if (localStorage.getItem('machine_original') != null) {
      localStorage.setItem('machine', localStorage.getItem('machine_original'))
    }
    window.location.reload();
  }


  //Function to incremet the product amount on the shopping cart
  const decrementProductAmount = (product_index) => {
    //We search the product on the list
    let product = shoppingCart[product_index];
    //We affect the product data
    product.amount = product.amount - 1;
    product.cantidadItem = product.amount
    product.descuento = product.descuento - product.unit_discount;
    product.discount = product.discount - product.unit_discount;

    //We check if the product amount is lower than 0
    if (product.amount <= 0) {
      //if is lower or equal to 0 we delete the product of the shopping cart list
      shoppingCart.splice(product_index, 1);
      setShoppingCart([...shoppingCart])
    }

    //recharge shoppingcart
    setEventHandler(1)
  };

  //Function to incremet the product amount on the shopping cart
  const incrementProductAmount = (product_index) => {
    //We search the product on the list
    let product = shoppingCart[product_index];
    //We affect the product data
    product.amount = product.amount + 1;
    product.cantidadItem = product.amount;
    product.descuento = product.descuento + product.unit_discount;
    product.discount = product.discount + product.unit_discount;

    //recharge shoppingcart
    setEventHandler(1)
  };

  //Function to delete a product of the shopping cart
  const deleteProduct = (product_index) => {
    //We delete the product of the shopping cart list
    shoppingCart.splice(product_index, 1);
    setShoppingCart([...shoppingCart]);

    //recharge shoppingcart
    setEventHandler(1)
  }

  const handlerPay = () => {
    getMachineById();
    if (shoppingCart.length > 0) {

      //
      let branchNow = JSON.parse(localStorage.getItem('branchoffice'));
      let branchOriginal = { id: null }

      if (localStorage.getItem('branchoffice_original') != null) {
        branchOriginal = JSON.parse(localStorage.getItem('branchoffice_original'));
      }

      if (branchNow.id == branchOriginal.id) {
        if (business.code == 9912) {
          setShowModalDonlee(true);
        } else {
          nextStep();
        }
      } else {
        nextStep();
      }

    }
  }

  const getMachineById = async () => {
    try {
      let branchNow = JSON.parse(localStorage.getItem('branchoffice'));
      let business = JSON.parse(localStorage.getItem('business'));
      let machineNow = JSON.parse(localStorage.getItem('machine'));
      const response = await MachinesFetchAPI.getmachineByid(machineNow.id, localStorage.getItem("x-access-machine-token"))
      const branchofficeResponse = await BranchOfficeFetchAPI.getBranchOfficesByid(branchNow.id, localStorage.getItem("x-access-machine-token"));
      let machine_status = response.data.data.machine_open;
      let branchoffice_status = branchofficeResponse.data.data.branchoffice
      if (branchoffice_status.branchoffice_open == 1) {

        if (machine_status == 1) {

        } else {
          navigate('/kiosk/publicity')
        }
      } else {
        navigate('/kiosk/publicity')
      }

    } catch (err) { console.log(err); }
  }



  //Extraccion de valores por producto 

  const value_total_products = shoppingCart.map(count => count.amount)
  console.log('Valores', value_total_products);

  //Sumatoria total
  const total_products = value_total_products.reduce((acc, curr) => acc + curr, 0)

  return (
    <section className='kiosk-delivery ' style={{ backgroundImage: `url(${background})` }} onClick={() => setAtivity(5)}>
      {/* <Header
        classContainer={"scale-up-horizontal-left"}
        classImg={"kiosk-header-img-categories"}>
      </Header> */}

      <div>

        <div className='d-flex justify-content-between align-items-center p-2'>
          <div className=''>
            <h5 className='title-order-section  scale-up-horizontal-left text-center'>
              Orden </h5>
          </div>

          <div className=''>
            <button className={`btn btn-warning  
    rounded-4  m-1 position-relative fw-bold btn-sm button-discont`}
              onClick={() => setModalDiscount(true)}>
              <i className="uil uil-percentage"></i> Descuento /
              <i className="uil uil-pricetag-alt"></i> Cupón
            </button>
          </div>
        </div>

        {/* 
<div className='bg-light p-5 rounded'>
  <div className='p-5'>
  </div>
</div> */}
      </div>
      <div className='kiosk-container_section 
border   
'>


        {/*Cart shhoppingcart*/}

        <div className='pb-5' >
          {
            !shoppingCart.length
              ?
              <div className='mt-5 '>
                {/* <div className='d-flex justify-content-center mt-5'>
          <img src={vacio}
            className=" d-block image-empty text-center " alt="..." loading='lazy' />
        </div> */}
                <div className='d-block text-center fw-bold text-msg-empty-orders'>
                  <span className='fw-bold '>
                    ¡Tu carrito está vacío!
                  </span>  <br /> No has agregado ningún producto aún.
                </div>
              </div>



              : shoppingCart.map((product, index) => (
                <div>
                  <div className={product.discount || product.unit_discount > 0
                    ? "d-flex order-item border rounded-2 border-warning border-2  position-relative bg-white shadow-lg w-100"
                    : "d-flex order-item rounded-2  bg-white  border shadow-lg "}
                  >
                    <div className="flex-shrink-0">
                      <img className='m-1 mt-2 bg-light  border rounded-3 img-order-summary  ' src={product.img} alt="..." />
                    </div>
                    <div className="flex-grow-1 ms-2">
                      {/* Producto header */}
                      <div className="d-flex bd-highlight ">
                        <div className="bd-highlight  mt-1 title-product-text fw-bold text-wrap">
                          {product.name}
                          {/* {parseFloat(product.base_price) > 0 ? <>: ${parseFloat(product.base_price).toFixed(2)} </> :
                  
                  <></>} */}
                        </div>
                        <div className="ms-auto px-2 pe-4 bd-highlight title-product-text fw-bold text-success "
                          translate='no'>$ {product.price.toFixed(2)}
                        </div>
                      </div>
                      {/* Producto header */}
                      {/*End  Producto Details */}
                      <div className={`d-flex bd-highlight text-muted opacity-75 position-relative`}>
                        <details close >
                          <summary className=''>
                            <span className='badge bg-light text-dark summary-text mt-1 fw-bold border rounded-4 my-1 text-wrap '>
                              <i className="uil uil-info-circle  px-1 "></i>  Detalles
                            </span>
                          </summary>
                          {product.segments.map(item => (
                            <span className="badge bg-light text-dark 
                    detail-text m-2 border rounded-4 my-1 text-wrap ">
                              {/* <span className='kiosk-subtitle-s' >   </span> */}
                              <span className='text-muted m-0 p-0'>{item.segment_name}:</span>
                              <br />
                              <strong>
                                <span className='me-2'>{item.name} </span>
                                {parseFloat(item.price).toFixed(2)}
                              </strong>
                            </span>
                          ))}
                        </details>
                      </div>
                      {/*End  Producto Details */}
                      <RenderIf isTrue={product.note != ""}>
                        <small className='text-danger p-1' >
                          Nota: {product.note}
                        </small>
                      </RenderIf>
                      <div className="d-flex justify-content-between align-items-center mt-1">
                        {/* <div className='px-1 d-flex justify-content-between align-items-center m-2 '>
                  <button
                    className="round-kiosk-button decrement d-flex align-items-center justify-content-center"
                    data-id={index} onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}
                  >-</button>
                  <button
                    className="round-count kiosk-subtitle-s m-2">{product.amount}</button>
                  <button
                    className="round-kiosk-button increment"
                    data-id={index} onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>+</button>
                </div> */}
                        <div className="d-flex align-items-center mt-2 " role="group" aria-label="Basic outlined example">
                          {/*1*/}

                          <RenderIf isTrue={product.descuento == 0}>
                            <button type="button"
                              className={`btn-dash rounded-3`}
                              data-id={index}
                              onClick={e => decrementProductAmount(e.currentTarget.dataset.id)}>
                              {/* <i className="bi bi-dash"></i> */}
                              <span className='dash-icon'>-</span>

                            </button></RenderIf>

                          {/*2*/}

                          <span className="btn  fw-bold border-0 count-items  ">{product.amount}</span>

                          <RenderIf isTrue={product.descuento == 0}>
                            <button type="button"
                              className={`btn-plus rounded-3`}

                              data-id={index}
                              onClick={e => incrementProductAmount(e.currentTarget.dataset.id)}>
                              {/* <i className="bi bi-plus"></i> */}
                              <span className='plus-icon '>+</span>
                            </button>
                          </RenderIf>
                        </div>



                        <div className=" px-2 bd-highlight fw-bold">
                          <button
                            className={`btn btn-outline-danger btn-sm border-0 rounded-circle position-relative
                    ${isButtonFocusedId === "delete" + product.name ? 'focused' : ''}`}
                            title="Eliminar Producto"
                            onFocus={() => handleButtonFocus("delete" + product.name)}
                            onBlur={handleButtonBlur}
                            data-id={index++} onClick={e => deleteProduct(e.currentTarget.dataset.id)}
                          >
                            <i className="uil uil-trash-alt icon-delete-kk px-2 "></i>

                            <div className="position-absolute postition-icon-count">
                              {isButtonFocusedId === "delete" + product.name && <img src={icon} alt="Hand"
                                className='icon-focus' />}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>

                    <span className={product.discount || product.unit_discount > 0
                      ? "position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning kiosk-title-discount"
                      : "visually-hidden"
                    } translate='no'>
                      Descuento aplicado: - {product.unit_discount}
                    </span>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-center'>
          <span className='text-secondary  fw-bold  text-count-orders'>
            Productos : <strong className='badge bg-brand-primary text-white'> {total_products} </strong>
          </span>
          {/* <CartButton amount={shoppingCart}></CartButton> */}
        </div>
        <div className="d-flex justify-content-between  small lh-sm my-1 mx-2 py-2">
          <div className="d-flex m-1   text-center">
            <span className="text-muted border border-2 rounded-3 p-1 text-subtitel-order  bg-light"> Descuento: - $
              <span translate='no' className='fw-bold'>{invoiceTotalDiscount.toFixed(2)}</span>
            </span>
          </div>
          <div className="d-flex m-1 text-center">
            <span className="text-muted border border-2 rounded-3 p-1 text-subtitel-order bg-light"> Subtotal: $
              <span translate='no' className='fw-bold text-center'>{invoiceSubtotal.toFixed(2)}</span>
            </span>
          </div>

          <div className="d-flex m-1 text-center"

            {...(taxesArrayAll.length > 1 ? {
              'data-bs-toggle': 'modal',
              'data-bs-target': '#taxesModal'
            } : {})}

          >
            <span className="text-muted border border-2 rounded-3 p-1 text-subtitel-order bg-light"><i class="uil uil-info-circle"></i> Impuestos: $
              <span translate='no' className='fw-bold text-center'>{invoiceTotalItbms.toFixed(2)}</span>
            </span>
          </div>

          {/* {!taxesArrayAll.length 
          ? <> no hay taxes </> 
          : <>

            {taxesArrayAll.map((tax) => (
              <>
                {tax.total > 0 ? <>
                  <div className="d-flex m-1 text-center">
                    <span className="text-muted border border-2 rounded-3 p-1 text-subtitel-order bg-light"> Impuesto ({tax.tax} ): $
                      <span translate='no' className='fw-bold text-center'>{tax.total.toFixed(3)}</span>
                    </span>
                  </div>

                </> : <></>}
              </>
            ))}
          </>


          } */}


          {/* <div className="d-flex justify-content-between my-3 ">
    <small className="text-muted   "> Subtotal: </small>
    <span className="text-black text-secondary   " translate='no'>
      <span className="px-2  ">$</span>{invoiceSubtotal.toFixed(2)}</span>
  </div>
  <div className="d-flex justify-content-between my-3">
    <small className=" text-muted  "> Itbms:
    </small>
    <span className="text-black text-secondary " translate='no'>
      <span className="px-2 ">$</span>{invoiceTotalItbms.toFixed(2)}</span>
  </div>  */}
{/* Modal de impuestos  */}
          <div class="modal fade" id="taxesModal" tabindex="-1" aria-labelledby="taxesModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">            
                <span class="fs-5 text-center fw-bold" id="taxesModalLabel"><i class="uil uil-info-circle"></i> Impuestos </span>
                <div class="modal-body">
                {taxesArrayAll.map((tax) => (
              <>
                {tax.total > 0 ? <>
                  <div className="d-flex m-1 text-center">
                    <span className="text-muted border border-2 rounded-3 p-1 text-subtitel-order bg-light"> Impuesto ({tax.tax} ): $
                      <span translate='no' className='fw-bold text-center'>{tax.total.toFixed(3)}</span>
                    </span>
                  </div>

                </> : <></>}
              </>
            ))}
                </div>
                <div class="d-flex justify-content-center mb-2">
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cerrar</button>                  
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="d-flex d-block justify-content-between align-content-center total-container px-2 bg-white" >
          <div>
            <span className="text-black fw-bold  mt-1  text-total-order"> Total<small className='fw-light text-muted' >(+Itbms)</small>: </span>
          </div>
          <div>
            <span className='text-total-order fw-bold ' translate='no'>$ {invoiceTotal.toFixed(2)}</span>
          </div>
        </div>
        <div className='d-flex mt-3'>
          <div className=" flex-grow-1 mx-2">
            <button className={`btn btn-primary btn-add-order  w-100 rounded-4  position-relative shadow`}
              onClick={linkMenu}>
              <i class="uil uil-plus"></i>    Agregar más productos
              <div className="position-absolute postition-icon-count">
                {isButtonFocusedId === "adds" && <img src={icon} alt="Hand"
                  className='icon-focus' />}
              </div>
            </button>
          </div>
        </div>
        <div className='d-flex mt-1'>
          <div className="m-2">
            <button className={`btn btn-danger btn-cancel-order  w-100 rounded-4 border  position-relative shadow`}
              onClick={() => (setPageLoader(1), updatePage())}>
              <i class="uil uil-times"></i>Cancelar
            </button>
          </div>
          <div className=" flex-grow-1 m-2">
            <button className={`btn btn-success btn-send-order bg-brand-smartpay  rounded-4 
      w-100 border shadow`}
              onClick={() => handlerPay()}>
              <i class="uil uil-check"></i>
              Pagar $ {invoiceTotal.toFixed(2)}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="kiosk-delivery-footer ">
<small>
  © 2023 Smart Pay
</small>
<small>
  Powered By Smart Retail Group
</small>
</div> */}


      <ModalDiscount icon={icon} background={background} showModalDiscount={showModalDiscount} setModalDiscount={setModalDiscount} setShoppingCart={setShoppingCart} shoppingCart={shoppingCart} setEventHandler={setEventHandler} invoiceTotal={invoiceTotal} invoiceTotalItbms={invoiceTotalItbms}
        invoiceSubtotal={invoiceSubtotal}
        invoiceTotalDiscount={invoiceTotalDiscount} setAtivity={setAtivity} dataDiscountApply={dataDiscountApply} setdataDiscountApply={setdataDiscountApply}></ModalDiscount>

      <ModalDonleeKiosk showModalDonlee={showModalDonlee} setShowModalDonlee={setShowModalDonlee} icon={icon} background={background} setLocator={setLocator} setClientPhone={setClientPhone} formatJsonOrder={formatJsonOrder} nextStep={nextStep} clientPhone={clientPhone} setAtivity={setAtivity}></ModalDonleeKiosk>
      <ModalOutService showModaloutService={showModaloutService} setShowModaloutService={setShowModaloutService} ></ModalOutService>



    </section >

  )
}

export default OrderSummary