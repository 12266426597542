import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
//Messages 
import Alerts from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
//External modules 
import moment from 'moment-timezone';
//Utils 
import Socket from '../utils/Socket.config';

//img
import logo_sp from '../../assets/img/logos/icon-black.png';
//API
import { DonleeFechtAPI } from '../../api/Donlee';
import { GroupsFechtAPI } from '../../api/Groups'
import { RenderIf } from '../utils/RenderIf';
import { SegmentsFechtAPI } from '../../api/Segments';


const CardInfo = (props) => {
   return <>
      <div className="col-6 col-md-4 my-2">
         <a className="btn btn-hover-light">
            <small className="d-block text-muted">{props.title}</small>
            <strong>{props.data}</strong>
         </a>
      </div>
   </>;
};

let token = localStorage.getItem('x-access-machine-token')

const NinyappTransaction = () => {
   //Params
   let { business_code, branchoffice_code } = useParams();
   const [ninjappOrders, setNinjappOrders] = useState([]);
   const [minDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
   const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
   const [modalShow, setModalShow] = useState(false);
   const [eventHandler, setEventHandler] = useState(0);
   const [orderDetails, setOrderDetails] = useState({
      order_id: '',
      customer: {
         id: '',
         name: '',
         rating: '',
         phone: ''
      },
      merchant: {
         name: "",
         address: '',
         phone: '',
         email: ''
      },
      job_delivery_datetime: '',
      job_pickup_address: '',
      job_address: '',
      order_preparation_time: '',
      delivery_method: '',
      orderDetails: [],
      total: ''
   });
   const [branchProducts, setBranchProducts] = useState([])
   const [productSelected, setProductSelected] = useState({ group: "" });
   const [segmentSelected, setSegmentSelected] = useState()
   const [ninyappProductSelected, setNinyappProductSelected] = useState({
      id: '',
      name: '',
      customizations: []
   })
   const [ninyappModifiersSelected, setNinyappModifiersSelected] = useState([]);

   const [productSegments, setProductSegments] = useState([]);

   const [segmentModifiers, setSegmentModifiers] = useState([]);

   const [segmentSelectedItems, setSegmentSelectedItems] = useState({});

   //Functions to show and hide the modal 
   const handleCloseModal = () => setModalShow(false);
   const handleShowModal = () => setModalShow(true)

   //function get groups by branch office
   const getAllGroupsBranchOffice = async () => {
      try {
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let token = localStorage.getItem('x-access-token')
         //We get the products of the branchoffice 
         const response = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice.id, token)
         //We set the products of this branchoffcie
         setBranchProducts(response.data.data)
      } catch (err) {
         console.log(err);
      }
   }

   //Function to get product asigned segments
   const getAsignedSegments = async (group_id) => {
      try {
         const response = await SegmentsFechtAPI.getSegmentsByGroup(group_id, token);
         //We set the product 
         setProductSelected(group_id)
         //We set the segments 
         setProductSegments(response.data.data);
         setEventHandler(1)
      } catch (err) {
         console.log(err)
         setProductSegments([])
      }
   }

   //Function to list the products 
   const segmentList = () => {
      let option_list = productSegments.map((segment) => (
         <option key={segment.segment_id} value={segment.segment_id}>{segment.name}</option>
      ));
      return (
         <>
            {option_list}
         </>
      )
   }

   //Function to list the products 
   const productsList = () => {
      let option_list = branchProducts.map((product) => (
         <option key={product.group_id} value={product.group_id}>{product.name}</option>
      ));
      return (
         <>
            {option_list}
         </>
      )
   }

   //Function to populate the transaction list 
   const transactionList = () => {
      const list = ninjappOrders.map(order => (
         <>
            <input className="list-group-item-check" type="radio" name="listGroupCheckableRadios" id={"listGroupCheckableRadios" + order.order_id} value="" />
            <label className="list-group-item py-3 bg-white" onClick={e => getNinjappOrderDetails(order.order_id)} for={"listGroupCheckableRadios" + order.order_id}>
               <span className='fw-bold'>Order ID :[{order.job_id}] </span>
               <span className="d-block small opacity-50">
                  <span>Customer:[{order.customer.name} ] </span>
               </span>
            </label>
         </>
      ))

      return list
   }

   //Order details template 
   const orderDetailsTemplate = () => {
      return <>
         <div className="d-flex justify-content-start">
            <div className="h5 mt-1 text-primary fw-bold">
               Order Information #{orderDetails.job_id}
            </div>
         </div>
         <div>

            {/* Init Product */}
            <div className="my-3 p-3 bg-body rounded shadow-sm">
               {/* Prodcut  */}
               <div className='d-flex justify-content-start border-bottom mt-2'>
                  <div>
                     <h6 className=" pb-2 mb-0 fw-bold ">Bill Summary</h6>

                  </div>
               </div>
               <div className="row mb-5">
                  <CardInfo title={"Customer #" + orderDetails.customer.id} data={orderDetails.customer.name} />
                  <CardInfo title="Store Name" data={orderDetails.merchant.name} />
                  <CardInfo title="Store Phone No." data={orderDetails.merchant.phone} />

                  <CardInfo title="Payment Method" data={orderDetails.payment_type} />
                  <CardInfo title="Email" data={orderDetails.merchant.email} />
                  <CardInfo title="Order Time" data={orderDetails.job_delivery_datetime} />

                  <CardInfo title="Scheduled Delivery Time" data={orderDetails.job_delivery_datetime} />
                  <CardInfo title="Customer Rating" data={orderDetails.customer.rating} />
                  <CardInfo title="Delivery Method" data={orderDetails.delivery_method === 2 ? 'Home delivery' : 'Take Away'} />


                  <CardInfo title={orderDetails.delivery_method === 2 ? 'Customer Address' : 'Pickup Adress'} data={orderDetails.delivery_method === 2 ? orderDetails.job_address : orderDetails.job_pickup_address} />
                  <CardInfo title="Customer Review" data="[-]" />
                  <CardInfo title="Phone No." data={orderDetails.customer.phone} />


                  <CardInfo title="Restaurants Earning" data={"$ " + orderDetails.total} />
                  <CardInfo title="Order Preparation Time (in minutes) " data={orderDetails.order_preparation_time} />
                  <CardInfo title="Description" data="Description" />

               </div>

               {/* Prodcut  */}
               <div className='d-flex justify-content-start border-bottom mt-2'>
                  <div>
                     <h6 className=" pb-2 mb-0 fw-bold ">Details</h6>
                     {/* <span className='mx-1 badge bg-light text-dark'>Cantidad 4</span>
                                                <span className='mx-1 badge bg-light text-dark '>Precio 19.88</span> */}
                  </div>
               </div>
               {productOrdersTemplate(orderDetails.orderDetails)}
               {/* Prodcut  */}
               <div className='d-flex justify-content-start border-bottom mt-2'>
                  <div>
                     <h6 className=" pb-2 mb-0 fw-bold ">Order History</h6>
                     {/* <span className='mx-1 badge bg-light text-dark'>Cantidad 4</span>
                                                <span className='mx-1 badge bg-light text-dark '>Precio 19.88</span> */}
                  </div>
               </div>
            </div>
            {/* End Product */}

         </div>
      </>
   }

   //Function product order template 
   const productOrdersTemplate = (products) => {
      const list = products.map(product => (
         <div className="d-flex text-muted pt-3">
            {/*             <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#007bff"></rect><text x="50%" y="50%" fill="#007bff" dy=".3em">32x32</text></svg>*/}
            <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.product.product_image} width="32" height="32" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false" />
            <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
               <div className="d-flex justify-content-between">
                  {/* Segments  */}
                  <strong className="text-gray-dark mb-1">{product.product.product_name}</strong>
                  {/* Selct Option  */}
                  <div>
                     <button type="button" onClick={e => { handleShowModal(); setNinyappProductSelected({ id: product.product.product_id, name: product.product.product_name, customizations: product.customizations }); setNinyappModifiersSelected(product.customizations) }} className="btn btn-success btn-sm mx-1">
                        <i className="uil uil-check-circle"></i>
                        Relacionar producto
                     </button>

                  </div>
               </div>
               <span className="d-block">
                  <span className='mx-1 badge bg-light text-dark'>Cantidad {product.product.quantity}</span>
                  <span className='mx-1 badge bg-light text-dark '>Precio {product.product.unit_price}</span>
               </span>

               {segmentsProductTemplate(product.customizations, 'transaction_details')}
            </div>
         </div>
      ))

      return list
   }

   //Function to selecte a segment to asing 
   const asingSegment = (segment_id) => {
      //We get the index  of the segment selected 
      let index = productSegments.findIndex(element => { return element.segment_id == segment_id })
      //We set the segment that the user select
      setSegmentSelected({ segment_id: segment_id, items: productSegments[index].items })
   }

   //Function to populate the segment products 
   const segmentProductTemplate = () => {
      return (
         <div>
            <label htmlFor="">Seleccione modificador</label>
            <select className='form-select' onChange={e => asingSegment(e.target.value === "Seleccione..." ? null : e.target.value)} name="" id="">
               <option value={null}>Seleccione...</option>
               {segmentList()}
            </select>
            {segmentSelected && (segmentItemsTemplate(segmentSelected.items))}
         </div>
      );
   };

   //Function to populate the segment items 
   const segmentItemsTemplate = (items) => {
      let items_list = <div>
         <label htmlFor="">Seleccione item del modificador</label>
         <select className='form-select ' name="" id="">
            <option value={null}>Seleccione...</option>
            {items.map(item => (
               <option key={item.item_id} value={item.item_id}>{item.name}</option>
            ))}
         </select>
      </div>

      return items_list
   }

   //Function segment order template 
   const segmentsProductTemplate = (segments, template_name) => {
      const list = segments.map(segment => (
         <>
            {/** Segment list transaction details  */}
            <RenderIf isTrue={template_name === 'transaction_details'}>
               <div className="d-flex text-muted pt-3">
                  <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                     <div className="d-flex justify-content-between">
                        {/* Segments  */}
                        <strong className="text-gray-dark mb-1">{segment.type_name + ": " + segment.cust_name}</strong>
                     </div>
                     <span className="d-block">
                        <span className='mx-1 badge bg-light text-dark '>Precio {segment.total_price}</span>
                     </span>
                  </div>
               </div>
            </RenderIf>

            {/** Segment list on modal list */}
            <RenderIf isTrue={template_name === 'segment_modal_list'}>
               <div className=" d-flex justify-content-between mt-1 mb-3 border-bottom pb-2">
                  <div className='w-50'>
                     <div>
                        <label className='d-block'>Modificador</label>
                        <strong> {segment.type_name + ": " + segment.cust_name} </strong>
                     </div>
                  </div>

                  {segmentProductTemplate()}

               </div>
            </RenderIf>
         </>
      ));

      return list
   }

   //We get the transactions by branchoffice 
   const getNinjappOrders = async (minDate, maxDate) => {
      try {
         //We call the orders of the branchoffice 
         const response = await DonleeFechtAPI.getNinjappOrders(minDate, maxDate, token);
         setNinjappOrders(response.data.orders);
      } catch (err) {
         console.log(err);
      }
   }

   //Function to get ninjapp order details 
   const getNinjappOrderDetails = async (order_id) => {
      try {
         const response = await DonleeFechtAPI.getNinjappOrderDetails(order_id, token);
         setOrderDetails(response.data.order)
      } catch (err) {
         console.log(err)
      }
   }

   //Function to asing the ninyapp Products to Smartpay 
   const asingNinyappProductsToSmartpay = async () => {
      try {
         //Loading alert 
         LoadingAlert('Cargando', 'Cargando informacion...');
         await DonleeFechtAPI.asingNinyappProductsToSmartpay(productSelected, ninyappProductSelected.id, token);
         //Alert success
         Alerts('Productos relacionados', 'Exitoso', 'success');
      } catch (err) {
         console.log(err)
         //Alert error
         Alerts(err.response.data.error, 'Error', 'error');
      }
   }

   //Hook to charge data when the page is loaded 
   useEffect(() => {
      setEventHandler(0)
      //We set the segments 
      setProductSegments(productSegments);
   }, [eventHandler]);

   //Hook to conect the client to the sockets and make the initial charge of data 
   //Hook to charge data when the page is loaded 
   useEffect(() => {
      //We set the branchoffices of the user 
      getNinjappOrders(minDate, maxDate);
      //We get the products of the branchoffice
      getAllGroupsBranchOffice()
      // Connection with the sockets server
      const socket = Socket({ "business_code": business_code, "branchoffice_code": branchoffice_code, "ninyapp": true });
      // we upate the data when the server send the signal of a new order 
      // Update of the data when the server send the signal of new order for this business 
      socket.on("ninyapp_order", (newNinyappOrder) => {
         //We set the new audio 
         const audio = new Audio("https://res.cloudinary.com/dk3x6ubyi/video/upload/v1695006765/mario-coin-200bpm-82548_trv59h.mp3");
         //We play the audio every time we have a new order 
         audio.play();
         //We set the new order 
         setNinjappOrders((prevOrders) => {
            //We return the new order 
            return [newNinyappOrder, ...prevOrders]
         });
      });

      return () => {
         socket.disconnect();
      }
   }, [branchoffice_code]);


   return (
      <>

         <div className='kds-main'>
            <header className="py-1 px-1  border-bottom  bg-white fixed-top">
               <nav className="border-bottom border-3 bg-white px-1 fixed-top ">
                  <div className="">
                     <div className="d-flex  align-items-center justify-content-between">
                        <button className='btn btn-outline-dark border-0 fw-bold icon-button fs-6'>
                           <i className="uil uil-arrow-circle-left "></i> Regresar
                        </button>
                        <div className='d-flex  bd-highlight  align-items-center'>
                           <a className="d-flex align-items-center 
                            flex-fill bd-highlight 
                            text-dark text-decoration-none">
                              <img
                                 className="navbar-logo-post"
                                 src={logo_sp}
                                 alt="Logo Smart Pay"
                              />
                              <span className="h5 pt-2 me-3 fw-bold align-self-center text-normal">Smart Pay</span>
                           </a>
                        </div>
                        <div className="badge c-2 text-wrap fs-6" title='Información de empresa'>
                           <i className="uil uil-tag"></i>    Ninyapp
                        </div>
                        {/* <div className='flex-fill bd-highlight me-2 '>
                        </div> */}
                     </div>
                  </div>
               </nav>
            </header>
            <main className="container-parent ">
               <div className="container-pos-left kds-view shadow-sm no-scroll">
                  <div className="flex-container">
                     <div className="item1 mt-5">
                        <div className='d-flex justify-content-center pt-3'>
                           <h4 className='text-dark fw-bold text-center'>
                              <i className="uil uil-receipt"></i>
                              Transacciones
                           </h4>
                        </div>
                     </div>
                     <div className="item2 py-0 px-1">
                        <div className=" px-1  bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-light" >

                           <div className="list-group mt-2 list-group-checkable">
                              {transactionList()}
                           </div>
                        </div>
                     </div>
                     <div className="item3"></div>
                  </div>
               </div>

               <div className="container-pos-right no-scroll">
                  <section className='h-100'>
                     <div className="flex-container">
                        <div className="item1 mt-5">
                           <div className='d-flex justify-content-center pt-3'>
                              <h4 className='text-dark fw-bold'>
                                 <i className="uil uil-notes"></i>
                                 Detalles de la orden
                              </h4>
                           </div>
                        </div>
                        <div className="item2 py-0 px-1">
                           <div className=" px-1  bd-highlight shopping-wrapper border  border-inset rounded no-scroll bg-light" >
                              <div className="d-flex shadow-sm rounded m-1 bg-white">
                                 <div className="flex-grow-1 m-1">
                                    {orderDetailsTemplate()}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="item3 py-0 px-1">
                           <div></div>
                        </div>
                     </div>
                  </section>
               </div>
            </main>
         </div >

         <Modal show={modalShow} onHide={handleCloseModal}>
            <Modal.Header closeButton>
               Asignar producto
            </Modal.Header>
            <Modal.Body>
               <div className="">
                  <div className=" d-flex justify-content-between mt-1 mb-3 border-bottom pb-2">
                     <div className='w-50'>
                        <div>
                           <label className='d-block'>Producto</label>
                           <strong> {ninyappProductSelected.name} </strong>
                        </div>
                     </div>
                     <div className='w-50'>
                        <label htmlFor="">Seleccione el producto</label>
                        <select className='form-select ' onChange={e => getAsignedSegments(e.target.value === "Seleccione..." ? 0 : e.target.value)} name="" id="">
                           <option value={null}>Seleccione...</option>
                           {productsList()}
                        </select>
                     </div>
                  </div>
                  {segmentsProductTemplate(ninyappModifiersSelected, 'segment_modal_list')}

                  <button type="button" className="btn btn-success btn-sm mx-1" onClick={asingNinyappProductsToSmartpay}>
                     <i className="uil uil-check-circle"></i>
                     Aceptar
                  </button>
               </div>


            </Modal.Body>
         </Modal>
      </>
   )
}

export default NinyappTransaction