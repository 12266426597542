import React, { useState, useMemo } from 'react';

const ReportTable = ({ table_name, table_data, table_headers, table_type }) => {
    //States
    const [searchTerm, setSearchTerm] = useState('');
    //Function to populate the table headers
    const tableHeaders = () => {
        const headers = table_headers.map((header) => (
            <>
                <th>{header}</th>
            </>
        ));
        return headers;
    }
    //Filter the data by the term searched 
    const filteredData = useMemo(() => {
        if (searchTerm === '') {
            return table_data
        }
        return table_data.filter(data => {
            //We check the type of table 
            if (table_type === "general_report") {
                const transactionMatch = data.transaction_code.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const dateMatch = data.createdAt.toLowerCase().includes(searchTerm.toLowerCase());
                return transactionMatch || dateMatch
            } else if (table_type === "paymentmethod_report") {
                const transactionMatch = data.transaction_code.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const dateMatch = data.createdAt.toLowerCase().includes(searchTerm.toLowerCase());
                const paymentmethodMatch = data.paymenthod.toLowerCase().includes(searchTerm.toLocaleLowerCase());

                return transactionMatch || dateMatch || paymentmethodMatch
            } else if (table_type === "products_report") {
                const productMatch = data.group_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
                const totalAmountProductMatch = data.total_amount_selled.toString().toLowerCase().includes(searchTerm.toLocaleLowerCase());

                return productMatch || totalAmountProductMatch
            }
        });
    }, [searchTerm, table_data]);

    //Function to populate the table content 
    const tableContent = () => {
        //We check the type of table to render the content of the table 
        if (table_type === "general_report") {
            //We format the date and hour of the order
            const general_sales_content = filteredData.map((content) => (
                <>
                    <tr>
                        <td className='align-middle'>{content.createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.createdAt.split('T')[1].split(':')[0] + ':' + content.createdAt.split('T')[1].split(':')[1] + ' ' + (content.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')} </td>
                        <td className='align-middle'> {content.transaction_code}</td>
                        <td className='align-middle'> {content.total === undefined ? "$ 0.00" : "$ " + content.total.toFixed(2)}</td>
                        <td className='align-middle'> {content.subtotal === undefined ? "$ 0.00" : "$ " + content.subtotal.toFixed(2)}</td>
                        <td className='align-middle'> {content.tax === undefined ? "$ 0.00" : "$ " + content.tax.toFixed(2)}</td>
                        <td className='align-middle'> {content.discount === undefined ? "$ 0.00" : "$ " + content.discount.toFixed(2)}</td>
                        <td className='align-middle'> {content.cufe === undefined ? "0" : content.cufe}</td>

                    </tr>
                </>
            ));

            return general_sales_content
        } else if (table_type === "paymentmethod_report") {
            //We format the date and hour of the order
            const paymentmethod_sales_content = filteredData.map((content) => (
                <>
                    <tr>
                        <td className='align-middle'>{content.createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.createdAt.split('T')[1].split(':')[0] + ':' + content.createdAt.split('T')[1].split(':')[1] + ' ' + (content.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')} </td>
                        <td className='align-middle'> {content.transaction_code}</td>
                        <td className='aling-middle'>{content.paymenthod}</td>
                        <td className='align-middle'> {content.total === undefined ? "$ 0.00" : "$ " + content.total.toFixed(2)}</td>
                        <td className='align-middle'> {content.subtotal === undefined ? "$ 0.00" : "$ " + content.subtotal.toFixed(2)}</td>
                        <td className='align-middle'> {content.tax === undefined ? "$ 0.00" : "$ " + content.tax.toFixed(2)}</td>
                        <td className='align-middle'> {content.discount === undefined ? "$ 0.00" : "$ " + content.discount.toFixed(2)}</td>
                        <td className='align-middle'> {content.cufe === undefined ? "0" : content.cufe}</td>
                    </tr>
                </>
            ));

            return paymentmethod_sales_content
        } else if (table_type === "products_report") {
            const product_sales_content = filteredData.map((content) => (
                <>
                    <tr>
                        <td className='align-middle'>{content.group_name}</td>
                        <td className='align-middle'>{content.total_amount_selled === undefined ? "0" : content.total_amount_selled}</td>
                        <td className='align-middle'>{content.total_selled === undefined ? "$ 0.00" : '$ ' + content.total_selled.toFixed(2)}</td>

                    </tr>
                </>
            ));

            return product_sales_content
        } else if (table_type === 'preaccount_report') {
            //We format the date and hour of the order
            const preaccount_report = filteredData.map((content) => (
                <>
                    <tr>
                        <td className='align-middle'>{content.createdAt.split('T')[0]}</td>
                        <td className='align-middle'>{content.createdAt.split('T')[1].split(':')[0] + ':' + content.createdAt.split('T')[1].split(':')[1] + ' ' + (content.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')} </td>
                        <td className='align-middle'> {content.code}</td>
                        <td className='align-middle'> {content.total === undefined ? "$ 0.00" : "$ " + content.total.toFixed(2)}</td>
                        <td className='align-middle'> {content.subtotal === undefined ? "$ 0.00" : "$ " + content.subtotal.toFixed(2)}</td>
                        <td className='align-middle'> {content.tax === undefined ? "$ 0.00" : "$ " + content.tax.toFixed(2)}</td>
                        <td className='align-middle'> {content.discount === undefined ? "$ 0.00" : "$ " + content.discount.toFixed(2)}</td>

                    </tr>
                </>
            ));
            return preaccount_report
        }
    }

    return (
        <>
            {/**Tabla de detalles */}
            <div className='bg-white border-sp shadow-sm border mb-5 mt-4'>
                <div className="row mt-3 mx-3">
                    <div className="col-md-4">
                        <h5 className='fs-4'>{table_name}</h5>
                    </div>
                    <div className="col-md-4  offset-md-4 ">
                        <div className="w-auto  d-flex justify-content-end mt-1">
                            <div className="row  g-3 align-content-center align-items-center">
                                <div className="col-auto">
                                    <span className='mb-0 text-secondary fw-bold' >Buscar</span>
                                </div>
                                <div className="col-auto">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Ingrese termino"
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-4 mt-3">
                    <div className=" border rounded table-responsive">
                        <table className='table table-hover table-sm' >
                            <thead className='table-light'>
                                <tr>
                                    {tableHeaders()}
                                </tr>
                            </thead>
                            <tbody>
                                {tableContent()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportTable;
