
import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class BuysFetchAPI {

    //Function to create a new buy 
    static async newBuy(data, token) {
        const res = await server(`/buys`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: data,
        });
        return res
    }

    //Function to get buys by branchoffice 
    static async getBuysByBranch(branchoffice_id, initialDate, maxDate, token) {
        const res = await server(`/buys/${branchoffice_id}/${initialDate}/${maxDate}`, {
            method: "get",
            headers: { 'x-access-token': token }
        });
        return res
    }

    //Function to get buys and sale history by branchoffice 
    static async getBuySaleHistoryByBranchoffice(branchoffice_id, initialDate, maxDate, token) {
        const res = await server(`/buy_sale/${branchoffice_id}/${initialDate}/${maxDate}`, {
            method: "get",
            headers: { 'x-access-token': token }
        });
        return res
    }

    //Function to gt the buys details 
    static async getBuysDetails(buy_id, token) {
        const res = await server(`/buy/${buy_id}`, {
            method: 'get',
            headers: { 'x-access-token': token }
        });
        return res;
    }

}

